import { Popconfirm } from "antd";
import VLButton from "components/common/buttons/VLButton";
import strings from "localisation/strings";
import React from "react";
import useAssetActions from "./hooks/useAssetActions";

export interface EmptyBinButtonProps {
  binnedAssetIds: string[];
}

export default ({ binnedAssetIds }: EmptyBinButtonProps) => {
  const {
    deleteAction: [deleteAssets]
  } = useAssetActions();

  const emptyBin = () => {
    deleteAssets({ assetIds: binnedAssetIds });
  };

  if (binnedAssetIds.length > 0) {
    return (
      <Popconfirm
        title={strings("folderInfo.deleteConfirm")}
        onConfirm={emptyBin}
        okText={strings("common.yes")}
        cancelText={strings("common.no")}
      >
        <VLButton t="folderTree.emptyBin" />
      </Popconfirm>
    );
  }

  return null;
};
