import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { isSingleChoiceMeta } from "components/assetMetaSpecs/MetadataFieldTypes";
import React from "react";
import { MetaFieldInputProps } from "./MetaFieldInputTypes";

export default ({
  field,
  onValuesChanged,
  values: {
    textValues: [selectedValue]
  }
}: MetaFieldInputProps) => {
  if (!isSingleChoiceMeta(field)) {
    return null;
  }
  const { options } = field;
  const possibleValues = options.possibleValues || [];
  if (!selectedValue && options.default) {
    onValuesChanged({
      fieldId: field.id || "",
      values: { textValues: [options.default] }
    });
  }
  const onChange = (e: RadioChangeEvent) => {
    onValuesChanged({
      fieldId: field.id || "",
      values: { textValues: [e.target.value] }
    });
  };
  return (
    <Radio.Group
      options={possibleValues}
      value={selectedValue}
      onChange={onChange}
    />
  );
};
