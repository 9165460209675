import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";
import GqlForm from "components/gqlForm/GqlForm";
import useGqlForm from "components/gqlForm/hooks/useGqlForm";
import strings from "localisation/strings";
import React from "react";
import { FolderFragment, useUpsertFolderMutation } from "__gen__/appService";
import { EditFolderForm } from "__gen__/forms";
import EditFolderFormSchema from "./forms/EditFolderForm.form.graphql";

interface EditFolderModalProps extends ModalProps {
  folder: FolderFragment;
  onDone?: () => void;
}

export default ({
  onDone = () => {},
  folder,
  ...props
}: EditFolderModalProps) => {
  const [upsertFolder, { loading }] = useUpsertFolderMutation();
  const { form, validate, valuesState, errorsState } = useGqlForm<
    EditFolderForm
  >({
    schema: EditFolderFormSchema,
    initialValue: {
      name: folder.name,
      description: folder.description || undefined,
    },
  });

  const onModalOk = async () => {
    const values = validate();
    if (!values) {
      return;
    }
    await upsertFolder({
      variables: {
        folder: [{ id: folder.id, org_id: folder.orgId, ...values }],
      },
    });
    onDone();
  };

  return (
    <Modal
      onOk={onModalOk}
      onCancel={onDone}
      cancelText={strings("buttons.cancel")}
      okText={strings("buttons.save")}
      okButtonProps={{ loading }}
      {...props}
    >
      <GqlForm form={form} errorsState={errorsState} state={valuesState} />
    </Modal>
  );
};
