import { CompareFn } from "antd/lib/table/interface";
import { SimpleText } from "components/common/text/SimpleText";
import MediaTypeIcon from "components/common/upload/MediaTypeIcon";
import AssetThumbnail from "components/imagePreview/AssetThumbnail";
import { VLAsset } from "components/mediaAssets/types";
import strings from "localisation/strings";
import { TranslationPaths } from "localisation/TranslationPaths";
import { innerJoin } from "ramda";
import React, { ReactNode } from "react";
import styled from "styled-components";
import {
  compareDeletedDates,
  compareMediaNames,
  compareMediaTypes,
  compareUpdatedDates,
} from "../assetSorters";

const ListAssetThumbnail = styled(AssetThumbnail)`
  max-width: 40px;
  max-height: 40px;
`;

export type AssetListColumnIndex =
  | "thumbnail"
  | "type"
  | "name"
  | "updatedAt"
  | "deletedAt";

export const allColumns: AssetListColumnIndex[] = [
  "thumbnail",
  "type",
  "name",
  "updatedAt",
  "deletedAt",
];

export const translations: {
  [key in AssetListColumnIndex]: TranslationPaths;
} = {
  thumbnail: "common.image",
  type: "common.type",
  name: "common.name",
  updatedAt: "common.updatedAt",
  deletedAt: "common.deletedAt",
};

interface AssetColumn {
  dataIndex: AssetListColumnIndex;
  title: string;
  sorter?: CompareFn<VLAsset>;
  render: (_: any, asset: VLAsset & { originalFiles: [] }) => ReactNode;
  width?: string;
}

interface AssetColumnProps {
  columns: AssetListColumnIndex[];
  formatTimestamp: (date?: string | null) => string;
}

export default ({ formatTimestamp, columns }: AssetColumnProps) => {
  const defaultColumns: AssetColumn[] = [
    {
      dataIndex: "thumbnail",
      title: "",
      render: (_, asset) => <ListAssetThumbnail asset={asset} />,
      width: "50px",
    },
    {
      dataIndex: "type",
      title: strings(translations.type),
      sorter: compareMediaTypes,
      render: (_, { id, type }) => <MediaTypeIcon key={id} type={type} />,
    },
    {
      dataIndex: "name",
      title: strings(translations.name),
      sorter: compareMediaNames,
      render: (_, asset) => (
        <SimpleText key={asset.id}>{asset.name}</SimpleText>
      ),
    },
    {
      dataIndex: "updatedAt",
      title: strings(translations.updatedAt),
      sorter: compareUpdatedDates,
      render: (_, { id, updatedAt }) => (
        <SimpleText key={id} fontSize="small" color="tertiary">
          {formatTimestamp(updatedAt)}
        </SimpleText>
      ),
    },
    {
      dataIndex: "deletedAt",
      title: strings(translations.deletedAt),
      sorter: compareDeletedDates,
      render: (_, { id, deletedAt }) => (
        <SimpleText key={id} fontSize="small" color="tertiary">
          {formatTimestamp(deletedAt)}
        </SimpleText>
      ),
    },
  ];

  return innerJoin((col, id) => col.dataIndex === id, defaultColumns, columns);
};
