import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import Env from "config/Env";

const apiHttpLink = createHttpLink({
  uri: Env.REACT_APP_GATEWAY_URL
});

export const vlApiClient = new ApolloClient({
  link: apiHttpLink,
  cache: new InMemoryCache()
});

export const fakeClient = new ApolloClient({
  link: createHttpLink({
    uri: Env.REACT_APP_HASURA_URL
  }),
  cache: new InMemoryCache()
});
