import { Table } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import { MetaField } from "components/assetMetaSpecs/MetadataFieldTypes";
import { Column } from "components/common/layout/Flex";
import useDateFns from "components/hooks/useDateFns";
import useMediaDeletingPermissions from "components/permissionControl/hooks/useMediaDeletingPermissions";
import { contains, pluck, without } from "ramda";
import React, { useEffect, useState } from "react";
import useMediaAssetSidebar from "state/hooks/useMediaAssetSidebar";
import MediaBrowserPreferences, {
  DEFAULT_PAGE_SIZE,
} from "state/MediaBrowserPreferences";
import styled from "styled-components";
import { isVlMedia, VLAsset, VLFolder } from "../mediaAssets/types";
import useSortedMedia from "./hooks/useSortedMedia";
import defaultColumns, { AssetListColumnIndex } from "./table/defaultColumns";
import metadataColumns from "./table/metadataColumns";

const highlightedRowClassname = "vl-row-highlighted";
const rowClassname = "vl-row";
const Container = styled(Column)`
  overflow-x: auto;

  .${rowClassname} {
    :hover {
      cursor: pointer;
    }
  }

  .${highlightedRowClassname} {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
`;

const HeaderCell = styled.th`
  max-width: 200px;
`;

const Cell = styled.td`
  &&&&&&&&&& {
    padding: ${({ theme }) => theme.margin.small};
  }
`;

interface AssetListProps {
  mediaAssets: VLAsset[];
  metaFields: MetaField[];
  activeAction?: string | undefined;
  onFolderClick?: (folder: VLFolder) => void;
  isBinnedMedia?: boolean;
  loading?: boolean;
  selectedIds: string[];
  setSelectedIds: (selectedIds: string[]) => void;
}

export default ({
  mediaAssets,
  metaFields,
  isBinnedMedia,
  activeAction,
  loading,
  selectedIds,
  setSelectedIds,
  onFolderClick = () => {},
}: AssetListProps) => {
  const { formatTimeStamp } = useDateFns();
  const { onTableChange, selectedAssetIndex } = useSortedMedia(mediaAssets);
  const [currentPage, setCurrentPage] = useState(1);
  const { openMediaSidebar, selectedAssetId } = useMediaAssetSidebar();
  const {
    pageSize,
    setPageSize,
    assetListColumns,
  } = MediaBrowserPreferences.useContainer();

  const mediaIds = pluck("id", mediaAssets);
  const removableMedia = pluck(
    "mediaAssetId",
    useMediaDeletingPermissions({ mediaIds }),
  );

  const metaColumnComponents = metadataColumns({ metaFields });

  useEffect(() => {
    const newSeleciton = selectedIds.filter(
      (id) => mediaIds.indexOf(id) !== -1,
    );
    setSelectedIds(newSeleciton);
    // eslint-disable-next-line
  }, [JSON.stringify(mediaIds)]);

  useEffect(() => {
    const pageMin = (currentPage - 1) * pageSize + 1;
    const pageMax = pageSize * currentPage;
    if (selectedAssetIndex > -1 && selectedAssetIndex >= pageMax) {
      setCurrentPage(Math.floor(selectedAssetIndex / pageSize) + 1);
    }
    if (selectedAssetIndex > -1 && selectedAssetIndex + 1 < pageMin) {
      setCurrentPage(Math.floor(selectedAssetIndex / pageSize) + 1);
    }
    // eslint-disable-next-line
  }, [selectedAssetId, pageSize]);

  const rowSelection: TableRowSelection<VLAsset> = {
    onChange: (selectedRowKeys) => {
      setSelectedIds(selectedRowKeys as string[]);
    },
    selectedRowKeys: selectedIds,
    getCheckboxProps: (asset) => ({
      disabled:
        activeAction === "DELETE" && !contains(asset.id, removableMedia),
    }),
  };

  const cols = !isBinnedMedia
    ? without<AssetListColumnIndex>(["deletedAt"], assetListColumns)
    : assetListColumns;

  return (
    <Container marginVertical="medium">
      <Table<VLAsset>
        onChange={onTableChange}
        size="small"
        dataSource={mediaAssets}
        onRow={(asset) => ({
          onClick: isVlMedia(asset)
            ? () => openMediaSidebar(asset.id)
            : () => onFolderClick(asset),
        })}
        rowKey="id"
        rowSelection={!!activeAction ? rowSelection : undefined}
        pagination={
          mediaAssets.length > DEFAULT_PAGE_SIZE
            ? {
                size: "small",
                pageSize,
                pageSizeOptions: ["10", "25", "50", "100"],
                showSizeChanger: true,
                current: currentPage,
                onChange: (page) => {
                  setCurrentPage(page);
                },
                onShowSizeChange: (_, size) => {
                  setPageSize(size);
                },
              }
            : false
        }
        loading={loading}
        scroll={{ x: "max-content" }}
        rowClassName={({ id }) =>
          selectedAssetId === id
            ? [highlightedRowClassname, rowClassname].join(" ")
            : rowClassname
        }
        components={{ body: { cell: Cell }, header: { cell: HeaderCell } }}
      >
        {defaultColumns({
          formatTimestamp: formatTimeStamp,
          columns: cols,
        }).map((col) => (
          <Table.Column key={col.dataIndex} {...col} />
        ))}
        {metaColumnComponents}
      </Table>
    </Container>
  );
};
