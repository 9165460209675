import { Table } from "antd";
import VLButton from "components/common/buttons/VLButton";
import { Row } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import CanEditOrgMembers from "components/permissionControl/CanEditOrgMembers";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import React from "react";
import { Link } from "react-router-dom";
import PermissionStore from "store/PermissionStore";
import { PermissionUserGroupRolesSelectColumn } from "vl-app-client/lib/__gen__/appServiceSdk";
import {
  OrgTeamsSubscription,
  useOrgTeamsSubscription,
} from "__gen__/appService";
import commaJoin from "./functions/commaJoin";
import MemberLink from "./MemberLink";
import RoleLink from "./RoleLink";
import TeamLink from "./TeamLink";

type OrgTeam = OrgTeamsSubscription["teams"][0];

interface OrgTeamsProps {
  orgSlug: string;
  orgId: string;
}

export default ({ orgId, orgSlug }: OrgTeamsProps) => {
  const { data, loading } = useOrgTeamsSubscription({
    orgId,
    distinctOn: [PermissionUserGroupRolesSelectColumn.role_id],
  });
  const { canEditOrgData, canEditOrgMembers } = PermissionStore.useContainer();

  const teams = data?.teams;

  return (
    <>
      <CanEditOrgMembers>
        <Row grow={0} mainAxis="flex-end" marginVertical="medium">
          <Link
            to={Paths.orgTeamDetails.withParams({ orgSlug, teamId: "new" })}
          >
            <VLButton icon="plus" />
          </Link>
        </Row>
      </CanEditOrgMembers>
      <Table<OrgTeam>
        dataSource={teams}
        rowKey="id"
        pagination={false}
        loading={loading}
        size="small"
      >
        <Table.Column<OrgTeam>
          dataIndex="name"
          title={strings("common.team")}
          render={(_, team) => (
            <TeamLink
              disabled={!canEditOrgMembers}
              team={team}
              orgSlug={orgSlug}
            />
          )}
        />
        <Table.Column<OrgTeam>
          dataIndex="roles"
          title={strings("common.roles")}
          render={(_, { roles }) => {
            if (roles.length === 0) {
              return <SimpleText color="tertiary" t="orgTeams.noRoles" />;
            }

            return (
              <SimpleText color="secondary">
                {commaJoin(
                  roles.map(({ role }) => (
                    <RoleLink
                      key={role.id}
                      role={role}
                      orgSlug={orgSlug}
                      disabled={!canEditOrgData}
                    />
                  )),
                )}
              </SimpleText>
            );
          }}
        />
        <Table.Column<OrgTeam>
          dataIndex="members"
          title={strings("common.members")}
          render={(_, { members }) => {
            if (members.length === 0) {
              return <SimpleText color="tertiary" t="orgTeams.noMembers" />;
            }

            return (
              <SimpleText color="secondary">
                {commaJoin(
                  members.map(({ user, id }) => (
                    <MemberLink
                      disabled={!canEditOrgMembers}
                      key={id}
                      memberId={id}
                      user={user}
                      orgSlug={orgSlug}
                    />
                  )),
                )}
              </SimpleText>
            );
          }}
        />
      </Table>
    </>
  );
};
