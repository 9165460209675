import { Button, Checkbox, Form, message } from "antd";
import { NativeButtonProps } from "antd/lib/button/button";
import { FormInstance } from "antd/lib/form";
import { Store } from "antd/lib/form/interface";
import VLInput from "components/common/input/VLInput";
import { SpreadRow } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import VLIcon from "components/common/VLIcon";
import strings from "localisation/strings";
import { TranslationPaths } from "localisation/TranslationPaths";
import Paths from "navigation/Paths";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useEmailAuth from "state/hooks/useEmailAuth";
import styled from "styled-components";
import { AuthErrorTypes } from "__gen__/authGatewaySdk";
import VerifyEmail from "./VerifyEmail";

const StyledForm = styled(Form)`
  max-width: 300px;
  flex: 1;
`;

const EmailInfoContainer = styled.div`
  max-width: 300px;
  flex: 1;
  background-color: ${({ theme }) => theme.color.background.primary};
`;

const B = (props: NativeButtonProps) => <Button {...props} />;
const LoginButton = styled(B)`
  width: 100%;
`;

const setFormError = (
  form: FormInstance,
  fieldName: string,
  errorTranslation: TranslationPaths,
) => {
  form.setFields([
    {
      name: fieldName,
      value: form.getFieldValue(fieldName),
      errors: [strings(errorTranslation)],
    },
  ]);
};

export default () => {
  const { login, errorType } = useEmailAuth();
  const [email, setEmail] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    if (errorType === AuthErrorTypes.INVALID_PASSWORD) {
      setFormError(
        form,
        "password",
        "login.emailAndPassword.fieldErrors.auth/wrong-password",
      );
    } else if (errorType === AuthErrorTypes.INVALID_EMAIL) {
      setFormError(
        form,
        "email",
        "login.emailAndPassword.fieldErrors.auth/invalid-email",
      );
    } else if (
      !!errorType &&
      errorType !== AuthErrorTypes.EMAIL_VERIFICATION_REQUIRED
    ) {
      message.error(strings("login.failed"));
    }
    // eslint-disable-next-line
  }, [errorType]);

  const onFinish = async (values: Store) => {
    setEmail(values.email);
    await login({
      email: values.email,
      password: values.password,
      rememberUser: values.remember,
    });
  };

  if (errorType === AuthErrorTypes.EMAIL_VERIFICATION_REQUIRED) {
    return (
      <EmailInfoContainer>
        <VerifyEmail email={email} />
      </EmailInfoContainer>
    );
  }

  return (
    <StyledForm form={form} onFinish={onFinish}>
      <Form.Item
        name="email"
        rules={[{ required: true, message: strings("login.emailError") }]}
      >
        <VLInput
          prefix={<VLIcon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder={strings("login.emailPlaceholder")}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: strings("login.passwordError") }]}
      >
        <VLInput
          prefix={<VLIcon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
          type="password"
          placeholder={strings("login.passwordPlaceholder")}
        />
      </Form.Item>
      <SpreadRow>
        <Form.Item name="remember" valuePropName="checked" initialValue={true}>
          <Checkbox>
            <SimpleText color="opposite">
              {strings("login.rememberMe")}
            </SimpleText>
          </Checkbox>
        </Form.Item>
        <Link to={Paths.forgotPassword.path}>
          {strings("login.forgotPassword")}
        </Link>
      </SpreadRow>
      <LoginButton htmlType="submit">{strings("login.logIn")}</LoginButton>
      <SimpleText color="opposite">{strings("login.orRegister")}</SimpleText>
      <Link to={Paths.register.path}>{strings("login.registerNow")}</Link>
    </StyledForm>
  );
};
