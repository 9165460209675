import { Row } from "antd";
import {
  isBooleanMeta,
  isDateMeta,
  isMultipleChoiceMeta,
  isNumberMeta,
  isSimpleTextMeta,
  isSingleChoiceMeta,
  isTagListMeta,
  MetaField,
} from "components/assetMetaSpecs/MetadataFieldTypes";
import { SimpleText } from "components/common/text/SimpleText";
import VLTag from "components/common/text/VLTag";
import useDateFns from "components/hooks/useDateFns";
import head from "functions/head";
import { toUpper } from "ramda";
import React from "react";
import styled from "styled-components";
import { MetaFieldValuesFragment } from "__gen__/appService";

const NotSetText = styled(SimpleText)`
  opacity: 0.6;
`;

const SimpleTextComponent = styled(SimpleText)`
  white-space: pre-wrap;
`;

const TagValue = styled(VLTag)`
  margin-top: ${({ theme }) => theme.margin.small};
  margin-bottom: ${({ theme }) => theme.margin.small};
`;

const NotSet = <NotSetText fontSize="small" color="error" t="common.notSet" />;

interface MetaFieldValuesProps {
  field: MetaField;
  metadataValues: MetaFieldValuesFragment[];
}

export default ({ field, metadataValues }: MetaFieldValuesProps) => {
  const { formatDate } = useDateFns();
  const values = metadataValues.find(({ fieldId }) => fieldId === field.id);

  if (!values) {
    return NotSet;
  }

  if (
    isTagListMeta(field) ||
    isSingleChoiceMeta(field) ||
    isMultipleChoiceMeta(field)
  ) {
    const { textValues } = values;
    return textValues.length === 0 ? (
      NotSet
    ) : (
      <Row>
        {textValues.map(({ value }) => (
          <TagValue key={value} label={value} />
        ))}
      </Row>
    );
  }

  if (isSimpleTextMeta(field)) {
    const value = head(values.textValues)?.value;
    return !value ? (
      NotSet
    ) : (
      <SimpleTextComponent fontSize="small">{value}</SimpleTextComponent>
    );
  }

  if (isBooleanMeta(field)) {
    const value = head(values.booleanValues)?.value;
    return typeof value !== "boolean" ? (
      NotSet
    ) : (
      <SimpleText format={toUpper} t={value ? "common.yes" : "common.no"}>
        {value}
      </SimpleText>
    );
  }

  if (isDateMeta(field)) {
    const value = head(values.dateValues)?.value;
    return !value ? (
      NotSet
    ) : (
      <SimpleText>{formatDate(new Date(value))}</SimpleText>
    );
  }

  if (isNumberMeta(field)) {
    const value = head(values.numberValues)?.value;
    return typeof value !== "number" ? (
      NotSet
    ) : (
      <SimpleText>{value}</SimpleText>
    );
  }

  return NotSet;
};
