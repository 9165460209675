/* eslint-disable no-console */
import { message } from "antd";
import defaultMetadataToState from "components/assetMetadata/functions/defaultMetadataToState";
import useRequiredFieldsCheck from "components/assetMetadata/hooks/useRequiredFieldsCheck";
import { AssetMetaFieldsState } from "components/assetMetadata/stateMapper";
import strings from "localisation/strings";
import {
  contains,
  dissoc,
  fromPairs,
  isEmpty,
  pair,
  pluck,
  values,
  without,
} from "ramda";
import { useEffect, useState } from "react";
import { isDefined } from "types/predicates";
import { createContainer } from "unstated-next";
import uuid from "uuid/v4";
import { S3UploadedFile } from "./functions/patchAwsPlugin";
import useCreateMedia from "./hooks/useCreateMedia";
import UserState from "./UserState";
import { useMetadataFieldsSubscription } from "__gen__/appService";

interface UploadBatch {
  id: string;
  orgId: string;
  fileIds: string[];
  createdAt: Date;
  metadataState: AssetMetaFieldsState;
  isBeingEdited: boolean;
}

interface BatchesState {
  [batchId: string]: UploadBatch;
}

let lastWarningTime = Date.now();

export default createContainer(() => {
  const [batches, setBatches] = useState<BatchesState>({});
  const { selectedOrgId } = UserState.useContainer();
  const { checkFields } = useRequiredFieldsCheck();
  const [finishedFiles, setFinishedFiles] = useState<S3UploadedFile[]>([]);
  const { data } = useMetadataFieldsSubscription({ orgId: selectedOrgId });
  const { createMedia } = useCreateMedia();
  const metaFields = (data && data.metadataFields) || [];

  const batchList = values(batches);

  /**
   * INTERNAL
   */

  const updateBatch = (batchId: string, newBatch: Partial<UploadBatch>) => {
    const batch = getUploadBatch(batchId);
    if (batch) {
      setBatches((current) => ({
        ...current,
        [batchId]: {
          ...batch,
          ...newBatch,
        },
      }));
    }
  };

  const findBatchByFileId = (fileId: string) => {
    return batchList.find(({ fileIds }) => {
      return fileIds.indexOf(fileId) > -1;
    });
  };

  /**
   * EXTERNAL
   */

  const createUploadBatch = (fileIds: string[]): string => {
    const batch: UploadBatch = {
      fileIds,
      orgId: selectedOrgId,
      createdAt: new Date(),
      id: uuid(),
      metadataState: defaultMetadataToState(metaFields),
      isBeingEdited: true,
    };

    setBatches((current) => ({
      ...current,
      [batch.id]: batch,
    }));

    return batch.id;
  };

  const getBatchFiles = (batchId: string) => {
    const batch = getUploadBatch(batchId);
    return batch ? batch.fileIds : [];
  };

  const getMetaForFile = (fileId: string) => {
    const batch = findBatchByFileId(fileId);
    if (batch) {
      return batch.metadataState;
    }
  };
  const removeFiles = (fileIds: string[]) => {
    removeFilesFromBatch(fileIds);
    removeFinishedFiles(fileIds);
  };

  const removeFilesFromBatch = (removedFileIds: string[]) => {
    const newState: BatchesState = fromPairs(
      batchList
        .map((batch) => {
          const newIds = without(removedFileIds, batch.fileIds);
          if (!isEmpty(newIds)) {
            return pair(batch.id, {
              ...batch,
              fileIds: newIds,
            });
          }
          return undefined;
        })
        .filter(isDefined),
    );
    setBatches(newState);
  };

  const removeFinishedFiles = (fileIds: string[]) => {
    setFinishedFiles((current) =>
      current.filter(({ id }) => !contains(id, fileIds)),
    );
  };

  const onUploadFinished = async (file: S3UploadedFile) => {
    setFinishedFiles((currentFiles) => [...currentFiles, file]);
    const batch = findBatchByFileId(file.id);
    if (!batch) {
      console.error(new Error("File does not exist in any batch"));
      return;
    }
    const metadata = await checkBatchMetadata(batch);
    if (!metadata) {
      showWarning();
      return;
    }
    createFile(file, metadata);
  };

  const showWarning = () => {
    if (Date.now() - lastWarningTime > 3000) {
      lastWarningTime = Date.now();
      message.warn(strings("uploadNotification.missingMeta"));
    }
  };

  const checkBatchMetadata = async (batch: UploadBatch) => {
    const noRequiredMetaMissing = await checkFields(
      batch.metadataState,
      batch.orgId,
    );
    if (noRequiredMetaMissing) {
      return batch.metadataState;
    }
  };

  const getFinishedBatchFiles = (batch: UploadBatch) => {
    const fileIds = getBatchFiles(batch.id);
    return finishedFiles.filter(({ id }) => contains(id, fileIds));
  };

  const createFinishedFiles = async (batch: UploadBatch) => {
    const files = getFinishedBatchFiles(batch);
    const fileIds = pluck("id", files);
    if (files.length === 0) {
      return;
    }
    const metadata = await checkBatchMetadata(batch);
    if (!metadata) {
      return;
    }
    try {
      await Promise.all(
        files.map(async (file) => await createMedia(file, metadata)),
      );
      removeFiles(fileIds);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    values(batches).forEach(createFinishedFiles);
    // eslint-disable-next-line
  }, [JSON.stringify(values(batches).map(dissoc("isBeingEdited")))]);

  const createFile = async (
    file: S3UploadedFile,
    metadataState: AssetMetaFieldsState,
  ) => {
    try {
      await createMedia(file, metadataState);
      removeFiles([file.id]);
    } catch (err) {
      console.error(err);
    }
  };

  const getUploadBatch = (batchId: string): UploadBatch | undefined => {
    return batches[batchId];
  };

  return {
    createUploadBatch,
    getMetaForFile,
    removeFiles,
    getUploadBatch,
    getBatchFiles,
    batchList,
    onUploadFinished,
    findBatchByFileId,
    updateBatch,
  };
});
