import gatewayLogin from "appGateway/gatewayLogin";
import authServiceClient from "authService/authGatewayClient";
import {
  EmailSignupDocument,
  EmailSignupMutation,
  EmailSignupMutationVariables,
} from "__gen__/authGatewaySdk";

export default async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  let authToken;
  const { data } = await authServiceClient.mutate<
    EmailSignupMutation,
    EmailSignupMutationVariables
  >({
    mutation: EmailSignupDocument,
    variables: { email, password },
  });

  authToken = data && data.signupEmailPassword.token;
  return await gatewayLogin(authToken);
};
