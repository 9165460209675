import { MetaField } from "components/assetMetaSpecs/MetadataFieldTypes";
import qs from "qs";
import { MediaAssetsBoolExp } from "__gen__/appService";
import isConditionComplete from "./functions/isConditionComplete";
import metadataToSearchQuery from "./functions/metadataToSearchQuery";
import { emptySearchState, SearchState, SerializedState } from "./types";

export const serializeSearch = (state: SearchState): SerializedState => {
  const newState: SerializedState = {
    ...state,
    metaConditions: state.metaConditions.map(({ field, values, ...rest }) => ({
      field: field.id || "",
      values: {
        ...values,
        dateValues: values.dateValues?.map((d) => d.toISOString()),
      } as any,
      ...rest,
    })),
  };
  return newState;
};

export const deserializeSearch = (
  state: SerializedState,
  fields: MetaField[],
): SearchState => {
  const newState: SearchState = {
    ...state,
    metaConditions: state.metaConditions
      .map(({ field, values, ...rest }) => {
        return {
          field: fields.find(({ id }) => id === field),
          values: {
            ...values,
            dateValues: values.dateValues?.map((d) => new Date(d)),
            numberValues: values.numberValues?.map((d) => parseFloat(d as any)),
            booleanValues: values.booleanValues?.map((d) =>
              (d as any) === "false" ? false : true,
            ),
          } as any,
          ...rest,
        };
      })
      .filter(isConditionComplete),
  };
  return newState;
};

export const searchToQueryString = (state: SearchState): string => {
  const { folder, ...queryState } = serializeSearch(state);
  const str = qs.stringify(queryState, { addQueryPrefix: true });
  return str;
};

export const queryStringToSerializedState = (
  query: string,
): SerializedState => {
  const parsed = qs.parse(query, { ignoreQueryPrefix: true });
  return { ...serializeSearch(emptySearchState), ...parsed };
};

export const queryStringToSearchState = (
  query: string,
  fields: MetaField[],
) => {
  const serialized = queryStringToSerializedState(query);
  return deserializeSearch(serialized, fields);
};

export const queryStringToGraphql = (query: string): MediaAssetsBoolExp => {
  const { q, metaConditions } = queryStringToSerializedState(query);

  if (!q && metaConditions.length === 0) {
    return {};
  }

  const metaExpressions = metaConditions.map<MediaAssetsBoolExp>(
    ({ field, values, operator }) => {
      return {
        meta_field_values: {
          _and: [
            { field_id: { _eq: field } },
            metadataToSearchQuery(operator, values),
          ],
        },
      };
    },
  );

  const metaQuery = metaExpressions.length > 0 ? { _or: metaExpressions } : {};
  const searchQuery = { _ilike: `%${q}%` };

  return {
    _and: [
      {
        _or: [
          { name: searchQuery },
          {
            meta_field_values: { text_values: { value: searchQuery } },
          },
        ],
      },
      metaQuery,
    ],
  };
};
