import { Column, Row } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import React, { ReactNode } from "react";
import { ValidationResultError } from "../functions/validation/types";
import validateField from "../functions/validation/validateField";
import FieldDescription from "./FieldDescription";
import { ResourceInputProps } from "./types";

export default ({
  children,
  ...props
}: ResourceInputProps & { children: ReactNode }) => {
  const {
    noWrapper,
    isList,
    isRequired,
    resolveValue,
    locale,
    dataPath,
    composer,
    isNewResource,
  } = props;

  if (noWrapper) {
    return <>{children}</>;
  }

  const value = resolveValue({ locale, dataPath });
  const validationResults = validateField({
    value,
    fieldPath: dataPath,
    fieldType: composer,
    isList,
    isRequired,
  });

  const validationErrors = validationResults.filter(
    ({ isValid }) => !isValid,
  ) as ValidationResultError[];

  return (
    <Row grow={0} marginVertical="medium">
      <Column grow={0} style={{ flexBasis: 200, flexShrink: 0 }}>
        <Row>
          <FieldDescription {...props} />
        </Row>
        {!isNewResource
          ? validationErrors.map((error) => (
              <SimpleText color="error">{error.error}</SimpleText>
            ))
          : null}
      </Column>
      {children}
    </Row>
  );
};
