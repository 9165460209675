import { Table } from "antd";
import {
  compareBooleanProps,
  compareStringProps,
} from "components/assetList/assetSorters";
import { Column } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import strings from "localisation/strings";
import { toUpper } from "ramda";
import React from "react";
import useLiveStreamSidebar from "state/hooks/useLiveStreamSidebar";
import useUserState from "state/hooks/useUserState";
import styled from "styled-components";
import {
  LiveStreamsSubscription,
  useLiveStreamsSubscription,
} from "__gen__/appService";

const highlightedRowClassname = "vl-row-highlighted";
const rowClassname = "vl-row";
const Container = styled(Column)`
  overflow-x: auto;

  .${rowClassname} {
    :hover {
      cursor: pointer;
    }
  }

  .${highlightedRowClassname} {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
`;

type Stream = LiveStreamsSubscription["liveStreams"][0];

export default () => {
  const { selectedOrgId } = useUserState();
  const { data } = useLiveStreamsSubscription({
    orgId: selectedOrgId,
  });
  const { openLiveStreamDetails } = useLiveStreamSidebar();

  const openStreamDetailsSidebar = (stream: Stream) => {
    openLiveStreamDetails(stream.streamKey);
  };

  return (
    <Container marginVertical="medium">
      <Table<Stream>
        onRow={(stream) => ({
          onClick: () => openStreamDetailsSidebar(stream),
        })}
        dataSource={(data?.liveStreams || []).map((stream) => ({
          ...stream,
          key: stream.streamKey,
        }))}
        rowClassName={rowClassname}
        columns={[
          {
            title: strings("common.title"),
            dataIndex: "title",
            key: "title",
            sorter: compareStringProps("title"),
          },
          {
            title: strings("common.streamKey"),
            dataIndex: "streamKey",
            key: "streamKey",
            sorter: compareStringProps("streamKey"),
          },
          {
            title: strings("common.status"),
            dataIndex: "status",
            key: "status",
            sorter: compareStringProps("status"),
          },
          {
            title: strings("common.transcode"),
            dataIndex: "transcode",
            key: "transcode",
            sorter: compareBooleanProps("transcode"),
            render: (_, { transcode }) => (
              <SimpleText
                format={toUpper}
                t={transcode ? "common.yes" : "common.no"}
              >
                {transcode}
              </SimpleText>
            ),
          },
          {
            title: strings("common.dvrLiveRewind"),
            dataIndex: "proto",
            key: "proto",
            render: (_, { proto }) => (
              <SimpleText
                format={toUpper}
                t={proto?.dvr?.liveRewind ? "common.yes" : "common.no"}
              >
                {proto?.dvr?.liveRewind}
              </SimpleText>
            ),
          },
          {
            title: strings("common.notes"),
            dataIndex: "notes",
            key: "notes",
            render: (_, { proto }) => (
              <SimpleText style={{ whiteSpace: "pre" }}>
                {proto?.notes}
              </SimpleText>
            ),
          },
        ]}
      />
    </Container>
  );
};
