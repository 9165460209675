import { generatePath } from "react-router";

const createRoute = (path: string) => ({
  path,
  withParams: () => path,
  params: {},
});

const createParamRoute = <T>(path: string) => ({
  path,
  withParams: (params: T) => generatePath(path, params as any),
  params: {} as T,
});

export interface WithOrgSlug {
  orgSlug: string;
}

export interface WithProjectSlug {
  projectSlug: string;
}

export interface WithProjectId {
  projectId: string;
}

export interface WithFolderId {
  folderId: string;
}

export interface WithToken {
  token: string;
}

export interface WithRootKey {
  rootKey: string;
}

export interface WithMemberId {
  memberId: string;
}

export interface WithTeamId {
  teamId: string;
}

export interface WithRoleId {
  roleId: string;
}

const Paths = {
  // MAINTENANCE
  maintenance: createRoute("/maintenance"),
  // LOGIN AND REGISTER
  login: createRoute("/login"),
  register: createRoute("/register"),
  forgotPassword: createRoute("/forgotPassword"),
  resetPassword: createParamRoute<WithToken>("/resetPassword/:token"),
  emailVerification: createParamRoute<WithToken>("/email/verify/:token"),
  // ACCOUNT
  account: createRoute("/account"),
  // SIDEBAR
  dashboard: createRoute("/dashboard"),
  organizations: createRoute("/organizations"),
  projects: createRoute("/projects"),
  users: createRoute("/users"),
  packages: createRoute("/packages"),
  // ORGANIZATION
  newOrganization: createRoute("/organizations/new"),
  accountOrganization: createParamRoute<WithOrgSlug>("/account/:orgSlug"),
  orgTab: createParamRoute<WithOrgSlug & { tabKey?: string }>(
    "/account/:orgSlug/:tabKey",
  ),
  organizationDetails: createParamRoute<WithOrgSlug>(
    "/organizations/:orgSlug/details",
  ),
  orgMemberDetails: createParamRoute<WithOrgSlug & WithMemberId>(
    "/account/:orgSlug/members/:memberId",
  ),
  orgTeamDetails: createParamRoute<WithOrgSlug & WithTeamId>(
    "/account/:orgSlug/teams/:teamId",
  ),
  orgRoleDetails: createParamRoute<WithOrgSlug & WithRoleId>(
    "/account/:orgSlug/roles/:roleId",
  ),
  // ASSETS
  orgMediaAssets: createRoute("/assets"),
  orgLiveStreams: createRoute("/streams"),
  binnedMediaAssets: createRoute("/assets/bin"),
  assetsSearch: createRoute("/assets/search"),
  folderMediaAssets: createParamRoute<WithFolderId>("/assets/f/:folderId"),
  projectCms: createParamRoute<WithProjectId>("/projects/:projectId/cms"),
  siteManager: createRoute("/cms"),
};

export default Paths;
