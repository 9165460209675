import { Row } from "components/common/layout/Flex";
import PageBuilder from "components/pageBuilder/PageBuilder";
import { isUnionType } from "graphql";
import React, { FC, ReactNode } from "react";
import { StringMapped } from "types/common";
import { isObjectType } from "vl-app-client/lib/cms/schema/getObjectTypes";
import directivesToObject from "../functions/directivesToObject";
import { isEnumType } from "../functions/typePredicates";
import FieldDescription from "../inputs/FieldDescription";
import { ResourceInputProps } from "../inputs/types";
import BooleanPreview from "./BooleanPreview";
import DateAndTimePreview from "./DateAndTimePreview";
import DatePreview from "./DatePreview";
import EmbedCodePreview from "./EmbedCodePreview";
import EnumPreview from "./EnumPreview";
import ObjectTypePreview from "./ObjectTypePreview";
import PosterUploadPreview from "./PosterUploadPreview";
import RefTablePreview from "./RefTablePreview";
import TextPreview from "./TextPreview";
import UnionPreview from "./UnionPreview";

const previewComponentMap: StringMapped<FC<ResourceInputProps>> = {
  dateAndTime: DateAndTimePreview,
  posterUpload: PosterUploadPreview,
  embedCode: EmbedCodePreview,
};

export default (props: ResourceInputProps) => {
  const { composer, fieldConfig, noWrapper, contentRow } = props;
  let previewContent: ReactNode = null;
  const directives = directivesToObject(fieldConfig?.astNode?.directives);
  const inputType = directives.input?.type;
  const preview = directives.preview;

  if (composer.getTypeName() === "String") {
    previewContent = <TextPreview {...props} />;
  }

  if (composer.getTypeName() === "VlPage") {
    return <PageBuilder {...props} />;
  }

  if (composer.getTypeName() === "Boolean") {
    previewContent = <BooleanPreview {...props} />;
  }

  if (!!inputType) {
    const PreviewComponent = previewComponentMap[inputType];

    if (PreviewComponent) {
      return <PreviewComponent {...props} />;
    }
  }

  if (isUnionType(composer.getType())) {
    return <UnionPreview {...props} composer={props.composer as any} />;
  }

  if (isEnumType(composer)) {
    previewContent = <EnumPreview {...props} composer={composer} />;
  }

  if (!!directives.table) {
    return <RefTablePreview {...props} />;
  }

  if (isObjectType(composer)) {
    return <ObjectTypePreview {...props} composer={composer} />;
  }

  if (preview?.type === "date") {
    previewContent = <DatePreview value={contentRow?.createdAt} />;
  }

  if (noWrapper) {
    return <>{previewContent}</>;
  }

  return (
    <Row grow={0} marginVertical="small">
      <FieldDescription {...props} isPreview />
      {previewContent}
    </Row>
  );
};
