import gql from "graphql-tag";
import * as ApolloReactHooks from "components/hooks/apollo";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  jsonb: any;
  timestamptz: string;
  uuid: string;
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export interface BooleanComparisonExp {
  _eq?: Maybe<Scalars["Boolean"]>;
  _gt?: Maybe<Scalars["Boolean"]>;
  _gte?: Maybe<Scalars["Boolean"]>;
  _in?: Maybe<Array<Scalars["Boolean"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Boolean"]>;
  _lte?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<Scalars["Boolean"]>;
  _nin?: Maybe<Array<Scalars["Boolean"]>>;
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface IntComparisonExp {
  _eq?: Maybe<Scalars["Int"]>;
  _gt?: Maybe<Scalars["Int"]>;
  _gte?: Maybe<Scalars["Int"]>;
  _in?: Maybe<Array<Scalars["Int"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Int"]>;
  _lte?: Maybe<Scalars["Int"]>;
  _neq?: Maybe<Scalars["Int"]>;
  _nin?: Maybe<Array<Scalars["Int"]>>;
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface StringComparisonExp {
  _eq?: Maybe<Scalars["String"]>;
  _gt?: Maybe<Scalars["String"]>;
  _gte?: Maybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars["String"]>;
  _in?: Maybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars["String"]>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars["String"]>;
  _lt?: Maybe<Scalars["String"]>;
  _lte?: Maybe<Scalars["String"]>;
  _neq?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars["String"]>;
  _nin?: Maybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "app_oauth_providers" */
export interface AppOauthProviders {
  /** An object relationship */
  app: Apps;
  app_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  oauth_provider: OauthProvider;
  oauth_provider_id: Scalars["uuid"];
}

/** aggregated selection of "app_oauth_providers" */
export interface AppOauthProvidersAggregate {
  aggregate?: Maybe<AppOauthProvidersAggregateFields>;
  nodes: Array<AppOauthProviders>;
}

export interface AppOauthProvidersAggregateBoolExp {
  count?: Maybe<AppOauthProvidersAggregateBoolExpCount>;
}

export interface AppOauthProvidersAggregateBoolExpCount {
  arguments?: Maybe<Array<AppOauthProvidersSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<AppOauthProvidersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "app_oauth_providers" */
export interface AppOauthProvidersAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<AppOauthProvidersMaxFields>;
  min?: Maybe<AppOauthProvidersMinFields>;
}

/** aggregate fields of "app_oauth_providers" */
export interface AppOauthProvidersAggregateFieldsCountArgs {
  columns?: Maybe<Array<AppOauthProvidersSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** order by aggregate values of table "app_oauth_providers" */
export interface AppOauthProvidersAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<AppOauthProvidersMaxOrderBy>;
  min?: Maybe<AppOauthProvidersMinOrderBy>;
}

/** input type for inserting array relation for remote table "app_oauth_providers" */
export interface AppOauthProvidersArrRelInsertInput {
  data: Array<AppOauthProvidersInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<AppOauthProvidersOnConflict>;
}

/** Boolean expression to filter rows from the table "app_oauth_providers". All fields are combined with a logical 'AND'. */
export interface AppOauthProvidersBoolExp {
  _and?: Maybe<Array<AppOauthProvidersBoolExp>>;
  _not?: Maybe<AppOauthProvidersBoolExp>;
  _or?: Maybe<Array<AppOauthProvidersBoolExp>>;
  app?: Maybe<AppsBoolExp>;
  app_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  oauth_provider?: Maybe<OauthProviderBoolExp>;
  oauth_provider_id?: Maybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "app_oauth_providers" */
export enum AppOauthProvidersConstraint {
  /** unique or primary key constraint on columns "app_id", "oauth_provider_id" */
  app_oauth_providers_app_id_oauth_provider_id_key = "app_oauth_providers_app_id_oauth_provider_id_key",
  /** unique or primary key constraint on columns "id" */
  app_oauth_providers_pkey = "app_oauth_providers_pkey",
}

/** input type for inserting data into table "app_oauth_providers" */
export interface AppOauthProvidersInsertInput {
  app?: Maybe<AppsObjRelInsertInput>;
  app_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  oauth_provider?: Maybe<OauthProviderObjRelInsertInput>;
  oauth_provider_id?: Maybe<Scalars["uuid"]>;
}

/** aggregate max on columns */
export interface AppOauthProvidersMaxFields {
  app_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  oauth_provider_id?: Maybe<Scalars["uuid"]>;
}

/** order by max() on columns of table "app_oauth_providers" */
export interface AppOauthProvidersMaxOrderBy {
  app_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  oauth_provider_id?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface AppOauthProvidersMinFields {
  app_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  oauth_provider_id?: Maybe<Scalars["uuid"]>;
}

/** order by min() on columns of table "app_oauth_providers" */
export interface AppOauthProvidersMinOrderBy {
  app_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  oauth_provider_id?: Maybe<OrderBy>;
}

/** response of any mutation on the table "app_oauth_providers" */
export interface AppOauthProvidersMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<AppOauthProviders>;
}

/** on_conflict condition type for table "app_oauth_providers" */
export interface AppOauthProvidersOnConflict {
  constraint: AppOauthProvidersConstraint;
  update_columns?: Array<AppOauthProvidersUpdateColumn>;
  where?: Maybe<AppOauthProvidersBoolExp>;
}

/** Ordering options when selecting data from "app_oauth_providers". */
export interface AppOauthProvidersOrderBy {
  app?: Maybe<AppsOrderBy>;
  app_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  oauth_provider?: Maybe<OauthProviderOrderBy>;
  oauth_provider_id?: Maybe<OrderBy>;
}

/** primary key columns input for table: app_oauth_providers */
export interface AppOauthProvidersPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "app_oauth_providers" */
export enum AppOauthProvidersSelectColumn {
  /** column name */
  app_id = "app_id",
  /** column name */
  id = "id",
  /** column name */
  oauth_provider_id = "oauth_provider_id",
}

/** input type for updating data in table "app_oauth_providers" */
export interface AppOauthProvidersSetInput {
  app_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  oauth_provider_id?: Maybe<Scalars["uuid"]>;
}

/** Streaming cursor of the table "app_oauth_providers" */
export interface AppOauthProvidersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AppOauthProvidersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AppOauthProvidersStreamCursorValueInput {
  app_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  oauth_provider_id?: Maybe<Scalars["uuid"]>;
}

/** update columns of table "app_oauth_providers" */
export enum AppOauthProvidersUpdateColumn {
  /** column name */
  app_id = "app_id",
  /** column name */
  id = "id",
  /** column name */
  oauth_provider_id = "oauth_provider_id",
}

export interface AppOauthProvidersUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<AppOauthProvidersSetInput>;
  where: AppOauthProvidersBoolExp;
}

/** columns and relationships of "app_webhook_triggers" */
export interface AppWebhookTriggers {
  trigger: Scalars["String"];
}

/** aggregated selection of "app_webhook_triggers" */
export interface AppWebhookTriggersAggregate {
  aggregate?: Maybe<AppWebhookTriggersAggregateFields>;
  nodes: Array<AppWebhookTriggers>;
}

/** aggregate fields of "app_webhook_triggers" */
export interface AppWebhookTriggersAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<AppWebhookTriggersMaxFields>;
  min?: Maybe<AppWebhookTriggersMinFields>;
}

/** aggregate fields of "app_webhook_triggers" */
export interface AppWebhookTriggersAggregateFieldsCountArgs {
  columns?: Maybe<Array<AppWebhookTriggersSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** Boolean expression to filter rows from the table "app_webhook_triggers". All fields are combined with a logical 'AND'. */
export interface AppWebhookTriggersBoolExp {
  _and?: Maybe<Array<AppWebhookTriggersBoolExp>>;
  _not?: Maybe<AppWebhookTriggersBoolExp>;
  _or?: Maybe<Array<AppWebhookTriggersBoolExp>>;
  trigger?: Maybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "app_webhook_triggers" */
export enum AppWebhookTriggersConstraint {
  /** unique or primary key constraint on columns "trigger" */
  app_webhook_triggers_pkey = "app_webhook_triggers_pkey",
}

export enum AppWebhookTriggersEnum {
  CLAIMS_REFRESH_REQUESTED = "CLAIMS_REFRESH_REQUESTED",
  USER_CREATED = "USER_CREATED",
  USER_LOGGED_IN = "USER_LOGGED_IN",
}

/** Boolean expression to compare columns of type "app_webhook_triggers_enum". All fields are combined with logical 'AND'. */
export interface AppWebhookTriggersEnumComparisonExp {
  _eq?: Maybe<AppWebhookTriggersEnum>;
  _in?: Maybe<Array<AppWebhookTriggersEnum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<AppWebhookTriggersEnum>;
  _nin?: Maybe<Array<AppWebhookTriggersEnum>>;
}

/** input type for inserting data into table "app_webhook_triggers" */
export interface AppWebhookTriggersInsertInput {
  trigger?: Maybe<Scalars["String"]>;
}

/** aggregate max on columns */
export interface AppWebhookTriggersMaxFields {
  trigger?: Maybe<Scalars["String"]>;
}

/** aggregate min on columns */
export interface AppWebhookTriggersMinFields {
  trigger?: Maybe<Scalars["String"]>;
}

/** response of any mutation on the table "app_webhook_triggers" */
export interface AppWebhookTriggersMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<AppWebhookTriggers>;
}

/** on_conflict condition type for table "app_webhook_triggers" */
export interface AppWebhookTriggersOnConflict {
  constraint: AppWebhookTriggersConstraint;
  update_columns?: Array<AppWebhookTriggersUpdateColumn>;
  where?: Maybe<AppWebhookTriggersBoolExp>;
}

/** Ordering options when selecting data from "app_webhook_triggers". */
export interface AppWebhookTriggersOrderBy {
  trigger?: Maybe<OrderBy>;
}

/** primary key columns input for table: app_webhook_triggers */
export interface AppWebhookTriggersPkColumnsInput {
  trigger: Scalars["String"];
}

/** select columns of table "app_webhook_triggers" */
export enum AppWebhookTriggersSelectColumn {
  /** column name */
  trigger = "trigger",
}

/** input type for updating data in table "app_webhook_triggers" */
export interface AppWebhookTriggersSetInput {
  trigger?: Maybe<Scalars["String"]>;
}

/** Streaming cursor of the table "app_webhook_triggers" */
export interface AppWebhookTriggersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AppWebhookTriggersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AppWebhookTriggersStreamCursorValueInput {
  trigger?: Maybe<Scalars["String"]>;
}

/** update columns of table "app_webhook_triggers" */
export enum AppWebhookTriggersUpdateColumn {
  /** column name */
  trigger = "trigger",
}

export interface AppWebhookTriggersUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<AppWebhookTriggersSetInput>;
  where: AppWebhookTriggersBoolExp;
}

/** columns and relationships of "app_webhooks" */
export interface AppWebhooks {
  app_id: Scalars["uuid"];
  id: Scalars["uuid"];
  trigger: AppWebhookTriggersEnum;
  url: Scalars["String"];
}

/** aggregated selection of "app_webhooks" */
export interface AppWebhooksAggregate {
  aggregate?: Maybe<AppWebhooksAggregateFields>;
  nodes: Array<AppWebhooks>;
}

export interface AppWebhooksAggregateBoolExp {
  count?: Maybe<AppWebhooksAggregateBoolExpCount>;
}

export interface AppWebhooksAggregateBoolExpCount {
  arguments?: Maybe<Array<AppWebhooksSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<AppWebhooksBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "app_webhooks" */
export interface AppWebhooksAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<AppWebhooksMaxFields>;
  min?: Maybe<AppWebhooksMinFields>;
}

/** aggregate fields of "app_webhooks" */
export interface AppWebhooksAggregateFieldsCountArgs {
  columns?: Maybe<Array<AppWebhooksSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** order by aggregate values of table "app_webhooks" */
export interface AppWebhooksAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<AppWebhooksMaxOrderBy>;
  min?: Maybe<AppWebhooksMinOrderBy>;
}

/** input type for inserting array relation for remote table "app_webhooks" */
export interface AppWebhooksArrRelInsertInput {
  data: Array<AppWebhooksInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<AppWebhooksOnConflict>;
}

/** Boolean expression to filter rows from the table "app_webhooks". All fields are combined with a logical 'AND'. */
export interface AppWebhooksBoolExp {
  _and?: Maybe<Array<AppWebhooksBoolExp>>;
  _not?: Maybe<AppWebhooksBoolExp>;
  _or?: Maybe<Array<AppWebhooksBoolExp>>;
  app_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  trigger?: Maybe<AppWebhookTriggersEnumComparisonExp>;
  url?: Maybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "app_webhooks" */
export enum AppWebhooksConstraint {
  /** unique or primary key constraint on columns "id" */
  app_webhooks_pkey = "app_webhooks_pkey",
}

/** input type for inserting data into table "app_webhooks" */
export interface AppWebhooksInsertInput {
  app_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  trigger?: Maybe<AppWebhookTriggersEnum>;
  url?: Maybe<Scalars["String"]>;
}

/** aggregate max on columns */
export interface AppWebhooksMaxFields {
  app_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  url?: Maybe<Scalars["String"]>;
}

/** order by max() on columns of table "app_webhooks" */
export interface AppWebhooksMaxOrderBy {
  app_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface AppWebhooksMinFields {
  app_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  url?: Maybe<Scalars["String"]>;
}

/** order by min() on columns of table "app_webhooks" */
export interface AppWebhooksMinOrderBy {
  app_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
}

/** response of any mutation on the table "app_webhooks" */
export interface AppWebhooksMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<AppWebhooks>;
}

/** on_conflict condition type for table "app_webhooks" */
export interface AppWebhooksOnConflict {
  constraint: AppWebhooksConstraint;
  update_columns?: Array<AppWebhooksUpdateColumn>;
  where?: Maybe<AppWebhooksBoolExp>;
}

/** Ordering options when selecting data from "app_webhooks". */
export interface AppWebhooksOrderBy {
  app_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  trigger?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
}

/** primary key columns input for table: app_webhooks */
export interface AppWebhooksPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "app_webhooks" */
export enum AppWebhooksSelectColumn {
  /** column name */
  app_id = "app_id",
  /** column name */
  id = "id",
  /** column name */
  trigger = "trigger",
  /** column name */
  url = "url",
}

/** input type for updating data in table "app_webhooks" */
export interface AppWebhooksSetInput {
  app_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  trigger?: Maybe<AppWebhookTriggersEnum>;
  url?: Maybe<Scalars["String"]>;
}

/** Streaming cursor of the table "app_webhooks" */
export interface AppWebhooksStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AppWebhooksStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AppWebhooksStreamCursorValueInput {
  app_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  trigger?: Maybe<AppWebhookTriggersEnum>;
  url?: Maybe<Scalars["String"]>;
}

/** update columns of table "app_webhooks" */
export enum AppWebhooksUpdateColumn {
  /** column name */
  app_id = "app_id",
  /** column name */
  id = "id",
  /** column name */
  trigger = "trigger",
  /** column name */
  url = "url",
}

export interface AppWebhooksUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<AppWebhooksSetInput>;
  where: AppWebhooksBoolExp;
}

/** columns and relationships of "apps" */
export interface Apps {
  /** An array relationship */
  app_oauth_providers: Array<AppOauthProviders>;
  /** An aggregate relationship */
  app_oauth_providers_aggregate: AppOauthProvidersAggregate;
  /** fetch data from the table: "app_webhooks" */
  app_webhooks: Array<AppWebhooks>;
  /** fetch aggregated fields from the table: "app_webhooks" */
  app_webhooks_aggregate: AppWebhooksAggregate;
  cors_allowed: Scalars["jsonb"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  public_info?: Maybe<Scalars["jsonb"]>;
  requires_email_verification: Scalars["Boolean"];
  updated_at: Scalars["timestamptz"];
}

/** columns and relationships of "apps" */
export interface AppsAppOauthProvidersArgs {
  distinct_on?: Maybe<Array<AppOauthProvidersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppOauthProvidersOrderBy>>;
  where?: Maybe<AppOauthProvidersBoolExp>;
}

/** columns and relationships of "apps" */
export interface AppsAppOauthProvidersAggregateArgs {
  distinct_on?: Maybe<Array<AppOauthProvidersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppOauthProvidersOrderBy>>;
  where?: Maybe<AppOauthProvidersBoolExp>;
}

/** columns and relationships of "apps" */
export interface AppsAppWebhooksArgs {
  distinct_on?: Maybe<Array<AppWebhooksSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppWebhooksOrderBy>>;
  where?: Maybe<AppWebhooksBoolExp>;
}

/** columns and relationships of "apps" */
export interface AppsAppWebhooksAggregateArgs {
  distinct_on?: Maybe<Array<AppWebhooksSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppWebhooksOrderBy>>;
  where?: Maybe<AppWebhooksBoolExp>;
}

/** columns and relationships of "apps" */
export interface AppsCorsAllowedArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "apps" */
export interface AppsPublicInfoArgs {
  path?: Maybe<Scalars["String"]>;
}

/** aggregated selection of "apps" */
export interface AppsAggregate {
  aggregate?: Maybe<AppsAggregateFields>;
  nodes: Array<Apps>;
}

/** aggregate fields of "apps" */
export interface AppsAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<AppsMaxFields>;
  min?: Maybe<AppsMinFields>;
}

/** aggregate fields of "apps" */
export interface AppsAggregateFieldsCountArgs {
  columns?: Maybe<Array<AppsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface AppsAppendInput {
  cors_allowed?: Maybe<Scalars["jsonb"]>;
  public_info?: Maybe<Scalars["jsonb"]>;
}

/** Boolean expression to filter rows from the table "apps". All fields are combined with a logical 'AND'. */
export interface AppsBoolExp {
  _and?: Maybe<Array<AppsBoolExp>>;
  _not?: Maybe<AppsBoolExp>;
  _or?: Maybe<Array<AppsBoolExp>>;
  app_oauth_providers?: Maybe<AppOauthProvidersBoolExp>;
  app_oauth_providers_aggregate?: Maybe<AppOauthProvidersAggregateBoolExp>;
  app_webhooks?: Maybe<AppWebhooksBoolExp>;
  app_webhooks_aggregate?: Maybe<AppWebhooksAggregateBoolExp>;
  cors_allowed?: Maybe<JsonbComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  public_info?: Maybe<JsonbComparisonExp>;
  requires_email_verification?: Maybe<BooleanComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "apps" */
export enum AppsConstraint {
  /** unique or primary key constraint on columns "id" */
  apps_pkey = "apps_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface AppsDeleteAtPathInput {
  cors_allowed?: Maybe<Array<Scalars["String"]>>;
  public_info?: Maybe<Array<Scalars["String"]>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface AppsDeleteElemInput {
  cors_allowed?: Maybe<Scalars["Int"]>;
  public_info?: Maybe<Scalars["Int"]>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface AppsDeleteKeyInput {
  cors_allowed?: Maybe<Scalars["String"]>;
  public_info?: Maybe<Scalars["String"]>;
}

/** input type for inserting data into table "apps" */
export interface AppsInsertInput {
  app_oauth_providers?: Maybe<AppOauthProvidersArrRelInsertInput>;
  app_webhooks?: Maybe<AppWebhooksArrRelInsertInput>;
  cors_allowed?: Maybe<Scalars["jsonb"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  public_info?: Maybe<Scalars["jsonb"]>;
  requires_email_verification?: Maybe<Scalars["Boolean"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** aggregate max on columns */
export interface AppsMaxFields {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** aggregate min on columns */
export interface AppsMinFields {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** response of any mutation on the table "apps" */
export interface AppsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Apps>;
}

/** input type for inserting object relation for remote table "apps" */
export interface AppsObjRelInsertInput {
  data: AppsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<AppsOnConflict>;
}

/** on_conflict condition type for table "apps" */
export interface AppsOnConflict {
  constraint: AppsConstraint;
  update_columns?: Array<AppsUpdateColumn>;
  where?: Maybe<AppsBoolExp>;
}

/** Ordering options when selecting data from "apps". */
export interface AppsOrderBy {
  app_oauth_providers_aggregate?: Maybe<AppOauthProvidersAggregateOrderBy>;
  app_webhooks_aggregate?: Maybe<AppWebhooksAggregateOrderBy>;
  cors_allowed?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  public_info?: Maybe<OrderBy>;
  requires_email_verification?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** primary key columns input for table: apps */
export interface AppsPkColumnsInput {
  id: Scalars["uuid"];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface AppsPrependInput {
  cors_allowed?: Maybe<Scalars["jsonb"]>;
  public_info?: Maybe<Scalars["jsonb"]>;
}

/** select columns of table "apps" */
export enum AppsSelectColumn {
  /** column name */
  cors_allowed = "cors_allowed",
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  public_info = "public_info",
  /** column name */
  requires_email_verification = "requires_email_verification",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "apps" */
export interface AppsSetInput {
  cors_allowed?: Maybe<Scalars["jsonb"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  public_info?: Maybe<Scalars["jsonb"]>;
  requires_email_verification?: Maybe<Scalars["Boolean"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** Streaming cursor of the table "apps" */
export interface AppsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AppsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AppsStreamCursorValueInput {
  cors_allowed?: Maybe<Scalars["jsonb"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  public_info?: Maybe<Scalars["jsonb"]>;
  requires_email_verification?: Maybe<Scalars["Boolean"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** update columns of table "apps" */
export enum AppsUpdateColumn {
  /** column name */
  cors_allowed = "cors_allowed",
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  public_info = "public_info",
  /** column name */
  requires_email_verification = "requires_email_verification",
  /** column name */
  updated_at = "updated_at",
}

export interface AppsUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<AppsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<AppsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<AppsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<AppsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<AppsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<AppsSetInput>;
  where: AppsBoolExp;
}

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  ASC = "ASC",
  /** descending ordering of the cursor */
  DESC = "DESC",
}

/** columns and relationships of "email_clients" */
export interface EmailClients {
  app_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  host: Scalars["String"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  password: Scalars["String"];
  port: Scalars["Int"];
  updated_at: Scalars["timestamptz"];
  username: Scalars["String"];
}

/** aggregated selection of "email_clients" */
export interface EmailClientsAggregate {
  aggregate?: Maybe<EmailClientsAggregateFields>;
  nodes: Array<EmailClients>;
}

/** aggregate fields of "email_clients" */
export interface EmailClientsAggregateFields {
  avg?: Maybe<EmailClientsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<EmailClientsMaxFields>;
  min?: Maybe<EmailClientsMinFields>;
  stddev?: Maybe<EmailClientsStddevFields>;
  stddev_pop?: Maybe<EmailClientsStddevPopFields>;
  stddev_samp?: Maybe<EmailClientsStddevSampFields>;
  sum?: Maybe<EmailClientsSumFields>;
  var_pop?: Maybe<EmailClientsVarPopFields>;
  var_samp?: Maybe<EmailClientsVarSampFields>;
  variance?: Maybe<EmailClientsVarianceFields>;
}

/** aggregate fields of "email_clients" */
export interface EmailClientsAggregateFieldsCountArgs {
  columns?: Maybe<Array<EmailClientsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** aggregate avg on columns */
export interface EmailClientsAvgFields {
  port?: Maybe<Scalars["Float"]>;
}

/** Boolean expression to filter rows from the table "email_clients". All fields are combined with a logical 'AND'. */
export interface EmailClientsBoolExp {
  _and?: Maybe<Array<EmailClientsBoolExp>>;
  _not?: Maybe<EmailClientsBoolExp>;
  _or?: Maybe<Array<EmailClientsBoolExp>>;
  app_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  host?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  password?: Maybe<StringComparisonExp>;
  port?: Maybe<IntComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  username?: Maybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "email_clients" */
export enum EmailClientsConstraint {
  /** unique or primary key constraint on columns "id" */
  email_clients_pkey = "email_clients_pkey",
}

/** input type for incrementing numeric columns in table "email_clients" */
export interface EmailClientsIncInput {
  port?: Maybe<Scalars["Int"]>;
}

/** input type for inserting data into table "email_clients" */
export interface EmailClientsInsertInput {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  host?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  port?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  username?: Maybe<Scalars["String"]>;
}

/** aggregate max on columns */
export interface EmailClientsMaxFields {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  host?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  port?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  username?: Maybe<Scalars["String"]>;
}

/** aggregate min on columns */
export interface EmailClientsMinFields {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  host?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  port?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  username?: Maybe<Scalars["String"]>;
}

/** response of any mutation on the table "email_clients" */
export interface EmailClientsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<EmailClients>;
}

/** input type for inserting object relation for remote table "email_clients" */
export interface EmailClientsObjRelInsertInput {
  data: EmailClientsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<EmailClientsOnConflict>;
}

/** on_conflict condition type for table "email_clients" */
export interface EmailClientsOnConflict {
  constraint: EmailClientsConstraint;
  update_columns?: Array<EmailClientsUpdateColumn>;
  where?: Maybe<EmailClientsBoolExp>;
}

/** Ordering options when selecting data from "email_clients". */
export interface EmailClientsOrderBy {
  app_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  host?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  password?: Maybe<OrderBy>;
  port?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  username?: Maybe<OrderBy>;
}

/** primary key columns input for table: email_clients */
export interface EmailClientsPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "email_clients" */
export enum EmailClientsSelectColumn {
  /** column name */
  app_id = "app_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  host = "host",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  password = "password",
  /** column name */
  port = "port",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  username = "username",
}

/** input type for updating data in table "email_clients" */
export interface EmailClientsSetInput {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  host?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  port?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  username?: Maybe<Scalars["String"]>;
}

/** aggregate stddev on columns */
export interface EmailClientsStddevFields {
  port?: Maybe<Scalars["Float"]>;
}

/** aggregate stddev_pop on columns */
export interface EmailClientsStddevPopFields {
  port?: Maybe<Scalars["Float"]>;
}

/** aggregate stddev_samp on columns */
export interface EmailClientsStddevSampFields {
  port?: Maybe<Scalars["Float"]>;
}

/** Streaming cursor of the table "email_clients" */
export interface EmailClientsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: EmailClientsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface EmailClientsStreamCursorValueInput {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  host?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  port?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  username?: Maybe<Scalars["String"]>;
}

/** aggregate sum on columns */
export interface EmailClientsSumFields {
  port?: Maybe<Scalars["Int"]>;
}

/** update columns of table "email_clients" */
export enum EmailClientsUpdateColumn {
  /** column name */
  app_id = "app_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  host = "host",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  password = "password",
  /** column name */
  port = "port",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  username = "username",
}

export interface EmailClientsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<EmailClientsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<EmailClientsSetInput>;
  where: EmailClientsBoolExp;
}

/** aggregate var_pop on columns */
export interface EmailClientsVarPopFields {
  port?: Maybe<Scalars["Float"]>;
}

/** aggregate var_samp on columns */
export interface EmailClientsVarSampFields {
  port?: Maybe<Scalars["Float"]>;
}

/** aggregate variance on columns */
export interface EmailClientsVarianceFields {
  port?: Maybe<Scalars["Float"]>;
}

/** columns and relationships of "email_templates" */
export interface EmailTemplates {
  /** An object relationship */
  app: Apps;
  app_id: Scalars["uuid"];
  body_html_template: Scalars["String"];
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  email_client: EmailClients;
  email_client_id: Scalars["uuid"];
  email_from: Scalars["String"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  subject_template: Scalars["String"];
  updated_at: Scalars["timestamptz"];
}

/** aggregated selection of "email_templates" */
export interface EmailTemplatesAggregate {
  aggregate?: Maybe<EmailTemplatesAggregateFields>;
  nodes: Array<EmailTemplates>;
}

/** aggregate fields of "email_templates" */
export interface EmailTemplatesAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<EmailTemplatesMaxFields>;
  min?: Maybe<EmailTemplatesMinFields>;
}

/** aggregate fields of "email_templates" */
export interface EmailTemplatesAggregateFieldsCountArgs {
  columns?: Maybe<Array<EmailTemplatesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** Boolean expression to filter rows from the table "email_templates". All fields are combined with a logical 'AND'. */
export interface EmailTemplatesBoolExp {
  _and?: Maybe<Array<EmailTemplatesBoolExp>>;
  _not?: Maybe<EmailTemplatesBoolExp>;
  _or?: Maybe<Array<EmailTemplatesBoolExp>>;
  app?: Maybe<AppsBoolExp>;
  app_id?: Maybe<UuidComparisonExp>;
  body_html_template?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  email_client?: Maybe<EmailClientsBoolExp>;
  email_client_id?: Maybe<UuidComparisonExp>;
  email_from?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  subject_template?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "email_templates" */
export enum EmailTemplatesConstraint {
  /** unique or primary key constraint on columns "name", "app_id" */
  email_templates_name_app_id_key = "email_templates_name_app_id_key",
  /** unique or primary key constraint on columns "id" */
  email_templates_pkey = "email_templates_pkey",
}

/** input type for inserting data into table "email_templates" */
export interface EmailTemplatesInsertInput {
  app?: Maybe<AppsObjRelInsertInput>;
  app_id?: Maybe<Scalars["uuid"]>;
  body_html_template?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email_client?: Maybe<EmailClientsObjRelInsertInput>;
  email_client_id?: Maybe<Scalars["uuid"]>;
  email_from?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  subject_template?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** aggregate max on columns */
export interface EmailTemplatesMaxFields {
  app_id?: Maybe<Scalars["uuid"]>;
  body_html_template?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email_client_id?: Maybe<Scalars["uuid"]>;
  email_from?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  subject_template?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** aggregate min on columns */
export interface EmailTemplatesMinFields {
  app_id?: Maybe<Scalars["uuid"]>;
  body_html_template?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email_client_id?: Maybe<Scalars["uuid"]>;
  email_from?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  subject_template?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** response of any mutation on the table "email_templates" */
export interface EmailTemplatesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<EmailTemplates>;
}

/** on_conflict condition type for table "email_templates" */
export interface EmailTemplatesOnConflict {
  constraint: EmailTemplatesConstraint;
  update_columns?: Array<EmailTemplatesUpdateColumn>;
  where?: Maybe<EmailTemplatesBoolExp>;
}

/** Ordering options when selecting data from "email_templates". */
export interface EmailTemplatesOrderBy {
  app?: Maybe<AppsOrderBy>;
  app_id?: Maybe<OrderBy>;
  body_html_template?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  email_client?: Maybe<EmailClientsOrderBy>;
  email_client_id?: Maybe<OrderBy>;
  email_from?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  subject_template?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** primary key columns input for table: email_templates */
export interface EmailTemplatesPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "email_templates" */
export enum EmailTemplatesSelectColumn {
  /** column name */
  app_id = "app_id",
  /** column name */
  body_html_template = "body_html_template",
  /** column name */
  created_at = "created_at",
  /** column name */
  email_client_id = "email_client_id",
  /** column name */
  email_from = "email_from",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  subject_template = "subject_template",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "email_templates" */
export interface EmailTemplatesSetInput {
  app_id?: Maybe<Scalars["uuid"]>;
  body_html_template?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email_client_id?: Maybe<Scalars["uuid"]>;
  email_from?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  subject_template?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** Streaming cursor of the table "email_templates" */
export interface EmailTemplatesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: EmailTemplatesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface EmailTemplatesStreamCursorValueInput {
  app_id?: Maybe<Scalars["uuid"]>;
  body_html_template?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email_client_id?: Maybe<Scalars["uuid"]>;
  email_from?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  subject_template?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** update columns of table "email_templates" */
export enum EmailTemplatesUpdateColumn {
  /** column name */
  app_id = "app_id",
  /** column name */
  body_html_template = "body_html_template",
  /** column name */
  created_at = "created_at",
  /** column name */
  email_client_id = "email_client_id",
  /** column name */
  email_from = "email_from",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  subject_template = "subject_template",
  /** column name */
  updated_at = "updated_at",
}

export interface EmailTemplatesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<EmailTemplatesSetInput>;
  where: EmailTemplatesBoolExp;
}

/** columns and relationships of "email_verification_tokens" */
export interface EmailVerificationTokens {
  app_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  email_identity: IdentityEmail;
  email_identity_id: Scalars["uuid"];
  token: Scalars["uuid"];
  used: Scalars["Boolean"];
}

/** aggregated selection of "email_verification_tokens" */
export interface EmailVerificationTokensAggregate {
  aggregate?: Maybe<EmailVerificationTokensAggregateFields>;
  nodes: Array<EmailVerificationTokens>;
}

export interface EmailVerificationTokensAggregateBoolExp {
  bool_and?: Maybe<EmailVerificationTokensAggregateBoolExpBoolAnd>;
  bool_or?: Maybe<EmailVerificationTokensAggregateBoolExpBoolOr>;
  count?: Maybe<EmailVerificationTokensAggregateBoolExpCount>;
}

export interface EmailVerificationTokensAggregateBoolExpBoolAnd {
  arguments: EmailVerificationTokensSelectColumnEmailVerificationTokensAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<EmailVerificationTokensBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface EmailVerificationTokensAggregateBoolExpBoolOr {
  arguments: EmailVerificationTokensSelectColumnEmailVerificationTokensAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<EmailVerificationTokensBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface EmailVerificationTokensAggregateBoolExpCount {
  arguments?: Maybe<Array<EmailVerificationTokensSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<EmailVerificationTokensBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "email_verification_tokens" */
export interface EmailVerificationTokensAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<EmailVerificationTokensMaxFields>;
  min?: Maybe<EmailVerificationTokensMinFields>;
}

/** aggregate fields of "email_verification_tokens" */
export interface EmailVerificationTokensAggregateFieldsCountArgs {
  columns?: Maybe<Array<EmailVerificationTokensSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** order by aggregate values of table "email_verification_tokens" */
export interface EmailVerificationTokensAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<EmailVerificationTokensMaxOrderBy>;
  min?: Maybe<EmailVerificationTokensMinOrderBy>;
}

/** input type for inserting array relation for remote table "email_verification_tokens" */
export interface EmailVerificationTokensArrRelInsertInput {
  data: Array<EmailVerificationTokensInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<EmailVerificationTokensOnConflict>;
}

/** Boolean expression to filter rows from the table "email_verification_tokens". All fields are combined with a logical 'AND'. */
export interface EmailVerificationTokensBoolExp {
  _and?: Maybe<Array<EmailVerificationTokensBoolExp>>;
  _not?: Maybe<EmailVerificationTokensBoolExp>;
  _or?: Maybe<Array<EmailVerificationTokensBoolExp>>;
  app_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  email_identity?: Maybe<IdentityEmailBoolExp>;
  email_identity_id?: Maybe<UuidComparisonExp>;
  token?: Maybe<UuidComparisonExp>;
  used?: Maybe<BooleanComparisonExp>;
}

/** unique or primary key constraints on table "email_verification_tokens" */
export enum EmailVerificationTokensConstraint {
  /** unique or primary key constraint on columns "token" */
  email_verification_tokens_pkey = "email_verification_tokens_pkey",
}

/** input type for inserting data into table "email_verification_tokens" */
export interface EmailVerificationTokensInsertInput {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email_identity?: Maybe<IdentityEmailObjRelInsertInput>;
  email_identity_id?: Maybe<Scalars["uuid"]>;
  token?: Maybe<Scalars["uuid"]>;
  used?: Maybe<Scalars["Boolean"]>;
}

/** aggregate max on columns */
export interface EmailVerificationTokensMaxFields {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email_identity_id?: Maybe<Scalars["uuid"]>;
  token?: Maybe<Scalars["uuid"]>;
}

/** order by max() on columns of table "email_verification_tokens" */
export interface EmailVerificationTokensMaxOrderBy {
  app_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  email_identity_id?: Maybe<OrderBy>;
  token?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface EmailVerificationTokensMinFields {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email_identity_id?: Maybe<Scalars["uuid"]>;
  token?: Maybe<Scalars["uuid"]>;
}

/** order by min() on columns of table "email_verification_tokens" */
export interface EmailVerificationTokensMinOrderBy {
  app_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  email_identity_id?: Maybe<OrderBy>;
  token?: Maybe<OrderBy>;
}

/** response of any mutation on the table "email_verification_tokens" */
export interface EmailVerificationTokensMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<EmailVerificationTokens>;
}

/** on_conflict condition type for table "email_verification_tokens" */
export interface EmailVerificationTokensOnConflict {
  constraint: EmailVerificationTokensConstraint;
  update_columns?: Array<EmailVerificationTokensUpdateColumn>;
  where?: Maybe<EmailVerificationTokensBoolExp>;
}

/** Ordering options when selecting data from "email_verification_tokens". */
export interface EmailVerificationTokensOrderBy {
  app_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  email_identity?: Maybe<IdentityEmailOrderBy>;
  email_identity_id?: Maybe<OrderBy>;
  token?: Maybe<OrderBy>;
  used?: Maybe<OrderBy>;
}

/** primary key columns input for table: email_verification_tokens */
export interface EmailVerificationTokensPkColumnsInput {
  token: Scalars["uuid"];
}

/** select columns of table "email_verification_tokens" */
export enum EmailVerificationTokensSelectColumn {
  /** column name */
  app_id = "app_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  email_identity_id = "email_identity_id",
  /** column name */
  token = "token",
  /** column name */
  used = "used",
}

/** select "email_verification_tokens_aggregate_bool_exp_bool_and_arguments_columns" columns of table "email_verification_tokens" */
export enum EmailVerificationTokensSelectColumnEmailVerificationTokensAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  used = "used",
}

/** select "email_verification_tokens_aggregate_bool_exp_bool_or_arguments_columns" columns of table "email_verification_tokens" */
export enum EmailVerificationTokensSelectColumnEmailVerificationTokensAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  used = "used",
}

/** input type for updating data in table "email_verification_tokens" */
export interface EmailVerificationTokensSetInput {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email_identity_id?: Maybe<Scalars["uuid"]>;
  token?: Maybe<Scalars["uuid"]>;
  used?: Maybe<Scalars["Boolean"]>;
}

/** Streaming cursor of the table "email_verification_tokens" */
export interface EmailVerificationTokensStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: EmailVerificationTokensStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface EmailVerificationTokensStreamCursorValueInput {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email_identity_id?: Maybe<Scalars["uuid"]>;
  token?: Maybe<Scalars["uuid"]>;
  used?: Maybe<Scalars["Boolean"]>;
}

/** update columns of table "email_verification_tokens" */
export enum EmailVerificationTokensUpdateColumn {
  /** column name */
  app_id = "app_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  email_identity_id = "email_identity_id",
  /** column name */
  token = "token",
  /** column name */
  used = "used",
}

export interface EmailVerificationTokensUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<EmailVerificationTokensSetInput>;
  where: EmailVerificationTokensBoolExp;
}

/** columns and relationships of "identity_email" */
export interface IdentityEmail {
  app_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  email: Scalars["String"];
  email_verified: Scalars["Boolean"];
  id: Scalars["uuid"];
  password: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  user: Users;
  user_id: Scalars["uuid"];
  /** An array relationship */
  verification_tokens: Array<EmailVerificationTokens>;
  /** An aggregate relationship */
  verification_tokens_aggregate: EmailVerificationTokensAggregate;
}

/** columns and relationships of "identity_email" */
export interface IdentityEmailVerificationTokensArgs {
  distinct_on?: Maybe<Array<EmailVerificationTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EmailVerificationTokensOrderBy>>;
  where?: Maybe<EmailVerificationTokensBoolExp>;
}

/** columns and relationships of "identity_email" */
export interface IdentityEmailVerificationTokensAggregateArgs {
  distinct_on?: Maybe<Array<EmailVerificationTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EmailVerificationTokensOrderBy>>;
  where?: Maybe<EmailVerificationTokensBoolExp>;
}

/** aggregated selection of "identity_email" */
export interface IdentityEmailAggregate {
  aggregate?: Maybe<IdentityEmailAggregateFields>;
  nodes: Array<IdentityEmail>;
}

export interface IdentityEmailAggregateBoolExp {
  bool_and?: Maybe<IdentityEmailAggregateBoolExpBoolAnd>;
  bool_or?: Maybe<IdentityEmailAggregateBoolExpBoolOr>;
  count?: Maybe<IdentityEmailAggregateBoolExpCount>;
}

export interface IdentityEmailAggregateBoolExpBoolAnd {
  arguments: IdentityEmailSelectColumnIdentityEmailAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<IdentityEmailBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface IdentityEmailAggregateBoolExpBoolOr {
  arguments: IdentityEmailSelectColumnIdentityEmailAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<IdentityEmailBoolExp>;
  predicate: BooleanComparisonExp;
}

export interface IdentityEmailAggregateBoolExpCount {
  arguments?: Maybe<Array<IdentityEmailSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<IdentityEmailBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "identity_email" */
export interface IdentityEmailAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<IdentityEmailMaxFields>;
  min?: Maybe<IdentityEmailMinFields>;
}

/** aggregate fields of "identity_email" */
export interface IdentityEmailAggregateFieldsCountArgs {
  columns?: Maybe<Array<IdentityEmailSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** order by aggregate values of table "identity_email" */
export interface IdentityEmailAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<IdentityEmailMaxOrderBy>;
  min?: Maybe<IdentityEmailMinOrderBy>;
}

/** input type for inserting array relation for remote table "identity_email" */
export interface IdentityEmailArrRelInsertInput {
  data: Array<IdentityEmailInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<IdentityEmailOnConflict>;
}

/** Boolean expression to filter rows from the table "identity_email". All fields are combined with a logical 'AND'. */
export interface IdentityEmailBoolExp {
  _and?: Maybe<Array<IdentityEmailBoolExp>>;
  _not?: Maybe<IdentityEmailBoolExp>;
  _or?: Maybe<Array<IdentityEmailBoolExp>>;
  app_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  email_verified?: Maybe<BooleanComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  password?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
  verification_tokens?: Maybe<EmailVerificationTokensBoolExp>;
  verification_tokens_aggregate?: Maybe<EmailVerificationTokensAggregateBoolExp>;
}

/** unique or primary key constraints on table "identity_email" */
export enum IdentityEmailConstraint {
  /** unique or primary key constraint on columns "email", "app_id" */
  identity_email_app_id_email_key = "identity_email_app_id_email_key",
  /** unique or primary key constraint on columns "id" */
  identity_email_pkey = "identity_email_pkey",
}

/** input type for inserting data into table "identity_email" */
export interface IdentityEmailInsertInput {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  email_verified?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["uuid"]>;
  password?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user?: Maybe<UsersObjRelInsertInput>;
  user_id?: Maybe<Scalars["uuid"]>;
  verification_tokens?: Maybe<EmailVerificationTokensArrRelInsertInput>;
}

/** aggregate max on columns */
export interface IdentityEmailMaxFields {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  password?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** order by max() on columns of table "identity_email" */
export interface IdentityEmailMaxOrderBy {
  app_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  password?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface IdentityEmailMinFields {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  password?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** order by min() on columns of table "identity_email" */
export interface IdentityEmailMinOrderBy {
  app_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  password?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** response of any mutation on the table "identity_email" */
export interface IdentityEmailMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityEmail>;
}

/** input type for inserting object relation for remote table "identity_email" */
export interface IdentityEmailObjRelInsertInput {
  data: IdentityEmailInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<IdentityEmailOnConflict>;
}

/** on_conflict condition type for table "identity_email" */
export interface IdentityEmailOnConflict {
  constraint: IdentityEmailConstraint;
  update_columns?: Array<IdentityEmailUpdateColumn>;
  where?: Maybe<IdentityEmailBoolExp>;
}

/** Ordering options when selecting data from "identity_email". */
export interface IdentityEmailOrderBy {
  app_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  email_verified?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  password?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  user_id?: Maybe<OrderBy>;
  verification_tokens_aggregate?: Maybe<EmailVerificationTokensAggregateOrderBy>;
}

/** primary key columns input for table: identity_email */
export interface IdentityEmailPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "identity_email" */
export enum IdentityEmailSelectColumn {
  /** column name */
  app_id = "app_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  email = "email",
  /** column name */
  email_verified = "email_verified",
  /** column name */
  id = "id",
  /** column name */
  password = "password",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  user_id = "user_id",
}

/** select "identity_email_aggregate_bool_exp_bool_and_arguments_columns" columns of table "identity_email" */
export enum IdentityEmailSelectColumnIdentityEmailAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  email_verified = "email_verified",
}

/** select "identity_email_aggregate_bool_exp_bool_or_arguments_columns" columns of table "identity_email" */
export enum IdentityEmailSelectColumnIdentityEmailAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  email_verified = "email_verified",
}

/** input type for updating data in table "identity_email" */
export interface IdentityEmailSetInput {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  email_verified?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["uuid"]>;
  password?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** Streaming cursor of the table "identity_email" */
export interface IdentityEmailStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: IdentityEmailStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface IdentityEmailStreamCursorValueInput {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  email_verified?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["uuid"]>;
  password?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** update columns of table "identity_email" */
export enum IdentityEmailUpdateColumn {
  /** column name */
  app_id = "app_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  email = "email",
  /** column name */
  email_verified = "email_verified",
  /** column name */
  id = "id",
  /** column name */
  password = "password",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  user_id = "user_id",
}

export interface IdentityEmailUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<IdentityEmailSetInput>;
  where: IdentityEmailBoolExp;
}

/** columns and relationships of "identity_oauth" */
export interface IdentityOauth {
  app_oauth_provider_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  oauth_user_id: Scalars["String"];
  /** An object relationship */
  user: Users;
  user_data: Scalars["jsonb"];
  user_id: Scalars["uuid"];
}

/** columns and relationships of "identity_oauth" */
export interface IdentityOauthUserDataArgs {
  path?: Maybe<Scalars["String"]>;
}

/** aggregated selection of "identity_oauth" */
export interface IdentityOauthAggregate {
  aggregate?: Maybe<IdentityOauthAggregateFields>;
  nodes: Array<IdentityOauth>;
}

export interface IdentityOauthAggregateBoolExp {
  count?: Maybe<IdentityOauthAggregateBoolExpCount>;
}

export interface IdentityOauthAggregateBoolExpCount {
  arguments?: Maybe<Array<IdentityOauthSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<IdentityOauthBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "identity_oauth" */
export interface IdentityOauthAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<IdentityOauthMaxFields>;
  min?: Maybe<IdentityOauthMinFields>;
}

/** aggregate fields of "identity_oauth" */
export interface IdentityOauthAggregateFieldsCountArgs {
  columns?: Maybe<Array<IdentityOauthSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** order by aggregate values of table "identity_oauth" */
export interface IdentityOauthAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<IdentityOauthMaxOrderBy>;
  min?: Maybe<IdentityOauthMinOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface IdentityOauthAppendInput {
  user_data?: Maybe<Scalars["jsonb"]>;
}

/** input type for inserting array relation for remote table "identity_oauth" */
export interface IdentityOauthArrRelInsertInput {
  data: Array<IdentityOauthInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<IdentityOauthOnConflict>;
}

/** Boolean expression to filter rows from the table "identity_oauth". All fields are combined with a logical 'AND'. */
export interface IdentityOauthBoolExp {
  _and?: Maybe<Array<IdentityOauthBoolExp>>;
  _not?: Maybe<IdentityOauthBoolExp>;
  _or?: Maybe<Array<IdentityOauthBoolExp>>;
  app_oauth_provider_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  oauth_user_id?: Maybe<StringComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  user_data?: Maybe<JsonbComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "identity_oauth" */
export enum IdentityOauthConstraint {
  /** unique or primary key constraint on columns "id" */
  identity_oauth_pkey = "identity_oauth_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface IdentityOauthDeleteAtPathInput {
  user_data?: Maybe<Array<Scalars["String"]>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface IdentityOauthDeleteElemInput {
  user_data?: Maybe<Scalars["Int"]>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface IdentityOauthDeleteKeyInput {
  user_data?: Maybe<Scalars["String"]>;
}

/** input type for inserting data into table "identity_oauth" */
export interface IdentityOauthInsertInput {
  app_oauth_provider_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  oauth_user_id?: Maybe<Scalars["String"]>;
  user?: Maybe<UsersObjRelInsertInput>;
  user_data?: Maybe<Scalars["jsonb"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** aggregate max on columns */
export interface IdentityOauthMaxFields {
  app_oauth_provider_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  oauth_user_id?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** order by max() on columns of table "identity_oauth" */
export interface IdentityOauthMaxOrderBy {
  app_oauth_provider_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  oauth_user_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface IdentityOauthMinFields {
  app_oauth_provider_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  oauth_user_id?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** order by min() on columns of table "identity_oauth" */
export interface IdentityOauthMinOrderBy {
  app_oauth_provider_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  oauth_user_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** response of any mutation on the table "identity_oauth" */
export interface IdentityOauthMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityOauth>;
}

/** on_conflict condition type for table "identity_oauth" */
export interface IdentityOauthOnConflict {
  constraint: IdentityOauthConstraint;
  update_columns?: Array<IdentityOauthUpdateColumn>;
  where?: Maybe<IdentityOauthBoolExp>;
}

/** Ordering options when selecting data from "identity_oauth". */
export interface IdentityOauthOrderBy {
  app_oauth_provider_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  oauth_user_id?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  user_data?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** primary key columns input for table: identity_oauth */
export interface IdentityOauthPkColumnsInput {
  id: Scalars["uuid"];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface IdentityOauthPrependInput {
  user_data?: Maybe<Scalars["jsonb"]>;
}

/** select columns of table "identity_oauth" */
export enum IdentityOauthSelectColumn {
  /** column name */
  app_oauth_provider_id = "app_oauth_provider_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  oauth_user_id = "oauth_user_id",
  /** column name */
  user_data = "user_data",
  /** column name */
  user_id = "user_id",
}

/** input type for updating data in table "identity_oauth" */
export interface IdentityOauthSetInput {
  app_oauth_provider_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  oauth_user_id?: Maybe<Scalars["String"]>;
  user_data?: Maybe<Scalars["jsonb"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** Streaming cursor of the table "identity_oauth" */
export interface IdentityOauthStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: IdentityOauthStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface IdentityOauthStreamCursorValueInput {
  app_oauth_provider_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  oauth_user_id?: Maybe<Scalars["String"]>;
  user_data?: Maybe<Scalars["jsonb"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** update columns of table "identity_oauth" */
export enum IdentityOauthUpdateColumn {
  /** column name */
  app_oauth_provider_id = "app_oauth_provider_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  oauth_user_id = "oauth_user_id",
  /** column name */
  user_data = "user_data",
  /** column name */
  user_id = "user_id",
}

export interface IdentityOauthUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<IdentityOauthAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<IdentityOauthDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<IdentityOauthDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<IdentityOauthDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<IdentityOauthPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<IdentityOauthSetInput>;
  where: IdentityOauthBoolExp;
}

export interface JsonbCastExp {
  String?: Maybe<StringComparisonExp>;
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export interface JsonbComparisonExp {
  _cast?: Maybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars["jsonb"]>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars["jsonb"]>;
  _eq?: Maybe<Scalars["jsonb"]>;
  _gt?: Maybe<Scalars["jsonb"]>;
  _gte?: Maybe<Scalars["jsonb"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars["String"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars["String"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars["String"]>>;
  _in?: Maybe<Array<Scalars["jsonb"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["jsonb"]>;
  _lte?: Maybe<Scalars["jsonb"]>;
  _neq?: Maybe<Scalars["jsonb"]>;
  _nin?: Maybe<Array<Scalars["jsonb"]>>;
}

/** mutation root */
export interface MutationRoot {
  /** delete data from the table: "app_oauth_providers" */
  delete_app_oauth_providers?: Maybe<AppOauthProvidersMutationResponse>;
  /** delete single row from the table: "app_oauth_providers" */
  delete_app_oauth_providers_by_pk?: Maybe<AppOauthProviders>;
  /** delete data from the table: "app_webhook_triggers" */
  delete_app_webhook_triggers?: Maybe<AppWebhookTriggersMutationResponse>;
  /** delete single row from the table: "app_webhook_triggers" */
  delete_app_webhook_triggers_by_pk?: Maybe<AppWebhookTriggers>;
  /** delete data from the table: "app_webhooks" */
  delete_app_webhooks?: Maybe<AppWebhooksMutationResponse>;
  /** delete single row from the table: "app_webhooks" */
  delete_app_webhooks_by_pk?: Maybe<AppWebhooks>;
  /** delete data from the table: "apps" */
  delete_apps?: Maybe<AppsMutationResponse>;
  /** delete single row from the table: "apps" */
  delete_apps_by_pk?: Maybe<Apps>;
  /** delete data from the table: "email_clients" */
  delete_email_clients?: Maybe<EmailClientsMutationResponse>;
  /** delete single row from the table: "email_clients" */
  delete_email_clients_by_pk?: Maybe<EmailClients>;
  /** delete data from the table: "email_templates" */
  delete_email_templates?: Maybe<EmailTemplatesMutationResponse>;
  /** delete single row from the table: "email_templates" */
  delete_email_templates_by_pk?: Maybe<EmailTemplates>;
  /** delete data from the table: "email_verification_tokens" */
  delete_email_verification_tokens?: Maybe<EmailVerificationTokensMutationResponse>;
  /** delete single row from the table: "email_verification_tokens" */
  delete_email_verification_tokens_by_pk?: Maybe<EmailVerificationTokens>;
  /** delete data from the table: "identity_email" */
  delete_identity_email?: Maybe<IdentityEmailMutationResponse>;
  /** delete single row from the table: "identity_email" */
  delete_identity_email_by_pk?: Maybe<IdentityEmail>;
  /** delete data from the table: "identity_oauth" */
  delete_identity_oauth?: Maybe<IdentityOauthMutationResponse>;
  /** delete single row from the table: "identity_oauth" */
  delete_identity_oauth_by_pk?: Maybe<IdentityOauth>;
  /** delete data from the table: "oauth_provider" */
  delete_oauth_provider?: Maybe<OauthProviderMutationResponse>;
  /** delete single row from the table: "oauth_provider" */
  delete_oauth_provider_by_pk?: Maybe<OauthProvider>;
  /** delete data from the table: "service_accounts" */
  delete_service_accounts?: Maybe<ServiceAccountsMutationResponse>;
  /** delete single row from the table: "service_accounts" */
  delete_service_accounts_by_pk?: Maybe<ServiceAccounts>;
  /** delete data from the table: "user_refresh_tokens" */
  delete_user_refresh_tokens?: Maybe<UserRefreshTokensMutationResponse>;
  /** delete single row from the table: "user_refresh_tokens" */
  delete_user_refresh_tokens_by_pk?: Maybe<UserRefreshTokens>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<UsersMutationResponse>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "app_oauth_providers" */
  insert_app_oauth_providers?: Maybe<AppOauthProvidersMutationResponse>;
  /** insert a single row into the table: "app_oauth_providers" */
  insert_app_oauth_providers_one?: Maybe<AppOauthProviders>;
  /** insert data into the table: "app_webhook_triggers" */
  insert_app_webhook_triggers?: Maybe<AppWebhookTriggersMutationResponse>;
  /** insert a single row into the table: "app_webhook_triggers" */
  insert_app_webhook_triggers_one?: Maybe<AppWebhookTriggers>;
  /** insert data into the table: "app_webhooks" */
  insert_app_webhooks?: Maybe<AppWebhooksMutationResponse>;
  /** insert a single row into the table: "app_webhooks" */
  insert_app_webhooks_one?: Maybe<AppWebhooks>;
  /** insert data into the table: "apps" */
  insert_apps?: Maybe<AppsMutationResponse>;
  /** insert a single row into the table: "apps" */
  insert_apps_one?: Maybe<Apps>;
  /** insert data into the table: "email_clients" */
  insert_email_clients?: Maybe<EmailClientsMutationResponse>;
  /** insert a single row into the table: "email_clients" */
  insert_email_clients_one?: Maybe<EmailClients>;
  /** insert data into the table: "email_templates" */
  insert_email_templates?: Maybe<EmailTemplatesMutationResponse>;
  /** insert a single row into the table: "email_templates" */
  insert_email_templates_one?: Maybe<EmailTemplates>;
  /** insert data into the table: "email_verification_tokens" */
  insert_email_verification_tokens?: Maybe<EmailVerificationTokensMutationResponse>;
  /** insert a single row into the table: "email_verification_tokens" */
  insert_email_verification_tokens_one?: Maybe<EmailVerificationTokens>;
  /** insert data into the table: "identity_email" */
  insert_identity_email?: Maybe<IdentityEmailMutationResponse>;
  /** insert a single row into the table: "identity_email" */
  insert_identity_email_one?: Maybe<IdentityEmail>;
  /** insert data into the table: "identity_oauth" */
  insert_identity_oauth?: Maybe<IdentityOauthMutationResponse>;
  /** insert a single row into the table: "identity_oauth" */
  insert_identity_oauth_one?: Maybe<IdentityOauth>;
  /** insert data into the table: "oauth_provider" */
  insert_oauth_provider?: Maybe<OauthProviderMutationResponse>;
  /** insert a single row into the table: "oauth_provider" */
  insert_oauth_provider_one?: Maybe<OauthProvider>;
  /** insert data into the table: "service_accounts" */
  insert_service_accounts?: Maybe<ServiceAccountsMutationResponse>;
  /** insert a single row into the table: "service_accounts" */
  insert_service_accounts_one?: Maybe<ServiceAccounts>;
  /** insert data into the table: "user_refresh_tokens" */
  insert_user_refresh_tokens?: Maybe<UserRefreshTokensMutationResponse>;
  /** insert a single row into the table: "user_refresh_tokens" */
  insert_user_refresh_tokens_one?: Maybe<UserRefreshTokens>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<UsersMutationResponse>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** update data of the table: "app_oauth_providers" */
  update_app_oauth_providers?: Maybe<AppOauthProvidersMutationResponse>;
  /** update single row of the table: "app_oauth_providers" */
  update_app_oauth_providers_by_pk?: Maybe<AppOauthProviders>;
  /** update multiples rows of table: "app_oauth_providers" */
  update_app_oauth_providers_many?: Maybe<
    Array<Maybe<AppOauthProvidersMutationResponse>>
  >;
  /** update data of the table: "app_webhook_triggers" */
  update_app_webhook_triggers?: Maybe<AppWebhookTriggersMutationResponse>;
  /** update single row of the table: "app_webhook_triggers" */
  update_app_webhook_triggers_by_pk?: Maybe<AppWebhookTriggers>;
  /** update multiples rows of table: "app_webhook_triggers" */
  update_app_webhook_triggers_many?: Maybe<
    Array<Maybe<AppWebhookTriggersMutationResponse>>
  >;
  /** update data of the table: "app_webhooks" */
  update_app_webhooks?: Maybe<AppWebhooksMutationResponse>;
  /** update single row of the table: "app_webhooks" */
  update_app_webhooks_by_pk?: Maybe<AppWebhooks>;
  /** update multiples rows of table: "app_webhooks" */
  update_app_webhooks_many?: Maybe<Array<Maybe<AppWebhooksMutationResponse>>>;
  /** update data of the table: "apps" */
  update_apps?: Maybe<AppsMutationResponse>;
  /** update single row of the table: "apps" */
  update_apps_by_pk?: Maybe<Apps>;
  /** update multiples rows of table: "apps" */
  update_apps_many?: Maybe<Array<Maybe<AppsMutationResponse>>>;
  /** update data of the table: "email_clients" */
  update_email_clients?: Maybe<EmailClientsMutationResponse>;
  /** update single row of the table: "email_clients" */
  update_email_clients_by_pk?: Maybe<EmailClients>;
  /** update multiples rows of table: "email_clients" */
  update_email_clients_many?: Maybe<Array<Maybe<EmailClientsMutationResponse>>>;
  /** update data of the table: "email_templates" */
  update_email_templates?: Maybe<EmailTemplatesMutationResponse>;
  /** update single row of the table: "email_templates" */
  update_email_templates_by_pk?: Maybe<EmailTemplates>;
  /** update multiples rows of table: "email_templates" */
  update_email_templates_many?: Maybe<
    Array<Maybe<EmailTemplatesMutationResponse>>
  >;
  /** update data of the table: "email_verification_tokens" */
  update_email_verification_tokens?: Maybe<EmailVerificationTokensMutationResponse>;
  /** update single row of the table: "email_verification_tokens" */
  update_email_verification_tokens_by_pk?: Maybe<EmailVerificationTokens>;
  /** update multiples rows of table: "email_verification_tokens" */
  update_email_verification_tokens_many?: Maybe<
    Array<Maybe<EmailVerificationTokensMutationResponse>>
  >;
  /** update data of the table: "identity_email" */
  update_identity_email?: Maybe<IdentityEmailMutationResponse>;
  /** update single row of the table: "identity_email" */
  update_identity_email_by_pk?: Maybe<IdentityEmail>;
  /** update multiples rows of table: "identity_email" */
  update_identity_email_many?: Maybe<
    Array<Maybe<IdentityEmailMutationResponse>>
  >;
  /** update data of the table: "identity_oauth" */
  update_identity_oauth?: Maybe<IdentityOauthMutationResponse>;
  /** update single row of the table: "identity_oauth" */
  update_identity_oauth_by_pk?: Maybe<IdentityOauth>;
  /** update multiples rows of table: "identity_oauth" */
  update_identity_oauth_many?: Maybe<
    Array<Maybe<IdentityOauthMutationResponse>>
  >;
  /** update data of the table: "oauth_provider" */
  update_oauth_provider?: Maybe<OauthProviderMutationResponse>;
  /** update single row of the table: "oauth_provider" */
  update_oauth_provider_by_pk?: Maybe<OauthProvider>;
  /** update multiples rows of table: "oauth_provider" */
  update_oauth_provider_many?: Maybe<
    Array<Maybe<OauthProviderMutationResponse>>
  >;
  /** update data of the table: "service_accounts" */
  update_service_accounts?: Maybe<ServiceAccountsMutationResponse>;
  /** update single row of the table: "service_accounts" */
  update_service_accounts_by_pk?: Maybe<ServiceAccounts>;
  /** update multiples rows of table: "service_accounts" */
  update_service_accounts_many?: Maybe<
    Array<Maybe<ServiceAccountsMutationResponse>>
  >;
  /** update data of the table: "user_refresh_tokens" */
  update_user_refresh_tokens?: Maybe<UserRefreshTokensMutationResponse>;
  /** update single row of the table: "user_refresh_tokens" */
  update_user_refresh_tokens_by_pk?: Maybe<UserRefreshTokens>;
  /** update multiples rows of table: "user_refresh_tokens" */
  update_user_refresh_tokens_many?: Maybe<
    Array<Maybe<UserRefreshTokensMutationResponse>>
  >;
  /** update data of the table: "users" */
  update_users?: Maybe<UsersMutationResponse>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<UsersMutationResponse>>>;
}

/** mutation root */
export interface MutationRootDeleteAppOauthProvidersArgs {
  where: AppOauthProvidersBoolExp;
}

/** mutation root */
export interface MutationRootDeleteAppOauthProvidersByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteAppWebhookTriggersArgs {
  where: AppWebhookTriggersBoolExp;
}

/** mutation root */
export interface MutationRootDeleteAppWebhookTriggersByPkArgs {
  trigger: Scalars["String"];
}

/** mutation root */
export interface MutationRootDeleteAppWebhooksArgs {
  where: AppWebhooksBoolExp;
}

/** mutation root */
export interface MutationRootDeleteAppWebhooksByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteAppsArgs {
  where: AppsBoolExp;
}

/** mutation root */
export interface MutationRootDeleteAppsByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteEmailClientsArgs {
  where: EmailClientsBoolExp;
}

/** mutation root */
export interface MutationRootDeleteEmailClientsByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteEmailTemplatesArgs {
  where: EmailTemplatesBoolExp;
}

/** mutation root */
export interface MutationRootDeleteEmailTemplatesByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteEmailVerificationTokensArgs {
  where: EmailVerificationTokensBoolExp;
}

/** mutation root */
export interface MutationRootDeleteEmailVerificationTokensByPkArgs {
  token: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteIdentityEmailArgs {
  where: IdentityEmailBoolExp;
}

/** mutation root */
export interface MutationRootDeleteIdentityEmailByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteIdentityOauthArgs {
  where: IdentityOauthBoolExp;
}

/** mutation root */
export interface MutationRootDeleteIdentityOauthByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteOauthProviderArgs {
  where: OauthProviderBoolExp;
}

/** mutation root */
export interface MutationRootDeleteOauthProviderByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteServiceAccountsArgs {
  where: ServiceAccountsBoolExp;
}

/** mutation root */
export interface MutationRootDeleteServiceAccountsByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteUserRefreshTokensArgs {
  where: UserRefreshTokensBoolExp;
}

/** mutation root */
export interface MutationRootDeleteUserRefreshTokensByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteUsersArgs {
  where: UsersBoolExp;
}

/** mutation root */
export interface MutationRootDeleteUsersByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootInsertAppOauthProvidersArgs {
  objects: Array<AppOauthProvidersInsertInput>;
  on_conflict?: Maybe<AppOauthProvidersOnConflict>;
}

/** mutation root */
export interface MutationRootInsertAppOauthProvidersOneArgs {
  object: AppOauthProvidersInsertInput;
  on_conflict?: Maybe<AppOauthProvidersOnConflict>;
}

/** mutation root */
export interface MutationRootInsertAppWebhookTriggersArgs {
  objects: Array<AppWebhookTriggersInsertInput>;
  on_conflict?: Maybe<AppWebhookTriggersOnConflict>;
}

/** mutation root */
export interface MutationRootInsertAppWebhookTriggersOneArgs {
  object: AppWebhookTriggersInsertInput;
  on_conflict?: Maybe<AppWebhookTriggersOnConflict>;
}

/** mutation root */
export interface MutationRootInsertAppWebhooksArgs {
  objects: Array<AppWebhooksInsertInput>;
  on_conflict?: Maybe<AppWebhooksOnConflict>;
}

/** mutation root */
export interface MutationRootInsertAppWebhooksOneArgs {
  object: AppWebhooksInsertInput;
  on_conflict?: Maybe<AppWebhooksOnConflict>;
}

/** mutation root */
export interface MutationRootInsertAppsArgs {
  objects: Array<AppsInsertInput>;
  on_conflict?: Maybe<AppsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertAppsOneArgs {
  object: AppsInsertInput;
  on_conflict?: Maybe<AppsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertEmailClientsArgs {
  objects: Array<EmailClientsInsertInput>;
  on_conflict?: Maybe<EmailClientsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertEmailClientsOneArgs {
  object: EmailClientsInsertInput;
  on_conflict?: Maybe<EmailClientsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertEmailTemplatesArgs {
  objects: Array<EmailTemplatesInsertInput>;
  on_conflict?: Maybe<EmailTemplatesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertEmailTemplatesOneArgs {
  object: EmailTemplatesInsertInput;
  on_conflict?: Maybe<EmailTemplatesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertEmailVerificationTokensArgs {
  objects: Array<EmailVerificationTokensInsertInput>;
  on_conflict?: Maybe<EmailVerificationTokensOnConflict>;
}

/** mutation root */
export interface MutationRootInsertEmailVerificationTokensOneArgs {
  object: EmailVerificationTokensInsertInput;
  on_conflict?: Maybe<EmailVerificationTokensOnConflict>;
}

/** mutation root */
export interface MutationRootInsertIdentityEmailArgs {
  objects: Array<IdentityEmailInsertInput>;
  on_conflict?: Maybe<IdentityEmailOnConflict>;
}

/** mutation root */
export interface MutationRootInsertIdentityEmailOneArgs {
  object: IdentityEmailInsertInput;
  on_conflict?: Maybe<IdentityEmailOnConflict>;
}

/** mutation root */
export interface MutationRootInsertIdentityOauthArgs {
  objects: Array<IdentityOauthInsertInput>;
  on_conflict?: Maybe<IdentityOauthOnConflict>;
}

/** mutation root */
export interface MutationRootInsertIdentityOauthOneArgs {
  object: IdentityOauthInsertInput;
  on_conflict?: Maybe<IdentityOauthOnConflict>;
}

/** mutation root */
export interface MutationRootInsertOauthProviderArgs {
  objects: Array<OauthProviderInsertInput>;
  on_conflict?: Maybe<OauthProviderOnConflict>;
}

/** mutation root */
export interface MutationRootInsertOauthProviderOneArgs {
  object: OauthProviderInsertInput;
  on_conflict?: Maybe<OauthProviderOnConflict>;
}

/** mutation root */
export interface MutationRootInsertServiceAccountsArgs {
  objects: Array<ServiceAccountsInsertInput>;
  on_conflict?: Maybe<ServiceAccountsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertServiceAccountsOneArgs {
  object: ServiceAccountsInsertInput;
  on_conflict?: Maybe<ServiceAccountsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertUserRefreshTokensArgs {
  objects: Array<UserRefreshTokensInsertInput>;
  on_conflict?: Maybe<UserRefreshTokensOnConflict>;
}

/** mutation root */
export interface MutationRootInsertUserRefreshTokensOneArgs {
  object: UserRefreshTokensInsertInput;
  on_conflict?: Maybe<UserRefreshTokensOnConflict>;
}

/** mutation root */
export interface MutationRootInsertUsersArgs {
  objects: Array<UsersInsertInput>;
  on_conflict?: Maybe<UsersOnConflict>;
}

/** mutation root */
export interface MutationRootInsertUsersOneArgs {
  object: UsersInsertInput;
  on_conflict?: Maybe<UsersOnConflict>;
}

/** mutation root */
export interface MutationRootUpdateAppOauthProvidersArgs {
  _set?: Maybe<AppOauthProvidersSetInput>;
  where: AppOauthProvidersBoolExp;
}

/** mutation root */
export interface MutationRootUpdateAppOauthProvidersByPkArgs {
  _set?: Maybe<AppOauthProvidersSetInput>;
  pk_columns: AppOauthProvidersPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateAppOauthProvidersManyArgs {
  updates: Array<AppOauthProvidersUpdates>;
}

/** mutation root */
export interface MutationRootUpdateAppWebhookTriggersArgs {
  _set?: Maybe<AppWebhookTriggersSetInput>;
  where: AppWebhookTriggersBoolExp;
}

/** mutation root */
export interface MutationRootUpdateAppWebhookTriggersByPkArgs {
  _set?: Maybe<AppWebhookTriggersSetInput>;
  pk_columns: AppWebhookTriggersPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateAppWebhookTriggersManyArgs {
  updates: Array<AppWebhookTriggersUpdates>;
}

/** mutation root */
export interface MutationRootUpdateAppWebhooksArgs {
  _set?: Maybe<AppWebhooksSetInput>;
  where: AppWebhooksBoolExp;
}

/** mutation root */
export interface MutationRootUpdateAppWebhooksByPkArgs {
  _set?: Maybe<AppWebhooksSetInput>;
  pk_columns: AppWebhooksPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateAppWebhooksManyArgs {
  updates: Array<AppWebhooksUpdates>;
}

/** mutation root */
export interface MutationRootUpdateAppsArgs {
  _append?: Maybe<AppsAppendInput>;
  _delete_at_path?: Maybe<AppsDeleteAtPathInput>;
  _delete_elem?: Maybe<AppsDeleteElemInput>;
  _delete_key?: Maybe<AppsDeleteKeyInput>;
  _prepend?: Maybe<AppsPrependInput>;
  _set?: Maybe<AppsSetInput>;
  where: AppsBoolExp;
}

/** mutation root */
export interface MutationRootUpdateAppsByPkArgs {
  _append?: Maybe<AppsAppendInput>;
  _delete_at_path?: Maybe<AppsDeleteAtPathInput>;
  _delete_elem?: Maybe<AppsDeleteElemInput>;
  _delete_key?: Maybe<AppsDeleteKeyInput>;
  _prepend?: Maybe<AppsPrependInput>;
  _set?: Maybe<AppsSetInput>;
  pk_columns: AppsPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateAppsManyArgs {
  updates: Array<AppsUpdates>;
}

/** mutation root */
export interface MutationRootUpdateEmailClientsArgs {
  _inc?: Maybe<EmailClientsIncInput>;
  _set?: Maybe<EmailClientsSetInput>;
  where: EmailClientsBoolExp;
}

/** mutation root */
export interface MutationRootUpdateEmailClientsByPkArgs {
  _inc?: Maybe<EmailClientsIncInput>;
  _set?: Maybe<EmailClientsSetInput>;
  pk_columns: EmailClientsPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateEmailClientsManyArgs {
  updates: Array<EmailClientsUpdates>;
}

/** mutation root */
export interface MutationRootUpdateEmailTemplatesArgs {
  _set?: Maybe<EmailTemplatesSetInput>;
  where: EmailTemplatesBoolExp;
}

/** mutation root */
export interface MutationRootUpdateEmailTemplatesByPkArgs {
  _set?: Maybe<EmailTemplatesSetInput>;
  pk_columns: EmailTemplatesPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateEmailTemplatesManyArgs {
  updates: Array<EmailTemplatesUpdates>;
}

/** mutation root */
export interface MutationRootUpdateEmailVerificationTokensArgs {
  _set?: Maybe<EmailVerificationTokensSetInput>;
  where: EmailVerificationTokensBoolExp;
}

/** mutation root */
export interface MutationRootUpdateEmailVerificationTokensByPkArgs {
  _set?: Maybe<EmailVerificationTokensSetInput>;
  pk_columns: EmailVerificationTokensPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateEmailVerificationTokensManyArgs {
  updates: Array<EmailVerificationTokensUpdates>;
}

/** mutation root */
export interface MutationRootUpdateIdentityEmailArgs {
  _set?: Maybe<IdentityEmailSetInput>;
  where: IdentityEmailBoolExp;
}

/** mutation root */
export interface MutationRootUpdateIdentityEmailByPkArgs {
  _set?: Maybe<IdentityEmailSetInput>;
  pk_columns: IdentityEmailPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateIdentityEmailManyArgs {
  updates: Array<IdentityEmailUpdates>;
}

/** mutation root */
export interface MutationRootUpdateIdentityOauthArgs {
  _append?: Maybe<IdentityOauthAppendInput>;
  _delete_at_path?: Maybe<IdentityOauthDeleteAtPathInput>;
  _delete_elem?: Maybe<IdentityOauthDeleteElemInput>;
  _delete_key?: Maybe<IdentityOauthDeleteKeyInput>;
  _prepend?: Maybe<IdentityOauthPrependInput>;
  _set?: Maybe<IdentityOauthSetInput>;
  where: IdentityOauthBoolExp;
}

/** mutation root */
export interface MutationRootUpdateIdentityOauthByPkArgs {
  _append?: Maybe<IdentityOauthAppendInput>;
  _delete_at_path?: Maybe<IdentityOauthDeleteAtPathInput>;
  _delete_elem?: Maybe<IdentityOauthDeleteElemInput>;
  _delete_key?: Maybe<IdentityOauthDeleteKeyInput>;
  _prepend?: Maybe<IdentityOauthPrependInput>;
  _set?: Maybe<IdentityOauthSetInput>;
  pk_columns: IdentityOauthPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateIdentityOauthManyArgs {
  updates: Array<IdentityOauthUpdates>;
}

/** mutation root */
export interface MutationRootUpdateOauthProviderArgs {
  _append?: Maybe<OauthProviderAppendInput>;
  _delete_at_path?: Maybe<OauthProviderDeleteAtPathInput>;
  _delete_elem?: Maybe<OauthProviderDeleteElemInput>;
  _delete_key?: Maybe<OauthProviderDeleteKeyInput>;
  _prepend?: Maybe<OauthProviderPrependInput>;
  _set?: Maybe<OauthProviderSetInput>;
  where: OauthProviderBoolExp;
}

/** mutation root */
export interface MutationRootUpdateOauthProviderByPkArgs {
  _append?: Maybe<OauthProviderAppendInput>;
  _delete_at_path?: Maybe<OauthProviderDeleteAtPathInput>;
  _delete_elem?: Maybe<OauthProviderDeleteElemInput>;
  _delete_key?: Maybe<OauthProviderDeleteKeyInput>;
  _prepend?: Maybe<OauthProviderPrependInput>;
  _set?: Maybe<OauthProviderSetInput>;
  pk_columns: OauthProviderPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateOauthProviderManyArgs {
  updates: Array<OauthProviderUpdates>;
}

/** mutation root */
export interface MutationRootUpdateServiceAccountsArgs {
  _append?: Maybe<ServiceAccountsAppendInput>;
  _delete_at_path?: Maybe<ServiceAccountsDeleteAtPathInput>;
  _delete_elem?: Maybe<ServiceAccountsDeleteElemInput>;
  _delete_key?: Maybe<ServiceAccountsDeleteKeyInput>;
  _prepend?: Maybe<ServiceAccountsPrependInput>;
  _set?: Maybe<ServiceAccountsSetInput>;
  where: ServiceAccountsBoolExp;
}

/** mutation root */
export interface MutationRootUpdateServiceAccountsByPkArgs {
  _append?: Maybe<ServiceAccountsAppendInput>;
  _delete_at_path?: Maybe<ServiceAccountsDeleteAtPathInput>;
  _delete_elem?: Maybe<ServiceAccountsDeleteElemInput>;
  _delete_key?: Maybe<ServiceAccountsDeleteKeyInput>;
  _prepend?: Maybe<ServiceAccountsPrependInput>;
  _set?: Maybe<ServiceAccountsSetInput>;
  pk_columns: ServiceAccountsPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateServiceAccountsManyArgs {
  updates: Array<ServiceAccountsUpdates>;
}

/** mutation root */
export interface MutationRootUpdateUserRefreshTokensArgs {
  _set?: Maybe<UserRefreshTokensSetInput>;
  where: UserRefreshTokensBoolExp;
}

/** mutation root */
export interface MutationRootUpdateUserRefreshTokensByPkArgs {
  _set?: Maybe<UserRefreshTokensSetInput>;
  pk_columns: UserRefreshTokensPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateUserRefreshTokensManyArgs {
  updates: Array<UserRefreshTokensUpdates>;
}

/** mutation root */
export interface MutationRootUpdateUsersArgs {
  _append?: Maybe<UsersAppendInput>;
  _delete_at_path?: Maybe<UsersDeleteAtPathInput>;
  _delete_elem?: Maybe<UsersDeleteElemInput>;
  _delete_key?: Maybe<UsersDeleteKeyInput>;
  _prepend?: Maybe<UsersPrependInput>;
  _set?: Maybe<UsersSetInput>;
  where: UsersBoolExp;
}

/** mutation root */
export interface MutationRootUpdateUsersByPkArgs {
  _append?: Maybe<UsersAppendInput>;
  _delete_at_path?: Maybe<UsersDeleteAtPathInput>;
  _delete_elem?: Maybe<UsersDeleteElemInput>;
  _delete_key?: Maybe<UsersDeleteKeyInput>;
  _prepend?: Maybe<UsersPrependInput>;
  _set?: Maybe<UsersSetInput>;
  pk_columns: UsersPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateUsersManyArgs {
  updates: Array<UsersUpdates>;
}

/** columns and relationships of "oauth_provider" */
export interface OauthProvider {
  client_id: Scalars["String"];
  client_secret: Scalars["String"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  redirect_uri: Scalars["String"];
  scopes: Scalars["jsonb"];
  type: Scalars["String"];
  updated_at: Scalars["timestamptz"];
}

/** columns and relationships of "oauth_provider" */
export interface OauthProviderScopesArgs {
  path?: Maybe<Scalars["String"]>;
}

/** aggregated selection of "oauth_provider" */
export interface OauthProviderAggregate {
  aggregate?: Maybe<OauthProviderAggregateFields>;
  nodes: Array<OauthProvider>;
}

/** aggregate fields of "oauth_provider" */
export interface OauthProviderAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<OauthProviderMaxFields>;
  min?: Maybe<OauthProviderMinFields>;
}

/** aggregate fields of "oauth_provider" */
export interface OauthProviderAggregateFieldsCountArgs {
  columns?: Maybe<Array<OauthProviderSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface OauthProviderAppendInput {
  scopes?: Maybe<Scalars["jsonb"]>;
}

/** Boolean expression to filter rows from the table "oauth_provider". All fields are combined with a logical 'AND'. */
export interface OauthProviderBoolExp {
  _and?: Maybe<Array<OauthProviderBoolExp>>;
  _not?: Maybe<OauthProviderBoolExp>;
  _or?: Maybe<Array<OauthProviderBoolExp>>;
  client_id?: Maybe<StringComparisonExp>;
  client_secret?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  redirect_uri?: Maybe<StringComparisonExp>;
  scopes?: Maybe<JsonbComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "oauth_provider" */
export enum OauthProviderConstraint {
  /** unique or primary key constraint on columns "id" */
  oauth_provider_pkey = "oauth_provider_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface OauthProviderDeleteAtPathInput {
  scopes?: Maybe<Array<Scalars["String"]>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface OauthProviderDeleteElemInput {
  scopes?: Maybe<Scalars["Int"]>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface OauthProviderDeleteKeyInput {
  scopes?: Maybe<Scalars["String"]>;
}

/** input type for inserting data into table "oauth_provider" */
export interface OauthProviderInsertInput {
  client_id?: Maybe<Scalars["String"]>;
  client_secret?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  redirect_uri?: Maybe<Scalars["String"]>;
  scopes?: Maybe<Scalars["jsonb"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** aggregate max on columns */
export interface OauthProviderMaxFields {
  client_id?: Maybe<Scalars["String"]>;
  client_secret?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  redirect_uri?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** aggregate min on columns */
export interface OauthProviderMinFields {
  client_id?: Maybe<Scalars["String"]>;
  client_secret?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  redirect_uri?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** response of any mutation on the table "oauth_provider" */
export interface OauthProviderMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<OauthProvider>;
}

/** input type for inserting object relation for remote table "oauth_provider" */
export interface OauthProviderObjRelInsertInput {
  data: OauthProviderInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<OauthProviderOnConflict>;
}

/** on_conflict condition type for table "oauth_provider" */
export interface OauthProviderOnConflict {
  constraint: OauthProviderConstraint;
  update_columns?: Array<OauthProviderUpdateColumn>;
  where?: Maybe<OauthProviderBoolExp>;
}

/** Ordering options when selecting data from "oauth_provider". */
export interface OauthProviderOrderBy {
  client_id?: Maybe<OrderBy>;
  client_secret?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  redirect_uri?: Maybe<OrderBy>;
  scopes?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** primary key columns input for table: oauth_provider */
export interface OauthProviderPkColumnsInput {
  id: Scalars["uuid"];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface OauthProviderPrependInput {
  scopes?: Maybe<Scalars["jsonb"]>;
}

/** select columns of table "oauth_provider" */
export enum OauthProviderSelectColumn {
  /** column name */
  client_id = "client_id",
  /** column name */
  client_secret = "client_secret",
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  redirect_uri = "redirect_uri",
  /** column name */
  scopes = "scopes",
  /** column name */
  type = "type",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "oauth_provider" */
export interface OauthProviderSetInput {
  client_id?: Maybe<Scalars["String"]>;
  client_secret?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  redirect_uri?: Maybe<Scalars["String"]>;
  scopes?: Maybe<Scalars["jsonb"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** Streaming cursor of the table "oauth_provider" */
export interface OauthProviderStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: OauthProviderStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface OauthProviderStreamCursorValueInput {
  client_id?: Maybe<Scalars["String"]>;
  client_secret?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  redirect_uri?: Maybe<Scalars["String"]>;
  scopes?: Maybe<Scalars["jsonb"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** update columns of table "oauth_provider" */
export enum OauthProviderUpdateColumn {
  /** column name */
  client_id = "client_id",
  /** column name */
  client_secret = "client_secret",
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  redirect_uri = "redirect_uri",
  /** column name */
  scopes = "scopes",
  /** column name */
  type = "type",
  /** column name */
  updated_at = "updated_at",
}

export interface OauthProviderUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<OauthProviderAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<OauthProviderDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<OauthProviderDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<OauthProviderDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<OauthProviderPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<OauthProviderSetInput>;
  where: OauthProviderBoolExp;
}

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  asc = "asc",
  /** in ascending order, nulls first */
  asc_nulls_first = "asc_nulls_first",
  /** in ascending order, nulls last */
  asc_nulls_last = "asc_nulls_last",
  /** in descending order, nulls first */
  desc = "desc",
  /** in descending order, nulls first */
  desc_nulls_first = "desc_nulls_first",
  /** in descending order, nulls last */
  desc_nulls_last = "desc_nulls_last",
}

export interface QueryRoot {
  /** An array relationship */
  app_oauth_providers: Array<AppOauthProviders>;
  /** An aggregate relationship */
  app_oauth_providers_aggregate: AppOauthProvidersAggregate;
  /** fetch data from the table: "app_oauth_providers" using primary key columns */
  app_oauth_providers_by_pk?: Maybe<AppOauthProviders>;
  /** fetch data from the table: "app_webhook_triggers" */
  app_webhook_triggers: Array<AppWebhookTriggers>;
  /** fetch aggregated fields from the table: "app_webhook_triggers" */
  app_webhook_triggers_aggregate: AppWebhookTriggersAggregate;
  /** fetch data from the table: "app_webhook_triggers" using primary key columns */
  app_webhook_triggers_by_pk?: Maybe<AppWebhookTriggers>;
  /** fetch data from the table: "app_webhooks" */
  app_webhooks: Array<AppWebhooks>;
  /** fetch aggregated fields from the table: "app_webhooks" */
  app_webhooks_aggregate: AppWebhooksAggregate;
  /** fetch data from the table: "app_webhooks" using primary key columns */
  app_webhooks_by_pk?: Maybe<AppWebhooks>;
  /** fetch data from the table: "apps" */
  apps: Array<Apps>;
  /** fetch aggregated fields from the table: "apps" */
  apps_aggregate: AppsAggregate;
  /** fetch data from the table: "apps" using primary key columns */
  apps_by_pk?: Maybe<Apps>;
  /** fetch data from the table: "email_clients" */
  email_clients: Array<EmailClients>;
  /** fetch aggregated fields from the table: "email_clients" */
  email_clients_aggregate: EmailClientsAggregate;
  /** fetch data from the table: "email_clients" using primary key columns */
  email_clients_by_pk?: Maybe<EmailClients>;
  /** fetch data from the table: "email_templates" */
  email_templates: Array<EmailTemplates>;
  /** fetch aggregated fields from the table: "email_templates" */
  email_templates_aggregate: EmailTemplatesAggregate;
  /** fetch data from the table: "email_templates" using primary key columns */
  email_templates_by_pk?: Maybe<EmailTemplates>;
  /** fetch data from the table: "email_verification_tokens" */
  email_verification_tokens: Array<EmailVerificationTokens>;
  /** fetch aggregated fields from the table: "email_verification_tokens" */
  email_verification_tokens_aggregate: EmailVerificationTokensAggregate;
  /** fetch data from the table: "email_verification_tokens" using primary key columns */
  email_verification_tokens_by_pk?: Maybe<EmailVerificationTokens>;
  /** fetch data from the table: "identity_email" */
  identity_email: Array<IdentityEmail>;
  /** fetch aggregated fields from the table: "identity_email" */
  identity_email_aggregate: IdentityEmailAggregate;
  /** fetch data from the table: "identity_email" using primary key columns */
  identity_email_by_pk?: Maybe<IdentityEmail>;
  /** fetch data from the table: "identity_oauth" */
  identity_oauth: Array<IdentityOauth>;
  /** fetch aggregated fields from the table: "identity_oauth" */
  identity_oauth_aggregate: IdentityOauthAggregate;
  /** fetch data from the table: "identity_oauth" using primary key columns */
  identity_oauth_by_pk?: Maybe<IdentityOauth>;
  /** fetch data from the table: "oauth_provider" */
  oauth_provider: Array<OauthProvider>;
  /** fetch aggregated fields from the table: "oauth_provider" */
  oauth_provider_aggregate: OauthProviderAggregate;
  /** fetch data from the table: "oauth_provider" using primary key columns */
  oauth_provider_by_pk?: Maybe<OauthProvider>;
  /** fetch data from the table: "service_accounts" */
  service_accounts: Array<ServiceAccounts>;
  /** fetch aggregated fields from the table: "service_accounts" */
  service_accounts_aggregate: ServiceAccountsAggregate;
  /** fetch data from the table: "service_accounts" using primary key columns */
  service_accounts_by_pk?: Maybe<ServiceAccounts>;
  /** fetch data from the table: "user_refresh_tokens" */
  user_refresh_tokens: Array<UserRefreshTokens>;
  /** fetch aggregated fields from the table: "user_refresh_tokens" */
  user_refresh_tokens_aggregate: UserRefreshTokensAggregate;
  /** fetch data from the table: "user_refresh_tokens" using primary key columns */
  user_refresh_tokens_by_pk?: Maybe<UserRefreshTokens>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
}

export interface QueryRootAppOauthProvidersArgs {
  distinct_on?: Maybe<Array<AppOauthProvidersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppOauthProvidersOrderBy>>;
  where?: Maybe<AppOauthProvidersBoolExp>;
}

export interface QueryRootAppOauthProvidersAggregateArgs {
  distinct_on?: Maybe<Array<AppOauthProvidersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppOauthProvidersOrderBy>>;
  where?: Maybe<AppOauthProvidersBoolExp>;
}

export interface QueryRootAppOauthProvidersByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootAppWebhookTriggersArgs {
  distinct_on?: Maybe<Array<AppWebhookTriggersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppWebhookTriggersOrderBy>>;
  where?: Maybe<AppWebhookTriggersBoolExp>;
}

export interface QueryRootAppWebhookTriggersAggregateArgs {
  distinct_on?: Maybe<Array<AppWebhookTriggersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppWebhookTriggersOrderBy>>;
  where?: Maybe<AppWebhookTriggersBoolExp>;
}

export interface QueryRootAppWebhookTriggersByPkArgs {
  trigger: Scalars["String"];
}

export interface QueryRootAppWebhooksArgs {
  distinct_on?: Maybe<Array<AppWebhooksSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppWebhooksOrderBy>>;
  where?: Maybe<AppWebhooksBoolExp>;
}

export interface QueryRootAppWebhooksAggregateArgs {
  distinct_on?: Maybe<Array<AppWebhooksSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppWebhooksOrderBy>>;
  where?: Maybe<AppWebhooksBoolExp>;
}

export interface QueryRootAppWebhooksByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootAppsArgs {
  distinct_on?: Maybe<Array<AppsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppsOrderBy>>;
  where?: Maybe<AppsBoolExp>;
}

export interface QueryRootAppsAggregateArgs {
  distinct_on?: Maybe<Array<AppsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppsOrderBy>>;
  where?: Maybe<AppsBoolExp>;
}

export interface QueryRootAppsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootEmailClientsArgs {
  distinct_on?: Maybe<Array<EmailClientsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EmailClientsOrderBy>>;
  where?: Maybe<EmailClientsBoolExp>;
}

export interface QueryRootEmailClientsAggregateArgs {
  distinct_on?: Maybe<Array<EmailClientsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EmailClientsOrderBy>>;
  where?: Maybe<EmailClientsBoolExp>;
}

export interface QueryRootEmailClientsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootEmailTemplatesArgs {
  distinct_on?: Maybe<Array<EmailTemplatesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EmailTemplatesOrderBy>>;
  where?: Maybe<EmailTemplatesBoolExp>;
}

export interface QueryRootEmailTemplatesAggregateArgs {
  distinct_on?: Maybe<Array<EmailTemplatesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EmailTemplatesOrderBy>>;
  where?: Maybe<EmailTemplatesBoolExp>;
}

export interface QueryRootEmailTemplatesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootEmailVerificationTokensArgs {
  distinct_on?: Maybe<Array<EmailVerificationTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EmailVerificationTokensOrderBy>>;
  where?: Maybe<EmailVerificationTokensBoolExp>;
}

export interface QueryRootEmailVerificationTokensAggregateArgs {
  distinct_on?: Maybe<Array<EmailVerificationTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EmailVerificationTokensOrderBy>>;
  where?: Maybe<EmailVerificationTokensBoolExp>;
}

export interface QueryRootEmailVerificationTokensByPkArgs {
  token: Scalars["uuid"];
}

export interface QueryRootIdentityEmailArgs {
  distinct_on?: Maybe<Array<IdentityEmailSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<IdentityEmailOrderBy>>;
  where?: Maybe<IdentityEmailBoolExp>;
}

export interface QueryRootIdentityEmailAggregateArgs {
  distinct_on?: Maybe<Array<IdentityEmailSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<IdentityEmailOrderBy>>;
  where?: Maybe<IdentityEmailBoolExp>;
}

export interface QueryRootIdentityEmailByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootIdentityOauthArgs {
  distinct_on?: Maybe<Array<IdentityOauthSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<IdentityOauthOrderBy>>;
  where?: Maybe<IdentityOauthBoolExp>;
}

export interface QueryRootIdentityOauthAggregateArgs {
  distinct_on?: Maybe<Array<IdentityOauthSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<IdentityOauthOrderBy>>;
  where?: Maybe<IdentityOauthBoolExp>;
}

export interface QueryRootIdentityOauthByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootOauthProviderArgs {
  distinct_on?: Maybe<Array<OauthProviderSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OauthProviderOrderBy>>;
  where?: Maybe<OauthProviderBoolExp>;
}

export interface QueryRootOauthProviderAggregateArgs {
  distinct_on?: Maybe<Array<OauthProviderSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OauthProviderOrderBy>>;
  where?: Maybe<OauthProviderBoolExp>;
}

export interface QueryRootOauthProviderByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootServiceAccountsArgs {
  distinct_on?: Maybe<Array<ServiceAccountsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ServiceAccountsOrderBy>>;
  where?: Maybe<ServiceAccountsBoolExp>;
}

export interface QueryRootServiceAccountsAggregateArgs {
  distinct_on?: Maybe<Array<ServiceAccountsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ServiceAccountsOrderBy>>;
  where?: Maybe<ServiceAccountsBoolExp>;
}

export interface QueryRootServiceAccountsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootUserRefreshTokensArgs {
  distinct_on?: Maybe<Array<UserRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserRefreshTokensOrderBy>>;
  where?: Maybe<UserRefreshTokensBoolExp>;
}

export interface QueryRootUserRefreshTokensAggregateArgs {
  distinct_on?: Maybe<Array<UserRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserRefreshTokensOrderBy>>;
  where?: Maybe<UserRefreshTokensBoolExp>;
}

export interface QueryRootUserRefreshTokensByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootUsersArgs {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
}

export interface QueryRootUsersAggregateArgs {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
}

export interface QueryRootUsersByPkArgs {
  id: Scalars["uuid"];
}

/** columns and relationships of "service_accounts" */
export interface ServiceAccounts {
  access_token: Scalars["String"];
  app_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  name?: Maybe<Scalars["String"]>;
  scopes?: Maybe<Scalars["jsonb"]>;
  updated_at: Scalars["timestamptz"];
}

/** columns and relationships of "service_accounts" */
export interface ServiceAccountsScopesArgs {
  path?: Maybe<Scalars["String"]>;
}

/** aggregated selection of "service_accounts" */
export interface ServiceAccountsAggregate {
  aggregate?: Maybe<ServiceAccountsAggregateFields>;
  nodes: Array<ServiceAccounts>;
}

/** aggregate fields of "service_accounts" */
export interface ServiceAccountsAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<ServiceAccountsMaxFields>;
  min?: Maybe<ServiceAccountsMinFields>;
}

/** aggregate fields of "service_accounts" */
export interface ServiceAccountsAggregateFieldsCountArgs {
  columns?: Maybe<Array<ServiceAccountsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface ServiceAccountsAppendInput {
  scopes?: Maybe<Scalars["jsonb"]>;
}

/** Boolean expression to filter rows from the table "service_accounts". All fields are combined with a logical 'AND'. */
export interface ServiceAccountsBoolExp {
  _and?: Maybe<Array<ServiceAccountsBoolExp>>;
  _not?: Maybe<ServiceAccountsBoolExp>;
  _or?: Maybe<Array<ServiceAccountsBoolExp>>;
  access_token?: Maybe<StringComparisonExp>;
  app_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  scopes?: Maybe<JsonbComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "service_accounts" */
export enum ServiceAccountsConstraint {
  /** unique or primary key constraint on columns "id" */
  service_accounts_pkey = "service_accounts_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface ServiceAccountsDeleteAtPathInput {
  scopes?: Maybe<Array<Scalars["String"]>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface ServiceAccountsDeleteElemInput {
  scopes?: Maybe<Scalars["Int"]>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface ServiceAccountsDeleteKeyInput {
  scopes?: Maybe<Scalars["String"]>;
}

/** input type for inserting data into table "service_accounts" */
export interface ServiceAccountsInsertInput {
  access_token?: Maybe<Scalars["String"]>;
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  scopes?: Maybe<Scalars["jsonb"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** aggregate max on columns */
export interface ServiceAccountsMaxFields {
  access_token?: Maybe<Scalars["String"]>;
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** aggregate min on columns */
export interface ServiceAccountsMinFields {
  access_token?: Maybe<Scalars["String"]>;
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** response of any mutation on the table "service_accounts" */
export interface ServiceAccountsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceAccounts>;
}

/** on_conflict condition type for table "service_accounts" */
export interface ServiceAccountsOnConflict {
  constraint: ServiceAccountsConstraint;
  update_columns?: Array<ServiceAccountsUpdateColumn>;
  where?: Maybe<ServiceAccountsBoolExp>;
}

/** Ordering options when selecting data from "service_accounts". */
export interface ServiceAccountsOrderBy {
  access_token?: Maybe<OrderBy>;
  app_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  scopes?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** primary key columns input for table: service_accounts */
export interface ServiceAccountsPkColumnsInput {
  id: Scalars["uuid"];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface ServiceAccountsPrependInput {
  scopes?: Maybe<Scalars["jsonb"]>;
}

/** select columns of table "service_accounts" */
export enum ServiceAccountsSelectColumn {
  /** column name */
  access_token = "access_token",
  /** column name */
  app_id = "app_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  scopes = "scopes",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "service_accounts" */
export interface ServiceAccountsSetInput {
  access_token?: Maybe<Scalars["String"]>;
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  scopes?: Maybe<Scalars["jsonb"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** Streaming cursor of the table "service_accounts" */
export interface ServiceAccountsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ServiceAccountsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ServiceAccountsStreamCursorValueInput {
  access_token?: Maybe<Scalars["String"]>;
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  scopes?: Maybe<Scalars["jsonb"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** update columns of table "service_accounts" */
export enum ServiceAccountsUpdateColumn {
  /** column name */
  access_token = "access_token",
  /** column name */
  app_id = "app_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  scopes = "scopes",
  /** column name */
  updated_at = "updated_at",
}

export interface ServiceAccountsUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<ServiceAccountsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<ServiceAccountsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<ServiceAccountsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<ServiceAccountsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<ServiceAccountsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ServiceAccountsSetInput>;
  where: ServiceAccountsBoolExp;
}

export interface SubscriptionRoot {
  /** An array relationship */
  app_oauth_providers: Array<AppOauthProviders>;
  /** An aggregate relationship */
  app_oauth_providers_aggregate: AppOauthProvidersAggregate;
  /** fetch data from the table: "app_oauth_providers" using primary key columns */
  app_oauth_providers_by_pk?: Maybe<AppOauthProviders>;
  /** fetch data from the table in a streaming manner: "app_oauth_providers" */
  app_oauth_providers_stream: Array<AppOauthProviders>;
  /** fetch data from the table: "app_webhook_triggers" */
  app_webhook_triggers: Array<AppWebhookTriggers>;
  /** fetch aggregated fields from the table: "app_webhook_triggers" */
  app_webhook_triggers_aggregate: AppWebhookTriggersAggregate;
  /** fetch data from the table: "app_webhook_triggers" using primary key columns */
  app_webhook_triggers_by_pk?: Maybe<AppWebhookTriggers>;
  /** fetch data from the table in a streaming manner: "app_webhook_triggers" */
  app_webhook_triggers_stream: Array<AppWebhookTriggers>;
  /** fetch data from the table: "app_webhooks" */
  app_webhooks: Array<AppWebhooks>;
  /** fetch aggregated fields from the table: "app_webhooks" */
  app_webhooks_aggregate: AppWebhooksAggregate;
  /** fetch data from the table: "app_webhooks" using primary key columns */
  app_webhooks_by_pk?: Maybe<AppWebhooks>;
  /** fetch data from the table in a streaming manner: "app_webhooks" */
  app_webhooks_stream: Array<AppWebhooks>;
  /** fetch data from the table: "apps" */
  apps: Array<Apps>;
  /** fetch aggregated fields from the table: "apps" */
  apps_aggregate: AppsAggregate;
  /** fetch data from the table: "apps" using primary key columns */
  apps_by_pk?: Maybe<Apps>;
  /** fetch data from the table in a streaming manner: "apps" */
  apps_stream: Array<Apps>;
  /** fetch data from the table: "email_clients" */
  email_clients: Array<EmailClients>;
  /** fetch aggregated fields from the table: "email_clients" */
  email_clients_aggregate: EmailClientsAggregate;
  /** fetch data from the table: "email_clients" using primary key columns */
  email_clients_by_pk?: Maybe<EmailClients>;
  /** fetch data from the table in a streaming manner: "email_clients" */
  email_clients_stream: Array<EmailClients>;
  /** fetch data from the table: "email_templates" */
  email_templates: Array<EmailTemplates>;
  /** fetch aggregated fields from the table: "email_templates" */
  email_templates_aggregate: EmailTemplatesAggregate;
  /** fetch data from the table: "email_templates" using primary key columns */
  email_templates_by_pk?: Maybe<EmailTemplates>;
  /** fetch data from the table in a streaming manner: "email_templates" */
  email_templates_stream: Array<EmailTemplates>;
  /** fetch data from the table: "email_verification_tokens" */
  email_verification_tokens: Array<EmailVerificationTokens>;
  /** fetch aggregated fields from the table: "email_verification_tokens" */
  email_verification_tokens_aggregate: EmailVerificationTokensAggregate;
  /** fetch data from the table: "email_verification_tokens" using primary key columns */
  email_verification_tokens_by_pk?: Maybe<EmailVerificationTokens>;
  /** fetch data from the table in a streaming manner: "email_verification_tokens" */
  email_verification_tokens_stream: Array<EmailVerificationTokens>;
  /** fetch data from the table: "identity_email" */
  identity_email: Array<IdentityEmail>;
  /** fetch aggregated fields from the table: "identity_email" */
  identity_email_aggregate: IdentityEmailAggregate;
  /** fetch data from the table: "identity_email" using primary key columns */
  identity_email_by_pk?: Maybe<IdentityEmail>;
  /** fetch data from the table in a streaming manner: "identity_email" */
  identity_email_stream: Array<IdentityEmail>;
  /** fetch data from the table: "identity_oauth" */
  identity_oauth: Array<IdentityOauth>;
  /** fetch aggregated fields from the table: "identity_oauth" */
  identity_oauth_aggregate: IdentityOauthAggregate;
  /** fetch data from the table: "identity_oauth" using primary key columns */
  identity_oauth_by_pk?: Maybe<IdentityOauth>;
  /** fetch data from the table in a streaming manner: "identity_oauth" */
  identity_oauth_stream: Array<IdentityOauth>;
  /** fetch data from the table: "oauth_provider" */
  oauth_provider: Array<OauthProvider>;
  /** fetch aggregated fields from the table: "oauth_provider" */
  oauth_provider_aggregate: OauthProviderAggregate;
  /** fetch data from the table: "oauth_provider" using primary key columns */
  oauth_provider_by_pk?: Maybe<OauthProvider>;
  /** fetch data from the table in a streaming manner: "oauth_provider" */
  oauth_provider_stream: Array<OauthProvider>;
  /** fetch data from the table: "service_accounts" */
  service_accounts: Array<ServiceAccounts>;
  /** fetch aggregated fields from the table: "service_accounts" */
  service_accounts_aggregate: ServiceAccountsAggregate;
  /** fetch data from the table: "service_accounts" using primary key columns */
  service_accounts_by_pk?: Maybe<ServiceAccounts>;
  /** fetch data from the table in a streaming manner: "service_accounts" */
  service_accounts_stream: Array<ServiceAccounts>;
  /** fetch data from the table: "user_refresh_tokens" */
  user_refresh_tokens: Array<UserRefreshTokens>;
  /** fetch aggregated fields from the table: "user_refresh_tokens" */
  user_refresh_tokens_aggregate: UserRefreshTokensAggregate;
  /** fetch data from the table: "user_refresh_tokens" using primary key columns */
  user_refresh_tokens_by_pk?: Maybe<UserRefreshTokens>;
  /** fetch data from the table in a streaming manner: "user_refresh_tokens" */
  user_refresh_tokens_stream: Array<UserRefreshTokens>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
}

export interface SubscriptionRootAppOauthProvidersArgs {
  distinct_on?: Maybe<Array<AppOauthProvidersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppOauthProvidersOrderBy>>;
  where?: Maybe<AppOauthProvidersBoolExp>;
}

export interface SubscriptionRootAppOauthProvidersAggregateArgs {
  distinct_on?: Maybe<Array<AppOauthProvidersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppOauthProvidersOrderBy>>;
  where?: Maybe<AppOauthProvidersBoolExp>;
}

export interface SubscriptionRootAppOauthProvidersByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootAppOauthProvidersStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<AppOauthProvidersStreamCursorInput>>;
  where?: Maybe<AppOauthProvidersBoolExp>;
}

export interface SubscriptionRootAppWebhookTriggersArgs {
  distinct_on?: Maybe<Array<AppWebhookTriggersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppWebhookTriggersOrderBy>>;
  where?: Maybe<AppWebhookTriggersBoolExp>;
}

export interface SubscriptionRootAppWebhookTriggersAggregateArgs {
  distinct_on?: Maybe<Array<AppWebhookTriggersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppWebhookTriggersOrderBy>>;
  where?: Maybe<AppWebhookTriggersBoolExp>;
}

export interface SubscriptionRootAppWebhookTriggersByPkArgs {
  trigger: Scalars["String"];
}

export interface SubscriptionRootAppWebhookTriggersStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<AppWebhookTriggersStreamCursorInput>>;
  where?: Maybe<AppWebhookTriggersBoolExp>;
}

export interface SubscriptionRootAppWebhooksArgs {
  distinct_on?: Maybe<Array<AppWebhooksSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppWebhooksOrderBy>>;
  where?: Maybe<AppWebhooksBoolExp>;
}

export interface SubscriptionRootAppWebhooksAggregateArgs {
  distinct_on?: Maybe<Array<AppWebhooksSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppWebhooksOrderBy>>;
  where?: Maybe<AppWebhooksBoolExp>;
}

export interface SubscriptionRootAppWebhooksByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootAppWebhooksStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<AppWebhooksStreamCursorInput>>;
  where?: Maybe<AppWebhooksBoolExp>;
}

export interface SubscriptionRootAppsArgs {
  distinct_on?: Maybe<Array<AppsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppsOrderBy>>;
  where?: Maybe<AppsBoolExp>;
}

export interface SubscriptionRootAppsAggregateArgs {
  distinct_on?: Maybe<Array<AppsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AppsOrderBy>>;
  where?: Maybe<AppsBoolExp>;
}

export interface SubscriptionRootAppsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootAppsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<AppsStreamCursorInput>>;
  where?: Maybe<AppsBoolExp>;
}

export interface SubscriptionRootEmailClientsArgs {
  distinct_on?: Maybe<Array<EmailClientsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EmailClientsOrderBy>>;
  where?: Maybe<EmailClientsBoolExp>;
}

export interface SubscriptionRootEmailClientsAggregateArgs {
  distinct_on?: Maybe<Array<EmailClientsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EmailClientsOrderBy>>;
  where?: Maybe<EmailClientsBoolExp>;
}

export interface SubscriptionRootEmailClientsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootEmailClientsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<EmailClientsStreamCursorInput>>;
  where?: Maybe<EmailClientsBoolExp>;
}

export interface SubscriptionRootEmailTemplatesArgs {
  distinct_on?: Maybe<Array<EmailTemplatesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EmailTemplatesOrderBy>>;
  where?: Maybe<EmailTemplatesBoolExp>;
}

export interface SubscriptionRootEmailTemplatesAggregateArgs {
  distinct_on?: Maybe<Array<EmailTemplatesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EmailTemplatesOrderBy>>;
  where?: Maybe<EmailTemplatesBoolExp>;
}

export interface SubscriptionRootEmailTemplatesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootEmailTemplatesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<EmailTemplatesStreamCursorInput>>;
  where?: Maybe<EmailTemplatesBoolExp>;
}

export interface SubscriptionRootEmailVerificationTokensArgs {
  distinct_on?: Maybe<Array<EmailVerificationTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EmailVerificationTokensOrderBy>>;
  where?: Maybe<EmailVerificationTokensBoolExp>;
}

export interface SubscriptionRootEmailVerificationTokensAggregateArgs {
  distinct_on?: Maybe<Array<EmailVerificationTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<EmailVerificationTokensOrderBy>>;
  where?: Maybe<EmailVerificationTokensBoolExp>;
}

export interface SubscriptionRootEmailVerificationTokensByPkArgs {
  token: Scalars["uuid"];
}

export interface SubscriptionRootEmailVerificationTokensStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<EmailVerificationTokensStreamCursorInput>>;
  where?: Maybe<EmailVerificationTokensBoolExp>;
}

export interface SubscriptionRootIdentityEmailArgs {
  distinct_on?: Maybe<Array<IdentityEmailSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<IdentityEmailOrderBy>>;
  where?: Maybe<IdentityEmailBoolExp>;
}

export interface SubscriptionRootIdentityEmailAggregateArgs {
  distinct_on?: Maybe<Array<IdentityEmailSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<IdentityEmailOrderBy>>;
  where?: Maybe<IdentityEmailBoolExp>;
}

export interface SubscriptionRootIdentityEmailByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootIdentityEmailStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<IdentityEmailStreamCursorInput>>;
  where?: Maybe<IdentityEmailBoolExp>;
}

export interface SubscriptionRootIdentityOauthArgs {
  distinct_on?: Maybe<Array<IdentityOauthSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<IdentityOauthOrderBy>>;
  where?: Maybe<IdentityOauthBoolExp>;
}

export interface SubscriptionRootIdentityOauthAggregateArgs {
  distinct_on?: Maybe<Array<IdentityOauthSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<IdentityOauthOrderBy>>;
  where?: Maybe<IdentityOauthBoolExp>;
}

export interface SubscriptionRootIdentityOauthByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootIdentityOauthStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<IdentityOauthStreamCursorInput>>;
  where?: Maybe<IdentityOauthBoolExp>;
}

export interface SubscriptionRootOauthProviderArgs {
  distinct_on?: Maybe<Array<OauthProviderSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OauthProviderOrderBy>>;
  where?: Maybe<OauthProviderBoolExp>;
}

export interface SubscriptionRootOauthProviderAggregateArgs {
  distinct_on?: Maybe<Array<OauthProviderSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OauthProviderOrderBy>>;
  where?: Maybe<OauthProviderBoolExp>;
}

export interface SubscriptionRootOauthProviderByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootOauthProviderStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<OauthProviderStreamCursorInput>>;
  where?: Maybe<OauthProviderBoolExp>;
}

export interface SubscriptionRootServiceAccountsArgs {
  distinct_on?: Maybe<Array<ServiceAccountsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ServiceAccountsOrderBy>>;
  where?: Maybe<ServiceAccountsBoolExp>;
}

export interface SubscriptionRootServiceAccountsAggregateArgs {
  distinct_on?: Maybe<Array<ServiceAccountsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ServiceAccountsOrderBy>>;
  where?: Maybe<ServiceAccountsBoolExp>;
}

export interface SubscriptionRootServiceAccountsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootServiceAccountsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<ServiceAccountsStreamCursorInput>>;
  where?: Maybe<ServiceAccountsBoolExp>;
}

export interface SubscriptionRootUserRefreshTokensArgs {
  distinct_on?: Maybe<Array<UserRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserRefreshTokensOrderBy>>;
  where?: Maybe<UserRefreshTokensBoolExp>;
}

export interface SubscriptionRootUserRefreshTokensAggregateArgs {
  distinct_on?: Maybe<Array<UserRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserRefreshTokensOrderBy>>;
  where?: Maybe<UserRefreshTokensBoolExp>;
}

export interface SubscriptionRootUserRefreshTokensByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootUserRefreshTokensStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<UserRefreshTokensStreamCursorInput>>;
  where?: Maybe<UserRefreshTokensBoolExp>;
}

export interface SubscriptionRootUsersArgs {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
}

export interface SubscriptionRootUsersAggregateArgs {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
}

export interface SubscriptionRootUsersByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootUsersStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<UsersStreamCursorInput>>;
  where?: Maybe<UsersBoolExp>;
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface TimestamptzComparisonExp {
  _eq?: Maybe<Scalars["timestamptz"]>;
  _gt?: Maybe<Scalars["timestamptz"]>;
  _gte?: Maybe<Scalars["timestamptz"]>;
  _in?: Maybe<Array<Scalars["timestamptz"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamptz"]>;
  _lte?: Maybe<Scalars["timestamptz"]>;
  _neq?: Maybe<Scalars["timestamptz"]>;
  _nin?: Maybe<Array<Scalars["timestamptz"]>>;
}

/** columns and relationships of "user_refresh_tokens" */
export interface UserRefreshTokens {
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  /** An object relationship */
  user: Users;
  user_id: Scalars["uuid"];
}

/** aggregated selection of "user_refresh_tokens" */
export interface UserRefreshTokensAggregate {
  aggregate?: Maybe<UserRefreshTokensAggregateFields>;
  nodes: Array<UserRefreshTokens>;
}

export interface UserRefreshTokensAggregateBoolExp {
  count?: Maybe<UserRefreshTokensAggregateBoolExpCount>;
}

export interface UserRefreshTokensAggregateBoolExpCount {
  arguments?: Maybe<Array<UserRefreshTokensSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<UserRefreshTokensBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "user_refresh_tokens" */
export interface UserRefreshTokensAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<UserRefreshTokensMaxFields>;
  min?: Maybe<UserRefreshTokensMinFields>;
}

/** aggregate fields of "user_refresh_tokens" */
export interface UserRefreshTokensAggregateFieldsCountArgs {
  columns?: Maybe<Array<UserRefreshTokensSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** order by aggregate values of table "user_refresh_tokens" */
export interface UserRefreshTokensAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<UserRefreshTokensMaxOrderBy>;
  min?: Maybe<UserRefreshTokensMinOrderBy>;
}

/** input type for inserting array relation for remote table "user_refresh_tokens" */
export interface UserRefreshTokensArrRelInsertInput {
  data: Array<UserRefreshTokensInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<UserRefreshTokensOnConflict>;
}

/** Boolean expression to filter rows from the table "user_refresh_tokens". All fields are combined with a logical 'AND'. */
export interface UserRefreshTokensBoolExp {
  _and?: Maybe<Array<UserRefreshTokensBoolExp>>;
  _not?: Maybe<UserRefreshTokensBoolExp>;
  _or?: Maybe<Array<UserRefreshTokensBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "user_refresh_tokens" */
export enum UserRefreshTokensConstraint {
  /** unique or primary key constraint on columns "id" */
  user_refresh_tokens_pkey = "user_refresh_tokens_pkey",
}

/** input type for inserting data into table "user_refresh_tokens" */
export interface UserRefreshTokensInsertInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  user?: Maybe<UsersObjRelInsertInput>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** aggregate max on columns */
export interface UserRefreshTokensMaxFields {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** order by max() on columns of table "user_refresh_tokens" */
export interface UserRefreshTokensMaxOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface UserRefreshTokensMinFields {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** order by min() on columns of table "user_refresh_tokens" */
export interface UserRefreshTokensMinOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** response of any mutation on the table "user_refresh_tokens" */
export interface UserRefreshTokensMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<UserRefreshTokens>;
}

/** on_conflict condition type for table "user_refresh_tokens" */
export interface UserRefreshTokensOnConflict {
  constraint: UserRefreshTokensConstraint;
  update_columns?: Array<UserRefreshTokensUpdateColumn>;
  where?: Maybe<UserRefreshTokensBoolExp>;
}

/** Ordering options when selecting data from "user_refresh_tokens". */
export interface UserRefreshTokensOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** primary key columns input for table: user_refresh_tokens */
export interface UserRefreshTokensPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "user_refresh_tokens" */
export enum UserRefreshTokensSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  user_id = "user_id",
}

/** input type for updating data in table "user_refresh_tokens" */
export interface UserRefreshTokensSetInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** Streaming cursor of the table "user_refresh_tokens" */
export interface UserRefreshTokensStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: UserRefreshTokensStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface UserRefreshTokensStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** update columns of table "user_refresh_tokens" */
export enum UserRefreshTokensUpdateColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  user_id = "user_id",
}

export interface UserRefreshTokensUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<UserRefreshTokensSetInput>;
  where: UserRefreshTokensBoolExp;
}

/** columns and relationships of "users" */
export interface Users {
  /** An object relationship */
  app: Apps;
  app_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  custom_claims?: Maybe<Scalars["jsonb"]>;
  id: Scalars["uuid"];
  /** An array relationship */
  identity_emails: Array<IdentityEmail>;
  /** An aggregate relationship */
  identity_emails_aggregate: IdentityEmailAggregate;
  /** An array relationship */
  identity_oauths: Array<IdentityOauth>;
  /** An aggregate relationship */
  identity_oauths_aggregate: IdentityOauthAggregate;
  /** An array relationship */
  refresh_tokens: Array<UserRefreshTokens>;
  /** An aggregate relationship */
  refresh_tokens_aggregate: UserRefreshTokensAggregate;
  updated_at: Scalars["timestamptz"];
}

/** columns and relationships of "users" */
export interface UsersCustomClaimsArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "users" */
export interface UsersIdentityEmailsArgs {
  distinct_on?: Maybe<Array<IdentityEmailSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<IdentityEmailOrderBy>>;
  where?: Maybe<IdentityEmailBoolExp>;
}

/** columns and relationships of "users" */
export interface UsersIdentityEmailsAggregateArgs {
  distinct_on?: Maybe<Array<IdentityEmailSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<IdentityEmailOrderBy>>;
  where?: Maybe<IdentityEmailBoolExp>;
}

/** columns and relationships of "users" */
export interface UsersIdentityOauthsArgs {
  distinct_on?: Maybe<Array<IdentityOauthSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<IdentityOauthOrderBy>>;
  where?: Maybe<IdentityOauthBoolExp>;
}

/** columns and relationships of "users" */
export interface UsersIdentityOauthsAggregateArgs {
  distinct_on?: Maybe<Array<IdentityOauthSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<IdentityOauthOrderBy>>;
  where?: Maybe<IdentityOauthBoolExp>;
}

/** columns and relationships of "users" */
export interface UsersRefreshTokensArgs {
  distinct_on?: Maybe<Array<UserRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserRefreshTokensOrderBy>>;
  where?: Maybe<UserRefreshTokensBoolExp>;
}

/** columns and relationships of "users" */
export interface UsersRefreshTokensAggregateArgs {
  distinct_on?: Maybe<Array<UserRefreshTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UserRefreshTokensOrderBy>>;
  where?: Maybe<UserRefreshTokensBoolExp>;
}

/** aggregated selection of "users" */
export interface UsersAggregate {
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
}

/** aggregate fields of "users" */
export interface UsersAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
}

/** aggregate fields of "users" */
export interface UsersAggregateFieldsCountArgs {
  columns?: Maybe<Array<UsersSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface UsersAppendInput {
  custom_claims?: Maybe<Scalars["jsonb"]>;
}

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export interface UsersBoolExp {
  _and?: Maybe<Array<UsersBoolExp>>;
  _not?: Maybe<UsersBoolExp>;
  _or?: Maybe<Array<UsersBoolExp>>;
  app?: Maybe<AppsBoolExp>;
  app_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  custom_claims?: Maybe<JsonbComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  identity_emails?: Maybe<IdentityEmailBoolExp>;
  identity_emails_aggregate?: Maybe<IdentityEmailAggregateBoolExp>;
  identity_oauths?: Maybe<IdentityOauthBoolExp>;
  identity_oauths_aggregate?: Maybe<IdentityOauthAggregateBoolExp>;
  refresh_tokens?: Maybe<UserRefreshTokensBoolExp>;
  refresh_tokens_aggregate?: Maybe<UserRefreshTokensAggregateBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "users" */
export enum UsersConstraint {
  /** unique or primary key constraint on columns "id" */
  users_pkey = "users_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface UsersDeleteAtPathInput {
  custom_claims?: Maybe<Array<Scalars["String"]>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface UsersDeleteElemInput {
  custom_claims?: Maybe<Scalars["Int"]>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface UsersDeleteKeyInput {
  custom_claims?: Maybe<Scalars["String"]>;
}

/** input type for inserting data into table "users" */
export interface UsersInsertInput {
  app?: Maybe<AppsObjRelInsertInput>;
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  custom_claims?: Maybe<Scalars["jsonb"]>;
  id?: Maybe<Scalars["uuid"]>;
  identity_emails?: Maybe<IdentityEmailArrRelInsertInput>;
  identity_oauths?: Maybe<IdentityOauthArrRelInsertInput>;
  refresh_tokens?: Maybe<UserRefreshTokensArrRelInsertInput>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** aggregate max on columns */
export interface UsersMaxFields {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** aggregate min on columns */
export interface UsersMinFields {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** response of any mutation on the table "users" */
export interface UsersMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
}

/** input type for inserting object relation for remote table "users" */
export interface UsersObjRelInsertInput {
  data: UsersInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<UsersOnConflict>;
}

/** on_conflict condition type for table "users" */
export interface UsersOnConflict {
  constraint: UsersConstraint;
  update_columns?: Array<UsersUpdateColumn>;
  where?: Maybe<UsersBoolExp>;
}

/** Ordering options when selecting data from "users". */
export interface UsersOrderBy {
  app?: Maybe<AppsOrderBy>;
  app_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  custom_claims?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  identity_emails_aggregate?: Maybe<IdentityEmailAggregateOrderBy>;
  identity_oauths_aggregate?: Maybe<IdentityOauthAggregateOrderBy>;
  refresh_tokens_aggregate?: Maybe<UserRefreshTokensAggregateOrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** primary key columns input for table: users */
export interface UsersPkColumnsInput {
  id: Scalars["uuid"];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface UsersPrependInput {
  custom_claims?: Maybe<Scalars["jsonb"]>;
}

/** select columns of table "users" */
export enum UsersSelectColumn {
  /** column name */
  app_id = "app_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  custom_claims = "custom_claims",
  /** column name */
  id = "id",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "users" */
export interface UsersSetInput {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  custom_claims?: Maybe<Scalars["jsonb"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** Streaming cursor of the table "users" */
export interface UsersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: UsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface UsersStreamCursorValueInput {
  app_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  custom_claims?: Maybe<Scalars["jsonb"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** update columns of table "users" */
export enum UsersUpdateColumn {
  /** column name */
  app_id = "app_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  custom_claims = "custom_claims",
  /** column name */
  id = "id",
  /** column name */
  updated_at = "updated_at",
}

export interface UsersUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<UsersAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<UsersDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<UsersDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<UsersDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<UsersPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<UsersSetInput>;
  where: UsersBoolExp;
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export interface UuidComparisonExp {
  _eq?: Maybe<Scalars["uuid"]>;
  _gt?: Maybe<Scalars["uuid"]>;
  _gte?: Maybe<Scalars["uuid"]>;
  _in?: Maybe<Array<Scalars["uuid"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["uuid"]>;
  _lte?: Maybe<Scalars["uuid"]>;
  _neq?: Maybe<Scalars["uuid"]>;
  _nin?: Maybe<Array<Scalars["uuid"]>>;
}

export type AppInfoSubscriptionVariables = Exact<{
  appId: Scalars["uuid"];
}>;

export type AppInfoSubscription = { app?: Maybe<{ info?: Maybe<any> }> };

export const AppInfoDocument = gql`
  subscription AppInfo($appId: uuid!) {
    app: apps_by_pk(id: $appId) {
      info: public_info
    }
  }
`;
export function useAppInfoSubscription(
  variables: AppInfoSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    AppInfoSubscription,
    AppInfoSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    AppInfoSubscription,
    AppInfoSubscriptionVariables
  >(AppInfoDocument, { variables, ...baseOptions });
}
