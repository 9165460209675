import {
  AssetMetadataTypesEnum as MetadataFieldTypes,
  MetadataFieldsSubscription,
} from "__gen__/appService";

export type MetadataField = MetadataFieldsSubscription["metadataFields"][0];

export { MetadataFieldTypes };

interface MaybeRequired {
  required?: boolean;
}

export interface MetaFieldInterface<
  Type extends MetadataFieldTypes,
  Options extends MaybeRequired = MaybeRequired
> {
  id?: string;
  type: Type;
  name: string;
  options: Options;
}

interface TagTypeOptions extends MaybeRequired {
  default?: string[] | null;
  possibleValues?: string[] | null;
}

interface TagListOptions extends TagTypeOptions {
  maxNumberOfTags?: number;
}

interface SingleChoiceOptions extends MaybeRequired {
  default?: string | null;
  possibleValues?: string[] | null;
}

interface BooleanOptions extends MaybeRequired {
  default?: boolean;
  required?: boolean;
}

export type MetaFieldSimpleText = MetaFieldInterface<
  MetadataFieldTypes.SIMPLE_TEXT
>;
export type MetaFieldDate = MetaFieldInterface<MetadataFieldTypes.DATE>;
export type MetaFieldNumber = MetaFieldInterface<MetadataFieldTypes.NUMBER>;
export type MetaFieldMultipleChoice = MetaFieldInterface<
  MetadataFieldTypes.MULTIPLE_CHOICE,
  TagTypeOptions
>;
export type MetaFieldTagList = MetaFieldInterface<
  MetadataFieldTypes.TAG_LIST,
  TagListOptions
>;
export type MetaFieldSingleChoice = MetaFieldInterface<
  MetadataFieldTypes.SINGLE_CHOICE,
  SingleChoiceOptions
>;
export type MetaFieldBoolean = MetaFieldInterface<
  MetadataFieldTypes.BOOLEAN,
  BooleanOptions
>;

export type MetaField =
  | MetaFieldSimpleText
  | MetaFieldBoolean
  | MetaFieldDate
  | MetaFieldNumber
  | MetaFieldMultipleChoice
  | MetaFieldSingleChoice
  | MetaFieldTagList;

const isOfType = <FieldType extends MetaField, Type = MetadataFieldTypes>(
  type: Type,
) => (field: MetaFieldInterface<any>): field is FieldType =>
  field.type === type;

export const isSimpleTextMeta = isOfType<MetaFieldSimpleText>(
  MetadataFieldTypes.SIMPLE_TEXT,
);
export const isBooleanMeta = isOfType<MetaFieldBoolean>(
  MetadataFieldTypes.BOOLEAN,
);
export const isDateMeta = isOfType<MetaFieldDate>(MetadataFieldTypes.DATE);
export const isNumberMeta = isOfType<MetaFieldNumber>(
  MetadataFieldTypes.NUMBER,
);
export const isMultipleChoiceMeta = isOfType<MetaFieldMultipleChoice>(
  MetadataFieldTypes.MULTIPLE_CHOICE,
);
export const isSingleChoiceMeta = isOfType<MetaFieldSingleChoice>(
  MetadataFieldTypes.SINGLE_CHOICE,
);
export const isTagListMeta = isOfType<MetaFieldTagList>(
  MetadataFieldTypes.TAG_LIST,
);
