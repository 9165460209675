import { Dropdown, Tooltip } from "antd";
import Avatar from "components/common/avatar/Avatar";
import AdjustingButton from "components/common/buttons/AdjustingButton";
import DropdownOverlay from "components/common/layout/DropdownOverlay";
import useOrganizationNames from "components/organizations/useOrganizationNames";
import head from "functions/head";
import Paths from "navigation/Paths";
import React, { useEffect } from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import UserState from "state/UserState";
import styled from "styled-components";

const OrgButton = styled(AdjustingButton)<{ selected?: boolean }>`
  margin: ${({ theme }) => theme.margin.small};
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  border: none;
`;

const AvatarContainer = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.margin.small};
`;

const AvatarButton = styled(Avatar)`
  cursor: pointer;
`;

export default withRouter(
  ({ history, location: { pathname } }: RouteComponentProps) => {
    const { selectOrgId, selectedOrgId } = UserState.useContainer();
    const { names, loading, error } = useOrganizationNames();
    const selectedOrg = names.find(({ id }) => id === selectedOrgId);

    useEffect(() => {
      const firstOrg = head(names);
      if (!selectedOrgId && firstOrg) {
        selectOrgId(firstOrg?.id);
      }
    }, [JSON.stringify({ names, selectedOrgId })]);

    if (names.length === 0 && !loading && !error) {
      return <Redirect to={Paths.newOrganization.withParams()} />;
    }

    const onSelectOrg = (orgId: string) => {
      selectOrgId(orgId);
      const newPath = ["/", pathname.split("/")[1] || ""].join("");
      if (newPath !== pathname) {
        history.push(newPath);
      }
    };

    return names.length < 2 && !loading ? null : (
      <Dropdown
        overlay={
          <DropdownOverlay>
            {names.map(({ name, id }) => (
              <Tooltip placement="right" title={name} key={id}>
                <OrgButton
                  selected={id === selectedOrgId}
                  onClick={() => onSelectOrg(id)}
                >
                  <Avatar icon="bank" shape="square" name={name} />
                </OrgButton>
              </Tooltip>
            ))}
          </DropdownOverlay>
        }
        trigger={["hover"]}
      >
        <AvatarContainer>
          <OrgAvatar loading={loading} org={selectedOrg} />
        </AvatarContainer>
      </Dropdown>
    );
  },
);

const OrgAvatar = ({
  loading,
  org,
}: {
  loading?: boolean;
  org?: { name: string };
}) => {
  if (loading) {
    return null;
  }

  if (org) {
    return (
      <Tooltip placement="right" title={org.name}>
        <AvatarButton icon="bank" shape="square" name={org.name} />
      </Tooltip>
    );
  }

  return <AvatarButton icon="delete" shape="square" />;
};
