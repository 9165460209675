import Input, { TextAreaProps } from "antd/lib/input";
import React from "react";

export interface VLTextAreaProps extends TextAreaProps {
  onTextChange?: (text: string) => void;
  onSave?: () => void;
  onCancel?: () => void;
}

export default ({
  onTextChange = () => {},
  onSave = () => {},
  onCancel = () => {},
  ...props
}: VLTextAreaProps) => {
  const onEnter = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.metaKey || ev.ctrlKey) {
      onSave();
    }
  };
  const onKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === "Escape") {
      ev.stopPropagation();
      onCancel();
    }
  };

  return (
    <Input.TextArea
      onPressEnter={onEnter}
      onKeyDown={onKeyDown}
      onChange={ev => onTextChange(`${ev.target.value}`.normalize())}
      {...props}
    />
  );
};
