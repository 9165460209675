import { compile } from "handlebars";

interface Profile {
  width: number;
  height: number;
  bandwidth: number;
  key: string;
}

const defaultPlaylistTemplate = `#EXTM3U
{{#each profiles}}
#EXT-X-STREAM-INF:RESOLUTION={{width}}x{{height}},BANDWIDTH={{bandwidth}},CLOSED-CAPTIONS=NONE
{{streamServerUrl}}/{{key}}/tracks-v1a1/mono.m3u8
{{/each}}`;

export const createPlaylist = ({
  profiles,
  streamServerUrl,
  template,
}: {
  profiles: Profile[];
  streamServerUrl?: string;
  template?: string;
}) => {
  if (!streamServerUrl) {
    return "";
  }
  const profilesWithServerUrl = profiles.map((profile) => ({
    ...profile,
    streamServerUrl,
  }));
  const rendered = compile(template || defaultPlaylistTemplate)({
    profiles: profilesWithServerUrl,
  });
  return rendered;
};

export const createStream = ({
  template,
  templateParams = {},
}: {
  template: string;
  templateParams?: Record<string, unknown>;
}) => {
  const rendered = compile(template)(templateParams);
  return rendered;
};
