import LiveStreamSidebarState from "state/LiveStreamSidebarState";
import RightSidebarState, {
  LIVE_STREAM_DETAILS_SIDEBAR,
} from "state/RightSidebarState";

export default () => {
  const { openSidebar, closeSidebar } = RightSidebarState.useContainer();
  const { selectStream, reset } = LiveStreamSidebarState.useContainer();

  const openLiveStreamDetails = (streamKey: string) => {
    selectStream(streamKey);
    openSidebar(LIVE_STREAM_DETAILS_SIDEBAR);
  };

  const close = () => {
    closeSidebar();
    reset();
  };

  return {
    openLiveStreamDetails,
    close,
  };
};
