import { head, intersection, uniq } from "ramda";
import {
  isMultipleChoiceMeta,
  isSingleChoiceMeta,
  isTagListMeta,
  MetadataFieldTypes,
  MetaField
} from "./MetadataFieldTypes";

export default (field: MetaField, newType: MetadataFieldTypes): MetaField => {
  if (
    (newType === MetadataFieldTypes.MULTIPLE_CHOICE ||
      newType === MetadataFieldTypes.SINGLE_CHOICE ||
      newType === MetadataFieldTypes.TAG_LIST) &&
    (isTagListMeta(field) ||
      isMultipleChoiceMeta(field) ||
      isSingleChoiceMeta(field))
  ) {
    const { options } = field;
    const possibles: string[] = Array.isArray(options.possibleValues)
      ? uniq(options.possibleValues)
      : [];
    let defaults: string[] = Array.isArray(options.default)
      ? uniq(options.default)
      : [];
    if (isSingleChoiceMeta(field) && !!field.options.default) {
      defaults = [field.options.default];
    }
    const restrictValues = possibles.length > 0;
    const newDefaultValues = restrictValues
      ? intersection(defaults, possibles)
      : defaults;
    if (newType === MetadataFieldTypes.SINGLE_CHOICE) {
      return {
        ...field,
        type: newType,
        options: {
          required: field.options.required,
          default: head(newDefaultValues)
        }
      };
    }
    return {
      ...field,
      type: newType,
      options: {
        required: field.options.required,
        default: newDefaultValues
      }
    };
  }
  return {
    ...field,
    type: newType,
    options: {
      required: field.options.required
    }
  };
};
