import ScreenHeader from "components/common/screen/ScreenHeader";
import OrganizationTable from "components/tables/OrganizationTable";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import AppScreen from "./AppScreen";

export default withRouter(({ history }: RouteComponentProps) => (
  <AppScreen>
    <ScreenHeader
      title={strings("screens.organizations.title")}
      buttonText={strings("screens.organizations.newOrganizationButton")}
      buttonTo={Paths.newOrganization.path}
    />
    <OrganizationTable
      onOrgClick={org =>
        history.push(
          Paths.organizationDetails.withParams({ orgSlug: org.slug })
        )
      }
    />
  </AppScreen>
));
