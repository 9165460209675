import { Select } from "antd";
import VLButton from "components/common/buttons/VLButton";
import { Row } from "components/common/layout/Flex";
import strings from "localisation/strings";
import { differenceWith, flatten, innerJoin } from "ramda";
import React, { useState } from "react";
import styled from "styled-components";
import uuid from "uuid/v4";
import { Maybe, useOrganizationMembersSubscription } from "__gen__/appService";
import joinNameEmail from "./functions/joinNameEmail";

const SelectComponent = styled(Select)`
  min-width: 200px;
  max-width: 600px;
  flex: 1;
  margin-right: ${({ theme }) => theme.margin.medium};
`;

export interface OrgMember {
  id: string;
  user: {
    id: string;
    name?: Maybe<string>;
    email?: Maybe<string>;
  };
}

interface MemberSelectProps {
  orgSlug: string;
  excludedMemberIds: string[];
  onMembersAdded: (members: OrgMember[]) => void;
}

export default ({
  orgSlug,
  excludedMemberIds,
  onMembersAdded,
}: MemberSelectProps) => {
  const { data } = useOrganizationMembersSubscription({ orgSlug });
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [key, setKey] = useState(uuid());

  const members = flatten(
    data?.organizations?.map((org) => org.orgMembers) || [],
  );

  const membersToSelect = differenceWith(
    (member, id) => member.id === id,
    members,
    [...selectedMembers, ...excludedMemberIds],
  );

  const onAdd = () => {
    const addedMembers = innerJoin(
      (member, id) => member.id === id,
      members,
      selectedMembers,
    );

    onMembersAdded(addedMembers);
    setSelectedMembers([]);
    setKey(uuid());
  };

  return (
    <Row mainAxis="flex-end">
      <SelectComponent
        key={key}
        optionFilterProp="children"
        onChange={(values) => setSelectedMembers(values as any)}
        mode="multiple"
        placeholder={strings("organizationMembers.addMembersInput")}
      >
        {membersToSelect.map(({ id, user }) => (
          <Select.Option key={id} value={id}>
            {joinNameEmail(user)}
          </Select.Option>
        ))}
      </SelectComponent>
      <VLButton
        icon="plus"
        type={selectedMembers.length > 0 ? "primary" : undefined}
        onClick={onAdd}
      />
    </Row>
  );
};
