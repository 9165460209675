import { SubscriptionResult } from "@apollo/react-common";
import {
  SubscriptionHookOptions,
  useSubscription as useApolloSubscription,
} from "@apollo/react-hooks";
import { OperationVariables } from "apollo-client";
import { DocumentNode } from "apollo-link";
import { useEffect, useState } from "react";

const useSubscription = <TData = any, TVariables = OperationVariables>(
  document: DocumentNode,
  options?: SubscriptionHookOptions<TData, TVariables>,
): SubscriptionResult<TData> => {
  const [subscriptionData, setSubscriptionData] = useState<
    SubscriptionResult<TData>
  >({ loading: true });
  const shouldResubscribe = options?.shouldResubscribe;
  const res = useApolloSubscription(document, {
    ...options,
    shouldResubscribe: true,
    onSubscriptionData: (d) => setSubscriptionData(d.subscriptionData),
  });

  useEffect(() => {
    if (res.error) {
      setSubscriptionData({ loading: false, error: res.error });
    }
  }, [res.error]);

  useEffect(() => {
    if (shouldResubscribe && res.loading) {
      setSubscriptionData({ loading: true });
    }
  }, [res.loading, shouldResubscribe]);

  return {
    ...subscriptionData,
    loading: subscriptionData.loading,
  };
};

export default useSubscription;
