import { Column, Row } from "components/common/layout/Flex";
import { MarginedDivider } from "components/common/layout/MarginedDivider";
import { append, dropLast, last, toPairs } from "ramda";
import React, { useState } from "react";
import ContentStore from "store/ContentStore";
import ContentBreadcrumbs from "./ContentBreadcrumbs";
import directivesToObject from "./functions/directivesToObject";
import MainTabSelect from "./MainTabSelect";
import PublishButton from "./preview/PublishButton";
import ResourcePreview from "./preview/ResourcePreview";

const DEFAULT_TAB_KEY = "default";

export default ({ projectId }: { projectId: string }) => {
  const { getContentType, getContentRow } = ContentStore.useContainer();
  const [selectedTabKey, setSelectedTabKey] = useState(DEFAULT_TAB_KEY);
  const [activeContentPath, setActiveContentPath] = useState([projectId]);
  const contentRow = getContentRow(last(activeContentPath) || "");
  const contentType = getContentType(contentRow?.type || "");

  if (!contentType || !contentRow) {
    return null;
  }

  const directives = directivesToObject(
    contentType.getType().astNode?.directives,
  );

  const tabKey =
    selectedTabKey === DEFAULT_TAB_KEY ? undefined : selectedTabKey;

  const onOpenContent = (contentId: string) => {
    setActiveContentPath(append(contentId));
    setSelectedTabKey(DEFAULT_TAB_KEY);
  };

  return (
    <Column>
      <ContentBreadcrumbs
        contentPath={activeContentPath}
        onPathChange={setActiveContentPath}
      />
      <Row>
        <MainTabSelect
          onSelect={setSelectedTabKey}
          selectedTabKey={selectedTabKey}
          tabs={
            directives.tabs
              ? toPairs(directives.tabs).map(([key, name]) => ({ key, name }))
              : []
          }
        />
        <MarginedDivider type="vertical" margin="medium" />
        <Column>
          <ResourcePreview
            tabKey={tabKey}
            contentRow={contentRow}
            contentType={contentType}
            onOpenContent={onOpenContent}
            goBack={() =>
              setActiveContentPath((current) => dropLast(1, current))
            }
          />
        </Column>
        {tabKey === undefined && <PublishButton />}
      </Row>
    </Column>
  );
};
