import { Spin } from "antd";
import AppContainer from "components/common/layout/AppContainer";
import RegisterForm from "components/register/RegisterForm";
import Paths from "navigation/Paths";
import React from "react";
import { Redirect } from "react-router";
import AuthState from "state/AuthState";
import styled from "styled-components";

const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.background.primary};
  opacity: 0.3;
`;

const CenteredSpin = styled(Spin)`
  position: absolute;
`;

const RegisterScreen = () => {
  const { authState } = AuthState.useContainer();

  if (authState.status === "loggedIn") {
    return <Redirect to={Paths.dashboard.path} />;
  }

  return (
    <AppContainer oppositeTheme>
      <RegisterForm />
      {authState.status === "loading" && (
        <>
          <LoadingOverlay />
          <CenteredSpin size="large" />
        </>
      )}
    </AppContainer>
  );
};

export default RegisterScreen;
