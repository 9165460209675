import VLInput from "components/common/input/VLInput";
import VLTextArea from "components/common/input/VLTextArea";
import React, { useEffect, useState } from "react";

interface TextFieldProps {
  initialValue?: string;
  onSave: (text?: string) => void;
  placeholder?: string;
  isLongText?: boolean;
  disabled?: boolean;
  input?: any;
}

export default ({
  initialValue,
  onSave: onSaveFromProps,
  isLongText,
  disabled,
  input,
}: TextFieldProps) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onSave = () => {
    onSaveFromProps(value);
  };

  if (isLongText) {
    return (
      <VLTextArea
        autoSize
        onBlur={onSave}
        value={value}
        onTextChange={setValue}
        onSave={onSave}
        disabled={disabled}
      />
    );
  }

  return (
    <VLInput
      {...input}
      onBlur={onSave}
      value={value}
      onTextChange={setValue}
      onSave={onSave}
      disabled={disabled}
    />
  );
};
