import { VlComponentReference } from "./../types";

export const baseUrl =
  "https://vl-dev-public.s3.eu-north-1.amazonaws.com/visitor-site-components/svelte-components/build/dist/components";

export default function useComponentReferences() {
  const references: Array<VlComponentReference> = [
    {
      name: "Title",
      schemaUrl: `${baseUrl}/Title.props.gql`,
      svelteUrl: `${baseUrl}/Title.svelte.js`,
    },
    {
      name: "Gallery",
      schemaUrl: `${baseUrl}/Gallery.props.gql`,
      svelteUrl: `${baseUrl}/Gallery.svelte.js`,
    },
  ];

  return references;
}
