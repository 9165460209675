import VLCard from "components/common/layout/VLCard";
import { useOrgConfig } from "components/hooks/useVlConfig";
import { compile } from "handlebars";
import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  ProjectDataSubscription,
  useEmbedBaseUrlSubscription,
} from "__gen__/appService";

const WrappingSpan = styled.span`
  overflow-wrap: break-word;
`;

interface EmbedLinkProps {
  contentId: string;
  project?: ProjectDataSubscription["project"];
}

export default ({ contentId, project }: EmbedLinkProps) => {
  const params = useParams<any>();
  const { data } = useEmbedBaseUrlSubscription({
    projectId: params.projectId || "",
  });
  const { data: configData } = useOrgConfig();
  configData.defaultConfig?.proto;

  const baseUrl = data?.project?.embedBaseUrl;
  const template = configData.defaultConfig?.proto?.fem?.embedTemplate;

  let embedScript = "";
  if (!baseUrl && !template) {
    return null;
  }
  if (typeof template === "string") {
    embedScript = compile(template)({
      project,
      contentId,
    });
  }
  if (baseUrl) {
    embedScript = `<div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;">
  <iframe src="${baseUrl}/${contentId}" style="position: absolute; top:0; left: 0; width: 100%; height: 100%;" frameborder="0" scrolling="no" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen="true" class="noTransform">
  </iframe>
</div>`;
  }

  return (
    <VLCard>
      <WrappingSpan>{embedScript}</WrappingSpan>
    </VLCard>
  );
};
