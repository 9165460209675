export default ({
  url,
  fileName = "download",
}: {
  url: string;
  fileName?: string;
}) => {
  const link = document.createElement("a");
  link.setAttribute("display", "none");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  if (link.parentNode) {
    link.parentNode.removeChild(link);
  }
};
