import VLButton from "components/common/buttons/VLButton";
import VLInput from "components/common/input/VLInput";
import { Column, Row } from "components/common/layout/Flex";
import Loading from "components/common/layout/Loading";
import { SimpleText } from "components/common/text/SimpleText";
import roleToFolderScope from "components/memberDetails/functions/roleToFolderScope";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import uuid from "uuid/v4";
import {
  PermissionRoleFolderScopesInsertInput,
  PermissionUserGroupMembersInsertInput,
  PermissionUserGroupRolesInsertInput,
  useTeamDetailsSubscription,
  useUpsertTeamMutation,
} from "__gen__/appService";
import { FolderScope } from "./FolderScopeForm";
import FolderScopes from "./FolderScopes";
import { OrgMember } from "./MemberSelect";
import TeamMembers from "./TeamMembers";

interface UpsertTeamProps {
  orgId: string;
}

export default ({ orgId }: UpsertTeamProps) => {
  const match = useRouteMatch<typeof Paths.orgTeamDetails.params>();
  const history = useHistory();
  const [name, setName] = useState("New team");
  const [scopes, setScopes] = useState<FolderScope[]>([]);
  const [members, setMembers] = useState<OrgMember[]>([]);
  const { data, loading, error } = useTeamDetailsSubscription({
    teamId: match.params.teamId,
  });
  const [upsertTeam, { loading: isSaving }] = useUpsertTeamMutation();

  const team = data?.team;

  useEffect(() => {
    if (team) {
      setName(team.name);
      setScopes(team.roles.map(roleToFolderScope));
      setMembers(team.members);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(team)]);

  const onDone = () => {
    history.goBack();
  };

  const onSave = async () => {
    try {
      await upsertTeam({
        variables: {
          teamId: team?.id || uuid(),
          name,
          orgId,
          members: members.map<PermissionUserGroupMembersInsertInput>(
            ({ id, user }) => ({ member_id: id, user_id: user.id }),
          ),
          roles: scopes.map<PermissionUserGroupRolesInsertInput>(
            ({ id, role, expires, folderIds }) => ({
              id,
              expires: expires?.toISOString(),
              role_id: role.id,
              folder_scopes: {
                data: folderIds.map<PermissionRoleFolderScopesInsertInput>(
                  (folderId) => ({
                    role_id: role.id,
                    folder_id: folderId,
                  }),
                ),
              },
            }),
          ),
        },
      });
      onDone();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  if (loading && !error && match.params.teamId !== "new") {
    return <Loading />;
  }

  return (
    <Column>
      <SimpleText fontSize="large" t="common.team" />
      <VLInput
        placeholder={strings("common.name")}
        value={name}
        onTextChange={setName}
      />
      <FolderScopes
        orgId={orgId}
        scopesState={[scopes, setScopes]}
        filterOwnerRoles
      />
      <Column grow={0} marginVertical="large">
        <TeamMembers
          orgSlug={match.params.orgSlug}
          membersState={[members, setMembers]}
        />
      </Column>
      <Row grow={0} mainAxis="flex-end">
        <VLButton
          t="buttons.cancel"
          onClick={onDone}
          marginHorizontal="medium"
        />
        <VLButton
          type="primary"
          t="buttons.save"
          onClick={onSave}
          loading={isSaving}
        />
      </Row>
    </Column>
  );
};
