import { ObjectTypeComposer } from "graphql-compose";
import React from "react";
import { VLLocales } from "vl-app-client/lib/cms/contentInterfaces";
import { StringMapped } from "vl-app-client/lib/util/types";
import directivesToObject from "../functions/directivesToObject";
import FieldInput from "./FieldInput";
import { ResourceInputProps } from "./types";

export default (
  props: ResourceInputProps<StringMapped, ObjectTypeComposer>,
) => {
  const { composer, dataPath, tabKey, locale } = props;
  const directives = directivesToObject(composer.getType().astNode?.directives);

  return (
    <>
      {composer.getFieldNames().map((fieldName) => {
        const fieldComposer = composer.getFieldTC(fieldName);
        const fieldConfig = composer.getFieldConfig(fieldName);

        const fieldDirectives = directivesToObject(
          fieldConfig?.astNode?.directives,
        );


        if (
          !!fieldDirectives.ref ||
          (!!directives.tabs && fieldDirectives.tab?.key !== tabKey)
        ) {
          return null;
        }

        const noLocale = !!fieldDirectives.noLocale;

        return (
          <FieldInput
            {...props}
            key={fieldName}
            composer={fieldComposer}
            isRequired={composer.isFieldNonNull(fieldName)}
            isList={composer.isFieldPlural(fieldName)}
            fieldConfig={fieldConfig}
            dataPath={[...dataPath, fieldName]}
            locale={noLocale ? VLLocales.NO_LOCALE : locale}
          />
        );
      })}
    </>
  );
};
