import {
  isBooleanMeta,
  isMultipleChoiceMeta,
  isSingleChoiceMeta,
  isTagListMeta,
  MetaField
} from "components/assetMetaSpecs/MetadataFieldTypes";
import { isDefined } from "types/predicates";
import { AssetMetaFieldsState, emptyValues } from "../stateMapper";

export default (fields: Array<Required<MetaField>>): AssetMetaFieldsState => {
  return fields.reduce<AssetMetaFieldsState>((state, field) => {
    let values = emptyValues;
    if (isTagListMeta(field) || isMultipleChoiceMeta(field)) {
      values = { ...values, textValues: field.options.default || [] };
    }
    if (isSingleChoiceMeta(field)) {
      values = {
        ...values,
        textValues: [field.options.default].filter(isDefined)
      };
    }
    if (isBooleanMeta(field)) {
      values = {
        ...values,
        booleanValues: [field.options.default].filter(isDefined)
      };
    }

    return { ...state, [field.id]: values };
  }, {});
};
