import { SimpleText } from "components/common/text/SimpleText";
import { EnumTypeComposer } from "graphql-compose";
import React from "react";
import { isDefined } from "types/predicates";
import FallbackText from "./FallbackText";
import { ResourcePreviewProps } from "./types";

export default (
  props: ResourcePreviewProps<string | string[] | undefined, EnumTypeComposer>,
) => {
  const { composer, resolveValue, resolveFallback } = props;
  const value = resolveValue(props);
  const fallbackValue = resolveFallback(props);

  const optionValues = Array.isArray(fallbackValue)
    ? fallbackValue
    : [fallbackValue].filter(isDefined);
  const labels = optionValues
    .map((key) => {
      const field = composer.getField(key);
      return field.description || field.value;
    })
    .join(", ");

  return value ? (
    <SimpleText>{labels}</SimpleText>
  ) : (
    <FallbackText>{labels}</FallbackText>
  );
};
