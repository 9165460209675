import { pathOr } from "ramda";
import {
  useCheckOrgSlugLazyQuery,
  useCheckProjectSlugLazyQuery,
} from "__gen__/appService";

const extractCount = pathOr(0, ["slugs", "total", "count"]);

interface OrgSlugParams {
  type: "orgSlug";
}

interface ProjectSlugParams {
  type: "projectSlug";
  orgId: string;
}

export const useCheckSlug = (params: OrgSlugParams | ProjectSlugParams) => {
  const [checkOrgSlug, { data: orgSlugData }] = useCheckOrgSlugLazyQuery();
  const [
    checkProjectSlug,
    { data: projectSlugData },
  ] = useCheckProjectSlugLazyQuery();

  const checkSlug = (slug: string) => {
    if (params.type === "projectSlug") {
      return checkProjectSlug({ variables: { orgId: params.orgId, slug } });
    }
    return checkOrgSlug({ variables: { slug } });
  };

  const data = params.type === "projectSlug" ? projectSlugData : orgSlugData;
  const sameSlugCount = extractCount(data);

  return { checkSlug, sameSlugCount };
};
