import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";
import { SimpleText } from "components/common/text/SimpleText";
import FolderTree from "components/folderBrowser/FolderTree";
import FolderText from "components/folderBrowser/folderTreeNodes/FolderText";
import head from "functions/head";
import strings from "localisation/strings";
import { pluck } from "ramda";
import React, { useState } from "react";
import UserState from "state/UserState";
import { isDefined } from "types/predicates";
import {
  useEditableFoldersSubscription,
  useMoveAssetsMutation,
} from "__gen__/appService";

interface MoveAssetsModalProps extends ModalProps {
  assetIds: string[];
  onDone?: () => void;
}

export default ({
  assetIds,
  onDone = () => {},
  ...props
}: MoveAssetsModalProps) => {
  const [targetFolder, setTargetFolder] = useState<string>();
  const { user, selectedOrgId } = UserState.useContainer();
  const [moveAssets, { loading: isMoving }] = useMoveAssetsMutation();
  const { data: editableFoldersData } = useEditableFoldersSubscription({
    userId: user?.id || "",
    orgId: selectedOrgId,
  });
  const editableFolders = pluck(
    "folderId",
    editableFoldersData?.editableFolders || [],
  ).filter(isDefined);

  const onModalOk = async () => {
    if (!!targetFolder) {
      await moveAssets({
        variables: { assetIds, toFolder: targetFolder },
      });
    }
    onDone();
  };

  return (
    <Modal
      onOk={onModalOk}
      onCancel={onDone}
      cancelText={strings("buttons.cancel")}
      okText={strings("common.move")}
      okButtonProps={{ disabled: !targetFolder, loading: isMoving }}
      {...props}
    >
      <SimpleText t="common.toFolder" color="tertiary" />
      <FolderTree
        onSelect={(selected) => {
          const id = head(selected);
          if (
            typeof id === "string" &&
            editableFolders.indexOf(id || "") > -1
          ) {
            setTargetFolder(id);
          }
        }}
        selectedKeys={[targetFolder].filter(isDefined)}
        useLinks={false}
        filter={(folder) => assetIds.indexOf(folder.id) === -1}
        nodeComponent={({ folder }) => (
          <FolderText
            folder={folder}
            disabled={editableFolders.indexOf(folder.id) === -1}
          />
        )}
      />
    </Modal>
  );
};
