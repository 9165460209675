import Env from "config/Env";
import downloadFileWithUrl from "functions/downloadFileWithUrl";
import { pluck } from "ramda";
import DownloadState from "state/DownloadState";
import UserState from "state/UserState";
import FolderStore from "store/FolderStore";
import createTuple from "types/createTuple";
import {
  ArchiveFilesInsertInput,
  useCreateArchiveJobMutation,
} from "__gen__/appService";

type Download = {
  folderId: string;
  assetFiles?: ArchiveFilesInsertInput[];
};

const directArchiverUrl = Env.REACT_APP_ARCHIVER_URL;

const usesDirectDownload = typeof directArchiverUrl === "string" && directArchiverUrl.length > 1;

export default () => {
  const { defaultWarehouseId, getFolderName, rootFolder } = FolderStore.useContainer();
  const { selectedOrgId } = UserState.useContainer();
  const { addPendingArchives } = DownloadState.useContainer();
  const [createArchiveJob, result] = useCreateArchiveJobMutation();

  const onDownload = async (download: Download) => {
    const { data } = await createArchiveJob({
      variables: {
        orgId: selectedOrgId,
        outputWarehouseId: defaultWarehouseId,
        outputName: getFolderName(download.folderId) || "",
        assetFiles: download.assetFiles || [],
        folderId: download.folderId || rootFolder?.id || "",
        proto: { isDirect: usesDirectDownload },
      },
    });
    const addedArchives =
      (data && data.result && data.result.archiveJobs) || [];
    if (usesDirectDownload) {
      addedArchives.map((job) =>
        downloadFileWithUrl({
          url: `${Env.REACT_APP_ARCHIVER_URL}/?jobId=${job.id}`,
        }),
      );
    } else {
      addPendingArchives(pluck("id", addedArchives));
    }
  };

  return createTuple(onDownload, result);
};
