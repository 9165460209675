import { createContainer } from "unstated-next";
import usePersistedState from "./usePersistedState";

const useSidebarState = () => {
  const [isSidebarOpen, setIsSidebarOpen] = usePersistedState(
    "VLSidebarState",
    true
  );

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return { isSidebarOpen, toggleSidebar };
};

export default createContainer(useSidebarState);
