import { VLAsset } from "components/mediaAssets/types";
import React from "react";
import MediaThumbnail, { MediaThumbnailProps } from "./MediaThumbnail";

interface AssetProps extends Omit<MediaThumbnailProps, "asset"> {
  asset: VLAsset;
}

export default ({ asset, imagePlaceholder, ...props }: AssetProps) => {
  if (
    asset.type === "VLFolder" ||
    (asset.type !== "IMAGE" && asset.type !== "VIDEO")
  ) {
    return imagePlaceholder || null;
  }

  return <MediaThumbnail asset={asset} {...props} />;
};
