import { Tag } from "antd";
import { SimpleText } from "components/common/text/SimpleText";
import React from "react";
import {
  isBooleanMeta,
  isMultipleChoiceMeta,
  isSingleChoiceMeta,
  isTagListMeta,
  MetaField
} from "./MetadataFieldTypes";

export default ({ field }: { field: MetaField }) => {
  if (
    (isTagListMeta(field) || isMultipleChoiceMeta(field)) &&
    !!field.options.default &&
    field.options.default.length > 0
  ) {
    return (
      <>
        {field.options.default.map(value => (
          <Tag color="geekblue" key={value}>
            {value}
          </Tag>
        ))}
      </>
    );
  }

  if (isSingleChoiceMeta(field) && !!field.options.default) {
    return <Tag color="geekblue">{field.options.default}</Tag>;
  }

  if (isBooleanMeta(field)) {
    const { default: defaultValue } = field.options;
    if (defaultValue === true) {
      return <SimpleText t="common.true" />;
    }
    if (defaultValue === false) {
      return <SimpleText t="common.false" />;
    }
  }

  return <SimpleText color="tertiary" t="metadataSpecs.noDefault" />;
};
