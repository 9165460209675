import { TypeMap } from "@vldev/simple-type/lib/v2";
import { ValidationError } from "components/pageBuilder/types";
import { createContainer } from "unstated-next";

export default createContainer(
  (
    initialState:
      | { componentTypeMap: TypeMap; errors: ValidationError[] }
      | undefined,
  ) => {
    if (initialState === undefined) {
      throw Error;
    }
    return initialState;
  },
);
