import React from "react";
import styled from "styled-components";
import Avatar, { AvatarProps } from "../avatar/Avatar";
import { VLIcons } from "../VLIcon";
import MediaAssetTypes from "./MediaAssetTypes";

const icons: { [key: string]: string } & Record<
  MediaAssetTypes | "VLFolder",
  VLIcons
> = {
  [MediaAssetTypes.IMAGE]: "file-image",
  [MediaAssetTypes.VIDEO]: "play-circle",
  [MediaAssetTypes.AUDIO]: "audio",
  [MediaAssetTypes.FILE]: "file",
  [MediaAssetTypes.UNKNOWN]: "setting",
  VLFolder: "folder",
};

const TypeAvatar = styled(Avatar)<{ type: MediaAssetTypes | "VLFolder" }>`
  background-color: ${({ theme, type }) => theme.color.mediaTypes[type]};
  color: ${({ theme }) => theme.color.foreground.opposite};
`;

interface MediaTypeIconProps extends AvatarProps {
  type: MediaAssetTypes | "VLFolder";
}

export default ({ type, ...props }: MediaTypeIconProps) => (
  <TypeAvatar
    size="large"
    shape="square"
    type={type}
    icon={icons[type]}
    {...props}
  />
);
