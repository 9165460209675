import { ApolloProvider } from "@apollo/react-hooks";
import AppGatewayClient from "api/AppGatewayClient";
import AppServiceClient from "api/AppServiceClient";
import LoadingScreen from "components/common/layout/LoadingScreen";
import React, { ReactNode, useEffect } from "react";
import AuthState from "state/AuthState";
import GraphQLClientState from "state/GraphQLClientState";
import UserState from "state/UserState";

export interface HasuraProviderProps {
  children?: ReactNode;
}

let currentToken = "";
const getAuthToken = () => currentToken;

const HasuraProvider = ({ children }: HasuraProviderProps) => {
  const {
    authState,
    tryRefreshingToken: triggerRefresh
  } = AuthState.useContainer();
  const { userState } = UserState.useContainer();
  const {
    hasuraClient,
    setHasuraClient,
    setApiClient,
    apiClient
  } = GraphQLClientState.useContainer();
  const { selectedRole: userRole } = userState;
  currentToken = authState.token || "";

  const stopClients = () => {
    if (hasuraClient) {
      hasuraClient.stop();
    }
    if (apiClient) {
      apiClient.stop();
    }
  };

  useEffect(() => {
    stopClients();
    setHasuraClient(
      new AppServiceClient({
        triggerRefresh,
        userRole,
        getAuthToken
      })
    );
    setApiClient(new AppGatewayClient({ userRole, getAuthToken }));
    // eslint-disable-next-line
  }, [authState.token]);

  if (authState.status === "loggedOut" && hasuraClient) {
    stopClients();
    setHasuraClient(undefined);
    setApiClient(undefined);
  }

  if (!hasuraClient) {
    return <LoadingScreen />;
  }

  return <ApolloProvider client={hasuraClient}>{children}</ApolloProvider>;
};

export default HasuraProvider;
