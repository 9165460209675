import { Form, InputNumber } from "antd";
import { Rule } from "antd/lib/form";
import VLInput from "components/common/input/VLInput";
import VLTextArea from "components/common/input/VLTextArea";
import React from "react";
import styled from "styled-components";

interface FormFieldProps {
  fieldKey: string;
  label: string;
  rules?: Rule[];
  type?: "number" | "textArea";
}

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

export default ({ fieldKey, label, rules, type }: FormFieldProps) => {
  let component = <VLInput placeholder={label} />;
  if (type === "number") {
    component = <FullWidthInputNumber placeholder={label} type="number" />;
  }
  if (type === "textArea") {
    component = (
      <VLTextArea placeholder={label} autoSize={{ minRows: 2, maxRows: 6 }} />
    );
  }
  return (
    <Form.Item name={fieldKey} label={label} rules={rules}>
      {component}
    </Form.Item>
  );
};
