import { useEffect, useState } from "react";
import { VlComponentDefinition } from "../functions/parseComponentDefinition";
import { getComponentSchema } from "../functions/getComponentSchema";

export const useComponentSchema = (schemaUrl: string) => {
  const [
    parsedDefinition,
    setParsedDefinition,
  ] = useState<VlComponentDefinition>();

  useEffect(() => {
    getComponentSchema(schemaUrl)
      .then(setParsedDefinition)
      .catch((err) => {
        // TODO show some error to user
      });
  }, [schemaUrl]);
  return parsedDefinition;
};
