import { StringMapped } from "types/common";
import { isDefined } from "types/predicates";
import { FormEnumType, FormObjectType } from "../types";

const getDefaultEnumValue = (enumType: FormEnumType) => {
  const option = enumType.options.find((o) => isDefined(o.directives.default));
  return option?.value;
};

const formToDefaultState = (form: FormObjectType): any => {
  return form.fields.reduce((acc, field) => {
    if (field.type.type === "object") {
      return { ...acc, [field.name]: formToDefaultState(field.type) };
    }
    if (field.type.type === "enum") {
      const defaultEnumValue = getDefaultEnumValue(field.type);
      if (defaultEnumValue) {
        return { ...acc, [field.name]: defaultEnumValue };
      }
    }
    return acc;
  }, {} as StringMapped);
};

export default formToDefaultState;
