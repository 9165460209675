import BinnedMediaBrowser from "components/assetBrowser/BinnedAssets";
import OrgAssetsSearch from "components/assetBrowser/OrgAssetsSearch";
import { Column, Row } from "components/common/layout/Flex";
import { MarginedDivider } from "components/common/layout/MarginedDivider";
import Paths from "navigation/Paths";
import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import ExistingAssetsBrowser from "../assetBrowser/ExistingAssetsBrowser";
import FolderTreeContent from "./FolderTreeContent";

const FolderTreeWrapper = styled.div<FolderTreeWrapperProps>`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 400px;
  ${({ width }) => (width ? `width: ${width}px;` : "")};
`;

const DividerWrapper = styled.div`
  cursor: w-resize;
`;

const Divider = styled(MarginedDivider)`
  height: 100%;
`;

const BrowserContainer = styled(Column)`
  overflow: auto;
`;

interface MediaAssetsProps {
  folderId: string;
}

interface FolderTreeWrapperProps {
  width?: number;
}

export default ({ folderId }: MediaAssetsProps) => {
  const match = useRouteMatch();
  const [width, setWidth] = useState<number>(200);

  const isBin = match.path === Paths.binnedMediaAssets.path;
  const isSearch = match.path === Paths.assetsSearch.path;

  const resizeHandler = (e: any) => {
    setWidth(e.clientX - 40);
  };

  const onMouseUp = () => {
    document.removeEventListener("mousemove", resizeHandler, false);
    document.removeEventListener("mouseup", onMouseUp, false);
  };

  const onMouseDown = () => {
    document.addEventListener("mousemove", resizeHandler, false);
    document.addEventListener("mouseup", onMouseUp, false);
  };

  return (
    <Row>
      <FolderTreeWrapper width={width}>
        <FolderTreeContent
          folderId={folderId}
          isBin={isBin}
          isSearch={isSearch}
        />
      </FolderTreeWrapper>
      <DividerWrapper onMouseDown={onMouseDown}>
        <Divider type="vertical" margin="medium" />
      </DividerWrapper>
      {!isBin && !isSearch && (
        <BrowserContainer>
          <ExistingAssetsBrowser folderId={folderId} />
        </BrowserContainer>
      )}
      {isBin && (
        <BrowserContainer>
          <BinnedMediaBrowser />
        </BrowserContainer>
      )}
      {isSearch && <OrgAssetsSearch />}
    </Row>
  );
};
