import AppServiceClient from "api/AppServiceClient";
import ApolloClient from "apollo-client";
import { useState } from "react";
import { createContainer } from "unstated-next";

const useGraphQLClientState = () => {
  const [hasuraClient, setHasuraClient] = useState<AppServiceClient>();
  const [apiClient, setApiClient] = useState<ApolloClient<any>>();

  return { hasuraClient, apiClient, setHasuraClient, setApiClient };
};

export default createContainer(useGraphQLClientState);
