import { TextButton } from "components/common/buttons/VLButton";
import React from "react";
import AuthState from "state/AuthState";

const SignOutButton = () => {
  const { signOut } = AuthState.useContainer();
  return <TextButton icon="poweroff" t="logOut" onClick={signOut} />;
};

export default SignOutButton;
