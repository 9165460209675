import { SimpleText } from "components/common/text/SimpleText";
import React from "react";
import directivesToObject from "../functions/directivesToObject";
import FallbackText from "./FallbackText";
import TagListPreview from "./TagListPreview";
import { ResourcePreviewProps } from "./types";

export default (props: ResourcePreviewProps) => {
  const { resolveValue, resolveFallback, fieldConfig } = props;
  const value = resolveValue(props);
  const fallbackValue = resolveFallback(props);
  const directives = directivesToObject(fieldConfig?.astNode?.directives);

  if (directives.input?.type === "tags") {
    return <TagListPreview {...props} />;
  }

  return value ? (
    <SimpleText style={{ whiteSpace: "pre" }}>{value || ""}</SimpleText>
  ) : (
    <FallbackText style={{ whiteSpace: "pre" }}>
      {fallbackValue || ""}
    </FallbackText>
  );
};
