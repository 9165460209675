import { Layout } from "antd";
import MediaAssetDetails from "components/assetDetails/MediaAssetDetails";
import VLButton from "components/common/buttons/VLButton";
import { Column } from "components/common/layout/Flex";
import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useMediaAssetSidebar from "state/hooks/useMediaAssetSidebar";
import RightSidebarState, {
  MEDIA_ASSET_SIDEBAR,
  RightSidebarType,
} from "state/RightSidebarState";
import styled from "styled-components";

const SidebarContainer = styled(Layout.Sider)`
  &&& {
    background-color: ${({ theme }) => theme.color.background.primary};
    border-left: 1px solid ${({ theme }) => theme.color.foreground.border};
    overflow-y: scroll;
  }

  .ant-layout-sider-children {
    height: auto;
  }
`;

interface RightSidebarProps {
  renderers?: Renderer[];
}

interface Renderer {
  type: RightSidebarType;
  component: ReactNode;
}

export const RightSidebar = ({ renderers }: RightSidebarProps) => {
  const {
    isSidebarOpen,
    sidebarType,
    closeSidebar,
  } = RightSidebarState.useContainer();
  const { closeMediaSidebar, selectedAssetId } = useMediaAssetSidebar();
  const location = useLocation();

  useEffect(() => {
    if (isSidebarOpen) {
      closeMediaSidebar();
      closeSidebar();
    }
    // eslint-disable-next-line
  }, [location.pathname, location.search]);

  return (
    <SidebarContainer
      collapsed={!isSidebarOpen}
      width={400}
      collapsible
      collapsedWidth={0}
      trigger={null}
    >
      <Column margin="medium">
        <VLButton
          style={{ alignSelf: "flex-start" }}
          icon="menu-unfold"
          onClick={closeMediaSidebar}
        />
        {sidebarType === MEDIA_ASSET_SIDEBAR && (
          <MediaAssetDetails mediaAssetId={selectedAssetId} />
        )}
        {renderers?.map(({ type, component }) => {
          if (type === sidebarType) {
            return component;
          }
          return null;
        })}
      </Column>
    </SidebarContainer>
  );
};
