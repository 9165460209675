import DescriptionItem from "components/common/descriptions/DescriptionItem";
import ErrorBoundary from "components/common/ErrorBoundary";
import { Column, SpreadRow } from "components/common/layout/Flex";
import Loading from "components/common/layout/Loading";
import { SimpleText } from "components/common/text/SimpleText";
import useDateFns from "components/hooks/useDateFns";
import { useOrgConfig } from "components/hooks/useVlConfig";
import VideoPlayer from "components/player/VideoPlayer";
import { compile } from "handlebars";
import strings from "localisation/strings";
import { toUpper } from "ramda";
import React from "react";
import LiveStreamSidebarState from "state/LiveStreamSidebarState";
import styled from "styled-components";
import { useLiveStreamSubscription } from "__gen__/appService";
import LiveStreamActions from "./LiveStreamActions";

const LoadingOverlay = styled(Loading)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff8;
`;

const Container = styled(Column)`
  position: relative;
`;

const StreamConfiguration = styled(Column)`
  background-color: #eee;
  border-radius: 4px;
  padding: 0.5rem;
  margin-top: 0.5rem;
`;

export default function LiveStreamDetails() {
  const { selectedStreamKey } = LiveStreamSidebarState.useContainer();
  const { formatTimeStamp } = useDateFns();
  const { data, loading } = useLiveStreamSubscription({
    streamKey: selectedStreamKey,
  });
  const liveStream = data?.liveStream;
  const isLoading = liveStream?.streamKey !== selectedStreamKey;
  const { data: vlConfigData } = useOrgConfig();

  if (!liveStream && loading) {
    return <LoadingOverlay />;
  }

  if (!liveStream) {
    return null;
  }

  const src = compile(
    vlConfigData?.defaultConfig?.proto?.liveStream?.playbackUrlTemplate || "",
  )({
    playbackKey: liveStream.playbackKey,
  });

  return (
    <Container>
      <SpreadRow>
        <SimpleText as="h3" t="common.details" />
        <LiveStreamActions stream={liveStream} />
      </SpreadRow>
      <DescriptionItem marginVertical="small" labelT="common.title">
        {liveStream.title}
      </DescriptionItem>
      <DescriptionItem labelT="common.preview">
        <ErrorBoundary>
          <VideoPlayer
            controls
            html5={{
              hls: {
                overrideNative: true,
              },
              nativeAudioTracks: false,
              nativeVideoTracks: false,
            }}
            sources={[
              {
                src,
                type: "application/x-mpegURL",
              },
            ]}
          />
        </ErrorBoundary>
      </DescriptionItem>
      <StreamConfiguration>
        <b>Encoder configuration</b>
        <DescriptionItem marginVertical="small" label="Server">
          {vlConfigData?.defaultConfig?.proto?.liveStream?.encoderServerUrl}
        </DescriptionItem>
        <DescriptionItem marginVertical="small" label="Stream key">
          {selectedStreamKey}
        </DescriptionItem>
        <b>Player configuration</b>
        <DescriptionItem marginVertical="small" label="Playback key">
          {liveStream.playbackKey}
        </DescriptionItem>
        <DescriptionItem marginVertical="small" label="Stream url">
          {src}
        </DescriptionItem>
        {liveStream.proto?.dvr?.liveRewind ? (
          <DescriptionItem
            marginVertical="small"
            label="Stream URL with live rewind"
          >
            {`${src}?DVR`}
          </DescriptionItem>
        ) : null}
      </StreamConfiguration>
      <DescriptionItem marginVertical="small" labelT="common.status">
        {liveStream.status}
      </DescriptionItem>
      <DescriptionItem marginVertical="small" labelT="common.transcode">
        {toUpper(strings(liveStream.transcode ? "common.yes" : "common.no"))}
      </DescriptionItem>
      <DescriptionItem marginVertical="small" labelT="common.dvrLiveRewind">
        {toUpper(
          strings(
            liveStream.proto?.dvr?.liveRewind ? "common.yes" : "common.no",
          ),
        )}
      </DescriptionItem>
      <DescriptionItem marginVertical="small" labelT="common.record">
        {toUpper(
          strings(
            liveStream.proto?.record?.enabled ? "common.yes" : "common.no",
          ),
        )}
      </DescriptionItem>
      <DescriptionItem marginVertical="small" labelT="common.updatedAt">
        {formatTimeStamp(liveStream.updatedAt)}
      </DescriptionItem>
      <DescriptionItem marginVertical="small" labelT="common.notes">
        <SimpleText style={{ whiteSpace: "pre" }}>
          {liveStream.proto?.notes}
        </SimpleText>
      </DescriptionItem>
      {isLoading && <LoadingOverlay />}
    </Container>
  );
}
