import { mergeDeepRight } from "ramda";
import { VLLocales } from "store/contentInterfaces";

export const mergeLanguageData = ({
  dataValue,
  defaultLanguage,
  selectedLanguage,
}: {
  selectedLanguage: string;
  defaultLanguage: string;
  dataValue: any;
}) => {
  const defaultLanguageData = mergeDeepRight(
    dataValue[VLLocales.NO_LOCALE],
    dataValue[defaultLanguage] || {},
  );
  return mergeDeepRight(defaultLanguageData, dataValue[selectedLanguage] || {});
};
