import { ContentRow } from "store/contentInterfaces";
import uuid from "uuid/v4";
import { StringMapped } from "vl-app-client/lib/util/types";

export default ({
  id,
  type,
  localeData = {},
}: {
  type: string;
  id?: string;
  localeData?: StringMapped;
}): ContentRow => ({
  id: id || uuid(),
  type,
  createdAt: (new Date()).toISOString(),
  status: "DRAFT",
  localeData,
  isAccessRestricted: false,
});
