import IsAdmin from "components/common/controlFlow/IsAdmin";
import VLIcon from "components/common/VLIcon";
import React from "react";
import SidebarState from "state/SidebarState";
import styled from "styled-components";

const MenuIcon = styled(VLIcon)`
  color: ${({ theme }) => theme.color.foreground.opposite};
`;

const SidebarButton = () => {
  const { isSidebarOpen, toggleSidebar } = SidebarState.useContainer();

  return (
    <IsAdmin>
      <MenuIcon
        type={isSidebarOpen ? "menu-fold" : "menu-unfold"}
        onClick={toggleSidebar}
      />
    </IsAdmin>
  );
};

export default SidebarButton;
