import { Card } from "antd";
import { SpreadRow } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import EmptyBinButton, {
  EmptyBinButtonProps
} from "components/mediaAssets/EmptyBinButton";
import React from "react";

export default ({ binnedAssetIds }: EmptyBinButtonProps) => (
  <Card size="small">
    <SpreadRow>
      {binnedAssetIds.length > 0 ? (
        <SimpleText color="tertiary" t="binnedAssetsInfo.deletedAssets" />
      ) : (
        <SimpleText color="tertiary" t="binnedAssetsInfo.noDeletedAssets" />
      )}
      <EmptyBinButton binnedAssetIds={binnedAssetIds} />
    </SpreadRow>
  </Card>
);
