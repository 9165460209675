import VLSelect, { VLSelectProps } from "components/common/select/VLSelect";
import React, { FC } from "react";
import styled from "styled-components";
import { FormEnumOption } from "../types";

const EnumSelect = styled(VLSelect)`
  width: 100%;
`;

interface EditableNumberProps {
  initialValue?: string;
  onSave: (option?: string) => void;
  options: FormEnumOption[];
}

export default ({ initialValue, onSave, options }: EditableNumberProps) => {
  const selectedOption = options.find(({ value }) => value === initialValue);

  return (
    <EnumSelect<FC<VLSelectProps<FormEnumOption>>>
      selectedOption={selectedOption}
      options={options}
      onOptionSelect={(option) => onSave(option.value)}
      optionLabel={(option) => option.description || option.value}
    />
  );
};
