export const isNotNull = <T>(v: T | null): v is T => v !== null;

export const isDefined = <T>(v?: T | null): v is T =>
  v !== undefined && v !== null;

export const isStringArray = (array: any[]): array is string[] =>
  array.length === 0 || isString(array[0]);
export const isString = (element: any): element is string =>
  typeof element === "string";
export const toStringArray = (array: any): string[] => array.filter(isString);
