import { message } from "antd";
import head from "functions/head";
import { useState } from "react";
import GraphQLClientState from "state/GraphQLClientState";
import ContentStore from "store/ContentStore";
import { createVLComposer, validateContentRow } from "vl-app-client/lib/cms";
import {
  usePublishStatusSubscription,
  useTriggerPublishingMutation,
} from "__gen__/appService";

interface PublishingOptions {
  projectId: string;
}

export default ({ projectId }: PublishingOptions) => {
  const { hasuraClient } = GraphQLClientState.useContainer();
  const { data: statusData } = usePublishStatusSubscription({ projectId });
  const [publishing, setPublishing] = useState(false);
  const { contentRows, schemaSdl, defaultLocale } = ContentStore.useContainer();
  const [triggerPublishing] = useTriggerPublishingMutation();

  const canPublish = !!statusData?.project?.publishingUrl;
  const isPublishing =
    publishing ||
    head(statusData?.project?.latestPublications)?.status === "PUBLISHING";

  const onPublish = async () => {
    setPublishing(true);
    if (!hasuraClient) {
      message.error("Failed to publish data.");
      // eslint-disable-next-line no-console
      console.error(new Error("No apollo client"));
      return;
    }
    try {
      const rowValidations = contentRows.map((row) =>
        validateContentRow({
          row,
          schemaComposer: createVLComposer(schemaSdl),
          defaultLocale,
        }),
      );

      const validationErrors = rowValidations.filter(({ isValid }) => !isValid);

      if (validationErrors.length) {
        // eslint-disable-next-line no-console
        console.log(validationErrors);
        message.error(
          "Failed to publish data. Check that all required fields are filled.",
        );
        setPublishing(false);
        return;
      }

      await triggerPublishing({
        variables: {
          projectId,
        },
      });
    } catch (err) {
      message.error("Failed to publish data.");
      // eslint-disable-next-line no-console
      console.error(err);
    }
    setPublishing(false);
  };

  return { onPublish, isPublishing, canPublish };
};
