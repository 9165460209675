import Input, { InputProps } from "antd/lib/input";
import React from "react";

export interface VLInputProps extends InputProps {
  onTextChange?: (text: string) => void;
  onSave?: () => void;
  onCancel?: () => void;
}

export default React.forwardRef(
  (
    {
      onTextChange = () => {},
      onSave = () => {},
      onCancel = () => {},
      ...props
    }: VLInputProps,
    ref,
  ) => {
    const onEnter = (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.metaKey || ev.ctrlKey) {
        onSave();
      }
    };
    const onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === "Escape") {
        ev.stopPropagation();
        onCancel();
      }
    };

    return (
      <Input
        ref={ref as any}
        style={{ color: "black" }}
        onPressEnter={onEnter}
        onKeyDown={onKeyDown}
        onChange={(ev) => onTextChange(`${ev.target.value}`.normalize())}
        {...props}
      />
    );
  },
);
