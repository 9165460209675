import { SimpleText } from "components/common/text/SimpleText";
import React from "react";
import EditableTagList from "./EditableTagList";
import {
  isMultipleChoiceMeta,
  isSingleChoiceMeta,
  isTagListMeta,
  MetaField
} from "./MetadataFieldTypes";

interface DefaultValuesSelectProps {
  field: MetaField;
  updateField: (field: MetaField) => void;
}

export default ({ field, updateField }: DefaultValuesSelectProps) => {
  if (
    isMultipleChoiceMeta(field) ||
    isTagListMeta(field) ||
    isSingleChoiceMeta(field)
  ) {
    const { options } = field;
    const onChange = (values: string[]) =>
      updateField({
        ...field,
        options: { ...(options as any), possibleValues: values }
      });
    return (
      <EditableTagList
        tags={options.possibleValues}
        onTagsChanged={onChange}
        addLabelT="metadataSpecs.addValue"
      />
    );
  }

  return (
    <SimpleText
      fontSize="small"
      color="tertiary"
      t="metadataSpecs.noRestrictionsForType"
    />
  );
};
