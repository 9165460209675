import { ObjectTypeComposer } from "graphql-compose";
import { ContentRow } from "store/contentInterfaces";
import { mergeLanguageData } from "../mergeLanguageData";
import validateObject from "../validation/validateObject";

interface ValidateProjectProps {
  contentRows: ContentRow[];
  getContentType: (typeName: string) => ObjectTypeComposer | undefined;
  defaultLanguage: string;
  selectedLanguage: string;
}

export const validateProject = ({
  contentRows,
  getContentType,
  defaultLanguage,
  selectedLanguage,
}: ValidateProjectProps) => {
  return contentRows.map((row) => {
    const rowType = getContentType(row.type);
    if (!rowType) {
      return undefined;
    }
    const mergedData = mergeLanguageData({
      dataValue: row.localeData,
      defaultLanguage,
      selectedLanguage,
    });
    const validationResults = validateObject({
      value: mergedData,
      typeComposer: rowType,
    });
    return { rowId: row.id, validationResults, rowType: row.type };
  });
};
