import { useOrgDataBySlugSubscription } from "__gen__/appService";

const useOrgData = ({ slug }: { slug: string }) => {
  const { data, ...subscriptionResult } = useOrgDataBySlugSubscription({
    slug,
  });

  const organization = data && data.organizations && data.organizations[0];

  return { org: organization, ...subscriptionResult };
};

export default useOrgData;
