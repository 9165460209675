import { AnyComposer } from "components/cmsV2/inputs/types";
import { EnumTypeComposer, ObjectTypeComposer } from "graphql-compose";
import { append, contains } from "ramda";
import { StringMapped } from "types/common";
import { isDefined } from "types/predicates";
import {
  ValidationError,
  ValidationResult,
  ValidationResultError,
  ValidationResultOk,
} from "./types";
import validateObject from "./validateObject";

type ScalerValidationResult =
  | Omit<ValidationResultError, "fieldPath">
  | Omit<ValidationResultOk, "fieldPath">;
const validateTextField = (value: any): ScalerValidationResult => {
  if (typeof value !== "string") {
    return {
      isValid: false,
      error: ValidationError.InvalidValueType,
    };
  }
  return { isValid: true };
};

const validateBooleanField = (value: any): ScalerValidationResult => {
  if (typeof value !== "boolean") {
    return {
      isValid: false,
      error: ValidationError.InvalidValueType,
    };
  }
  return { isValid: true };
};

const validateNumberField = (value: any): ScalerValidationResult => {
  if (typeof value !== "number") {
    return {
      isValid: false,
      error: ValidationError.InvalidValueType,
    };
  }
  return { isValid: true };
};

type ScalarValidator = (value: any) => ScalerValidationResult;

const scalarValidators: StringMapped<ScalarValidator> = {
  String: validateTextField,
  Boolean: validateBooleanField,
  Number: validateNumberField,
};

interface ValidateFieldParams {
  value: any;
  fieldType: AnyComposer;
  fieldPath: Array<string | number>;
  isList?: boolean;
  isRequired?: boolean;
}

export default function validateField({
  value,
  fieldPath,
  fieldType,
  isList,
  isRequired,
}: ValidateFieldParams): ValidationResult[] {
  const fieldTypeName = fieldType.getTypeName();

  if (!isDefined(value)) {
    if (isRequired) {
      return [
        {
          isValid: false,
          error: ValidationError.MissingRequiredValue,
          fieldPath,
        },
      ];
    }
    return [{ isValid: true, fieldPath }];
  }

  if (isList) {
    if (!Array.isArray(value)) {
      return [
        {
          isValid: false,
          error: ValidationError.InvalidValueType,
          fieldPath,
        },
      ];
    }
    return value.reduce((fieldResults, fieldValue, index) => {
      const fieldResult = validateField({
        value: fieldValue,
        fieldType,
        fieldPath: append(String(index), fieldPath),
      });
      return [...fieldResults, ...fieldResult];
    }, []);
  }

  const validator = scalarValidators[fieldTypeName];
  if (!!validator) {
    return [{ ...validator(value), fieldPath }];
  }

  if (fieldType instanceof ObjectTypeComposer) {
    return validateObject({ value, typeComposer: fieldType, fieldPath });
  }
  if (fieldType instanceof EnumTypeComposer) {
    if (!contains(value, fieldType.getFieldNames())) {
      return [
        { isValid: false, error: ValidationError.InvalidValueType, fieldPath },
      ];
    }
  }

  return [{ isValid: true, fieldPath }];
}
