import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { isTagListMeta } from "components/assetMetaSpecs/MetadataFieldTypes";
import { prop, takeLast, uniq } from "ramda";
import React from "react";
import { isNotNull } from "types/predicates";
import { useTagListSuggestionsSubscription } from "__gen__/appService";
import { MetaFieldInputProps } from "./MetaFieldInputTypes";

const RestrictedTagList = ({
  selectedValues,
  possibleValues,
  ...props
}: { selectedValues: string[]; possibleValues: string[] } & SelectProps<
  string[]
>) => {
  return (
    <Select
      style={{ flex: 1 }}
      mode="multiple"
      allowClear
      value={selectedValues}
      {...props}
    >
      {possibleValues.map((value) => (
        <Select.Option key={value} value={value}>
          {value}
        </Select.Option>
      ))}
    </Select>
  );
};

const UnrestrictedTagList = ({
  selectedValues,
  defaultValues,
  fieldId,
  ...props
}: {
  selectedValues: string[];
  defaultValues: string[];
  fieldId: string;
} & SelectProps<string[]>) => {
  const { data, loading } = useTagListSuggestionsSubscription({ fieldId });

  const suggestions = ((data && data.metadataValues) || [])
    .map(prop("textValue"))
    .filter(isNotNull);
  const options = uniq([...suggestions, ...defaultValues]);

  return (
    <Select
      style={{ flex: 1 }}
      mode="tags"
      allowClear
      value={selectedValues}
      loading={loading}
      {...props}
    >
      {options.map((value) => (
        <Select.Option key={value} value={value}>
          {value}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ({
  field,
  onValuesChanged,
  values: { textValues: selectedValues },
}: MetaFieldInputProps) => {
  if (!isTagListMeta(field)) {
    return null;
  }
  const defaultValues = field.options.default || [];
  const possibleValues = field.options.possibleValues || [];
  const onChange = (values: string[]) => {
    onValuesChanged({
      fieldId: field.id || "",
      values: {
        textValues: takeLast(field.options.maxNumberOfTags || Infinity, values),
      },
    });
  };
  return possibleValues.length > 0 ? (
    <RestrictedTagList
      onChange={onChange}
      selectedValues={selectedValues}
      possibleValues={possibleValues}
    />
  ) : (
    <UnrestrictedTagList
      onChange={onChange}
      selectedValues={selectedValues}
      defaultValues={defaultValues}
      fieldId={field.id || ""}
    />
  );
};
