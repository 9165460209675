import { Dropdown, Menu } from "antd";
import VLButton from "components/common/buttons/VLButton";
import { SimpleText } from "components/common/text/SimpleText";
import React, { useState } from "react";
import RightSidebarState from "state/RightSidebarState";
import {
  LiveStreamsSubscription,
  useDeleteLiveStreamMutation,
} from "__gen__/appService";
import UpsertStreamModal from "./UpsertStreamModal";

type ModalType = "EDIT" | "DELETE";

interface LiveStreamActionsProps {
  stream: LiveStreamsSubscription["liveStreams"][0];
}

export default ({ stream }: LiveStreamActionsProps) => {
  const { closeSidebar } = RightSidebarState.useContainer();
  const [onDelete] = useDeleteLiveStreamMutation();
  const [modal, setModal] = useState<ModalType>();

  const onDeleteStream = () => {
    onDelete({ variables: { streamKey: stream.streamKey } });
    closeSidebar();
  };

  const onModalClosed = () => {
    setModal(undefined);
  };

  return (
    <>
      <Dropdown
        trigger={["click"]}
        overlay={
          <Menu>
            <Menu.Item onClick={() => setModal("EDIT")}>
              <SimpleText t="common.edit" />
            </Menu.Item>
            <Menu.Item onClick={onDeleteStream}>
              <SimpleText t="common.delete" />
            </Menu.Item>
          </Menu>
        }
      >
        <VLButton icon="more" />
      </Dropdown>
      <UpsertStreamModal
        visible={modal === "EDIT"}
        stream={stream}
        onDone={onModalClosed}
        onCancel={onModalClosed}
      />
    </>
  );
};
