import { Select } from "antd";
import React from "react";
import InputWrapper from "./InputWrapper";
import { ResourceInputProps } from "./types";

export default (props: ResourceInputProps<string[]>) => {
  const {
    resolveValue,
    resolveFallback,
    dataPath,
    locale,
    onValueSaved,
  } = props;
  const value = resolveValue({ locale, dataPath });
  const fallbackValue = resolveFallback({ locale, dataPath });

  return (
    <InputWrapper {...props}>
      <Select
        mode="tags"
        allowClear
        placeholder={fallbackValue?.join(", ")}
        value={value || []}
        onChange={(selectedValues) =>
          onValueSaved({
            value: selectedValues.length === 0 ? undefined : selectedValues,
            dataPath,
            locale,
          })
        }
        open={false}
        style={{ flex: 1 }}
      />
    </InputWrapper>
  );
};
