import ButtonModal from "components/organizations/ButtonModal";
import React from "react";
import ContentStatistics, { ContentStatisticsProps } from "./ContentStatistics";

export default ({ contentId }: ContentStatisticsProps) => {
  return (
    <ButtonModal
      buttonProps={{ icon: "line-chart" }}
      cancelButtonProps={{ hidden: true }}
    >
      <ContentStatistics contentId={contentId} />
    </ButtonModal>
  );
};
