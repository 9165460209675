import { ReactComponent as Logo } from "assets/icons/logo-white.svg";
import { Column, Row } from "components/common/layout/Flex";
import React from "react";
import styled from "styled-components";
import EmailLoginForm from "./EmailLoginForm";

const StyledLogo = styled(Logo)`
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const LoginForm = () => (
  <Column>
    <StyledLogo height={150} width="100%" />
    <Row mainAxis="center" crossAxis="stretch">
      <EmailLoginForm />
    </Row>
  </Column>
);

export default LoginForm;
