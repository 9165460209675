import LoadingScreen from "components/common/layout/LoadingScreen";
import NotFound from "components/common/result/NotFound";
import useOrgData from "components/hooks/useOrgData";
import OrganizationDetails from "components/organizations/OrganizationDetails";
import Paths from "navigation/Paths";
import React from "react";
import { RouteComponentProps } from "react-router";
import FolderStore from "store/FolderStore";
import PermissionStore from "store/PermissionStore";

const OrganizationDetailsScreen = ({
  match: {
    params: { orgSlug, tabKey }
  }
}: RouteComponentProps<typeof Paths.orgTab.params>) => {
  const { org, loading } = useOrgData({ slug: orgSlug });

  if (loading) {
    return <LoadingScreen />;
  }

  if (!org) {
    return <NotFound />;
  }

  return (
    <FolderStore.Provider initialState={{ orgId: org.id }}>
      <PermissionStore.Provider initialState={{ orgId: org.id }}>
        <OrganizationDetails orgSlug={orgSlug} tabKey={tabKey} org={org} />
      </PermissionStore.Provider>
    </FolderStore.Provider>
  );
};

export default OrganizationDetailsScreen;
