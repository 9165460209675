import { Layout, Menu } from "antd";
import VLIcon from "components/common/VLIcon";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import SidebarState from "state/SidebarState";
import styled from "styled-components";

const { Sider } = Layout;

const SidebarContainer = styled(Sider)`
  background-color: ${({ theme }) => theme.color.background.secondary};
`;

const StyledMenu = styled(Menu)`
  background-color: ${({ theme }) => theme.color.background.secondary};
`;

const Sidebar = ({ location }: RouteComponentProps) => {
  const { isSidebarOpen } = SidebarState.useContainer();
  const selectedKey = location.pathname.split("/").slice(0, 2).join("/");

  return (
    <SidebarContainer trigger={null} collapsible collapsed={!isSidebarOpen}>
      <StyledMenu mode="inline" selectedKeys={[selectedKey]}>
        <Menu.Item key={Paths.dashboard.path}>
          <Link to={Paths.dashboard.path}>
            <VLIcon type="dashboard" iconTheme="filled" />
            <span>{strings("screens.dashboard.title")}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={Paths.users.path}>
          <Link to={Paths.users.path}>
            <VLIcon type="idcard" iconTheme="filled" />
            <span>{strings("screens.users.title")}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={Paths.organizations.path}>
          <Link to={Paths.organizations.path}>
            <VLIcon type="bank" iconTheme="filled" />
            <span>{strings("screens.organizations.title")}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={Paths.projects.path}>
          <Link to={Paths.projects.path}>
            <VLIcon type="project" iconTheme="filled" />
            <span>{strings("screens.projects.title")}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={Paths.packages.path}>
          <Link to={Paths.packages.path}>
            <VLIcon type="tags" iconTheme="filled" />
            <span>{strings("screens.packages.title")}</span>
          </Link>
        </Menu.Item>
      </StyledMenu>
    </SidebarContainer>
  );
};

export default withRouter(Sidebar);
