import { Switch } from "antd";
import { isBooleanMeta } from "components/assetMetaSpecs/MetadataFieldTypes";
import { Row } from "components/common/layout/Flex";
import head from "functions/head";
import React, { useEffect } from "react";
import { isDefined } from "types/predicates";
import { MetaFieldInputProps } from "./MetaFieldInputTypes";

export default ({ field, onValuesChanged, values }: MetaFieldInputProps) => {
  if (!isBooleanMeta(field)) {
    return null;
  }
  const selectedValue = head(values.booleanValues);

  useEffect(() => {
    if (!isDefined(selectedValue)) {
      onValuesChanged({
        fieldId: field.id || "",
        values: { booleanValues: [field.options.default || false] },
      });
    }
    // eslint-disable-next-line
  }, []);

  const onChange = (checked: boolean) => {
    onValuesChanged({
      fieldId: field.id || "",
      values: { booleanValues: [checked] },
    });
  };
  return (
    <Row>
      <Switch checked={selectedValue} onChange={onChange} />
    </Row>
  );
};
