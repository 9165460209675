import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { SimpleText } from "components/common/text/SimpleText";
import strings from "localisation/strings";
import React from "react";
import {
  isBooleanMeta,
  isMultipleChoiceMeta,
  isSingleChoiceMeta,
  isTagListMeta,
  MetaField,
} from "./MetadataFieldTypes";

interface DefaultValuesSelectProps {
  field: MetaField;
  updateField: (field: MetaField) => void;
}

export default ({ field, updateField }: DefaultValuesSelectProps) => {
  if (
    isMultipleChoiceMeta(field) ||
    isTagListMeta(field) ||
    isSingleChoiceMeta(field)
  ) {
    const { options } = field;
    const possibleValues = options.possibleValues || [];
    const defaultValues = options.default || [];
    const restrictValues = possibleValues.length > 0;
    let mode: SelectProps<string>["mode"] = restrictValues
      ? "multiple"
      : "tags";
    if (isSingleChoiceMeta(field)) {
      mode = undefined;
    }
    return (
      <Select
        mode={mode}
        placeholder={strings("metadataSpecs.noDefault")}
        allowClear
        onChange={(value: string | string[]) => {
          const values = typeof value === "string" ? [value] : value;
          if (isSingleChoiceMeta(field)) {
            updateField({
              ...field,
              options: { ...options, default: values[0] || "" },
            });
          } else {
            updateField({ ...field, options: { ...options, default: values } });
          }
        }}
        value={defaultValues}
      >
        {possibleValues.map((value) => (
          <Select.Option key={value} value={value}>
            {value}
          </Select.Option>
        ))}
      </Select>
    );
  }

  if (isBooleanMeta(field)) {
    const { options } = field;
    let selectedValue = options.default === true ? "TRUE" : "NONE";
    if (options.default === false) {
      selectedValue = "FALSE";
    }
    return (
      <Select
        placeholder={strings("metadataSpecs.noDefault")}
        allowClear
        onChange={(value: string) => {
          let newValue;
          if (value === "TRUE") {
            newValue = true;
          }
          if (value === "FALSE") {
            newValue = false;
          }
          updateField({ ...field, options: { ...options, default: newValue } });
        }}
        value={selectedValue}
      >
        <Select.Option value="NONE">
          {strings("metadataSpecs.noDefault")}
        </Select.Option>
        <Select.Option value="TRUE">{strings("common.true")}</Select.Option>
        <Select.Option value="FALSE">{strings("common.false")}</Select.Option>
      </Select>
    );
  }

  return (
    <SimpleText
      fontSize="small"
      color="tertiary"
      t="metadataSpecs.noDefaultForType"
    />
  );
};
