import { Result } from "antd";
import AppContainer from "components/common/layout/AppContainer";
import strings from "localisation/strings";
import React from "react";

export default () => (
  <AppContainer>
    <Result
      status="500"
      title={strings("maintenance.title")}
      subTitle={strings("maintenance.subtitle")}
    />
  </AppContainer>
);
