import Env from "config/Env";
import { path } from "ramda";
import { useAppInfoSubscription } from "__gen__/authServiceSdk";
import { authServiceClient } from "../client";

export default () => {
  const { data, loading, error } = useAppInfoSubscription(
    {
      appId: Env.REACT_APP_AUTH_SERVICE_APP_ID,
    },
    { client: authServiceClient },
  );

  return {
    data,
    loading,
    error,
    appInfo: path<any>(["app", "info"], data),
  };
};
