import { Popconfirm } from "antd";
import changeContentStatus from "components/cms/functions/changeContentStatus";
import VLButton, { VLButtonProps } from "components/common/buttons/VLButton";
import { Row } from "components/common/layout/Flex";
import ButtonModal from "components/organizations/ButtonModal";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import { assocPath } from "ramda";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ContentRow, VLContentType } from "store/contentInterfaces";
import ContentStore from "store/ContentStore";
import createContentRow from "store/functions/createContentRow";
import { useDeleteProjectDataMutation } from "__gen__/appService";
import { mergeLanguageData } from "../functions/mergeLanguageData";
import validateObject from "../functions/validation/validateObject";
import useProjectLanguages from "../hooks/useProjectLanguages";
import LanguageSelect from "../LanguageSelect";
import ResourceForm from "./ResourceForm";
import { LocaleData } from "./types";

interface ResourceFormButtonProps {
  contentId?: string;
  contentType: VLContentType;
  onContentCreated?: (contentRow: ContentRow) => void;
  tabKey?: string;
  buttonProps?: VLButtonProps;
  onDelete?: () => void;
  isNewResource?: boolean;
}

export default ({
  contentId,
  contentType,
  tabKey,
  onContentCreated = () => {},
  buttonProps = {},
  onDelete = () => {},
  isNewResource,
}: ResourceFormButtonProps) => {
  const {
    getContentRow,
    upsertRow,
    removeRow,
    projectId,
  } = ContentStore.useContainer();
  const contentRow = getContentRow(contentId || "");
  const [resourceValue, setResourceValue] = useState<LocaleData>(
    contentRow?.localeData || {},
  );
  const [isNew, setIsNew] = useState(isNewResource);
  const { defaultLanguage, selectedLanguage } = useProjectLanguages();

  let newRow = contentRow;
  const checkInputValue = () => {
    newRow = createContentRow({
      id: contentId,
      type: contentType.getTypeName(),
    });
    let mergedData;
    if (!!defaultLanguage) {
      mergedData = mergeLanguageData({
        dataValue: resourceValue,
        defaultLanguage,
        selectedLanguage,
      });
    }
    const validationResults = validateObject({
      value: mergedData || {},
      typeComposer: contentType,
    });
    const error = validationResults.filter((item) => !item.isValid);
    if (error.length > 0) {
      setIsNew(!isNewResource);
      return true;
    }
  };

  const onSave = () => {
    const hasErrors = checkInputValue();
    if (hasErrors) {
      throw new Error();
    }
    if (!newRow) {
      return undefined;
    }
    onContentCreated(newRow);
    upsertRow(
      assocPath(["localeData"], resourceValue, changeContentStatus(newRow)),
    );
  };

  const onCancel = () => {
    setResourceValue(contentRow?.localeData || {});
  };

  const [deleteProject] = useDeleteProjectDataMutation();
  const removeProject = (projectId: string) => {
    deleteProject({
      variables: {
        projectId: projectId,
      },
    });
  };

  const history = useHistory();

  return (
    <ButtonModal
      buttonProps={buttonProps}
      onDone={onSave}
      onCancel={onCancel}
      width={720}
    >
      <LanguageSelect />
      {contentRow ? (
        <Row mainAxis="flex-end">
          <Popconfirm
            title={strings("folderInfo.deleteConfirm")}
            onConfirm={() => {
              if (contentType.getTypeName() === "VlEvent") {
                removeRow(contentId || "");
                onDelete();
              } else if (contentType.getTypeName() === "VlProject") {
                removeProject(projectId || "");
                history.replace(Paths.projects.withParams());
              }
            }}
            okText={strings("common.yes")}
            cancelText={strings("common.no")}
            placement="topRight"
          >
            <VLButton danger marginVertical="medium" icon="delete">
              Delete
            </VLButton>
          </Popconfirm>
        </Row>
      ) : null}
      <ResourceForm
        tabKey={tabKey}
        contentType={contentType}
        resourceValue={resourceValue}
        setResourceValue={setResourceValue}
        isNewResource={isNew}
      />
    </ButtonModal>
  );
};
