import { Form, message, Modal } from "antd";
import VLButton, { VLButtonProps } from "components/common/buttons/VLButton";
import VLInput from "components/common/input/VLInput";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import UserState from "state/UserState";
import styled from "styled-components";
import { useUpsertFolderMutation } from "__gen__/appService";

const FormItem = styled(Form.Item)`
  margin: ${({ theme }) => theme.margin.large} 0;
`;

interface FieldStatus {
  validateStatus?: "error" | "success" | "warning" | "validating";
  message?: string;
}

interface UpsertFolderProps extends VLButtonProps {
  folderId?: string;
  parentFolderId?: string;
  name?: string;
  description?: string | null;
}

export default ({
  folderId,
  parentFolderId,
  name = "",
  description,
  ...buttonProps
}: UpsertFolderProps) => {
  const { selectedOrgId } = UserState.useContainer();
  const [modalVisible, setModalVisible] = useState(false);
  const [folderName, setFolderName] = useState(name);
  const [folderDescription, setFolderDescription] = useState(description || "");
  const [folderNameStatus, setFolderNameStatus] = useState<FieldStatus>({});
  const [upsertFolder] = useUpsertFolderMutation();
  const history = useHistory();

  const showModal = () => {
    setFolderName(name);
    setFolderDescription(description || "");
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
    setFolderNameStatus({});
  };

  const onNameChange = (text: string) => {
    setFolderName(text);
    validateForm(text);
  };

  const onDescriptionChange = (text: string) => {
    setFolderDescription(text);
  };

  const validateForm = (newName: string = folderName) => {
    if (!newName) {
      setFolderNameStatus({
        validateStatus: "error",
        message: strings("upsertFolder.fields.name.errorEmpty"),
      });
      return false;
    }
    setFolderNameStatus({});
    return true;
  };

  const tryCreatingFolder = async () => {
    if (validateForm()) {
      try {
        const { data } = await upsertFolder({
          variables: {
            folder: [
              {
                id: folderId,
                name: folderName,
                description: folderDescription,
                org_id: selectedOrgId,
                parent_id: parentFolderId,
              },
            ],
          },
        });
        hideModal();
        const newFolderId = data?.folders?.upserted[0]?.id;
        if (!folderId && newFolderId) {
          history.push(
            Paths.folderMediaAssets.withParams({ folderId: newFolderId }),
          );
        }
      } catch (err) {
        message.error(strings("upsertFolder.createFailed"));
      }
    }
  };

  return (
    <>
      <VLButton
        type="primary"
        shape="circle"
        icon="folder-add"
        onClick={showModal}
        {...buttonProps}
      />
      <Modal
        onCancel={hideModal}
        visible={modalVisible}
        onOk={tryCreatingFolder}
        okText={strings("buttons.save")}
        cancelText={strings("buttons.cancel")}
        destroyOnClose
      >
        <FormItem
          label={strings("upsertFolder.fields.name.label")}
          required
          validateStatus={folderNameStatus.validateStatus}
          help={folderNameStatus.message}
        >
          <VLInput
            onPressEnter={tryCreatingFolder}
            autoFocus
            value={folderName}
            onTextChange={onNameChange}
          />
        </FormItem>
        <FormItem label={strings("upsertFolder.fields.description.label")}>
          <VLInput
            onPressEnter={tryCreatingFolder}
            value={folderDescription}
            onTextChange={onDescriptionChange}
          />
        </FormItem>
      </Modal>
    </>
  );
};
