import {
  allColumns,
  AssetListColumnIndex,
} from "components/assetList/table/defaultColumns";
import { not, uniq } from "ramda";
import { createContainer } from "unstated-next";
import { MediaAssetsOrderBy } from "__gen__/appService";
import usePersistedState from "./usePersistedState";
import UserState from "./UserState";

interface MetaColumnsState {
  [orgIdUserId: string]: string[];
}

export type SortOrderValue = keyof Pick<
  MediaAssetsOrderBy,
  "name" | "created_at" | "type" | "updated_at"
>;

export const DEFAULT_PAGE_SIZE = 10;

const useMediaBrowserPreferencesState = () => {
  const { selectedOrgId, user } = UserState.useContainer();
  const [
    metaColumnsState,
    setMetaColumnsState,
    reset,
  ] = usePersistedState<MetaColumnsState>(
    "VLMediaBrowserPreferencesState/MetaColumnsState",
    {},
  );
  const [pageSize, setPageSize] = usePersistedState(
    "VLMediaBrowserPreferencesState/pageSize",
    DEFAULT_PAGE_SIZE,
  );
  const [assetListColumns, setAssetListColumns] = usePersistedState<
    AssetListColumnIndex[]
  >("VLMediaBrowserPreferencesState/AssetListColumns", allColumns);

  const key = `${selectedOrgId}/${(user && user.id) || ""}`;

  const setMetaColumns = (metaFieldIds: string[]) => {
    setMetaColumnsState((current) => ({
      ...current,
      [key]: uniq(metaFieldIds),
    }));
  };

  const [gridColumnCount, setGridColumnCount] = usePersistedState(
    "VLMediaBrowserPreferencesState/GridColumnCount",
    4,
  );

  const [isGrid, setIsGrid] = usePersistedState(
    "VLMediaBrowserPreferencesState/IsGrid",
    false,
  );
  const toggleIsGrid = () => setIsGrid(not);

  const [sortBy, setSortBy] = usePersistedState<SortOrderValue | undefined>(
    "VLMediaBrowserPreferencesState/SortBy",
    undefined,
  );
  const [sortingOrder, setSortingOrder] = usePersistedState(
    "VLMediaBrowserPreferencesState/SortingOrder",
    "",
  );

  return {
    metaColumns: metaColumnsState[key] || [],
    setMetaColumns,
    pageSize,
    setPageSize,
    assetListColumns,
    setAssetListColumns,
    reset,
    isGrid,
    toggleIsGrid,
    gridColumnCount,
    setGridColumnCount,
    sortBy,
    setSortBy,
    sortingOrder,
    setSortingOrder,
  };
};

export default createContainer(useMediaBrowserPreferencesState);
