import { Checkbox } from "antd";
import { SimpleText } from "components/common/text/SimpleText";
import React from "react";
import FallbackText from "../preview/FallbackText";
import InputWrapper from "./InputWrapper";
import { ResourceInputProps } from "./types";

export default (props: ResourceInputProps<boolean>) => {
  const {
    onValueSaved,
    resolveValue,
    resolveFallback,
    dataPath,
    locale,
  } = props;
  const value = resolveValue({ locale, dataPath });
  const fallbackValue = resolveFallback({ locale, dataPath });

  const onSave = () => onValueSaved({ value: !value, dataPath, locale });

  const hasValue = typeof value === "boolean";
  const hasFallback = typeof fallbackValue === "boolean";

  return (
    <InputWrapper {...props}>
      <Checkbox
        style={{ opacity: hasFallback && !hasValue ? 0.7 : 1 }}
        checked={hasValue ? value : fallbackValue}
        onChange={onSave}
      >
        {!hasValue && !hasFallback && <FallbackText t="common.notSet" />}
        {hasValue && <SimpleText t={value ? "common.yes" : "common.no"} />}
        {!hasValue && hasFallback && (
          <FallbackText t={fallbackValue ? "common.yes" : "common.no"} />
        )}
      </Checkbox>
    </InputWrapper>
  );
};
