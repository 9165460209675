import VLButton from "components/common/buttons/VLButton";
import React from "react";
import { useDeleteMetadataFieldMutation } from "__gen__/appService";

interface DeleteMetadataFieldButtonProps {
  fieldId: string;
}

export default ({ fieldId }: DeleteMetadataFieldButtonProps) => {
  const [onDelete, { loading }] = useDeleteMetadataFieldMutation();

  return (
    <VLButton
      loading={loading}
      onClick={() => onDelete({ variables: { fieldId } })}
      size="small"
      icon="delete"
      shape="circle"
    />
  );
};
