import { Breadcrumb } from "antd";
import Paths, {
  WithFolderId,
  WithOrgSlug,
  WithProjectSlug,
} from "navigation/Paths";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import BreadcrumbsContainer from "./BreadcrumbsContainer";
import FolderBreadcrumbs from "./FolderBreadcrumbs";

interface BreadcrumbParams {
  name: string;
  linksTo: string;
}

const VLBreadcrumbs = ({
  match,
}: RouteComponentProps<
  Partial<WithOrgSlug & WithProjectSlug & WithFolderId>
>) => {
  const breadcrumbParams: BreadcrumbParams[] = [];
  switch (match.path) {
    case Paths.orgMediaAssets.path:
    case Paths.binnedMediaAssets.path:
    case Paths.assetsSearch.path:
    case Paths.folderMediaAssets.path:
      return (
        <BreadcrumbsContainer>
          <FolderBreadcrumbs folderId={match.params.folderId} />
        </BreadcrumbsContainer>
      );
    default:
      return (
        <BreadcrumbsContainer>
          <Breadcrumb>
            {breadcrumbParams.length > 0 &&
              breadcrumbParams.map(({ name, linksTo }, index) => (
                <Breadcrumb.Item key={index}>
                  {index + 1 === breadcrumbParams.length ? (
                    name
                  ) : (
                    <Link to={linksTo}>{name}</Link>
                  )}
                </Breadcrumb.Item>
              ))}
          </Breadcrumb>
        </BreadcrumbsContainer>
      );
  }
};

export default withRouter(VLBreadcrumbs);
