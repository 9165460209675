import head from "functions/head";
import { MetaFieldValuesBoolExp } from "__gen__/appService";
import { Operator, SerializedFieldValues } from "../types";

export default (
  operator: Operator,
  values?: SerializedFieldValues,
): MetaFieldValuesBoolExp => {
  if (!values) {
    return {};
  }

  if (operator === "EQUALS") {
    if (!!values.booleanValues) {
      return {
        _and: values.booleanValues.map<MetaFieldValuesBoolExp>((value) => ({
          boolean_values: { value: { _eq: value } },
        })),
      };
    }
    if (!!values.textValues) {
      return {
        _and: values.textValues.map<MetaFieldValuesBoolExp>((value) => ({
          text_values: { value: { _eq: value } },
        })),
      };
    }
    if (!!values.dateValues) {
      return {
        _and: values.dateValues.map<MetaFieldValuesBoolExp>((value) => ({
          date_values: { value: { _eq: value } },
        })),
      };
    }
    if (!!values.numberValues) {
      return {
        _and: values.numberValues.map<MetaFieldValuesBoolExp>((value) => ({
          number_values: { value: { _eq: value } },
        })),
      };
    }
  }

  if (operator === "IS_ANY") {
    if (!!values.booleanValues) {
      return { boolean_values: { value: { _in: values.booleanValues } } };
    }
    if (!!values.textValues) {
      return { text_values: { value: { _in: values.textValues } } };
    }
    if (!!values.dateValues) {
      return { date_values: { value: { _in: values.dateValues } } };
    }
    if (!!values.numberValues) {
      return { number_values: { value: { _in: values.numberValues } } };
    }
  }

  if (operator === "CONTAINS") {
    const textValue = head(values.textValues);
    if (textValue) {
      return { text_values: { value: { _ilike: `%${textValue}%` } } };
    }
  }

  if (operator === "IS_BIGGER" || operator === "IS_AFTER") {
    const dateValue = head(values.dateValues);
    if (dateValue) {
      return { date_values: { value: { _gt: dateValue } } };
    }
    const numberValue = head(values.numberValues);
    if (numberValue) {
      return { number_values: { value: { _gt: numberValue } } };
    }
  }

  if (operator === "IS_SMALLER" || operator === "IS_BEFORE") {
    const dateValue = head(values.dateValues);
    if (dateValue) {
      return { date_values: { value: { _lt: dateValue } } };
    }
    const numberValue = head(values.numberValues);
    if (numberValue) {
      return { number_values: { value: { _lt: numberValue } } };
    }
  }

  return {};
};
