import { SimpleText } from "components/common/text/SimpleText";
import React from "react";
import FallbackText from "../preview/FallbackText";
import { ResourcePreviewProps } from "./types";

export default (props: ResourcePreviewProps<boolean>) => {
  const { resolveValue, resolveFallback, dataPath, locale } = props;
  const value = resolveValue({ locale, dataPath });
  const fallbackValue = resolveFallback({ locale, dataPath });

  const hasValue = typeof value === "boolean";
  const hasFallback = typeof fallbackValue === "boolean";

  if (hasValue) {
    return <SimpleText t={value ? "common.yes" : "common.no"} />;
  }
  if (!hasValue && hasFallback) {
    return <FallbackText t={fallbackValue ? "common.yes" : "common.no"} />;
  }
  return <FallbackText t="common.notSet" />;
};
