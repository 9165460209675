import { SimpleText } from "components/common/text/SimpleText";
import Paths from "navigation/Paths";
import React from "react";
import { Link } from "react-router-dom";

interface TeamLinkProps {
  team: { id: string; name: string };
  orgSlug: string;
  disabled?: boolean;
}

export default ({ team, orgSlug, disabled }: TeamLinkProps) => {
  if (disabled) {
    return <SimpleText>{team.name}</SimpleText>;
  }

  return (
    <Link to={Paths.orgTeamDetails.withParams({ orgSlug, teamId: team.id })}>
      {team.name}
    </Link>
  );
};
