import Tooltip, { TooltipProps } from "antd/lib/tooltip";
import strings from "localisation/strings";
import { TranslationPaths } from "localisation/TranslationPaths";
import React from "react";

interface VLTooltipProps {
  t?: TranslationPaths;
}

const VLTooltip = ({
  t,
  title,
  ...props
}: VLTooltipProps & Partial<TooltipProps>) => (
  <Tooltip title={t ? strings(t) : title} {...props} />
);

export default VLTooltip;
