import head from "functions/head";
import { VLLocales } from "store/contentInterfaces";
import {
  CmsDataSubscriptionVariables,
  useCmsDataSubscription,
  useContentSchemasSubscription,
  useDeleteCmsDataMutation,
  useProjectDataSubscription,
  useUpsertCmsDataMutation,
} from "__gen__/appService";

export default (variables: CmsDataSubscriptionVariables) => {
  const { data, loading, error } = useCmsDataSubscription(variables);
  const { data: projectData, loading: projectLoading } =
    useProjectDataSubscription({
      projectId: variables.projectId || "",
    });
  const [upsertData] = useUpsertCmsDataMutation();
  const [deleteData] = useDeleteCmsDataMutation();
  const { data: schemaData } = useContentSchemasSubscription({
    projectId: variables.projectId,
  });

  return {
    rows: data?.cmsContents || [],
    locales: (projectData?.project?.locales as VLLocales[]) || [],
    defaultLocale:
      (projectData?.project?.defaultLocale as unknown as VLLocales) ||
      VLLocales.EN_US,
    loading: loading || projectLoading,
    error,
    schemaSdl: head(schemaData?.contentSchemas)?.schemaSdl || "",
    upsertData,
    deleteData,
    projectExtraData: projectData?.project?.extraData,
    projectData,
  };
};
