import AppContainer from "components/common/layout/AppContainer";
import NotFound from "components/common/result/NotFound";
import React from "react";

const NotFoundScreen = () => (
  <AppContainer>
    <NotFound />
  </AppContainer>
);

export default NotFoundScreen;
