import { isNumberMeta } from "components/assetMetaSpecs/MetadataFieldTypes";
import VLInput from "components/common/input/VLInput";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MetaFieldInputProps } from "./MetaFieldInputTypes";

const InputElement = styled(VLInput)`
  flex: 1;
`;

export default ({
  field,
  onValuesChanged,
  values: {
    numberValues: [selectedValue],
  },
}: MetaFieldInputProps) => {
  const [localValue, setLocalValue] = useState("");

  useEffect(() => {
    setLocalValue(selectedValue ? `${selectedValue}` : "");
  }, [selectedValue, setLocalValue]);

  if (!isNumberMeta(field)) {
    return null;
  }
  const onTextChange = (value: string) => {
    setLocalValue(value);
  };
  const onBlur = () => {
    const localNumber = parseFloat(localValue);
    if (!isNaN(localNumber)) {
      onValuesChanged({
        fieldId: field.id || "",
        values: { numberValues: [localNumber] },
      });
    } else {
      setLocalValue("");
    }
  };
  return (
    <InputElement
      placeholder={field.name}
      value={localValue}
      onBlur={onBlur}
      type="number"
      onTextChange={onTextChange}
    />
  );
};
