import { SimpleText } from "components/common/text/SimpleText";
import { concat } from "ramda";
import React from "react";
import { ResourceInputProps } from "./types";

export default ({
  fieldConfig,
  isRequired,
  style,
  isPreview,
  extras,
}: Pick<ResourceInputProps, "fieldConfig" | "isRequired"> & {
  extras?: string;
  style?: React.CSSProperties;
  isPreview?: boolean;
}) => {
  const { description } = fieldConfig || {};
  let labelArray: any[] = [description || "", extras || ""];
  if (!isPreview) {
    labelArray = concat(labelArray, [
      isRequired ? "*" : "",
      description ? ":" : "",
    ]);
  }
  return (
    <SimpleText style={{ flexBasis: 200, flexShrink: 0, ...(style || {}) }}>
      {labelArray.join("")}
    </SimpleText>
  );
};
