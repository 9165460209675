import { Modal } from "antd";
import VLButton, { VLButtonProps } from "components/common/buttons/VLButton";
import strings from "localisation/strings";
import React, { useEffect, useState } from "react";
import UploadingFilesMetadataState from "state/UploadingFilesMetadataState";
import { useMetadataFieldsSubscription } from "__gen__/appService";
import AssetMetaFields from "./AssetMetaFields";
import { ValuesChange } from "./MetaFieldInputTypes";
import { AssetMetaFieldsState, emptyValues } from "./stateMapper";

interface UploadingAssetMetadataModalProps extends VLButtonProps {
  batchId: string;
  openOnMount?: boolean;
}

export default ({
  batchId,
  openOnMount,
  ...buttonProps
}: UploadingAssetMetadataModalProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const {
    getUploadBatch,
    updateBatch,
  } = UploadingFilesMetadataState.useContainer();
  const batch = getUploadBatch(batchId);
  const { data } = useMetadataFieldsSubscription({
    orgId: batch ? batch.orgId : "",
  });
  const metadataFields = (data && data.metadataFields) || [];
  const initialState = !!batch ? batch.metadataState : {};
  const [currentState, setCurrentState] = useState<AssetMetaFieldsState>(
    initialState,
  );

  useEffect(() => {
    setCurrentState(initialState);
  }, [initialState, setCurrentState]);

  useEffect(() => {
    if (openOnMount) {
      setModalVisible(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateBatch(batchId, { isBeingEdited: modalVisible });
    // eslint-disable-next-line
  }, [batchId, modalVisible]);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setCurrentState(initialState);
    setModalVisible(false);
  };

  const onSave = async () => {
    updateBatch(batchId, { metadataState: currentState, isBeingEdited: false });
    hideModal();
  };

  const onChange = ({ fieldId, values }: ValuesChange) => {
    setCurrentState((current) => ({
      ...current,
      [fieldId]: { ...emptyValues, ...values },
    }));
  };

  return metadataFields.length === 0 ? null : (
    <>
      <VLButton icon="edit" onClick={showModal} {...buttonProps} />
      <Modal
        onCancel={hideModal}
        visible={modalVisible}
        onOk={onSave}
        okText={strings("buttons.save")}
        cancelText={strings("buttons.cancel")}
        destroyOnClose
      >
        <AssetMetaFields
          fields={metadataFields}
          selectedValues={currentState}
          setValues={onChange}
        />
      </Modal>
    </>
  );
};
