import { Column } from "components/common/layout/Flex";
import { MarginedDivider } from "components/common/layout/MarginedDivider";
import { append, remove, update } from "ramda";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import UserState from "state/UserState";
import { useMetadataFieldsSubscription } from "__gen__/appService";
import FieldConditionRow from "./FieldConditionRow";
import isConditionComplete from "./functions/isConditionComplete";
import { queryStringToSearchState } from "./serializers";
import { FieldCondition, PartialCondition, SearchState } from "./types";

interface MetaSerachConditionsProps {
  searchState: SearchState;
  onChange: (state: Partial<SearchState>) => void;
}

export default ({
  searchState: { metaConditions },
  onChange,
}: MetaSerachConditionsProps) => {
  const { selectedOrgId } = UserState.useContainer();
  const { data } = useMetadataFieldsSubscription({ orgId: selectedOrgId });
  const [lastCondition, setLastCondition] = useState<PartialCondition>({});
  const { search } = useLocation();
  const fields = data?.metadataFields || [];
  const lastIsComplete = isConditionComplete(lastCondition);
  const setConditions = (
    fn: (conditions: FieldCondition[]) => FieldCondition[],
  ) => onChange({ metaConditions: fn(metaConditions) });

  useEffect(() => {
    if (data && search) {
      onChange({
        metaConditions: queryStringToSearchState(search, fields).metaConditions,
      });
    }
    // eslint-disable-next-line
  }, [search, JSON.stringify(fields)]);

  useEffect(() => {
    if (lastIsComplete) {
      setConditions(append(lastCondition));
      setLastCondition({});
    }
    // eslint-disable-next-line
  }, [lastIsComplete]);

  const updateCondition = (index: number, condition: PartialCondition) => {
    setConditions((current) =>
      update(index, { ...current[index], ...condition }, current),
    );
  };

  const removeCondition = (index: number) => {
    setConditions(remove(index, 1));
  };

  const updateLastCondition = (condition: PartialCondition) => {
    setLastCondition((current) => ({ ...current, ...condition }));
  };

  if (fields.length === 0) {
    return null;
  }

  return (
    <Column>
      <MarginedDivider margin="medium" />
      {metaConditions.map((condition, index) => (
        <FieldConditionRow
          key={index}
          fields={fields}
          condition={condition}
          onUpdate={(change) => updateCondition(index, change)}
          onRemove={() => removeCondition(index)}
        />
      ))}
      <FieldConditionRow
        fields={fields}
        condition={lastCondition}
        onUpdate={(change) => updateLastCondition(change)}
      />
    </Column>
  );
};
