import React, { ReactNode } from "react";
import UserState, { UserRole } from "state/UserState";

interface IsNotAdminProps {
  children: ReactNode;
}

const IsNotAdmin = ({ children }: IsNotAdminProps) => {
  const { userState } = UserState.useContainer();
  const isAdmin = userState.selectedRole === UserRole.ADMIN;

  return isAdmin ? null : <>{children}</>;
};

export default IsNotAdmin;
