import VLButton from "components/common/buttons/VLButton";
import { SpreadRow } from "components/common/layout/Flex";
import React from "react";
import { Link } from "react-router-dom";
import ScreenTitle from "../text/ScreenTitle";

interface ScreenHeaderProps {
  title: string;
  buttonText?: string;
  buttonTo?: string;
}

const ScreenHeader = ({ title, buttonText, buttonTo }: ScreenHeaderProps) => (
  <SpreadRow flex={0}>
    <ScreenTitle as="h2">{title}</ScreenTitle>
    {buttonText && buttonTo && (
      <VLButton type="primary">
        <Link to={buttonTo}>{buttonText}</Link>
      </VLButton>
    )}
  </SpreadRow>
);

export default ScreenHeader;
