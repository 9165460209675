import { useS3SignedUrlLazyQuery } from "__gen__/appGatewaySdk";
import {
  JobStatusTypesEnum,
  useMediaAssetDetailsSubscription,
  useMetadataFieldsSubscription,
} from "__gen__/appService";
import MetaFieldValues from "components/assetMetadata/valueRenderers/MetaFieldValues";
import FolderBreadcrumbs from "components/common/breadcrumbs/FolderBreadcrumbs";
import DescriptionItem from "components/common/descriptions/DescriptionItem";
import { SpreadRow } from "components/common/layout/Flex";
import Loading from "components/common/layout/Loading";
import { MarginedDivider } from "components/common/layout/MarginedDivider";
import { SimpleText } from "components/common/text/SimpleText";
import useDateFns from "components/hooks/useDateFns";
import { useOrgConfig } from "components/hooks/useVlConfig";
import useViewableMeta from "components/permissionControl/hooks/useViewableMeta";
import VideoPlayer from "components/player/VideoPlayer";
import { formatBytes } from "localisation/formatBytes";
import React, { useEffect } from "react";
import GraphQLClientState from "state/GraphQLClientState";
import MediaSidebarState from "state/MediaSidebarState";
import UserState from "state/UserState";
import ErrorBoundary from "../common/ErrorBoundary";
import CopyInput from "./CopyInput";
import ImagePreview from "./ImagePreview";
import MediaAssetActions from "./MediaAssetActions";
import ProfileStatuses from "./ProfileStatusest";
import { createPlaylist, createStream } from "./functions/createPlaylist";
import { isMimeImage, isMimeVideo } from "./mimeUtils";

interface MediaAssetDetailsProps {
  mediaAssetId: string;
}

export default ({ mediaAssetId }: MediaAssetDetailsProps) => {
  const { apiClient } = GraphQLClientState.useContainer();
  const { data } = useMediaAssetDetailsSubscription({ mediaAssetId });
  const [getSignedUrl, { data: signedUrlData }] = useS3SignedUrlLazyQuery(
    undefined,
    {
      client: apiClient,
    },
  );
  const { formatTimeStamp } = useDateFns();
  const { selectNextAsset, selectPreviousAsset } =
    MediaSidebarState.useContainer();
  const { selectedOrgId } = UserState.useContainer();
  const { data: meta, loading: metaFieldsLoading } =
    useMetadataFieldsSubscription({
      orgId: selectedOrgId,
    });
  const { viewableMetaFields } = useViewableMeta({
    folderId: data?.media?.folderId || "",
    metaFields: meta?.metadataFields,
  });
  const { data: vlConfig } = useOrgConfig();

  useEffect(() => {
    const onKeyDown = ({ keyCode: code }: KeyboardEvent) => {
      if (code === 37 || code === 38) {
        selectPreviousAsset();
      }
      if (code === 39 || code === 40) {
        selectNextAsset();
      }
    };
    document.addEventListener("keydown", onKeyDown, false);
    return () => document.removeEventListener("keydown", onKeyDown, false);
  }, [selectPreviousAsset, selectNextAsset]);

  const originalFile = data?.media?.originalFiles[0];

  useEffect(() => {
    if (originalFile) {
      getSignedUrl({
        variables: { assetFileId: originalFile.id },
      });
    }
  }, [getSignedUrl, originalFile]);

  if (!data?.media) {
    return null;
  }

  const signedUrl = signedUrlData && signedUrlData.s3SignedUrl;

  const {
    media: {
      name: mediaName,
      createdAt,
      creator,
      statistics,
      metaValues,
      description,
    },
  } = data;

  const profiles = originalFile?.transcoderProfiles || [];
  const finishedProfiles = profiles.filter(
    (profile) => profile.status === JobStatusTypesEnum.FINISHED,
  );
  const playlistProfiles =
    finishedProfiles
      .filter((profile) => !!profile.outputFile?.storageKey)
      .map(
        ({
          width,
          height,
          videoBitrateKbps,
          audioBitrateKbps,
          outputFile,
        }) => ({
          width,
          height,
          bandwidth: videoBitrateKbps + audioBitrateKbps,
          key: outputFile?.storageKey || "",
        }),
      ) || [];

  const { streamServerUrl, playlistTemplate, playbackUrlTemplate } =
    vlConfig?.defaultConfig?.proto?.vodStream || {};

  const playlist = createPlaylist({
    profiles: playlistProfiles,
    streamServerUrl,
    template: playlistTemplate,
  });

  const src =
    typeof playbackUrlTemplate === "string"
      ? createStream({
          template: playbackUrlTemplate,
          templateParams: {
            profiles: playlistProfiles,
            media: data.media,
          },
        })
      : `data:application/x-mpegURL;base64,${btoa(playlist)}`;

  return (
    <>
      <SpreadRow>
        <SimpleText as="h3" t="common.details" />
        <MediaAssetActions media={data.media} />
      </SpreadRow>
      <DescriptionItem marginVertical="small" label="Location">
        <FolderBreadcrumbs noSearch folderId={data.media.folderId} />
      </DescriptionItem>
      <DescriptionItem marginVertical="small" labelT="common.name">
        {mediaName}
      </DescriptionItem>
      <MarginedDivider margin="medium" />
      {!!originalFile && isMimeImage(originalFile) && (
        <DescriptionItem labelT="common.preview">
          <ImagePreview originalFile={originalFile} />
        </DescriptionItem>
      )}
      {originalFile &&
        finishedProfiles.length > 0 &&
        isMimeVideo(originalFile) && (
          <DescriptionItem labelT="common.preview">
            <ErrorBoundary>
              <VideoPlayer
                controls
                html5={{
                  hls: {
                    overrideNative: true,
                  },
                  nativeAudioTracks: false,
                  nativeVideoTracks: false,
                }}
                sources={[
                  {
                    src,
                    type: "application/x-mpegURL",
                  },
                ]}
              />
            </ErrorBoundary>
          </DescriptionItem>
        )}
      <DescriptionItem marginVertical="small" labelT="common.createdAt">
        {formatTimeStamp(createdAt)}
      </DescriptionItem>
      {!!creator && (
        <DescriptionItem marginVertical="small" labelT="common.createdBy">
          {creator.name || creator.email}
        </DescriptionItem>
      )}
      {!!statistics.total &&
        statistics.total.file &&
        statistics.total.file.size && (
          <DescriptionItem marginVertical="small" labelT="common.size">
            {formatBytes(statistics.total.file.size)}
          </DescriptionItem>
        )}
      {!!signedUrl && (
        <DescriptionItem marginVertical="small" labelT="common.url">
          <CopyInput value={signedUrl} contentEditable={false} />
        </DescriptionItem>
      )}
      <ProfileStatuses profiles={profiles} />
      {metaFieldsLoading && <Loading marginVertical="medium" />}
      {!!description && (
        <DescriptionItem marginVertical="small" labelT="common.description">
          {description}
        </DescriptionItem>
      )}
      {viewableMetaFields.map((field) => (
        <DescriptionItem
          marginVertical="small"
          key={field.id}
          label={field.name}
        >
          <MetaFieldValues field={field} metadataValues={metaValues} />
        </DescriptionItem>
      ))}
    </>
  );
};
