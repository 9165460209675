import { Property } from "csstype";
import globalTheme from "style/theme";
import styled from "styled-components";

export interface FlexProps {
  mainAxis?: Property.JustifyContent;
  crossAxis?: Property.AlignItems;
  padding?: keyof typeof globalTheme.margin;
  margin?: keyof typeof globalTheme.margin;
  marginVertical?: keyof typeof globalTheme.margin;
  marginHorizontal?: keyof typeof globalTheme.margin;
  grow?: number;
  basis?: string;
  flex?: number;
  flexWrap?: boolean;
}

export const Row = styled.div<FlexProps>`
  display: flex;
  ${({ flex }) => (typeof flex === "number" ? `flex: ${flex};` : "flex: 1;")}
  ${({ grow }) => (typeof grow === "number" ? `flex-grow: ${grow};` : "")}
  ${({ basis }) => (typeof basis === "string" ? `flex-basis: ${basis};` : "")}
  ${({ mainAxis }) => (mainAxis ? `justify-content: ${mainAxis};` : "")}
  ${({ crossAxis }) => (crossAxis ? `align-items: ${crossAxis};` : "")}
  ${({ padding, theme }) =>
    padding ? `padding: ${theme.margin[padding]};` : ""}
  ${({ margin, theme }) => (margin ? `margin: ${theme.margin[margin]};` : "")}
  ${({ marginHorizontal, theme }) =>
    marginHorizontal
      ? `margin-left: ${theme.margin[marginHorizontal]};
      margin-right: ${theme.margin[marginHorizontal]};`
      : ""}
  ${({ marginVertical, theme }) =>
    marginVertical
      ? `margin-top: ${theme.margin[marginVertical]}; margin-bottom: ${theme.margin[marginVertical]};`
      : ""}
  ${({ flexWrap }) => (flexWrap ? "flex-wrap: wrap;" : "")}
`;

export const Column = styled(Row)`
  flex-direction: column;
`;

export const SpreadRow = styled(Row)`
  justify-content: space-between;
  align-items: ${({ crossAxis }) => crossAxis || "baseline"};
`;
