import { TimePicker } from "antd";
import { timeStringToDate } from "components/hooks/useDateFns";
import { moment } from "localisation/i18n";
import React from "react";
import InputWrapper from "./InputWrapper";
import { ResourceInputProps } from "./types";

const timeStringToMoment = (timeString?: string) =>
  timeString ? moment(timeStringToDate(timeString)) : undefined;

export default (props: ResourceInputProps<string>) => {
  const {
    resolveValue,
    resolveFallback,
    dataPath,
    locale,
    onValueSaved,
    isRequired,
  } = props;
  const value = resolveValue({ locale, dataPath });
  const fallbackValue = resolveFallback({ locale, dataPath });

  return (
    <InputWrapper {...props}>
      <TimePicker
        style={{ minWidth: 140 }}
        allowClear={!isRequired}
        placeholder={fallbackValue}
        value={timeStringToMoment(value)}
        onChange={(m) => {
          const newValue = m ? m.toISOString().split("T")[1] : undefined;
          onValueSaved({ value: newValue, locale, dataPath });
        }}
      />
    </InputWrapper>
  );
};
