import { vlApiClient } from "api/clients";
import { getFirstErrorType } from "authService/errorHandling";
import refreshToken from "authService/refreshToken";
import { User, UserRole } from "state/UserState";
import {
  GatewayLoginDocument,
  GatewayLoginMutation,
  GatewayLoginMutationVariables,
  VlApiErrorTypes,
} from "__gen__/appGatewaySdk";

const tryGatewayLogin = async (authToken?: string | null) => {
  if (!authToken) {
    throw new Error("Missing auth token");
  }
  const { data } = await vlApiClient.mutate<
    GatewayLoginMutation,
    GatewayLoginMutationVariables
  >({ mutation: GatewayLoginDocument, variables: { token: authToken } });
  let user: User | undefined;
  if (data) {
    const { id, name, email, photoUrl, roles } = data.user;
    user = { id, name, email, photoUrl, allowedRoles: roles as UserRole[] };
  }
  return { token: authToken, user };
};

export default async (authToken?: string | null) => {
  try {
    return await tryGatewayLogin(authToken);
  } catch (error) {
    const errorType = getFirstErrorType<VlApiErrorTypes>(error as any);
    if (errorType === VlApiErrorTypes.TOKEN_REFRESH_NEEDED) {
      const { token } = await refreshToken();
      return await tryGatewayLogin(token);
    } else {
      throw error;
    }
  }
};
