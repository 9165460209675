import { InputNumber } from "antd";
import { InputNumberProps } from "antd/lib/input-number";
import React, { useEffect, useState } from "react";

interface EditableNumberProps extends InputNumberProps {
  initialValue?: number;
  onSave: (text?: number) => void;
}

export default ({
  initialValue,
  onSave: onSaveNumber,
  ...props
}: EditableNumberProps) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onSave = () => {
    const v = parseFloat(`${value}`);
    const newValue = isNaN(v) ? undefined : v;
    setValue(newValue);
    onSaveNumber(newValue);
  };

  const onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if ((ev.metaKey || ev.ctrlKey) && ev.key === "Enter") {
      onSave();
    }
  };

  return (
    <InputNumber
      onKeyDown={onKeyDown}
      onBlur={onSave}
      value={value}
      onChange={(v) =>
        typeof v === "string" ? setValue(parseFloat(v)) : setValue(v)
      }
      {...props}
    />
  );
};
