import downloadFileWithUrl from "functions/downloadFileWithUrl";
import GraphQLClientState from "state/GraphQLClientState";
import {
  S3DownloadUrlDocument,
  S3DownloadUrlQuery,
  S3DownloadUrlQueryVariables,
} from "__gen__/appGatewaySdk";

export default () => {
  const { apiClient } = GraphQLClientState.useContainer();

  const downloadFile = async ({
    assetFileId,
    fileName,
  }: {
    assetFileId: string;
    fileName?: string;
  }) => {
    if (apiClient) {
      const {
        data: { s3DownloadUrl },
      } = await apiClient.query<
        S3DownloadUrlQuery,
        S3DownloadUrlQueryVariables
      >({
        query: S3DownloadUrlDocument,
        variables: { assetFileId, fileName },
      });
      downloadFileWithUrl({ url: s3DownloadUrl });
    }
  };

  return { downloadFile };
};
