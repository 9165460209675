import { Table } from "antd";
import Avatar from "components/common/avatar/Avatar";
import VLButton from "components/common/buttons/VLButton";
import { SpreadRow } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import strings from "localisation/strings";
import { concat, pluck, remove } from "ramda";
import React from "react";
import { ReactState } from "state/interfaces/ReactState";
import styled from "styled-components";
import joinNameEmail from "./functions/joinNameEmail";
import MemberSelect, { OrgMember } from "./MemberSelect";

const DeleteButton = styled(VLButton)`
  margin-left: ${({ theme }) => theme.margin.medium};
`;

interface TeamMembersProps {
  orgSlug: string;
  membersState: ReactState<OrgMember[]>;
}

export default ({
  orgSlug,
  membersState: [members, setMembers],
}: TeamMembersProps) => {
  return (
    <>
      <SpreadRow
        grow={0}
        mainAxis="flex-end"
        marginVertical="medium"
        crossAxis="center"
      >
        <SimpleText color="tertiary" t="teamMembers.title" />
        <MemberSelect
          orgSlug={orgSlug}
          excludedMemberIds={pluck("id", members)}
          onMembersAdded={(addedMembers) => setMembers(concat(addedMembers))}
        />
      </SpreadRow>
      <Table<OrgMember>
        dataSource={members}
        size="small"
        pagination={false}
        rowKey="id"
      >
        <Table.Column<OrgMember>
          title={strings("common.nameDotEmail")}
          dataIndex="name"
          render={(_, { user }) => (
            <>
              <Avatar name={user.name || user.email} />
              <SimpleText marginHorizontal="medium">
                {joinNameEmail(user)}
              </SimpleText>
            </>
          )}
        />
        <Table.Column<OrgMember>
          dataIndex="actions"
          align="right"
          render={(_, __, index) => (
            <>
              <DeleteButton
                size="small"
                shape="circle"
                icon="delete"
                onClick={() => setMembers(remove(index, 1))}
              />
            </>
          )}
        />
      </Table>
    </>
  );
};
