import { Column } from "components/common/layout/Flex";
import React from "react";
import ComponentEditorState from "state/ComponentEditorState";
import { getComponentSchema } from "./functions/getComponentSchema";
import { useComponentSchema } from "./hooks/useComponentSchema";
import TypeInput, { transformAnyType, validateAnyType } from "./TypeInput";
import { DataPath, ValidationError, VlComponentState } from "./types";
interface ComponentEditorProps {
  componentName?: string;
  value: any;
  definition: { schemaUrl: string };
  onValueChange: (newValue: any) => void;
  errors: ValidationError[];
  dataPath: DataPath;
}

export const validateComponentProps = async (
  state: VlComponentState,
  { dataPath }: { dataPath: DataPath },
) => {
  const componentDefinition = await getComponentSchema(state.schemaUrl);
  return await validateAnyType({
    value: state.props,
    schemaType: componentDefinition.propsType,
    typeMap: componentDefinition.propsTypeMap,
    dataPath: [...dataPath, "props"],
  });
};

export const transformComponent = async (state: VlComponentState) => {
  const componentDefinition = await getComponentSchema(state.schemaUrl);
  return await transformAnyType({
    value: state.props,
    schemaType: componentDefinition.propsType,
  });
};

export default function ComponentEditor({
  definition,
  value,
  onValueChange,
  errors,
  dataPath,
}: ComponentEditorProps) {
  const parsedDefinition = useComponentSchema(definition.schemaUrl);

  if (!parsedDefinition) {
    return null;
  }
  return (
    <ComponentEditorState.Provider
      initialState={{
        componentTypeMap: parsedDefinition.propsTypeMap,
        errors,
      }}
    >
      <Column>
        <TypeInput
          value={value}
          onValueChange={onValueChange}
          schemaType={parsedDefinition.propsType}
          dataPath={[...dataPath, "props"]}
        />
      </Column>
    </ComponentEditorState.Provider>
  );
}
