import { Table } from "antd";
import { Row } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import CanEditOrgData from "components/permissionControl/CanEditOrgData";
import strings from "localisation/strings";
import React from "react";
import styled from "styled-components";
import { useMetadataFieldsSubscription } from "__gen__/appService";
import DeleteMetadataFieldButton from "./DeleteMetadataFieldButton";
import { MetaField } from "./MetadataFieldTypes";
import MetaFieldDefaultsRenderer from "./MetaFieldDefaultsRenderer";
import MetaFieldRestrictionsRenderer from "./MetaFieldRestrictionsRenderer";
import UpsertMetadataFields from "./UpsertMetadataFields";

const AddFieldButton = styled(UpsertMetadataFields)`
  margin-left: ${({ theme }) => theme.margin.medium};
`;

const EditButton = styled(UpsertMetadataFields)`
  margin-right: ${({ theme }) => theme.margin.medium};
`;

interface MetadataSpecsProps {
  orgId: string;
}

export default ({ orgId }: MetadataSpecsProps) => {
  const { data, loading } = useMetadataFieldsSubscription({ orgId });

  const metadataFields = (data && data.metadataFields) || [];

  return (
    <>
      <Row grow={0} mainAxis="flex-end" marginVertical="medium">
        <CanEditOrgData>
          <AddFieldButton orgId={orgId} />
        </CanEditOrgData>
      </Row>
      <Table<MetaField>
        dataSource={metadataFields}
        rowKey="id"
        pagination={false}
        loading={loading}
        size="small"
      >
        <Table.Column<MetaField>
          dataIndex="name"
          title={strings("common.name")}
        />
        <Table.Column<MetaField>
          dataIndex="type"
          title={strings("common.type")}
        />
        <Table.Column<MetaField>
          dataIndex="possibleValues"
          title={strings("metadataSpecs.possibleValues")}
          render={(_, field) => <MetaFieldRestrictionsRenderer field={field} />}
        />
        <Table.Column<MetaField>
          dataIndex="defaultValues"
          title={strings("metadataSpecs.defaultValues")}
          render={(_, field) => <MetaFieldDefaultsRenderer field={field} />}
        />
        <Table.Column<MetaField>
          dataIndex="required"
          title={strings("metadataSpecs.required")}
          render={(_, { options: { required } }) => {
            if (required) {
              return <SimpleText color="tertiary" t="common.yes" />;
            }
            return <SimpleText color="tertiary" t="common.no" />;
          }}
        />
        <Table.Column<MetaField>
          dataIndex="actions"
          render={(_, field) => (
            <CanEditOrgData>
              <EditButton
                size="small"
                icon="edit"
                orgId={orgId}
                initialValues={field}
              />
              <DeleteMetadataFieldButton fieldId={field.id || ""} />
            </CanEditOrgData>
          )}
        />
      </Table>
    </>
  );
};
