import { isSimpleTextMeta } from "components/assetMetaSpecs/MetadataFieldTypes";
import VLTextArea from "components/common/input/VLTextArea";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MetaFieldInputProps } from "./MetaFieldInputTypes";

const InputElement = styled(VLTextArea)`
  flex: 1;
`;

export default ({
  field,
  onValuesChanged,
  values: {
    textValues: [selectedValue],
  },
}: MetaFieldInputProps) => {
  const [localValue, setLocalValue] = useState("");

  useEffect(() => {
    setLocalValue(selectedValue || "");
  }, [selectedValue, setLocalValue]);

  if (!isSimpleTextMeta(field)) {
    return null;
  }
  const onTextChange = (text: string) => {
    setLocalValue(text);
  };
  const onBlur = () => {
    onValuesChanged({
      fieldId: field.id || "",
      values: { textValues: [localValue] },
    });
  };
  return (
    <InputElement
      placeholder={field.name}
      value={localValue}
      onBlur={onBlur}
      onTextChange={onTextChange}
      autoSize
    />
  );
};
