import { UppyFile } from "@uppy/utils";
import { Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import React, { useEffect, useState } from "react";
import UploadState from "state/UploadState";
import styled from "styled-components";
import VLButton from "../buttons/VLButton";
import { Row } from "../layout/Flex";
import { SimpleText } from "../text/SimpleText";
import UploadFileList from "./UploadFileList";

const FilesWaitingForUpload = styled(UploadFileList)`
  margin: ${({ theme }) => theme.margin.medium};
`;

const UploadAreaBackground = styled.div`
  margin-top: ${({ theme }) => theme.margin.medium};
  background-color: ${({ theme }) => theme.color.background.upload};
`;

const CancelButton = styled(VLButton)`
  margin-right: ${({ theme }) => theme.margin.medium};
`;

interface UploadAreaProps {
  orgId: string;
  folderId: string;
}

export default ({ orgId, folderId }: UploadAreaProps) => {
  const {
    addFile,
    filesWaitingForUpload,
    clearWaiting,
    removeFile,
    startUpload,
  } = UploadState.useContainer();
  const [loading, setLoading] = useState(false);
  const anyFilesWaiting = filesWaitingForUpload.length > 0;

  useEffect(() => {
    if (!anyFilesWaiting) {
      setLoading(false);
    }
  }, [anyFilesWaiting, setLoading]);

  const onFileAdded = (file: RcFile) => {
    addFile(file, { orgId, folderId });
    return false;
  };

  const onFileRemoved = (file: UppyFile) => {
    removeFile(file.id);
  };
  const onUpload = () => {
    setLoading(true);
    startUpload();
  };

  const onCancel = () => {
    clearWaiting();
  };

  return (
    <UploadAreaBackground>
      <Upload.Dragger
        disabled={loading}
        beforeUpload={onFileAdded}
        fileList={[]}
        multiple
      >
        <SimpleText color="secondary" t="uploadArea.dragDropArea" />
      </Upload.Dragger>
      <FilesWaitingForUpload
        files={filesWaitingForUpload}
        onFileRemoved={onFileRemoved}
        showSize
      />
      {anyFilesWaiting && (
        <Row mainAxis="flex-end" padding="medium">
          <CancelButton onClick={onCancel} t="buttons.cancel" />
          <VLButton
            loading={loading}
            type="primary"
            onClick={onUpload}
            t="buttons.upload"
          />
        </Row>
      )}
    </UploadAreaBackground>
  );
};
