import { Table } from "antd";
import useDateFns from "components/hooks/useDateFns";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import React from "react";
import { useHistory } from "react-router-dom";
import UserState from "state/UserState";
import styled from "styled-components";
import {
  ProjectsTableSubscription,
  useProjectsTableSubscription,
} from "__gen__/appService";

const tableRowClassname = "vl-table-row";
const TableContainer = styled.div`
  .${tableRowClassname} {
    cursor: pointer;
  }
`;

const ProjectsTable = () => {
  const { selectedOrgId } = UserState.useContainer();
  const { data, loading } = useProjectsTableSubscription({
    orgId: selectedOrgId,
  });
  const { formatTimeStamp } = useDateFns();
  const history = useHistory();

  const projects = (data && data.projects) || [];

  const dataSource = projects.map((project) => ({
    ...project,
    createdAt: formatTimeStamp(project.createdAt),
    orgName: project.organization.name,
  }));

  return (
    <TableContainer>
      <Table<ProjectsTableSubscription["projects"][0]>
        rowClassName={tableRowClassname}
        size="middle"
        loading={loading}
        dataSource={dataSource}
        rowKey="id"
        pagination={{ hideOnSinglePage: true }}
        onRow={(project) => ({
          onClick: () =>
            history.push(
              Paths.projectCms.withParams({ projectId: project.id }),
            ),
        })}
      >
        <Table.Column
          key="name"
          title={strings("projectsTable.columns.name")}
          dataIndex="name"
        />
        <Table.Column
          key="createdAt"
          title={strings("projectsTable.columns.createdAt")}
          dataIndex="createdAt"
        />
      </Table>
    </TableContainer>
  );
};

export default ProjectsTable;
