import gatewayLogin from "appGateway/gatewayLogin";
import authServiceClient from "authService/authGatewayClient";
import {
  EmailLoginDocument,
  EmailLoginMutation,
  EmailLoginMutationVariables,
} from "__gen__/authGatewaySdk";

export default async ({
  email,
  password,
  rememberUser,
}: {
  email: string;
  password: string;
  rememberUser: boolean;
}) => {
  let authToken;
  const { data } = await authServiceClient.mutate<
    EmailLoginMutation,
    EmailLoginMutationVariables
  >({
    mutation: EmailLoginDocument,
    variables: { email, password, rememberUser },
  });

  authToken = data && data.login.token;
  return await gatewayLogin(authToken);
};
