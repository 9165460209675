import gql from "graphql-tag";
import * as ApolloReactHooks from "components/hooks/apollo";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  float8: any;
  jsonb: any;
  name: any;
  numeric: number;
  timestamptz: string;
  uuid: string;
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export interface BooleanComparisonExp {
  _eq?: Maybe<Scalars["Boolean"]>;
  _gt?: Maybe<Scalars["Boolean"]>;
  _gte?: Maybe<Scalars["Boolean"]>;
  _in?: Maybe<Array<Scalars["Boolean"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Boolean"]>;
  _lte?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<Scalars["Boolean"]>;
  _nin?: Maybe<Array<Scalars["Boolean"]>>;
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface IntComparisonExp {
  _eq?: Maybe<Scalars["Int"]>;
  _gt?: Maybe<Scalars["Int"]>;
  _gte?: Maybe<Scalars["Int"]>;
  _in?: Maybe<Array<Scalars["Int"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Int"]>;
  _lte?: Maybe<Scalars["Int"]>;
  _neq?: Maybe<Scalars["Int"]>;
  _nin?: Maybe<Array<Scalars["Int"]>>;
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface StringComparisonExp {
  _eq?: Maybe<Scalars["String"]>;
  _gt?: Maybe<Scalars["String"]>;
  _gte?: Maybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars["String"]>;
  _in?: Maybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars["String"]>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars["String"]>;
  _lt?: Maybe<Scalars["String"]>;
  _lte?: Maybe<Scalars["String"]>;
  _neq?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars["String"]>;
  _nin?: Maybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars["String"]>;
}

/** input type for inserting array relation for remote table "archive_files" */
export interface ArchiveFilesArrRelInsertInput {
  data: Array<ArchiveFilesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<ArchiveFilesOnConflict>;
}

/** Boolean expression to filter rows from the table "archive_files". All fields are combined with a logical 'AND'. */
export interface ArchiveFilesBoolExp {
  _and?: Maybe<Array<ArchiveFilesBoolExp>>;
  _not?: Maybe<ArchiveFilesBoolExp>;
  _or?: Maybe<Array<ArchiveFilesBoolExp>>;
}

/** unique or primary key constraints on table "archive_files" */
export enum ArchiveFilesConstraint {
  /** unique or primary key constraint on columns "id" */
  archive_files_pkey = "archive_files_pkey",
}

/** input type for inserting data into table "archive_files" */
export interface ArchiveFilesInsertInput {
  archive_job?: Maybe<ArchiveJobsObjRelInsertInput>;
  archive_job_id?: Maybe<Scalars["uuid"]>;
  asset_file?: Maybe<AssetFilesObjRelInsertInput>;
  asset_file_id?: Maybe<Scalars["uuid"]>;
  output_name?: Maybe<Scalars["String"]>;
  output_path?: Maybe<Scalars["String"]>;
}

/** response of any mutation on the table "archive_files" */
export interface ArchiveFilesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
}

/** on_conflict condition type for table "archive_files" */
export interface ArchiveFilesOnConflict {
  constraint: ArchiveFilesConstraint;
  update_columns?: Array<ArchiveFilesUpdateColumn>;
  where?: Maybe<ArchiveFilesBoolExp>;
}

/** placeholder for update columns of table "archive_files" (current role has no relevant permissions) */
export enum ArchiveFilesUpdateColumn {
  /** placeholder (do not use) */
  _PLACEHOLDER = "_PLACEHOLDER",
}

/** columns and relationships of "archive_jobs" */
export interface ArchiveJobs {
  created_at: Scalars["timestamptz"];
  error?: Maybe<Scalars["jsonb"]>;
  /** An object relationship */
  folder: Folders;
  folder_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  output_file?: Maybe<AssetFiles>;
  output_file_id?: Maybe<Scalars["uuid"]>;
  output_name: Scalars["String"];
  output_warehouse_id: Scalars["uuid"];
  proto?: Maybe<Scalars["jsonb"]>;
  status: TranscoderJobStatusTypesEnum;
}

/** columns and relationships of "archive_jobs" */
export interface ArchiveJobsErrorArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "archive_jobs" */
export interface ArchiveJobsProtoArgs {
  path?: Maybe<Scalars["String"]>;
}

/** Boolean expression to filter rows from the table "archive_jobs". All fields are combined with a logical 'AND'. */
export interface ArchiveJobsBoolExp {
  _and?: Maybe<Array<ArchiveJobsBoolExp>>;
  _not?: Maybe<ArchiveJobsBoolExp>;
  _or?: Maybe<Array<ArchiveJobsBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  error?: Maybe<JsonbComparisonExp>;
  folder?: Maybe<FoldersBoolExp>;
  folder_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  output_file?: Maybe<AssetFilesBoolExp>;
  output_file_id?: Maybe<UuidComparisonExp>;
  output_name?: Maybe<StringComparisonExp>;
  output_warehouse_id?: Maybe<UuidComparisonExp>;
  proto?: Maybe<JsonbComparisonExp>;
  status?: Maybe<TranscoderJobStatusTypesEnumComparisonExp>;
}

/** unique or primary key constraints on table "archive_jobs" */
export enum ArchiveJobsConstraint {
  /** unique or primary key constraint on columns "output_file_id" */
  archive_jobs_output_file_id_key = "archive_jobs_output_file_id_key",
  /** unique or primary key constraint on columns "id" */
  archive_jobs_pkey = "archive_jobs_pkey",
}

/** input type for inserting data into table "archive_jobs" */
export interface ArchiveJobsInsertInput {
  archive_files?: Maybe<ArchiveFilesArrRelInsertInput>;
  folder?: Maybe<FoldersObjRelInsertInput>;
  folder_id?: Maybe<Scalars["uuid"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  output_file?: Maybe<AssetFilesObjRelInsertInput>;
  output_name?: Maybe<Scalars["String"]>;
  output_warehouse_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
}

/** response of any mutation on the table "archive_jobs" */
export interface ArchiveJobsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<ArchiveJobs>;
}

/** input type for inserting object relation for remote table "archive_jobs" */
export interface ArchiveJobsObjRelInsertInput {
  data: ArchiveJobsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<ArchiveJobsOnConflict>;
}

/** on_conflict condition type for table "archive_jobs" */
export interface ArchiveJobsOnConflict {
  constraint: ArchiveJobsConstraint;
  update_columns?: Array<ArchiveJobsUpdateColumn>;
  where?: Maybe<ArchiveJobsBoolExp>;
}

/** Ordering options when selecting data from "archive_jobs". */
export interface ArchiveJobsOrderBy {
  created_at?: Maybe<OrderBy>;
  error?: Maybe<OrderBy>;
  folder?: Maybe<FoldersOrderBy>;
  folder_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  output_file?: Maybe<AssetFilesOrderBy>;
  output_file_id?: Maybe<OrderBy>;
  output_name?: Maybe<OrderBy>;
  output_warehouse_id?: Maybe<OrderBy>;
  proto?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
}

/** select columns of table "archive_jobs" */
export enum ArchiveJobsSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  error = "error",
  /** column name */
  folder_id = "folder_id",
  /** column name */
  id = "id",
  /** column name */
  output_file_id = "output_file_id",
  /** column name */
  output_name = "output_name",
  /** column name */
  output_warehouse_id = "output_warehouse_id",
  /** column name */
  proto = "proto",
  /** column name */
  status = "status",
}

/** Streaming cursor of the table "archive_jobs" */
export interface ArchiveJobsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ArchiveJobsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ArchiveJobsStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  error?: Maybe<Scalars["jsonb"]>;
  folder_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  output_file_id?: Maybe<Scalars["uuid"]>;
  output_name?: Maybe<Scalars["String"]>;
  output_warehouse_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  status?: Maybe<TranscoderJobStatusTypesEnum>;
}

/** placeholder for update columns of table "archive_jobs" (current role has no relevant permissions) */
export enum ArchiveJobsUpdateColumn {
  /** placeholder (do not use) */
  _PLACEHOLDER = "_PLACEHOLDER",
}

/** columns and relationships of "asset_file_types" */
export interface AssetFileTypes {
  type: Scalars["String"];
}

/** Boolean expression to filter rows from the table "asset_file_types". All fields are combined with a logical 'AND'. */
export interface AssetFileTypesBoolExp {
  _and?: Maybe<Array<AssetFileTypesBoolExp>>;
  _not?: Maybe<AssetFileTypesBoolExp>;
  _or?: Maybe<Array<AssetFileTypesBoolExp>>;
  type?: Maybe<StringComparisonExp>;
}

export enum AssetFileTypesEnum {
  ARCHIVE = "ARCHIVE",
  IMAGE_THUMBNAIL = "IMAGE_THUMBNAIL",
  ORIGINAL = "ORIGINAL",
  TRANSCODED_VIDEO = "TRANSCODED_VIDEO",
  UNKNOWN = "UNKNOWN",
  VIDEO_THUMBNAIL = "VIDEO_THUMBNAIL",
}

/** Boolean expression to compare columns of type "asset_file_types_enum". All fields are combined with logical 'AND'. */
export interface AssetFileTypesEnumComparisonExp {
  _eq?: Maybe<AssetFileTypesEnum>;
  _in?: Maybe<Array<AssetFileTypesEnum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<AssetFileTypesEnum>;
  _nin?: Maybe<Array<AssetFileTypesEnum>>;
}

/** Ordering options when selecting data from "asset_file_types". */
export interface AssetFileTypesOrderBy {
  type?: Maybe<OrderBy>;
}

/** select columns of table "asset_file_types" */
export enum AssetFileTypesSelectColumn {
  /** column name */
  type = "type",
}

/** Streaming cursor of the table "asset_file_types" */
export interface AssetFileTypesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AssetFileTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AssetFileTypesStreamCursorValueInput {
  type?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "asset_files" */
export interface AssetFiles {
  created_at: Scalars["timestamptz"];
  created_by?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  creator?: Maybe<Users>;
  id: Scalars["uuid"];
  /** An array relationship */
  image_thumbnail_jobs: Array<ImageThumbnailJobs>;
  /** An array relationship */
  image_thumbnails: Array<ImageThumbnails>;
  /** An object relationship */
  media_asset?: Maybe<MediaAssets>;
  media_asset_id?: Maybe<Scalars["uuid"]>;
  mime_type: Scalars["String"];
  name: Scalars["String"];
  org_id: Scalars["uuid"];
  /** An object relationship */
  organization: Organizations;
  size: Scalars["bigint"];
  storage_key: Scalars["String"];
  /** An object relationship */
  thumbnail?: Maybe<ThumbnailProfiles>;
  /** An array relationship */
  thumbnail_profiles: Array<ThumbnailProfiles>;
  /** An array relationship */
  transcoder_profiles: Array<TranscoderProfiles>;
  type: AssetFileTypesEnum;
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  video?: Maybe<TranscoderProfiles>;
  warehouse_id: Scalars["uuid"];
}

/** columns and relationships of "asset_files" */
export interface AssetFilesImageThumbnailJobsArgs {
  distinct_on?: Maybe<Array<ImageThumbnailJobsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ImageThumbnailJobsOrderBy>>;
  where?: Maybe<ImageThumbnailJobsBoolExp>;
}

/** columns and relationships of "asset_files" */
export interface AssetFilesImageThumbnailsArgs {
  distinct_on?: Maybe<Array<ImageThumbnailsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ImageThumbnailsOrderBy>>;
  where?: Maybe<ImageThumbnailsBoolExp>;
}

/** columns and relationships of "asset_files" */
export interface AssetFilesThumbnailProfilesArgs {
  distinct_on?: Maybe<Array<ThumbnailProfilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ThumbnailProfilesOrderBy>>;
  where?: Maybe<ThumbnailProfilesBoolExp>;
}

/** columns and relationships of "asset_files" */
export interface AssetFilesTranscoderProfilesArgs {
  distinct_on?: Maybe<Array<TranscoderProfilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TranscoderProfilesOrderBy>>;
  where?: Maybe<TranscoderProfilesBoolExp>;
}

/** aggregated selection of "asset_files" */
export interface AssetFilesAggregate {
  aggregate?: Maybe<AssetFilesAggregateFields>;
  nodes: Array<AssetFiles>;
}

export interface AssetFilesAggregateBoolExp {
  count?: Maybe<AssetFilesAggregateBoolExpCount>;
}

export interface AssetFilesAggregateBoolExpCount {
  arguments?: Maybe<Array<AssetFilesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<AssetFilesBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "asset_files" */
export interface AssetFilesAggregateFields {
  avg?: Maybe<AssetFilesAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<AssetFilesMaxFields>;
  min?: Maybe<AssetFilesMinFields>;
  stddev?: Maybe<AssetFilesStddevFields>;
  stddev_pop?: Maybe<AssetFilesStddevPopFields>;
  stddev_samp?: Maybe<AssetFilesStddevSampFields>;
  sum?: Maybe<AssetFilesSumFields>;
  var_pop?: Maybe<AssetFilesVarPopFields>;
  var_samp?: Maybe<AssetFilesVarSampFields>;
  variance?: Maybe<AssetFilesVarianceFields>;
}

/** aggregate fields of "asset_files" */
export interface AssetFilesAggregateFieldsCountArgs {
  columns?: Maybe<Array<AssetFilesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** order by aggregate values of table "asset_files" */
export interface AssetFilesAggregateOrderBy {
  avg?: Maybe<AssetFilesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AssetFilesMaxOrderBy>;
  min?: Maybe<AssetFilesMinOrderBy>;
  stddev?: Maybe<AssetFilesStddevOrderBy>;
  stddev_pop?: Maybe<AssetFilesStddevPopOrderBy>;
  stddev_samp?: Maybe<AssetFilesStddevSampOrderBy>;
  sum?: Maybe<AssetFilesSumOrderBy>;
  var_pop?: Maybe<AssetFilesVarPopOrderBy>;
  var_samp?: Maybe<AssetFilesVarSampOrderBy>;
  variance?: Maybe<AssetFilesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "asset_files" */
export interface AssetFilesArrRelInsertInput {
  data: Array<AssetFilesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<AssetFilesOnConflict>;
}

/** aggregate avg on columns */
export interface AssetFilesAvgFields {
  size?: Maybe<Scalars["Float"]>;
}

/** order by avg() on columns of table "asset_files" */
export interface AssetFilesAvgOrderBy {
  size?: Maybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "asset_files". All fields are combined with a logical 'AND'. */
export interface AssetFilesBoolExp {
  _and?: Maybe<Array<AssetFilesBoolExp>>;
  _not?: Maybe<AssetFilesBoolExp>;
  _or?: Maybe<Array<AssetFilesBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  creator?: Maybe<UsersBoolExp>;
  id?: Maybe<UuidComparisonExp>;
  image_thumbnail_jobs?: Maybe<ImageThumbnailJobsBoolExp>;
  image_thumbnails?: Maybe<ImageThumbnailsBoolExp>;
  media_asset?: Maybe<MediaAssetsBoolExp>;
  media_asset_id?: Maybe<UuidComparisonExp>;
  mime_type?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  org_id?: Maybe<UuidComparisonExp>;
  organization?: Maybe<OrganizationsBoolExp>;
  size?: Maybe<BigintComparisonExp>;
  storage_key?: Maybe<StringComparisonExp>;
  thumbnail?: Maybe<ThumbnailProfilesBoolExp>;
  thumbnail_profiles?: Maybe<ThumbnailProfilesBoolExp>;
  transcoder_profiles?: Maybe<TranscoderProfilesBoolExp>;
  type?: Maybe<AssetFileTypesEnumComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  video?: Maybe<TranscoderProfilesBoolExp>;
  warehouse_id?: Maybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "asset_files" */
export enum AssetFilesConstraint {
  /** unique or primary key constraint on columns "id" */
  asset_files_pkey = "asset_files_pkey",
  /** unique or primary key constraint on columns "storage_key" */
  asset_files_storage_key_key = "asset_files_storage_key_key",
  /** unique or primary key constraint on columns "transcoded_profile_id" */
  asset_files_transcoded_profile_key = "asset_files_transcoded_profile_key",
}

/** input type for inserting data into table "asset_files" */
export interface AssetFilesInsertInput {
  id?: Maybe<Scalars["uuid"]>;
  image_thumbnail_jobs?: Maybe<ImageThumbnailJobsArrRelInsertInput>;
  media_asset?: Maybe<MediaAssetsObjRelInsertInput>;
  media_asset_id?: Maybe<Scalars["uuid"]>;
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  organization?: Maybe<OrganizationsObjRelInsertInput>;
  size?: Maybe<Scalars["bigint"]>;
  storage_key?: Maybe<Scalars["String"]>;
  thumbnail?: Maybe<ThumbnailProfilesObjRelInsertInput>;
  thumbnail_profiles?: Maybe<ThumbnailProfilesArrRelInsertInput>;
  transcoder_profiles?: Maybe<TranscoderProfilesArrRelInsertInput>;
  type?: Maybe<AssetFileTypesEnum>;
  video?: Maybe<TranscoderProfilesObjRelInsertInput>;
  warehouse_id?: Maybe<Scalars["uuid"]>;
}

/** aggregate max on columns */
export interface AssetFilesMaxFields {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  media_asset_id?: Maybe<Scalars["uuid"]>;
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  size?: Maybe<Scalars["bigint"]>;
  storage_key?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  warehouse_id?: Maybe<Scalars["uuid"]>;
}

/** order by max() on columns of table "asset_files" */
export interface AssetFilesMaxOrderBy {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  media_asset_id?: Maybe<OrderBy>;
  mime_type?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  size?: Maybe<OrderBy>;
  storage_key?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  warehouse_id?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface AssetFilesMinFields {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  media_asset_id?: Maybe<Scalars["uuid"]>;
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  size?: Maybe<Scalars["bigint"]>;
  storage_key?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  warehouse_id?: Maybe<Scalars["uuid"]>;
}

/** order by min() on columns of table "asset_files" */
export interface AssetFilesMinOrderBy {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  media_asset_id?: Maybe<OrderBy>;
  mime_type?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  size?: Maybe<OrderBy>;
  storage_key?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  warehouse_id?: Maybe<OrderBy>;
}

/** response of any mutation on the table "asset_files" */
export interface AssetFilesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<AssetFiles>;
}

/** input type for inserting object relation for remote table "asset_files" */
export interface AssetFilesObjRelInsertInput {
  data: AssetFilesInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<AssetFilesOnConflict>;
}

/** on_conflict condition type for table "asset_files" */
export interface AssetFilesOnConflict {
  constraint: AssetFilesConstraint;
  update_columns?: Array<AssetFilesUpdateColumn>;
  where?: Maybe<AssetFilesBoolExp>;
}

/** Ordering options when selecting data from "asset_files". */
export interface AssetFilesOrderBy {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  creator?: Maybe<UsersOrderBy>;
  id?: Maybe<OrderBy>;
  image_thumbnail_jobs_aggregate?: Maybe<ImageThumbnailJobsAggregateOrderBy>;
  image_thumbnails_aggregate?: Maybe<ImageThumbnailsAggregateOrderBy>;
  media_asset?: Maybe<MediaAssetsOrderBy>;
  media_asset_id?: Maybe<OrderBy>;
  mime_type?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationsOrderBy>;
  size?: Maybe<OrderBy>;
  storage_key?: Maybe<OrderBy>;
  thumbnail?: Maybe<ThumbnailProfilesOrderBy>;
  thumbnail_profiles_aggregate?: Maybe<ThumbnailProfilesAggregateOrderBy>;
  transcoder_profiles_aggregate?: Maybe<TranscoderProfilesAggregateOrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  video?: Maybe<TranscoderProfilesOrderBy>;
  warehouse_id?: Maybe<OrderBy>;
}

/** select columns of table "asset_files" */
export enum AssetFilesSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  created_by = "created_by",
  /** column name */
  id = "id",
  /** column name */
  media_asset_id = "media_asset_id",
  /** column name */
  mime_type = "mime_type",
  /** column name */
  name = "name",
  /** column name */
  org_id = "org_id",
  /** column name */
  size = "size",
  /** column name */
  storage_key = "storage_key",
  /** column name */
  type = "type",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  warehouse_id = "warehouse_id",
}

/** aggregate stddev on columns */
export interface AssetFilesStddevFields {
  size?: Maybe<Scalars["Float"]>;
}

/** order by stddev() on columns of table "asset_files" */
export interface AssetFilesStddevOrderBy {
  size?: Maybe<OrderBy>;
}

/** aggregate stddev_pop on columns */
export interface AssetFilesStddevPopFields {
  size?: Maybe<Scalars["Float"]>;
}

/** order by stddev_pop() on columns of table "asset_files" */
export interface AssetFilesStddevPopOrderBy {
  size?: Maybe<OrderBy>;
}

/** aggregate stddev_samp on columns */
export interface AssetFilesStddevSampFields {
  size?: Maybe<Scalars["Float"]>;
}

/** order by stddev_samp() on columns of table "asset_files" */
export interface AssetFilesStddevSampOrderBy {
  size?: Maybe<OrderBy>;
}

/** Streaming cursor of the table "asset_files" */
export interface AssetFilesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AssetFilesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AssetFilesStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  media_asset_id?: Maybe<Scalars["uuid"]>;
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  size?: Maybe<Scalars["bigint"]>;
  storage_key?: Maybe<Scalars["String"]>;
  type?: Maybe<AssetFileTypesEnum>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  warehouse_id?: Maybe<Scalars["uuid"]>;
}

/** aggregate sum on columns */
export interface AssetFilesSumFields {
  size?: Maybe<Scalars["bigint"]>;
}

/** order by sum() on columns of table "asset_files" */
export interface AssetFilesSumOrderBy {
  size?: Maybe<OrderBy>;
}

/** placeholder for update columns of table "asset_files" (current role has no relevant permissions) */
export enum AssetFilesUpdateColumn {
  /** placeholder (do not use) */
  _PLACEHOLDER = "_PLACEHOLDER",
}

/** aggregate var_pop on columns */
export interface AssetFilesVarPopFields {
  size?: Maybe<Scalars["Float"]>;
}

/** order by var_pop() on columns of table "asset_files" */
export interface AssetFilesVarPopOrderBy {
  size?: Maybe<OrderBy>;
}

/** aggregate var_samp on columns */
export interface AssetFilesVarSampFields {
  size?: Maybe<Scalars["Float"]>;
}

/** order by var_samp() on columns of table "asset_files" */
export interface AssetFilesVarSampOrderBy {
  size?: Maybe<OrderBy>;
}

/** aggregate variance on columns */
export interface AssetFilesVarianceFields {
  size?: Maybe<Scalars["Float"]>;
}

/** order by variance() on columns of table "asset_files" */
export interface AssetFilesVarianceOrderBy {
  size?: Maybe<OrderBy>;
}

/** columns and relationships of "asset_metadata_fields" */
export interface AssetMetadataFields {
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  options: Scalars["jsonb"];
  org_id: Scalars["uuid"];
  /** An object relationship */
  organization: Organizations;
  /** An array relationship */
  role_meta_scopes: Array<PermissionRoleMetaScopes>;
  /** An aggregate relationship */
  role_meta_scopes_aggregate: PermissionRoleMetaScopesAggregate;
  type: AssetMetadataTypesEnum;
  updated_at: Scalars["timestamptz"];
  /** An array relationship */
  values: Array<MetaFieldValues>;
}

/** columns and relationships of "asset_metadata_fields" */
export interface AssetMetadataFieldsOptionsArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "asset_metadata_fields" */
export interface AssetMetadataFieldsRoleMetaScopesArgs {
  distinct_on?: Maybe<Array<PermissionRoleMetaScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleMetaScopesOrderBy>>;
  where?: Maybe<PermissionRoleMetaScopesBoolExp>;
}

/** columns and relationships of "asset_metadata_fields" */
export interface AssetMetadataFieldsRoleMetaScopesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionRoleMetaScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleMetaScopesOrderBy>>;
  where?: Maybe<PermissionRoleMetaScopesBoolExp>;
}

/** columns and relationships of "asset_metadata_fields" */
export interface AssetMetadataFieldsValuesArgs {
  distinct_on?: Maybe<Array<MetaFieldValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaFieldValuesOrderBy>>;
  where?: Maybe<MetaFieldValuesBoolExp>;
}

/** order by aggregate values of table "asset_metadata_fields" */
export interface AssetMetadataFieldsAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<AssetMetadataFieldsMaxOrderBy>;
  min?: Maybe<AssetMetadataFieldsMinOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface AssetMetadataFieldsAppendInput {
  options?: Maybe<Scalars["jsonb"]>;
}

/** input type for inserting array relation for remote table "asset_metadata_fields" */
export interface AssetMetadataFieldsArrRelInsertInput {
  data: Array<AssetMetadataFieldsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<AssetMetadataFieldsOnConflict>;
}

/** Boolean expression to filter rows from the table "asset_metadata_fields". All fields are combined with a logical 'AND'. */
export interface AssetMetadataFieldsBoolExp {
  _and?: Maybe<Array<AssetMetadataFieldsBoolExp>>;
  _not?: Maybe<AssetMetadataFieldsBoolExp>;
  _or?: Maybe<Array<AssetMetadataFieldsBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  options?: Maybe<JsonbComparisonExp>;
  org_id?: Maybe<UuidComparisonExp>;
  organization?: Maybe<OrganizationsBoolExp>;
  role_meta_scopes?: Maybe<PermissionRoleMetaScopesBoolExp>;
  role_meta_scopes_aggregate?: Maybe<PermissionRoleMetaScopesAggregateBoolExp>;
  type?: Maybe<AssetMetadataTypesEnumComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  values?: Maybe<MetaFieldValuesBoolExp>;
}

/** unique or primary key constraints on table "asset_metadata_fields" */
export enum AssetMetadataFieldsConstraint {
  /** unique or primary key constraint on columns "id" */
  asset_metadata_fields_pkey = "asset_metadata_fields_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface AssetMetadataFieldsDeleteAtPathInput {
  options?: Maybe<Array<Scalars["String"]>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface AssetMetadataFieldsDeleteElemInput {
  options?: Maybe<Scalars["Int"]>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface AssetMetadataFieldsDeleteKeyInput {
  options?: Maybe<Scalars["String"]>;
}

/** input type for inserting data into table "asset_metadata_fields" */
export interface AssetMetadataFieldsInsertInput {
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  options?: Maybe<Scalars["jsonb"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  organization?: Maybe<OrganizationsObjRelInsertInput>;
  role_meta_scopes?: Maybe<PermissionRoleMetaScopesArrRelInsertInput>;
  type?: Maybe<AssetMetadataTypesEnum>;
  values?: Maybe<MetaFieldValuesArrRelInsertInput>;
}

/** order by max() on columns of table "asset_metadata_fields" */
export interface AssetMetadataFieldsMaxOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** order by min() on columns of table "asset_metadata_fields" */
export interface AssetMetadataFieldsMinOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** response of any mutation on the table "asset_metadata_fields" */
export interface AssetMetadataFieldsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<AssetMetadataFields>;
}

/** input type for inserting object relation for remote table "asset_metadata_fields" */
export interface AssetMetadataFieldsObjRelInsertInput {
  data: AssetMetadataFieldsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<AssetMetadataFieldsOnConflict>;
}

/** on_conflict condition type for table "asset_metadata_fields" */
export interface AssetMetadataFieldsOnConflict {
  constraint: AssetMetadataFieldsConstraint;
  update_columns?: Array<AssetMetadataFieldsUpdateColumn>;
  where?: Maybe<AssetMetadataFieldsBoolExp>;
}

/** Ordering options when selecting data from "asset_metadata_fields". */
export interface AssetMetadataFieldsOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  options?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationsOrderBy>;
  role_meta_scopes_aggregate?: Maybe<PermissionRoleMetaScopesAggregateOrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  values_aggregate?: Maybe<MetaFieldValuesAggregateOrderBy>;
}

/** primary key columns input for table: asset_metadata_fields */
export interface AssetMetadataFieldsPkColumnsInput {
  id: Scalars["uuid"];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface AssetMetadataFieldsPrependInput {
  options?: Maybe<Scalars["jsonb"]>;
}

/** select columns of table "asset_metadata_fields" */
export enum AssetMetadataFieldsSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  options = "options",
  /** column name */
  org_id = "org_id",
  /** column name */
  type = "type",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "asset_metadata_fields" */
export interface AssetMetadataFieldsSetInput {
  name?: Maybe<Scalars["String"]>;
  options?: Maybe<Scalars["jsonb"]>;
  type?: Maybe<AssetMetadataTypesEnum>;
}

/** Streaming cursor of the table "asset_metadata_fields" */
export interface AssetMetadataFieldsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AssetMetadataFieldsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AssetMetadataFieldsStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  options?: Maybe<Scalars["jsonb"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<AssetMetadataTypesEnum>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** update columns of table "asset_metadata_fields" */
export enum AssetMetadataFieldsUpdateColumn {
  /** column name */
  name = "name",
  /** column name */
  options = "options",
  /** column name */
  type = "type",
}

export interface AssetMetadataFieldsUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<AssetMetadataFieldsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<AssetMetadataFieldsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<AssetMetadataFieldsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<AssetMetadataFieldsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<AssetMetadataFieldsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<AssetMetadataFieldsSetInput>;
  /** filter the rows which have to be updated */
  where: AssetMetadataFieldsBoolExp;
}

/** columns and relationships of "asset_metadata_types" */
export interface AssetMetadataTypes {
  type: Scalars["String"];
}

/** Boolean expression to filter rows from the table "asset_metadata_types". All fields are combined with a logical 'AND'. */
export interface AssetMetadataTypesBoolExp {
  _and?: Maybe<Array<AssetMetadataTypesBoolExp>>;
  _not?: Maybe<AssetMetadataTypesBoolExp>;
  _or?: Maybe<Array<AssetMetadataTypesBoolExp>>;
  type?: Maybe<StringComparisonExp>;
}

export enum AssetMetadataTypesEnum {
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  NUMBER = "NUMBER",
  SIMPLE_TEXT = "SIMPLE_TEXT",
  SINGLE_CHOICE = "SINGLE_CHOICE",
  TAG_LIST = "TAG_LIST",
}

/** Boolean expression to compare columns of type "asset_metadata_types_enum". All fields are combined with logical 'AND'. */
export interface AssetMetadataTypesEnumComparisonExp {
  _eq?: Maybe<AssetMetadataTypesEnum>;
  _in?: Maybe<Array<AssetMetadataTypesEnum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<AssetMetadataTypesEnum>;
  _nin?: Maybe<Array<AssetMetadataTypesEnum>>;
}

/** Ordering options when selecting data from "asset_metadata_types". */
export interface AssetMetadataTypesOrderBy {
  type?: Maybe<OrderBy>;
}

/** select columns of table "asset_metadata_types" */
export enum AssetMetadataTypesSelectColumn {
  /** column name */
  type = "type",
}

/** Streaming cursor of the table "asset_metadata_types" */
export interface AssetMetadataTypesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AssetMetadataTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AssetMetadataTypesStreamCursorValueInput {
  type?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "asset_metadata_values" */
export interface AssetMetadataValues {
  /** An object relationship */
  asset: MediaAssets;
  asset_id: Scalars["uuid"];
  boolean_value?: Maybe<Scalars["Boolean"]>;
  date_value?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  field: AssetMetadataFields;
  field_id: Scalars["uuid"];
  id: Scalars["uuid"];
  number_value?: Maybe<Scalars["float8"]>;
  text_value?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
}

/** order by aggregate values of table "asset_metadata_values" */
export interface AssetMetadataValuesAggregateOrderBy {
  avg?: Maybe<AssetMetadataValuesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AssetMetadataValuesMaxOrderBy>;
  min?: Maybe<AssetMetadataValuesMinOrderBy>;
  stddev?: Maybe<AssetMetadataValuesStddevOrderBy>;
  stddev_pop?: Maybe<AssetMetadataValuesStddevPopOrderBy>;
  stddev_samp?: Maybe<AssetMetadataValuesStddevSampOrderBy>;
  sum?: Maybe<AssetMetadataValuesSumOrderBy>;
  var_pop?: Maybe<AssetMetadataValuesVarPopOrderBy>;
  var_samp?: Maybe<AssetMetadataValuesVarSampOrderBy>;
  variance?: Maybe<AssetMetadataValuesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "asset_metadata_values" */
export interface AssetMetadataValuesArrRelInsertInput {
  data: Array<AssetMetadataValuesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<AssetMetadataValuesOnConflict>;
}

/** order by avg() on columns of table "asset_metadata_values" */
export interface AssetMetadataValuesAvgOrderBy {
  number_value?: Maybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "asset_metadata_values". All fields are combined with a logical 'AND'. */
export interface AssetMetadataValuesBoolExp {
  _and?: Maybe<Array<AssetMetadataValuesBoolExp>>;
  _not?: Maybe<AssetMetadataValuesBoolExp>;
  _or?: Maybe<Array<AssetMetadataValuesBoolExp>>;
  asset?: Maybe<MediaAssetsBoolExp>;
  asset_id?: Maybe<UuidComparisonExp>;
  boolean_value?: Maybe<BooleanComparisonExp>;
  date_value?: Maybe<TimestamptzComparisonExp>;
  field?: Maybe<AssetMetadataFieldsBoolExp>;
  field_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  number_value?: Maybe<Float8ComparisonExp>;
  text_value?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "asset_metadata_values" */
export enum AssetMetadataValuesConstraint {
  /** unique or primary key constraint on columns "id" */
  asset_metadata_values_pkey = "asset_metadata_values_pkey",
}

/** input type for incrementing numeric columns in table "asset_metadata_values" */
export interface AssetMetadataValuesIncInput {
  number_value?: Maybe<Scalars["float8"]>;
}

/** input type for inserting data into table "asset_metadata_values" */
export interface AssetMetadataValuesInsertInput {
  asset?: Maybe<MediaAssetsObjRelInsertInput>;
  asset_id?: Maybe<Scalars["uuid"]>;
  boolean_value?: Maybe<Scalars["Boolean"]>;
  date_value?: Maybe<Scalars["timestamptz"]>;
  field?: Maybe<AssetMetadataFieldsObjRelInsertInput>;
  field_id?: Maybe<Scalars["uuid"]>;
  number_value?: Maybe<Scalars["float8"]>;
  text_value?: Maybe<Scalars["String"]>;
}

/** order by max() on columns of table "asset_metadata_values" */
export interface AssetMetadataValuesMaxOrderBy {
  asset_id?: Maybe<OrderBy>;
  date_value?: Maybe<OrderBy>;
  field_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  number_value?: Maybe<OrderBy>;
  text_value?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** order by min() on columns of table "asset_metadata_values" */
export interface AssetMetadataValuesMinOrderBy {
  asset_id?: Maybe<OrderBy>;
  date_value?: Maybe<OrderBy>;
  field_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  number_value?: Maybe<OrderBy>;
  text_value?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** response of any mutation on the table "asset_metadata_values" */
export interface AssetMetadataValuesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<AssetMetadataValues>;
}

/** on_conflict condition type for table "asset_metadata_values" */
export interface AssetMetadataValuesOnConflict {
  constraint: AssetMetadataValuesConstraint;
  update_columns?: Array<AssetMetadataValuesUpdateColumn>;
  where?: Maybe<AssetMetadataValuesBoolExp>;
}

/** Ordering options when selecting data from "asset_metadata_values". */
export interface AssetMetadataValuesOrderBy {
  asset?: Maybe<MediaAssetsOrderBy>;
  asset_id?: Maybe<OrderBy>;
  boolean_value?: Maybe<OrderBy>;
  date_value?: Maybe<OrderBy>;
  field?: Maybe<AssetMetadataFieldsOrderBy>;
  field_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  number_value?: Maybe<OrderBy>;
  text_value?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** primary key columns input for table: asset_metadata_values */
export interface AssetMetadataValuesPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "asset_metadata_values" */
export enum AssetMetadataValuesSelectColumn {
  /** column name */
  asset_id = "asset_id",
  /** column name */
  boolean_value = "boolean_value",
  /** column name */
  date_value = "date_value",
  /** column name */
  field_id = "field_id",
  /** column name */
  id = "id",
  /** column name */
  number_value = "number_value",
  /** column name */
  text_value = "text_value",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "asset_metadata_values" */
export interface AssetMetadataValuesSetInput {
  boolean_value?: Maybe<Scalars["Boolean"]>;
  date_value?: Maybe<Scalars["timestamptz"]>;
  number_value?: Maybe<Scalars["float8"]>;
  text_value?: Maybe<Scalars["String"]>;
}

/** order by stddev() on columns of table "asset_metadata_values" */
export interface AssetMetadataValuesStddevOrderBy {
  number_value?: Maybe<OrderBy>;
}

/** order by stddev_pop() on columns of table "asset_metadata_values" */
export interface AssetMetadataValuesStddevPopOrderBy {
  number_value?: Maybe<OrderBy>;
}

/** order by stddev_samp() on columns of table "asset_metadata_values" */
export interface AssetMetadataValuesStddevSampOrderBy {
  number_value?: Maybe<OrderBy>;
}

/** Streaming cursor of the table "asset_metadata_values" */
export interface AssetMetadataValuesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: AssetMetadataValuesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface AssetMetadataValuesStreamCursorValueInput {
  asset_id?: Maybe<Scalars["uuid"]>;
  boolean_value?: Maybe<Scalars["Boolean"]>;
  date_value?: Maybe<Scalars["timestamptz"]>;
  field_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  number_value?: Maybe<Scalars["float8"]>;
  text_value?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** order by sum() on columns of table "asset_metadata_values" */
export interface AssetMetadataValuesSumOrderBy {
  number_value?: Maybe<OrderBy>;
}

/** update columns of table "asset_metadata_values" */
export enum AssetMetadataValuesUpdateColumn {
  /** column name */
  boolean_value = "boolean_value",
  /** column name */
  date_value = "date_value",
  /** column name */
  number_value = "number_value",
  /** column name */
  text_value = "text_value",
}

export interface AssetMetadataValuesUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<AssetMetadataValuesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<AssetMetadataValuesSetInput>;
  /** filter the rows which have to be updated */
  where: AssetMetadataValuesBoolExp;
}

/** order by var_pop() on columns of table "asset_metadata_values" */
export interface AssetMetadataValuesVarPopOrderBy {
  number_value?: Maybe<OrderBy>;
}

/** order by var_samp() on columns of table "asset_metadata_values" */
export interface AssetMetadataValuesVarSampOrderBy {
  number_value?: Maybe<OrderBy>;
}

/** order by variance() on columns of table "asset_metadata_values" */
export interface AssetMetadataValuesVarianceOrderBy {
  number_value?: Maybe<OrderBy>;
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export interface BigintComparisonExp {
  _eq?: Maybe<Scalars["bigint"]>;
  _gt?: Maybe<Scalars["bigint"]>;
  _gte?: Maybe<Scalars["bigint"]>;
  _in?: Maybe<Array<Scalars["bigint"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["bigint"]>;
  _lte?: Maybe<Scalars["bigint"]>;
  _neq?: Maybe<Scalars["bigint"]>;
  _nin?: Maybe<Array<Scalars["bigint"]>>;
}

/** columns and relationships of "cms.content_rows" */
export interface CmsContentRows {
  /** An array relationship */
  access_codes: Array<TicketingAccessCodes>;
  created_at: Scalars["timestamptz"];
  default_locale?: Maybe<CmsLocalesEnum>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  is_access_restricted: Scalars["Boolean"];
  locale_data?: Maybe<Scalars["jsonb"]>;
  /** An object relationship */
  project: Projects;
  project_id: Scalars["uuid"];
  revision?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  type: Scalars["String"];
  version: Scalars["String"];
}

/** columns and relationships of "cms.content_rows" */
export interface CmsContentRowsAccessCodesArgs {
  distinct_on?: Maybe<Array<TicketingAccessCodesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TicketingAccessCodesOrderBy>>;
  where?: Maybe<TicketingAccessCodesBoolExp>;
}

/** columns and relationships of "cms.content_rows" */
export interface CmsContentRowsLocaleDataArgs {
  path?: Maybe<Scalars["String"]>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface CmsContentRowsAppendInput {
  locale_data?: Maybe<Scalars["jsonb"]>;
}

/** Boolean expression to filter rows from the table "cms.content_rows". All fields are combined with a logical 'AND'. */
export interface CmsContentRowsBoolExp {
  _and?: Maybe<Array<CmsContentRowsBoolExp>>;
  _not?: Maybe<CmsContentRowsBoolExp>;
  _or?: Maybe<Array<CmsContentRowsBoolExp>>;
  access_codes?: Maybe<TicketingAccessCodesBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  default_locale?: Maybe<CmsLocalesEnumComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_access_restricted?: Maybe<BooleanComparisonExp>;
  locale_data?: Maybe<JsonbComparisonExp>;
  project?: Maybe<ProjectsBoolExp>;
  project_id?: Maybe<UuidComparisonExp>;
  revision?: Maybe<StringComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  version?: Maybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "cms.content_rows" */
export enum CmsContentRowsConstraint {
  /** unique or primary key constraint on columns "id" */
  content_rows_pkey = "content_rows_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface CmsContentRowsDeleteAtPathInput {
  locale_data?: Maybe<Array<Scalars["String"]>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface CmsContentRowsDeleteElemInput {
  locale_data?: Maybe<Scalars["Int"]>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface CmsContentRowsDeleteKeyInput {
  locale_data?: Maybe<Scalars["String"]>;
}

/** input type for inserting data into table "cms.content_rows" */
export interface CmsContentRowsInsertInput {
  access_codes?: Maybe<TicketingAccessCodesArrRelInsertInput>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  default_locale?: Maybe<CmsLocalesEnum>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_access_restricted?: Maybe<Scalars["Boolean"]>;
  locale_data?: Maybe<Scalars["jsonb"]>;
  project?: Maybe<ProjectsObjRelInsertInput>;
  project_id?: Maybe<Scalars["uuid"]>;
  revision?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
}

/** response of any mutation on the table "cms.content_rows" */
export interface CmsContentRowsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CmsContentRows>;
}

/** input type for inserting object relation for remote table "cms.content_rows" */
export interface CmsContentRowsObjRelInsertInput {
  data: CmsContentRowsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<CmsContentRowsOnConflict>;
}

/** on_conflict condition type for table "cms.content_rows" */
export interface CmsContentRowsOnConflict {
  constraint: CmsContentRowsConstraint;
  update_columns?: Array<CmsContentRowsUpdateColumn>;
  where?: Maybe<CmsContentRowsBoolExp>;
}

/** Ordering options when selecting data from "cms.content_rows". */
export interface CmsContentRowsOrderBy {
  access_codes_aggregate?: Maybe<TicketingAccessCodesAggregateOrderBy>;
  created_at?: Maybe<OrderBy>;
  default_locale?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_access_restricted?: Maybe<OrderBy>;
  locale_data?: Maybe<OrderBy>;
  project?: Maybe<ProjectsOrderBy>;
  project_id?: Maybe<OrderBy>;
  revision?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
}

/** primary key columns input for table: cms.content_rows */
export interface CmsContentRowsPkColumnsInput {
  id: Scalars["uuid"];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface CmsContentRowsPrependInput {
  locale_data?: Maybe<Scalars["jsonb"]>;
}

/** select columns of table "cms.content_rows" */
export enum CmsContentRowsSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  default_locale = "default_locale",
  /** column name */
  deleted_at = "deleted_at",
  /** column name */
  id = "id",
  /** column name */
  is_access_restricted = "is_access_restricted",
  /** column name */
  locale_data = "locale_data",
  /** column name */
  project_id = "project_id",
  /** column name */
  revision = "revision",
  /** column name */
  status = "status",
  /** column name */
  type = "type",
  /** column name */
  version = "version",
}

/** input type for updating data in table "cms.content_rows" */
export interface CmsContentRowsSetInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  default_locale?: Maybe<CmsLocalesEnum>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_access_restricted?: Maybe<Scalars["Boolean"]>;
  locale_data?: Maybe<Scalars["jsonb"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  revision?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
}

/** Streaming cursor of the table "cms_content_rows" */
export interface CmsContentRowsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CmsContentRowsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CmsContentRowsStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  default_locale?: Maybe<CmsLocalesEnum>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_access_restricted?: Maybe<Scalars["Boolean"]>;
  locale_data?: Maybe<Scalars["jsonb"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  revision?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
}

/** update columns of table "cms.content_rows" */
export enum CmsContentRowsUpdateColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  default_locale = "default_locale",
  /** column name */
  deleted_at = "deleted_at",
  /** column name */
  id = "id",
  /** column name */
  is_access_restricted = "is_access_restricted",
  /** column name */
  locale_data = "locale_data",
  /** column name */
  project_id = "project_id",
  /** column name */
  revision = "revision",
  /** column name */
  status = "status",
  /** column name */
  type = "type",
  /** column name */
  version = "version",
}

export interface CmsContentRowsUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<CmsContentRowsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<CmsContentRowsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<CmsContentRowsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<CmsContentRowsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<CmsContentRowsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CmsContentRowsSetInput>;
  /** filter the rows which have to be updated */
  where: CmsContentRowsBoolExp;
}

/** columns and relationships of "cms.content_schemas" */
export interface CmsContentSchemas {
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  /** An object relationship */
  project: Projects;
  project_id: Scalars["uuid"];
  schema_sdl: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  version: Scalars["Int"];
}

/** Boolean expression to filter rows from the table "cms.content_schemas". All fields are combined with a logical 'AND'. */
export interface CmsContentSchemasBoolExp {
  _and?: Maybe<Array<CmsContentSchemasBoolExp>>;
  _not?: Maybe<CmsContentSchemasBoolExp>;
  _or?: Maybe<Array<CmsContentSchemasBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  project?: Maybe<ProjectsBoolExp>;
  project_id?: Maybe<UuidComparisonExp>;
  schema_sdl?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  version?: Maybe<IntComparisonExp>;
}

/** unique or primary key constraints on table "cms.content_schemas" */
export enum CmsContentSchemasConstraint {
  /** unique or primary key constraint on columns "id" */
  content_schemas_pkey = "content_schemas_pkey",
  /** unique or primary key constraint on columns "project_id", "version" */
  content_schemas_project_id_version_key = "content_schemas_project_id_version_key",
}

/** input type for inserting data into table "cms.content_schemas" */
export interface CmsContentSchemasInsertInput {
  project?: Maybe<ProjectsObjRelInsertInput>;
  project_id?: Maybe<Scalars["uuid"]>;
  schema_sdl?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["Int"]>;
}

/** response of any mutation on the table "cms.content_schemas" */
export interface CmsContentSchemasMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CmsContentSchemas>;
}

/** on_conflict condition type for table "cms.content_schemas" */
export interface CmsContentSchemasOnConflict {
  constraint: CmsContentSchemasConstraint;
  update_columns?: Array<CmsContentSchemasUpdateColumn>;
  where?: Maybe<CmsContentSchemasBoolExp>;
}

/** Ordering options when selecting data from "cms.content_schemas". */
export interface CmsContentSchemasOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  project?: Maybe<ProjectsOrderBy>;
  project_id?: Maybe<OrderBy>;
  schema_sdl?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
}

/** select columns of table "cms.content_schemas" */
export enum CmsContentSchemasSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  project_id = "project_id",
  /** column name */
  schema_sdl = "schema_sdl",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  version = "version",
}

/** Streaming cursor of the table "cms_content_schemas" */
export interface CmsContentSchemasStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CmsContentSchemasStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CmsContentSchemasStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  schema_sdl?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  version?: Maybe<Scalars["Int"]>;
}

/** placeholder for update columns of table "cms.content_schemas" (current role has no relevant permissions) */
export enum CmsContentSchemasUpdateColumn {
  /** placeholder (do not use) */
  _PLACEHOLDER = "_PLACEHOLDER",
}

export enum CmsLocalesEnum {
  EN_US = "EN_US",
  ET = "ET",
  LT = "LT",
  LV = "LV",
  NO_LOCALE = "NO_LOCALE",
}

/** Boolean expression to compare columns of type "cms_locales_enum". All fields are combined with logical 'AND'. */
export interface CmsLocalesEnumComparisonExp {
  _eq?: Maybe<CmsLocalesEnum>;
  _in?: Maybe<Array<CmsLocalesEnum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<CmsLocalesEnum>;
  _nin?: Maybe<Array<CmsLocalesEnum>>;
}

/** Boolean expression to filter rows from the table "cms.project_publications". All fields are combined with a logical 'AND'. */
export interface CmsProjectPublicationsBoolExp {
  _and?: Maybe<Array<CmsProjectPublicationsBoolExp>>;
  _not?: Maybe<CmsProjectPublicationsBoolExp>;
  _or?: Maybe<Array<CmsProjectPublicationsBoolExp>>;
}

/** unique or primary key constraints on table "cms.project_publications" */
export enum CmsProjectPublicationsConstraint {
  /** unique or primary key constraint on columns "id" */
  project_publications_pkey = "project_publications_pkey",
}

/** input type for inserting data into table "cms.project_publications" */
export interface CmsProjectPublicationsInsertInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  data?: Maybe<Scalars["jsonb"]>;
  id?: Maybe<Scalars["uuid"]>;
  project?: Maybe<CmsProjectsObjRelInsertInput>;
  projectId?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  resource?: Maybe<CmsResourcesObjRelInsertInput>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** response of any mutation on the table "cms.project_publications" */
export interface CmsProjectPublicationsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
}

/** on_conflict condition type for table "cms.project_publications" */
export interface CmsProjectPublicationsOnConflict {
  constraint: CmsProjectPublicationsConstraint;
  update_columns?: Array<CmsProjectPublicationsUpdateColumn>;
  where?: Maybe<CmsProjectPublicationsBoolExp>;
}

/** placeholder for update columns of table "cms.project_publications" (current role has no relevant permissions) */
export enum CmsProjectPublicationsUpdateColumn {
  /** placeholder (do not use) */
  _PLACEHOLDER = "_PLACEHOLDER",
}

/** columns and relationships of "cms.projects" */
export interface CmsProjects {
  created_at: Scalars["timestamptz"];
  created_by?: Maybe<Scalars["uuid"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  org: Organizations;
  org_id: Scalars["uuid"];
  proto?: Maybe<Scalars["jsonb"]>;
  /** An object relationship */
  resource?: Maybe<CmsResources>;
  updated_at: Scalars["timestamptz"];
}

/** columns and relationships of "cms.projects" */
export interface CmsProjectsProtoArgs {
  path?: Maybe<Scalars["String"]>;
}

/** Boolean expression to filter rows from the table "cms.projects". All fields are combined with a logical 'AND'. */
export interface CmsProjectsBoolExp {
  _and?: Maybe<Array<CmsProjectsBoolExp>>;
  _not?: Maybe<CmsProjectsBoolExp>;
  _or?: Maybe<Array<CmsProjectsBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  org?: Maybe<OrganizationsBoolExp>;
  org_id?: Maybe<UuidComparisonExp>;
  proto?: Maybe<JsonbComparisonExp>;
  resource?: Maybe<CmsResourcesBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "cms.projects" */
export enum CmsProjectsConstraint {
  /** unique or primary key constraint on columns "id" */
  projects_pkey = "projects_pkey",
}

/** input type for inserting data into table "cms.projects" */
export interface CmsProjectsInsertInput {
  id?: Maybe<Scalars["uuid"]>;
  org?: Maybe<OrganizationsObjRelInsertInput>;
  org_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  resource?: Maybe<CmsResourcesObjRelInsertInput>;
}

/** response of any mutation on the table "cms.projects" */
export interface CmsProjectsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CmsProjects>;
}

/** input type for inserting object relation for remote table "cms.projects" */
export interface CmsProjectsObjRelInsertInput {
  data: CmsProjectsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<CmsProjectsOnConflict>;
}

/** on_conflict condition type for table "cms.projects" */
export interface CmsProjectsOnConflict {
  constraint: CmsProjectsConstraint;
  update_columns?: Array<CmsProjectsUpdateColumn>;
  where?: Maybe<CmsProjectsBoolExp>;
}

/** Ordering options when selecting data from "cms.projects". */
export interface CmsProjectsOrderBy {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  org?: Maybe<OrganizationsOrderBy>;
  org_id?: Maybe<OrderBy>;
  proto?: Maybe<OrderBy>;
  resource?: Maybe<CmsResourcesOrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** select columns of table "cms.projects" */
export enum CmsProjectsSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  created_by = "created_by",
  /** column name */
  id = "id",
  /** column name */
  org_id = "org_id",
  /** column name */
  proto = "proto",
  /** column name */
  updated_at = "updated_at",
}

/** Streaming cursor of the table "cms_projects" */
export interface CmsProjectsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CmsProjectsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CmsProjectsStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** placeholder for update columns of table "cms.projects" (current role has no relevant permissions) */
export enum CmsProjectsUpdateColumn {
  /** placeholder (do not use) */
  _PLACEHOLDER = "_PLACEHOLDER",
}

/** columns and relationships of "cms.publications" */
export interface CmsPublications {
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  /** An object relationship */
  project: Projects;
  project_id: Scalars["uuid"];
  published_data: Scalars["jsonb"];
  publishing_webhook_url: Scalars["String"];
  schema_sdl: Scalars["String"];
  status: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  version: Scalars["String"];
}

/** columns and relationships of "cms.publications" */
export interface CmsPublicationsPublishedDataArgs {
  path?: Maybe<Scalars["String"]>;
}

/** order by aggregate values of table "cms.publications" */
export interface CmsPublicationsAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<CmsPublicationsMaxOrderBy>;
  min?: Maybe<CmsPublicationsMinOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface CmsPublicationsAppendInput {
  published_data?: Maybe<Scalars["jsonb"]>;
}

/** input type for inserting array relation for remote table "cms.publications" */
export interface CmsPublicationsArrRelInsertInput {
  data: Array<CmsPublicationsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<CmsPublicationsOnConflict>;
}

/** Boolean expression to filter rows from the table "cms.publications". All fields are combined with a logical 'AND'. */
export interface CmsPublicationsBoolExp {
  _and?: Maybe<Array<CmsPublicationsBoolExp>>;
  _not?: Maybe<CmsPublicationsBoolExp>;
  _or?: Maybe<Array<CmsPublicationsBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  project?: Maybe<ProjectsBoolExp>;
  project_id?: Maybe<UuidComparisonExp>;
  published_data?: Maybe<JsonbComparisonExp>;
  publishing_webhook_url?: Maybe<StringComparisonExp>;
  schema_sdl?: Maybe<StringComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  version?: Maybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "cms.publications" */
export enum CmsPublicationsConstraint {
  /** unique or primary key constraint on columns "id" */
  publications_pkey = "publications_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface CmsPublicationsDeleteAtPathInput {
  published_data?: Maybe<Array<Scalars["String"]>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface CmsPublicationsDeleteElemInput {
  published_data?: Maybe<Scalars["Int"]>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface CmsPublicationsDeleteKeyInput {
  published_data?: Maybe<Scalars["String"]>;
}

/** input type for inserting data into table "cms.publications" */
export interface CmsPublicationsInsertInput {
  project?: Maybe<ProjectsObjRelInsertInput>;
  project_id?: Maybe<Scalars["uuid"]>;
  published_data?: Maybe<Scalars["jsonb"]>;
  publishing_webhook_url?: Maybe<Scalars["String"]>;
  schema_sdl?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
}

/** order by max() on columns of table "cms.publications" */
export interface CmsPublicationsMaxOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  project_id?: Maybe<OrderBy>;
  publishing_webhook_url?: Maybe<OrderBy>;
  schema_sdl?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
}

/** order by min() on columns of table "cms.publications" */
export interface CmsPublicationsMinOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  project_id?: Maybe<OrderBy>;
  publishing_webhook_url?: Maybe<OrderBy>;
  schema_sdl?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
}

/** response of any mutation on the table "cms.publications" */
export interface CmsPublicationsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CmsPublications>;
}

/** on_conflict condition type for table "cms.publications" */
export interface CmsPublicationsOnConflict {
  constraint: CmsPublicationsConstraint;
  update_columns?: Array<CmsPublicationsUpdateColumn>;
  where?: Maybe<CmsPublicationsBoolExp>;
}

/** Ordering options when selecting data from "cms.publications". */
export interface CmsPublicationsOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  project?: Maybe<ProjectsOrderBy>;
  project_id?: Maybe<OrderBy>;
  published_data?: Maybe<OrderBy>;
  publishing_webhook_url?: Maybe<OrderBy>;
  schema_sdl?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
}

/** primary key columns input for table: cms.publications */
export interface CmsPublicationsPkColumnsInput {
  id: Scalars["uuid"];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface CmsPublicationsPrependInput {
  published_data?: Maybe<Scalars["jsonb"]>;
}

/** select columns of table "cms.publications" */
export enum CmsPublicationsSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  project_id = "project_id",
  /** column name */
  published_data = "published_data",
  /** column name */
  publishing_webhook_url = "publishing_webhook_url",
  /** column name */
  schema_sdl = "schema_sdl",
  /** column name */
  status = "status",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  version = "version",
}

/** input type for updating data in table "cms.publications" */
export interface CmsPublicationsSetInput {
  project_id?: Maybe<Scalars["uuid"]>;
  published_data?: Maybe<Scalars["jsonb"]>;
  publishing_webhook_url?: Maybe<Scalars["String"]>;
  schema_sdl?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
}

/** Streaming cursor of the table "cms_publications" */
export interface CmsPublicationsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CmsPublicationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CmsPublicationsStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  published_data?: Maybe<Scalars["jsonb"]>;
  publishing_webhook_url?: Maybe<Scalars["String"]>;
  schema_sdl?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  version?: Maybe<Scalars["String"]>;
}

/** update columns of table "cms.publications" */
export enum CmsPublicationsUpdateColumn {
  /** column name */
  project_id = "project_id",
  /** column name */
  published_data = "published_data",
  /** column name */
  publishing_webhook_url = "publishing_webhook_url",
  /** column name */
  schema_sdl = "schema_sdl",
  /** column name */
  version = "version",
}

export interface CmsPublicationsUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<CmsPublicationsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<CmsPublicationsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<CmsPublicationsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<CmsPublicationsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<CmsPublicationsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CmsPublicationsSetInput>;
  /** filter the rows which have to be updated */
  where: CmsPublicationsBoolExp;
}

/** columns and relationships of "cms.resources" */
export interface CmsResources {
  created_at: Scalars["timestamptz"];
  created_by?: Maybe<Scalars["uuid"]>;
  data: Scalars["jsonb"];
  id: Scalars["uuid"];
  /** An object relationship */
  project: CmsProjects;
  project_id: Scalars["uuid"];
  proto?: Maybe<Scalars["jsonb"]>;
  updated_at: Scalars["timestamptz"];
  updated_by?: Maybe<Scalars["uuid"]>;
}

/** columns and relationships of "cms.resources" */
export interface CmsResourcesDataArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "cms.resources" */
export interface CmsResourcesProtoArgs {
  path?: Maybe<Scalars["String"]>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface CmsResourcesAppendInput {
  data?: Maybe<Scalars["jsonb"]>;
  proto?: Maybe<Scalars["jsonb"]>;
}

/** Boolean expression to filter rows from the table "cms.resources". All fields are combined with a logical 'AND'. */
export interface CmsResourcesBoolExp {
  _and?: Maybe<Array<CmsResourcesBoolExp>>;
  _not?: Maybe<CmsResourcesBoolExp>;
  _or?: Maybe<Array<CmsResourcesBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  data?: Maybe<JsonbComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  project?: Maybe<CmsProjectsBoolExp>;
  project_id?: Maybe<UuidComparisonExp>;
  proto?: Maybe<JsonbComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  updated_by?: Maybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "cms.resources" */
export enum CmsResourcesConstraint {
  /** unique or primary key constraint on columns "id" */
  resources_pkey = "resources_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface CmsResourcesDeleteAtPathInput {
  data?: Maybe<Array<Scalars["String"]>>;
  proto?: Maybe<Array<Scalars["String"]>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface CmsResourcesDeleteElemInput {
  data?: Maybe<Scalars["Int"]>;
  proto?: Maybe<Scalars["Int"]>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface CmsResourcesDeleteKeyInput {
  data?: Maybe<Scalars["String"]>;
  proto?: Maybe<Scalars["String"]>;
}

/** input type for inserting data into table "cms.resources" */
export interface CmsResourcesInsertInput {
  data?: Maybe<Scalars["jsonb"]>;
  id?: Maybe<Scalars["uuid"]>;
  project?: Maybe<CmsProjectsObjRelInsertInput>;
  project_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
}

/** response of any mutation on the table "cms.resources" */
export interface CmsResourcesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<CmsResources>;
}

/** input type for inserting object relation for remote table "cms.resources" */
export interface CmsResourcesObjRelInsertInput {
  data: CmsResourcesInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<CmsResourcesOnConflict>;
}

/** on_conflict condition type for table "cms.resources" */
export interface CmsResourcesOnConflict {
  constraint: CmsResourcesConstraint;
  update_columns?: Array<CmsResourcesUpdateColumn>;
  where?: Maybe<CmsResourcesBoolExp>;
}

/** Ordering options when selecting data from "cms.resources". */
export interface CmsResourcesOrderBy {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  data?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  project?: Maybe<CmsProjectsOrderBy>;
  project_id?: Maybe<OrderBy>;
  proto?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  updated_by?: Maybe<OrderBy>;
}

/** primary key columns input for table: cms.resources */
export interface CmsResourcesPkColumnsInput {
  id: Scalars["uuid"];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface CmsResourcesPrependInput {
  data?: Maybe<Scalars["jsonb"]>;
  proto?: Maybe<Scalars["jsonb"]>;
}

/** select columns of table "cms.resources" */
export enum CmsResourcesSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  created_by = "created_by",
  /** column name */
  data = "data",
  /** column name */
  id = "id",
  /** column name */
  project_id = "project_id",
  /** column name */
  proto = "proto",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  updated_by = "updated_by",
}

/** input type for updating data in table "cms.resources" */
export interface CmsResourcesSetInput {
  data?: Maybe<Scalars["jsonb"]>;
  proto?: Maybe<Scalars["jsonb"]>;
}

/** Streaming cursor of the table "cms_resources" */
export interface CmsResourcesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: CmsResourcesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface CmsResourcesStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  data?: Maybe<Scalars["jsonb"]>;
  id?: Maybe<Scalars["uuid"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  updated_by?: Maybe<Scalars["uuid"]>;
}

/** update columns of table "cms.resources" */
export enum CmsResourcesUpdateColumn {
  /** column name */
  data = "data",
  /** column name */
  proto = "proto",
}

export interface CmsResourcesUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<CmsResourcesAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<CmsResourcesDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<CmsResourcesDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<CmsResourcesDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<CmsResourcesPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CmsResourcesSetInput>;
  /** filter the rows which have to be updated */
  where: CmsResourcesBoolExp;
}

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  ASC = "ASC",
  /** descending ordering of the cursor */
  DESC = "DESC",
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export interface DateComparisonExp {
  _eq?: Maybe<Scalars["date"]>;
  _gt?: Maybe<Scalars["date"]>;
  _gte?: Maybe<Scalars["date"]>;
  _in?: Maybe<Array<Scalars["date"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["date"]>;
  _lte?: Maybe<Scalars["date"]>;
  _neq?: Maybe<Scalars["date"]>;
  _nin?: Maybe<Array<Scalars["date"]>>;
}

/** columns and relationships of "fe.link_share" */
export interface FeLinkShare {
  created_at: Scalars["timestamptz"];
  download_enabled?: Maybe<Scalars["Boolean"]>;
  /** An object relationship */
  folder?: Maybe<Folders>;
  id: Scalars["uuid"];
  mam_folder_id?: Maybe<Scalars["uuid"]>;
  mam_media_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  media?: Maybe<MediaAssets>;
  proto?: Maybe<Scalars["jsonb"]>;
  title?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
  valid_from?: Maybe<Scalars["timestamptz"]>;
  valid_until?: Maybe<Scalars["timestamptz"]>;
}

/** columns and relationships of "fe.link_share" */
export interface FeLinkShareProtoArgs {
  path?: Maybe<Scalars["String"]>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface FeLinkShareAppendInput {
  proto?: Maybe<Scalars["jsonb"]>;
}

/** Boolean expression to filter rows from the table "fe.link_share". All fields are combined with a logical 'AND'. */
export interface FeLinkShareBoolExp {
  _and?: Maybe<Array<FeLinkShareBoolExp>>;
  _not?: Maybe<FeLinkShareBoolExp>;
  _or?: Maybe<Array<FeLinkShareBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  download_enabled?: Maybe<BooleanComparisonExp>;
  folder?: Maybe<FoldersBoolExp>;
  id?: Maybe<UuidComparisonExp>;
  mam_folder_id?: Maybe<UuidComparisonExp>;
  mam_media_id?: Maybe<UuidComparisonExp>;
  media?: Maybe<MediaAssetsBoolExp>;
  proto?: Maybe<JsonbComparisonExp>;
  title?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  valid_from?: Maybe<TimestamptzComparisonExp>;
  valid_until?: Maybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "fe.link_share" */
export enum FeLinkShareConstraint {
  /** unique or primary key constraint on columns "id" */
  link_share_pkey = "link_share_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface FeLinkShareDeleteAtPathInput {
  proto?: Maybe<Array<Scalars["String"]>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface FeLinkShareDeleteElemInput {
  proto?: Maybe<Scalars["Int"]>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface FeLinkShareDeleteKeyInput {
  proto?: Maybe<Scalars["String"]>;
}

/** input type for inserting data into table "fe.link_share" */
export interface FeLinkShareInsertInput {
  download_enabled?: Maybe<Scalars["Boolean"]>;
  folder?: Maybe<FoldersObjRelInsertInput>;
  mam_folder_id?: Maybe<Scalars["uuid"]>;
  mam_media_id?: Maybe<Scalars["uuid"]>;
  media?: Maybe<MediaAssetsObjRelInsertInput>;
  proto?: Maybe<Scalars["jsonb"]>;
  title?: Maybe<Scalars["String"]>;
  valid_from?: Maybe<Scalars["timestamptz"]>;
  valid_until?: Maybe<Scalars["timestamptz"]>;
}

/** response of any mutation on the table "fe.link_share" */
export interface FeLinkShareMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<FeLinkShare>;
}

/** on_conflict condition type for table "fe.link_share" */
export interface FeLinkShareOnConflict {
  constraint: FeLinkShareConstraint;
  update_columns?: Array<FeLinkShareUpdateColumn>;
  where?: Maybe<FeLinkShareBoolExp>;
}

/** Ordering options when selecting data from "fe.link_share". */
export interface FeLinkShareOrderBy {
  created_at?: Maybe<OrderBy>;
  download_enabled?: Maybe<OrderBy>;
  folder?: Maybe<FoldersOrderBy>;
  id?: Maybe<OrderBy>;
  mam_folder_id?: Maybe<OrderBy>;
  mam_media_id?: Maybe<OrderBy>;
  media?: Maybe<MediaAssetsOrderBy>;
  proto?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  valid_from?: Maybe<OrderBy>;
  valid_until?: Maybe<OrderBy>;
}

/** primary key columns input for table: fe.link_share */
export interface FeLinkSharePkColumnsInput {
  id: Scalars["uuid"];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface FeLinkSharePrependInput {
  proto?: Maybe<Scalars["jsonb"]>;
}

/** select columns of table "fe.link_share" */
export enum FeLinkShareSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  download_enabled = "download_enabled",
  /** column name */
  id = "id",
  /** column name */
  mam_folder_id = "mam_folder_id",
  /** column name */
  mam_media_id = "mam_media_id",
  /** column name */
  proto = "proto",
  /** column name */
  title = "title",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  valid_from = "valid_from",
  /** column name */
  valid_until = "valid_until",
}

/** input type for updating data in table "fe.link_share" */
export interface FeLinkShareSetInput {
  download_enabled?: Maybe<Scalars["Boolean"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  title?: Maybe<Scalars["String"]>;
  valid_from?: Maybe<Scalars["timestamptz"]>;
  valid_until?: Maybe<Scalars["timestamptz"]>;
}

/** Streaming cursor of the table "fe_link_share" */
export interface FeLinkShareStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: FeLinkShareStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface FeLinkShareStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  download_enabled?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["uuid"]>;
  mam_folder_id?: Maybe<Scalars["uuid"]>;
  mam_media_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  title?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  valid_from?: Maybe<Scalars["timestamptz"]>;
  valid_until?: Maybe<Scalars["timestamptz"]>;
}

/** update columns of table "fe.link_share" */
export enum FeLinkShareUpdateColumn {
  /** column name */
  download_enabled = "download_enabled",
  /** column name */
  proto = "proto",
  /** column name */
  title = "title",
  /** column name */
  valid_from = "valid_from",
  /** column name */
  valid_until = "valid_until",
}

export interface FeLinkShareUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<FeLinkShareAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<FeLinkShareDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<FeLinkShareDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<FeLinkShareDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<FeLinkSharePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<FeLinkShareSetInput>;
  /** filter the rows which have to be updated */
  where: FeLinkShareBoolExp;
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export interface Float8ComparisonExp {
  _eq?: Maybe<Scalars["float8"]>;
  _gt?: Maybe<Scalars["float8"]>;
  _gte?: Maybe<Scalars["float8"]>;
  _in?: Maybe<Array<Scalars["float8"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["float8"]>;
  _lte?: Maybe<Scalars["float8"]>;
  _neq?: Maybe<Scalars["float8"]>;
  _nin?: Maybe<Array<Scalars["float8"]>>;
}

/** columns and relationships of "folder_hierarchy" */
export interface FolderHierarchy {
  /** An object relationship */
  child_folder: Folders;
  child_folder_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  depth: Scalars["Int"];
  id: Scalars["Int"];
  /** An object relationship */
  parent_folder: Folders;
  parent_folder_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
}

/** order by aggregate values of table "folder_hierarchy" */
export interface FolderHierarchyAggregateOrderBy {
  avg?: Maybe<FolderHierarchyAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<FolderHierarchyMaxOrderBy>;
  min?: Maybe<FolderHierarchyMinOrderBy>;
  stddev?: Maybe<FolderHierarchyStddevOrderBy>;
  stddev_pop?: Maybe<FolderHierarchyStddevPopOrderBy>;
  stddev_samp?: Maybe<FolderHierarchyStddevSampOrderBy>;
  sum?: Maybe<FolderHierarchySumOrderBy>;
  var_pop?: Maybe<FolderHierarchyVarPopOrderBy>;
  var_samp?: Maybe<FolderHierarchyVarSampOrderBy>;
  variance?: Maybe<FolderHierarchyVarianceOrderBy>;
}

/** order by avg() on columns of table "folder_hierarchy" */
export interface FolderHierarchyAvgOrderBy {
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "folder_hierarchy". All fields are combined with a logical 'AND'. */
export interface FolderHierarchyBoolExp {
  _and?: Maybe<Array<FolderHierarchyBoolExp>>;
  _not?: Maybe<FolderHierarchyBoolExp>;
  _or?: Maybe<Array<FolderHierarchyBoolExp>>;
  child_folder?: Maybe<FoldersBoolExp>;
  child_folder_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  depth?: Maybe<IntComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  parent_folder?: Maybe<FoldersBoolExp>;
  parent_folder_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
}

/** order by max() on columns of table "folder_hierarchy" */
export interface FolderHierarchyMaxOrderBy {
  child_folder_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  parent_folder_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** order by min() on columns of table "folder_hierarchy" */
export interface FolderHierarchyMinOrderBy {
  child_folder_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  parent_folder_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** Ordering options when selecting data from "folder_hierarchy". */
export interface FolderHierarchyOrderBy {
  child_folder?: Maybe<FoldersOrderBy>;
  child_folder_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  parent_folder?: Maybe<FoldersOrderBy>;
  parent_folder_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** select columns of table "folder_hierarchy" */
export enum FolderHierarchySelectColumn {
  /** column name */
  child_folder_id = "child_folder_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  depth = "depth",
  /** column name */
  id = "id",
  /** column name */
  parent_folder_id = "parent_folder_id",
  /** column name */
  updated_at = "updated_at",
}

/** order by stddev() on columns of table "folder_hierarchy" */
export interface FolderHierarchyStddevOrderBy {
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** order by stddev_pop() on columns of table "folder_hierarchy" */
export interface FolderHierarchyStddevPopOrderBy {
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** order by stddev_samp() on columns of table "folder_hierarchy" */
export interface FolderHierarchyStddevSampOrderBy {
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** Streaming cursor of the table "folder_hierarchy" */
export interface FolderHierarchyStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: FolderHierarchyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface FolderHierarchyStreamCursorValueInput {
  child_folder_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  depth?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  parent_folder_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** order by sum() on columns of table "folder_hierarchy" */
export interface FolderHierarchySumOrderBy {
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** order by var_pop() on columns of table "folder_hierarchy" */
export interface FolderHierarchyVarPopOrderBy {
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** order by var_samp() on columns of table "folder_hierarchy" */
export interface FolderHierarchyVarSampOrderBy {
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** order by variance() on columns of table "folder_hierarchy" */
export interface FolderHierarchyVarianceOrderBy {
  depth?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** columns and relationships of "folders" */
export interface Folders {
  /** An array relationship */
  child_folders: Array<FolderHierarchy>;
  created_at: Scalars["timestamptz"];
  created_by?: Maybe<Scalars["uuid"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  /** An array relationship */
  direct_children: Array<Folders>;
  /** An object relationship */
  direct_parent?: Maybe<Folders>;
  /** An array relationship */
  folder_permissions: Array<PermissionFolderPermissions>;
  id: Scalars["uuid"];
  /** An array relationship */
  media_assets: Array<MediaAssets>;
  name: Scalars["String"];
  org_id: Scalars["uuid"];
  /** An object relationship */
  organization: Organizations;
  /** An array relationship */
  parent_folders: Array<FolderHierarchy>;
  parent_id?: Maybe<Scalars["uuid"]>;
  updated_at: Scalars["timestamptz"];
}

/** columns and relationships of "folders" */
export interface FoldersChildFoldersArgs {
  distinct_on?: Maybe<Array<FolderHierarchySelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FolderHierarchyOrderBy>>;
  where?: Maybe<FolderHierarchyBoolExp>;
}

/** columns and relationships of "folders" */
export interface FoldersDirectChildrenArgs {
  distinct_on?: Maybe<Array<FoldersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FoldersOrderBy>>;
  where?: Maybe<FoldersBoolExp>;
}

/** columns and relationships of "folders" */
export interface FoldersFolderPermissionsArgs {
  distinct_on?: Maybe<Array<PermissionFolderPermissionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionFolderPermissionsOrderBy>>;
  where?: Maybe<PermissionFolderPermissionsBoolExp>;
}

/** columns and relationships of "folders" */
export interface FoldersMediaAssetsArgs {
  distinct_on?: Maybe<Array<MediaAssetsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaAssetsOrderBy>>;
  where?: Maybe<MediaAssetsBoolExp>;
}

/** columns and relationships of "folders" */
export interface FoldersParentFoldersArgs {
  distinct_on?: Maybe<Array<FolderHierarchySelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FolderHierarchyOrderBy>>;
  where?: Maybe<FolderHierarchyBoolExp>;
}

/** order by aggregate values of table "folders" */
export interface FoldersAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<FoldersMaxOrderBy>;
  min?: Maybe<FoldersMinOrderBy>;
}

/** input type for inserting array relation for remote table "folders" */
export interface FoldersArrRelInsertInput {
  data: Array<FoldersInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<FoldersOnConflict>;
}

/** Boolean expression to filter rows from the table "folders". All fields are combined with a logical 'AND'. */
export interface FoldersBoolExp {
  _and?: Maybe<Array<FoldersBoolExp>>;
  _not?: Maybe<FoldersBoolExp>;
  _or?: Maybe<Array<FoldersBoolExp>>;
  child_folders?: Maybe<FolderHierarchyBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  direct_children?: Maybe<FoldersBoolExp>;
  direct_parent?: Maybe<FoldersBoolExp>;
  folder_permissions?: Maybe<PermissionFolderPermissionsBoolExp>;
  id?: Maybe<UuidComparisonExp>;
  media_assets?: Maybe<MediaAssetsBoolExp>;
  name?: Maybe<StringComparisonExp>;
  org_id?: Maybe<UuidComparisonExp>;
  organization?: Maybe<OrganizationsBoolExp>;
  parent_folders?: Maybe<FolderHierarchyBoolExp>;
  parent_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "folders" */
export enum FoldersConstraint {
  /** unique or primary key constraint on columns "id" */
  folders_pkey = "folders_pkey",
}

/** input type for inserting data into table "folders" */
export interface FoldersInsertInput {
  description?: Maybe<Scalars["String"]>;
  direct_children?: Maybe<FoldersArrRelInsertInput>;
  direct_parent?: Maybe<FoldersObjRelInsertInput>;
  id?: Maybe<Scalars["uuid"]>;
  media_assets?: Maybe<MediaAssetsArrRelInsertInput>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  organization?: Maybe<OrganizationsObjRelInsertInput>;
  parent_id?: Maybe<Scalars["uuid"]>;
}

/** order by max() on columns of table "folders" */
export interface FoldersMaxOrderBy {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  parent_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** order by min() on columns of table "folders" */
export interface FoldersMinOrderBy {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  parent_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** response of any mutation on the table "folders" */
export interface FoldersMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Folders>;
}

/** input type for inserting object relation for remote table "folders" */
export interface FoldersObjRelInsertInput {
  data: FoldersInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<FoldersOnConflict>;
}

/** on_conflict condition type for table "folders" */
export interface FoldersOnConflict {
  constraint: FoldersConstraint;
  update_columns?: Array<FoldersUpdateColumn>;
  where?: Maybe<FoldersBoolExp>;
}

/** Ordering options when selecting data from "folders". */
export interface FoldersOrderBy {
  child_folders_aggregate?: Maybe<FolderHierarchyAggregateOrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  direct_children_aggregate?: Maybe<FoldersAggregateOrderBy>;
  direct_parent?: Maybe<FoldersOrderBy>;
  folder_permissions_aggregate?: Maybe<PermissionFolderPermissionsAggregateOrderBy>;
  id?: Maybe<OrderBy>;
  media_assets_aggregate?: Maybe<MediaAssetsAggregateOrderBy>;
  name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationsOrderBy>;
  parent_folders_aggregate?: Maybe<FolderHierarchyAggregateOrderBy>;
  parent_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** primary key columns input for table: folders */
export interface FoldersPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "folders" */
export enum FoldersSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  created_by = "created_by",
  /** column name */
  deleted_at = "deleted_at",
  /** column name */
  description = "description",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  org_id = "org_id",
  /** column name */
  parent_id = "parent_id",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "folders" */
export interface FoldersSetInput {
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
}

/** Streaming cursor of the table "folders" */
export interface FoldersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: FoldersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface FoldersStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** update columns of table "folders" */
export enum FoldersUpdateColumn {
  /** column name */
  deleted_at = "deleted_at",
  /** column name */
  description = "description",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  org_id = "org_id",
  /** column name */
  parent_id = "parent_id",
}

export interface FoldersUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<FoldersSetInput>;
  /** filter the rows which have to be updated */
  where: FoldersBoolExp;
}

/** columns and relationships of "image_thumbnail_jobs" */
export interface ImageThumbnailJobs {
  /** An object relationship */
  asset_file: AssetFiles;
  asset_file_id: Scalars["uuid"];
  id: Scalars["uuid"];
  outputs: Scalars["jsonb"];
  status: JobStatusTypesEnum;
}

/** columns and relationships of "image_thumbnail_jobs" */
export interface ImageThumbnailJobsOutputsArgs {
  path?: Maybe<Scalars["String"]>;
}

/** order by aggregate values of table "image_thumbnail_jobs" */
export interface ImageThumbnailJobsAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<ImageThumbnailJobsMaxOrderBy>;
  min?: Maybe<ImageThumbnailJobsMinOrderBy>;
}

/** input type for inserting array relation for remote table "image_thumbnail_jobs" */
export interface ImageThumbnailJobsArrRelInsertInput {
  data: Array<ImageThumbnailJobsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<ImageThumbnailJobsOnConflict>;
}

/** Boolean expression to filter rows from the table "image_thumbnail_jobs". All fields are combined with a logical 'AND'. */
export interface ImageThumbnailJobsBoolExp {
  _and?: Maybe<Array<ImageThumbnailJobsBoolExp>>;
  _not?: Maybe<ImageThumbnailJobsBoolExp>;
  _or?: Maybe<Array<ImageThumbnailJobsBoolExp>>;
  asset_file?: Maybe<AssetFilesBoolExp>;
  asset_file_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  outputs?: Maybe<JsonbComparisonExp>;
  status?: Maybe<JobStatusTypesEnumComparisonExp>;
}

/** unique or primary key constraints on table "image_thumbnail_jobs" */
export enum ImageThumbnailJobsConstraint {
  /** unique or primary key constraint on columns "id" */
  image_thumbnail_jobs_pkey = "image_thumbnail_jobs_pkey",
}

/** input type for inserting data into table "image_thumbnail_jobs" */
export interface ImageThumbnailJobsInsertInput {
  asset_file?: Maybe<AssetFilesObjRelInsertInput>;
  asset_file_id?: Maybe<Scalars["uuid"]>;
  outputs?: Maybe<Scalars["jsonb"]>;
}

/** order by max() on columns of table "image_thumbnail_jobs" */
export interface ImageThumbnailJobsMaxOrderBy {
  asset_file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** order by min() on columns of table "image_thumbnail_jobs" */
export interface ImageThumbnailJobsMinOrderBy {
  asset_file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** response of any mutation on the table "image_thumbnail_jobs" */
export interface ImageThumbnailJobsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<ImageThumbnailJobs>;
}

/** on_conflict condition type for table "image_thumbnail_jobs" */
export interface ImageThumbnailJobsOnConflict {
  constraint: ImageThumbnailJobsConstraint;
  update_columns?: Array<ImageThumbnailJobsUpdateColumn>;
  where?: Maybe<ImageThumbnailJobsBoolExp>;
}

/** Ordering options when selecting data from "image_thumbnail_jobs". */
export interface ImageThumbnailJobsOrderBy {
  asset_file?: Maybe<AssetFilesOrderBy>;
  asset_file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  outputs?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
}

/** select columns of table "image_thumbnail_jobs" */
export enum ImageThumbnailJobsSelectColumn {
  /** column name */
  asset_file_id = "asset_file_id",
  /** column name */
  id = "id",
  /** column name */
  outputs = "outputs",
  /** column name */
  status = "status",
}

/** Streaming cursor of the table "image_thumbnail_jobs" */
export interface ImageThumbnailJobsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ImageThumbnailJobsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ImageThumbnailJobsStreamCursorValueInput {
  asset_file_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  outputs?: Maybe<Scalars["jsonb"]>;
  status?: Maybe<JobStatusTypesEnum>;
}

/** placeholder for update columns of table "image_thumbnail_jobs" (current role has no relevant permissions) */
export enum ImageThumbnailJobsUpdateColumn {
  /** placeholder (do not use) */
  _PLACEHOLDER = "_PLACEHOLDER",
}

/** columns and relationships of "image_thumbnails" */
export interface ImageThumbnails {
  height: Scalars["Int"];
  original_file_id: Scalars["uuid"];
  /** An object relationship */
  thumbnail_file: AssetFiles;
  thumbnail_file_id: Scalars["uuid"];
  width: Scalars["Int"];
}

/** order by aggregate values of table "image_thumbnails" */
export interface ImageThumbnailsAggregateOrderBy {
  avg?: Maybe<ImageThumbnailsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ImageThumbnailsMaxOrderBy>;
  min?: Maybe<ImageThumbnailsMinOrderBy>;
  stddev?: Maybe<ImageThumbnailsStddevOrderBy>;
  stddev_pop?: Maybe<ImageThumbnailsStddevPopOrderBy>;
  stddev_samp?: Maybe<ImageThumbnailsStddevSampOrderBy>;
  sum?: Maybe<ImageThumbnailsSumOrderBy>;
  var_pop?: Maybe<ImageThumbnailsVarPopOrderBy>;
  var_samp?: Maybe<ImageThumbnailsVarSampOrderBy>;
  variance?: Maybe<ImageThumbnailsVarianceOrderBy>;
}

/** order by avg() on columns of table "image_thumbnails" */
export interface ImageThumbnailsAvgOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "image_thumbnails". All fields are combined with a logical 'AND'. */
export interface ImageThumbnailsBoolExp {
  _and?: Maybe<Array<ImageThumbnailsBoolExp>>;
  _not?: Maybe<ImageThumbnailsBoolExp>;
  _or?: Maybe<Array<ImageThumbnailsBoolExp>>;
  height?: Maybe<IntComparisonExp>;
  original_file_id?: Maybe<UuidComparisonExp>;
  thumbnail_file?: Maybe<AssetFilesBoolExp>;
  thumbnail_file_id?: Maybe<UuidComparisonExp>;
  width?: Maybe<IntComparisonExp>;
}

/** order by max() on columns of table "image_thumbnails" */
export interface ImageThumbnailsMaxOrderBy {
  height?: Maybe<OrderBy>;
  original_file_id?: Maybe<OrderBy>;
  thumbnail_file_id?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by min() on columns of table "image_thumbnails" */
export interface ImageThumbnailsMinOrderBy {
  height?: Maybe<OrderBy>;
  original_file_id?: Maybe<OrderBy>;
  thumbnail_file_id?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** response of any mutation on the table "image_thumbnails" */
export interface ImageThumbnailsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<ImageThumbnails>;
}

/** Ordering options when selecting data from "image_thumbnails". */
export interface ImageThumbnailsOrderBy {
  height?: Maybe<OrderBy>;
  original_file_id?: Maybe<OrderBy>;
  thumbnail_file?: Maybe<AssetFilesOrderBy>;
  thumbnail_file_id?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** select columns of table "image_thumbnails" */
export enum ImageThumbnailsSelectColumn {
  /** column name */
  height = "height",
  /** column name */
  original_file_id = "original_file_id",
  /** column name */
  thumbnail_file_id = "thumbnail_file_id",
  /** column name */
  width = "width",
}

/** order by stddev() on columns of table "image_thumbnails" */
export interface ImageThumbnailsStddevOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by stddev_pop() on columns of table "image_thumbnails" */
export interface ImageThumbnailsStddevPopOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by stddev_samp() on columns of table "image_thumbnails" */
export interface ImageThumbnailsStddevSampOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** Streaming cursor of the table "image_thumbnails" */
export interface ImageThumbnailsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ImageThumbnailsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ImageThumbnailsStreamCursorValueInput {
  height?: Maybe<Scalars["Int"]>;
  original_file_id?: Maybe<Scalars["uuid"]>;
  thumbnail_file_id?: Maybe<Scalars["uuid"]>;
  width?: Maybe<Scalars["Int"]>;
}

/** order by sum() on columns of table "image_thumbnails" */
export interface ImageThumbnailsSumOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by var_pop() on columns of table "image_thumbnails" */
export interface ImageThumbnailsVarPopOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by var_samp() on columns of table "image_thumbnails" */
export interface ImageThumbnailsVarSampOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by variance() on columns of table "image_thumbnails" */
export interface ImageThumbnailsVarianceOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** columns and relationships of "insight.storage_logs" */
export interface InsightStorageLogs {
  amount_in_bytes: Scalars["bigint"];
  created_at: Scalars["timestamptz"];
  id: Scalars["bigint"];
  /** An object relationship */
  org?: Maybe<Organizations>;
  org_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
}

/** columns and relationships of "insight.storage_logs" */
export interface InsightStorageLogsProtoArgs {
  path?: Maybe<Scalars["String"]>;
}

/** Boolean expression to filter rows from the table "insight.storage_logs". All fields are combined with a logical 'AND'. */
export interface InsightStorageLogsBoolExp {
  _and?: Maybe<Array<InsightStorageLogsBoolExp>>;
  _not?: Maybe<InsightStorageLogsBoolExp>;
  _or?: Maybe<Array<InsightStorageLogsBoolExp>>;
  amount_in_bytes?: Maybe<BigintComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  org?: Maybe<OrganizationsBoolExp>;
  org_id?: Maybe<UuidComparisonExp>;
  proto?: Maybe<JsonbComparisonExp>;
}

/** Ordering options when selecting data from "insight.storage_logs". */
export interface InsightStorageLogsOrderBy {
  amount_in_bytes?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  org?: Maybe<OrganizationsOrderBy>;
  org_id?: Maybe<OrderBy>;
  proto?: Maybe<OrderBy>;
}

/** select columns of table "insight.storage_logs" */
export enum InsightStorageLogsSelectColumn {
  /** column name */
  amount_in_bytes = "amount_in_bytes",
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  org_id = "org_id",
  /** column name */
  proto = "proto",
}

/** Streaming cursor of the table "insight_storage_logs" */
export interface InsightStorageLogsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: InsightStorageLogsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface InsightStorageLogsStreamCursorValueInput {
  amount_in_bytes?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
}

/** columns and relationships of "insight.transcoder_logs" */
export interface InsightTranscoderLogs {
  amount_in_seconds: Scalars["float8"];
  created_at: Scalars["timestamptz"];
  id: Scalars["bigint"];
  /** An object relationship */
  org?: Maybe<Organizations>;
  org_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  resolution?: Maybe<Scalars["String"]>;
  time_span_end: Scalars["timestamptz"];
  time_span_start: Scalars["timestamptz"];
}

/** columns and relationships of "insight.transcoder_logs" */
export interface InsightTranscoderLogsProtoArgs {
  path?: Maybe<Scalars["String"]>;
}

/** Boolean expression to filter rows from the table "insight.transcoder_logs". All fields are combined with a logical 'AND'. */
export interface InsightTranscoderLogsBoolExp {
  _and?: Maybe<Array<InsightTranscoderLogsBoolExp>>;
  _not?: Maybe<InsightTranscoderLogsBoolExp>;
  _or?: Maybe<Array<InsightTranscoderLogsBoolExp>>;
  amount_in_seconds?: Maybe<Float8ComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  org?: Maybe<OrganizationsBoolExp>;
  org_id?: Maybe<UuidComparisonExp>;
  proto?: Maybe<JsonbComparisonExp>;
  resolution?: Maybe<StringComparisonExp>;
  time_span_end?: Maybe<TimestamptzComparisonExp>;
  time_span_start?: Maybe<TimestamptzComparisonExp>;
}

/** Ordering options when selecting data from "insight.transcoder_logs". */
export interface InsightTranscoderLogsOrderBy {
  amount_in_seconds?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  org?: Maybe<OrganizationsOrderBy>;
  org_id?: Maybe<OrderBy>;
  proto?: Maybe<OrderBy>;
  resolution?: Maybe<OrderBy>;
  time_span_end?: Maybe<OrderBy>;
  time_span_start?: Maybe<OrderBy>;
}

/** select columns of table "insight.transcoder_logs" */
export enum InsightTranscoderLogsSelectColumn {
  /** column name */
  amount_in_seconds = "amount_in_seconds",
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  org_id = "org_id",
  /** column name */
  proto = "proto",
  /** column name */
  resolution = "resolution",
  /** column name */
  time_span_end = "time_span_end",
  /** column name */
  time_span_start = "time_span_start",
}

/** Streaming cursor of the table "insight_transcoder_logs" */
export interface InsightTranscoderLogsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: InsightTranscoderLogsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface InsightTranscoderLogsStreamCursorValueInput {
  amount_in_seconds?: Maybe<Scalars["float8"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  resolution?: Maybe<Scalars["String"]>;
  time_span_end?: Maybe<Scalars["timestamptz"]>;
  time_span_start?: Maybe<Scalars["timestamptz"]>;
}

/** columns and relationships of "insight.transfer_logs" */
export interface InsightTransferLogs {
  amount_in_bytes: Scalars["bigint"];
  created_at: Scalars["timestamptz"];
  id: Scalars["bigint"];
  /** An object relationship */
  org?: Maybe<Organizations>;
  org_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  time_span_end: Scalars["timestamptz"];
  time_span_start: Scalars["timestamptz"];
}

/** columns and relationships of "insight.transfer_logs" */
export interface InsightTransferLogsProtoArgs {
  path?: Maybe<Scalars["String"]>;
}

/** Boolean expression to filter rows from the table "insight.transfer_logs". All fields are combined with a logical 'AND'. */
export interface InsightTransferLogsBoolExp {
  _and?: Maybe<Array<InsightTransferLogsBoolExp>>;
  _not?: Maybe<InsightTransferLogsBoolExp>;
  _or?: Maybe<Array<InsightTransferLogsBoolExp>>;
  amount_in_bytes?: Maybe<BigintComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  org?: Maybe<OrganizationsBoolExp>;
  org_id?: Maybe<UuidComparisonExp>;
  proto?: Maybe<JsonbComparisonExp>;
  time_span_end?: Maybe<TimestamptzComparisonExp>;
  time_span_start?: Maybe<TimestamptzComparisonExp>;
}

/** Ordering options when selecting data from "insight.transfer_logs". */
export interface InsightTransferLogsOrderBy {
  amount_in_bytes?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  org?: Maybe<OrganizationsOrderBy>;
  org_id?: Maybe<OrderBy>;
  proto?: Maybe<OrderBy>;
  time_span_end?: Maybe<OrderBy>;
  time_span_start?: Maybe<OrderBy>;
}

/** select columns of table "insight.transfer_logs" */
export enum InsightTransferLogsSelectColumn {
  /** column name */
  amount_in_bytes = "amount_in_bytes",
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  org_id = "org_id",
  /** column name */
  proto = "proto",
  /** column name */
  time_span_end = "time_span_end",
  /** column name */
  time_span_start = "time_span_start",
}

/** Streaming cursor of the table "insight_transfer_logs" */
export interface InsightTransferLogsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: InsightTransferLogsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface InsightTransferLogsStreamCursorValueInput {
  amount_in_bytes?: Maybe<Scalars["bigint"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  time_span_end?: Maybe<Scalars["timestamptz"]>;
  time_span_start?: Maybe<Scalars["timestamptz"]>;
}

export enum JobStatusTypesEnum {
  FAILED = "FAILED",
  FINISHED = "FINISHED",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  PROCESSING = "PROCESSING",
  WAITING_FOR_PROCESSING = "WAITING_FOR_PROCESSING",
}

/** Boolean expression to compare columns of type "job_status_types_enum". All fields are combined with logical 'AND'. */
export interface JobStatusTypesEnumComparisonExp {
  _eq?: Maybe<JobStatusTypesEnum>;
  _in?: Maybe<Array<JobStatusTypesEnum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<JobStatusTypesEnum>;
  _nin?: Maybe<Array<JobStatusTypesEnum>>;
}

export interface JsonbCastExp {
  String?: Maybe<StringComparisonExp>;
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export interface JsonbComparisonExp {
  _cast?: Maybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars["jsonb"]>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars["jsonb"]>;
  _eq?: Maybe<Scalars["jsonb"]>;
  _gt?: Maybe<Scalars["jsonb"]>;
  _gte?: Maybe<Scalars["jsonb"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars["String"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars["String"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars["String"]>>;
  _in?: Maybe<Array<Scalars["jsonb"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["jsonb"]>;
  _lte?: Maybe<Scalars["jsonb"]>;
  _neq?: Maybe<Scalars["jsonb"]>;
  _nin?: Maybe<Array<Scalars["jsonb"]>>;
}

/** columns and relationships of "media_asset_types" */
export interface MediaAssetTypes {
  type: Scalars["String"];
}

/** Boolean expression to filter rows from the table "media_asset_types". All fields are combined with a logical 'AND'. */
export interface MediaAssetTypesBoolExp {
  _and?: Maybe<Array<MediaAssetTypesBoolExp>>;
  _not?: Maybe<MediaAssetTypesBoolExp>;
  _or?: Maybe<Array<MediaAssetTypesBoolExp>>;
  type?: Maybe<StringComparisonExp>;
}

export enum MediaAssetTypesEnum {
  AUDIO = "AUDIO",
  FILE = "FILE",
  IMAGE = "IMAGE",
  UNKNOWN = "UNKNOWN",
  VIDEO = "VIDEO",
}

/** Boolean expression to compare columns of type "media_asset_types_enum". All fields are combined with logical 'AND'. */
export interface MediaAssetTypesEnumComparisonExp {
  _eq?: Maybe<MediaAssetTypesEnum>;
  _in?: Maybe<Array<MediaAssetTypesEnum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<MediaAssetTypesEnum>;
  _nin?: Maybe<Array<MediaAssetTypesEnum>>;
}

/** Ordering options when selecting data from "media_asset_types". */
export interface MediaAssetTypesOrderBy {
  type?: Maybe<OrderBy>;
}

/** select columns of table "media_asset_types" */
export enum MediaAssetTypesSelectColumn {
  /** column name */
  type = "type",
}

/** Streaming cursor of the table "media_asset_types" */
export interface MediaAssetTypesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: MediaAssetTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface MediaAssetTypesStreamCursorValueInput {
  type?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "media_assets" */
export interface MediaAssets {
  /** An array relationship */
  asset_files: Array<AssetFiles>;
  /** An aggregate relationship */
  asset_files_aggregate: AssetFilesAggregate;
  created_at: Scalars["timestamptz"];
  created_by?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  creator?: Maybe<Users>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  /** An object relationship */
  folder: Folders;
  folder_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An array relationship */
  meta_field_values: Array<MetaFieldValues>;
  /** An array relationship */
  metadata_fields: Array<AssetMetadataValues>;
  name: Scalars["String"];
  org_id: Scalars["uuid"];
  /** An object relationship */
  organization: Organizations;
  /** An array relationship */
  permissions: Array<PermissionMediaPermissions>;
  type: MediaAssetTypesEnum;
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  video_properties?: Maybe<VideoProperties>;
}

/** columns and relationships of "media_assets" */
export interface MediaAssetsAssetFilesArgs {
  distinct_on?: Maybe<Array<AssetFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetFilesOrderBy>>;
  where?: Maybe<AssetFilesBoolExp>;
}

/** columns and relationships of "media_assets" */
export interface MediaAssetsAssetFilesAggregateArgs {
  distinct_on?: Maybe<Array<AssetFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetFilesOrderBy>>;
  where?: Maybe<AssetFilesBoolExp>;
}

/** columns and relationships of "media_assets" */
export interface MediaAssetsMetaFieldValuesArgs {
  distinct_on?: Maybe<Array<MetaFieldValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaFieldValuesOrderBy>>;
  where?: Maybe<MetaFieldValuesBoolExp>;
}

/** columns and relationships of "media_assets" */
export interface MediaAssetsMetadataFieldsArgs {
  distinct_on?: Maybe<Array<AssetMetadataValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetMetadataValuesOrderBy>>;
  where?: Maybe<AssetMetadataValuesBoolExp>;
}

/** columns and relationships of "media_assets" */
export interface MediaAssetsPermissionsArgs {
  distinct_on?: Maybe<Array<PermissionMediaPermissionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionMediaPermissionsOrderBy>>;
  where?: Maybe<PermissionMediaPermissionsBoolExp>;
}

/** order by aggregate values of table "media_assets" */
export interface MediaAssetsAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<MediaAssetsMaxOrderBy>;
  min?: Maybe<MediaAssetsMinOrderBy>;
}

/** input type for inserting array relation for remote table "media_assets" */
export interface MediaAssetsArrRelInsertInput {
  data: Array<MediaAssetsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<MediaAssetsOnConflict>;
}

/** Boolean expression to filter rows from the table "media_assets". All fields are combined with a logical 'AND'. */
export interface MediaAssetsBoolExp {
  _and?: Maybe<Array<MediaAssetsBoolExp>>;
  _not?: Maybe<MediaAssetsBoolExp>;
  _or?: Maybe<Array<MediaAssetsBoolExp>>;
  asset_files?: Maybe<AssetFilesBoolExp>;
  asset_files_aggregate?: Maybe<AssetFilesAggregateBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  creator?: Maybe<UsersBoolExp>;
  deleted_at?: Maybe<TimestamptzComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  folder?: Maybe<FoldersBoolExp>;
  folder_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  meta_field_values?: Maybe<MetaFieldValuesBoolExp>;
  metadata_fields?: Maybe<AssetMetadataValuesBoolExp>;
  name?: Maybe<StringComparisonExp>;
  org_id?: Maybe<UuidComparisonExp>;
  organization?: Maybe<OrganizationsBoolExp>;
  permissions?: Maybe<PermissionMediaPermissionsBoolExp>;
  type?: Maybe<MediaAssetTypesEnumComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  video_properties?: Maybe<VideoPropertiesBoolExp>;
}

/** unique or primary key constraints on table "media_assets" */
export enum MediaAssetsConstraint {
  /** unique or primary key constraint on columns "id" */
  media_assets_pkey = "media_assets_pkey",
}

/** input type for inserting data into table "media_assets" */
export interface MediaAssetsInsertInput {
  asset_files?: Maybe<AssetFilesArrRelInsertInput>;
  description?: Maybe<Scalars["String"]>;
  folder?: Maybe<FoldersObjRelInsertInput>;
  folder_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  meta_field_values?: Maybe<MetaFieldValuesArrRelInsertInput>;
  metadata_fields?: Maybe<AssetMetadataValuesArrRelInsertInput>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  organization?: Maybe<OrganizationsObjRelInsertInput>;
  type?: Maybe<MediaAssetTypesEnum>;
}

/** order by max() on columns of table "media_assets" */
export interface MediaAssetsMaxOrderBy {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  folder_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** order by min() on columns of table "media_assets" */
export interface MediaAssetsMinOrderBy {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  folder_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** response of any mutation on the table "media_assets" */
export interface MediaAssetsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MediaAssets>;
}

/** input type for inserting object relation for remote table "media_assets" */
export interface MediaAssetsObjRelInsertInput {
  data: MediaAssetsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<MediaAssetsOnConflict>;
}

/** on_conflict condition type for table "media_assets" */
export interface MediaAssetsOnConflict {
  constraint: MediaAssetsConstraint;
  update_columns?: Array<MediaAssetsUpdateColumn>;
  where?: Maybe<MediaAssetsBoolExp>;
}

/** Ordering options when selecting data from "media_assets". */
export interface MediaAssetsOrderBy {
  asset_files_aggregate?: Maybe<AssetFilesAggregateOrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  creator?: Maybe<UsersOrderBy>;
  deleted_at?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  folder?: Maybe<FoldersOrderBy>;
  folder_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  meta_field_values_aggregate?: Maybe<MetaFieldValuesAggregateOrderBy>;
  metadata_fields_aggregate?: Maybe<AssetMetadataValuesAggregateOrderBy>;
  name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationsOrderBy>;
  permissions_aggregate?: Maybe<PermissionMediaPermissionsAggregateOrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  video_properties?: Maybe<VideoPropertiesOrderBy>;
}

/** primary key columns input for table: media_assets */
export interface MediaAssetsPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "media_assets" */
export enum MediaAssetsSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  created_by = "created_by",
  /** column name */
  deleted_at = "deleted_at",
  /** column name */
  description = "description",
  /** column name */
  folder_id = "folder_id",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  org_id = "org_id",
  /** column name */
  type = "type",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "media_assets" */
export interface MediaAssetsSetInput {
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  folder_id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<MediaAssetTypesEnum>;
}

/** Streaming cursor of the table "media_assets" */
export interface MediaAssetsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: MediaAssetsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface MediaAssetsStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  folder_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<MediaAssetTypesEnum>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** update columns of table "media_assets" */
export enum MediaAssetsUpdateColumn {
  /** column name */
  deleted_at = "deleted_at",
  /** column name */
  description = "description",
  /** column name */
  folder_id = "folder_id",
  /** column name */
  name = "name",
  /** column name */
  org_id = "org_id",
  /** column name */
  type = "type",
}

export interface MediaAssetsUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<MediaAssetsSetInput>;
  /** filter the rows which have to be updated */
  where: MediaAssetsBoolExp;
}

/** columns and relationships of "media.live_stream_sessions" */
export interface MediaLiveStreamSessions {
  ended_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  live_stream?: Maybe<MediaLiveStreams>;
  /** An object relationship */
  org?: Maybe<Organizations>;
  org_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  started_at: Scalars["timestamptz"];
  stream_key?: Maybe<Scalars["uuid"]>;
}

/** columns and relationships of "media.live_stream_sessions" */
export interface MediaLiveStreamSessionsProtoArgs {
  path?: Maybe<Scalars["String"]>;
}

/** order by aggregate values of table "media.live_stream_sessions" */
export interface MediaLiveStreamSessionsAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<MediaLiveStreamSessionsMaxOrderBy>;
  min?: Maybe<MediaLiveStreamSessionsMinOrderBy>;
}

/** Boolean expression to filter rows from the table "media.live_stream_sessions". All fields are combined with a logical 'AND'. */
export interface MediaLiveStreamSessionsBoolExp {
  _and?: Maybe<Array<MediaLiveStreamSessionsBoolExp>>;
  _not?: Maybe<MediaLiveStreamSessionsBoolExp>;
  _or?: Maybe<Array<MediaLiveStreamSessionsBoolExp>>;
  ended_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  live_stream?: Maybe<MediaLiveStreamsBoolExp>;
  org?: Maybe<OrganizationsBoolExp>;
  org_id?: Maybe<UuidComparisonExp>;
  proto?: Maybe<JsonbComparisonExp>;
  started_at?: Maybe<TimestamptzComparisonExp>;
  stream_key?: Maybe<UuidComparisonExp>;
}

/** order by max() on columns of table "media.live_stream_sessions" */
export interface MediaLiveStreamSessionsMaxOrderBy {
  ended_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  started_at?: Maybe<OrderBy>;
  stream_key?: Maybe<OrderBy>;
}

/** order by min() on columns of table "media.live_stream_sessions" */
export interface MediaLiveStreamSessionsMinOrderBy {
  ended_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  started_at?: Maybe<OrderBy>;
  stream_key?: Maybe<OrderBy>;
}

/** Ordering options when selecting data from "media.live_stream_sessions". */
export interface MediaLiveStreamSessionsOrderBy {
  ended_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  live_stream?: Maybe<MediaLiveStreamsOrderBy>;
  org?: Maybe<OrganizationsOrderBy>;
  org_id?: Maybe<OrderBy>;
  proto?: Maybe<OrderBy>;
  started_at?: Maybe<OrderBy>;
  stream_key?: Maybe<OrderBy>;
}

/** select columns of table "media.live_stream_sessions" */
export enum MediaLiveStreamSessionsSelectColumn {
  /** column name */
  ended_at = "ended_at",
  /** column name */
  id = "id",
  /** column name */
  org_id = "org_id",
  /** column name */
  proto = "proto",
  /** column name */
  started_at = "started_at",
  /** column name */
  stream_key = "stream_key",
}

/** Streaming cursor of the table "media_live_stream_sessions" */
export interface MediaLiveStreamSessionsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: MediaLiveStreamSessionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface MediaLiveStreamSessionsStreamCursorValueInput {
  ended_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  started_at?: Maybe<Scalars["timestamptz"]>;
  stream_key?: Maybe<Scalars["uuid"]>;
}

/** columns and relationships of "media.live_streams" */
export interface MediaLiveStreams {
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  org: Organizations;
  org_id: Scalars["uuid"];
  playback_key: Scalars["uuid"];
  proto?: Maybe<Scalars["jsonb"]>;
  /** An array relationship */
  sessions: Array<MediaLiveStreamSessions>;
  status: Scalars["String"];
  stream_key: Scalars["uuid"];
  title: Scalars["String"];
  transcode: Scalars["Boolean"];
  updated_at: Scalars["timestamptz"];
}

/** columns and relationships of "media.live_streams" */
export interface MediaLiveStreamsProtoArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "media.live_streams" */
export interface MediaLiveStreamsSessionsArgs {
  distinct_on?: Maybe<Array<MediaLiveStreamSessionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaLiveStreamSessionsOrderBy>>;
  where?: Maybe<MediaLiveStreamSessionsBoolExp>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface MediaLiveStreamsAppendInput {
  proto?: Maybe<Scalars["jsonb"]>;
}

/** Boolean expression to filter rows from the table "media.live_streams". All fields are combined with a logical 'AND'. */
export interface MediaLiveStreamsBoolExp {
  _and?: Maybe<Array<MediaLiveStreamsBoolExp>>;
  _not?: Maybe<MediaLiveStreamsBoolExp>;
  _or?: Maybe<Array<MediaLiveStreamsBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  org?: Maybe<OrganizationsBoolExp>;
  org_id?: Maybe<UuidComparisonExp>;
  playback_key?: Maybe<UuidComparisonExp>;
  proto?: Maybe<JsonbComparisonExp>;
  sessions?: Maybe<MediaLiveStreamSessionsBoolExp>;
  status?: Maybe<StringComparisonExp>;
  stream_key?: Maybe<UuidComparisonExp>;
  title?: Maybe<StringComparisonExp>;
  transcode?: Maybe<BooleanComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "media.live_streams" */
export enum MediaLiveStreamsConstraint {
  /** unique or primary key constraint on columns "stream_key" */
  live_streams_pkey = "live_streams_pkey",
  /** unique or primary key constraint on columns "playback_key" */
  live_streams_playback_key_key = "live_streams_playback_key_key",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface MediaLiveStreamsDeleteAtPathInput {
  proto?: Maybe<Array<Scalars["String"]>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface MediaLiveStreamsDeleteElemInput {
  proto?: Maybe<Scalars["Int"]>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface MediaLiveStreamsDeleteKeyInput {
  proto?: Maybe<Scalars["String"]>;
}

/** input type for inserting data into table "media.live_streams" */
export interface MediaLiveStreamsInsertInput {
  org?: Maybe<OrganizationsObjRelInsertInput>;
  org_id?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  status?: Maybe<Scalars["String"]>;
  stream_key?: Maybe<Scalars["uuid"]>;
  title?: Maybe<Scalars["String"]>;
  transcode?: Maybe<Scalars["Boolean"]>;
}

/** response of any mutation on the table "media.live_streams" */
export interface MediaLiveStreamsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MediaLiveStreams>;
}

/** on_conflict condition type for table "media.live_streams" */
export interface MediaLiveStreamsOnConflict {
  constraint: MediaLiveStreamsConstraint;
  update_columns?: Array<MediaLiveStreamsUpdateColumn>;
  where?: Maybe<MediaLiveStreamsBoolExp>;
}

/** Ordering options when selecting data from "media.live_streams". */
export interface MediaLiveStreamsOrderBy {
  created_at?: Maybe<OrderBy>;
  org?: Maybe<OrganizationsOrderBy>;
  org_id?: Maybe<OrderBy>;
  playback_key?: Maybe<OrderBy>;
  proto?: Maybe<OrderBy>;
  sessions_aggregate?: Maybe<MediaLiveStreamSessionsAggregateOrderBy>;
  status?: Maybe<OrderBy>;
  stream_key?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  transcode?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** primary key columns input for table: media.live_streams */
export interface MediaLiveStreamsPkColumnsInput {
  stream_key: Scalars["uuid"];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface MediaLiveStreamsPrependInput {
  proto?: Maybe<Scalars["jsonb"]>;
}

/** select columns of table "media.live_streams" */
export enum MediaLiveStreamsSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  org_id = "org_id",
  /** column name */
  playback_key = "playback_key",
  /** column name */
  proto = "proto",
  /** column name */
  status = "status",
  /** column name */
  stream_key = "stream_key",
  /** column name */
  title = "title",
  /** column name */
  transcode = "transcode",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "media.live_streams" */
export interface MediaLiveStreamsSetInput {
  proto?: Maybe<Scalars["jsonb"]>;
  title?: Maybe<Scalars["String"]>;
  transcode?: Maybe<Scalars["Boolean"]>;
}

/** Streaming cursor of the table "media_live_streams" */
export interface MediaLiveStreamsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: MediaLiveStreamsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface MediaLiveStreamsStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  playback_key?: Maybe<Scalars["uuid"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  status?: Maybe<Scalars["String"]>;
  stream_key?: Maybe<Scalars["uuid"]>;
  title?: Maybe<Scalars["String"]>;
  transcode?: Maybe<Scalars["Boolean"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** update columns of table "media.live_streams" */
export enum MediaLiveStreamsUpdateColumn {
  /** column name */
  proto = "proto",
  /** column name */
  title = "title",
  /** column name */
  transcode = "transcode",
}

export interface MediaLiveStreamsUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<MediaLiveStreamsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<MediaLiveStreamsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<MediaLiveStreamsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<MediaLiveStreamsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<MediaLiveStreamsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<MediaLiveStreamsSetInput>;
  /** filter the rows which have to be updated */
  where: MediaLiveStreamsBoolExp;
}

/** columns and relationships of "meta_boolean_values" */
export interface MetaBooleanValues {
  /** An object relationship */
  field_values: MetaFieldValues;
  field_values_id: Scalars["uuid"];
  id: Scalars["uuid"];
  value: Scalars["Boolean"];
}

/** order by aggregate values of table "meta_boolean_values" */
export interface MetaBooleanValuesAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<MetaBooleanValuesMaxOrderBy>;
  min?: Maybe<MetaBooleanValuesMinOrderBy>;
}

/** input type for inserting array relation for remote table "meta_boolean_values" */
export interface MetaBooleanValuesArrRelInsertInput {
  data: Array<MetaBooleanValuesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<MetaBooleanValuesOnConflict>;
}

/** Boolean expression to filter rows from the table "meta_boolean_values". All fields are combined with a logical 'AND'. */
export interface MetaBooleanValuesBoolExp {
  _and?: Maybe<Array<MetaBooleanValuesBoolExp>>;
  _not?: Maybe<MetaBooleanValuesBoolExp>;
  _or?: Maybe<Array<MetaBooleanValuesBoolExp>>;
  field_values?: Maybe<MetaFieldValuesBoolExp>;
  field_values_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  value?: Maybe<BooleanComparisonExp>;
}

/** unique or primary key constraints on table "meta_boolean_values" */
export enum MetaBooleanValuesConstraint {
  /** unique or primary key constraint on columns "id" */
  meta_boolean_values_pkey = "meta_boolean_values_pkey",
}

/** input type for inserting data into table "meta_boolean_values" */
export interface MetaBooleanValuesInsertInput {
  field_values?: Maybe<MetaFieldValuesObjRelInsertInput>;
  field_values_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["Boolean"]>;
}

/** order by max() on columns of table "meta_boolean_values" */
export interface MetaBooleanValuesMaxOrderBy {
  field_values_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** order by min() on columns of table "meta_boolean_values" */
export interface MetaBooleanValuesMinOrderBy {
  field_values_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** response of any mutation on the table "meta_boolean_values" */
export interface MetaBooleanValuesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetaBooleanValues>;
}

/** on_conflict condition type for table "meta_boolean_values" */
export interface MetaBooleanValuesOnConflict {
  constraint: MetaBooleanValuesConstraint;
  update_columns?: Array<MetaBooleanValuesUpdateColumn>;
  where?: Maybe<MetaBooleanValuesBoolExp>;
}

/** Ordering options when selecting data from "meta_boolean_values". */
export interface MetaBooleanValuesOrderBy {
  field_values?: Maybe<MetaFieldValuesOrderBy>;
  field_values_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** primary key columns input for table: meta_boolean_values */
export interface MetaBooleanValuesPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "meta_boolean_values" */
export enum MetaBooleanValuesSelectColumn {
  /** column name */
  field_values_id = "field_values_id",
  /** column name */
  id = "id",
  /** column name */
  value = "value",
}

/** input type for updating data in table "meta_boolean_values" */
export interface MetaBooleanValuesSetInput {
  field_values_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["Boolean"]>;
}

/** Streaming cursor of the table "meta_boolean_values" */
export interface MetaBooleanValuesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: MetaBooleanValuesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface MetaBooleanValuesStreamCursorValueInput {
  field_values_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["Boolean"]>;
}

/** update columns of table "meta_boolean_values" */
export enum MetaBooleanValuesUpdateColumn {
  /** column name */
  field_values_id = "field_values_id",
  /** column name */
  id = "id",
  /** column name */
  value = "value",
}

export interface MetaBooleanValuesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<MetaBooleanValuesSetInput>;
  /** filter the rows which have to be updated */
  where: MetaBooleanValuesBoolExp;
}

/** columns and relationships of "meta_date_values" */
export interface MetaDateValues {
  /** An object relationship */
  field_values: MetaFieldValues;
  field_values_id: Scalars["uuid"];
  id: Scalars["uuid"];
  value: Scalars["date"];
}

/** order by aggregate values of table "meta_date_values" */
export interface MetaDateValuesAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<MetaDateValuesMaxOrderBy>;
  min?: Maybe<MetaDateValuesMinOrderBy>;
}

/** input type for inserting array relation for remote table "meta_date_values" */
export interface MetaDateValuesArrRelInsertInput {
  data: Array<MetaDateValuesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<MetaDateValuesOnConflict>;
}

/** Boolean expression to filter rows from the table "meta_date_values". All fields are combined with a logical 'AND'. */
export interface MetaDateValuesBoolExp {
  _and?: Maybe<Array<MetaDateValuesBoolExp>>;
  _not?: Maybe<MetaDateValuesBoolExp>;
  _or?: Maybe<Array<MetaDateValuesBoolExp>>;
  field_values?: Maybe<MetaFieldValuesBoolExp>;
  field_values_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  value?: Maybe<DateComparisonExp>;
}

/** unique or primary key constraints on table "meta_date_values" */
export enum MetaDateValuesConstraint {
  /** unique or primary key constraint on columns "id" */
  meta_date_values_pkey = "meta_date_values_pkey",
}

/** input type for inserting data into table "meta_date_values" */
export interface MetaDateValuesInsertInput {
  field_values?: Maybe<MetaFieldValuesObjRelInsertInput>;
  field_values_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["date"]>;
}

/** order by max() on columns of table "meta_date_values" */
export interface MetaDateValuesMaxOrderBy {
  field_values_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** order by min() on columns of table "meta_date_values" */
export interface MetaDateValuesMinOrderBy {
  field_values_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** response of any mutation on the table "meta_date_values" */
export interface MetaDateValuesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetaDateValues>;
}

/** on_conflict condition type for table "meta_date_values" */
export interface MetaDateValuesOnConflict {
  constraint: MetaDateValuesConstraint;
  update_columns?: Array<MetaDateValuesUpdateColumn>;
  where?: Maybe<MetaDateValuesBoolExp>;
}

/** Ordering options when selecting data from "meta_date_values". */
export interface MetaDateValuesOrderBy {
  field_values?: Maybe<MetaFieldValuesOrderBy>;
  field_values_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** primary key columns input for table: meta_date_values */
export interface MetaDateValuesPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "meta_date_values" */
export enum MetaDateValuesSelectColumn {
  /** column name */
  field_values_id = "field_values_id",
  /** column name */
  id = "id",
  /** column name */
  value = "value",
}

/** input type for updating data in table "meta_date_values" */
export interface MetaDateValuesSetInput {
  field_values_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["date"]>;
}

/** Streaming cursor of the table "meta_date_values" */
export interface MetaDateValuesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: MetaDateValuesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface MetaDateValuesStreamCursorValueInput {
  field_values_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["date"]>;
}

/** update columns of table "meta_date_values" */
export enum MetaDateValuesUpdateColumn {
  /** column name */
  field_values_id = "field_values_id",
  /** column name */
  id = "id",
  /** column name */
  value = "value",
}

export interface MetaDateValuesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<MetaDateValuesSetInput>;
  /** filter the rows which have to be updated */
  where: MetaDateValuesBoolExp;
}

/** columns and relationships of "meta_field_values" */
export interface MetaFieldValues {
  asset_id: Scalars["uuid"];
  /** An array relationship */
  boolean_values: Array<MetaBooleanValues>;
  /** An array relationship */
  date_values: Array<MetaDateValues>;
  /** An object relationship */
  field: AssetMetadataFields;
  field_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An array relationship */
  meta_permissions: Array<PermissionMetaPermissions>;
  /** An array relationship */
  number_values: Array<MetaNumberValues>;
  /** An array relationship */
  text_values: Array<MetaTextValues>;
}

/** columns and relationships of "meta_field_values" */
export interface MetaFieldValuesBooleanValuesArgs {
  distinct_on?: Maybe<Array<MetaBooleanValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaBooleanValuesOrderBy>>;
  where?: Maybe<MetaBooleanValuesBoolExp>;
}

/** columns and relationships of "meta_field_values" */
export interface MetaFieldValuesDateValuesArgs {
  distinct_on?: Maybe<Array<MetaDateValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaDateValuesOrderBy>>;
  where?: Maybe<MetaDateValuesBoolExp>;
}

/** columns and relationships of "meta_field_values" */
export interface MetaFieldValuesMetaPermissionsArgs {
  distinct_on?: Maybe<Array<PermissionMetaPermissionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionMetaPermissionsOrderBy>>;
  where?: Maybe<PermissionMetaPermissionsBoolExp>;
}

/** columns and relationships of "meta_field_values" */
export interface MetaFieldValuesNumberValuesArgs {
  distinct_on?: Maybe<Array<MetaNumberValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaNumberValuesOrderBy>>;
  where?: Maybe<MetaNumberValuesBoolExp>;
}

/** columns and relationships of "meta_field_values" */
export interface MetaFieldValuesTextValuesArgs {
  distinct_on?: Maybe<Array<MetaTextValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaTextValuesOrderBy>>;
  where?: Maybe<MetaTextValuesBoolExp>;
}

/** order by aggregate values of table "meta_field_values" */
export interface MetaFieldValuesAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<MetaFieldValuesMaxOrderBy>;
  min?: Maybe<MetaFieldValuesMinOrderBy>;
}

/** input type for inserting array relation for remote table "meta_field_values" */
export interface MetaFieldValuesArrRelInsertInput {
  data: Array<MetaFieldValuesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<MetaFieldValuesOnConflict>;
}

/** Boolean expression to filter rows from the table "meta_field_values". All fields are combined with a logical 'AND'. */
export interface MetaFieldValuesBoolExp {
  _and?: Maybe<Array<MetaFieldValuesBoolExp>>;
  _not?: Maybe<MetaFieldValuesBoolExp>;
  _or?: Maybe<Array<MetaFieldValuesBoolExp>>;
  asset_id?: Maybe<UuidComparisonExp>;
  boolean_values?: Maybe<MetaBooleanValuesBoolExp>;
  date_values?: Maybe<MetaDateValuesBoolExp>;
  field?: Maybe<AssetMetadataFieldsBoolExp>;
  field_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  meta_permissions?: Maybe<PermissionMetaPermissionsBoolExp>;
  number_values?: Maybe<MetaNumberValuesBoolExp>;
  text_values?: Maybe<MetaTextValuesBoolExp>;
}

/** unique or primary key constraints on table "meta_field_values" */
export enum MetaFieldValuesConstraint {
  /** unique or primary key constraint on columns "asset_id", "field_id" */
  meta_field_values_field_id_asset_id_key = "meta_field_values_field_id_asset_id_key",
  /** unique or primary key constraint on columns "id" */
  meta_field_values_pkey = "meta_field_values_pkey",
}

/** input type for inserting data into table "meta_field_values" */
export interface MetaFieldValuesInsertInput {
  asset_id?: Maybe<Scalars["uuid"]>;
  boolean_values?: Maybe<MetaBooleanValuesArrRelInsertInput>;
  date_values?: Maybe<MetaDateValuesArrRelInsertInput>;
  field?: Maybe<AssetMetadataFieldsObjRelInsertInput>;
  field_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  number_values?: Maybe<MetaNumberValuesArrRelInsertInput>;
  text_values?: Maybe<MetaTextValuesArrRelInsertInput>;
}

/** order by max() on columns of table "meta_field_values" */
export interface MetaFieldValuesMaxOrderBy {
  asset_id?: Maybe<OrderBy>;
  field_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** order by min() on columns of table "meta_field_values" */
export interface MetaFieldValuesMinOrderBy {
  asset_id?: Maybe<OrderBy>;
  field_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** response of any mutation on the table "meta_field_values" */
export interface MetaFieldValuesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetaFieldValues>;
}

/** input type for inserting object relation for remote table "meta_field_values" */
export interface MetaFieldValuesObjRelInsertInput {
  data: MetaFieldValuesInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<MetaFieldValuesOnConflict>;
}

/** on_conflict condition type for table "meta_field_values" */
export interface MetaFieldValuesOnConflict {
  constraint: MetaFieldValuesConstraint;
  update_columns?: Array<MetaFieldValuesUpdateColumn>;
  where?: Maybe<MetaFieldValuesBoolExp>;
}

/** Ordering options when selecting data from "meta_field_values". */
export interface MetaFieldValuesOrderBy {
  asset_id?: Maybe<OrderBy>;
  boolean_values_aggregate?: Maybe<MetaBooleanValuesAggregateOrderBy>;
  date_values_aggregate?: Maybe<MetaDateValuesAggregateOrderBy>;
  field?: Maybe<AssetMetadataFieldsOrderBy>;
  field_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  meta_permissions_aggregate?: Maybe<PermissionMetaPermissionsAggregateOrderBy>;
  number_values_aggregate?: Maybe<MetaNumberValuesAggregateOrderBy>;
  text_values_aggregate?: Maybe<MetaTextValuesAggregateOrderBy>;
}

/** primary key columns input for table: meta_field_values */
export interface MetaFieldValuesPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "meta_field_values" */
export enum MetaFieldValuesSelectColumn {
  /** column name */
  asset_id = "asset_id",
  /** column name */
  field_id = "field_id",
  /** column name */
  id = "id",
}

/** input type for updating data in table "meta_field_values" */
export interface MetaFieldValuesSetInput {
  asset_id?: Maybe<Scalars["uuid"]>;
  field_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
}

/** Streaming cursor of the table "meta_field_values" */
export interface MetaFieldValuesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: MetaFieldValuesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface MetaFieldValuesStreamCursorValueInput {
  asset_id?: Maybe<Scalars["uuid"]>;
  field_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
}

/** update columns of table "meta_field_values" */
export enum MetaFieldValuesUpdateColumn {
  /** column name */
  asset_id = "asset_id",
  /** column name */
  field_id = "field_id",
  /** column name */
  id = "id",
}

export interface MetaFieldValuesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<MetaFieldValuesSetInput>;
  /** filter the rows which have to be updated */
  where: MetaFieldValuesBoolExp;
}

/** columns and relationships of "meta_number_values" */
export interface MetaNumberValues {
  /** An object relationship */
  field_values: MetaFieldValues;
  field_values_id: Scalars["uuid"];
  id: Scalars["uuid"];
  value: Scalars["float8"];
}

/** order by aggregate values of table "meta_number_values" */
export interface MetaNumberValuesAggregateOrderBy {
  avg?: Maybe<MetaNumberValuesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<MetaNumberValuesMaxOrderBy>;
  min?: Maybe<MetaNumberValuesMinOrderBy>;
  stddev?: Maybe<MetaNumberValuesStddevOrderBy>;
  stddev_pop?: Maybe<MetaNumberValuesStddevPopOrderBy>;
  stddev_samp?: Maybe<MetaNumberValuesStddevSampOrderBy>;
  sum?: Maybe<MetaNumberValuesSumOrderBy>;
  var_pop?: Maybe<MetaNumberValuesVarPopOrderBy>;
  var_samp?: Maybe<MetaNumberValuesVarSampOrderBy>;
  variance?: Maybe<MetaNumberValuesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "meta_number_values" */
export interface MetaNumberValuesArrRelInsertInput {
  data: Array<MetaNumberValuesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<MetaNumberValuesOnConflict>;
}

/** order by avg() on columns of table "meta_number_values" */
export interface MetaNumberValuesAvgOrderBy {
  value?: Maybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "meta_number_values". All fields are combined with a logical 'AND'. */
export interface MetaNumberValuesBoolExp {
  _and?: Maybe<Array<MetaNumberValuesBoolExp>>;
  _not?: Maybe<MetaNumberValuesBoolExp>;
  _or?: Maybe<Array<MetaNumberValuesBoolExp>>;
  field_values?: Maybe<MetaFieldValuesBoolExp>;
  field_values_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  value?: Maybe<Float8ComparisonExp>;
}

/** unique or primary key constraints on table "meta_number_values" */
export enum MetaNumberValuesConstraint {
  /** unique or primary key constraint on columns "id" */
  meta_number_values_pkey = "meta_number_values_pkey",
}

/** input type for incrementing numeric columns in table "meta_number_values" */
export interface MetaNumberValuesIncInput {
  value?: Maybe<Scalars["float8"]>;
}

/** input type for inserting data into table "meta_number_values" */
export interface MetaNumberValuesInsertInput {
  field_values?: Maybe<MetaFieldValuesObjRelInsertInput>;
  field_values_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["float8"]>;
}

/** order by max() on columns of table "meta_number_values" */
export interface MetaNumberValuesMaxOrderBy {
  field_values_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** order by min() on columns of table "meta_number_values" */
export interface MetaNumberValuesMinOrderBy {
  field_values_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** response of any mutation on the table "meta_number_values" */
export interface MetaNumberValuesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetaNumberValues>;
}

/** on_conflict condition type for table "meta_number_values" */
export interface MetaNumberValuesOnConflict {
  constraint: MetaNumberValuesConstraint;
  update_columns?: Array<MetaNumberValuesUpdateColumn>;
  where?: Maybe<MetaNumberValuesBoolExp>;
}

/** Ordering options when selecting data from "meta_number_values". */
export interface MetaNumberValuesOrderBy {
  field_values?: Maybe<MetaFieldValuesOrderBy>;
  field_values_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** primary key columns input for table: meta_number_values */
export interface MetaNumberValuesPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "meta_number_values" */
export enum MetaNumberValuesSelectColumn {
  /** column name */
  field_values_id = "field_values_id",
  /** column name */
  id = "id",
  /** column name */
  value = "value",
}

/** input type for updating data in table "meta_number_values" */
export interface MetaNumberValuesSetInput {
  field_values_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["float8"]>;
}

/** order by stddev() on columns of table "meta_number_values" */
export interface MetaNumberValuesStddevOrderBy {
  value?: Maybe<OrderBy>;
}

/** order by stddev_pop() on columns of table "meta_number_values" */
export interface MetaNumberValuesStddevPopOrderBy {
  value?: Maybe<OrderBy>;
}

/** order by stddev_samp() on columns of table "meta_number_values" */
export interface MetaNumberValuesStddevSampOrderBy {
  value?: Maybe<OrderBy>;
}

/** Streaming cursor of the table "meta_number_values" */
export interface MetaNumberValuesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: MetaNumberValuesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface MetaNumberValuesStreamCursorValueInput {
  field_values_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["float8"]>;
}

/** order by sum() on columns of table "meta_number_values" */
export interface MetaNumberValuesSumOrderBy {
  value?: Maybe<OrderBy>;
}

/** update columns of table "meta_number_values" */
export enum MetaNumberValuesUpdateColumn {
  /** column name */
  field_values_id = "field_values_id",
  /** column name */
  id = "id",
  /** column name */
  value = "value",
}

export interface MetaNumberValuesUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<MetaNumberValuesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<MetaNumberValuesSetInput>;
  /** filter the rows which have to be updated */
  where: MetaNumberValuesBoolExp;
}

/** order by var_pop() on columns of table "meta_number_values" */
export interface MetaNumberValuesVarPopOrderBy {
  value?: Maybe<OrderBy>;
}

/** order by var_samp() on columns of table "meta_number_values" */
export interface MetaNumberValuesVarSampOrderBy {
  value?: Maybe<OrderBy>;
}

/** order by variance() on columns of table "meta_number_values" */
export interface MetaNumberValuesVarianceOrderBy {
  value?: Maybe<OrderBy>;
}

/** columns and relationships of "meta_text_values" */
export interface MetaTextValues {
  /** An object relationship */
  field_values: MetaFieldValues;
  field_values_id: Scalars["uuid"];
  id: Scalars["uuid"];
  value: Scalars["String"];
}

/** order by aggregate values of table "meta_text_values" */
export interface MetaTextValuesAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<MetaTextValuesMaxOrderBy>;
  min?: Maybe<MetaTextValuesMinOrderBy>;
}

/** input type for inserting array relation for remote table "meta_text_values" */
export interface MetaTextValuesArrRelInsertInput {
  data: Array<MetaTextValuesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<MetaTextValuesOnConflict>;
}

/** Boolean expression to filter rows from the table "meta_text_values". All fields are combined with a logical 'AND'. */
export interface MetaTextValuesBoolExp {
  _and?: Maybe<Array<MetaTextValuesBoolExp>>;
  _not?: Maybe<MetaTextValuesBoolExp>;
  _or?: Maybe<Array<MetaTextValuesBoolExp>>;
  field_values?: Maybe<MetaFieldValuesBoolExp>;
  field_values_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  value?: Maybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "meta_text_values" */
export enum MetaTextValuesConstraint {
  /** unique or primary key constraint on columns "id" */
  meta_text_values_pkey = "meta_text_values_pkey",
}

/** input type for inserting data into table "meta_text_values" */
export interface MetaTextValuesInsertInput {
  field_values?: Maybe<MetaFieldValuesObjRelInsertInput>;
  field_values_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["String"]>;
}

/** order by max() on columns of table "meta_text_values" */
export interface MetaTextValuesMaxOrderBy {
  field_values_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** order by min() on columns of table "meta_text_values" */
export interface MetaTextValuesMinOrderBy {
  field_values_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** response of any mutation on the table "meta_text_values" */
export interface MetaTextValuesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<MetaTextValues>;
}

/** on_conflict condition type for table "meta_text_values" */
export interface MetaTextValuesOnConflict {
  constraint: MetaTextValuesConstraint;
  update_columns?: Array<MetaTextValuesUpdateColumn>;
  where?: Maybe<MetaTextValuesBoolExp>;
}

/** Ordering options when selecting data from "meta_text_values". */
export interface MetaTextValuesOrderBy {
  field_values?: Maybe<MetaFieldValuesOrderBy>;
  field_values_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** primary key columns input for table: meta_text_values */
export interface MetaTextValuesPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "meta_text_values" */
export enum MetaTextValuesSelectColumn {
  /** column name */
  field_values_id = "field_values_id",
  /** column name */
  id = "id",
  /** column name */
  value = "value",
}

/** input type for updating data in table "meta_text_values" */
export interface MetaTextValuesSetInput {
  field_values_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["String"]>;
}

/** Streaming cursor of the table "meta_text_values" */
export interface MetaTextValuesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: MetaTextValuesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface MetaTextValuesStreamCursorValueInput {
  field_values_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  value?: Maybe<Scalars["String"]>;
}

/** update columns of table "meta_text_values" */
export enum MetaTextValuesUpdateColumn {
  /** column name */
  field_values_id = "field_values_id",
  /** column name */
  id = "id",
  /** column name */
  value = "value",
}

export interface MetaTextValuesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<MetaTextValuesSetInput>;
  /** filter the rows which have to be updated */
  where: MetaTextValuesBoolExp;
}

/** mutation root */
export interface MutationRoot {
  /** delete data from the table: "asset_metadata_fields" */
  delete_asset_metadata_fields?: Maybe<AssetMetadataFieldsMutationResponse>;
  /** delete single row from the table: "asset_metadata_fields" */
  delete_asset_metadata_fields_by_pk?: Maybe<AssetMetadataFields>;
  /** delete data from the table: "asset_metadata_values" */
  delete_asset_metadata_values?: Maybe<AssetMetadataValuesMutationResponse>;
  /** delete single row from the table: "asset_metadata_values" */
  delete_asset_metadata_values_by_pk?: Maybe<AssetMetadataValues>;
  /** delete data from the table: "cms.content_rows" */
  delete_cms_content_rows?: Maybe<CmsContentRowsMutationResponse>;
  /** delete single row from the table: "cms.content_rows" */
  delete_cms_content_rows_by_pk?: Maybe<CmsContentRows>;
  /** delete data from the table: "cms.publications" */
  delete_cms_publications?: Maybe<CmsPublicationsMutationResponse>;
  /** delete single row from the table: "cms.publications" */
  delete_cms_publications_by_pk?: Maybe<CmsPublications>;
  /** delete data from the table: "cms.resources" */
  delete_cms_resources?: Maybe<CmsResourcesMutationResponse>;
  /** delete single row from the table: "cms.resources" */
  delete_cms_resources_by_pk?: Maybe<CmsResources>;
  /** delete data from the table: "fe.link_share" */
  delete_fe_link_share?: Maybe<FeLinkShareMutationResponse>;
  /** delete single row from the table: "fe.link_share" */
  delete_fe_link_share_by_pk?: Maybe<FeLinkShare>;
  /** delete data from the table: "folders" */
  delete_folders?: Maybe<FoldersMutationResponse>;
  /** delete single row from the table: "folders" */
  delete_folders_by_pk?: Maybe<Folders>;
  /** delete data from the table: "image_thumbnails" */
  delete_image_thumbnails?: Maybe<ImageThumbnailsMutationResponse>;
  /** delete single row from the table: "image_thumbnails" */
  delete_image_thumbnails_by_pk?: Maybe<ImageThumbnails>;
  /** delete data from the table: "media_assets" */
  delete_media_assets?: Maybe<MediaAssetsMutationResponse>;
  /** delete single row from the table: "media_assets" */
  delete_media_assets_by_pk?: Maybe<MediaAssets>;
  /** delete data from the table: "media.live_streams" */
  delete_media_live_streams?: Maybe<MediaLiveStreamsMutationResponse>;
  /** delete single row from the table: "media.live_streams" */
  delete_media_live_streams_by_pk?: Maybe<MediaLiveStreams>;
  /** delete data from the table: "meta_boolean_values" */
  delete_meta_boolean_values?: Maybe<MetaBooleanValuesMutationResponse>;
  /** delete single row from the table: "meta_boolean_values" */
  delete_meta_boolean_values_by_pk?: Maybe<MetaBooleanValues>;
  /** delete data from the table: "meta_date_values" */
  delete_meta_date_values?: Maybe<MetaDateValuesMutationResponse>;
  /** delete single row from the table: "meta_date_values" */
  delete_meta_date_values_by_pk?: Maybe<MetaDateValues>;
  /** delete data from the table: "meta_field_values" */
  delete_meta_field_values?: Maybe<MetaFieldValuesMutationResponse>;
  /** delete single row from the table: "meta_field_values" */
  delete_meta_field_values_by_pk?: Maybe<MetaFieldValues>;
  /** delete data from the table: "meta_number_values" */
  delete_meta_number_values?: Maybe<MetaNumberValuesMutationResponse>;
  /** delete single row from the table: "meta_number_values" */
  delete_meta_number_values_by_pk?: Maybe<MetaNumberValues>;
  /** delete data from the table: "meta_text_values" */
  delete_meta_text_values?: Maybe<MetaTextValuesMutationResponse>;
  /** delete single row from the table: "meta_text_values" */
  delete_meta_text_values_by_pk?: Maybe<MetaTextValues>;
  /** delete data from the table: "org_member_invites" */
  delete_org_member_invites?: Maybe<OrgMemberInvitesMutationResponse>;
  /** delete single row from the table: "org_member_invites" */
  delete_org_member_invites_by_pk?: Maybe<OrgMemberInvites>;
  /** delete data from the table: "org_members" */
  delete_org_members?: Maybe<OrgMembersMutationResponse>;
  /** delete single row from the table: "org_members" */
  delete_org_members_by_pk?: Maybe<OrgMembers>;
  /** delete data from the table: "permission.org_member_roles" */
  delete_permission_org_member_roles?: Maybe<PermissionOrgMemberRolesMutationResponse>;
  /** delete single row from the table: "permission.org_member_roles" */
  delete_permission_org_member_roles_by_pk?: Maybe<PermissionOrgMemberRoles>;
  /** delete data from the table: "permission.role_folder_scopes" */
  delete_permission_role_folder_scopes?: Maybe<PermissionRoleFolderScopesMutationResponse>;
  /** delete single row from the table: "permission.role_folder_scopes" */
  delete_permission_role_folder_scopes_by_pk?: Maybe<PermissionRoleFolderScopes>;
  /** delete data from the table: "permission.role_meta_scopes" */
  delete_permission_role_meta_scopes?: Maybe<PermissionRoleMetaScopesMutationResponse>;
  /** delete single row from the table: "permission.role_meta_scopes" */
  delete_permission_role_meta_scopes_by_pk?: Maybe<PermissionRoleMetaScopes>;
  /** delete data from the table: "permission.roles" */
  delete_permission_roles?: Maybe<PermissionRolesMutationResponse>;
  /** delete single row from the table: "permission.roles" */
  delete_permission_roles_by_pk?: Maybe<PermissionRoles>;
  /** delete data from the table: "permission.user_group_members" */
  delete_permission_user_group_members?: Maybe<PermissionUserGroupMembersMutationResponse>;
  /** delete single row from the table: "permission.user_group_members" */
  delete_permission_user_group_members_by_pk?: Maybe<PermissionUserGroupMembers>;
  /** delete data from the table: "permission.user_group_roles" */
  delete_permission_user_group_roles?: Maybe<PermissionUserGroupRolesMutationResponse>;
  /** delete single row from the table: "permission.user_group_roles" */
  delete_permission_user_group_roles_by_pk?: Maybe<PermissionUserGroupRoles>;
  /** delete data from the table: "permission.user_groups" */
  delete_permission_user_groups?: Maybe<PermissionUserGroupsMutationResponse>;
  /** delete single row from the table: "permission.user_groups" */
  delete_permission_user_groups_by_pk?: Maybe<PermissionUserGroups>;
  /** delete data from the table: "projects" */
  delete_projects?: Maybe<ProjectsMutationResponse>;
  /** delete single row from the table: "projects" */
  delete_projects_by_pk?: Maybe<Projects>;
  /** delete data from the table: "ticketing.access_codes" */
  delete_ticketing_access_codes?: Maybe<TicketingAccessCodesMutationResponse>;
  /** delete single row from the table: "ticketing.access_codes" */
  delete_ticketing_access_codes_by_pk?: Maybe<TicketingAccessCodes>;
  /** delete data from the table: "ticketing.ticket_options" */
  delete_ticketing_ticket_options?: Maybe<TicketingTicketOptionsMutationResponse>;
  /** delete single row from the table: "ticketing.ticket_options" */
  delete_ticketing_ticket_options_by_pk?: Maybe<TicketingTicketOptions>;
  /** insert data into the table: "archive_files" */
  insert_archive_files?: Maybe<ArchiveFilesMutationResponse>;
  /** insert data into the table: "archive_jobs" */
  insert_archive_jobs?: Maybe<ArchiveJobsMutationResponse>;
  /** insert a single row into the table: "archive_jobs" */
  insert_archive_jobs_one?: Maybe<ArchiveJobs>;
  /** insert data into the table: "asset_files" */
  insert_asset_files?: Maybe<AssetFilesMutationResponse>;
  /** insert a single row into the table: "asset_files" */
  insert_asset_files_one?: Maybe<AssetFiles>;
  /** insert data into the table: "asset_metadata_fields" */
  insert_asset_metadata_fields?: Maybe<AssetMetadataFieldsMutationResponse>;
  /** insert a single row into the table: "asset_metadata_fields" */
  insert_asset_metadata_fields_one?: Maybe<AssetMetadataFields>;
  /** insert data into the table: "asset_metadata_values" */
  insert_asset_metadata_values?: Maybe<AssetMetadataValuesMutationResponse>;
  /** insert a single row into the table: "asset_metadata_values" */
  insert_asset_metadata_values_one?: Maybe<AssetMetadataValues>;
  /** insert data into the table: "cms.content_rows" */
  insert_cms_content_rows?: Maybe<CmsContentRowsMutationResponse>;
  /** insert a single row into the table: "cms.content_rows" */
  insert_cms_content_rows_one?: Maybe<CmsContentRows>;
  /** insert data into the table: "cms.content_schemas" */
  insert_cms_content_schemas?: Maybe<CmsContentSchemasMutationResponse>;
  /** insert a single row into the table: "cms.content_schemas" */
  insert_cms_content_schemas_one?: Maybe<CmsContentSchemas>;
  /** insert data into the table: "cms.project_publications" */
  insert_cms_project_publications?: Maybe<CmsProjectPublicationsMutationResponse>;
  /** insert data into the table: "cms.projects" */
  insert_cms_projects?: Maybe<CmsProjectsMutationResponse>;
  /** insert a single row into the table: "cms.projects" */
  insert_cms_projects_one?: Maybe<CmsProjects>;
  /** insert data into the table: "cms.publications" */
  insert_cms_publications?: Maybe<CmsPublicationsMutationResponse>;
  /** insert a single row into the table: "cms.publications" */
  insert_cms_publications_one?: Maybe<CmsPublications>;
  /** insert data into the table: "cms.resources" */
  insert_cms_resources?: Maybe<CmsResourcesMutationResponse>;
  /** insert a single row into the table: "cms.resources" */
  insert_cms_resources_one?: Maybe<CmsResources>;
  /** insert data into the table: "fe.link_share" */
  insert_fe_link_share?: Maybe<FeLinkShareMutationResponse>;
  /** insert a single row into the table: "fe.link_share" */
  insert_fe_link_share_one?: Maybe<FeLinkShare>;
  /** insert data into the table: "folders" */
  insert_folders?: Maybe<FoldersMutationResponse>;
  /** insert a single row into the table: "folders" */
  insert_folders_one?: Maybe<Folders>;
  /** insert data into the table: "image_thumbnail_jobs" */
  insert_image_thumbnail_jobs?: Maybe<ImageThumbnailJobsMutationResponse>;
  /** insert a single row into the table: "image_thumbnail_jobs" */
  insert_image_thumbnail_jobs_one?: Maybe<ImageThumbnailJobs>;
  /** insert data into the table: "media_assets" */
  insert_media_assets?: Maybe<MediaAssetsMutationResponse>;
  /** insert a single row into the table: "media_assets" */
  insert_media_assets_one?: Maybe<MediaAssets>;
  /** insert data into the table: "media.live_streams" */
  insert_media_live_streams?: Maybe<MediaLiveStreamsMutationResponse>;
  /** insert a single row into the table: "media.live_streams" */
  insert_media_live_streams_one?: Maybe<MediaLiveStreams>;
  /** insert data into the table: "meta_boolean_values" */
  insert_meta_boolean_values?: Maybe<MetaBooleanValuesMutationResponse>;
  /** insert a single row into the table: "meta_boolean_values" */
  insert_meta_boolean_values_one?: Maybe<MetaBooleanValues>;
  /** insert data into the table: "meta_date_values" */
  insert_meta_date_values?: Maybe<MetaDateValuesMutationResponse>;
  /** insert a single row into the table: "meta_date_values" */
  insert_meta_date_values_one?: Maybe<MetaDateValues>;
  /** insert data into the table: "meta_field_values" */
  insert_meta_field_values?: Maybe<MetaFieldValuesMutationResponse>;
  /** insert a single row into the table: "meta_field_values" */
  insert_meta_field_values_one?: Maybe<MetaFieldValues>;
  /** insert data into the table: "meta_number_values" */
  insert_meta_number_values?: Maybe<MetaNumberValuesMutationResponse>;
  /** insert a single row into the table: "meta_number_values" */
  insert_meta_number_values_one?: Maybe<MetaNumberValues>;
  /** insert data into the table: "meta_text_values" */
  insert_meta_text_values?: Maybe<MetaTextValuesMutationResponse>;
  /** insert a single row into the table: "meta_text_values" */
  insert_meta_text_values_one?: Maybe<MetaTextValues>;
  /** insert data into the table: "org_member_invites" */
  insert_org_member_invites?: Maybe<OrgMemberInvitesMutationResponse>;
  /** insert a single row into the table: "org_member_invites" */
  insert_org_member_invites_one?: Maybe<OrgMemberInvites>;
  /** insert data into the table: "org_members" */
  insert_org_members?: Maybe<OrgMembersMutationResponse>;
  /** insert a single row into the table: "org_members" */
  insert_org_members_one?: Maybe<OrgMembers>;
  /** insert data into the table: "organizations" */
  insert_organizations?: Maybe<OrganizationsMutationResponse>;
  /** insert a single row into the table: "organizations" */
  insert_organizations_one?: Maybe<Organizations>;
  /** insert data into the table: "permission.org_member_roles" */
  insert_permission_org_member_roles?: Maybe<PermissionOrgMemberRolesMutationResponse>;
  /** insert a single row into the table: "permission.org_member_roles" */
  insert_permission_org_member_roles_one?: Maybe<PermissionOrgMemberRoles>;
  /** insert data into the table: "permission.role_folder_scopes" */
  insert_permission_role_folder_scopes?: Maybe<PermissionRoleFolderScopesMutationResponse>;
  /** insert a single row into the table: "permission.role_folder_scopes" */
  insert_permission_role_folder_scopes_one?: Maybe<PermissionRoleFolderScopes>;
  /** insert data into the table: "permission.role_meta_scopes" */
  insert_permission_role_meta_scopes?: Maybe<PermissionRoleMetaScopesMutationResponse>;
  /** insert a single row into the table: "permission.role_meta_scopes" */
  insert_permission_role_meta_scopes_one?: Maybe<PermissionRoleMetaScopes>;
  /** insert data into the table: "permission.roles" */
  insert_permission_roles?: Maybe<PermissionRolesMutationResponse>;
  /** insert a single row into the table: "permission.roles" */
  insert_permission_roles_one?: Maybe<PermissionRoles>;
  /** insert data into the table: "permission.user_group_members" */
  insert_permission_user_group_members?: Maybe<PermissionUserGroupMembersMutationResponse>;
  /** insert a single row into the table: "permission.user_group_members" */
  insert_permission_user_group_members_one?: Maybe<PermissionUserGroupMembers>;
  /** insert data into the table: "permission.user_group_roles" */
  insert_permission_user_group_roles?: Maybe<PermissionUserGroupRolesMutationResponse>;
  /** insert a single row into the table: "permission.user_group_roles" */
  insert_permission_user_group_roles_one?: Maybe<PermissionUserGroupRoles>;
  /** insert data into the table: "permission.user_groups" */
  insert_permission_user_groups?: Maybe<PermissionUserGroupsMutationResponse>;
  /** insert a single row into the table: "permission.user_groups" */
  insert_permission_user_groups_one?: Maybe<PermissionUserGroups>;
  /** insert data into the table: "projects" */
  insert_projects?: Maybe<ProjectsMutationResponse>;
  /** insert a single row into the table: "projects" */
  insert_projects_one?: Maybe<Projects>;
  /** insert data into the table: "thumbnail_profiles" */
  insert_thumbnail_profiles?: Maybe<ThumbnailProfilesMutationResponse>;
  /** insert a single row into the table: "thumbnail_profiles" */
  insert_thumbnail_profiles_one?: Maybe<ThumbnailProfiles>;
  /** insert data into the table: "ticketing.access_codes" */
  insert_ticketing_access_codes?: Maybe<TicketingAccessCodesMutationResponse>;
  /** insert a single row into the table: "ticketing.access_codes" */
  insert_ticketing_access_codes_one?: Maybe<TicketingAccessCodes>;
  /** insert data into the table: "ticketing.ticket_options" */
  insert_ticketing_ticket_options?: Maybe<TicketingTicketOptionsMutationResponse>;
  /** insert a single row into the table: "ticketing.ticket_options" */
  insert_ticketing_ticket_options_one?: Maybe<TicketingTicketOptions>;
  /** insert data into the table: "transcoder_jobs" */
  insert_transcoder_jobs?: Maybe<TranscoderJobsMutationResponse>;
  /** insert a single row into the table: "transcoder_jobs" */
  insert_transcoder_jobs_one?: Maybe<TranscoderJobs>;
  /** insert data into the table: "transcoder_profiles" */
  insert_transcoder_profiles?: Maybe<TranscoderProfilesMutationResponse>;
  /** insert a single row into the table: "transcoder_profiles" */
  insert_transcoder_profiles_one?: Maybe<TranscoderProfiles>;
  /** update data of the table: "asset_metadata_fields" */
  update_asset_metadata_fields?: Maybe<AssetMetadataFieldsMutationResponse>;
  /** update single row of the table: "asset_metadata_fields" */
  update_asset_metadata_fields_by_pk?: Maybe<AssetMetadataFields>;
  /** update multiples rows of table: "asset_metadata_fields" */
  update_asset_metadata_fields_many?: Maybe<
    Array<Maybe<AssetMetadataFieldsMutationResponse>>
  >;
  /** update data of the table: "asset_metadata_values" */
  update_asset_metadata_values?: Maybe<AssetMetadataValuesMutationResponse>;
  /** update single row of the table: "asset_metadata_values" */
  update_asset_metadata_values_by_pk?: Maybe<AssetMetadataValues>;
  /** update multiples rows of table: "asset_metadata_values" */
  update_asset_metadata_values_many?: Maybe<
    Array<Maybe<AssetMetadataValuesMutationResponse>>
  >;
  /** update data of the table: "cms.content_rows" */
  update_cms_content_rows?: Maybe<CmsContentRowsMutationResponse>;
  /** update single row of the table: "cms.content_rows" */
  update_cms_content_rows_by_pk?: Maybe<CmsContentRows>;
  /** update multiples rows of table: "cms.content_rows" */
  update_cms_content_rows_many?: Maybe<
    Array<Maybe<CmsContentRowsMutationResponse>>
  >;
  /** update data of the table: "cms.publications" */
  update_cms_publications?: Maybe<CmsPublicationsMutationResponse>;
  /** update single row of the table: "cms.publications" */
  update_cms_publications_by_pk?: Maybe<CmsPublications>;
  /** update multiples rows of table: "cms.publications" */
  update_cms_publications_many?: Maybe<
    Array<Maybe<CmsPublicationsMutationResponse>>
  >;
  /** update data of the table: "cms.resources" */
  update_cms_resources?: Maybe<CmsResourcesMutationResponse>;
  /** update single row of the table: "cms.resources" */
  update_cms_resources_by_pk?: Maybe<CmsResources>;
  /** update multiples rows of table: "cms.resources" */
  update_cms_resources_many?: Maybe<Array<Maybe<CmsResourcesMutationResponse>>>;
  /** update data of the table: "fe.link_share" */
  update_fe_link_share?: Maybe<FeLinkShareMutationResponse>;
  /** update single row of the table: "fe.link_share" */
  update_fe_link_share_by_pk?: Maybe<FeLinkShare>;
  /** update multiples rows of table: "fe.link_share" */
  update_fe_link_share_many?: Maybe<Array<Maybe<FeLinkShareMutationResponse>>>;
  /** update data of the table: "folders" */
  update_folders?: Maybe<FoldersMutationResponse>;
  /** update single row of the table: "folders" */
  update_folders_by_pk?: Maybe<Folders>;
  /** update multiples rows of table: "folders" */
  update_folders_many?: Maybe<Array<Maybe<FoldersMutationResponse>>>;
  /** update data of the table: "media_assets" */
  update_media_assets?: Maybe<MediaAssetsMutationResponse>;
  /** update single row of the table: "media_assets" */
  update_media_assets_by_pk?: Maybe<MediaAssets>;
  /** update multiples rows of table: "media_assets" */
  update_media_assets_many?: Maybe<Array<Maybe<MediaAssetsMutationResponse>>>;
  /** update data of the table: "media.live_streams" */
  update_media_live_streams?: Maybe<MediaLiveStreamsMutationResponse>;
  /** update single row of the table: "media.live_streams" */
  update_media_live_streams_by_pk?: Maybe<MediaLiveStreams>;
  /** update multiples rows of table: "media.live_streams" */
  update_media_live_streams_many?: Maybe<
    Array<Maybe<MediaLiveStreamsMutationResponse>>
  >;
  /** update data of the table: "meta_boolean_values" */
  update_meta_boolean_values?: Maybe<MetaBooleanValuesMutationResponse>;
  /** update single row of the table: "meta_boolean_values" */
  update_meta_boolean_values_by_pk?: Maybe<MetaBooleanValues>;
  /** update multiples rows of table: "meta_boolean_values" */
  update_meta_boolean_values_many?: Maybe<
    Array<Maybe<MetaBooleanValuesMutationResponse>>
  >;
  /** update data of the table: "meta_date_values" */
  update_meta_date_values?: Maybe<MetaDateValuesMutationResponse>;
  /** update single row of the table: "meta_date_values" */
  update_meta_date_values_by_pk?: Maybe<MetaDateValues>;
  /** update multiples rows of table: "meta_date_values" */
  update_meta_date_values_many?: Maybe<
    Array<Maybe<MetaDateValuesMutationResponse>>
  >;
  /** update data of the table: "meta_field_values" */
  update_meta_field_values?: Maybe<MetaFieldValuesMutationResponse>;
  /** update single row of the table: "meta_field_values" */
  update_meta_field_values_by_pk?: Maybe<MetaFieldValues>;
  /** update multiples rows of table: "meta_field_values" */
  update_meta_field_values_many?: Maybe<
    Array<Maybe<MetaFieldValuesMutationResponse>>
  >;
  /** update data of the table: "meta_number_values" */
  update_meta_number_values?: Maybe<MetaNumberValuesMutationResponse>;
  /** update single row of the table: "meta_number_values" */
  update_meta_number_values_by_pk?: Maybe<MetaNumberValues>;
  /** update multiples rows of table: "meta_number_values" */
  update_meta_number_values_many?: Maybe<
    Array<Maybe<MetaNumberValuesMutationResponse>>
  >;
  /** update data of the table: "meta_text_values" */
  update_meta_text_values?: Maybe<MetaTextValuesMutationResponse>;
  /** update single row of the table: "meta_text_values" */
  update_meta_text_values_by_pk?: Maybe<MetaTextValues>;
  /** update multiples rows of table: "meta_text_values" */
  update_meta_text_values_many?: Maybe<
    Array<Maybe<MetaTextValuesMutationResponse>>
  >;
  /** update data of the table: "organizations" */
  update_organizations?: Maybe<OrganizationsMutationResponse>;
  /** update single row of the table: "organizations" */
  update_organizations_by_pk?: Maybe<Organizations>;
  /** update multiples rows of table: "organizations" */
  update_organizations_many?: Maybe<
    Array<Maybe<OrganizationsMutationResponse>>
  >;
  /** update data of the table: "permission.org_member_roles" */
  update_permission_org_member_roles?: Maybe<PermissionOrgMemberRolesMutationResponse>;
  /** update single row of the table: "permission.org_member_roles" */
  update_permission_org_member_roles_by_pk?: Maybe<PermissionOrgMemberRoles>;
  /** update multiples rows of table: "permission.org_member_roles" */
  update_permission_org_member_roles_many?: Maybe<
    Array<Maybe<PermissionOrgMemberRolesMutationResponse>>
  >;
  /** update data of the table: "permission.role_folder_scopes" */
  update_permission_role_folder_scopes?: Maybe<PermissionRoleFolderScopesMutationResponse>;
  /** update single row of the table: "permission.role_folder_scopes" */
  update_permission_role_folder_scopes_by_pk?: Maybe<PermissionRoleFolderScopes>;
  /** update multiples rows of table: "permission.role_folder_scopes" */
  update_permission_role_folder_scopes_many?: Maybe<
    Array<Maybe<PermissionRoleFolderScopesMutationResponse>>
  >;
  /** update data of the table: "permission.role_meta_scopes" */
  update_permission_role_meta_scopes?: Maybe<PermissionRoleMetaScopesMutationResponse>;
  /** update single row of the table: "permission.role_meta_scopes" */
  update_permission_role_meta_scopes_by_pk?: Maybe<PermissionRoleMetaScopes>;
  /** update multiples rows of table: "permission.role_meta_scopes" */
  update_permission_role_meta_scopes_many?: Maybe<
    Array<Maybe<PermissionRoleMetaScopesMutationResponse>>
  >;
  /** update data of the table: "permission.roles" */
  update_permission_roles?: Maybe<PermissionRolesMutationResponse>;
  /** update single row of the table: "permission.roles" */
  update_permission_roles_by_pk?: Maybe<PermissionRoles>;
  /** update multiples rows of table: "permission.roles" */
  update_permission_roles_many?: Maybe<
    Array<Maybe<PermissionRolesMutationResponse>>
  >;
  /** update data of the table: "permission.user_group_members" */
  update_permission_user_group_members?: Maybe<PermissionUserGroupMembersMutationResponse>;
  /** update single row of the table: "permission.user_group_members" */
  update_permission_user_group_members_by_pk?: Maybe<PermissionUserGroupMembers>;
  /** update multiples rows of table: "permission.user_group_members" */
  update_permission_user_group_members_many?: Maybe<
    Array<Maybe<PermissionUserGroupMembersMutationResponse>>
  >;
  /** update data of the table: "permission.user_group_roles" */
  update_permission_user_group_roles?: Maybe<PermissionUserGroupRolesMutationResponse>;
  /** update single row of the table: "permission.user_group_roles" */
  update_permission_user_group_roles_by_pk?: Maybe<PermissionUserGroupRoles>;
  /** update multiples rows of table: "permission.user_group_roles" */
  update_permission_user_group_roles_many?: Maybe<
    Array<Maybe<PermissionUserGroupRolesMutationResponse>>
  >;
  /** update data of the table: "permission.user_groups" */
  update_permission_user_groups?: Maybe<PermissionUserGroupsMutationResponse>;
  /** update single row of the table: "permission.user_groups" */
  update_permission_user_groups_by_pk?: Maybe<PermissionUserGroups>;
  /** update multiples rows of table: "permission.user_groups" */
  update_permission_user_groups_many?: Maybe<
    Array<Maybe<PermissionUserGroupsMutationResponse>>
  >;
  /** update data of the table: "ticketing.access_codes" */
  update_ticketing_access_codes?: Maybe<TicketingAccessCodesMutationResponse>;
  /** update single row of the table: "ticketing.access_codes" */
  update_ticketing_access_codes_by_pk?: Maybe<TicketingAccessCodes>;
  /** update multiples rows of table: "ticketing.access_codes" */
  update_ticketing_access_codes_many?: Maybe<
    Array<Maybe<TicketingAccessCodesMutationResponse>>
  >;
  /** update data of the table: "ticketing.ticket_options" */
  update_ticketing_ticket_options?: Maybe<TicketingTicketOptionsMutationResponse>;
  /** update single row of the table: "ticketing.ticket_options" */
  update_ticketing_ticket_options_by_pk?: Maybe<TicketingTicketOptions>;
  /** update multiples rows of table: "ticketing.ticket_options" */
  update_ticketing_ticket_options_many?: Maybe<
    Array<Maybe<TicketingTicketOptionsMutationResponse>>
  >;
}

/** mutation root */
export interface MutationRootDeleteAssetMetadataFieldsArgs {
  where: AssetMetadataFieldsBoolExp;
}

/** mutation root */
export interface MutationRootDeleteAssetMetadataFieldsByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteAssetMetadataValuesArgs {
  where: AssetMetadataValuesBoolExp;
}

/** mutation root */
export interface MutationRootDeleteAssetMetadataValuesByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteCmsContentRowsArgs {
  where: CmsContentRowsBoolExp;
}

/** mutation root */
export interface MutationRootDeleteCmsContentRowsByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteCmsPublicationsArgs {
  where: CmsPublicationsBoolExp;
}

/** mutation root */
export interface MutationRootDeleteCmsPublicationsByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteCmsResourcesArgs {
  where: CmsResourcesBoolExp;
}

/** mutation root */
export interface MutationRootDeleteCmsResourcesByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteFeLinkShareArgs {
  where: FeLinkShareBoolExp;
}

/** mutation root */
export interface MutationRootDeleteFeLinkShareByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteFoldersArgs {
  where: FoldersBoolExp;
}

/** mutation root */
export interface MutationRootDeleteFoldersByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteImageThumbnailsArgs {
  where: ImageThumbnailsBoolExp;
}

/** mutation root */
export interface MutationRootDeleteImageThumbnailsByPkArgs {
  thumbnail_file_id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteMediaAssetsArgs {
  where: MediaAssetsBoolExp;
}

/** mutation root */
export interface MutationRootDeleteMediaAssetsByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteMediaLiveStreamsArgs {
  where: MediaLiveStreamsBoolExp;
}

/** mutation root */
export interface MutationRootDeleteMediaLiveStreamsByPkArgs {
  stream_key: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteMetaBooleanValuesArgs {
  where: MetaBooleanValuesBoolExp;
}

/** mutation root */
export interface MutationRootDeleteMetaBooleanValuesByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteMetaDateValuesArgs {
  where: MetaDateValuesBoolExp;
}

/** mutation root */
export interface MutationRootDeleteMetaDateValuesByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteMetaFieldValuesArgs {
  where: MetaFieldValuesBoolExp;
}

/** mutation root */
export interface MutationRootDeleteMetaFieldValuesByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteMetaNumberValuesArgs {
  where: MetaNumberValuesBoolExp;
}

/** mutation root */
export interface MutationRootDeleteMetaNumberValuesByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteMetaTextValuesArgs {
  where: MetaTextValuesBoolExp;
}

/** mutation root */
export interface MutationRootDeleteMetaTextValuesByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteOrgMemberInvitesArgs {
  where: OrgMemberInvitesBoolExp;
}

/** mutation root */
export interface MutationRootDeleteOrgMemberInvitesByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteOrgMembersArgs {
  where: OrgMembersBoolExp;
}

/** mutation root */
export interface MutationRootDeleteOrgMembersByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeletePermissionOrgMemberRolesArgs {
  where: PermissionOrgMemberRolesBoolExp;
}

/** mutation root */
export interface MutationRootDeletePermissionOrgMemberRolesByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeletePermissionRoleFolderScopesArgs {
  where: PermissionRoleFolderScopesBoolExp;
}

/** mutation root */
export interface MutationRootDeletePermissionRoleFolderScopesByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeletePermissionRoleMetaScopesArgs {
  where: PermissionRoleMetaScopesBoolExp;
}

/** mutation root */
export interface MutationRootDeletePermissionRoleMetaScopesByPkArgs {
  field_id: Scalars["uuid"];
  role_id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeletePermissionRolesArgs {
  where: PermissionRolesBoolExp;
}

/** mutation root */
export interface MutationRootDeletePermissionRolesByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeletePermissionUserGroupMembersArgs {
  where: PermissionUserGroupMembersBoolExp;
}

/** mutation root */
export interface MutationRootDeletePermissionUserGroupMembersByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeletePermissionUserGroupRolesArgs {
  where: PermissionUserGroupRolesBoolExp;
}

/** mutation root */
export interface MutationRootDeletePermissionUserGroupRolesByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeletePermissionUserGroupsArgs {
  where: PermissionUserGroupsBoolExp;
}

/** mutation root */
export interface MutationRootDeletePermissionUserGroupsByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteProjectsArgs {
  where: ProjectsBoolExp;
}

/** mutation root */
export interface MutationRootDeleteProjectsByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteTicketingAccessCodesArgs {
  where: TicketingAccessCodesBoolExp;
}

/** mutation root */
export interface MutationRootDeleteTicketingAccessCodesByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootDeleteTicketingTicketOptionsArgs {
  where: TicketingTicketOptionsBoolExp;
}

/** mutation root */
export interface MutationRootDeleteTicketingTicketOptionsByPkArgs {
  id: Scalars["uuid"];
}

/** mutation root */
export interface MutationRootInsertArchiveFilesArgs {
  objects: Array<ArchiveFilesInsertInput>;
  on_conflict?: Maybe<ArchiveFilesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertArchiveJobsArgs {
  objects: Array<ArchiveJobsInsertInput>;
  on_conflict?: Maybe<ArchiveJobsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertArchiveJobsOneArgs {
  object: ArchiveJobsInsertInput;
  on_conflict?: Maybe<ArchiveJobsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertAssetFilesArgs {
  objects: Array<AssetFilesInsertInput>;
  on_conflict?: Maybe<AssetFilesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertAssetFilesOneArgs {
  object: AssetFilesInsertInput;
  on_conflict?: Maybe<AssetFilesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertAssetMetadataFieldsArgs {
  objects: Array<AssetMetadataFieldsInsertInput>;
  on_conflict?: Maybe<AssetMetadataFieldsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertAssetMetadataFieldsOneArgs {
  object: AssetMetadataFieldsInsertInput;
  on_conflict?: Maybe<AssetMetadataFieldsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertAssetMetadataValuesArgs {
  objects: Array<AssetMetadataValuesInsertInput>;
  on_conflict?: Maybe<AssetMetadataValuesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertAssetMetadataValuesOneArgs {
  object: AssetMetadataValuesInsertInput;
  on_conflict?: Maybe<AssetMetadataValuesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertCmsContentRowsArgs {
  objects: Array<CmsContentRowsInsertInput>;
  on_conflict?: Maybe<CmsContentRowsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertCmsContentRowsOneArgs {
  object: CmsContentRowsInsertInput;
  on_conflict?: Maybe<CmsContentRowsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertCmsContentSchemasArgs {
  objects: Array<CmsContentSchemasInsertInput>;
  on_conflict?: Maybe<CmsContentSchemasOnConflict>;
}

/** mutation root */
export interface MutationRootInsertCmsContentSchemasOneArgs {
  object: CmsContentSchemasInsertInput;
  on_conflict?: Maybe<CmsContentSchemasOnConflict>;
}

/** mutation root */
export interface MutationRootInsertCmsProjectPublicationsArgs {
  objects: Array<CmsProjectPublicationsInsertInput>;
  on_conflict?: Maybe<CmsProjectPublicationsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertCmsProjectsArgs {
  objects: Array<CmsProjectsInsertInput>;
  on_conflict?: Maybe<CmsProjectsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertCmsProjectsOneArgs {
  object: CmsProjectsInsertInput;
  on_conflict?: Maybe<CmsProjectsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertCmsPublicationsArgs {
  objects: Array<CmsPublicationsInsertInput>;
  on_conflict?: Maybe<CmsPublicationsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertCmsPublicationsOneArgs {
  object: CmsPublicationsInsertInput;
  on_conflict?: Maybe<CmsPublicationsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertCmsResourcesArgs {
  objects: Array<CmsResourcesInsertInput>;
  on_conflict?: Maybe<CmsResourcesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertCmsResourcesOneArgs {
  object: CmsResourcesInsertInput;
  on_conflict?: Maybe<CmsResourcesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertFeLinkShareArgs {
  objects: Array<FeLinkShareInsertInput>;
  on_conflict?: Maybe<FeLinkShareOnConflict>;
}

/** mutation root */
export interface MutationRootInsertFeLinkShareOneArgs {
  object: FeLinkShareInsertInput;
  on_conflict?: Maybe<FeLinkShareOnConflict>;
}

/** mutation root */
export interface MutationRootInsertFoldersArgs {
  objects: Array<FoldersInsertInput>;
  on_conflict?: Maybe<FoldersOnConflict>;
}

/** mutation root */
export interface MutationRootInsertFoldersOneArgs {
  object: FoldersInsertInput;
  on_conflict?: Maybe<FoldersOnConflict>;
}

/** mutation root */
export interface MutationRootInsertImageThumbnailJobsArgs {
  objects: Array<ImageThumbnailJobsInsertInput>;
  on_conflict?: Maybe<ImageThumbnailJobsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertImageThumbnailJobsOneArgs {
  object: ImageThumbnailJobsInsertInput;
  on_conflict?: Maybe<ImageThumbnailJobsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertMediaAssetsArgs {
  objects: Array<MediaAssetsInsertInput>;
  on_conflict?: Maybe<MediaAssetsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertMediaAssetsOneArgs {
  object: MediaAssetsInsertInput;
  on_conflict?: Maybe<MediaAssetsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertMediaLiveStreamsArgs {
  objects: Array<MediaLiveStreamsInsertInput>;
  on_conflict?: Maybe<MediaLiveStreamsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertMediaLiveStreamsOneArgs {
  object: MediaLiveStreamsInsertInput;
  on_conflict?: Maybe<MediaLiveStreamsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertMetaBooleanValuesArgs {
  objects: Array<MetaBooleanValuesInsertInput>;
  on_conflict?: Maybe<MetaBooleanValuesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertMetaBooleanValuesOneArgs {
  object: MetaBooleanValuesInsertInput;
  on_conflict?: Maybe<MetaBooleanValuesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertMetaDateValuesArgs {
  objects: Array<MetaDateValuesInsertInput>;
  on_conflict?: Maybe<MetaDateValuesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertMetaDateValuesOneArgs {
  object: MetaDateValuesInsertInput;
  on_conflict?: Maybe<MetaDateValuesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertMetaFieldValuesArgs {
  objects: Array<MetaFieldValuesInsertInput>;
  on_conflict?: Maybe<MetaFieldValuesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertMetaFieldValuesOneArgs {
  object: MetaFieldValuesInsertInput;
  on_conflict?: Maybe<MetaFieldValuesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertMetaNumberValuesArgs {
  objects: Array<MetaNumberValuesInsertInput>;
  on_conflict?: Maybe<MetaNumberValuesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertMetaNumberValuesOneArgs {
  object: MetaNumberValuesInsertInput;
  on_conflict?: Maybe<MetaNumberValuesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertMetaTextValuesArgs {
  objects: Array<MetaTextValuesInsertInput>;
  on_conflict?: Maybe<MetaTextValuesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertMetaTextValuesOneArgs {
  object: MetaTextValuesInsertInput;
  on_conflict?: Maybe<MetaTextValuesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertOrgMemberInvitesArgs {
  objects: Array<OrgMemberInvitesInsertInput>;
  on_conflict?: Maybe<OrgMemberInvitesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertOrgMemberInvitesOneArgs {
  object: OrgMemberInvitesInsertInput;
  on_conflict?: Maybe<OrgMemberInvitesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertOrgMembersArgs {
  objects: Array<OrgMembersInsertInput>;
  on_conflict?: Maybe<OrgMembersOnConflict>;
}

/** mutation root */
export interface MutationRootInsertOrgMembersOneArgs {
  object: OrgMembersInsertInput;
  on_conflict?: Maybe<OrgMembersOnConflict>;
}

/** mutation root */
export interface MutationRootInsertOrganizationsArgs {
  objects: Array<OrganizationsInsertInput>;
  on_conflict?: Maybe<OrganizationsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertOrganizationsOneArgs {
  object: OrganizationsInsertInput;
  on_conflict?: Maybe<OrganizationsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertPermissionOrgMemberRolesArgs {
  objects: Array<PermissionOrgMemberRolesInsertInput>;
  on_conflict?: Maybe<PermissionOrgMemberRolesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertPermissionOrgMemberRolesOneArgs {
  object: PermissionOrgMemberRolesInsertInput;
  on_conflict?: Maybe<PermissionOrgMemberRolesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertPermissionRoleFolderScopesArgs {
  objects: Array<PermissionRoleFolderScopesInsertInput>;
  on_conflict?: Maybe<PermissionRoleFolderScopesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertPermissionRoleFolderScopesOneArgs {
  object: PermissionRoleFolderScopesInsertInput;
  on_conflict?: Maybe<PermissionRoleFolderScopesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertPermissionRoleMetaScopesArgs {
  objects: Array<PermissionRoleMetaScopesInsertInput>;
  on_conflict?: Maybe<PermissionRoleMetaScopesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertPermissionRoleMetaScopesOneArgs {
  object: PermissionRoleMetaScopesInsertInput;
  on_conflict?: Maybe<PermissionRoleMetaScopesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertPermissionRolesArgs {
  objects: Array<PermissionRolesInsertInput>;
  on_conflict?: Maybe<PermissionRolesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertPermissionRolesOneArgs {
  object: PermissionRolesInsertInput;
  on_conflict?: Maybe<PermissionRolesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertPermissionUserGroupMembersArgs {
  objects: Array<PermissionUserGroupMembersInsertInput>;
  on_conflict?: Maybe<PermissionUserGroupMembersOnConflict>;
}

/** mutation root */
export interface MutationRootInsertPermissionUserGroupMembersOneArgs {
  object: PermissionUserGroupMembersInsertInput;
  on_conflict?: Maybe<PermissionUserGroupMembersOnConflict>;
}

/** mutation root */
export interface MutationRootInsertPermissionUserGroupRolesArgs {
  objects: Array<PermissionUserGroupRolesInsertInput>;
  on_conflict?: Maybe<PermissionUserGroupRolesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertPermissionUserGroupRolesOneArgs {
  object: PermissionUserGroupRolesInsertInput;
  on_conflict?: Maybe<PermissionUserGroupRolesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertPermissionUserGroupsArgs {
  objects: Array<PermissionUserGroupsInsertInput>;
  on_conflict?: Maybe<PermissionUserGroupsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertPermissionUserGroupsOneArgs {
  object: PermissionUserGroupsInsertInput;
  on_conflict?: Maybe<PermissionUserGroupsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertProjectsArgs {
  objects: Array<ProjectsInsertInput>;
  on_conflict?: Maybe<ProjectsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertProjectsOneArgs {
  object: ProjectsInsertInput;
  on_conflict?: Maybe<ProjectsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertThumbnailProfilesArgs {
  objects: Array<ThumbnailProfilesInsertInput>;
  on_conflict?: Maybe<ThumbnailProfilesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertThumbnailProfilesOneArgs {
  object: ThumbnailProfilesInsertInput;
  on_conflict?: Maybe<ThumbnailProfilesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertTicketingAccessCodesArgs {
  objects: Array<TicketingAccessCodesInsertInput>;
  on_conflict?: Maybe<TicketingAccessCodesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertTicketingAccessCodesOneArgs {
  object: TicketingAccessCodesInsertInput;
  on_conflict?: Maybe<TicketingAccessCodesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertTicketingTicketOptionsArgs {
  objects: Array<TicketingTicketOptionsInsertInput>;
  on_conflict?: Maybe<TicketingTicketOptionsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertTicketingTicketOptionsOneArgs {
  object: TicketingTicketOptionsInsertInput;
  on_conflict?: Maybe<TicketingTicketOptionsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertTranscoderJobsArgs {
  objects: Array<TranscoderJobsInsertInput>;
  on_conflict?: Maybe<TranscoderJobsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertTranscoderJobsOneArgs {
  object: TranscoderJobsInsertInput;
  on_conflict?: Maybe<TranscoderJobsOnConflict>;
}

/** mutation root */
export interface MutationRootInsertTranscoderProfilesArgs {
  objects: Array<TranscoderProfilesInsertInput>;
  on_conflict?: Maybe<TranscoderProfilesOnConflict>;
}

/** mutation root */
export interface MutationRootInsertTranscoderProfilesOneArgs {
  object: TranscoderProfilesInsertInput;
  on_conflict?: Maybe<TranscoderProfilesOnConflict>;
}

/** mutation root */
export interface MutationRootUpdateAssetMetadataFieldsArgs {
  _append?: Maybe<AssetMetadataFieldsAppendInput>;
  _delete_at_path?: Maybe<AssetMetadataFieldsDeleteAtPathInput>;
  _delete_elem?: Maybe<AssetMetadataFieldsDeleteElemInput>;
  _delete_key?: Maybe<AssetMetadataFieldsDeleteKeyInput>;
  _prepend?: Maybe<AssetMetadataFieldsPrependInput>;
  _set?: Maybe<AssetMetadataFieldsSetInput>;
  where: AssetMetadataFieldsBoolExp;
}

/** mutation root */
export interface MutationRootUpdateAssetMetadataFieldsByPkArgs {
  _append?: Maybe<AssetMetadataFieldsAppendInput>;
  _delete_at_path?: Maybe<AssetMetadataFieldsDeleteAtPathInput>;
  _delete_elem?: Maybe<AssetMetadataFieldsDeleteElemInput>;
  _delete_key?: Maybe<AssetMetadataFieldsDeleteKeyInput>;
  _prepend?: Maybe<AssetMetadataFieldsPrependInput>;
  _set?: Maybe<AssetMetadataFieldsSetInput>;
  pk_columns: AssetMetadataFieldsPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateAssetMetadataFieldsManyArgs {
  updates: Array<AssetMetadataFieldsUpdates>;
}

/** mutation root */
export interface MutationRootUpdateAssetMetadataValuesArgs {
  _inc?: Maybe<AssetMetadataValuesIncInput>;
  _set?: Maybe<AssetMetadataValuesSetInput>;
  where: AssetMetadataValuesBoolExp;
}

/** mutation root */
export interface MutationRootUpdateAssetMetadataValuesByPkArgs {
  _inc?: Maybe<AssetMetadataValuesIncInput>;
  _set?: Maybe<AssetMetadataValuesSetInput>;
  pk_columns: AssetMetadataValuesPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateAssetMetadataValuesManyArgs {
  updates: Array<AssetMetadataValuesUpdates>;
}

/** mutation root */
export interface MutationRootUpdateCmsContentRowsArgs {
  _append?: Maybe<CmsContentRowsAppendInput>;
  _delete_at_path?: Maybe<CmsContentRowsDeleteAtPathInput>;
  _delete_elem?: Maybe<CmsContentRowsDeleteElemInput>;
  _delete_key?: Maybe<CmsContentRowsDeleteKeyInput>;
  _prepend?: Maybe<CmsContentRowsPrependInput>;
  _set?: Maybe<CmsContentRowsSetInput>;
  where: CmsContentRowsBoolExp;
}

/** mutation root */
export interface MutationRootUpdateCmsContentRowsByPkArgs {
  _append?: Maybe<CmsContentRowsAppendInput>;
  _delete_at_path?: Maybe<CmsContentRowsDeleteAtPathInput>;
  _delete_elem?: Maybe<CmsContentRowsDeleteElemInput>;
  _delete_key?: Maybe<CmsContentRowsDeleteKeyInput>;
  _prepend?: Maybe<CmsContentRowsPrependInput>;
  _set?: Maybe<CmsContentRowsSetInput>;
  pk_columns: CmsContentRowsPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateCmsContentRowsManyArgs {
  updates: Array<CmsContentRowsUpdates>;
}

/** mutation root */
export interface MutationRootUpdateCmsPublicationsArgs {
  _append?: Maybe<CmsPublicationsAppendInput>;
  _delete_at_path?: Maybe<CmsPublicationsDeleteAtPathInput>;
  _delete_elem?: Maybe<CmsPublicationsDeleteElemInput>;
  _delete_key?: Maybe<CmsPublicationsDeleteKeyInput>;
  _prepend?: Maybe<CmsPublicationsPrependInput>;
  _set?: Maybe<CmsPublicationsSetInput>;
  where: CmsPublicationsBoolExp;
}

/** mutation root */
export interface MutationRootUpdateCmsPublicationsByPkArgs {
  _append?: Maybe<CmsPublicationsAppendInput>;
  _delete_at_path?: Maybe<CmsPublicationsDeleteAtPathInput>;
  _delete_elem?: Maybe<CmsPublicationsDeleteElemInput>;
  _delete_key?: Maybe<CmsPublicationsDeleteKeyInput>;
  _prepend?: Maybe<CmsPublicationsPrependInput>;
  _set?: Maybe<CmsPublicationsSetInput>;
  pk_columns: CmsPublicationsPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateCmsPublicationsManyArgs {
  updates: Array<CmsPublicationsUpdates>;
}

/** mutation root */
export interface MutationRootUpdateCmsResourcesArgs {
  _append?: Maybe<CmsResourcesAppendInput>;
  _delete_at_path?: Maybe<CmsResourcesDeleteAtPathInput>;
  _delete_elem?: Maybe<CmsResourcesDeleteElemInput>;
  _delete_key?: Maybe<CmsResourcesDeleteKeyInput>;
  _prepend?: Maybe<CmsResourcesPrependInput>;
  _set?: Maybe<CmsResourcesSetInput>;
  where: CmsResourcesBoolExp;
}

/** mutation root */
export interface MutationRootUpdateCmsResourcesByPkArgs {
  _append?: Maybe<CmsResourcesAppendInput>;
  _delete_at_path?: Maybe<CmsResourcesDeleteAtPathInput>;
  _delete_elem?: Maybe<CmsResourcesDeleteElemInput>;
  _delete_key?: Maybe<CmsResourcesDeleteKeyInput>;
  _prepend?: Maybe<CmsResourcesPrependInput>;
  _set?: Maybe<CmsResourcesSetInput>;
  pk_columns: CmsResourcesPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateCmsResourcesManyArgs {
  updates: Array<CmsResourcesUpdates>;
}

/** mutation root */
export interface MutationRootUpdateFeLinkShareArgs {
  _append?: Maybe<FeLinkShareAppendInput>;
  _delete_at_path?: Maybe<FeLinkShareDeleteAtPathInput>;
  _delete_elem?: Maybe<FeLinkShareDeleteElemInput>;
  _delete_key?: Maybe<FeLinkShareDeleteKeyInput>;
  _prepend?: Maybe<FeLinkSharePrependInput>;
  _set?: Maybe<FeLinkShareSetInput>;
  where: FeLinkShareBoolExp;
}

/** mutation root */
export interface MutationRootUpdateFeLinkShareByPkArgs {
  _append?: Maybe<FeLinkShareAppendInput>;
  _delete_at_path?: Maybe<FeLinkShareDeleteAtPathInput>;
  _delete_elem?: Maybe<FeLinkShareDeleteElemInput>;
  _delete_key?: Maybe<FeLinkShareDeleteKeyInput>;
  _prepend?: Maybe<FeLinkSharePrependInput>;
  _set?: Maybe<FeLinkShareSetInput>;
  pk_columns: FeLinkSharePkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateFeLinkShareManyArgs {
  updates: Array<FeLinkShareUpdates>;
}

/** mutation root */
export interface MutationRootUpdateFoldersArgs {
  _set?: Maybe<FoldersSetInput>;
  where: FoldersBoolExp;
}

/** mutation root */
export interface MutationRootUpdateFoldersByPkArgs {
  _set?: Maybe<FoldersSetInput>;
  pk_columns: FoldersPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateFoldersManyArgs {
  updates: Array<FoldersUpdates>;
}

/** mutation root */
export interface MutationRootUpdateMediaAssetsArgs {
  _set?: Maybe<MediaAssetsSetInput>;
  where: MediaAssetsBoolExp;
}

/** mutation root */
export interface MutationRootUpdateMediaAssetsByPkArgs {
  _set?: Maybe<MediaAssetsSetInput>;
  pk_columns: MediaAssetsPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateMediaAssetsManyArgs {
  updates: Array<MediaAssetsUpdates>;
}

/** mutation root */
export interface MutationRootUpdateMediaLiveStreamsArgs {
  _append?: Maybe<MediaLiveStreamsAppendInput>;
  _delete_at_path?: Maybe<MediaLiveStreamsDeleteAtPathInput>;
  _delete_elem?: Maybe<MediaLiveStreamsDeleteElemInput>;
  _delete_key?: Maybe<MediaLiveStreamsDeleteKeyInput>;
  _prepend?: Maybe<MediaLiveStreamsPrependInput>;
  _set?: Maybe<MediaLiveStreamsSetInput>;
  where: MediaLiveStreamsBoolExp;
}

/** mutation root */
export interface MutationRootUpdateMediaLiveStreamsByPkArgs {
  _append?: Maybe<MediaLiveStreamsAppendInput>;
  _delete_at_path?: Maybe<MediaLiveStreamsDeleteAtPathInput>;
  _delete_elem?: Maybe<MediaLiveStreamsDeleteElemInput>;
  _delete_key?: Maybe<MediaLiveStreamsDeleteKeyInput>;
  _prepend?: Maybe<MediaLiveStreamsPrependInput>;
  _set?: Maybe<MediaLiveStreamsSetInput>;
  pk_columns: MediaLiveStreamsPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateMediaLiveStreamsManyArgs {
  updates: Array<MediaLiveStreamsUpdates>;
}

/** mutation root */
export interface MutationRootUpdateMetaBooleanValuesArgs {
  _set?: Maybe<MetaBooleanValuesSetInput>;
  where: MetaBooleanValuesBoolExp;
}

/** mutation root */
export interface MutationRootUpdateMetaBooleanValuesByPkArgs {
  _set?: Maybe<MetaBooleanValuesSetInput>;
  pk_columns: MetaBooleanValuesPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateMetaBooleanValuesManyArgs {
  updates: Array<MetaBooleanValuesUpdates>;
}

/** mutation root */
export interface MutationRootUpdateMetaDateValuesArgs {
  _set?: Maybe<MetaDateValuesSetInput>;
  where: MetaDateValuesBoolExp;
}

/** mutation root */
export interface MutationRootUpdateMetaDateValuesByPkArgs {
  _set?: Maybe<MetaDateValuesSetInput>;
  pk_columns: MetaDateValuesPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateMetaDateValuesManyArgs {
  updates: Array<MetaDateValuesUpdates>;
}

/** mutation root */
export interface MutationRootUpdateMetaFieldValuesArgs {
  _set?: Maybe<MetaFieldValuesSetInput>;
  where: MetaFieldValuesBoolExp;
}

/** mutation root */
export interface MutationRootUpdateMetaFieldValuesByPkArgs {
  _set?: Maybe<MetaFieldValuesSetInput>;
  pk_columns: MetaFieldValuesPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateMetaFieldValuesManyArgs {
  updates: Array<MetaFieldValuesUpdates>;
}

/** mutation root */
export interface MutationRootUpdateMetaNumberValuesArgs {
  _inc?: Maybe<MetaNumberValuesIncInput>;
  _set?: Maybe<MetaNumberValuesSetInput>;
  where: MetaNumberValuesBoolExp;
}

/** mutation root */
export interface MutationRootUpdateMetaNumberValuesByPkArgs {
  _inc?: Maybe<MetaNumberValuesIncInput>;
  _set?: Maybe<MetaNumberValuesSetInput>;
  pk_columns: MetaNumberValuesPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateMetaNumberValuesManyArgs {
  updates: Array<MetaNumberValuesUpdates>;
}

/** mutation root */
export interface MutationRootUpdateMetaTextValuesArgs {
  _set?: Maybe<MetaTextValuesSetInput>;
  where: MetaTextValuesBoolExp;
}

/** mutation root */
export interface MutationRootUpdateMetaTextValuesByPkArgs {
  _set?: Maybe<MetaTextValuesSetInput>;
  pk_columns: MetaTextValuesPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateMetaTextValuesManyArgs {
  updates: Array<MetaTextValuesUpdates>;
}

/** mutation root */
export interface MutationRootUpdateOrganizationsArgs {
  _inc?: Maybe<OrganizationsIncInput>;
  _set?: Maybe<OrganizationsSetInput>;
  where: OrganizationsBoolExp;
}

/** mutation root */
export interface MutationRootUpdateOrganizationsByPkArgs {
  _inc?: Maybe<OrganizationsIncInput>;
  _set?: Maybe<OrganizationsSetInput>;
  pk_columns: OrganizationsPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateOrganizationsManyArgs {
  updates: Array<OrganizationsUpdates>;
}

/** mutation root */
export interface MutationRootUpdatePermissionOrgMemberRolesArgs {
  _set?: Maybe<PermissionOrgMemberRolesSetInput>;
  where: PermissionOrgMemberRolesBoolExp;
}

/** mutation root */
export interface MutationRootUpdatePermissionOrgMemberRolesByPkArgs {
  _set?: Maybe<PermissionOrgMemberRolesSetInput>;
  pk_columns: PermissionOrgMemberRolesPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdatePermissionOrgMemberRolesManyArgs {
  updates: Array<PermissionOrgMemberRolesUpdates>;
}

/** mutation root */
export interface MutationRootUpdatePermissionRoleFolderScopesArgs {
  _set?: Maybe<PermissionRoleFolderScopesSetInput>;
  where: PermissionRoleFolderScopesBoolExp;
}

/** mutation root */
export interface MutationRootUpdatePermissionRoleFolderScopesByPkArgs {
  _set?: Maybe<PermissionRoleFolderScopesSetInput>;
  pk_columns: PermissionRoleFolderScopesPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdatePermissionRoleFolderScopesManyArgs {
  updates: Array<PermissionRoleFolderScopesUpdates>;
}

/** mutation root */
export interface MutationRootUpdatePermissionRoleMetaScopesArgs {
  _append?: Maybe<PermissionRoleMetaScopesAppendInput>;
  _delete_at_path?: Maybe<PermissionRoleMetaScopesDeleteAtPathInput>;
  _delete_elem?: Maybe<PermissionRoleMetaScopesDeleteElemInput>;
  _delete_key?: Maybe<PermissionRoleMetaScopesDeleteKeyInput>;
  _prepend?: Maybe<PermissionRoleMetaScopesPrependInput>;
  _set?: Maybe<PermissionRoleMetaScopesSetInput>;
  where: PermissionRoleMetaScopesBoolExp;
}

/** mutation root */
export interface MutationRootUpdatePermissionRoleMetaScopesByPkArgs {
  _append?: Maybe<PermissionRoleMetaScopesAppendInput>;
  _delete_at_path?: Maybe<PermissionRoleMetaScopesDeleteAtPathInput>;
  _delete_elem?: Maybe<PermissionRoleMetaScopesDeleteElemInput>;
  _delete_key?: Maybe<PermissionRoleMetaScopesDeleteKeyInput>;
  _prepend?: Maybe<PermissionRoleMetaScopesPrependInput>;
  _set?: Maybe<PermissionRoleMetaScopesSetInput>;
  pk_columns: PermissionRoleMetaScopesPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdatePermissionRoleMetaScopesManyArgs {
  updates: Array<PermissionRoleMetaScopesUpdates>;
}

/** mutation root */
export interface MutationRootUpdatePermissionRolesArgs {
  _append?: Maybe<PermissionRolesAppendInput>;
  _delete_at_path?: Maybe<PermissionRolesDeleteAtPathInput>;
  _delete_elem?: Maybe<PermissionRolesDeleteElemInput>;
  _delete_key?: Maybe<PermissionRolesDeleteKeyInput>;
  _prepend?: Maybe<PermissionRolesPrependInput>;
  _set?: Maybe<PermissionRolesSetInput>;
  where: PermissionRolesBoolExp;
}

/** mutation root */
export interface MutationRootUpdatePermissionRolesByPkArgs {
  _append?: Maybe<PermissionRolesAppendInput>;
  _delete_at_path?: Maybe<PermissionRolesDeleteAtPathInput>;
  _delete_elem?: Maybe<PermissionRolesDeleteElemInput>;
  _delete_key?: Maybe<PermissionRolesDeleteKeyInput>;
  _prepend?: Maybe<PermissionRolesPrependInput>;
  _set?: Maybe<PermissionRolesSetInput>;
  pk_columns: PermissionRolesPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdatePermissionRolesManyArgs {
  updates: Array<PermissionRolesUpdates>;
}

/** mutation root */
export interface MutationRootUpdatePermissionUserGroupMembersArgs {
  _set?: Maybe<PermissionUserGroupMembersSetInput>;
  where: PermissionUserGroupMembersBoolExp;
}

/** mutation root */
export interface MutationRootUpdatePermissionUserGroupMembersByPkArgs {
  _set?: Maybe<PermissionUserGroupMembersSetInput>;
  pk_columns: PermissionUserGroupMembersPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdatePermissionUserGroupMembersManyArgs {
  updates: Array<PermissionUserGroupMembersUpdates>;
}

/** mutation root */
export interface MutationRootUpdatePermissionUserGroupRolesArgs {
  _set?: Maybe<PermissionUserGroupRolesSetInput>;
  where: PermissionUserGroupRolesBoolExp;
}

/** mutation root */
export interface MutationRootUpdatePermissionUserGroupRolesByPkArgs {
  _set?: Maybe<PermissionUserGroupRolesSetInput>;
  pk_columns: PermissionUserGroupRolesPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdatePermissionUserGroupRolesManyArgs {
  updates: Array<PermissionUserGroupRolesUpdates>;
}

/** mutation root */
export interface MutationRootUpdatePermissionUserGroupsArgs {
  _set?: Maybe<PermissionUserGroupsSetInput>;
  where: PermissionUserGroupsBoolExp;
}

/** mutation root */
export interface MutationRootUpdatePermissionUserGroupsByPkArgs {
  _set?: Maybe<PermissionUserGroupsSetInput>;
  pk_columns: PermissionUserGroupsPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdatePermissionUserGroupsManyArgs {
  updates: Array<PermissionUserGroupsUpdates>;
}

/** mutation root */
export interface MutationRootUpdateTicketingAccessCodesArgs {
  _append?: Maybe<TicketingAccessCodesAppendInput>;
  _delete_at_path?: Maybe<TicketingAccessCodesDeleteAtPathInput>;
  _delete_elem?: Maybe<TicketingAccessCodesDeleteElemInput>;
  _delete_key?: Maybe<TicketingAccessCodesDeleteKeyInput>;
  _prepend?: Maybe<TicketingAccessCodesPrependInput>;
  _set?: Maybe<TicketingAccessCodesSetInput>;
  where: TicketingAccessCodesBoolExp;
}

/** mutation root */
export interface MutationRootUpdateTicketingAccessCodesByPkArgs {
  _append?: Maybe<TicketingAccessCodesAppendInput>;
  _delete_at_path?: Maybe<TicketingAccessCodesDeleteAtPathInput>;
  _delete_elem?: Maybe<TicketingAccessCodesDeleteElemInput>;
  _delete_key?: Maybe<TicketingAccessCodesDeleteKeyInput>;
  _prepend?: Maybe<TicketingAccessCodesPrependInput>;
  _set?: Maybe<TicketingAccessCodesSetInput>;
  pk_columns: TicketingAccessCodesPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateTicketingAccessCodesManyArgs {
  updates: Array<TicketingAccessCodesUpdates>;
}

/** mutation root */
export interface MutationRootUpdateTicketingTicketOptionsArgs {
  _inc?: Maybe<TicketingTicketOptionsIncInput>;
  _set?: Maybe<TicketingTicketOptionsSetInput>;
  where: TicketingTicketOptionsBoolExp;
}

/** mutation root */
export interface MutationRootUpdateTicketingTicketOptionsByPkArgs {
  _inc?: Maybe<TicketingTicketOptionsIncInput>;
  _set?: Maybe<TicketingTicketOptionsSetInput>;
  pk_columns: TicketingTicketOptionsPkColumnsInput;
}

/** mutation root */
export interface MutationRootUpdateTicketingTicketOptionsManyArgs {
  updates: Array<TicketingTicketOptionsUpdates>;
}

/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export interface NameComparisonExp {
  _eq?: Maybe<Scalars["name"]>;
  _gt?: Maybe<Scalars["name"]>;
  _gte?: Maybe<Scalars["name"]>;
  _in?: Maybe<Array<Scalars["name"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["name"]>;
  _lte?: Maybe<Scalars["name"]>;
  _neq?: Maybe<Scalars["name"]>;
  _nin?: Maybe<Array<Scalars["name"]>>;
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export interface NumericComparisonExp {
  _eq?: Maybe<Scalars["numeric"]>;
  _gt?: Maybe<Scalars["numeric"]>;
  _gte?: Maybe<Scalars["numeric"]>;
  _in?: Maybe<Array<Scalars["numeric"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["numeric"]>;
  _lte?: Maybe<Scalars["numeric"]>;
  _neq?: Maybe<Scalars["numeric"]>;
  _nin?: Maybe<Array<Scalars["numeric"]>>;
}

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  asc = "asc",
  /** in ascending order, nulls first */
  asc_nulls_first = "asc_nulls_first",
  /** in ascending order, nulls last */
  asc_nulls_last = "asc_nulls_last",
  /** in descending order, nulls first */
  desc = "desc",
  /** in descending order, nulls first */
  desc_nulls_first = "desc_nulls_first",
  /** in descending order, nulls last */
  desc_nulls_last = "desc_nulls_last",
}

/** columns and relationships of "org_member_invites" */
export interface OrgMemberInvites {
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  invitee_email: Scalars["String"];
  inviter_user_id: Scalars["uuid"];
  is_used: Scalars["Boolean"];
  org_id: Scalars["uuid"];
  /** An object relationship */
  organization: Organizations;
  updated_at: Scalars["timestamptz"];
}

/** order by aggregate values of table "org_member_invites" */
export interface OrgMemberInvitesAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<OrgMemberInvitesMaxOrderBy>;
  min?: Maybe<OrgMemberInvitesMinOrderBy>;
}

/** input type for inserting array relation for remote table "org_member_invites" */
export interface OrgMemberInvitesArrRelInsertInput {
  data: Array<OrgMemberInvitesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<OrgMemberInvitesOnConflict>;
}

/** Boolean expression to filter rows from the table "org_member_invites". All fields are combined with a logical 'AND'. */
export interface OrgMemberInvitesBoolExp {
  _and?: Maybe<Array<OrgMemberInvitesBoolExp>>;
  _not?: Maybe<OrgMemberInvitesBoolExp>;
  _or?: Maybe<Array<OrgMemberInvitesBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  invitee_email?: Maybe<StringComparisonExp>;
  inviter_user_id?: Maybe<UuidComparisonExp>;
  is_used?: Maybe<BooleanComparisonExp>;
  org_id?: Maybe<UuidComparisonExp>;
  organization?: Maybe<OrganizationsBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "org_member_invites" */
export enum OrgMemberInvitesConstraint {
  /** unique or primary key constraint on columns "id" */
  org_member_invites_pkey = "org_member_invites_pkey",
}

/** input type for inserting data into table "org_member_invites" */
export interface OrgMemberInvitesInsertInput {
  invitee_email?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  organization?: Maybe<OrganizationsObjRelInsertInput>;
}

/** order by max() on columns of table "org_member_invites" */
export interface OrgMemberInvitesMaxOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  invitee_email?: Maybe<OrderBy>;
  inviter_user_id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** order by min() on columns of table "org_member_invites" */
export interface OrgMemberInvitesMinOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  invitee_email?: Maybe<OrderBy>;
  inviter_user_id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** response of any mutation on the table "org_member_invites" */
export interface OrgMemberInvitesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<OrgMemberInvites>;
}

/** on_conflict condition type for table "org_member_invites" */
export interface OrgMemberInvitesOnConflict {
  constraint: OrgMemberInvitesConstraint;
  update_columns?: Array<OrgMemberInvitesUpdateColumn>;
  where?: Maybe<OrgMemberInvitesBoolExp>;
}

/** Ordering options when selecting data from "org_member_invites". */
export interface OrgMemberInvitesOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  invitee_email?: Maybe<OrderBy>;
  inviter_user_id?: Maybe<OrderBy>;
  is_used?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationsOrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** select columns of table "org_member_invites" */
export enum OrgMemberInvitesSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  invitee_email = "invitee_email",
  /** column name */
  inviter_user_id = "inviter_user_id",
  /** column name */
  is_used = "is_used",
  /** column name */
  org_id = "org_id",
  /** column name */
  updated_at = "updated_at",
}

/** Streaming cursor of the table "org_member_invites" */
export interface OrgMemberInvitesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: OrgMemberInvitesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface OrgMemberInvitesStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  invitee_email?: Maybe<Scalars["String"]>;
  inviter_user_id?: Maybe<Scalars["uuid"]>;
  is_used?: Maybe<Scalars["Boolean"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** placeholder for update columns of table "org_member_invites" (current role has no relevant permissions) */
export enum OrgMemberInvitesUpdateColumn {
  /** placeholder (do not use) */
  _PLACEHOLDER = "_PLACEHOLDER",
}

/** columns and relationships of "org_members" */
export interface OrgMembers {
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  /** An array relationship */
  member_roles: Array<PermissionOrgMemberRoles>;
  /** An aggregate relationship */
  member_roles_aggregate: PermissionOrgMemberRolesAggregate;
  org_id: Scalars["uuid"];
  /** An object relationship */
  organization: Organizations;
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  user: Users;
  /** An array relationship */
  user_group_members: Array<PermissionUserGroupMembers>;
  /** An aggregate relationship */
  user_group_members_aggregate: PermissionUserGroupMembersAggregate;
  user_id: Scalars["uuid"];
}

/** columns and relationships of "org_members" */
export interface OrgMembersMemberRolesArgs {
  distinct_on?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionOrgMemberRolesOrderBy>>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

/** columns and relationships of "org_members" */
export interface OrgMembersMemberRolesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionOrgMemberRolesOrderBy>>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

/** columns and relationships of "org_members" */
export interface OrgMembersUserGroupMembersArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupMembersOrderBy>>;
  where?: Maybe<PermissionUserGroupMembersBoolExp>;
}

/** columns and relationships of "org_members" */
export interface OrgMembersUserGroupMembersAggregateArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupMembersOrderBy>>;
  where?: Maybe<PermissionUserGroupMembersBoolExp>;
}

/** aggregated selection of "org_members" */
export interface OrgMembersAggregate {
  aggregate?: Maybe<OrgMembersAggregateFields>;
  nodes: Array<OrgMembers>;
}

export interface OrgMembersAggregateBoolExp {
  count?: Maybe<OrgMembersAggregateBoolExpCount>;
}

export interface OrgMembersAggregateBoolExpCount {
  arguments?: Maybe<Array<OrgMembersSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<OrgMembersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "org_members" */
export interface OrgMembersAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<OrgMembersMaxFields>;
  min?: Maybe<OrgMembersMinFields>;
}

/** aggregate fields of "org_members" */
export interface OrgMembersAggregateFieldsCountArgs {
  columns?: Maybe<Array<OrgMembersSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** order by aggregate values of table "org_members" */
export interface OrgMembersAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<OrgMembersMaxOrderBy>;
  min?: Maybe<OrgMembersMinOrderBy>;
}

/** input type for inserting array relation for remote table "org_members" */
export interface OrgMembersArrRelInsertInput {
  data: Array<OrgMembersInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<OrgMembersOnConflict>;
}

/** Boolean expression to filter rows from the table "org_members". All fields are combined with a logical 'AND'. */
export interface OrgMembersBoolExp {
  _and?: Maybe<Array<OrgMembersBoolExp>>;
  _not?: Maybe<OrgMembersBoolExp>;
  _or?: Maybe<Array<OrgMembersBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  member_roles?: Maybe<PermissionOrgMemberRolesBoolExp>;
  member_roles_aggregate?: Maybe<PermissionOrgMemberRolesAggregateBoolExp>;
  org_id?: Maybe<UuidComparisonExp>;
  organization?: Maybe<OrganizationsBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  user_group_members?: Maybe<PermissionUserGroupMembersBoolExp>;
  user_group_members_aggregate?: Maybe<PermissionUserGroupMembersAggregateBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "org_members" */
export enum OrgMembersConstraint {
  /** unique or primary key constraint on columns "id", "user_id" */
  org_members_id_user_id_key = "org_members_id_user_id_key",
  /** unique or primary key constraint on columns "org_id", "user_id" */
  org_members_org_id_user_id_key = "org_members_org_id_user_id_key",
  /** unique or primary key constraint on columns "id" */
  org_members_pkey = "org_members_pkey",
}

/** input type for inserting data into table "org_members" */
export interface OrgMembersInsertInput {
  member_roles?: Maybe<PermissionOrgMemberRolesArrRelInsertInput>;
  org_id?: Maybe<Scalars["uuid"]>;
  organization?: Maybe<OrganizationsObjRelInsertInput>;
  user_group_members?: Maybe<PermissionUserGroupMembersArrRelInsertInput>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** aggregate max on columns */
export interface OrgMembersMaxFields {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** order by max() on columns of table "org_members" */
export interface OrgMembersMaxOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface OrgMembersMinFields {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** order by min() on columns of table "org_members" */
export interface OrgMembersMinOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** response of any mutation on the table "org_members" */
export interface OrgMembersMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<OrgMembers>;
}

/** input type for inserting object relation for remote table "org_members" */
export interface OrgMembersObjRelInsertInput {
  data: OrgMembersInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<OrgMembersOnConflict>;
}

/** on_conflict condition type for table "org_members" */
export interface OrgMembersOnConflict {
  constraint: OrgMembersConstraint;
  update_columns?: Array<OrgMembersUpdateColumn>;
  where?: Maybe<OrgMembersBoolExp>;
}

/** Ordering options when selecting data from "org_members". */
export interface OrgMembersOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  member_roles_aggregate?: Maybe<PermissionOrgMemberRolesAggregateOrderBy>;
  org_id?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationsOrderBy>;
  updated_at?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  user_group_members_aggregate?: Maybe<PermissionUserGroupMembersAggregateOrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** select columns of table "org_members" */
export enum OrgMembersSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  org_id = "org_id",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  user_id = "user_id",
}

/** Streaming cursor of the table "org_members" */
export interface OrgMembersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: OrgMembersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface OrgMembersStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** placeholder for update columns of table "org_members" (current role has no relevant permissions) */
export enum OrgMembersUpdateColumn {
  /** placeholder (do not use) */
  _PLACEHOLDER = "_PLACEHOLDER",
}

/** columns and relationships of "organizations" */
export interface Organizations {
  /** An array relationship */
  asset_metadata_fields: Array<AssetMetadataFields>;
  avatar?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company_code?: Maybe<Scalars["Int"]>;
  created_at: Scalars["timestamptz"];
  created_by: Scalars["uuid"];
  email: Scalars["String"];
  /** An array relationship */
  folders: Array<Folders>;
  house_number?: Maybe<Scalars["Int"]>;
  id: Scalars["uuid"];
  name: Scalars["name"];
  notes?: Maybe<Scalars["String"]>;
  /** An array relationship */
  org_member_invites: Array<OrgMemberInvites>;
  /** An array relationship */
  org_members: Array<OrgMembers>;
  /** An aggregate relationship */
  org_members_aggregate: OrgMembersAggregate;
  phone_number: Scalars["String"];
  /** An array relationship */
  roles: Array<PermissionRoles>;
  /** An aggregate relationship */
  roles_aggregate: PermissionRolesAggregate;
  slug: Scalars["String"];
  street?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  vat_number?: Maybe<Scalars["Int"]>;
  zip_code?: Maybe<Scalars["Int"]>;
}

/** columns and relationships of "organizations" */
export interface OrganizationsAssetMetadataFieldsArgs {
  distinct_on?: Maybe<Array<AssetMetadataFieldsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetMetadataFieldsOrderBy>>;
  where?: Maybe<AssetMetadataFieldsBoolExp>;
}

/** columns and relationships of "organizations" */
export interface OrganizationsFoldersArgs {
  distinct_on?: Maybe<Array<FoldersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FoldersOrderBy>>;
  where?: Maybe<FoldersBoolExp>;
}

/** columns and relationships of "organizations" */
export interface OrganizationsOrgMemberInvitesArgs {
  distinct_on?: Maybe<Array<OrgMemberInvitesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OrgMemberInvitesOrderBy>>;
  where?: Maybe<OrgMemberInvitesBoolExp>;
}

/** columns and relationships of "organizations" */
export interface OrganizationsOrgMembersArgs {
  distinct_on?: Maybe<Array<OrgMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OrgMembersOrderBy>>;
  where?: Maybe<OrgMembersBoolExp>;
}

/** columns and relationships of "organizations" */
export interface OrganizationsOrgMembersAggregateArgs {
  distinct_on?: Maybe<Array<OrgMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OrgMembersOrderBy>>;
  where?: Maybe<OrgMembersBoolExp>;
}

/** columns and relationships of "organizations" */
export interface OrganizationsRolesArgs {
  distinct_on?: Maybe<Array<PermissionRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRolesOrderBy>>;
  where?: Maybe<PermissionRolesBoolExp>;
}

/** columns and relationships of "organizations" */
export interface OrganizationsRolesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRolesOrderBy>>;
  where?: Maybe<PermissionRolesBoolExp>;
}

/** aggregated selection of "organizations" */
export interface OrganizationsAggregate {
  aggregate?: Maybe<OrganizationsAggregateFields>;
  nodes: Array<Organizations>;
}

/** aggregate fields of "organizations" */
export interface OrganizationsAggregateFields {
  avg?: Maybe<OrganizationsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<OrganizationsMaxFields>;
  min?: Maybe<OrganizationsMinFields>;
  stddev?: Maybe<OrganizationsStddevFields>;
  stddev_pop?: Maybe<OrganizationsStddevPopFields>;
  stddev_samp?: Maybe<OrganizationsStddevSampFields>;
  sum?: Maybe<OrganizationsSumFields>;
  var_pop?: Maybe<OrganizationsVarPopFields>;
  var_samp?: Maybe<OrganizationsVarSampFields>;
  variance?: Maybe<OrganizationsVarianceFields>;
}

/** aggregate fields of "organizations" */
export interface OrganizationsAggregateFieldsCountArgs {
  columns?: Maybe<Array<OrganizationsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** aggregate avg on columns */
export interface OrganizationsAvgFields {
  company_code?: Maybe<Scalars["Float"]>;
  house_number?: Maybe<Scalars["Float"]>;
  vat_number?: Maybe<Scalars["Float"]>;
  zip_code?: Maybe<Scalars["Float"]>;
}

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export interface OrganizationsBoolExp {
  _and?: Maybe<Array<OrganizationsBoolExp>>;
  _not?: Maybe<OrganizationsBoolExp>;
  _or?: Maybe<Array<OrganizationsBoolExp>>;
  asset_metadata_fields?: Maybe<AssetMetadataFieldsBoolExp>;
  avatar?: Maybe<StringComparisonExp>;
  city?: Maybe<StringComparisonExp>;
  company_code?: Maybe<IntComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  folders?: Maybe<FoldersBoolExp>;
  house_number?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<NameComparisonExp>;
  notes?: Maybe<StringComparisonExp>;
  org_member_invites?: Maybe<OrgMemberInvitesBoolExp>;
  org_members?: Maybe<OrgMembersBoolExp>;
  org_members_aggregate?: Maybe<OrgMembersAggregateBoolExp>;
  phone_number?: Maybe<StringComparisonExp>;
  roles?: Maybe<PermissionRolesBoolExp>;
  roles_aggregate?: Maybe<PermissionRolesAggregateBoolExp>;
  slug?: Maybe<StringComparisonExp>;
  street?: Maybe<StringComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  vat_number?: Maybe<IntComparisonExp>;
  zip_code?: Maybe<IntComparisonExp>;
}

/** unique or primary key constraints on table "organizations" */
export enum OrganizationsConstraint {
  /** unique or primary key constraint on columns "id" */
  organization_pkey = "organization_pkey",
  /** unique or primary key constraint on columns "slug" */
  organizations_slug_key = "organizations_slug_key",
}

/** input type for incrementing numeric columns in table "organizations" */
export interface OrganizationsIncInput {
  company_code?: Maybe<Scalars["Int"]>;
  house_number?: Maybe<Scalars["Int"]>;
  vat_number?: Maybe<Scalars["Int"]>;
  zip_code?: Maybe<Scalars["Int"]>;
}

/** input type for inserting data into table "organizations" */
export interface OrganizationsInsertInput {
  asset_metadata_fields?: Maybe<AssetMetadataFieldsArrRelInsertInput>;
  avatar?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company_code?: Maybe<Scalars["Int"]>;
  email?: Maybe<Scalars["String"]>;
  folders?: Maybe<FoldersArrRelInsertInput>;
  house_number?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["name"]>;
  notes?: Maybe<Scalars["String"]>;
  org_member_invites?: Maybe<OrgMemberInvitesArrRelInsertInput>;
  org_members?: Maybe<OrgMembersArrRelInsertInput>;
  phone_number?: Maybe<Scalars["String"]>;
  roles?: Maybe<PermissionRolesArrRelInsertInput>;
  slug?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  vat_number?: Maybe<Scalars["Int"]>;
  zip_code?: Maybe<Scalars["Int"]>;
}

/** aggregate max on columns */
export interface OrganizationsMaxFields {
  avatar?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company_code?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  house_number?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  notes?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vat_number?: Maybe<Scalars["Int"]>;
  zip_code?: Maybe<Scalars["Int"]>;
}

/** aggregate min on columns */
export interface OrganizationsMinFields {
  avatar?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company_code?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  house_number?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  notes?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vat_number?: Maybe<Scalars["Int"]>;
  zip_code?: Maybe<Scalars["Int"]>;
}

/** response of any mutation on the table "organizations" */
export interface OrganizationsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations>;
}

/** input type for inserting object relation for remote table "organizations" */
export interface OrganizationsObjRelInsertInput {
  data: OrganizationsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<OrganizationsOnConflict>;
}

/** on_conflict condition type for table "organizations" */
export interface OrganizationsOnConflict {
  constraint: OrganizationsConstraint;
  update_columns?: Array<OrganizationsUpdateColumn>;
  where?: Maybe<OrganizationsBoolExp>;
}

/** Ordering options when selecting data from "organizations". */
export interface OrganizationsOrderBy {
  asset_metadata_fields_aggregate?: Maybe<AssetMetadataFieldsAggregateOrderBy>;
  avatar?: Maybe<OrderBy>;
  city?: Maybe<OrderBy>;
  company_code?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  folders_aggregate?: Maybe<FoldersAggregateOrderBy>;
  house_number?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  notes?: Maybe<OrderBy>;
  org_member_invites_aggregate?: Maybe<OrgMemberInvitesAggregateOrderBy>;
  org_members_aggregate?: Maybe<OrgMembersAggregateOrderBy>;
  phone_number?: Maybe<OrderBy>;
  roles_aggregate?: Maybe<PermissionRolesAggregateOrderBy>;
  slug?: Maybe<OrderBy>;
  street?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  vat_number?: Maybe<OrderBy>;
  zip_code?: Maybe<OrderBy>;
}

/** primary key columns input for table: organizations */
export interface OrganizationsPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "organizations" */
export enum OrganizationsSelectColumn {
  /** column name */
  avatar = "avatar",
  /** column name */
  city = "city",
  /** column name */
  company_code = "company_code",
  /** column name */
  created_at = "created_at",
  /** column name */
  created_by = "created_by",
  /** column name */
  email = "email",
  /** column name */
  house_number = "house_number",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  notes = "notes",
  /** column name */
  phone_number = "phone_number",
  /** column name */
  slug = "slug",
  /** column name */
  street = "street",
  /** column name */
  type = "type",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  vat_number = "vat_number",
  /** column name */
  zip_code = "zip_code",
}

/** input type for updating data in table "organizations" */
export interface OrganizationsSetInput {
  city?: Maybe<Scalars["String"]>;
  company_code?: Maybe<Scalars["Int"]>;
  email?: Maybe<Scalars["String"]>;
  house_number?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["name"]>;
  notes?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  vat_number?: Maybe<Scalars["Int"]>;
  zip_code?: Maybe<Scalars["Int"]>;
}

/** aggregate stddev on columns */
export interface OrganizationsStddevFields {
  company_code?: Maybe<Scalars["Float"]>;
  house_number?: Maybe<Scalars["Float"]>;
  vat_number?: Maybe<Scalars["Float"]>;
  zip_code?: Maybe<Scalars["Float"]>;
}

/** aggregate stddev_pop on columns */
export interface OrganizationsStddevPopFields {
  company_code?: Maybe<Scalars["Float"]>;
  house_number?: Maybe<Scalars["Float"]>;
  vat_number?: Maybe<Scalars["Float"]>;
  zip_code?: Maybe<Scalars["Float"]>;
}

/** aggregate stddev_samp on columns */
export interface OrganizationsStddevSampFields {
  company_code?: Maybe<Scalars["Float"]>;
  house_number?: Maybe<Scalars["Float"]>;
  vat_number?: Maybe<Scalars["Float"]>;
  zip_code?: Maybe<Scalars["Float"]>;
}

/** Streaming cursor of the table "organizations" */
export interface OrganizationsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: OrganizationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface OrganizationsStreamCursorValueInput {
  avatar?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  company_code?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  house_number?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["name"]>;
  notes?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  vat_number?: Maybe<Scalars["Int"]>;
  zip_code?: Maybe<Scalars["Int"]>;
}

/** aggregate sum on columns */
export interface OrganizationsSumFields {
  company_code?: Maybe<Scalars["Int"]>;
  house_number?: Maybe<Scalars["Int"]>;
  vat_number?: Maybe<Scalars["Int"]>;
  zip_code?: Maybe<Scalars["Int"]>;
}

/** update columns of table "organizations" */
export enum OrganizationsUpdateColumn {
  /** column name */
  city = "city",
  /** column name */
  company_code = "company_code",
  /** column name */
  email = "email",
  /** column name */
  house_number = "house_number",
  /** column name */
  name = "name",
  /** column name */
  notes = "notes",
  /** column name */
  phone_number = "phone_number",
  /** column name */
  slug = "slug",
  /** column name */
  street = "street",
  /** column name */
  type = "type",
  /** column name */
  vat_number = "vat_number",
  /** column name */
  zip_code = "zip_code",
}

export interface OrganizationsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<OrganizationsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<OrganizationsSetInput>;
  /** filter the rows which have to be updated */
  where: OrganizationsBoolExp;
}

/** aggregate var_pop on columns */
export interface OrganizationsVarPopFields {
  company_code?: Maybe<Scalars["Float"]>;
  house_number?: Maybe<Scalars["Float"]>;
  vat_number?: Maybe<Scalars["Float"]>;
  zip_code?: Maybe<Scalars["Float"]>;
}

/** aggregate var_samp on columns */
export interface OrganizationsVarSampFields {
  company_code?: Maybe<Scalars["Float"]>;
  house_number?: Maybe<Scalars["Float"]>;
  vat_number?: Maybe<Scalars["Float"]>;
  zip_code?: Maybe<Scalars["Float"]>;
}

/** aggregate variance on columns */
export interface OrganizationsVarianceFields {
  company_code?: Maybe<Scalars["Float"]>;
  house_number?: Maybe<Scalars["Float"]>;
  vat_number?: Maybe<Scalars["Float"]>;
  zip_code?: Maybe<Scalars["Float"]>;
}

/** columns and relationships of "permission.folder_permissions" */
export interface PermissionFolderPermissions {
  expires?: Maybe<Scalars["date"]>;
  /** An object relationship */
  folder?: Maybe<Folders>;
  folder_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  group_roles: Array<PermissionUserGroupUsers>;
  /** An array relationship */
  member_roles: Array<PermissionOrgMemberRoles>;
  /** An aggregate relationship */
  member_roles_aggregate: PermissionOrgMemberRolesAggregate;
  owned_media?: Maybe<Scalars["jsonb"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  shared_media?: Maybe<Scalars["jsonb"]>;
}

/** columns and relationships of "permission.folder_permissions" */
export interface PermissionFolderPermissionsGroupRolesArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupUsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupUsersOrderBy>>;
  where?: Maybe<PermissionUserGroupUsersBoolExp>;
}

/** columns and relationships of "permission.folder_permissions" */
export interface PermissionFolderPermissionsMemberRolesArgs {
  distinct_on?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionOrgMemberRolesOrderBy>>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

/** columns and relationships of "permission.folder_permissions" */
export interface PermissionFolderPermissionsMemberRolesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionOrgMemberRolesOrderBy>>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

/** columns and relationships of "permission.folder_permissions" */
export interface PermissionFolderPermissionsOwnedMediaArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "permission.folder_permissions" */
export interface PermissionFolderPermissionsSharedMediaArgs {
  path?: Maybe<Scalars["String"]>;
}

/** order by aggregate values of table "permission.folder_permissions" */
export interface PermissionFolderPermissionsAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<PermissionFolderPermissionsMaxOrderBy>;
  min?: Maybe<PermissionFolderPermissionsMinOrderBy>;
}

/** Boolean expression to filter rows from the table "permission.folder_permissions". All fields are combined with a logical 'AND'. */
export interface PermissionFolderPermissionsBoolExp {
  _and?: Maybe<Array<PermissionFolderPermissionsBoolExp>>;
  _not?: Maybe<PermissionFolderPermissionsBoolExp>;
  _or?: Maybe<Array<PermissionFolderPermissionsBoolExp>>;
  expires?: Maybe<DateComparisonExp>;
  folder?: Maybe<FoldersBoolExp>;
  folder_id?: Maybe<UuidComparisonExp>;
  group_roles?: Maybe<PermissionUserGroupUsersBoolExp>;
  member_roles?: Maybe<PermissionOrgMemberRolesBoolExp>;
  member_roles_aggregate?: Maybe<PermissionOrgMemberRolesAggregateBoolExp>;
  owned_media?: Maybe<JsonbComparisonExp>;
  role_id?: Maybe<UuidComparisonExp>;
  shared_media?: Maybe<JsonbComparisonExp>;
}

/** order by max() on columns of table "permission.folder_permissions" */
export interface PermissionFolderPermissionsMaxOrderBy {
  expires?: Maybe<OrderBy>;
  folder_id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
}

/** order by min() on columns of table "permission.folder_permissions" */
export interface PermissionFolderPermissionsMinOrderBy {
  expires?: Maybe<OrderBy>;
  folder_id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
}

/** Ordering options when selecting data from "permission.folder_permissions". */
export interface PermissionFolderPermissionsOrderBy {
  expires?: Maybe<OrderBy>;
  folder?: Maybe<FoldersOrderBy>;
  folder_id?: Maybe<OrderBy>;
  group_roles_aggregate?: Maybe<PermissionUserGroupUsersAggregateOrderBy>;
  member_roles_aggregate?: Maybe<PermissionOrgMemberRolesAggregateOrderBy>;
  owned_media?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  shared_media?: Maybe<OrderBy>;
}

/** select columns of table "permission.folder_permissions" */
export enum PermissionFolderPermissionsSelectColumn {
  /** column name */
  expires = "expires",
  /** column name */
  folder_id = "folder_id",
  /** column name */
  owned_media = "owned_media",
  /** column name */
  role_id = "role_id",
  /** column name */
  shared_media = "shared_media",
}

/** Streaming cursor of the table "permission_folder_permissions" */
export interface PermissionFolderPermissionsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PermissionFolderPermissionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PermissionFolderPermissionsStreamCursorValueInput {
  expires?: Maybe<Scalars["date"]>;
  folder_id?: Maybe<Scalars["uuid"]>;
  owned_media?: Maybe<Scalars["jsonb"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  shared_media?: Maybe<Scalars["jsonb"]>;
}

/** columns and relationships of "permission.media_permissions" */
export interface PermissionMediaPermissions {
  expires?: Maybe<Scalars["date"]>;
  folder_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  group_roles: Array<PermissionUserGroupUsers>;
  /** An object relationship */
  media?: Maybe<MediaAssets>;
  media_asset_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  member_roles: Array<PermissionOrgMemberRoles>;
  /** An aggregate relationship */
  member_roles_aggregate: PermissionOrgMemberRolesAggregate;
  owned_media?: Maybe<Scalars["jsonb"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  shared_media?: Maybe<Scalars["jsonb"]>;
}

/** columns and relationships of "permission.media_permissions" */
export interface PermissionMediaPermissionsGroupRolesArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupUsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupUsersOrderBy>>;
  where?: Maybe<PermissionUserGroupUsersBoolExp>;
}

/** columns and relationships of "permission.media_permissions" */
export interface PermissionMediaPermissionsMemberRolesArgs {
  distinct_on?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionOrgMemberRolesOrderBy>>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

/** columns and relationships of "permission.media_permissions" */
export interface PermissionMediaPermissionsMemberRolesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionOrgMemberRolesOrderBy>>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

/** columns and relationships of "permission.media_permissions" */
export interface PermissionMediaPermissionsOwnedMediaArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "permission.media_permissions" */
export interface PermissionMediaPermissionsSharedMediaArgs {
  path?: Maybe<Scalars["String"]>;
}

/** order by aggregate values of table "permission.media_permissions" */
export interface PermissionMediaPermissionsAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<PermissionMediaPermissionsMaxOrderBy>;
  min?: Maybe<PermissionMediaPermissionsMinOrderBy>;
}

/** Boolean expression to filter rows from the table "permission.media_permissions". All fields are combined with a logical 'AND'. */
export interface PermissionMediaPermissionsBoolExp {
  _and?: Maybe<Array<PermissionMediaPermissionsBoolExp>>;
  _not?: Maybe<PermissionMediaPermissionsBoolExp>;
  _or?: Maybe<Array<PermissionMediaPermissionsBoolExp>>;
  expires?: Maybe<DateComparisonExp>;
  folder_id?: Maybe<UuidComparisonExp>;
  group_roles?: Maybe<PermissionUserGroupUsersBoolExp>;
  media?: Maybe<MediaAssetsBoolExp>;
  media_asset_id?: Maybe<UuidComparisonExp>;
  member_roles?: Maybe<PermissionOrgMemberRolesBoolExp>;
  member_roles_aggregate?: Maybe<PermissionOrgMemberRolesAggregateBoolExp>;
  owned_media?: Maybe<JsonbComparisonExp>;
  role_id?: Maybe<UuidComparisonExp>;
  shared_media?: Maybe<JsonbComparisonExp>;
}

/** order by max() on columns of table "permission.media_permissions" */
export interface PermissionMediaPermissionsMaxOrderBy {
  expires?: Maybe<OrderBy>;
  folder_id?: Maybe<OrderBy>;
  media_asset_id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
}

/** order by min() on columns of table "permission.media_permissions" */
export interface PermissionMediaPermissionsMinOrderBy {
  expires?: Maybe<OrderBy>;
  folder_id?: Maybe<OrderBy>;
  media_asset_id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
}

/** Ordering options when selecting data from "permission.media_permissions". */
export interface PermissionMediaPermissionsOrderBy {
  expires?: Maybe<OrderBy>;
  folder_id?: Maybe<OrderBy>;
  group_roles_aggregate?: Maybe<PermissionUserGroupUsersAggregateOrderBy>;
  media?: Maybe<MediaAssetsOrderBy>;
  media_asset_id?: Maybe<OrderBy>;
  member_roles_aggregate?: Maybe<PermissionOrgMemberRolesAggregateOrderBy>;
  owned_media?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  shared_media?: Maybe<OrderBy>;
}

/** select columns of table "permission.media_permissions" */
export enum PermissionMediaPermissionsSelectColumn {
  /** column name */
  expires = "expires",
  /** column name */
  folder_id = "folder_id",
  /** column name */
  media_asset_id = "media_asset_id",
  /** column name */
  owned_media = "owned_media",
  /** column name */
  role_id = "role_id",
  /** column name */
  shared_media = "shared_media",
}

/** Streaming cursor of the table "permission_media_permissions" */
export interface PermissionMediaPermissionsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PermissionMediaPermissionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PermissionMediaPermissionsStreamCursorValueInput {
  expires?: Maybe<Scalars["date"]>;
  folder_id?: Maybe<Scalars["uuid"]>;
  media_asset_id?: Maybe<Scalars["uuid"]>;
  owned_media?: Maybe<Scalars["jsonb"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  shared_media?: Maybe<Scalars["jsonb"]>;
}

/** columns and relationships of "permission.meta_permissions" */
export interface PermissionMetaPermissions {
  expires?: Maybe<Scalars["date"]>;
  field_id?: Maybe<Scalars["uuid"]>;
  folder_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  group_roles: Array<PermissionUserGroupUsers>;
  media_asset_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  member_roles: Array<PermissionOrgMemberRoles>;
  /** An aggregate relationship */
  member_roles_aggregate: PermissionOrgMemberRolesAggregate;
  owned_media?: Maybe<Scalars["jsonb"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  scopes?: Maybe<Scalars["jsonb"]>;
  shared_media?: Maybe<Scalars["jsonb"]>;
}

/** columns and relationships of "permission.meta_permissions" */
export interface PermissionMetaPermissionsGroupRolesArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupUsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupUsersOrderBy>>;
  where?: Maybe<PermissionUserGroupUsersBoolExp>;
}

/** columns and relationships of "permission.meta_permissions" */
export interface PermissionMetaPermissionsMemberRolesArgs {
  distinct_on?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionOrgMemberRolesOrderBy>>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

/** columns and relationships of "permission.meta_permissions" */
export interface PermissionMetaPermissionsMemberRolesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionOrgMemberRolesOrderBy>>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

/** columns and relationships of "permission.meta_permissions" */
export interface PermissionMetaPermissionsOwnedMediaArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "permission.meta_permissions" */
export interface PermissionMetaPermissionsScopesArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "permission.meta_permissions" */
export interface PermissionMetaPermissionsSharedMediaArgs {
  path?: Maybe<Scalars["String"]>;
}

/** order by aggregate values of table "permission.meta_permissions" */
export interface PermissionMetaPermissionsAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<PermissionMetaPermissionsMaxOrderBy>;
  min?: Maybe<PermissionMetaPermissionsMinOrderBy>;
}

/** Boolean expression to filter rows from the table "permission.meta_permissions". All fields are combined with a logical 'AND'. */
export interface PermissionMetaPermissionsBoolExp {
  _and?: Maybe<Array<PermissionMetaPermissionsBoolExp>>;
  _not?: Maybe<PermissionMetaPermissionsBoolExp>;
  _or?: Maybe<Array<PermissionMetaPermissionsBoolExp>>;
  expires?: Maybe<DateComparisonExp>;
  field_id?: Maybe<UuidComparisonExp>;
  folder_id?: Maybe<UuidComparisonExp>;
  group_roles?: Maybe<PermissionUserGroupUsersBoolExp>;
  media_asset_id?: Maybe<UuidComparisonExp>;
  member_roles?: Maybe<PermissionOrgMemberRolesBoolExp>;
  member_roles_aggregate?: Maybe<PermissionOrgMemberRolesAggregateBoolExp>;
  owned_media?: Maybe<JsonbComparisonExp>;
  role_id?: Maybe<UuidComparisonExp>;
  scopes?: Maybe<JsonbComparisonExp>;
  shared_media?: Maybe<JsonbComparisonExp>;
}

/** order by max() on columns of table "permission.meta_permissions" */
export interface PermissionMetaPermissionsMaxOrderBy {
  expires?: Maybe<OrderBy>;
  field_id?: Maybe<OrderBy>;
  folder_id?: Maybe<OrderBy>;
  media_asset_id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
}

/** order by min() on columns of table "permission.meta_permissions" */
export interface PermissionMetaPermissionsMinOrderBy {
  expires?: Maybe<OrderBy>;
  field_id?: Maybe<OrderBy>;
  folder_id?: Maybe<OrderBy>;
  media_asset_id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
}

/** Ordering options when selecting data from "permission.meta_permissions". */
export interface PermissionMetaPermissionsOrderBy {
  expires?: Maybe<OrderBy>;
  field_id?: Maybe<OrderBy>;
  folder_id?: Maybe<OrderBy>;
  group_roles_aggregate?: Maybe<PermissionUserGroupUsersAggregateOrderBy>;
  media_asset_id?: Maybe<OrderBy>;
  member_roles_aggregate?: Maybe<PermissionOrgMemberRolesAggregateOrderBy>;
  owned_media?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  scopes?: Maybe<OrderBy>;
  shared_media?: Maybe<OrderBy>;
}

/** select columns of table "permission.meta_permissions" */
export enum PermissionMetaPermissionsSelectColumn {
  /** column name */
  expires = "expires",
  /** column name */
  field_id = "field_id",
  /** column name */
  folder_id = "folder_id",
  /** column name */
  media_asset_id = "media_asset_id",
  /** column name */
  owned_media = "owned_media",
  /** column name */
  role_id = "role_id",
  /** column name */
  scopes = "scopes",
  /** column name */
  shared_media = "shared_media",
}

/** Streaming cursor of the table "permission_meta_permissions" */
export interface PermissionMetaPermissionsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PermissionMetaPermissionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PermissionMetaPermissionsStreamCursorValueInput {
  expires?: Maybe<Scalars["date"]>;
  field_id?: Maybe<Scalars["uuid"]>;
  folder_id?: Maybe<Scalars["uuid"]>;
  media_asset_id?: Maybe<Scalars["uuid"]>;
  owned_media?: Maybe<Scalars["jsonb"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  scopes?: Maybe<Scalars["jsonb"]>;
  shared_media?: Maybe<Scalars["jsonb"]>;
}

/** columns and relationships of "permission.org_member_roles" */
export interface PermissionOrgMemberRoles {
  expires?: Maybe<Scalars["date"]>;
  /** An array relationship */
  folder_scopes: Array<PermissionRoleFolderScopes>;
  /** An aggregate relationship */
  folder_scopes_aggregate: PermissionRoleFolderScopesAggregate;
  id: Scalars["uuid"];
  /** An object relationship */
  member?: Maybe<OrgMembers>;
  member_id: Scalars["uuid"];
  /** An object relationship */
  role: PermissionRoles;
  role_id: Scalars["uuid"];
  /** An object relationship */
  user: Users;
  user_id: Scalars["uuid"];
}

/** columns and relationships of "permission.org_member_roles" */
export interface PermissionOrgMemberRolesFolderScopesArgs {
  distinct_on?: Maybe<Array<PermissionRoleFolderScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleFolderScopesOrderBy>>;
  where?: Maybe<PermissionRoleFolderScopesBoolExp>;
}

/** columns and relationships of "permission.org_member_roles" */
export interface PermissionOrgMemberRolesFolderScopesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionRoleFolderScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleFolderScopesOrderBy>>;
  where?: Maybe<PermissionRoleFolderScopesBoolExp>;
}

/** aggregated selection of "permission.org_member_roles" */
export interface PermissionOrgMemberRolesAggregate {
  aggregate?: Maybe<PermissionOrgMemberRolesAggregateFields>;
  nodes: Array<PermissionOrgMemberRoles>;
}

export interface PermissionOrgMemberRolesAggregateBoolExp {
  count?: Maybe<PermissionOrgMemberRolesAggregateBoolExpCount>;
}

export interface PermissionOrgMemberRolesAggregateBoolExpCount {
  arguments?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PermissionOrgMemberRolesBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "permission.org_member_roles" */
export interface PermissionOrgMemberRolesAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<PermissionOrgMemberRolesMaxFields>;
  min?: Maybe<PermissionOrgMemberRolesMinFields>;
}

/** aggregate fields of "permission.org_member_roles" */
export interface PermissionOrgMemberRolesAggregateFieldsCountArgs {
  columns?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** order by aggregate values of table "permission.org_member_roles" */
export interface PermissionOrgMemberRolesAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<PermissionOrgMemberRolesMaxOrderBy>;
  min?: Maybe<PermissionOrgMemberRolesMinOrderBy>;
}

/** input type for inserting array relation for remote table "permission.org_member_roles" */
export interface PermissionOrgMemberRolesArrRelInsertInput {
  data: Array<PermissionOrgMemberRolesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<PermissionOrgMemberRolesOnConflict>;
}

/** Boolean expression to filter rows from the table "permission.org_member_roles". All fields are combined with a logical 'AND'. */
export interface PermissionOrgMemberRolesBoolExp {
  _and?: Maybe<Array<PermissionOrgMemberRolesBoolExp>>;
  _not?: Maybe<PermissionOrgMemberRolesBoolExp>;
  _or?: Maybe<Array<PermissionOrgMemberRolesBoolExp>>;
  expires?: Maybe<DateComparisonExp>;
  folder_scopes?: Maybe<PermissionRoleFolderScopesBoolExp>;
  folder_scopes_aggregate?: Maybe<PermissionRoleFolderScopesAggregateBoolExp>;
  id?: Maybe<UuidComparisonExp>;
  member?: Maybe<OrgMembersBoolExp>;
  member_id?: Maybe<UuidComparisonExp>;
  role?: Maybe<PermissionRolesBoolExp>;
  role_id?: Maybe<UuidComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "permission.org_member_roles" */
export enum PermissionOrgMemberRolesConstraint {
  /** unique or primary key constraint on columns "id" */
  org_member_roles_pkey = "org_member_roles_pkey",
}

/** input type for inserting data into table "permission.org_member_roles" */
export interface PermissionOrgMemberRolesInsertInput {
  expires?: Maybe<Scalars["date"]>;
  folder_scopes?: Maybe<PermissionRoleFolderScopesArrRelInsertInput>;
  id?: Maybe<Scalars["uuid"]>;
  member?: Maybe<OrgMembersObjRelInsertInput>;
  member_id?: Maybe<Scalars["uuid"]>;
  role?: Maybe<PermissionRolesObjRelInsertInput>;
  role_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** aggregate max on columns */
export interface PermissionOrgMemberRolesMaxFields {
  expires?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  member_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** order by max() on columns of table "permission.org_member_roles" */
export interface PermissionOrgMemberRolesMaxOrderBy {
  expires?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  member_id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface PermissionOrgMemberRolesMinFields {
  expires?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  member_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** order by min() on columns of table "permission.org_member_roles" */
export interface PermissionOrgMemberRolesMinOrderBy {
  expires?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  member_id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** response of any mutation on the table "permission.org_member_roles" */
export interface PermissionOrgMemberRolesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PermissionOrgMemberRoles>;
}

/** on_conflict condition type for table "permission.org_member_roles" */
export interface PermissionOrgMemberRolesOnConflict {
  constraint: PermissionOrgMemberRolesConstraint;
  update_columns?: Array<PermissionOrgMemberRolesUpdateColumn>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

/** Ordering options when selecting data from "permission.org_member_roles". */
export interface PermissionOrgMemberRolesOrderBy {
  expires?: Maybe<OrderBy>;
  folder_scopes_aggregate?: Maybe<PermissionRoleFolderScopesAggregateOrderBy>;
  id?: Maybe<OrderBy>;
  member?: Maybe<OrgMembersOrderBy>;
  member_id?: Maybe<OrderBy>;
  role?: Maybe<PermissionRolesOrderBy>;
  role_id?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** primary key columns input for table: permission.org_member_roles */
export interface PermissionOrgMemberRolesPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "permission.org_member_roles" */
export enum PermissionOrgMemberRolesSelectColumn {
  /** column name */
  expires = "expires",
  /** column name */
  id = "id",
  /** column name */
  member_id = "member_id",
  /** column name */
  role_id = "role_id",
  /** column name */
  user_id = "user_id",
}

/** input type for updating data in table "permission.org_member_roles" */
export interface PermissionOrgMemberRolesSetInput {
  expires?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  member_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** Streaming cursor of the table "permission_org_member_roles" */
export interface PermissionOrgMemberRolesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PermissionOrgMemberRolesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PermissionOrgMemberRolesStreamCursorValueInput {
  expires?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  member_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** update columns of table "permission.org_member_roles" */
export enum PermissionOrgMemberRolesUpdateColumn {
  /** column name */
  expires = "expires",
  /** column name */
  id = "id",
  /** column name */
  member_id = "member_id",
  /** column name */
  role_id = "role_id",
  /** column name */
  user_id = "user_id",
}

export interface PermissionOrgMemberRolesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<PermissionOrgMemberRolesSetInput>;
  /** filter the rows which have to be updated */
  where: PermissionOrgMemberRolesBoolExp;
}

/** columns and relationships of "permission.role_folder_scopes" */
export interface PermissionRoleFolderScopes {
  /** An object relationship */
  folder: Folders;
  folder_id: Scalars["uuid"];
  group_role_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["uuid"];
  member_role_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  role: PermissionRoles;
  role_id: Scalars["uuid"];
}

/** aggregated selection of "permission.role_folder_scopes" */
export interface PermissionRoleFolderScopesAggregate {
  aggregate?: Maybe<PermissionRoleFolderScopesAggregateFields>;
  nodes: Array<PermissionRoleFolderScopes>;
}

export interface PermissionRoleFolderScopesAggregateBoolExp {
  count?: Maybe<PermissionRoleFolderScopesAggregateBoolExpCount>;
}

export interface PermissionRoleFolderScopesAggregateBoolExpCount {
  arguments?: Maybe<Array<PermissionRoleFolderScopesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PermissionRoleFolderScopesBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "permission.role_folder_scopes" */
export interface PermissionRoleFolderScopesAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<PermissionRoleFolderScopesMaxFields>;
  min?: Maybe<PermissionRoleFolderScopesMinFields>;
}

/** aggregate fields of "permission.role_folder_scopes" */
export interface PermissionRoleFolderScopesAggregateFieldsCountArgs {
  columns?: Maybe<Array<PermissionRoleFolderScopesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** order by aggregate values of table "permission.role_folder_scopes" */
export interface PermissionRoleFolderScopesAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<PermissionRoleFolderScopesMaxOrderBy>;
  min?: Maybe<PermissionRoleFolderScopesMinOrderBy>;
}

/** input type for inserting array relation for remote table "permission.role_folder_scopes" */
export interface PermissionRoleFolderScopesArrRelInsertInput {
  data: Array<PermissionRoleFolderScopesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<PermissionRoleFolderScopesOnConflict>;
}

/** Boolean expression to filter rows from the table "permission.role_folder_scopes". All fields are combined with a logical 'AND'. */
export interface PermissionRoleFolderScopesBoolExp {
  _and?: Maybe<Array<PermissionRoleFolderScopesBoolExp>>;
  _not?: Maybe<PermissionRoleFolderScopesBoolExp>;
  _or?: Maybe<Array<PermissionRoleFolderScopesBoolExp>>;
  folder?: Maybe<FoldersBoolExp>;
  folder_id?: Maybe<UuidComparisonExp>;
  group_role_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  member_role_id?: Maybe<UuidComparisonExp>;
  role?: Maybe<PermissionRolesBoolExp>;
  role_id?: Maybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "permission.role_folder_scopes" */
export enum PermissionRoleFolderScopesConstraint {
  /** unique or primary key constraint on columns "id" */
  role_folder_scopes_pkey = "role_folder_scopes_pkey",
}

/** input type for inserting data into table "permission.role_folder_scopes" */
export interface PermissionRoleFolderScopesInsertInput {
  folder?: Maybe<FoldersObjRelInsertInput>;
  folder_id?: Maybe<Scalars["uuid"]>;
  group_role_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  member_role_id?: Maybe<Scalars["uuid"]>;
  role?: Maybe<PermissionRolesObjRelInsertInput>;
  role_id?: Maybe<Scalars["uuid"]>;
}

/** aggregate max on columns */
export interface PermissionRoleFolderScopesMaxFields {
  folder_id?: Maybe<Scalars["uuid"]>;
  group_role_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  member_role_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
}

/** order by max() on columns of table "permission.role_folder_scopes" */
export interface PermissionRoleFolderScopesMaxOrderBy {
  folder_id?: Maybe<OrderBy>;
  group_role_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  member_role_id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface PermissionRoleFolderScopesMinFields {
  folder_id?: Maybe<Scalars["uuid"]>;
  group_role_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  member_role_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
}

/** order by min() on columns of table "permission.role_folder_scopes" */
export interface PermissionRoleFolderScopesMinOrderBy {
  folder_id?: Maybe<OrderBy>;
  group_role_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  member_role_id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
}

/** response of any mutation on the table "permission.role_folder_scopes" */
export interface PermissionRoleFolderScopesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PermissionRoleFolderScopes>;
}

/** on_conflict condition type for table "permission.role_folder_scopes" */
export interface PermissionRoleFolderScopesOnConflict {
  constraint: PermissionRoleFolderScopesConstraint;
  update_columns?: Array<PermissionRoleFolderScopesUpdateColumn>;
  where?: Maybe<PermissionRoleFolderScopesBoolExp>;
}

/** Ordering options when selecting data from "permission.role_folder_scopes". */
export interface PermissionRoleFolderScopesOrderBy {
  folder?: Maybe<FoldersOrderBy>;
  folder_id?: Maybe<OrderBy>;
  group_role_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  member_role_id?: Maybe<OrderBy>;
  role?: Maybe<PermissionRolesOrderBy>;
  role_id?: Maybe<OrderBy>;
}

/** primary key columns input for table: permission.role_folder_scopes */
export interface PermissionRoleFolderScopesPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "permission.role_folder_scopes" */
export enum PermissionRoleFolderScopesSelectColumn {
  /** column name */
  folder_id = "folder_id",
  /** column name */
  group_role_id = "group_role_id",
  /** column name */
  id = "id",
  /** column name */
  member_role_id = "member_role_id",
  /** column name */
  role_id = "role_id",
}

/** input type for updating data in table "permission.role_folder_scopes" */
export interface PermissionRoleFolderScopesSetInput {
  folder_id?: Maybe<Scalars["uuid"]>;
  group_role_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  member_role_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
}

/** Streaming cursor of the table "permission_role_folder_scopes" */
export interface PermissionRoleFolderScopesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PermissionRoleFolderScopesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PermissionRoleFolderScopesStreamCursorValueInput {
  folder_id?: Maybe<Scalars["uuid"]>;
  group_role_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  member_role_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
}

/** update columns of table "permission.role_folder_scopes" */
export enum PermissionRoleFolderScopesUpdateColumn {
  /** column name */
  folder_id = "folder_id",
  /** column name */
  group_role_id = "group_role_id",
  /** column name */
  id = "id",
  /** column name */
  member_role_id = "member_role_id",
  /** column name */
  role_id = "role_id",
}

export interface PermissionRoleFolderScopesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<PermissionRoleFolderScopesSetInput>;
  /** filter the rows which have to be updated */
  where: PermissionRoleFolderScopesBoolExp;
}

/** columns and relationships of "permission.role_meta_scopes" */
export interface PermissionRoleMetaScopes {
  field_id: Scalars["uuid"];
  /** An object relationship */
  role: PermissionRoles;
  role_id: Scalars["uuid"];
  scopes: Scalars["jsonb"];
}

/** columns and relationships of "permission.role_meta_scopes" */
export interface PermissionRoleMetaScopesScopesArgs {
  path?: Maybe<Scalars["String"]>;
}

/** aggregated selection of "permission.role_meta_scopes" */
export interface PermissionRoleMetaScopesAggregate {
  aggregate?: Maybe<PermissionRoleMetaScopesAggregateFields>;
  nodes: Array<PermissionRoleMetaScopes>;
}

export interface PermissionRoleMetaScopesAggregateBoolExp {
  count?: Maybe<PermissionRoleMetaScopesAggregateBoolExpCount>;
}

export interface PermissionRoleMetaScopesAggregateBoolExpCount {
  arguments?: Maybe<Array<PermissionRoleMetaScopesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PermissionRoleMetaScopesBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "permission.role_meta_scopes" */
export interface PermissionRoleMetaScopesAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<PermissionRoleMetaScopesMaxFields>;
  min?: Maybe<PermissionRoleMetaScopesMinFields>;
}

/** aggregate fields of "permission.role_meta_scopes" */
export interface PermissionRoleMetaScopesAggregateFieldsCountArgs {
  columns?: Maybe<Array<PermissionRoleMetaScopesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** order by aggregate values of table "permission.role_meta_scopes" */
export interface PermissionRoleMetaScopesAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<PermissionRoleMetaScopesMaxOrderBy>;
  min?: Maybe<PermissionRoleMetaScopesMinOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface PermissionRoleMetaScopesAppendInput {
  scopes?: Maybe<Scalars["jsonb"]>;
}

/** input type for inserting array relation for remote table "permission.role_meta_scopes" */
export interface PermissionRoleMetaScopesArrRelInsertInput {
  data: Array<PermissionRoleMetaScopesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<PermissionRoleMetaScopesOnConflict>;
}

/** Boolean expression to filter rows from the table "permission.role_meta_scopes". All fields are combined with a logical 'AND'. */
export interface PermissionRoleMetaScopesBoolExp {
  _and?: Maybe<Array<PermissionRoleMetaScopesBoolExp>>;
  _not?: Maybe<PermissionRoleMetaScopesBoolExp>;
  _or?: Maybe<Array<PermissionRoleMetaScopesBoolExp>>;
  field_id?: Maybe<UuidComparisonExp>;
  role?: Maybe<PermissionRolesBoolExp>;
  role_id?: Maybe<UuidComparisonExp>;
  scopes?: Maybe<JsonbComparisonExp>;
}

/** unique or primary key constraints on table "permission.role_meta_scopes" */
export enum PermissionRoleMetaScopesConstraint {
  /** unique or primary key constraint on columns "role_id", "field_id" */
  role_meta_scopes_pkey = "role_meta_scopes_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface PermissionRoleMetaScopesDeleteAtPathInput {
  scopes?: Maybe<Array<Scalars["String"]>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface PermissionRoleMetaScopesDeleteElemInput {
  scopes?: Maybe<Scalars["Int"]>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface PermissionRoleMetaScopesDeleteKeyInput {
  scopes?: Maybe<Scalars["String"]>;
}

/** input type for inserting data into table "permission.role_meta_scopes" */
export interface PermissionRoleMetaScopesInsertInput {
  field_id?: Maybe<Scalars["uuid"]>;
  role?: Maybe<PermissionRolesObjRelInsertInput>;
  role_id?: Maybe<Scalars["uuid"]>;
  scopes?: Maybe<Scalars["jsonb"]>;
}

/** aggregate max on columns */
export interface PermissionRoleMetaScopesMaxFields {
  field_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
}

/** order by max() on columns of table "permission.role_meta_scopes" */
export interface PermissionRoleMetaScopesMaxOrderBy {
  field_id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface PermissionRoleMetaScopesMinFields {
  field_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
}

/** order by min() on columns of table "permission.role_meta_scopes" */
export interface PermissionRoleMetaScopesMinOrderBy {
  field_id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
}

/** response of any mutation on the table "permission.role_meta_scopes" */
export interface PermissionRoleMetaScopesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PermissionRoleMetaScopes>;
}

/** on_conflict condition type for table "permission.role_meta_scopes" */
export interface PermissionRoleMetaScopesOnConflict {
  constraint: PermissionRoleMetaScopesConstraint;
  update_columns?: Array<PermissionRoleMetaScopesUpdateColumn>;
  where?: Maybe<PermissionRoleMetaScopesBoolExp>;
}

/** Ordering options when selecting data from "permission.role_meta_scopes". */
export interface PermissionRoleMetaScopesOrderBy {
  field_id?: Maybe<OrderBy>;
  role?: Maybe<PermissionRolesOrderBy>;
  role_id?: Maybe<OrderBy>;
  scopes?: Maybe<OrderBy>;
}

/** primary key columns input for table: permission.role_meta_scopes */
export interface PermissionRoleMetaScopesPkColumnsInput {
  field_id: Scalars["uuid"];
  role_id: Scalars["uuid"];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface PermissionRoleMetaScopesPrependInput {
  scopes?: Maybe<Scalars["jsonb"]>;
}

/** select columns of table "permission.role_meta_scopes" */
export enum PermissionRoleMetaScopesSelectColumn {
  /** column name */
  field_id = "field_id",
  /** column name */
  role_id = "role_id",
  /** column name */
  scopes = "scopes",
}

/** input type for updating data in table "permission.role_meta_scopes" */
export interface PermissionRoleMetaScopesSetInput {
  field_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  scopes?: Maybe<Scalars["jsonb"]>;
}

/** Streaming cursor of the table "permission_role_meta_scopes" */
export interface PermissionRoleMetaScopesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PermissionRoleMetaScopesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PermissionRoleMetaScopesStreamCursorValueInput {
  field_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  scopes?: Maybe<Scalars["jsonb"]>;
}

/** update columns of table "permission.role_meta_scopes" */
export enum PermissionRoleMetaScopesUpdateColumn {
  /** column name */
  field_id = "field_id",
  /** column name */
  role_id = "role_id",
  /** column name */
  scopes = "scopes",
}

export interface PermissionRoleMetaScopesUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<PermissionRoleMetaScopesAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<PermissionRoleMetaScopesDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<PermissionRoleMetaScopesDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<PermissionRoleMetaScopesDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<PermissionRoleMetaScopesPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<PermissionRoleMetaScopesSetInput>;
  /** filter the rows which have to be updated */
  where: PermissionRoleMetaScopesBoolExp;
}

/** columns and relationships of "permission.roles" */
export interface PermissionRoles {
  /** An array relationship */
  group_roles: Array<PermissionUserGroupUsers>;
  id: Scalars["uuid"];
  /** An array relationship */
  member_roles: Array<PermissionOrgMemberRoles>;
  /** An aggregate relationship */
  member_roles_aggregate: PermissionOrgMemberRolesAggregate;
  /** An array relationship */
  meta_scopes: Array<PermissionRoleMetaScopes>;
  /** An aggregate relationship */
  meta_scopes_aggregate: PermissionRoleMetaScopesAggregate;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  /** An object relationship */
  org: Organizations;
  org_data: Scalars["jsonb"];
  org_id: Scalars["uuid"];
  org_members: Scalars["jsonb"];
  owned_media: Scalars["jsonb"];
  shared_media: Scalars["jsonb"];
  type?: Maybe<Scalars["String"]>;
  /** An array relationship */
  user_group_roles: Array<PermissionUserGroupRoles>;
  /** An aggregate relationship */
  user_group_roles_aggregate: PermissionUserGroupRolesAggregate;
}

/** columns and relationships of "permission.roles" */
export interface PermissionRolesGroupRolesArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupUsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupUsersOrderBy>>;
  where?: Maybe<PermissionUserGroupUsersBoolExp>;
}

/** columns and relationships of "permission.roles" */
export interface PermissionRolesMemberRolesArgs {
  distinct_on?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionOrgMemberRolesOrderBy>>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

/** columns and relationships of "permission.roles" */
export interface PermissionRolesMemberRolesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionOrgMemberRolesOrderBy>>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

/** columns and relationships of "permission.roles" */
export interface PermissionRolesMetaScopesArgs {
  distinct_on?: Maybe<Array<PermissionRoleMetaScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleMetaScopesOrderBy>>;
  where?: Maybe<PermissionRoleMetaScopesBoolExp>;
}

/** columns and relationships of "permission.roles" */
export interface PermissionRolesMetaScopesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionRoleMetaScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleMetaScopesOrderBy>>;
  where?: Maybe<PermissionRoleMetaScopesBoolExp>;
}

/** columns and relationships of "permission.roles" */
export interface PermissionRolesOrgDataArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "permission.roles" */
export interface PermissionRolesOrgMembersArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "permission.roles" */
export interface PermissionRolesOwnedMediaArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "permission.roles" */
export interface PermissionRolesSharedMediaArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "permission.roles" */
export interface PermissionRolesUserGroupRolesArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupRolesOrderBy>>;
  where?: Maybe<PermissionUserGroupRolesBoolExp>;
}

/** columns and relationships of "permission.roles" */
export interface PermissionRolesUserGroupRolesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupRolesOrderBy>>;
  where?: Maybe<PermissionUserGroupRolesBoolExp>;
}

/** aggregated selection of "permission.roles" */
export interface PermissionRolesAggregate {
  aggregate?: Maybe<PermissionRolesAggregateFields>;
  nodes: Array<PermissionRoles>;
}

export interface PermissionRolesAggregateBoolExp {
  count?: Maybe<PermissionRolesAggregateBoolExpCount>;
}

export interface PermissionRolesAggregateBoolExpCount {
  arguments?: Maybe<Array<PermissionRolesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PermissionRolesBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "permission.roles" */
export interface PermissionRolesAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<PermissionRolesMaxFields>;
  min?: Maybe<PermissionRolesMinFields>;
}

/** aggregate fields of "permission.roles" */
export interface PermissionRolesAggregateFieldsCountArgs {
  columns?: Maybe<Array<PermissionRolesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** order by aggregate values of table "permission.roles" */
export interface PermissionRolesAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<PermissionRolesMaxOrderBy>;
  min?: Maybe<PermissionRolesMinOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface PermissionRolesAppendInput {
  org_data?: Maybe<Scalars["jsonb"]>;
  org_members?: Maybe<Scalars["jsonb"]>;
  owned_media?: Maybe<Scalars["jsonb"]>;
  shared_media?: Maybe<Scalars["jsonb"]>;
}

/** input type for inserting array relation for remote table "permission.roles" */
export interface PermissionRolesArrRelInsertInput {
  data: Array<PermissionRolesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<PermissionRolesOnConflict>;
}

/** Boolean expression to filter rows from the table "permission.roles". All fields are combined with a logical 'AND'. */
export interface PermissionRolesBoolExp {
  _and?: Maybe<Array<PermissionRolesBoolExp>>;
  _not?: Maybe<PermissionRolesBoolExp>;
  _or?: Maybe<Array<PermissionRolesBoolExp>>;
  group_roles?: Maybe<PermissionUserGroupUsersBoolExp>;
  id?: Maybe<UuidComparisonExp>;
  member_roles?: Maybe<PermissionOrgMemberRolesBoolExp>;
  member_roles_aggregate?: Maybe<PermissionOrgMemberRolesAggregateBoolExp>;
  meta_scopes?: Maybe<PermissionRoleMetaScopesBoolExp>;
  meta_scopes_aggregate?: Maybe<PermissionRoleMetaScopesAggregateBoolExp>;
  name?: Maybe<StringComparisonExp>;
  notes?: Maybe<StringComparisonExp>;
  org?: Maybe<OrganizationsBoolExp>;
  org_data?: Maybe<JsonbComparisonExp>;
  org_id?: Maybe<UuidComparisonExp>;
  org_members?: Maybe<JsonbComparisonExp>;
  owned_media?: Maybe<JsonbComparisonExp>;
  shared_media?: Maybe<JsonbComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  user_group_roles?: Maybe<PermissionUserGroupRolesBoolExp>;
  user_group_roles_aggregate?: Maybe<PermissionUserGroupRolesAggregateBoolExp>;
}

/** unique or primary key constraints on table "permission.roles" */
export enum PermissionRolesConstraint {
  /** unique or primary key constraint on columns "id" */
  roles_pkey = "roles_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface PermissionRolesDeleteAtPathInput {
  org_data?: Maybe<Array<Scalars["String"]>>;
  org_members?: Maybe<Array<Scalars["String"]>>;
  owned_media?: Maybe<Array<Scalars["String"]>>;
  shared_media?: Maybe<Array<Scalars["String"]>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface PermissionRolesDeleteElemInput {
  org_data?: Maybe<Scalars["Int"]>;
  org_members?: Maybe<Scalars["Int"]>;
  owned_media?: Maybe<Scalars["Int"]>;
  shared_media?: Maybe<Scalars["Int"]>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface PermissionRolesDeleteKeyInput {
  org_data?: Maybe<Scalars["String"]>;
  org_members?: Maybe<Scalars["String"]>;
  owned_media?: Maybe<Scalars["String"]>;
  shared_media?: Maybe<Scalars["String"]>;
}

/** input type for inserting data into table "permission.roles" */
export interface PermissionRolesInsertInput {
  id?: Maybe<Scalars["uuid"]>;
  member_roles?: Maybe<PermissionOrgMemberRolesArrRelInsertInput>;
  meta_scopes?: Maybe<PermissionRoleMetaScopesArrRelInsertInput>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  org?: Maybe<OrganizationsObjRelInsertInput>;
  org_data?: Maybe<Scalars["jsonb"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  org_members?: Maybe<Scalars["jsonb"]>;
  owned_media?: Maybe<Scalars["jsonb"]>;
  shared_media?: Maybe<Scalars["jsonb"]>;
  type?: Maybe<Scalars["String"]>;
  user_group_roles?: Maybe<PermissionUserGroupRolesArrRelInsertInput>;
}

/** aggregate max on columns */
export interface PermissionRolesMaxFields {
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
}

/** order by max() on columns of table "permission.roles" */
export interface PermissionRolesMaxOrderBy {
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  notes?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface PermissionRolesMinFields {
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
}

/** order by min() on columns of table "permission.roles" */
export interface PermissionRolesMinOrderBy {
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  notes?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
}

/** response of any mutation on the table "permission.roles" */
export interface PermissionRolesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PermissionRoles>;
}

/** input type for inserting object relation for remote table "permission.roles" */
export interface PermissionRolesObjRelInsertInput {
  data: PermissionRolesInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<PermissionRolesOnConflict>;
}

/** on_conflict condition type for table "permission.roles" */
export interface PermissionRolesOnConflict {
  constraint: PermissionRolesConstraint;
  update_columns?: Array<PermissionRolesUpdateColumn>;
  where?: Maybe<PermissionRolesBoolExp>;
}

/** Ordering options when selecting data from "permission.roles". */
export interface PermissionRolesOrderBy {
  group_roles_aggregate?: Maybe<PermissionUserGroupUsersAggregateOrderBy>;
  id?: Maybe<OrderBy>;
  member_roles_aggregate?: Maybe<PermissionOrgMemberRolesAggregateOrderBy>;
  meta_scopes_aggregate?: Maybe<PermissionRoleMetaScopesAggregateOrderBy>;
  name?: Maybe<OrderBy>;
  notes?: Maybe<OrderBy>;
  org?: Maybe<OrganizationsOrderBy>;
  org_data?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  org_members?: Maybe<OrderBy>;
  owned_media?: Maybe<OrderBy>;
  shared_media?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  user_group_roles_aggregate?: Maybe<PermissionUserGroupRolesAggregateOrderBy>;
}

/** primary key columns input for table: permission.roles */
export interface PermissionRolesPkColumnsInput {
  id: Scalars["uuid"];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface PermissionRolesPrependInput {
  org_data?: Maybe<Scalars["jsonb"]>;
  org_members?: Maybe<Scalars["jsonb"]>;
  owned_media?: Maybe<Scalars["jsonb"]>;
  shared_media?: Maybe<Scalars["jsonb"]>;
}

/** select columns of table "permission.roles" */
export enum PermissionRolesSelectColumn {
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  notes = "notes",
  /** column name */
  org_data = "org_data",
  /** column name */
  org_id = "org_id",
  /** column name */
  org_members = "org_members",
  /** column name */
  owned_media = "owned_media",
  /** column name */
  shared_media = "shared_media",
  /** column name */
  type = "type",
}

/** input type for updating data in table "permission.roles" */
export interface PermissionRolesSetInput {
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  org_data?: Maybe<Scalars["jsonb"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  org_members?: Maybe<Scalars["jsonb"]>;
  owned_media?: Maybe<Scalars["jsonb"]>;
  shared_media?: Maybe<Scalars["jsonb"]>;
}

/** Streaming cursor of the table "permission_roles" */
export interface PermissionRolesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PermissionRolesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PermissionRolesStreamCursorValueInput {
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  org_data?: Maybe<Scalars["jsonb"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  org_members?: Maybe<Scalars["jsonb"]>;
  owned_media?: Maybe<Scalars["jsonb"]>;
  shared_media?: Maybe<Scalars["jsonb"]>;
  type?: Maybe<Scalars["String"]>;
}

/** update columns of table "permission.roles" */
export enum PermissionRolesUpdateColumn {
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  notes = "notes",
  /** column name */
  org_data = "org_data",
  /** column name */
  org_id = "org_id",
  /** column name */
  org_members = "org_members",
  /** column name */
  owned_media = "owned_media",
  /** column name */
  shared_media = "shared_media",
}

export interface PermissionRolesUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<PermissionRolesAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<PermissionRolesDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<PermissionRolesDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<PermissionRolesDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<PermissionRolesPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<PermissionRolesSetInput>;
  /** filter the rows which have to be updated */
  where: PermissionRolesBoolExp;
}

/** columns and relationships of "permission.scopes" */
export interface PermissionScopes {
  scope: Scalars["String"];
}

/** aggregated selection of "permission.scopes" */
export interface PermissionScopesAggregate {
  aggregate?: Maybe<PermissionScopesAggregateFields>;
  nodes: Array<PermissionScopes>;
}

/** aggregate fields of "permission.scopes" */
export interface PermissionScopesAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<PermissionScopesMaxFields>;
  min?: Maybe<PermissionScopesMinFields>;
}

/** aggregate fields of "permission.scopes" */
export interface PermissionScopesAggregateFieldsCountArgs {
  columns?: Maybe<Array<PermissionScopesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** Boolean expression to filter rows from the table "permission.scopes". All fields are combined with a logical 'AND'. */
export interface PermissionScopesBoolExp {
  _and?: Maybe<Array<PermissionScopesBoolExp>>;
  _not?: Maybe<PermissionScopesBoolExp>;
  _or?: Maybe<Array<PermissionScopesBoolExp>>;
  scope?: Maybe<StringComparisonExp>;
}

/** aggregate max on columns */
export interface PermissionScopesMaxFields {
  scope?: Maybe<Scalars["String"]>;
}

/** aggregate min on columns */
export interface PermissionScopesMinFields {
  scope?: Maybe<Scalars["String"]>;
}

/** Ordering options when selecting data from "permission.scopes". */
export interface PermissionScopesOrderBy {
  scope?: Maybe<OrderBy>;
}

/** select columns of table "permission.scopes" */
export enum PermissionScopesSelectColumn {
  /** column name */
  scope = "scope",
}

/** Streaming cursor of the table "permission_scopes" */
export interface PermissionScopesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PermissionScopesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PermissionScopesStreamCursorValueInput {
  scope?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "permission.user_group_members" */
export interface PermissionUserGroupMembers {
  group_id: Scalars["uuid"];
  id: Scalars["uuid"];
  member_id: Scalars["uuid"];
  /** An object relationship */
  user: Users;
  /** An object relationship */
  user_group: PermissionUserGroups;
  user_id: Scalars["uuid"];
}

/** aggregated selection of "permission.user_group_members" */
export interface PermissionUserGroupMembersAggregate {
  aggregate?: Maybe<PermissionUserGroupMembersAggregateFields>;
  nodes: Array<PermissionUserGroupMembers>;
}

export interface PermissionUserGroupMembersAggregateBoolExp {
  count?: Maybe<PermissionUserGroupMembersAggregateBoolExpCount>;
}

export interface PermissionUserGroupMembersAggregateBoolExpCount {
  arguments?: Maybe<Array<PermissionUserGroupMembersSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PermissionUserGroupMembersBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "permission.user_group_members" */
export interface PermissionUserGroupMembersAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<PermissionUserGroupMembersMaxFields>;
  min?: Maybe<PermissionUserGroupMembersMinFields>;
}

/** aggregate fields of "permission.user_group_members" */
export interface PermissionUserGroupMembersAggregateFieldsCountArgs {
  columns?: Maybe<Array<PermissionUserGroupMembersSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** order by aggregate values of table "permission.user_group_members" */
export interface PermissionUserGroupMembersAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<PermissionUserGroupMembersMaxOrderBy>;
  min?: Maybe<PermissionUserGroupMembersMinOrderBy>;
}

/** input type for inserting array relation for remote table "permission.user_group_members" */
export interface PermissionUserGroupMembersArrRelInsertInput {
  data: Array<PermissionUserGroupMembersInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<PermissionUserGroupMembersOnConflict>;
}

/** Boolean expression to filter rows from the table "permission.user_group_members". All fields are combined with a logical 'AND'. */
export interface PermissionUserGroupMembersBoolExp {
  _and?: Maybe<Array<PermissionUserGroupMembersBoolExp>>;
  _not?: Maybe<PermissionUserGroupMembersBoolExp>;
  _or?: Maybe<Array<PermissionUserGroupMembersBoolExp>>;
  group_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  member_id?: Maybe<UuidComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  user_group?: Maybe<PermissionUserGroupsBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "permission.user_group_members" */
export enum PermissionUserGroupMembersConstraint {
  /** unique or primary key constraint on columns "member_id", "group_id" */
  user_group_members_group_id_member_id_key = "user_group_members_group_id_member_id_key",
  /** unique or primary key constraint on columns "id" */
  user_group_members_pkey = "user_group_members_pkey",
}

/** input type for inserting data into table "permission.user_group_members" */
export interface PermissionUserGroupMembersInsertInput {
  group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  member_id?: Maybe<Scalars["uuid"]>;
  user_group?: Maybe<PermissionUserGroupsObjRelInsertInput>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** aggregate max on columns */
export interface PermissionUserGroupMembersMaxFields {
  group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  member_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** order by max() on columns of table "permission.user_group_members" */
export interface PermissionUserGroupMembersMaxOrderBy {
  group_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  member_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface PermissionUserGroupMembersMinFields {
  group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  member_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** order by min() on columns of table "permission.user_group_members" */
export interface PermissionUserGroupMembersMinOrderBy {
  group_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  member_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** response of any mutation on the table "permission.user_group_members" */
export interface PermissionUserGroupMembersMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PermissionUserGroupMembers>;
}

/** on_conflict condition type for table "permission.user_group_members" */
export interface PermissionUserGroupMembersOnConflict {
  constraint: PermissionUserGroupMembersConstraint;
  update_columns?: Array<PermissionUserGroupMembersUpdateColumn>;
  where?: Maybe<PermissionUserGroupMembersBoolExp>;
}

/** Ordering options when selecting data from "permission.user_group_members". */
export interface PermissionUserGroupMembersOrderBy {
  group_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  member_id?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  user_group?: Maybe<PermissionUserGroupsOrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** primary key columns input for table: permission.user_group_members */
export interface PermissionUserGroupMembersPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "permission.user_group_members" */
export enum PermissionUserGroupMembersSelectColumn {
  /** column name */
  group_id = "group_id",
  /** column name */
  id = "id",
  /** column name */
  member_id = "member_id",
  /** column name */
  user_id = "user_id",
}

/** input type for updating data in table "permission.user_group_members" */
export interface PermissionUserGroupMembersSetInput {
  group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  member_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** Streaming cursor of the table "permission_user_group_members" */
export interface PermissionUserGroupMembersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PermissionUserGroupMembersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PermissionUserGroupMembersStreamCursorValueInput {
  group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  member_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** update columns of table "permission.user_group_members" */
export enum PermissionUserGroupMembersUpdateColumn {
  /** column name */
  group_id = "group_id",
  /** column name */
  id = "id",
  /** column name */
  member_id = "member_id",
  /** column name */
  user_id = "user_id",
}

export interface PermissionUserGroupMembersUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<PermissionUserGroupMembersSetInput>;
  /** filter the rows which have to be updated */
  where: PermissionUserGroupMembersBoolExp;
}

/** columns and relationships of "permission.user_group_roles" */
export interface PermissionUserGroupRoles {
  expires?: Maybe<Scalars["date"]>;
  /** An array relationship */
  folder_scopes: Array<PermissionRoleFolderScopes>;
  /** An aggregate relationship */
  folder_scopes_aggregate: PermissionRoleFolderScopesAggregate;
  group_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An object relationship */
  role: PermissionRoles;
  role_id: Scalars["uuid"];
  /** An object relationship */
  user_group: PermissionUserGroups;
}

/** columns and relationships of "permission.user_group_roles" */
export interface PermissionUserGroupRolesFolderScopesArgs {
  distinct_on?: Maybe<Array<PermissionRoleFolderScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleFolderScopesOrderBy>>;
  where?: Maybe<PermissionRoleFolderScopesBoolExp>;
}

/** columns and relationships of "permission.user_group_roles" */
export interface PermissionUserGroupRolesFolderScopesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionRoleFolderScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleFolderScopesOrderBy>>;
  where?: Maybe<PermissionRoleFolderScopesBoolExp>;
}

/** aggregated selection of "permission.user_group_roles" */
export interface PermissionUserGroupRolesAggregate {
  aggregate?: Maybe<PermissionUserGroupRolesAggregateFields>;
  nodes: Array<PermissionUserGroupRoles>;
}

export interface PermissionUserGroupRolesAggregateBoolExp {
  count?: Maybe<PermissionUserGroupRolesAggregateBoolExpCount>;
}

export interface PermissionUserGroupRolesAggregateBoolExpCount {
  arguments?: Maybe<Array<PermissionUserGroupRolesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<PermissionUserGroupRolesBoolExp>;
  predicate: IntComparisonExp;
}

/** aggregate fields of "permission.user_group_roles" */
export interface PermissionUserGroupRolesAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<PermissionUserGroupRolesMaxFields>;
  min?: Maybe<PermissionUserGroupRolesMinFields>;
}

/** aggregate fields of "permission.user_group_roles" */
export interface PermissionUserGroupRolesAggregateFieldsCountArgs {
  columns?: Maybe<Array<PermissionUserGroupRolesSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** order by aggregate values of table "permission.user_group_roles" */
export interface PermissionUserGroupRolesAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<PermissionUserGroupRolesMaxOrderBy>;
  min?: Maybe<PermissionUserGroupRolesMinOrderBy>;
}

/** input type for inserting array relation for remote table "permission.user_group_roles" */
export interface PermissionUserGroupRolesArrRelInsertInput {
  data: Array<PermissionUserGroupRolesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<PermissionUserGroupRolesOnConflict>;
}

/** Boolean expression to filter rows from the table "permission.user_group_roles". All fields are combined with a logical 'AND'. */
export interface PermissionUserGroupRolesBoolExp {
  _and?: Maybe<Array<PermissionUserGroupRolesBoolExp>>;
  _not?: Maybe<PermissionUserGroupRolesBoolExp>;
  _or?: Maybe<Array<PermissionUserGroupRolesBoolExp>>;
  expires?: Maybe<DateComparisonExp>;
  folder_scopes?: Maybe<PermissionRoleFolderScopesBoolExp>;
  folder_scopes_aggregate?: Maybe<PermissionRoleFolderScopesAggregateBoolExp>;
  group_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  role?: Maybe<PermissionRolesBoolExp>;
  role_id?: Maybe<UuidComparisonExp>;
  user_group?: Maybe<PermissionUserGroupsBoolExp>;
}

/** unique or primary key constraints on table "permission.user_group_roles" */
export enum PermissionUserGroupRolesConstraint {
  /** unique or primary key constraint on columns "id" */
  user_group_roles_pkey = "user_group_roles_pkey",
}

/** input type for inserting data into table "permission.user_group_roles" */
export interface PermissionUserGroupRolesInsertInput {
  expires?: Maybe<Scalars["date"]>;
  folder_scopes?: Maybe<PermissionRoleFolderScopesArrRelInsertInput>;
  group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  role?: Maybe<PermissionRolesObjRelInsertInput>;
  role_id?: Maybe<Scalars["uuid"]>;
  user_group?: Maybe<PermissionUserGroupsObjRelInsertInput>;
}

/** aggregate max on columns */
export interface PermissionUserGroupRolesMaxFields {
  expires?: Maybe<Scalars["date"]>;
  group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
}

/** order by max() on columns of table "permission.user_group_roles" */
export interface PermissionUserGroupRolesMaxOrderBy {
  expires?: Maybe<OrderBy>;
  group_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface PermissionUserGroupRolesMinFields {
  expires?: Maybe<Scalars["date"]>;
  group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
}

/** order by min() on columns of table "permission.user_group_roles" */
export interface PermissionUserGroupRolesMinOrderBy {
  expires?: Maybe<OrderBy>;
  group_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
}

/** response of any mutation on the table "permission.user_group_roles" */
export interface PermissionUserGroupRolesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PermissionUserGroupRoles>;
}

/** on_conflict condition type for table "permission.user_group_roles" */
export interface PermissionUserGroupRolesOnConflict {
  constraint: PermissionUserGroupRolesConstraint;
  update_columns?: Array<PermissionUserGroupRolesUpdateColumn>;
  where?: Maybe<PermissionUserGroupRolesBoolExp>;
}

/** Ordering options when selecting data from "permission.user_group_roles". */
export interface PermissionUserGroupRolesOrderBy {
  expires?: Maybe<OrderBy>;
  folder_scopes_aggregate?: Maybe<PermissionRoleFolderScopesAggregateOrderBy>;
  group_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role?: Maybe<PermissionRolesOrderBy>;
  role_id?: Maybe<OrderBy>;
  user_group?: Maybe<PermissionUserGroupsOrderBy>;
}

/** primary key columns input for table: permission.user_group_roles */
export interface PermissionUserGroupRolesPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "permission.user_group_roles" */
export enum PermissionUserGroupRolesSelectColumn {
  /** column name */
  expires = "expires",
  /** column name */
  group_id = "group_id",
  /** column name */
  id = "id",
  /** column name */
  role_id = "role_id",
}

/** input type for updating data in table "permission.user_group_roles" */
export interface PermissionUserGroupRolesSetInput {
  expires?: Maybe<Scalars["date"]>;
  group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
}

/** Streaming cursor of the table "permission_user_group_roles" */
export interface PermissionUserGroupRolesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PermissionUserGroupRolesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PermissionUserGroupRolesStreamCursorValueInput {
  expires?: Maybe<Scalars["date"]>;
  group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
}

/** update columns of table "permission.user_group_roles" */
export enum PermissionUserGroupRolesUpdateColumn {
  /** column name */
  expires = "expires",
  /** column name */
  group_id = "group_id",
  /** column name */
  id = "id",
  /** column name */
  role_id = "role_id",
}

export interface PermissionUserGroupRolesUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<PermissionUserGroupRolesSetInput>;
  /** filter the rows which have to be updated */
  where: PermissionUserGroupRolesBoolExp;
}

/** columns and relationships of "permission.user_group_users" */
export interface PermissionUserGroupUsers {
  expires?: Maybe<Scalars["date"]>;
  group_role_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  user_group_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** order by aggregate values of table "permission.user_group_users" */
export interface PermissionUserGroupUsersAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<PermissionUserGroupUsersMaxOrderBy>;
  min?: Maybe<PermissionUserGroupUsersMinOrderBy>;
}

/** Boolean expression to filter rows from the table "permission.user_group_users". All fields are combined with a logical 'AND'. */
export interface PermissionUserGroupUsersBoolExp {
  _and?: Maybe<Array<PermissionUserGroupUsersBoolExp>>;
  _not?: Maybe<PermissionUserGroupUsersBoolExp>;
  _or?: Maybe<Array<PermissionUserGroupUsersBoolExp>>;
  expires?: Maybe<DateComparisonExp>;
  group_role_id?: Maybe<UuidComparisonExp>;
  role_id?: Maybe<UuidComparisonExp>;
  user_group_id?: Maybe<UuidComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
}

/** order by max() on columns of table "permission.user_group_users" */
export interface PermissionUserGroupUsersMaxOrderBy {
  expires?: Maybe<OrderBy>;
  group_role_id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  user_group_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** order by min() on columns of table "permission.user_group_users" */
export interface PermissionUserGroupUsersMinOrderBy {
  expires?: Maybe<OrderBy>;
  group_role_id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  user_group_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** Ordering options when selecting data from "permission.user_group_users". */
export interface PermissionUserGroupUsersOrderBy {
  expires?: Maybe<OrderBy>;
  group_role_id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  user_group_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
}

/** select columns of table "permission.user_group_users" */
export enum PermissionUserGroupUsersSelectColumn {
  /** column name */
  expires = "expires",
  /** column name */
  group_role_id = "group_role_id",
  /** column name */
  role_id = "role_id",
  /** column name */
  user_group_id = "user_group_id",
  /** column name */
  user_id = "user_id",
}

/** Streaming cursor of the table "permission_user_group_users" */
export interface PermissionUserGroupUsersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PermissionUserGroupUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PermissionUserGroupUsersStreamCursorValueInput {
  expires?: Maybe<Scalars["date"]>;
  group_role_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  user_group_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
}

/** columns and relationships of "permission.user_groups" */
export interface PermissionUserGroups {
  created_at: Scalars["timestamptz"];
  /** An array relationship */
  group_members: Array<PermissionUserGroupMembers>;
  /** An aggregate relationship */
  group_members_aggregate: PermissionUserGroupMembersAggregate;
  /** An array relationship */
  group_roles: Array<PermissionUserGroupRoles>;
  /** An aggregate relationship */
  group_roles_aggregate: PermissionUserGroupRolesAggregate;
  id: Scalars["uuid"];
  name: Scalars["String"];
  /** An object relationship */
  org: Organizations;
  org_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
}

/** columns and relationships of "permission.user_groups" */
export interface PermissionUserGroupsGroupMembersArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupMembersOrderBy>>;
  where?: Maybe<PermissionUserGroupMembersBoolExp>;
}

/** columns and relationships of "permission.user_groups" */
export interface PermissionUserGroupsGroupMembersAggregateArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupMembersOrderBy>>;
  where?: Maybe<PermissionUserGroupMembersBoolExp>;
}

/** columns and relationships of "permission.user_groups" */
export interface PermissionUserGroupsGroupRolesArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupRolesOrderBy>>;
  where?: Maybe<PermissionUserGroupRolesBoolExp>;
}

/** columns and relationships of "permission.user_groups" */
export interface PermissionUserGroupsGroupRolesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupRolesOrderBy>>;
  where?: Maybe<PermissionUserGroupRolesBoolExp>;
}

/** aggregated selection of "permission.user_groups" */
export interface PermissionUserGroupsAggregate {
  aggregate?: Maybe<PermissionUserGroupsAggregateFields>;
  nodes: Array<PermissionUserGroups>;
}

/** aggregate fields of "permission.user_groups" */
export interface PermissionUserGroupsAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<PermissionUserGroupsMaxFields>;
  min?: Maybe<PermissionUserGroupsMinFields>;
}

/** aggregate fields of "permission.user_groups" */
export interface PermissionUserGroupsAggregateFieldsCountArgs {
  columns?: Maybe<Array<PermissionUserGroupsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** Boolean expression to filter rows from the table "permission.user_groups". All fields are combined with a logical 'AND'. */
export interface PermissionUserGroupsBoolExp {
  _and?: Maybe<Array<PermissionUserGroupsBoolExp>>;
  _not?: Maybe<PermissionUserGroupsBoolExp>;
  _or?: Maybe<Array<PermissionUserGroupsBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  group_members?: Maybe<PermissionUserGroupMembersBoolExp>;
  group_members_aggregate?: Maybe<PermissionUserGroupMembersAggregateBoolExp>;
  group_roles?: Maybe<PermissionUserGroupRolesBoolExp>;
  group_roles_aggregate?: Maybe<PermissionUserGroupRolesAggregateBoolExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  org?: Maybe<OrganizationsBoolExp>;
  org_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "permission.user_groups" */
export enum PermissionUserGroupsConstraint {
  /** unique or primary key constraint on columns "id" */
  user_groups_pkey = "user_groups_pkey",
}

/** input type for inserting data into table "permission.user_groups" */
export interface PermissionUserGroupsInsertInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  group_members?: Maybe<PermissionUserGroupMembersArrRelInsertInput>;
  group_roles?: Maybe<PermissionUserGroupRolesArrRelInsertInput>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  org?: Maybe<OrganizationsObjRelInsertInput>;
  org_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** aggregate max on columns */
export interface PermissionUserGroupsMaxFields {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** aggregate min on columns */
export interface PermissionUserGroupsMinFields {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** response of any mutation on the table "permission.user_groups" */
export interface PermissionUserGroupsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<PermissionUserGroups>;
}

/** input type for inserting object relation for remote table "permission.user_groups" */
export interface PermissionUserGroupsObjRelInsertInput {
  data: PermissionUserGroupsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<PermissionUserGroupsOnConflict>;
}

/** on_conflict condition type for table "permission.user_groups" */
export interface PermissionUserGroupsOnConflict {
  constraint: PermissionUserGroupsConstraint;
  update_columns?: Array<PermissionUserGroupsUpdateColumn>;
  where?: Maybe<PermissionUserGroupsBoolExp>;
}

/** Ordering options when selecting data from "permission.user_groups". */
export interface PermissionUserGroupsOrderBy {
  created_at?: Maybe<OrderBy>;
  group_members_aggregate?: Maybe<PermissionUserGroupMembersAggregateOrderBy>;
  group_roles_aggregate?: Maybe<PermissionUserGroupRolesAggregateOrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org?: Maybe<OrganizationsOrderBy>;
  org_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** primary key columns input for table: permission.user_groups */
export interface PermissionUserGroupsPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "permission.user_groups" */
export enum PermissionUserGroupsSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  org_id = "org_id",
  /** column name */
  updated_at = "updated_at",
}

/** input type for updating data in table "permission.user_groups" */
export interface PermissionUserGroupsSetInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** Streaming cursor of the table "permission_user_groups" */
export interface PermissionUserGroupsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: PermissionUserGroupsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface PermissionUserGroupsStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** update columns of table "permission.user_groups" */
export enum PermissionUserGroupsUpdateColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  org_id = "org_id",
  /** column name */
  updated_at = "updated_at",
}

export interface PermissionUserGroupsUpdates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<PermissionUserGroupsSetInput>;
  /** filter the rows which have to be updated */
  where: PermissionUserGroupsBoolExp;
}

/** columns and relationships of "projects" */
export interface Projects {
  created_at: Scalars["timestamptz"];
  created_by: Scalars["uuid"];
  default_locale: CmsLocalesEnum;
  extra_data?: Maybe<Scalars["jsonb"]>;
  id: Scalars["uuid"];
  image_url?: Maybe<Scalars["String"]>;
  locales: Scalars["jsonb"];
  name: Scalars["String"];
  org_id: Scalars["uuid"];
  /** An object relationship */
  organization: Organizations;
  /** An array relationship */
  publications: Array<CmsPublications>;
  publishing_url?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
}

/** columns and relationships of "projects" */
export interface ProjectsExtraDataArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "projects" */
export interface ProjectsLocalesArgs {
  path?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "projects" */
export interface ProjectsPublicationsArgs {
  distinct_on?: Maybe<Array<CmsPublicationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CmsPublicationsOrderBy>>;
  where?: Maybe<CmsPublicationsBoolExp>;
}

/** aggregated selection of "projects" */
export interface ProjectsAggregate {
  aggregate?: Maybe<ProjectsAggregateFields>;
  nodes: Array<Projects>;
}

/** aggregate fields of "projects" */
export interface ProjectsAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<ProjectsMaxFields>;
  min?: Maybe<ProjectsMinFields>;
}

/** aggregate fields of "projects" */
export interface ProjectsAggregateFieldsCountArgs {
  columns?: Maybe<Array<ProjectsSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** Boolean expression to filter rows from the table "projects". All fields are combined with a logical 'AND'. */
export interface ProjectsBoolExp {
  _and?: Maybe<Array<ProjectsBoolExp>>;
  _not?: Maybe<ProjectsBoolExp>;
  _or?: Maybe<Array<ProjectsBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<UuidComparisonExp>;
  default_locale?: Maybe<CmsLocalesEnumComparisonExp>;
  extra_data?: Maybe<JsonbComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  image_url?: Maybe<StringComparisonExp>;
  locales?: Maybe<JsonbComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  org_id?: Maybe<UuidComparisonExp>;
  organization?: Maybe<OrganizationsBoolExp>;
  publications?: Maybe<CmsPublicationsBoolExp>;
  publishing_url?: Maybe<StringComparisonExp>;
  slug?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "projects" */
export enum ProjectsConstraint {
  /** unique or primary key constraint on columns "id" */
  project_pkey = "project_pkey",
  /** unique or primary key constraint on columns "org_id", "slug" */
  projects_slug_org_id_key = "projects_slug_org_id_key",
}

/** input type for inserting data into table "projects" */
export interface ProjectsInsertInput {
  extra_data?: Maybe<Scalars["jsonb"]>;
  image_url?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  organization?: Maybe<OrganizationsObjRelInsertInput>;
  publications?: Maybe<CmsPublicationsArrRelInsertInput>;
  slug?: Maybe<Scalars["String"]>;
}

/** aggregate max on columns */
export interface ProjectsMaxFields {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  image_url?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  publishing_url?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** aggregate min on columns */
export interface ProjectsMinFields {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  image_url?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  publishing_url?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** response of any mutation on the table "projects" */
export interface ProjectsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Projects>;
}

/** input type for inserting object relation for remote table "projects" */
export interface ProjectsObjRelInsertInput {
  data: ProjectsInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<ProjectsOnConflict>;
}

/** on_conflict condition type for table "projects" */
export interface ProjectsOnConflict {
  constraint: ProjectsConstraint;
  update_columns?: Array<ProjectsUpdateColumn>;
  where?: Maybe<ProjectsBoolExp>;
}

/** Ordering options when selecting data from "projects". */
export interface ProjectsOrderBy {
  created_at?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  default_locale?: Maybe<OrderBy>;
  extra_data?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  image_url?: Maybe<OrderBy>;
  locales?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  organization?: Maybe<OrganizationsOrderBy>;
  publications_aggregate?: Maybe<CmsPublicationsAggregateOrderBy>;
  publishing_url?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** select columns of table "projects" */
export enum ProjectsSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  created_by = "created_by",
  /** column name */
  default_locale = "default_locale",
  /** column name */
  extra_data = "extra_data",
  /** column name */
  id = "id",
  /** column name */
  image_url = "image_url",
  /** column name */
  locales = "locales",
  /** column name */
  name = "name",
  /** column name */
  org_id = "org_id",
  /** column name */
  publishing_url = "publishing_url",
  /** column name */
  slug = "slug",
  /** column name */
  updated_at = "updated_at",
}

/** Streaming cursor of the table "projects" */
export interface ProjectsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ProjectsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ProjectsStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  default_locale?: Maybe<CmsLocalesEnum>;
  extra_data?: Maybe<Scalars["jsonb"]>;
  id?: Maybe<Scalars["uuid"]>;
  image_url?: Maybe<Scalars["String"]>;
  locales?: Maybe<Scalars["jsonb"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  publishing_url?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** placeholder for update columns of table "projects" (current role has no relevant permissions) */
export enum ProjectsUpdateColumn {
  /** placeholder (do not use) */
  _PLACEHOLDER = "_PLACEHOLDER",
}

export interface QueryRoot {
  /** fetch data from the table: "archive_jobs" */
  archive_jobs: Array<ArchiveJobs>;
  /** fetch data from the table: "archive_jobs" using primary key columns */
  archive_jobs_by_pk?: Maybe<ArchiveJobs>;
  /** fetch data from the table: "asset_file_types" */
  asset_file_types: Array<AssetFileTypes>;
  /** fetch data from the table: "asset_file_types" using primary key columns */
  asset_file_types_by_pk?: Maybe<AssetFileTypes>;
  /** An array relationship */
  asset_files: Array<AssetFiles>;
  /** An aggregate relationship */
  asset_files_aggregate: AssetFilesAggregate;
  /** fetch data from the table: "asset_files" using primary key columns */
  asset_files_by_pk?: Maybe<AssetFiles>;
  /** An array relationship */
  asset_metadata_fields: Array<AssetMetadataFields>;
  /** fetch data from the table: "asset_metadata_fields" using primary key columns */
  asset_metadata_fields_by_pk?: Maybe<AssetMetadataFields>;
  /** fetch data from the table: "asset_metadata_types" */
  asset_metadata_types: Array<AssetMetadataTypes>;
  /** fetch data from the table: "asset_metadata_types" using primary key columns */
  asset_metadata_types_by_pk?: Maybe<AssetMetadataTypes>;
  /** fetch data from the table: "asset_metadata_values" */
  asset_metadata_values: Array<AssetMetadataValues>;
  /** fetch data from the table: "asset_metadata_values" using primary key columns */
  asset_metadata_values_by_pk?: Maybe<AssetMetadataValues>;
  /** fetch data from the table: "cms.content_rows" */
  cms_content_rows: Array<CmsContentRows>;
  /** fetch data from the table: "cms.content_rows" using primary key columns */
  cms_content_rows_by_pk?: Maybe<CmsContentRows>;
  /** fetch data from the table: "cms.content_schemas" */
  cms_content_schemas: Array<CmsContentSchemas>;
  /** fetch data from the table: "cms.content_schemas" using primary key columns */
  cms_content_schemas_by_pk?: Maybe<CmsContentSchemas>;
  /** fetch data from the table: "cms.projects" */
  cms_projects: Array<CmsProjects>;
  /** fetch data from the table: "cms.projects" using primary key columns */
  cms_projects_by_pk?: Maybe<CmsProjects>;
  /** fetch data from the table: "cms.publications" */
  cms_publications: Array<CmsPublications>;
  /** fetch data from the table: "cms.publications" using primary key columns */
  cms_publications_by_pk?: Maybe<CmsPublications>;
  /** fetch data from the table: "cms.resources" */
  cms_resources: Array<CmsResources>;
  /** fetch data from the table: "cms.resources" using primary key columns */
  cms_resources_by_pk?: Maybe<CmsResources>;
  /** fetch data from the table: "fe.link_share" */
  fe_link_share: Array<FeLinkShare>;
  /** fetch data from the table: "fe.link_share" using primary key columns */
  fe_link_share_by_pk?: Maybe<FeLinkShare>;
  /** fetch data from the table: "folder_hierarchy" */
  folder_hierarchy: Array<FolderHierarchy>;
  /** fetch data from the table: "folder_hierarchy" using primary key columns */
  folder_hierarchy_by_pk?: Maybe<FolderHierarchy>;
  /** An array relationship */
  folders: Array<Folders>;
  /** fetch data from the table: "folders" using primary key columns */
  folders_by_pk?: Maybe<Folders>;
  /** An array relationship */
  image_thumbnail_jobs: Array<ImageThumbnailJobs>;
  /** fetch data from the table: "image_thumbnail_jobs" using primary key columns */
  image_thumbnail_jobs_by_pk?: Maybe<ImageThumbnailJobs>;
  /** An array relationship */
  image_thumbnails: Array<ImageThumbnails>;
  /** fetch data from the table: "image_thumbnails" using primary key columns */
  image_thumbnails_by_pk?: Maybe<ImageThumbnails>;
  /** fetch data from the table: "insight.storage_logs" */
  insight_storage_logs: Array<InsightStorageLogs>;
  /** fetch data from the table: "insight.storage_logs" using primary key columns */
  insight_storage_logs_by_pk?: Maybe<InsightStorageLogs>;
  /** fetch data from the table: "insight.transcoder_logs" */
  insight_transcoder_logs: Array<InsightTranscoderLogs>;
  /** fetch data from the table: "insight.transcoder_logs" using primary key columns */
  insight_transcoder_logs_by_pk?: Maybe<InsightTranscoderLogs>;
  /** fetch data from the table: "insight.transfer_logs" */
  insight_transfer_logs: Array<InsightTransferLogs>;
  /** fetch data from the table: "insight.transfer_logs" using primary key columns */
  insight_transfer_logs_by_pk?: Maybe<InsightTransferLogs>;
  /** fetch data from the table: "media_asset_types" */
  media_asset_types: Array<MediaAssetTypes>;
  /** fetch data from the table: "media_asset_types" using primary key columns */
  media_asset_types_by_pk?: Maybe<MediaAssetTypes>;
  /** An array relationship */
  media_assets: Array<MediaAssets>;
  /** fetch data from the table: "media_assets" using primary key columns */
  media_assets_by_pk?: Maybe<MediaAssets>;
  /** fetch data from the table: "media.live_stream_sessions" */
  media_live_stream_sessions: Array<MediaLiveStreamSessions>;
  /** fetch data from the table: "media.live_stream_sessions" using primary key columns */
  media_live_stream_sessions_by_pk?: Maybe<MediaLiveStreamSessions>;
  /** fetch data from the table: "media.live_streams" */
  media_live_streams: Array<MediaLiveStreams>;
  /** fetch data from the table: "media.live_streams" using primary key columns */
  media_live_streams_by_pk?: Maybe<MediaLiveStreams>;
  /** fetch data from the table: "meta_boolean_values" */
  meta_boolean_values: Array<MetaBooleanValues>;
  /** fetch data from the table: "meta_boolean_values" using primary key columns */
  meta_boolean_values_by_pk?: Maybe<MetaBooleanValues>;
  /** fetch data from the table: "meta_date_values" */
  meta_date_values: Array<MetaDateValues>;
  /** fetch data from the table: "meta_date_values" using primary key columns */
  meta_date_values_by_pk?: Maybe<MetaDateValues>;
  /** An array relationship */
  meta_field_values: Array<MetaFieldValues>;
  /** fetch data from the table: "meta_field_values" using primary key columns */
  meta_field_values_by_pk?: Maybe<MetaFieldValues>;
  /** fetch data from the table: "meta_number_values" */
  meta_number_values: Array<MetaNumberValues>;
  /** fetch data from the table: "meta_number_values" using primary key columns */
  meta_number_values_by_pk?: Maybe<MetaNumberValues>;
  /** fetch data from the table: "meta_text_values" */
  meta_text_values: Array<MetaTextValues>;
  /** fetch data from the table: "meta_text_values" using primary key columns */
  meta_text_values_by_pk?: Maybe<MetaTextValues>;
  /** An array relationship */
  org_member_invites: Array<OrgMemberInvites>;
  /** fetch data from the table: "org_member_invites" using primary key columns */
  org_member_invites_by_pk?: Maybe<OrgMemberInvites>;
  /** An array relationship */
  org_members: Array<OrgMembers>;
  /** An aggregate relationship */
  org_members_aggregate: OrgMembersAggregate;
  /** fetch data from the table: "org_members" using primary key columns */
  org_members_by_pk?: Maybe<OrgMembers>;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: OrganizationsAggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table: "permission.folder_permissions" */
  permission_folder_permissions: Array<PermissionFolderPermissions>;
  /** fetch data from the table: "permission.media_permissions" */
  permission_media_permissions: Array<PermissionMediaPermissions>;
  /** fetch data from the table: "permission.meta_permissions" */
  permission_meta_permissions: Array<PermissionMetaPermissions>;
  /** fetch data from the table: "permission.org_member_roles" */
  permission_org_member_roles: Array<PermissionOrgMemberRoles>;
  /** fetch aggregated fields from the table: "permission.org_member_roles" */
  permission_org_member_roles_aggregate: PermissionOrgMemberRolesAggregate;
  /** fetch data from the table: "permission.org_member_roles" using primary key columns */
  permission_org_member_roles_by_pk?: Maybe<PermissionOrgMemberRoles>;
  /** fetch data from the table: "permission.role_folder_scopes" */
  permission_role_folder_scopes: Array<PermissionRoleFolderScopes>;
  /** fetch aggregated fields from the table: "permission.role_folder_scopes" */
  permission_role_folder_scopes_aggregate: PermissionRoleFolderScopesAggregate;
  /** fetch data from the table: "permission.role_folder_scopes" using primary key columns */
  permission_role_folder_scopes_by_pk?: Maybe<PermissionRoleFolderScopes>;
  /** fetch data from the table: "permission.role_meta_scopes" */
  permission_role_meta_scopes: Array<PermissionRoleMetaScopes>;
  /** fetch aggregated fields from the table: "permission.role_meta_scopes" */
  permission_role_meta_scopes_aggregate: PermissionRoleMetaScopesAggregate;
  /** fetch data from the table: "permission.role_meta_scopes" using primary key columns */
  permission_role_meta_scopes_by_pk?: Maybe<PermissionRoleMetaScopes>;
  /** fetch data from the table: "permission.roles" */
  permission_roles: Array<PermissionRoles>;
  /** fetch aggregated fields from the table: "permission.roles" */
  permission_roles_aggregate: PermissionRolesAggregate;
  /** fetch data from the table: "permission.roles" using primary key columns */
  permission_roles_by_pk?: Maybe<PermissionRoles>;
  /** fetch data from the table: "permission.scopes" */
  permission_scopes: Array<PermissionScopes>;
  /** fetch aggregated fields from the table: "permission.scopes" */
  permission_scopes_aggregate: PermissionScopesAggregate;
  /** fetch data from the table: "permission.scopes" using primary key columns */
  permission_scopes_by_pk?: Maybe<PermissionScopes>;
  /** fetch data from the table: "permission.user_group_members" */
  permission_user_group_members: Array<PermissionUserGroupMembers>;
  /** fetch aggregated fields from the table: "permission.user_group_members" */
  permission_user_group_members_aggregate: PermissionUserGroupMembersAggregate;
  /** fetch data from the table: "permission.user_group_members" using primary key columns */
  permission_user_group_members_by_pk?: Maybe<PermissionUserGroupMembers>;
  /** fetch data from the table: "permission.user_group_roles" */
  permission_user_group_roles: Array<PermissionUserGroupRoles>;
  /** fetch aggregated fields from the table: "permission.user_group_roles" */
  permission_user_group_roles_aggregate: PermissionUserGroupRolesAggregate;
  /** fetch data from the table: "permission.user_group_roles" using primary key columns */
  permission_user_group_roles_by_pk?: Maybe<PermissionUserGroupRoles>;
  /** fetch data from the table: "permission.user_group_users" */
  permission_user_group_users: Array<PermissionUserGroupUsers>;
  /** fetch data from the table: "permission.user_groups" */
  permission_user_groups: Array<PermissionUserGroups>;
  /** fetch aggregated fields from the table: "permission.user_groups" */
  permission_user_groups_aggregate: PermissionUserGroupsAggregate;
  /** fetch data from the table: "permission.user_groups" using primary key columns */
  permission_user_groups_by_pk?: Maybe<PermissionUserGroups>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projects_aggregate: ProjectsAggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** An array relationship */
  thumbnail_profiles: Array<ThumbnailProfiles>;
  /** fetch data from the table: "thumbnail_profiles" using primary key columns */
  thumbnail_profiles_by_pk?: Maybe<ThumbnailProfiles>;
  /** fetch data from the table: "ticketing.access_codes" */
  ticketing_access_codes: Array<TicketingAccessCodes>;
  /** fetch data from the table: "ticketing.access_codes" using primary key columns */
  ticketing_access_codes_by_pk?: Maybe<TicketingAccessCodes>;
  /** fetch data from the table: "ticketing.access_tokens" */
  ticketing_access_tokens: Array<TicketingAccessTokens>;
  /** fetch aggregated fields from the table: "ticketing.access_tokens" */
  ticketing_access_tokens_aggregate: TicketingAccessTokensAggregate;
  /** fetch data from the table: "ticketing.access_tokens" using primary key columns */
  ticketing_access_tokens_by_pk?: Maybe<TicketingAccessTokens>;
  /** fetch data from the table: "ticketing.ticket_options" */
  ticketing_ticket_options: Array<TicketingTicketOptions>;
  /** fetch data from the table: "ticketing.ticket_options" using primary key columns */
  ticketing_ticket_options_by_pk?: Maybe<TicketingTicketOptions>;
  /** fetch data from the table: "transcoder_job_status_types" */
  transcoder_job_status_types: Array<TranscoderJobStatusTypes>;
  /** fetch data from the table: "transcoder_job_status_types" using primary key columns */
  transcoder_job_status_types_by_pk?: Maybe<TranscoderJobStatusTypes>;
  /** An array relationship */
  transcoder_jobs: Array<TranscoderJobs>;
  /** fetch data from the table: "transcoder_jobs" using primary key columns */
  transcoder_jobs_by_pk?: Maybe<TranscoderJobs>;
  /** An array relationship */
  transcoder_profiles: Array<TranscoderProfiles>;
  /** fetch data from the table: "transcoder_profiles" using primary key columns */
  transcoder_profiles_by_pk?: Maybe<TranscoderProfiles>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "video_properties" */
  video_properties: Array<VideoProperties>;
  /** fetch data from the table: "video_properties" using primary key columns */
  video_properties_by_pk?: Maybe<VideoProperties>;
  /** fetch data from the table: "vl_config" */
  vl_config: Array<VlConfig>;
  /** fetch data from the table: "vl_config" using primary key columns */
  vl_config_by_pk?: Maybe<VlConfig>;
}

export interface QueryRootArchiveJobsArgs {
  distinct_on?: Maybe<Array<ArchiveJobsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ArchiveJobsOrderBy>>;
  where?: Maybe<ArchiveJobsBoolExp>;
}

export interface QueryRootArchiveJobsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootAssetFileTypesArgs {
  distinct_on?: Maybe<Array<AssetFileTypesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetFileTypesOrderBy>>;
  where?: Maybe<AssetFileTypesBoolExp>;
}

export interface QueryRootAssetFileTypesByPkArgs {
  type: Scalars["String"];
}

export interface QueryRootAssetFilesArgs {
  distinct_on?: Maybe<Array<AssetFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetFilesOrderBy>>;
  where?: Maybe<AssetFilesBoolExp>;
}

export interface QueryRootAssetFilesAggregateArgs {
  distinct_on?: Maybe<Array<AssetFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetFilesOrderBy>>;
  where?: Maybe<AssetFilesBoolExp>;
}

export interface QueryRootAssetFilesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootAssetMetadataFieldsArgs {
  distinct_on?: Maybe<Array<AssetMetadataFieldsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetMetadataFieldsOrderBy>>;
  where?: Maybe<AssetMetadataFieldsBoolExp>;
}

export interface QueryRootAssetMetadataFieldsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootAssetMetadataTypesArgs {
  distinct_on?: Maybe<Array<AssetMetadataTypesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetMetadataTypesOrderBy>>;
  where?: Maybe<AssetMetadataTypesBoolExp>;
}

export interface QueryRootAssetMetadataTypesByPkArgs {
  type: Scalars["String"];
}

export interface QueryRootAssetMetadataValuesArgs {
  distinct_on?: Maybe<Array<AssetMetadataValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetMetadataValuesOrderBy>>;
  where?: Maybe<AssetMetadataValuesBoolExp>;
}

export interface QueryRootAssetMetadataValuesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootCmsContentRowsArgs {
  distinct_on?: Maybe<Array<CmsContentRowsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CmsContentRowsOrderBy>>;
  where?: Maybe<CmsContentRowsBoolExp>;
}

export interface QueryRootCmsContentRowsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootCmsContentSchemasArgs {
  distinct_on?: Maybe<Array<CmsContentSchemasSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CmsContentSchemasOrderBy>>;
  where?: Maybe<CmsContentSchemasBoolExp>;
}

export interface QueryRootCmsContentSchemasByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootCmsProjectsArgs {
  distinct_on?: Maybe<Array<CmsProjectsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CmsProjectsOrderBy>>;
  where?: Maybe<CmsProjectsBoolExp>;
}

export interface QueryRootCmsProjectsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootCmsPublicationsArgs {
  distinct_on?: Maybe<Array<CmsPublicationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CmsPublicationsOrderBy>>;
  where?: Maybe<CmsPublicationsBoolExp>;
}

export interface QueryRootCmsPublicationsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootCmsResourcesArgs {
  distinct_on?: Maybe<Array<CmsResourcesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CmsResourcesOrderBy>>;
  where?: Maybe<CmsResourcesBoolExp>;
}

export interface QueryRootCmsResourcesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootFeLinkShareArgs {
  distinct_on?: Maybe<Array<FeLinkShareSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FeLinkShareOrderBy>>;
  where?: Maybe<FeLinkShareBoolExp>;
}

export interface QueryRootFeLinkShareByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootFolderHierarchyArgs {
  distinct_on?: Maybe<Array<FolderHierarchySelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FolderHierarchyOrderBy>>;
  where?: Maybe<FolderHierarchyBoolExp>;
}

export interface QueryRootFolderHierarchyByPkArgs {
  id: Scalars["Int"];
}

export interface QueryRootFoldersArgs {
  distinct_on?: Maybe<Array<FoldersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FoldersOrderBy>>;
  where?: Maybe<FoldersBoolExp>;
}

export interface QueryRootFoldersByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootImageThumbnailJobsArgs {
  distinct_on?: Maybe<Array<ImageThumbnailJobsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ImageThumbnailJobsOrderBy>>;
  where?: Maybe<ImageThumbnailJobsBoolExp>;
}

export interface QueryRootImageThumbnailJobsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootImageThumbnailsArgs {
  distinct_on?: Maybe<Array<ImageThumbnailsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ImageThumbnailsOrderBy>>;
  where?: Maybe<ImageThumbnailsBoolExp>;
}

export interface QueryRootImageThumbnailsByPkArgs {
  thumbnail_file_id: Scalars["uuid"];
}

export interface QueryRootInsightStorageLogsArgs {
  distinct_on?: Maybe<Array<InsightStorageLogsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InsightStorageLogsOrderBy>>;
  where?: Maybe<InsightStorageLogsBoolExp>;
}

export interface QueryRootInsightStorageLogsByPkArgs {
  id: Scalars["bigint"];
}

export interface QueryRootInsightTranscoderLogsArgs {
  distinct_on?: Maybe<Array<InsightTranscoderLogsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InsightTranscoderLogsOrderBy>>;
  where?: Maybe<InsightTranscoderLogsBoolExp>;
}

export interface QueryRootInsightTranscoderLogsByPkArgs {
  id: Scalars["bigint"];
}

export interface QueryRootInsightTransferLogsArgs {
  distinct_on?: Maybe<Array<InsightTransferLogsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InsightTransferLogsOrderBy>>;
  where?: Maybe<InsightTransferLogsBoolExp>;
}

export interface QueryRootInsightTransferLogsByPkArgs {
  id: Scalars["bigint"];
}

export interface QueryRootMediaAssetTypesArgs {
  distinct_on?: Maybe<Array<MediaAssetTypesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaAssetTypesOrderBy>>;
  where?: Maybe<MediaAssetTypesBoolExp>;
}

export interface QueryRootMediaAssetTypesByPkArgs {
  type: Scalars["String"];
}

export interface QueryRootMediaAssetsArgs {
  distinct_on?: Maybe<Array<MediaAssetsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaAssetsOrderBy>>;
  where?: Maybe<MediaAssetsBoolExp>;
}

export interface QueryRootMediaAssetsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootMediaLiveStreamSessionsArgs {
  distinct_on?: Maybe<Array<MediaLiveStreamSessionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaLiveStreamSessionsOrderBy>>;
  where?: Maybe<MediaLiveStreamSessionsBoolExp>;
}

export interface QueryRootMediaLiveStreamSessionsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootMediaLiveStreamsArgs {
  distinct_on?: Maybe<Array<MediaLiveStreamsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaLiveStreamsOrderBy>>;
  where?: Maybe<MediaLiveStreamsBoolExp>;
}

export interface QueryRootMediaLiveStreamsByPkArgs {
  stream_key: Scalars["uuid"];
}

export interface QueryRootMetaBooleanValuesArgs {
  distinct_on?: Maybe<Array<MetaBooleanValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaBooleanValuesOrderBy>>;
  where?: Maybe<MetaBooleanValuesBoolExp>;
}

export interface QueryRootMetaBooleanValuesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootMetaDateValuesArgs {
  distinct_on?: Maybe<Array<MetaDateValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaDateValuesOrderBy>>;
  where?: Maybe<MetaDateValuesBoolExp>;
}

export interface QueryRootMetaDateValuesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootMetaFieldValuesArgs {
  distinct_on?: Maybe<Array<MetaFieldValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaFieldValuesOrderBy>>;
  where?: Maybe<MetaFieldValuesBoolExp>;
}

export interface QueryRootMetaFieldValuesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootMetaNumberValuesArgs {
  distinct_on?: Maybe<Array<MetaNumberValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaNumberValuesOrderBy>>;
  where?: Maybe<MetaNumberValuesBoolExp>;
}

export interface QueryRootMetaNumberValuesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootMetaTextValuesArgs {
  distinct_on?: Maybe<Array<MetaTextValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaTextValuesOrderBy>>;
  where?: Maybe<MetaTextValuesBoolExp>;
}

export interface QueryRootMetaTextValuesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootOrgMemberInvitesArgs {
  distinct_on?: Maybe<Array<OrgMemberInvitesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OrgMemberInvitesOrderBy>>;
  where?: Maybe<OrgMemberInvitesBoolExp>;
}

export interface QueryRootOrgMemberInvitesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootOrgMembersArgs {
  distinct_on?: Maybe<Array<OrgMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OrgMembersOrderBy>>;
  where?: Maybe<OrgMembersBoolExp>;
}

export interface QueryRootOrgMembersAggregateArgs {
  distinct_on?: Maybe<Array<OrgMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OrgMembersOrderBy>>;
  where?: Maybe<OrgMembersBoolExp>;
}

export interface QueryRootOrgMembersByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootOrganizationsArgs {
  distinct_on?: Maybe<Array<OrganizationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OrganizationsOrderBy>>;
  where?: Maybe<OrganizationsBoolExp>;
}

export interface QueryRootOrganizationsAggregateArgs {
  distinct_on?: Maybe<Array<OrganizationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OrganizationsOrderBy>>;
  where?: Maybe<OrganizationsBoolExp>;
}

export interface QueryRootOrganizationsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootPermissionFolderPermissionsArgs {
  distinct_on?: Maybe<Array<PermissionFolderPermissionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionFolderPermissionsOrderBy>>;
  where?: Maybe<PermissionFolderPermissionsBoolExp>;
}

export interface QueryRootPermissionMediaPermissionsArgs {
  distinct_on?: Maybe<Array<PermissionMediaPermissionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionMediaPermissionsOrderBy>>;
  where?: Maybe<PermissionMediaPermissionsBoolExp>;
}

export interface QueryRootPermissionMetaPermissionsArgs {
  distinct_on?: Maybe<Array<PermissionMetaPermissionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionMetaPermissionsOrderBy>>;
  where?: Maybe<PermissionMetaPermissionsBoolExp>;
}

export interface QueryRootPermissionOrgMemberRolesArgs {
  distinct_on?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionOrgMemberRolesOrderBy>>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

export interface QueryRootPermissionOrgMemberRolesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionOrgMemberRolesOrderBy>>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

export interface QueryRootPermissionOrgMemberRolesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootPermissionRoleFolderScopesArgs {
  distinct_on?: Maybe<Array<PermissionRoleFolderScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleFolderScopesOrderBy>>;
  where?: Maybe<PermissionRoleFolderScopesBoolExp>;
}

export interface QueryRootPermissionRoleFolderScopesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionRoleFolderScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleFolderScopesOrderBy>>;
  where?: Maybe<PermissionRoleFolderScopesBoolExp>;
}

export interface QueryRootPermissionRoleFolderScopesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootPermissionRoleMetaScopesArgs {
  distinct_on?: Maybe<Array<PermissionRoleMetaScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleMetaScopesOrderBy>>;
  where?: Maybe<PermissionRoleMetaScopesBoolExp>;
}

export interface QueryRootPermissionRoleMetaScopesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionRoleMetaScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleMetaScopesOrderBy>>;
  where?: Maybe<PermissionRoleMetaScopesBoolExp>;
}

export interface QueryRootPermissionRoleMetaScopesByPkArgs {
  field_id: Scalars["uuid"];
  role_id: Scalars["uuid"];
}

export interface QueryRootPermissionRolesArgs {
  distinct_on?: Maybe<Array<PermissionRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRolesOrderBy>>;
  where?: Maybe<PermissionRolesBoolExp>;
}

export interface QueryRootPermissionRolesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRolesOrderBy>>;
  where?: Maybe<PermissionRolesBoolExp>;
}

export interface QueryRootPermissionRolesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootPermissionScopesArgs {
  distinct_on?: Maybe<Array<PermissionScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionScopesOrderBy>>;
  where?: Maybe<PermissionScopesBoolExp>;
}

export interface QueryRootPermissionScopesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionScopesOrderBy>>;
  where?: Maybe<PermissionScopesBoolExp>;
}

export interface QueryRootPermissionScopesByPkArgs {
  scope: Scalars["String"];
}

export interface QueryRootPermissionUserGroupMembersArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupMembersOrderBy>>;
  where?: Maybe<PermissionUserGroupMembersBoolExp>;
}

export interface QueryRootPermissionUserGroupMembersAggregateArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupMembersOrderBy>>;
  where?: Maybe<PermissionUserGroupMembersBoolExp>;
}

export interface QueryRootPermissionUserGroupMembersByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootPermissionUserGroupRolesArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupRolesOrderBy>>;
  where?: Maybe<PermissionUserGroupRolesBoolExp>;
}

export interface QueryRootPermissionUserGroupRolesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupRolesOrderBy>>;
  where?: Maybe<PermissionUserGroupRolesBoolExp>;
}

export interface QueryRootPermissionUserGroupRolesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootPermissionUserGroupUsersArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupUsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupUsersOrderBy>>;
  where?: Maybe<PermissionUserGroupUsersBoolExp>;
}

export interface QueryRootPermissionUserGroupsArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupsOrderBy>>;
  where?: Maybe<PermissionUserGroupsBoolExp>;
}

export interface QueryRootPermissionUserGroupsAggregateArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupsOrderBy>>;
  where?: Maybe<PermissionUserGroupsBoolExp>;
}

export interface QueryRootPermissionUserGroupsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootProjectsArgs {
  distinct_on?: Maybe<Array<ProjectsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ProjectsOrderBy>>;
  where?: Maybe<ProjectsBoolExp>;
}

export interface QueryRootProjectsAggregateArgs {
  distinct_on?: Maybe<Array<ProjectsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ProjectsOrderBy>>;
  where?: Maybe<ProjectsBoolExp>;
}

export interface QueryRootProjectsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootThumbnailProfilesArgs {
  distinct_on?: Maybe<Array<ThumbnailProfilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ThumbnailProfilesOrderBy>>;
  where?: Maybe<ThumbnailProfilesBoolExp>;
}

export interface QueryRootThumbnailProfilesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootTicketingAccessCodesArgs {
  distinct_on?: Maybe<Array<TicketingAccessCodesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TicketingAccessCodesOrderBy>>;
  where?: Maybe<TicketingAccessCodesBoolExp>;
}

export interface QueryRootTicketingAccessCodesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootTicketingAccessTokensArgs {
  distinct_on?: Maybe<Array<TicketingAccessTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TicketingAccessTokensOrderBy>>;
  where?: Maybe<TicketingAccessTokensBoolExp>;
}

export interface QueryRootTicketingAccessTokensAggregateArgs {
  distinct_on?: Maybe<Array<TicketingAccessTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TicketingAccessTokensOrderBy>>;
  where?: Maybe<TicketingAccessTokensBoolExp>;
}

export interface QueryRootTicketingAccessTokensByPkArgs {
  id: Scalars["String"];
}

export interface QueryRootTicketingTicketOptionsArgs {
  distinct_on?: Maybe<Array<TicketingTicketOptionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TicketingTicketOptionsOrderBy>>;
  where?: Maybe<TicketingTicketOptionsBoolExp>;
}

export interface QueryRootTicketingTicketOptionsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootTranscoderJobStatusTypesArgs {
  distinct_on?: Maybe<Array<TranscoderJobStatusTypesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TranscoderJobStatusTypesOrderBy>>;
  where?: Maybe<TranscoderJobStatusTypesBoolExp>;
}

export interface QueryRootTranscoderJobStatusTypesByPkArgs {
  type: Scalars["String"];
}

export interface QueryRootTranscoderJobsArgs {
  distinct_on?: Maybe<Array<TranscoderJobsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TranscoderJobsOrderBy>>;
  where?: Maybe<TranscoderJobsBoolExp>;
}

export interface QueryRootTranscoderJobsByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootTranscoderProfilesArgs {
  distinct_on?: Maybe<Array<TranscoderProfilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TranscoderProfilesOrderBy>>;
  where?: Maybe<TranscoderProfilesBoolExp>;
}

export interface QueryRootTranscoderProfilesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootUsersArgs {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
}

export interface QueryRootUsersAggregateArgs {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
}

export interface QueryRootUsersByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootVideoPropertiesArgs {
  distinct_on?: Maybe<Array<VideoPropertiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VideoPropertiesOrderBy>>;
  where?: Maybe<VideoPropertiesBoolExp>;
}

export interface QueryRootVideoPropertiesByPkArgs {
  id: Scalars["uuid"];
}

export interface QueryRootVlConfigArgs {
  distinct_on?: Maybe<Array<VlConfigSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VlConfigOrderBy>>;
  where?: Maybe<VlConfigBoolExp>;
}

export interface QueryRootVlConfigByPkArgs {
  id: Scalars["String"];
}

export interface SubscriptionRoot {
  /** fetch data from the table: "archive_jobs" */
  archive_jobs: Array<ArchiveJobs>;
  /** fetch data from the table: "archive_jobs" using primary key columns */
  archive_jobs_by_pk?: Maybe<ArchiveJobs>;
  /** fetch data from the table in a streaming manner: "archive_jobs" */
  archive_jobs_stream: Array<ArchiveJobs>;
  /** fetch data from the table: "asset_file_types" */
  asset_file_types: Array<AssetFileTypes>;
  /** fetch data from the table: "asset_file_types" using primary key columns */
  asset_file_types_by_pk?: Maybe<AssetFileTypes>;
  /** fetch data from the table in a streaming manner: "asset_file_types" */
  asset_file_types_stream: Array<AssetFileTypes>;
  /** An array relationship */
  asset_files: Array<AssetFiles>;
  /** An aggregate relationship */
  asset_files_aggregate: AssetFilesAggregate;
  /** fetch data from the table: "asset_files" using primary key columns */
  asset_files_by_pk?: Maybe<AssetFiles>;
  /** fetch data from the table in a streaming manner: "asset_files" */
  asset_files_stream: Array<AssetFiles>;
  /** An array relationship */
  asset_metadata_fields: Array<AssetMetadataFields>;
  /** fetch data from the table: "asset_metadata_fields" using primary key columns */
  asset_metadata_fields_by_pk?: Maybe<AssetMetadataFields>;
  /** fetch data from the table in a streaming manner: "asset_metadata_fields" */
  asset_metadata_fields_stream: Array<AssetMetadataFields>;
  /** fetch data from the table: "asset_metadata_types" */
  asset_metadata_types: Array<AssetMetadataTypes>;
  /** fetch data from the table: "asset_metadata_types" using primary key columns */
  asset_metadata_types_by_pk?: Maybe<AssetMetadataTypes>;
  /** fetch data from the table in a streaming manner: "asset_metadata_types" */
  asset_metadata_types_stream: Array<AssetMetadataTypes>;
  /** fetch data from the table: "asset_metadata_values" */
  asset_metadata_values: Array<AssetMetadataValues>;
  /** fetch data from the table: "asset_metadata_values" using primary key columns */
  asset_metadata_values_by_pk?: Maybe<AssetMetadataValues>;
  /** fetch data from the table in a streaming manner: "asset_metadata_values" */
  asset_metadata_values_stream: Array<AssetMetadataValues>;
  /** fetch data from the table: "cms.content_rows" */
  cms_content_rows: Array<CmsContentRows>;
  /** fetch data from the table: "cms.content_rows" using primary key columns */
  cms_content_rows_by_pk?: Maybe<CmsContentRows>;
  /** fetch data from the table in a streaming manner: "cms.content_rows" */
  cms_content_rows_stream: Array<CmsContentRows>;
  /** fetch data from the table: "cms.content_schemas" */
  cms_content_schemas: Array<CmsContentSchemas>;
  /** fetch data from the table: "cms.content_schemas" using primary key columns */
  cms_content_schemas_by_pk?: Maybe<CmsContentSchemas>;
  /** fetch data from the table in a streaming manner: "cms.content_schemas" */
  cms_content_schemas_stream: Array<CmsContentSchemas>;
  /** fetch data from the table: "cms.projects" */
  cms_projects: Array<CmsProjects>;
  /** fetch data from the table: "cms.projects" using primary key columns */
  cms_projects_by_pk?: Maybe<CmsProjects>;
  /** fetch data from the table in a streaming manner: "cms.projects" */
  cms_projects_stream: Array<CmsProjects>;
  /** fetch data from the table: "cms.publications" */
  cms_publications: Array<CmsPublications>;
  /** fetch data from the table: "cms.publications" using primary key columns */
  cms_publications_by_pk?: Maybe<CmsPublications>;
  /** fetch data from the table in a streaming manner: "cms.publications" */
  cms_publications_stream: Array<CmsPublications>;
  /** fetch data from the table: "cms.resources" */
  cms_resources: Array<CmsResources>;
  /** fetch data from the table: "cms.resources" using primary key columns */
  cms_resources_by_pk?: Maybe<CmsResources>;
  /** fetch data from the table in a streaming manner: "cms.resources" */
  cms_resources_stream: Array<CmsResources>;
  /** fetch data from the table: "fe.link_share" */
  fe_link_share: Array<FeLinkShare>;
  /** fetch data from the table: "fe.link_share" using primary key columns */
  fe_link_share_by_pk?: Maybe<FeLinkShare>;
  /** fetch data from the table in a streaming manner: "fe.link_share" */
  fe_link_share_stream: Array<FeLinkShare>;
  /** fetch data from the table: "folder_hierarchy" */
  folder_hierarchy: Array<FolderHierarchy>;
  /** fetch data from the table: "folder_hierarchy" using primary key columns */
  folder_hierarchy_by_pk?: Maybe<FolderHierarchy>;
  /** fetch data from the table in a streaming manner: "folder_hierarchy" */
  folder_hierarchy_stream: Array<FolderHierarchy>;
  /** An array relationship */
  folders: Array<Folders>;
  /** fetch data from the table: "folders" using primary key columns */
  folders_by_pk?: Maybe<Folders>;
  /** fetch data from the table in a streaming manner: "folders" */
  folders_stream: Array<Folders>;
  /** An array relationship */
  image_thumbnail_jobs: Array<ImageThumbnailJobs>;
  /** fetch data from the table: "image_thumbnail_jobs" using primary key columns */
  image_thumbnail_jobs_by_pk?: Maybe<ImageThumbnailJobs>;
  /** fetch data from the table in a streaming manner: "image_thumbnail_jobs" */
  image_thumbnail_jobs_stream: Array<ImageThumbnailJobs>;
  /** An array relationship */
  image_thumbnails: Array<ImageThumbnails>;
  /** fetch data from the table: "image_thumbnails" using primary key columns */
  image_thumbnails_by_pk?: Maybe<ImageThumbnails>;
  /** fetch data from the table in a streaming manner: "image_thumbnails" */
  image_thumbnails_stream: Array<ImageThumbnails>;
  /** fetch data from the table: "insight.storage_logs" */
  insight_storage_logs: Array<InsightStorageLogs>;
  /** fetch data from the table: "insight.storage_logs" using primary key columns */
  insight_storage_logs_by_pk?: Maybe<InsightStorageLogs>;
  /** fetch data from the table in a streaming manner: "insight.storage_logs" */
  insight_storage_logs_stream: Array<InsightStorageLogs>;
  /** fetch data from the table: "insight.transcoder_logs" */
  insight_transcoder_logs: Array<InsightTranscoderLogs>;
  /** fetch data from the table: "insight.transcoder_logs" using primary key columns */
  insight_transcoder_logs_by_pk?: Maybe<InsightTranscoderLogs>;
  /** fetch data from the table in a streaming manner: "insight.transcoder_logs" */
  insight_transcoder_logs_stream: Array<InsightTranscoderLogs>;
  /** fetch data from the table: "insight.transfer_logs" */
  insight_transfer_logs: Array<InsightTransferLogs>;
  /** fetch data from the table: "insight.transfer_logs" using primary key columns */
  insight_transfer_logs_by_pk?: Maybe<InsightTransferLogs>;
  /** fetch data from the table in a streaming manner: "insight.transfer_logs" */
  insight_transfer_logs_stream: Array<InsightTransferLogs>;
  /** fetch data from the table: "media_asset_types" */
  media_asset_types: Array<MediaAssetTypes>;
  /** fetch data from the table: "media_asset_types" using primary key columns */
  media_asset_types_by_pk?: Maybe<MediaAssetTypes>;
  /** fetch data from the table in a streaming manner: "media_asset_types" */
  media_asset_types_stream: Array<MediaAssetTypes>;
  /** An array relationship */
  media_assets: Array<MediaAssets>;
  /** fetch data from the table: "media_assets" using primary key columns */
  media_assets_by_pk?: Maybe<MediaAssets>;
  /** fetch data from the table in a streaming manner: "media_assets" */
  media_assets_stream: Array<MediaAssets>;
  /** fetch data from the table: "media.live_stream_sessions" */
  media_live_stream_sessions: Array<MediaLiveStreamSessions>;
  /** fetch data from the table: "media.live_stream_sessions" using primary key columns */
  media_live_stream_sessions_by_pk?: Maybe<MediaLiveStreamSessions>;
  /** fetch data from the table in a streaming manner: "media.live_stream_sessions" */
  media_live_stream_sessions_stream: Array<MediaLiveStreamSessions>;
  /** fetch data from the table: "media.live_streams" */
  media_live_streams: Array<MediaLiveStreams>;
  /** fetch data from the table: "media.live_streams" using primary key columns */
  media_live_streams_by_pk?: Maybe<MediaLiveStreams>;
  /** fetch data from the table in a streaming manner: "media.live_streams" */
  media_live_streams_stream: Array<MediaLiveStreams>;
  /** fetch data from the table: "meta_boolean_values" */
  meta_boolean_values: Array<MetaBooleanValues>;
  /** fetch data from the table: "meta_boolean_values" using primary key columns */
  meta_boolean_values_by_pk?: Maybe<MetaBooleanValues>;
  /** fetch data from the table in a streaming manner: "meta_boolean_values" */
  meta_boolean_values_stream: Array<MetaBooleanValues>;
  /** fetch data from the table: "meta_date_values" */
  meta_date_values: Array<MetaDateValues>;
  /** fetch data from the table: "meta_date_values" using primary key columns */
  meta_date_values_by_pk?: Maybe<MetaDateValues>;
  /** fetch data from the table in a streaming manner: "meta_date_values" */
  meta_date_values_stream: Array<MetaDateValues>;
  /** An array relationship */
  meta_field_values: Array<MetaFieldValues>;
  /** fetch data from the table: "meta_field_values" using primary key columns */
  meta_field_values_by_pk?: Maybe<MetaFieldValues>;
  /** fetch data from the table in a streaming manner: "meta_field_values" */
  meta_field_values_stream: Array<MetaFieldValues>;
  /** fetch data from the table: "meta_number_values" */
  meta_number_values: Array<MetaNumberValues>;
  /** fetch data from the table: "meta_number_values" using primary key columns */
  meta_number_values_by_pk?: Maybe<MetaNumberValues>;
  /** fetch data from the table in a streaming manner: "meta_number_values" */
  meta_number_values_stream: Array<MetaNumberValues>;
  /** fetch data from the table: "meta_text_values" */
  meta_text_values: Array<MetaTextValues>;
  /** fetch data from the table: "meta_text_values" using primary key columns */
  meta_text_values_by_pk?: Maybe<MetaTextValues>;
  /** fetch data from the table in a streaming manner: "meta_text_values" */
  meta_text_values_stream: Array<MetaTextValues>;
  /** An array relationship */
  org_member_invites: Array<OrgMemberInvites>;
  /** fetch data from the table: "org_member_invites" using primary key columns */
  org_member_invites_by_pk?: Maybe<OrgMemberInvites>;
  /** fetch data from the table in a streaming manner: "org_member_invites" */
  org_member_invites_stream: Array<OrgMemberInvites>;
  /** An array relationship */
  org_members: Array<OrgMembers>;
  /** An aggregate relationship */
  org_members_aggregate: OrgMembersAggregate;
  /** fetch data from the table: "org_members" using primary key columns */
  org_members_by_pk?: Maybe<OrgMembers>;
  /** fetch data from the table in a streaming manner: "org_members" */
  org_members_stream: Array<OrgMembers>;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: OrganizationsAggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table in a streaming manner: "organizations" */
  organizations_stream: Array<Organizations>;
  /** fetch data from the table: "permission.folder_permissions" */
  permission_folder_permissions: Array<PermissionFolderPermissions>;
  /** fetch data from the table in a streaming manner: "permission.folder_permissions" */
  permission_folder_permissions_stream: Array<PermissionFolderPermissions>;
  /** fetch data from the table: "permission.media_permissions" */
  permission_media_permissions: Array<PermissionMediaPermissions>;
  /** fetch data from the table in a streaming manner: "permission.media_permissions" */
  permission_media_permissions_stream: Array<PermissionMediaPermissions>;
  /** fetch data from the table: "permission.meta_permissions" */
  permission_meta_permissions: Array<PermissionMetaPermissions>;
  /** fetch data from the table in a streaming manner: "permission.meta_permissions" */
  permission_meta_permissions_stream: Array<PermissionMetaPermissions>;
  /** fetch data from the table: "permission.org_member_roles" */
  permission_org_member_roles: Array<PermissionOrgMemberRoles>;
  /** fetch aggregated fields from the table: "permission.org_member_roles" */
  permission_org_member_roles_aggregate: PermissionOrgMemberRolesAggregate;
  /** fetch data from the table: "permission.org_member_roles" using primary key columns */
  permission_org_member_roles_by_pk?: Maybe<PermissionOrgMemberRoles>;
  /** fetch data from the table in a streaming manner: "permission.org_member_roles" */
  permission_org_member_roles_stream: Array<PermissionOrgMemberRoles>;
  /** fetch data from the table: "permission.role_folder_scopes" */
  permission_role_folder_scopes: Array<PermissionRoleFolderScopes>;
  /** fetch aggregated fields from the table: "permission.role_folder_scopes" */
  permission_role_folder_scopes_aggregate: PermissionRoleFolderScopesAggregate;
  /** fetch data from the table: "permission.role_folder_scopes" using primary key columns */
  permission_role_folder_scopes_by_pk?: Maybe<PermissionRoleFolderScopes>;
  /** fetch data from the table in a streaming manner: "permission.role_folder_scopes" */
  permission_role_folder_scopes_stream: Array<PermissionRoleFolderScopes>;
  /** fetch data from the table: "permission.role_meta_scopes" */
  permission_role_meta_scopes: Array<PermissionRoleMetaScopes>;
  /** fetch aggregated fields from the table: "permission.role_meta_scopes" */
  permission_role_meta_scopes_aggregate: PermissionRoleMetaScopesAggregate;
  /** fetch data from the table: "permission.role_meta_scopes" using primary key columns */
  permission_role_meta_scopes_by_pk?: Maybe<PermissionRoleMetaScopes>;
  /** fetch data from the table in a streaming manner: "permission.role_meta_scopes" */
  permission_role_meta_scopes_stream: Array<PermissionRoleMetaScopes>;
  /** fetch data from the table: "permission.roles" */
  permission_roles: Array<PermissionRoles>;
  /** fetch aggregated fields from the table: "permission.roles" */
  permission_roles_aggregate: PermissionRolesAggregate;
  /** fetch data from the table: "permission.roles" using primary key columns */
  permission_roles_by_pk?: Maybe<PermissionRoles>;
  /** fetch data from the table in a streaming manner: "permission.roles" */
  permission_roles_stream: Array<PermissionRoles>;
  /** fetch data from the table: "permission.scopes" */
  permission_scopes: Array<PermissionScopes>;
  /** fetch aggregated fields from the table: "permission.scopes" */
  permission_scopes_aggregate: PermissionScopesAggregate;
  /** fetch data from the table: "permission.scopes" using primary key columns */
  permission_scopes_by_pk?: Maybe<PermissionScopes>;
  /** fetch data from the table in a streaming manner: "permission.scopes" */
  permission_scopes_stream: Array<PermissionScopes>;
  /** fetch data from the table: "permission.user_group_members" */
  permission_user_group_members: Array<PermissionUserGroupMembers>;
  /** fetch aggregated fields from the table: "permission.user_group_members" */
  permission_user_group_members_aggregate: PermissionUserGroupMembersAggregate;
  /** fetch data from the table: "permission.user_group_members" using primary key columns */
  permission_user_group_members_by_pk?: Maybe<PermissionUserGroupMembers>;
  /** fetch data from the table in a streaming manner: "permission.user_group_members" */
  permission_user_group_members_stream: Array<PermissionUserGroupMembers>;
  /** fetch data from the table: "permission.user_group_roles" */
  permission_user_group_roles: Array<PermissionUserGroupRoles>;
  /** fetch aggregated fields from the table: "permission.user_group_roles" */
  permission_user_group_roles_aggregate: PermissionUserGroupRolesAggregate;
  /** fetch data from the table: "permission.user_group_roles" using primary key columns */
  permission_user_group_roles_by_pk?: Maybe<PermissionUserGroupRoles>;
  /** fetch data from the table in a streaming manner: "permission.user_group_roles" */
  permission_user_group_roles_stream: Array<PermissionUserGroupRoles>;
  /** fetch data from the table: "permission.user_group_users" */
  permission_user_group_users: Array<PermissionUserGroupUsers>;
  /** fetch data from the table in a streaming manner: "permission.user_group_users" */
  permission_user_group_users_stream: Array<PermissionUserGroupUsers>;
  /** fetch data from the table: "permission.user_groups" */
  permission_user_groups: Array<PermissionUserGroups>;
  /** fetch aggregated fields from the table: "permission.user_groups" */
  permission_user_groups_aggregate: PermissionUserGroupsAggregate;
  /** fetch data from the table: "permission.user_groups" using primary key columns */
  permission_user_groups_by_pk?: Maybe<PermissionUserGroups>;
  /** fetch data from the table in a streaming manner: "permission.user_groups" */
  permission_user_groups_stream: Array<PermissionUserGroups>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projects_aggregate: ProjectsAggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table in a streaming manner: "projects" */
  projects_stream: Array<Projects>;
  /** An array relationship */
  thumbnail_profiles: Array<ThumbnailProfiles>;
  /** fetch data from the table: "thumbnail_profiles" using primary key columns */
  thumbnail_profiles_by_pk?: Maybe<ThumbnailProfiles>;
  /** fetch data from the table in a streaming manner: "thumbnail_profiles" */
  thumbnail_profiles_stream: Array<ThumbnailProfiles>;
  /** fetch data from the table: "ticketing.access_codes" */
  ticketing_access_codes: Array<TicketingAccessCodes>;
  /** fetch data from the table: "ticketing.access_codes" using primary key columns */
  ticketing_access_codes_by_pk?: Maybe<TicketingAccessCodes>;
  /** fetch data from the table in a streaming manner: "ticketing.access_codes" */
  ticketing_access_codes_stream: Array<TicketingAccessCodes>;
  /** fetch data from the table: "ticketing.access_tokens" */
  ticketing_access_tokens: Array<TicketingAccessTokens>;
  /** fetch aggregated fields from the table: "ticketing.access_tokens" */
  ticketing_access_tokens_aggregate: TicketingAccessTokensAggregate;
  /** fetch data from the table: "ticketing.access_tokens" using primary key columns */
  ticketing_access_tokens_by_pk?: Maybe<TicketingAccessTokens>;
  /** fetch data from the table in a streaming manner: "ticketing.access_tokens" */
  ticketing_access_tokens_stream: Array<TicketingAccessTokens>;
  /** fetch data from the table: "ticketing.ticket_options" */
  ticketing_ticket_options: Array<TicketingTicketOptions>;
  /** fetch data from the table: "ticketing.ticket_options" using primary key columns */
  ticketing_ticket_options_by_pk?: Maybe<TicketingTicketOptions>;
  /** fetch data from the table in a streaming manner: "ticketing.ticket_options" */
  ticketing_ticket_options_stream: Array<TicketingTicketOptions>;
  /** fetch data from the table: "transcoder_job_status_types" */
  transcoder_job_status_types: Array<TranscoderJobStatusTypes>;
  /** fetch data from the table: "transcoder_job_status_types" using primary key columns */
  transcoder_job_status_types_by_pk?: Maybe<TranscoderJobStatusTypes>;
  /** fetch data from the table in a streaming manner: "transcoder_job_status_types" */
  transcoder_job_status_types_stream: Array<TranscoderJobStatusTypes>;
  /** An array relationship */
  transcoder_jobs: Array<TranscoderJobs>;
  /** fetch data from the table: "transcoder_jobs" using primary key columns */
  transcoder_jobs_by_pk?: Maybe<TranscoderJobs>;
  /** fetch data from the table in a streaming manner: "transcoder_jobs" */
  transcoder_jobs_stream: Array<TranscoderJobs>;
  /** An array relationship */
  transcoder_profiles: Array<TranscoderProfiles>;
  /** fetch data from the table: "transcoder_profiles" using primary key columns */
  transcoder_profiles_by_pk?: Maybe<TranscoderProfiles>;
  /** fetch data from the table in a streaming manner: "transcoder_profiles" */
  transcoder_profiles_stream: Array<TranscoderProfiles>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "video_properties" */
  video_properties: Array<VideoProperties>;
  /** fetch data from the table: "video_properties" using primary key columns */
  video_properties_by_pk?: Maybe<VideoProperties>;
  /** fetch data from the table in a streaming manner: "video_properties" */
  video_properties_stream: Array<VideoProperties>;
  /** fetch data from the table: "vl_config" */
  vl_config: Array<VlConfig>;
  /** fetch data from the table: "vl_config" using primary key columns */
  vl_config_by_pk?: Maybe<VlConfig>;
  /** fetch data from the table in a streaming manner: "vl_config" */
  vl_config_stream: Array<VlConfig>;
}

export interface SubscriptionRootArchiveJobsArgs {
  distinct_on?: Maybe<Array<ArchiveJobsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ArchiveJobsOrderBy>>;
  where?: Maybe<ArchiveJobsBoolExp>;
}

export interface SubscriptionRootArchiveJobsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootArchiveJobsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<ArchiveJobsStreamCursorInput>>;
  where?: Maybe<ArchiveJobsBoolExp>;
}

export interface SubscriptionRootAssetFileTypesArgs {
  distinct_on?: Maybe<Array<AssetFileTypesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetFileTypesOrderBy>>;
  where?: Maybe<AssetFileTypesBoolExp>;
}

export interface SubscriptionRootAssetFileTypesByPkArgs {
  type: Scalars["String"];
}

export interface SubscriptionRootAssetFileTypesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<AssetFileTypesStreamCursorInput>>;
  where?: Maybe<AssetFileTypesBoolExp>;
}

export interface SubscriptionRootAssetFilesArgs {
  distinct_on?: Maybe<Array<AssetFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetFilesOrderBy>>;
  where?: Maybe<AssetFilesBoolExp>;
}

export interface SubscriptionRootAssetFilesAggregateArgs {
  distinct_on?: Maybe<Array<AssetFilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetFilesOrderBy>>;
  where?: Maybe<AssetFilesBoolExp>;
}

export interface SubscriptionRootAssetFilesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootAssetFilesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<AssetFilesStreamCursorInput>>;
  where?: Maybe<AssetFilesBoolExp>;
}

export interface SubscriptionRootAssetMetadataFieldsArgs {
  distinct_on?: Maybe<Array<AssetMetadataFieldsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetMetadataFieldsOrderBy>>;
  where?: Maybe<AssetMetadataFieldsBoolExp>;
}

export interface SubscriptionRootAssetMetadataFieldsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootAssetMetadataFieldsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<AssetMetadataFieldsStreamCursorInput>>;
  where?: Maybe<AssetMetadataFieldsBoolExp>;
}

export interface SubscriptionRootAssetMetadataTypesArgs {
  distinct_on?: Maybe<Array<AssetMetadataTypesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetMetadataTypesOrderBy>>;
  where?: Maybe<AssetMetadataTypesBoolExp>;
}

export interface SubscriptionRootAssetMetadataTypesByPkArgs {
  type: Scalars["String"];
}

export interface SubscriptionRootAssetMetadataTypesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<AssetMetadataTypesStreamCursorInput>>;
  where?: Maybe<AssetMetadataTypesBoolExp>;
}

export interface SubscriptionRootAssetMetadataValuesArgs {
  distinct_on?: Maybe<Array<AssetMetadataValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<AssetMetadataValuesOrderBy>>;
  where?: Maybe<AssetMetadataValuesBoolExp>;
}

export interface SubscriptionRootAssetMetadataValuesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootAssetMetadataValuesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<AssetMetadataValuesStreamCursorInput>>;
  where?: Maybe<AssetMetadataValuesBoolExp>;
}

export interface SubscriptionRootCmsContentRowsArgs {
  distinct_on?: Maybe<Array<CmsContentRowsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CmsContentRowsOrderBy>>;
  where?: Maybe<CmsContentRowsBoolExp>;
}

export interface SubscriptionRootCmsContentRowsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootCmsContentRowsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<CmsContentRowsStreamCursorInput>>;
  where?: Maybe<CmsContentRowsBoolExp>;
}

export interface SubscriptionRootCmsContentSchemasArgs {
  distinct_on?: Maybe<Array<CmsContentSchemasSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CmsContentSchemasOrderBy>>;
  where?: Maybe<CmsContentSchemasBoolExp>;
}

export interface SubscriptionRootCmsContentSchemasByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootCmsContentSchemasStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<CmsContentSchemasStreamCursorInput>>;
  where?: Maybe<CmsContentSchemasBoolExp>;
}

export interface SubscriptionRootCmsProjectsArgs {
  distinct_on?: Maybe<Array<CmsProjectsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CmsProjectsOrderBy>>;
  where?: Maybe<CmsProjectsBoolExp>;
}

export interface SubscriptionRootCmsProjectsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootCmsProjectsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<CmsProjectsStreamCursorInput>>;
  where?: Maybe<CmsProjectsBoolExp>;
}

export interface SubscriptionRootCmsPublicationsArgs {
  distinct_on?: Maybe<Array<CmsPublicationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CmsPublicationsOrderBy>>;
  where?: Maybe<CmsPublicationsBoolExp>;
}

export interface SubscriptionRootCmsPublicationsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootCmsPublicationsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<CmsPublicationsStreamCursorInput>>;
  where?: Maybe<CmsPublicationsBoolExp>;
}

export interface SubscriptionRootCmsResourcesArgs {
  distinct_on?: Maybe<Array<CmsResourcesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<CmsResourcesOrderBy>>;
  where?: Maybe<CmsResourcesBoolExp>;
}

export interface SubscriptionRootCmsResourcesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootCmsResourcesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<CmsResourcesStreamCursorInput>>;
  where?: Maybe<CmsResourcesBoolExp>;
}

export interface SubscriptionRootFeLinkShareArgs {
  distinct_on?: Maybe<Array<FeLinkShareSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FeLinkShareOrderBy>>;
  where?: Maybe<FeLinkShareBoolExp>;
}

export interface SubscriptionRootFeLinkShareByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootFeLinkShareStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<FeLinkShareStreamCursorInput>>;
  where?: Maybe<FeLinkShareBoolExp>;
}

export interface SubscriptionRootFolderHierarchyArgs {
  distinct_on?: Maybe<Array<FolderHierarchySelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FolderHierarchyOrderBy>>;
  where?: Maybe<FolderHierarchyBoolExp>;
}

export interface SubscriptionRootFolderHierarchyByPkArgs {
  id: Scalars["Int"];
}

export interface SubscriptionRootFolderHierarchyStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<FolderHierarchyStreamCursorInput>>;
  where?: Maybe<FolderHierarchyBoolExp>;
}

export interface SubscriptionRootFoldersArgs {
  distinct_on?: Maybe<Array<FoldersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<FoldersOrderBy>>;
  where?: Maybe<FoldersBoolExp>;
}

export interface SubscriptionRootFoldersByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootFoldersStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<FoldersStreamCursorInput>>;
  where?: Maybe<FoldersBoolExp>;
}

export interface SubscriptionRootImageThumbnailJobsArgs {
  distinct_on?: Maybe<Array<ImageThumbnailJobsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ImageThumbnailJobsOrderBy>>;
  where?: Maybe<ImageThumbnailJobsBoolExp>;
}

export interface SubscriptionRootImageThumbnailJobsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootImageThumbnailJobsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<ImageThumbnailJobsStreamCursorInput>>;
  where?: Maybe<ImageThumbnailJobsBoolExp>;
}

export interface SubscriptionRootImageThumbnailsArgs {
  distinct_on?: Maybe<Array<ImageThumbnailsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ImageThumbnailsOrderBy>>;
  where?: Maybe<ImageThumbnailsBoolExp>;
}

export interface SubscriptionRootImageThumbnailsByPkArgs {
  thumbnail_file_id: Scalars["uuid"];
}

export interface SubscriptionRootImageThumbnailsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<ImageThumbnailsStreamCursorInput>>;
  where?: Maybe<ImageThumbnailsBoolExp>;
}

export interface SubscriptionRootInsightStorageLogsArgs {
  distinct_on?: Maybe<Array<InsightStorageLogsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InsightStorageLogsOrderBy>>;
  where?: Maybe<InsightStorageLogsBoolExp>;
}

export interface SubscriptionRootInsightStorageLogsByPkArgs {
  id: Scalars["bigint"];
}

export interface SubscriptionRootInsightStorageLogsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<InsightStorageLogsStreamCursorInput>>;
  where?: Maybe<InsightStorageLogsBoolExp>;
}

export interface SubscriptionRootInsightTranscoderLogsArgs {
  distinct_on?: Maybe<Array<InsightTranscoderLogsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InsightTranscoderLogsOrderBy>>;
  where?: Maybe<InsightTranscoderLogsBoolExp>;
}

export interface SubscriptionRootInsightTranscoderLogsByPkArgs {
  id: Scalars["bigint"];
}

export interface SubscriptionRootInsightTranscoderLogsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<InsightTranscoderLogsStreamCursorInput>>;
  where?: Maybe<InsightTranscoderLogsBoolExp>;
}

export interface SubscriptionRootInsightTransferLogsArgs {
  distinct_on?: Maybe<Array<InsightTransferLogsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<InsightTransferLogsOrderBy>>;
  where?: Maybe<InsightTransferLogsBoolExp>;
}

export interface SubscriptionRootInsightTransferLogsByPkArgs {
  id: Scalars["bigint"];
}

export interface SubscriptionRootInsightTransferLogsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<InsightTransferLogsStreamCursorInput>>;
  where?: Maybe<InsightTransferLogsBoolExp>;
}

export interface SubscriptionRootMediaAssetTypesArgs {
  distinct_on?: Maybe<Array<MediaAssetTypesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaAssetTypesOrderBy>>;
  where?: Maybe<MediaAssetTypesBoolExp>;
}

export interface SubscriptionRootMediaAssetTypesByPkArgs {
  type: Scalars["String"];
}

export interface SubscriptionRootMediaAssetTypesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<MediaAssetTypesStreamCursorInput>>;
  where?: Maybe<MediaAssetTypesBoolExp>;
}

export interface SubscriptionRootMediaAssetsArgs {
  distinct_on?: Maybe<Array<MediaAssetsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaAssetsOrderBy>>;
  where?: Maybe<MediaAssetsBoolExp>;
}

export interface SubscriptionRootMediaAssetsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootMediaAssetsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<MediaAssetsStreamCursorInput>>;
  where?: Maybe<MediaAssetsBoolExp>;
}

export interface SubscriptionRootMediaLiveStreamSessionsArgs {
  distinct_on?: Maybe<Array<MediaLiveStreamSessionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaLiveStreamSessionsOrderBy>>;
  where?: Maybe<MediaLiveStreamSessionsBoolExp>;
}

export interface SubscriptionRootMediaLiveStreamSessionsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootMediaLiveStreamSessionsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<MediaLiveStreamSessionsStreamCursorInput>>;
  where?: Maybe<MediaLiveStreamSessionsBoolExp>;
}

export interface SubscriptionRootMediaLiveStreamsArgs {
  distinct_on?: Maybe<Array<MediaLiveStreamsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MediaLiveStreamsOrderBy>>;
  where?: Maybe<MediaLiveStreamsBoolExp>;
}

export interface SubscriptionRootMediaLiveStreamsByPkArgs {
  stream_key: Scalars["uuid"];
}

export interface SubscriptionRootMediaLiveStreamsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<MediaLiveStreamsStreamCursorInput>>;
  where?: Maybe<MediaLiveStreamsBoolExp>;
}

export interface SubscriptionRootMetaBooleanValuesArgs {
  distinct_on?: Maybe<Array<MetaBooleanValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaBooleanValuesOrderBy>>;
  where?: Maybe<MetaBooleanValuesBoolExp>;
}

export interface SubscriptionRootMetaBooleanValuesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootMetaBooleanValuesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<MetaBooleanValuesStreamCursorInput>>;
  where?: Maybe<MetaBooleanValuesBoolExp>;
}

export interface SubscriptionRootMetaDateValuesArgs {
  distinct_on?: Maybe<Array<MetaDateValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaDateValuesOrderBy>>;
  where?: Maybe<MetaDateValuesBoolExp>;
}

export interface SubscriptionRootMetaDateValuesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootMetaDateValuesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<MetaDateValuesStreamCursorInput>>;
  where?: Maybe<MetaDateValuesBoolExp>;
}

export interface SubscriptionRootMetaFieldValuesArgs {
  distinct_on?: Maybe<Array<MetaFieldValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaFieldValuesOrderBy>>;
  where?: Maybe<MetaFieldValuesBoolExp>;
}

export interface SubscriptionRootMetaFieldValuesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootMetaFieldValuesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<MetaFieldValuesStreamCursorInput>>;
  where?: Maybe<MetaFieldValuesBoolExp>;
}

export interface SubscriptionRootMetaNumberValuesArgs {
  distinct_on?: Maybe<Array<MetaNumberValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaNumberValuesOrderBy>>;
  where?: Maybe<MetaNumberValuesBoolExp>;
}

export interface SubscriptionRootMetaNumberValuesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootMetaNumberValuesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<MetaNumberValuesStreamCursorInput>>;
  where?: Maybe<MetaNumberValuesBoolExp>;
}

export interface SubscriptionRootMetaTextValuesArgs {
  distinct_on?: Maybe<Array<MetaTextValuesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<MetaTextValuesOrderBy>>;
  where?: Maybe<MetaTextValuesBoolExp>;
}

export interface SubscriptionRootMetaTextValuesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootMetaTextValuesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<MetaTextValuesStreamCursorInput>>;
  where?: Maybe<MetaTextValuesBoolExp>;
}

export interface SubscriptionRootOrgMemberInvitesArgs {
  distinct_on?: Maybe<Array<OrgMemberInvitesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OrgMemberInvitesOrderBy>>;
  where?: Maybe<OrgMemberInvitesBoolExp>;
}

export interface SubscriptionRootOrgMemberInvitesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootOrgMemberInvitesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<OrgMemberInvitesStreamCursorInput>>;
  where?: Maybe<OrgMemberInvitesBoolExp>;
}

export interface SubscriptionRootOrgMembersArgs {
  distinct_on?: Maybe<Array<OrgMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OrgMembersOrderBy>>;
  where?: Maybe<OrgMembersBoolExp>;
}

export interface SubscriptionRootOrgMembersAggregateArgs {
  distinct_on?: Maybe<Array<OrgMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OrgMembersOrderBy>>;
  where?: Maybe<OrgMembersBoolExp>;
}

export interface SubscriptionRootOrgMembersByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootOrgMembersStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<OrgMembersStreamCursorInput>>;
  where?: Maybe<OrgMembersBoolExp>;
}

export interface SubscriptionRootOrganizationsArgs {
  distinct_on?: Maybe<Array<OrganizationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OrganizationsOrderBy>>;
  where?: Maybe<OrganizationsBoolExp>;
}

export interface SubscriptionRootOrganizationsAggregateArgs {
  distinct_on?: Maybe<Array<OrganizationsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OrganizationsOrderBy>>;
  where?: Maybe<OrganizationsBoolExp>;
}

export interface SubscriptionRootOrganizationsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootOrganizationsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<OrganizationsStreamCursorInput>>;
  where?: Maybe<OrganizationsBoolExp>;
}

export interface SubscriptionRootPermissionFolderPermissionsArgs {
  distinct_on?: Maybe<Array<PermissionFolderPermissionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionFolderPermissionsOrderBy>>;
  where?: Maybe<PermissionFolderPermissionsBoolExp>;
}

export interface SubscriptionRootPermissionFolderPermissionsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<PermissionFolderPermissionsStreamCursorInput>>;
  where?: Maybe<PermissionFolderPermissionsBoolExp>;
}

export interface SubscriptionRootPermissionMediaPermissionsArgs {
  distinct_on?: Maybe<Array<PermissionMediaPermissionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionMediaPermissionsOrderBy>>;
  where?: Maybe<PermissionMediaPermissionsBoolExp>;
}

export interface SubscriptionRootPermissionMediaPermissionsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<PermissionMediaPermissionsStreamCursorInput>>;
  where?: Maybe<PermissionMediaPermissionsBoolExp>;
}

export interface SubscriptionRootPermissionMetaPermissionsArgs {
  distinct_on?: Maybe<Array<PermissionMetaPermissionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionMetaPermissionsOrderBy>>;
  where?: Maybe<PermissionMetaPermissionsBoolExp>;
}

export interface SubscriptionRootPermissionMetaPermissionsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<PermissionMetaPermissionsStreamCursorInput>>;
  where?: Maybe<PermissionMetaPermissionsBoolExp>;
}

export interface SubscriptionRootPermissionOrgMemberRolesArgs {
  distinct_on?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionOrgMemberRolesOrderBy>>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

export interface SubscriptionRootPermissionOrgMemberRolesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionOrgMemberRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionOrgMemberRolesOrderBy>>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

export interface SubscriptionRootPermissionOrgMemberRolesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootPermissionOrgMemberRolesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<PermissionOrgMemberRolesStreamCursorInput>>;
  where?: Maybe<PermissionOrgMemberRolesBoolExp>;
}

export interface SubscriptionRootPermissionRoleFolderScopesArgs {
  distinct_on?: Maybe<Array<PermissionRoleFolderScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleFolderScopesOrderBy>>;
  where?: Maybe<PermissionRoleFolderScopesBoolExp>;
}

export interface SubscriptionRootPermissionRoleFolderScopesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionRoleFolderScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleFolderScopesOrderBy>>;
  where?: Maybe<PermissionRoleFolderScopesBoolExp>;
}

export interface SubscriptionRootPermissionRoleFolderScopesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootPermissionRoleFolderScopesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<PermissionRoleFolderScopesStreamCursorInput>>;
  where?: Maybe<PermissionRoleFolderScopesBoolExp>;
}

export interface SubscriptionRootPermissionRoleMetaScopesArgs {
  distinct_on?: Maybe<Array<PermissionRoleMetaScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleMetaScopesOrderBy>>;
  where?: Maybe<PermissionRoleMetaScopesBoolExp>;
}

export interface SubscriptionRootPermissionRoleMetaScopesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionRoleMetaScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRoleMetaScopesOrderBy>>;
  where?: Maybe<PermissionRoleMetaScopesBoolExp>;
}

export interface SubscriptionRootPermissionRoleMetaScopesByPkArgs {
  field_id: Scalars["uuid"];
  role_id: Scalars["uuid"];
}

export interface SubscriptionRootPermissionRoleMetaScopesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<PermissionRoleMetaScopesStreamCursorInput>>;
  where?: Maybe<PermissionRoleMetaScopesBoolExp>;
}

export interface SubscriptionRootPermissionRolesArgs {
  distinct_on?: Maybe<Array<PermissionRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRolesOrderBy>>;
  where?: Maybe<PermissionRolesBoolExp>;
}

export interface SubscriptionRootPermissionRolesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionRolesOrderBy>>;
  where?: Maybe<PermissionRolesBoolExp>;
}

export interface SubscriptionRootPermissionRolesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootPermissionRolesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<PermissionRolesStreamCursorInput>>;
  where?: Maybe<PermissionRolesBoolExp>;
}

export interface SubscriptionRootPermissionScopesArgs {
  distinct_on?: Maybe<Array<PermissionScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionScopesOrderBy>>;
  where?: Maybe<PermissionScopesBoolExp>;
}

export interface SubscriptionRootPermissionScopesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionScopesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionScopesOrderBy>>;
  where?: Maybe<PermissionScopesBoolExp>;
}

export interface SubscriptionRootPermissionScopesByPkArgs {
  scope: Scalars["String"];
}

export interface SubscriptionRootPermissionScopesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<PermissionScopesStreamCursorInput>>;
  where?: Maybe<PermissionScopesBoolExp>;
}

export interface SubscriptionRootPermissionUserGroupMembersArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupMembersOrderBy>>;
  where?: Maybe<PermissionUserGroupMembersBoolExp>;
}

export interface SubscriptionRootPermissionUserGroupMembersAggregateArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupMembersOrderBy>>;
  where?: Maybe<PermissionUserGroupMembersBoolExp>;
}

export interface SubscriptionRootPermissionUserGroupMembersByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootPermissionUserGroupMembersStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<PermissionUserGroupMembersStreamCursorInput>>;
  where?: Maybe<PermissionUserGroupMembersBoolExp>;
}

export interface SubscriptionRootPermissionUserGroupRolesArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupRolesOrderBy>>;
  where?: Maybe<PermissionUserGroupRolesBoolExp>;
}

export interface SubscriptionRootPermissionUserGroupRolesAggregateArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupRolesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupRolesOrderBy>>;
  where?: Maybe<PermissionUserGroupRolesBoolExp>;
}

export interface SubscriptionRootPermissionUserGroupRolesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootPermissionUserGroupRolesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<PermissionUserGroupRolesStreamCursorInput>>;
  where?: Maybe<PermissionUserGroupRolesBoolExp>;
}

export interface SubscriptionRootPermissionUserGroupUsersArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupUsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupUsersOrderBy>>;
  where?: Maybe<PermissionUserGroupUsersBoolExp>;
}

export interface SubscriptionRootPermissionUserGroupUsersStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<PermissionUserGroupUsersStreamCursorInput>>;
  where?: Maybe<PermissionUserGroupUsersBoolExp>;
}

export interface SubscriptionRootPermissionUserGroupsArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupsOrderBy>>;
  where?: Maybe<PermissionUserGroupsBoolExp>;
}

export interface SubscriptionRootPermissionUserGroupsAggregateArgs {
  distinct_on?: Maybe<Array<PermissionUserGroupsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<PermissionUserGroupsOrderBy>>;
  where?: Maybe<PermissionUserGroupsBoolExp>;
}

export interface SubscriptionRootPermissionUserGroupsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootPermissionUserGroupsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<PermissionUserGroupsStreamCursorInput>>;
  where?: Maybe<PermissionUserGroupsBoolExp>;
}

export interface SubscriptionRootProjectsArgs {
  distinct_on?: Maybe<Array<ProjectsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ProjectsOrderBy>>;
  where?: Maybe<ProjectsBoolExp>;
}

export interface SubscriptionRootProjectsAggregateArgs {
  distinct_on?: Maybe<Array<ProjectsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ProjectsOrderBy>>;
  where?: Maybe<ProjectsBoolExp>;
}

export interface SubscriptionRootProjectsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootProjectsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<ProjectsStreamCursorInput>>;
  where?: Maybe<ProjectsBoolExp>;
}

export interface SubscriptionRootThumbnailProfilesArgs {
  distinct_on?: Maybe<Array<ThumbnailProfilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<ThumbnailProfilesOrderBy>>;
  where?: Maybe<ThumbnailProfilesBoolExp>;
}

export interface SubscriptionRootThumbnailProfilesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootThumbnailProfilesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<ThumbnailProfilesStreamCursorInput>>;
  where?: Maybe<ThumbnailProfilesBoolExp>;
}

export interface SubscriptionRootTicketingAccessCodesArgs {
  distinct_on?: Maybe<Array<TicketingAccessCodesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TicketingAccessCodesOrderBy>>;
  where?: Maybe<TicketingAccessCodesBoolExp>;
}

export interface SubscriptionRootTicketingAccessCodesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootTicketingAccessCodesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<TicketingAccessCodesStreamCursorInput>>;
  where?: Maybe<TicketingAccessCodesBoolExp>;
}

export interface SubscriptionRootTicketingAccessTokensArgs {
  distinct_on?: Maybe<Array<TicketingAccessTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TicketingAccessTokensOrderBy>>;
  where?: Maybe<TicketingAccessTokensBoolExp>;
}

export interface SubscriptionRootTicketingAccessTokensAggregateArgs {
  distinct_on?: Maybe<Array<TicketingAccessTokensSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TicketingAccessTokensOrderBy>>;
  where?: Maybe<TicketingAccessTokensBoolExp>;
}

export interface SubscriptionRootTicketingAccessTokensByPkArgs {
  id: Scalars["String"];
}

export interface SubscriptionRootTicketingAccessTokensStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<TicketingAccessTokensStreamCursorInput>>;
  where?: Maybe<TicketingAccessTokensBoolExp>;
}

export interface SubscriptionRootTicketingTicketOptionsArgs {
  distinct_on?: Maybe<Array<TicketingTicketOptionsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TicketingTicketOptionsOrderBy>>;
  where?: Maybe<TicketingTicketOptionsBoolExp>;
}

export interface SubscriptionRootTicketingTicketOptionsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootTicketingTicketOptionsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<TicketingTicketOptionsStreamCursorInput>>;
  where?: Maybe<TicketingTicketOptionsBoolExp>;
}

export interface SubscriptionRootTranscoderJobStatusTypesArgs {
  distinct_on?: Maybe<Array<TranscoderJobStatusTypesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TranscoderJobStatusTypesOrderBy>>;
  where?: Maybe<TranscoderJobStatusTypesBoolExp>;
}

export interface SubscriptionRootTranscoderJobStatusTypesByPkArgs {
  type: Scalars["String"];
}

export interface SubscriptionRootTranscoderJobStatusTypesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<TranscoderJobStatusTypesStreamCursorInput>>;
  where?: Maybe<TranscoderJobStatusTypesBoolExp>;
}

export interface SubscriptionRootTranscoderJobsArgs {
  distinct_on?: Maybe<Array<TranscoderJobsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TranscoderJobsOrderBy>>;
  where?: Maybe<TranscoderJobsBoolExp>;
}

export interface SubscriptionRootTranscoderJobsByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootTranscoderJobsStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<TranscoderJobsStreamCursorInput>>;
  where?: Maybe<TranscoderJobsBoolExp>;
}

export interface SubscriptionRootTranscoderProfilesArgs {
  distinct_on?: Maybe<Array<TranscoderProfilesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TranscoderProfilesOrderBy>>;
  where?: Maybe<TranscoderProfilesBoolExp>;
}

export interface SubscriptionRootTranscoderProfilesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootTranscoderProfilesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<TranscoderProfilesStreamCursorInput>>;
  where?: Maybe<TranscoderProfilesBoolExp>;
}

export interface SubscriptionRootUsersArgs {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
}

export interface SubscriptionRootUsersAggregateArgs {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
}

export interface SubscriptionRootUsersByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootUsersStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<UsersStreamCursorInput>>;
  where?: Maybe<UsersBoolExp>;
}

export interface SubscriptionRootVideoPropertiesArgs {
  distinct_on?: Maybe<Array<VideoPropertiesSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VideoPropertiesOrderBy>>;
  where?: Maybe<VideoPropertiesBoolExp>;
}

export interface SubscriptionRootVideoPropertiesByPkArgs {
  id: Scalars["uuid"];
}

export interface SubscriptionRootVideoPropertiesStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<VideoPropertiesStreamCursorInput>>;
  where?: Maybe<VideoPropertiesBoolExp>;
}

export interface SubscriptionRootVlConfigArgs {
  distinct_on?: Maybe<Array<VlConfigSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<VlConfigOrderBy>>;
  where?: Maybe<VlConfigBoolExp>;
}

export interface SubscriptionRootVlConfigByPkArgs {
  id: Scalars["String"];
}

export interface SubscriptionRootVlConfigStreamArgs {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<VlConfigStreamCursorInput>>;
  where?: Maybe<VlConfigBoolExp>;
}

/** columns and relationships of "thumbnail_profiles" */
export interface ThumbnailProfiles {
  created_at: Scalars["timestamptz"];
  height: Scalars["Int"];
  id: Scalars["uuid"];
  /** An object relationship */
  original_file: AssetFiles;
  original_file_id: Scalars["uuid"];
  /** An object relationship */
  output_file?: Maybe<AssetFiles>;
  output_file_id?: Maybe<Scalars["uuid"]>;
  seconds: Scalars["jsonb"];
  status: JobStatusTypesEnum;
  updated_at: Scalars["timestamptz"];
  width?: Maybe<Scalars["Int"]>;
}

/** columns and relationships of "thumbnail_profiles" */
export interface ThumbnailProfilesSecondsArgs {
  path?: Maybe<Scalars["String"]>;
}

/** order by aggregate values of table "thumbnail_profiles" */
export interface ThumbnailProfilesAggregateOrderBy {
  avg?: Maybe<ThumbnailProfilesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ThumbnailProfilesMaxOrderBy>;
  min?: Maybe<ThumbnailProfilesMinOrderBy>;
  stddev?: Maybe<ThumbnailProfilesStddevOrderBy>;
  stddev_pop?: Maybe<ThumbnailProfilesStddevPopOrderBy>;
  stddev_samp?: Maybe<ThumbnailProfilesStddevSampOrderBy>;
  sum?: Maybe<ThumbnailProfilesSumOrderBy>;
  var_pop?: Maybe<ThumbnailProfilesVarPopOrderBy>;
  var_samp?: Maybe<ThumbnailProfilesVarSampOrderBy>;
  variance?: Maybe<ThumbnailProfilesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "thumbnail_profiles" */
export interface ThumbnailProfilesArrRelInsertInput {
  data: Array<ThumbnailProfilesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<ThumbnailProfilesOnConflict>;
}

/** order by avg() on columns of table "thumbnail_profiles" */
export interface ThumbnailProfilesAvgOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "thumbnail_profiles". All fields are combined with a logical 'AND'. */
export interface ThumbnailProfilesBoolExp {
  _and?: Maybe<Array<ThumbnailProfilesBoolExp>>;
  _not?: Maybe<ThumbnailProfilesBoolExp>;
  _or?: Maybe<Array<ThumbnailProfilesBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  height?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  original_file?: Maybe<AssetFilesBoolExp>;
  original_file_id?: Maybe<UuidComparisonExp>;
  output_file?: Maybe<AssetFilesBoolExp>;
  output_file_id?: Maybe<UuidComparisonExp>;
  seconds?: Maybe<JsonbComparisonExp>;
  status?: Maybe<JobStatusTypesEnumComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  width?: Maybe<IntComparisonExp>;
}

/** unique or primary key constraints on table "thumbnail_profiles" */
export enum ThumbnailProfilesConstraint {
  /** unique or primary key constraint on columns "output_file_id" */
  thumbnail_profiles_output_file_id_key = "thumbnail_profiles_output_file_id_key",
  /** unique or primary key constraint on columns "id" */
  thumbnail_profiles_pkey = "thumbnail_profiles_pkey",
}

/** input type for inserting data into table "thumbnail_profiles" */
export interface ThumbnailProfilesInsertInput {
  height?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  original_file?: Maybe<AssetFilesObjRelInsertInput>;
  original_file_id?: Maybe<Scalars["uuid"]>;
  output_file?: Maybe<AssetFilesObjRelInsertInput>;
  seconds?: Maybe<Scalars["jsonb"]>;
}

/** order by max() on columns of table "thumbnail_profiles" */
export interface ThumbnailProfilesMaxOrderBy {
  created_at?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  original_file_id?: Maybe<OrderBy>;
  output_file_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by min() on columns of table "thumbnail_profiles" */
export interface ThumbnailProfilesMinOrderBy {
  created_at?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  original_file_id?: Maybe<OrderBy>;
  output_file_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** response of any mutation on the table "thumbnail_profiles" */
export interface ThumbnailProfilesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<ThumbnailProfiles>;
}

/** input type for inserting object relation for remote table "thumbnail_profiles" */
export interface ThumbnailProfilesObjRelInsertInput {
  data: ThumbnailProfilesInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<ThumbnailProfilesOnConflict>;
}

/** on_conflict condition type for table "thumbnail_profiles" */
export interface ThumbnailProfilesOnConflict {
  constraint: ThumbnailProfilesConstraint;
  update_columns?: Array<ThumbnailProfilesUpdateColumn>;
  where?: Maybe<ThumbnailProfilesBoolExp>;
}

/** Ordering options when selecting data from "thumbnail_profiles". */
export interface ThumbnailProfilesOrderBy {
  created_at?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  original_file?: Maybe<AssetFilesOrderBy>;
  original_file_id?: Maybe<OrderBy>;
  output_file?: Maybe<AssetFilesOrderBy>;
  output_file_id?: Maybe<OrderBy>;
  seconds?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** select columns of table "thumbnail_profiles" */
export enum ThumbnailProfilesSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  height = "height",
  /** column name */
  id = "id",
  /** column name */
  original_file_id = "original_file_id",
  /** column name */
  output_file_id = "output_file_id",
  /** column name */
  seconds = "seconds",
  /** column name */
  status = "status",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  width = "width",
}

/** order by stddev() on columns of table "thumbnail_profiles" */
export interface ThumbnailProfilesStddevOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by stddev_pop() on columns of table "thumbnail_profiles" */
export interface ThumbnailProfilesStddevPopOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by stddev_samp() on columns of table "thumbnail_profiles" */
export interface ThumbnailProfilesStddevSampOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** Streaming cursor of the table "thumbnail_profiles" */
export interface ThumbnailProfilesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ThumbnailProfilesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ThumbnailProfilesStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  height?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  original_file_id?: Maybe<Scalars["uuid"]>;
  output_file_id?: Maybe<Scalars["uuid"]>;
  seconds?: Maybe<Scalars["jsonb"]>;
  status?: Maybe<JobStatusTypesEnum>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  width?: Maybe<Scalars["Int"]>;
}

/** order by sum() on columns of table "thumbnail_profiles" */
export interface ThumbnailProfilesSumOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** placeholder for update columns of table "thumbnail_profiles" (current role has no relevant permissions) */
export enum ThumbnailProfilesUpdateColumn {
  /** placeholder (do not use) */
  _PLACEHOLDER = "_PLACEHOLDER",
}

/** order by var_pop() on columns of table "thumbnail_profiles" */
export interface ThumbnailProfilesVarPopOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by var_samp() on columns of table "thumbnail_profiles" */
export interface ThumbnailProfilesVarSampOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by variance() on columns of table "thumbnail_profiles" */
export interface ThumbnailProfilesVarianceOrderBy {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** columns and relationships of "ticketing.access_codes" */
export interface TicketingAccessCodes {
  code?: Maybe<Scalars["String"]>;
  codes: Scalars["jsonb"];
  /** An object relationship */
  content_row: CmsContentRows;
  content_row_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
}

/** columns and relationships of "ticketing.access_codes" */
export interface TicketingAccessCodesCodesArgs {
  path?: Maybe<Scalars["String"]>;
}

/** order by aggregate values of table "ticketing.access_codes" */
export interface TicketingAccessCodesAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<TicketingAccessCodesMaxOrderBy>;
  min?: Maybe<TicketingAccessCodesMinOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface TicketingAccessCodesAppendInput {
  codes?: Maybe<Scalars["jsonb"]>;
}

/** input type for inserting array relation for remote table "ticketing.access_codes" */
export interface TicketingAccessCodesArrRelInsertInput {
  data: Array<TicketingAccessCodesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<TicketingAccessCodesOnConflict>;
}

/** Boolean expression to filter rows from the table "ticketing.access_codes". All fields are combined with a logical 'AND'. */
export interface TicketingAccessCodesBoolExp {
  _and?: Maybe<Array<TicketingAccessCodesBoolExp>>;
  _not?: Maybe<TicketingAccessCodesBoolExp>;
  _or?: Maybe<Array<TicketingAccessCodesBoolExp>>;
  code?: Maybe<StringComparisonExp>;
  codes?: Maybe<JsonbComparisonExp>;
  content_row?: Maybe<CmsContentRowsBoolExp>;
  content_row_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "ticketing.access_codes" */
export enum TicketingAccessCodesConstraint {
  /** unique or primary key constraint on columns "content_row_id" */
  access_codes_content_row_id_key = "access_codes_content_row_id_key",
  /** unique or primary key constraint on columns "id" */
  access_codes_pkey = "access_codes_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface TicketingAccessCodesDeleteAtPathInput {
  codes?: Maybe<Array<Scalars["String"]>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface TicketingAccessCodesDeleteElemInput {
  codes?: Maybe<Scalars["Int"]>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface TicketingAccessCodesDeleteKeyInput {
  codes?: Maybe<Scalars["String"]>;
}

/** input type for inserting data into table "ticketing.access_codes" */
export interface TicketingAccessCodesInsertInput {
  code?: Maybe<Scalars["String"]>;
  codes?: Maybe<Scalars["jsonb"]>;
  content_row?: Maybe<CmsContentRowsObjRelInsertInput>;
  content_row_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
}

/** order by max() on columns of table "ticketing.access_codes" */
export interface TicketingAccessCodesMaxOrderBy {
  code?: Maybe<OrderBy>;
  content_row_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** order by min() on columns of table "ticketing.access_codes" */
export interface TicketingAccessCodesMinOrderBy {
  code?: Maybe<OrderBy>;
  content_row_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** response of any mutation on the table "ticketing.access_codes" */
export interface TicketingAccessCodesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TicketingAccessCodes>;
}

/** on_conflict condition type for table "ticketing.access_codes" */
export interface TicketingAccessCodesOnConflict {
  constraint: TicketingAccessCodesConstraint;
  update_columns?: Array<TicketingAccessCodesUpdateColumn>;
  where?: Maybe<TicketingAccessCodesBoolExp>;
}

/** Ordering options when selecting data from "ticketing.access_codes". */
export interface TicketingAccessCodesOrderBy {
  code?: Maybe<OrderBy>;
  codes?: Maybe<OrderBy>;
  content_row?: Maybe<CmsContentRowsOrderBy>;
  content_row_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
}

/** primary key columns input for table: ticketing.access_codes */
export interface TicketingAccessCodesPkColumnsInput {
  id: Scalars["uuid"];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface TicketingAccessCodesPrependInput {
  codes?: Maybe<Scalars["jsonb"]>;
}

/** select columns of table "ticketing.access_codes" */
export enum TicketingAccessCodesSelectColumn {
  /** column name */
  code = "code",
  /** column name */
  codes = "codes",
  /** column name */
  content_row_id = "content_row_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
}

/** input type for updating data in table "ticketing.access_codes" */
export interface TicketingAccessCodesSetInput {
  code?: Maybe<Scalars["String"]>;
  codes?: Maybe<Scalars["jsonb"]>;
  content_row_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
}

/** Streaming cursor of the table "ticketing_access_codes" */
export interface TicketingAccessCodesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: TicketingAccessCodesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface TicketingAccessCodesStreamCursorValueInput {
  code?: Maybe<Scalars["String"]>;
  codes?: Maybe<Scalars["jsonb"]>;
  content_row_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
}

/** update columns of table "ticketing.access_codes" */
export enum TicketingAccessCodesUpdateColumn {
  /** column name */
  code = "code",
  /** column name */
  codes = "codes",
  /** column name */
  content_row_id = "content_row_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
}

export interface TicketingAccessCodesUpdates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<TicketingAccessCodesAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<TicketingAccessCodesDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<TicketingAccessCodesDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<TicketingAccessCodesDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<TicketingAccessCodesPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<TicketingAccessCodesSetInput>;
  /** filter the rows which have to be updated */
  where: TicketingAccessCodesBoolExp;
}

/** columns and relationships of "ticketing.access_tokens" */
export interface TicketingAccessTokens {
  access_code_id?: Maybe<Scalars["uuid"]>;
  code?: Maybe<Scalars["String"]>;
  content_row_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  extra_data?: Maybe<Scalars["jsonb"]>;
  id: Scalars["String"];
  ip: Scalars["String"];
}

/** columns and relationships of "ticketing.access_tokens" */
export interface TicketingAccessTokensExtraDataArgs {
  path?: Maybe<Scalars["String"]>;
}

/** aggregated selection of "ticketing.access_tokens" */
export interface TicketingAccessTokensAggregate {
  aggregate?: Maybe<TicketingAccessTokensAggregateFields>;
  nodes: Array<TicketingAccessTokens>;
}

/** aggregate fields of "ticketing.access_tokens" */
export interface TicketingAccessTokensAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<TicketingAccessTokensMaxFields>;
  min?: Maybe<TicketingAccessTokensMinFields>;
}

/** aggregate fields of "ticketing.access_tokens" */
export interface TicketingAccessTokensAggregateFieldsCountArgs {
  columns?: Maybe<Array<TicketingAccessTokensSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** Boolean expression to filter rows from the table "ticketing.access_tokens". All fields are combined with a logical 'AND'. */
export interface TicketingAccessTokensBoolExp {
  _and?: Maybe<Array<TicketingAccessTokensBoolExp>>;
  _not?: Maybe<TicketingAccessTokensBoolExp>;
  _or?: Maybe<Array<TicketingAccessTokensBoolExp>>;
  access_code_id?: Maybe<UuidComparisonExp>;
  code?: Maybe<StringComparisonExp>;
  content_row_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  extra_data?: Maybe<JsonbComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  ip?: Maybe<StringComparisonExp>;
}

/** aggregate max on columns */
export interface TicketingAccessTokensMaxFields {
  access_code_id?: Maybe<Scalars["uuid"]>;
  code?: Maybe<Scalars["String"]>;
  content_row_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  ip?: Maybe<Scalars["String"]>;
}

/** aggregate min on columns */
export interface TicketingAccessTokensMinFields {
  access_code_id?: Maybe<Scalars["uuid"]>;
  code?: Maybe<Scalars["String"]>;
  content_row_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  ip?: Maybe<Scalars["String"]>;
}

/** Ordering options when selecting data from "ticketing.access_tokens". */
export interface TicketingAccessTokensOrderBy {
  access_code_id?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  content_row_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  extra_data?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip?: Maybe<OrderBy>;
}

/** select columns of table "ticketing.access_tokens" */
export enum TicketingAccessTokensSelectColumn {
  /** column name */
  access_code_id = "access_code_id",
  /** column name */
  code = "code",
  /** column name */
  content_row_id = "content_row_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  extra_data = "extra_data",
  /** column name */
  id = "id",
  /** column name */
  ip = "ip",
}

/** Streaming cursor of the table "ticketing_access_tokens" */
export interface TicketingAccessTokensStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: TicketingAccessTokensStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface TicketingAccessTokensStreamCursorValueInput {
  access_code_id?: Maybe<Scalars["uuid"]>;
  code?: Maybe<Scalars["String"]>;
  content_row_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  extra_data?: Maybe<Scalars["jsonb"]>;
  id?: Maybe<Scalars["String"]>;
  ip?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "ticketing.ticket_options" */
export interface TicketingTicketOptions {
  content_id: Scalars["uuid"];
  /** An object relationship */
  content_row: CmsContentRows;
  created_at: Scalars["timestamptz"];
  currency: Scalars["String"];
  id: Scalars["uuid"];
  name: Scalars["String"];
  price: Scalars["numeric"];
  ticket_type: Scalars["String"];
  valid_for_amount?: Maybe<Scalars["Int"]>;
  valid_for_unit?: Maybe<Scalars["String"]>;
  valid_until?: Maybe<Scalars["timestamptz"]>;
}

/** Boolean expression to filter rows from the table "ticketing.ticket_options". All fields are combined with a logical 'AND'. */
export interface TicketingTicketOptionsBoolExp {
  _and?: Maybe<Array<TicketingTicketOptionsBoolExp>>;
  _not?: Maybe<TicketingTicketOptionsBoolExp>;
  _or?: Maybe<Array<TicketingTicketOptionsBoolExp>>;
  content_id?: Maybe<UuidComparisonExp>;
  content_row?: Maybe<CmsContentRowsBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  currency?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  price?: Maybe<NumericComparisonExp>;
  ticket_type?: Maybe<StringComparisonExp>;
  valid_for_amount?: Maybe<IntComparisonExp>;
  valid_for_unit?: Maybe<StringComparisonExp>;
  valid_until?: Maybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "ticketing.ticket_options" */
export enum TicketingTicketOptionsConstraint {
  /** unique or primary key constraint on columns "id" */
  ticket_options_pkey = "ticket_options_pkey",
}

/** input type for incrementing numeric columns in table "ticketing.ticket_options" */
export interface TicketingTicketOptionsIncInput {
  price?: Maybe<Scalars["numeric"]>;
  valid_for_amount?: Maybe<Scalars["Int"]>;
}

/** input type for inserting data into table "ticketing.ticket_options" */
export interface TicketingTicketOptionsInsertInput {
  content_id?: Maybe<Scalars["uuid"]>;
  content_row?: Maybe<CmsContentRowsObjRelInsertInput>;
  currency?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["numeric"]>;
  ticket_type?: Maybe<Scalars["String"]>;
  valid_for_amount?: Maybe<Scalars["Int"]>;
  valid_for_unit?: Maybe<Scalars["String"]>;
  valid_until?: Maybe<Scalars["timestamptz"]>;
}

/** response of any mutation on the table "ticketing.ticket_options" */
export interface TicketingTicketOptionsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TicketingTicketOptions>;
}

/** on_conflict condition type for table "ticketing.ticket_options" */
export interface TicketingTicketOptionsOnConflict {
  constraint: TicketingTicketOptionsConstraint;
  update_columns?: Array<TicketingTicketOptionsUpdateColumn>;
  where?: Maybe<TicketingTicketOptionsBoolExp>;
}

/** Ordering options when selecting data from "ticketing.ticket_options". */
export interface TicketingTicketOptionsOrderBy {
  content_id?: Maybe<OrderBy>;
  content_row?: Maybe<CmsContentRowsOrderBy>;
  created_at?: Maybe<OrderBy>;
  currency?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  price?: Maybe<OrderBy>;
  ticket_type?: Maybe<OrderBy>;
  valid_for_amount?: Maybe<OrderBy>;
  valid_for_unit?: Maybe<OrderBy>;
  valid_until?: Maybe<OrderBy>;
}

/** primary key columns input for table: ticketing.ticket_options */
export interface TicketingTicketOptionsPkColumnsInput {
  id: Scalars["uuid"];
}

/** select columns of table "ticketing.ticket_options" */
export enum TicketingTicketOptionsSelectColumn {
  /** column name */
  content_id = "content_id",
  /** column name */
  created_at = "created_at",
  /** column name */
  currency = "currency",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  price = "price",
  /** column name */
  ticket_type = "ticket_type",
  /** column name */
  valid_for_amount = "valid_for_amount",
  /** column name */
  valid_for_unit = "valid_for_unit",
  /** column name */
  valid_until = "valid_until",
}

/** input type for updating data in table "ticketing.ticket_options" */
export interface TicketingTicketOptionsSetInput {
  content_id?: Maybe<Scalars["uuid"]>;
  currency?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["numeric"]>;
  ticket_type?: Maybe<Scalars["String"]>;
  valid_for_amount?: Maybe<Scalars["Int"]>;
  valid_for_unit?: Maybe<Scalars["String"]>;
  valid_until?: Maybe<Scalars["timestamptz"]>;
}

/** Streaming cursor of the table "ticketing_ticket_options" */
export interface TicketingTicketOptionsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: TicketingTicketOptionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface TicketingTicketOptionsStreamCursorValueInput {
  content_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  currency?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["numeric"]>;
  ticket_type?: Maybe<Scalars["String"]>;
  valid_for_amount?: Maybe<Scalars["Int"]>;
  valid_for_unit?: Maybe<Scalars["String"]>;
  valid_until?: Maybe<Scalars["timestamptz"]>;
}

/** update columns of table "ticketing.ticket_options" */
export enum TicketingTicketOptionsUpdateColumn {
  /** column name */
  content_id = "content_id",
  /** column name */
  currency = "currency",
  /** column name */
  id = "id",
  /** column name */
  name = "name",
  /** column name */
  price = "price",
  /** column name */
  ticket_type = "ticket_type",
  /** column name */
  valid_for_amount = "valid_for_amount",
  /** column name */
  valid_for_unit = "valid_for_unit",
  /** column name */
  valid_until = "valid_until",
}

export interface TicketingTicketOptionsUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<TicketingTicketOptionsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<TicketingTicketOptionsSetInput>;
  /** filter the rows which have to be updated */
  where: TicketingTicketOptionsBoolExp;
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface TimestamptzComparisonExp {
  _eq?: Maybe<Scalars["timestamptz"]>;
  _gt?: Maybe<Scalars["timestamptz"]>;
  _gte?: Maybe<Scalars["timestamptz"]>;
  _in?: Maybe<Array<Scalars["timestamptz"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamptz"]>;
  _lte?: Maybe<Scalars["timestamptz"]>;
  _neq?: Maybe<Scalars["timestamptz"]>;
  _nin?: Maybe<Array<Scalars["timestamptz"]>>;
}

/** columns and relationships of "transcoder_job_status_types" */
export interface TranscoderJobStatusTypes {
  type: Scalars["String"];
}

/** Boolean expression to filter rows from the table "transcoder_job_status_types". All fields are combined with a logical 'AND'. */
export interface TranscoderJobStatusTypesBoolExp {
  _and?: Maybe<Array<TranscoderJobStatusTypesBoolExp>>;
  _not?: Maybe<TranscoderJobStatusTypesBoolExp>;
  _or?: Maybe<Array<TranscoderJobStatusTypesBoolExp>>;
  type?: Maybe<StringComparisonExp>;
}

export enum TranscoderJobStatusTypesEnum {
  FAILED = "FAILED",
  FINISHED = "FINISHED",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  PROCESSING = "PROCESSING",
  WAITING_FOR_PROCESSING = "WAITING_FOR_PROCESSING",
}

/** Boolean expression to compare columns of type "transcoder_job_status_types_enum". All fields are combined with logical 'AND'. */
export interface TranscoderJobStatusTypesEnumComparisonExp {
  _eq?: Maybe<TranscoderJobStatusTypesEnum>;
  _in?: Maybe<Array<TranscoderJobStatusTypesEnum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<TranscoderJobStatusTypesEnum>;
  _nin?: Maybe<Array<TranscoderJobStatusTypesEnum>>;
}

/** Ordering options when selecting data from "transcoder_job_status_types". */
export interface TranscoderJobStatusTypesOrderBy {
  type?: Maybe<OrderBy>;
}

/** select columns of table "transcoder_job_status_types" */
export enum TranscoderJobStatusTypesSelectColumn {
  /** column name */
  type = "type",
}

/** Streaming cursor of the table "transcoder_job_status_types" */
export interface TranscoderJobStatusTypesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: TranscoderJobStatusTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface TranscoderJobStatusTypesStreamCursorValueInput {
  type?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "transcoder_jobs" */
export interface TranscoderJobs {
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  is_dry_run: Scalars["Boolean"];
  org_id: Scalars["uuid"];
  status: TranscoderJobStatusTypesEnum;
  thumbnail_height: Scalars["Int"];
  thumbnail_seconds: Scalars["jsonb"];
  /** An object relationship */
  transcoder_profile: TranscoderProfiles;
  transcoder_profile_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
}

/** columns and relationships of "transcoder_jobs" */
export interface TranscoderJobsThumbnailSecondsArgs {
  path?: Maybe<Scalars["String"]>;
}

/** order by aggregate values of table "transcoder_jobs" */
export interface TranscoderJobsAggregateOrderBy {
  avg?: Maybe<TranscoderJobsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<TranscoderJobsMaxOrderBy>;
  min?: Maybe<TranscoderJobsMinOrderBy>;
  stddev?: Maybe<TranscoderJobsStddevOrderBy>;
  stddev_pop?: Maybe<TranscoderJobsStddevPopOrderBy>;
  stddev_samp?: Maybe<TranscoderJobsStddevSampOrderBy>;
  sum?: Maybe<TranscoderJobsSumOrderBy>;
  var_pop?: Maybe<TranscoderJobsVarPopOrderBy>;
  var_samp?: Maybe<TranscoderJobsVarSampOrderBy>;
  variance?: Maybe<TranscoderJobsVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "transcoder_jobs" */
export interface TranscoderJobsArrRelInsertInput {
  data: Array<TranscoderJobsInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<TranscoderJobsOnConflict>;
}

/** order by avg() on columns of table "transcoder_jobs" */
export interface TranscoderJobsAvgOrderBy {
  thumbnail_height?: Maybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "transcoder_jobs". All fields are combined with a logical 'AND'. */
export interface TranscoderJobsBoolExp {
  _and?: Maybe<Array<TranscoderJobsBoolExp>>;
  _not?: Maybe<TranscoderJobsBoolExp>;
  _or?: Maybe<Array<TranscoderJobsBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_dry_run?: Maybe<BooleanComparisonExp>;
  org_id?: Maybe<UuidComparisonExp>;
  status?: Maybe<TranscoderJobStatusTypesEnumComparisonExp>;
  thumbnail_height?: Maybe<IntComparisonExp>;
  thumbnail_seconds?: Maybe<JsonbComparisonExp>;
  transcoder_profile?: Maybe<TranscoderProfilesBoolExp>;
  transcoder_profile_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
}

/** unique or primary key constraints on table "transcoder_jobs" */
export enum TranscoderJobsConstraint {
  /** unique or primary key constraint on columns "id" */
  transcoder_jobs_pkey = "transcoder_jobs_pkey",
}

/** input type for inserting data into table "transcoder_jobs" */
export interface TranscoderJobsInsertInput {
  is_dry_run?: Maybe<Scalars["Boolean"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<TranscoderJobStatusTypesEnum>;
  thumbnail_height?: Maybe<Scalars["Int"]>;
  thumbnail_seconds?: Maybe<Scalars["jsonb"]>;
  transcoder_profile?: Maybe<TranscoderProfilesObjRelInsertInput>;
  transcoder_profile_id?: Maybe<Scalars["uuid"]>;
}

/** order by max() on columns of table "transcoder_jobs" */
export interface TranscoderJobsMaxOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  thumbnail_height?: Maybe<OrderBy>;
  transcoder_profile_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** order by min() on columns of table "transcoder_jobs" */
export interface TranscoderJobsMinOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  thumbnail_height?: Maybe<OrderBy>;
  transcoder_profile_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** response of any mutation on the table "transcoder_jobs" */
export interface TranscoderJobsMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TranscoderJobs>;
}

/** on_conflict condition type for table "transcoder_jobs" */
export interface TranscoderJobsOnConflict {
  constraint: TranscoderJobsConstraint;
  update_columns?: Array<TranscoderJobsUpdateColumn>;
  where?: Maybe<TranscoderJobsBoolExp>;
}

/** Ordering options when selecting data from "transcoder_jobs". */
export interface TranscoderJobsOrderBy {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_dry_run?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  thumbnail_height?: Maybe<OrderBy>;
  thumbnail_seconds?: Maybe<OrderBy>;
  transcoder_profile?: Maybe<TranscoderProfilesOrderBy>;
  transcoder_profile_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** select columns of table "transcoder_jobs" */
export enum TranscoderJobsSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  id = "id",
  /** column name */
  is_dry_run = "is_dry_run",
  /** column name */
  org_id = "org_id",
  /** column name */
  status = "status",
  /** column name */
  thumbnail_height = "thumbnail_height",
  /** column name */
  thumbnail_seconds = "thumbnail_seconds",
  /** column name */
  transcoder_profile_id = "transcoder_profile_id",
  /** column name */
  updated_at = "updated_at",
}

/** order by stddev() on columns of table "transcoder_jobs" */
export interface TranscoderJobsStddevOrderBy {
  thumbnail_height?: Maybe<OrderBy>;
}

/** order by stddev_pop() on columns of table "transcoder_jobs" */
export interface TranscoderJobsStddevPopOrderBy {
  thumbnail_height?: Maybe<OrderBy>;
}

/** order by stddev_samp() on columns of table "transcoder_jobs" */
export interface TranscoderJobsStddevSampOrderBy {
  thumbnail_height?: Maybe<OrderBy>;
}

/** Streaming cursor of the table "transcoder_jobs" */
export interface TranscoderJobsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: TranscoderJobsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface TranscoderJobsStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_dry_run?: Maybe<Scalars["Boolean"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<TranscoderJobStatusTypesEnum>;
  thumbnail_height?: Maybe<Scalars["Int"]>;
  thumbnail_seconds?: Maybe<Scalars["jsonb"]>;
  transcoder_profile_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** order by sum() on columns of table "transcoder_jobs" */
export interface TranscoderJobsSumOrderBy {
  thumbnail_height?: Maybe<OrderBy>;
}

/** placeholder for update columns of table "transcoder_jobs" (current role has no relevant permissions) */
export enum TranscoderJobsUpdateColumn {
  /** placeholder (do not use) */
  _PLACEHOLDER = "_PLACEHOLDER",
}

/** order by var_pop() on columns of table "transcoder_jobs" */
export interface TranscoderJobsVarPopOrderBy {
  thumbnail_height?: Maybe<OrderBy>;
}

/** order by var_samp() on columns of table "transcoder_jobs" */
export interface TranscoderJobsVarSampOrderBy {
  thumbnail_height?: Maybe<OrderBy>;
}

/** order by variance() on columns of table "transcoder_jobs" */
export interface TranscoderJobsVarianceOrderBy {
  thumbnail_height?: Maybe<OrderBy>;
}

/** columns and relationships of "transcoder_profiles" */
export interface TranscoderProfiles {
  audio_bitrate_kbps: Scalars["Int"];
  created_at: Scalars["timestamptz"];
  frame_rate: Scalars["float8"];
  height: Scalars["Int"];
  id: Scalars["uuid"];
  includes_audio: Scalars["Boolean"];
  includes_video: Scalars["Boolean"];
  name: Scalars["String"];
  org_id: Scalars["uuid"];
  /** An object relationship */
  original_file: AssetFiles;
  original_file_id: Scalars["uuid"];
  /** An object relationship */
  output_file?: Maybe<AssetFiles>;
  output_file_id?: Maybe<Scalars["uuid"]>;
  status: JobStatusTypesEnum;
  /** An object relationship */
  transcoded_file?: Maybe<AssetFiles>;
  /** An array relationship */
  transcoder_jobs: Array<TranscoderJobs>;
  updated_at: Scalars["timestamptz"];
  video_bitrate_kbps: Scalars["Int"];
  width: Scalars["Int"];
}

/** columns and relationships of "transcoder_profiles" */
export interface TranscoderProfilesTranscoderJobsArgs {
  distinct_on?: Maybe<Array<TranscoderJobsSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<TranscoderJobsOrderBy>>;
  where?: Maybe<TranscoderJobsBoolExp>;
}

/** order by aggregate values of table "transcoder_profiles" */
export interface TranscoderProfilesAggregateOrderBy {
  avg?: Maybe<TranscoderProfilesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<TranscoderProfilesMaxOrderBy>;
  min?: Maybe<TranscoderProfilesMinOrderBy>;
  stddev?: Maybe<TranscoderProfilesStddevOrderBy>;
  stddev_pop?: Maybe<TranscoderProfilesStddevPopOrderBy>;
  stddev_samp?: Maybe<TranscoderProfilesStddevSampOrderBy>;
  sum?: Maybe<TranscoderProfilesSumOrderBy>;
  var_pop?: Maybe<TranscoderProfilesVarPopOrderBy>;
  var_samp?: Maybe<TranscoderProfilesVarSampOrderBy>;
  variance?: Maybe<TranscoderProfilesVarianceOrderBy>;
}

/** input type for inserting array relation for remote table "transcoder_profiles" */
export interface TranscoderProfilesArrRelInsertInput {
  data: Array<TranscoderProfilesInsertInput>;
  /** upsert condition */
  on_conflict?: Maybe<TranscoderProfilesOnConflict>;
}

/** order by avg() on columns of table "transcoder_profiles" */
export interface TranscoderProfilesAvgOrderBy {
  audio_bitrate_kbps?: Maybe<OrderBy>;
  frame_rate?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  video_bitrate_kbps?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** Boolean expression to filter rows from the table "transcoder_profiles". All fields are combined with a logical 'AND'. */
export interface TranscoderProfilesBoolExp {
  _and?: Maybe<Array<TranscoderProfilesBoolExp>>;
  _not?: Maybe<TranscoderProfilesBoolExp>;
  _or?: Maybe<Array<TranscoderProfilesBoolExp>>;
  audio_bitrate_kbps?: Maybe<IntComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  frame_rate?: Maybe<Float8ComparisonExp>;
  height?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  includes_audio?: Maybe<BooleanComparisonExp>;
  includes_video?: Maybe<BooleanComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  org_id?: Maybe<UuidComparisonExp>;
  original_file?: Maybe<AssetFilesBoolExp>;
  original_file_id?: Maybe<UuidComparisonExp>;
  output_file?: Maybe<AssetFilesBoolExp>;
  output_file_id?: Maybe<UuidComparisonExp>;
  status?: Maybe<JobStatusTypesEnumComparisonExp>;
  transcoded_file?: Maybe<AssetFilesBoolExp>;
  transcoder_jobs?: Maybe<TranscoderJobsBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  video_bitrate_kbps?: Maybe<IntComparisonExp>;
  width?: Maybe<IntComparisonExp>;
}

/** unique or primary key constraints on table "transcoder_profiles" */
export enum TranscoderProfilesConstraint {
  /** unique or primary key constraint on columns "output_file_id" */
  transcoder_profiles_output_file_id_key = "transcoder_profiles_output_file_id_key",
  /** unique or primary key constraint on columns "id" */
  transcoder_profiles_pkey = "transcoder_profiles_pkey",
}

/** input type for inserting data into table "transcoder_profiles" */
export interface TranscoderProfilesInsertInput {
  audio_bitrate_kbps?: Maybe<Scalars["Int"]>;
  frame_rate?: Maybe<Scalars["float8"]>;
  height?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  includes_audio?: Maybe<Scalars["Boolean"]>;
  includes_video?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  original_file?: Maybe<AssetFilesObjRelInsertInput>;
  original_file_id?: Maybe<Scalars["uuid"]>;
  output_file?: Maybe<AssetFilesObjRelInsertInput>;
  transcoded_file?: Maybe<AssetFilesObjRelInsertInput>;
  transcoder_jobs?: Maybe<TranscoderJobsArrRelInsertInput>;
  video_bitrate_kbps?: Maybe<Scalars["Int"]>;
  width?: Maybe<Scalars["Int"]>;
}

/** order by max() on columns of table "transcoder_profiles" */
export interface TranscoderProfilesMaxOrderBy {
  audio_bitrate_kbps?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  frame_rate?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  original_file_id?: Maybe<OrderBy>;
  output_file_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  video_bitrate_kbps?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by min() on columns of table "transcoder_profiles" */
export interface TranscoderProfilesMinOrderBy {
  audio_bitrate_kbps?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  frame_rate?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  original_file_id?: Maybe<OrderBy>;
  output_file_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  video_bitrate_kbps?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** response of any mutation on the table "transcoder_profiles" */
export interface TranscoderProfilesMutationResponse {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TranscoderProfiles>;
}

/** input type for inserting object relation for remote table "transcoder_profiles" */
export interface TranscoderProfilesObjRelInsertInput {
  data: TranscoderProfilesInsertInput;
  /** upsert condition */
  on_conflict?: Maybe<TranscoderProfilesOnConflict>;
}

/** on_conflict condition type for table "transcoder_profiles" */
export interface TranscoderProfilesOnConflict {
  constraint: TranscoderProfilesConstraint;
  update_columns?: Array<TranscoderProfilesUpdateColumn>;
  where?: Maybe<TranscoderProfilesBoolExp>;
}

/** Ordering options when selecting data from "transcoder_profiles". */
export interface TranscoderProfilesOrderBy {
  audio_bitrate_kbps?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  frame_rate?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  includes_audio?: Maybe<OrderBy>;
  includes_video?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org_id?: Maybe<OrderBy>;
  original_file?: Maybe<AssetFilesOrderBy>;
  original_file_id?: Maybe<OrderBy>;
  output_file?: Maybe<AssetFilesOrderBy>;
  output_file_id?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  transcoded_file?: Maybe<AssetFilesOrderBy>;
  transcoder_jobs_aggregate?: Maybe<TranscoderJobsAggregateOrderBy>;
  updated_at?: Maybe<OrderBy>;
  video_bitrate_kbps?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** select columns of table "transcoder_profiles" */
export enum TranscoderProfilesSelectColumn {
  /** column name */
  audio_bitrate_kbps = "audio_bitrate_kbps",
  /** column name */
  created_at = "created_at",
  /** column name */
  frame_rate = "frame_rate",
  /** column name */
  height = "height",
  /** column name */
  id = "id",
  /** column name */
  includes_audio = "includes_audio",
  /** column name */
  includes_video = "includes_video",
  /** column name */
  name = "name",
  /** column name */
  org_id = "org_id",
  /** column name */
  original_file_id = "original_file_id",
  /** column name */
  output_file_id = "output_file_id",
  /** column name */
  status = "status",
  /** column name */
  updated_at = "updated_at",
  /** column name */
  video_bitrate_kbps = "video_bitrate_kbps",
  /** column name */
  width = "width",
}

/** order by stddev() on columns of table "transcoder_profiles" */
export interface TranscoderProfilesStddevOrderBy {
  audio_bitrate_kbps?: Maybe<OrderBy>;
  frame_rate?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  video_bitrate_kbps?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by stddev_pop() on columns of table "transcoder_profiles" */
export interface TranscoderProfilesStddevPopOrderBy {
  audio_bitrate_kbps?: Maybe<OrderBy>;
  frame_rate?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  video_bitrate_kbps?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by stddev_samp() on columns of table "transcoder_profiles" */
export interface TranscoderProfilesStddevSampOrderBy {
  audio_bitrate_kbps?: Maybe<OrderBy>;
  frame_rate?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  video_bitrate_kbps?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** Streaming cursor of the table "transcoder_profiles" */
export interface TranscoderProfilesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: TranscoderProfilesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface TranscoderProfilesStreamCursorValueInput {
  audio_bitrate_kbps?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  frame_rate?: Maybe<Scalars["float8"]>;
  height?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  includes_audio?: Maybe<Scalars["Boolean"]>;
  includes_video?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  org_id?: Maybe<Scalars["uuid"]>;
  original_file_id?: Maybe<Scalars["uuid"]>;
  output_file_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<JobStatusTypesEnum>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  video_bitrate_kbps?: Maybe<Scalars["Int"]>;
  width?: Maybe<Scalars["Int"]>;
}

/** order by sum() on columns of table "transcoder_profiles" */
export interface TranscoderProfilesSumOrderBy {
  audio_bitrate_kbps?: Maybe<OrderBy>;
  frame_rate?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  video_bitrate_kbps?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** placeholder for update columns of table "transcoder_profiles" (current role has no relevant permissions) */
export enum TranscoderProfilesUpdateColumn {
  /** placeholder (do not use) */
  _PLACEHOLDER = "_PLACEHOLDER",
}

/** order by var_pop() on columns of table "transcoder_profiles" */
export interface TranscoderProfilesVarPopOrderBy {
  audio_bitrate_kbps?: Maybe<OrderBy>;
  frame_rate?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  video_bitrate_kbps?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by var_samp() on columns of table "transcoder_profiles" */
export interface TranscoderProfilesVarSampOrderBy {
  audio_bitrate_kbps?: Maybe<OrderBy>;
  frame_rate?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  video_bitrate_kbps?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** order by variance() on columns of table "transcoder_profiles" */
export interface TranscoderProfilesVarianceOrderBy {
  audio_bitrate_kbps?: Maybe<OrderBy>;
  frame_rate?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  video_bitrate_kbps?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** columns and relationships of "users" */
export interface Users {
  created_at: Scalars["timestamptz"];
  email?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  is_vl_admin: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  /** An array relationship */
  org_members: Array<OrgMembers>;
  /** An aggregate relationship */
  org_members_aggregate: OrgMembersAggregate;
  photo_url?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
}

/** columns and relationships of "users" */
export interface UsersOrgMembersArgs {
  distinct_on?: Maybe<Array<OrgMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OrgMembersOrderBy>>;
  where?: Maybe<OrgMembersBoolExp>;
}

/** columns and relationships of "users" */
export interface UsersOrgMembersAggregateArgs {
  distinct_on?: Maybe<Array<OrgMembersSelectColumn>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<OrgMembersOrderBy>>;
  where?: Maybe<OrgMembersBoolExp>;
}

/** aggregated selection of "users" */
export interface UsersAggregate {
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
}

/** aggregate fields of "users" */
export interface UsersAggregateFields {
  count: Scalars["Int"];
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
}

/** aggregate fields of "users" */
export interface UsersAggregateFieldsCountArgs {
  columns?: Maybe<Array<UsersSelectColumn>>;
  distinct?: Maybe<Scalars["Boolean"]>;
}

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export interface UsersBoolExp {
  _and?: Maybe<Array<UsersBoolExp>>;
  _not?: Maybe<UsersBoolExp>;
  _or?: Maybe<Array<UsersBoolExp>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_vl_admin?: Maybe<BooleanComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  org_members?: Maybe<OrgMembersBoolExp>;
  org_members_aggregate?: Maybe<OrgMembersAggregateBoolExp>;
  photo_url?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
}

/** aggregate max on columns */
export interface UsersMaxFields {
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  photo_url?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** aggregate min on columns */
export interface UsersMinFields {
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  photo_url?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** Ordering options when selecting data from "users". */
export interface UsersOrderBy {
  created_at?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_vl_admin?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  org_members_aggregate?: Maybe<OrgMembersAggregateOrderBy>;
  photo_url?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
}

/** select columns of table "users" */
export enum UsersSelectColumn {
  /** column name */
  created_at = "created_at",
  /** column name */
  email = "email",
  /** column name */
  id = "id",
  /** column name */
  is_vl_admin = "is_vl_admin",
  /** column name */
  name = "name",
  /** column name */
  photo_url = "photo_url",
  /** column name */
  updated_at = "updated_at",
}

/** Streaming cursor of the table "users" */
export interface UsersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: UsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface UsersStreamCursorValueInput {
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  is_vl_admin?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  photo_url?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export interface UuidComparisonExp {
  _eq?: Maybe<Scalars["uuid"]>;
  _gt?: Maybe<Scalars["uuid"]>;
  _gte?: Maybe<Scalars["uuid"]>;
  _in?: Maybe<Array<Scalars["uuid"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["uuid"]>;
  _lte?: Maybe<Scalars["uuid"]>;
  _neq?: Maybe<Scalars["uuid"]>;
  _nin?: Maybe<Array<Scalars["uuid"]>>;
}

/** columns and relationships of "video_properties" */
export interface VideoProperties {
  duration_in_seconds: Scalars["float8"];
  height: Scalars["Int"];
  id: Scalars["uuid"];
  /** An object relationship */
  media_asset?: Maybe<MediaAssets>;
  media_asset_id?: Maybe<Scalars["uuid"]>;
  width: Scalars["Int"];
}

/** Boolean expression to filter rows from the table "video_properties". All fields are combined with a logical 'AND'. */
export interface VideoPropertiesBoolExp {
  _and?: Maybe<Array<VideoPropertiesBoolExp>>;
  _not?: Maybe<VideoPropertiesBoolExp>;
  _or?: Maybe<Array<VideoPropertiesBoolExp>>;
  duration_in_seconds?: Maybe<Float8ComparisonExp>;
  height?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  media_asset?: Maybe<MediaAssetsBoolExp>;
  media_asset_id?: Maybe<UuidComparisonExp>;
  width?: Maybe<IntComparisonExp>;
}

/** Ordering options when selecting data from "video_properties". */
export interface VideoPropertiesOrderBy {
  duration_in_seconds?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  media_asset?: Maybe<MediaAssetsOrderBy>;
  media_asset_id?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
}

/** select columns of table "video_properties" */
export enum VideoPropertiesSelectColumn {
  /** column name */
  duration_in_seconds = "duration_in_seconds",
  /** column name */
  height = "height",
  /** column name */
  id = "id",
  /** column name */
  media_asset_id = "media_asset_id",
  /** column name */
  width = "width",
}

/** Streaming cursor of the table "video_properties" */
export interface VideoPropertiesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: VideoPropertiesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface VideoPropertiesStreamCursorValueInput {
  duration_in_seconds?: Maybe<Scalars["float8"]>;
  height?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  media_asset_id?: Maybe<Scalars["uuid"]>;
  width?: Maybe<Scalars["Int"]>;
}

/** columns and relationships of "vl_config" */
export interface VlConfig {
  default_storage_warehouse_id: Scalars["uuid"];
  id: Scalars["String"];
  proto?: Maybe<Scalars["jsonb"]>;
  title?: Maybe<Scalars["String"]>;
}

/** columns and relationships of "vl_config" */
export interface VlConfigProtoArgs {
  path?: Maybe<Scalars["String"]>;
}

/** Boolean expression to filter rows from the table "vl_config". All fields are combined with a logical 'AND'. */
export interface VlConfigBoolExp {
  _and?: Maybe<Array<VlConfigBoolExp>>;
  _not?: Maybe<VlConfigBoolExp>;
  _or?: Maybe<Array<VlConfigBoolExp>>;
  default_storage_warehouse_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  proto?: Maybe<JsonbComparisonExp>;
  title?: Maybe<StringComparisonExp>;
}

/** Ordering options when selecting data from "vl_config". */
export interface VlConfigOrderBy {
  default_storage_warehouse_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proto?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
}

/** select columns of table "vl_config" */
export enum VlConfigSelectColumn {
  /** column name */
  default_storage_warehouse_id = "default_storage_warehouse_id",
  /** column name */
  id = "id",
  /** column name */
  proto = "proto",
  /** column name */
  title = "title",
}

/** Streaming cursor of the table "vl_config" */
export interface VlConfigStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: VlConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface VlConfigStreamCursorValueInput {
  default_storage_warehouse_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["String"]>;
  proto?: Maybe<Scalars["jsonb"]>;
  title?: Maybe<Scalars["String"]>;
}

export type UpsertFolderMutationVariables = Exact<{
  folder: Array<FoldersInsertInput> | FoldersInsertInput;
}>;

export type UpsertFolderMutation = {
  folders?: Maybe<{ upserted: Array<{ id: string }> }>;
};

export type MediaAssetDetailsSubscriptionVariables = Exact<{
  mediaAssetId: Scalars["uuid"];
}>;

export type MediaAssetDetailsSubscription = {
  media?: Maybe<
    {
      creator?: Maybe<{ name?: Maybe<string>; email?: Maybe<string> }>;
      originalFiles: Array<{
        id: string;
        name: string;
        type: AssetFileTypesEnum;
        size: any;
        mimeType: string;
        transcoderProfiles: Array<{
          id: string;
          name: string;
          status: JobStatusTypesEnum;
          width: number;
          height: number;
          videoBitrateKbps: number;
          audioBitrateKbps: number;
          outputFile?: Maybe<{ id: string; storageKey: string }>;
        }>;
        thumbnails: Array<{
          width: number;
          height: number;
          originalFileId: string;
          file: { id: string };
        }>;
      }>;
      metaValues: Array<MetaFieldValuesFragment>;
      statistics: { total?: Maybe<{ file?: Maybe<{ size?: Maybe<any> }> }> };
    } & MediaAssetFragment
  >;
};

export type DeleteMetadataFieldMutationVariables = Exact<{
  fieldId: Scalars["uuid"];
}>;

export type DeleteMetadataFieldMutation = {
  delete_asset_metadata_fields?: Maybe<{ returning: Array<{ id: string }> }>;
};

export type MetadataFieldsSubscriptionVariables = Exact<{
  orgId: Scalars["uuid"];
}>;

export type MetadataFieldsSubscription = {
  metadataFields: Array<MetaFieldFragment>;
};

export type MetaFieldFragment = {
  id: string;
  type: AssetMetadataTypesEnum;
  name: string;
  options: any;
};

export type MetaFieldValuesFragment = {
  id: string;
  fieldId: string;
  booleanValues: Array<{ value: boolean }>;
  dateValues: Array<{ value: any }>;
  numberValues: Array<{ value: any }>;
  textValues: Array<{ value: string }>;
};

export type UpsertMetadataFieldMutationVariables = Exact<{
  field: AssetMetadataFieldsInsertInput;
}>;

export type UpsertMetadataFieldMutation = {
  insert_asset_metadata_fields?: Maybe<{ returning: Array<{ id: string }> }>;
};

export type AssetMetadataValuesSubscriptionVariables = Exact<{
  mediaAssetId: Scalars["uuid"];
  orgId: Scalars["uuid"];
}>;

export type AssetMetadataValuesSubscription = {
  metadataFields: Array<{
    id: string;
    name: string;
    options: any;
    type: AssetMetadataTypesEnum;
    metadataValues: Array<MetaFieldValuesFragment>;
  }>;
};

export type ReplaceAssetMetadataMutationVariables = Exact<{
  mediaAssetId: Scalars["uuid"];
  fieldIds: Array<Scalars["uuid"]> | Scalars["uuid"];
  newValues: Array<MetaFieldValuesInsertInput> | MetaFieldValuesInsertInput;
}>;

export type ReplaceAssetMetadataMutation = {
  delete_meta_field_values?: Maybe<{ affected_rows: number }>;
  insert_meta_field_values?: Maybe<{ returning: Array<{ id: string }> }>;
};

export type TagListSuggestionsSubscriptionVariables = Exact<{
  fieldId: Scalars["uuid"];
}>;

export type TagListSuggestionsSubscription = {
  metadataValues: Array<{ id: string; textValue: string }>;
};

export type AccessCodesSubscriptionVariables = Exact<{
  contentRowIds: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type AccessCodesSubscription = {
  accessCodes: Array<{ id: string; codes: any }>;
};

export type DeleteAccessCodeMutationVariables = Exact<{
  contentId: Scalars["uuid"];
  code: Scalars["String"];
}>;

export type DeleteAccessCodeMutation = {
  update_ticketing_access_codes?: Maybe<{ affected_rows: number }>;
};

export type EmbedBaseUrlSubscriptionVariables = Exact<{
  projectId: Scalars["uuid"];
}>;

export type EmbedBaseUrlSubscription = {
  project?: Maybe<{ id: string; embedBaseUrl?: Maybe<any> }>;
};

export type PublishingDataQueryVariables = Exact<{
  projectId: Scalars["uuid"];
}>;

export type PublishingDataQuery = {
  project?: Maybe<{
    id: string;
    locales: any;
    publishingUrl?: Maybe<string>;
    defaultLocale: CmsLocalesEnum;
  }>;
  schemas: Array<{ id: string; version: number; sdl: string }>;
  contentData: Array<{
    id: string;
    type: string;
    version: string;
    status: string;
    isAccessRestricted: boolean;
    localeData?: Maybe<any>;
  }>;
  latestPublications: Array<{ id: string; version: string }>;
  accessCodes: Array<{ id: string; codes: any; contentId: string }>;
};

export type PublishStatusSubscriptionVariables = Exact<{
  projectId: Scalars["uuid"];
}>;

export type PublishStatusSubscription = {
  project?: Maybe<{
    id: string;
    publishingUrl?: Maybe<string>;
    latestPublications: Array<{ id: string; status: string }>;
  }>;
};

export type TriggerPublishingMutationVariables = Exact<{
  projectId: Scalars["uuid"];
}>;

export type TriggerPublishingMutation = {
  insert_cms_publications?: Maybe<{ returning: Array<{ id: string }> }>;
  updatedRows?: Maybe<{ total: number }>;
};

export type UpsertAccessCodesMutationVariables = Exact<{
  contentId: Scalars["uuid"];
  codes: Scalars["jsonb"];
}>;

export type UpsertAccessCodesMutation = {
  accessCodes?: Maybe<{ affected_rows: number }>;
};

export type FolderPathSubscriptionVariables = Exact<{
  folderIds: Array<Scalars["uuid"]> | Scalars["uuid"];
  orgId: Scalars["uuid"];
}>;

export type FolderPathSubscription = {
  pathFolders: Array<{ folder: { id: string; name: string } }>;
};

export type ContentStatisticsQueryVariables = Exact<{
  contentId: Scalars["uuid"];
}>;

export type ContentStatisticsQuery = {
  tokens: { total?: Maybe<{ count: number }> };
  usedCodes: { total?: Maybe<{ count: number }> };
  ips: { total?: Maybe<{ count: number }> };
  usedIps: Array<{
    code?: Maybe<string>;
    ip: string;
    token: string;
    codeUsedAt: string;
    extraData?: Maybe<any>;
  }>;
};

export type OrganizationStatisticsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OrganizationStatisticsSubscription = {
  organizations: { total?: Maybe<{ count: number }> };
};

export type ProjectStatisticsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ProjectStatisticsSubscription = {
  projects: { total?: Maybe<{ count: number }> };
};

export type UserStatisticsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type UserStatisticsSubscription = {
  users: { total?: Maybe<{ count: number }> };
};

export type CheckOrgSlugQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type CheckOrgSlugQuery = { slugs: { total?: Maybe<{ count: number }> } };

export type CheckProjectSlugQueryVariables = Exact<{
  orgId: Scalars["uuid"];
  slug: Scalars["String"];
}>;

export type CheckProjectSlugQuery = {
  slugs: { total?: Maybe<{ count: number }> };
};

export type CreateOrganizationMutationVariables = Exact<{
  name: Scalars["name"];
  email: Scalars["String"];
  organizationType: Scalars["String"];
  phoneNumber: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  houseNumber?: Maybe<Scalars["Int"]>;
  zipCode?: Maybe<Scalars["Int"]>;
  companyCode?: Maybe<Scalars["Int"]>;
  vatNumber?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
}>;

export type CreateOrganizationMutation = {
  organizations?: Maybe<{ inserted: Array<{ id: string }> }>;
};

export type CreateMediaAssetMutationVariables = Exact<{
  mediaAssetId: Scalars["uuid"];
  orgId: Scalars["uuid"];
  folderId: Scalars["uuid"];
  mimeType: Scalars["String"];
  name: Scalars["String"];
  size: Scalars["bigint"];
  storageKey: Scalars["String"];
  warehouseId: Scalars["uuid"];
  mediaType: MediaAssetTypesEnum;
  metadata: Array<MetaFieldValuesInsertInput> | MetaFieldValuesInsertInput;
}>;

export type CreateMediaAssetMutation = {
  insert_media_assets?: Maybe<{ returning: Array<{ id: string }> }>;
};

export type LazyMetadataFieldsQueryVariables = Exact<{
  orgId: Scalars["uuid"];
}>;

export type LazyMetadataFieldsQuery = {
  metadataFields: Array<MetaFieldFragment>;
};

export type OrgDataBySlugSubscriptionVariables = Exact<{
  slug: Scalars["String"];
}>;

export type OrgDataBySlugSubscription = {
  organizations: Array<{ id: string; name: any }>;
};

export type OrgSlugSubscriptionVariables = Exact<{
  orgId: Scalars["uuid"];
}>;

export type OrgSlugSubscription = {
  org?: Maybe<{ id: string; name: any; slug: string }>;
};

export type ProjectDataBySlugSubscriptionVariables = Exact<{
  slug: Scalars["String"];
}>;

export type ProjectDataBySlugSubscription = {
  projects: Array<{
    id: string;
    name: string;
    slug?: Maybe<string>;
    imageUrl?: Maybe<string>;
    createdAt: string;
    orgId: string;
    updatedAt: string;
  }>;
};

export type VlConfigQueryVariables = Exact<{
  id?: Maybe<Scalars["String"]>;
}>;

export type VlConfigQuery = {
  defaultConfig?: Maybe<{
    id: string;
    proto?: Maybe<any>;
    defaultStorageWarehouseId: string;
  }>;
};

export type DeleteLiveStreamMutationVariables = Exact<{
  streamKey: Scalars["uuid"];
}>;

export type DeleteLiveStreamMutation = {
  deleted?: Maybe<{ streamKey: string }>;
};

export type LiveStreamSubscriptionVariables = Exact<{
  streamKey: Scalars["uuid"];
}>;

export type LiveStreamSubscription = {
  liveStream?: Maybe<{
    title: string;
    status: string;
    transcode: boolean;
    proto?: Maybe<any>;
    streamKey: string;
    createdAt: string;
    updatedAt: string;
    playbackKey: string;
  }>;
};

export type LiveStreamsSubscriptionVariables = Exact<{
  orgId: Scalars["uuid"];
}>;

export type LiveStreamsSubscription = {
  liveStreams: Array<{
    title: string;
    status: string;
    transcode: boolean;
    proto?: Maybe<any>;
    streamKey: string;
    createdAt: string;
    updatedAt: string;
    playbackKey: string;
  }>;
};

export type UpsertLiveStreamMutationVariables = Exact<{
  streams: Array<MediaLiveStreamsInsertInput> | MediaLiveStreamsInsertInput;
}>;

export type UpsertLiveStreamMutation = {
  addedStreams?: Maybe<{ returning: Array<{ title: string }> }>;
};

export type BinFoldersSubscriptionVariables = Exact<{
  orgId: Scalars["uuid"];
}>;

export type BinFoldersSubscription = {
  folders: Array<{
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    deletedAt?: Maybe<string>;
    parent?: Maybe<{ id: string }>;
  }>;
};

export type CreateArchiveJobMutationVariables = Exact<{
  orgId: Scalars["uuid"];
  outputWarehouseId: Scalars["uuid"];
  outputName: Scalars["String"];
  assetFiles: Array<ArchiveFilesInsertInput> | ArchiveFilesInsertInput;
  folderId: Scalars["uuid"];
  proto?: Maybe<Scalars["jsonb"]>;
}>;

export type CreateArchiveJobMutation = {
  result?: Maybe<{ archiveJobs: Array<{ id: string }> }>;
};

export type DeletedMediaSubscriptionVariables = Exact<{
  orgId: Scalars["uuid"];
  mediaSearch: MediaAssetsBoolExp;
  metadataFieldIds: Array<Scalars["uuid"]> | Scalars["uuid"];
  sortBy?: Maybe<Array<MediaAssetsOrderBy> | MediaAssetsOrderBy>;
}>;

export type DeletedMediaSubscription = {
  mediaAssets: Array<
    { metadataValues: Array<MetaFieldValuesFragment> } & MediaAssetFragment
  >;
};

export type DeleteAssetsMutationVariables = Exact<{
  assetIds: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type DeleteAssetsMutation = {
  deletedMedia?: Maybe<{ returning: Array<{ id: string }> }>;
  deletedFolders?: Maybe<{ returning: Array<{ id: string }> }>;
};

export type ExistingMediasSubscriptionVariables = Exact<{
  mediaSearch: MediaAssetsBoolExp;
  metadataFieldIds: Array<Scalars["uuid"]> | Scalars["uuid"];
  sortBy?: Maybe<Array<MediaAssetsOrderBy> | MediaAssetsOrderBy>;
}>;

export type ExistingMediasSubscription = {
  mediaAssets: Array<
    { metadataValues: Array<MetaFieldValuesFragment> } & MediaAssetFragment
  >;
};

export type FolderDetailsSubscriptionVariables = Exact<{
  folderId: Scalars["uuid"];
}>;

export type FolderDetailsSubscription = { folder?: Maybe<FolderFragment> };

export type FolderFragment = {
  id: string;
  name: string;
  description?: Maybe<string>;
  orgId: string;
  parentId?: Maybe<string>;
  createdAt: string;
  updatedAt: string;
  deletedAt?: Maybe<string>;
};

export type MediaAssetFragment = {
  id: string;
  name: string;
  description?: Maybe<string>;
  type: MediaAssetTypesEnum;
  createdAt: string;
  updatedAt: string;
  deletedAt?: Maybe<string>;
  orgId: string;
  folderId: string;
  originalFiles: Array<{
    id: string;
    name: string;
    thumbnails: Array<{ height: number; width: number; file: { id: string } }>;
    videoThumbnails: Array<{
      status: JobStatusTypesEnum;
      width?: Maybe<number>;
      height: number;
      file?: Maybe<{ id: string }>;
    }>;
  }>;
};

export type MediaAssetOriginalFilesQueryVariables = Exact<{
  mediaAssetIds: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type MediaAssetOriginalFilesQuery = {
  mediaAssets: Array<{ assetFiles: Array<{ id: string }> }>;
};

export type MoveAssetsMutationVariables = Exact<{
  assetIds: Array<Scalars["uuid"]> | Scalars["uuid"];
  toFolder: Scalars["uuid"];
}>;

export type MoveAssetsMutation = {
  movedMedia?: Maybe<{ returning: Array<{ id: string }> }>;
  movedFolders?: Maybe<{ returning: Array<{ id: string }> }>;
};

export type OrgFolderTreeSubscriptionVariables = Exact<{
  orgId: Scalars["uuid"];
}>;

export type OrgFolderTreeSubscription = {
  folders: Array<{ id: string; name: string; parentId?: Maybe<string> }>;
};

export type OrgRootFolderQueryVariables = Exact<{
  orgId: Scalars["uuid"];
}>;

export type OrgRootFolderQuery = {
  folders: Array<{ id: string; name: string }>;
};

export type SearchOrgAssetsSubscriptionVariables = Exact<{
  mediaSearch: MediaAssetsBoolExp;
  orgId: Scalars["uuid"];
  metadataFieldIds: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type SearchOrgAssetsSubscription = {
  mediaAssets: Array<
    { metadataValues: Array<MetaFieldValuesFragment> } & MediaAssetFragment
  >;
};

export type SetAssetsDeletedMutationVariables = Exact<{
  assetIds: Array<Scalars["uuid"]> | Scalars["uuid"];
  deletedAt?: Maybe<Scalars["timestamptz"]>;
}>;

export type SetAssetsDeletedMutation = {
  binnedAssets?: Maybe<{ returning: Array<{ id: string }> }>;
  binnedFolders?: Maybe<{ returning: Array<{ id: string }> }>;
};

export type UpdateMediaAssetMutationVariables = Exact<{
  assetId: Scalars["uuid"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
}>;

export type UpdateMediaAssetMutation = {
  update_media_assets?: Maybe<{ returning: Array<{ id: string }> }>;
};

export type MemberRolesSubscriptionVariables = Exact<{
  memberId: Scalars["uuid"];
}>;

export type MemberRolesSubscription = {
  member?: Maybe<{
    id: string;
    user: { id: string; name?: Maybe<string>; email?: Maybe<string> };
    roles: Array<{
      id: string;
      expires?: Maybe<any>;
      role: {
        id: string;
        name: string;
        type?: Maybe<string>;
        membersAgg: { total?: Maybe<{ count: number }> };
      };
      folderScopes: Array<{ id: string; folderId: string }>;
    }>;
    groupMemberships: Array<{
      group: {
        roles: Array<{
          id: string;
          expires?: Maybe<any>;
          role: { id: string; name: string; type?: Maybe<string> };
          folderScopes: Array<{ id: string; folderId: string }>;
        }>;
      };
    }>;
  }>;
};

export type MyArchiveJobsSubscriptionVariables = Exact<{
  archiveIds: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type MyArchiveJobsSubscription = {
  archives: Array<{
    id: string;
    status: TranscoderJobStatusTypesEnum;
    error?: Maybe<any>;
    outputName: string;
  }>;
};

export type AddOrgMemberMutationVariables = Exact<{
  orgId: Scalars["uuid"];
  userId: Scalars["uuid"];
}>;

export type AddOrgMemberMutation = {
  insert_org_members?: Maybe<{ returning: Array<{ id: string }> }>;
};

export type CreateOrgMemberInviteMutationVariables = Exact<{
  orgId: Scalars["uuid"];
  inviteeEmail: Scalars["String"];
}>;

export type CreateOrgMemberInviteMutation = {
  insert_org_member_invites?: Maybe<{ returning: Array<{ id: string }> }>;
};

export type DeleteOrgMemberMutationVariables = Exact<{
  memberId: Scalars["uuid"];
}>;

export type DeleteOrgMemberMutation = {
  delete_org_members?: Maybe<{ deleted: Array<{ id: string }> }>;
};

export type DeleteOrgMemberInviteMutationVariables = Exact<{
  inviteId: Scalars["uuid"];
}>;

export type DeleteOrgMemberInviteMutation = {
  delete_org_member_invites?: Maybe<{ returning: Array<{ id: string }> }>;
};

export type OrganizationDetailsSubscriptionVariables = Exact<{
  orgSlug: Scalars["String"];
}>;

export type OrganizationDetailsSubscription = {
  organizations: Array<{
    id: string;
    name: any;
    city?: Maybe<string>;
    email: string;
    notes?: Maybe<string>;
    street?: Maybe<string>;
    type: string;
    companyCode?: Maybe<number>;
    createdAt: string;
    houseNumber?: Maybe<number>;
    phoneNumber: string;
    updatedAt: string;
    vatNumber?: Maybe<number>;
    zipCode?: Maybe<number>;
  }>;
};

export type OrganizationMembersSubscriptionVariables = Exact<{
  orgSlug: Scalars["String"];
}>;

export type OrganizationMembersSubscription = {
  organizations: Array<{
    id: string;
    orgMembers: Array<{
      id: string;
      user: {
        id: string;
        email?: Maybe<string>;
        name?: Maybe<string>;
        photoUrl?: Maybe<string>;
      };
    }>;
    orgMemberInvites: Array<{
      id: string;
      inviteeEmail: string;
      isUsed: boolean;
    }>;
  }>;
};

export type OrganizationNamesSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OrganizationNamesSubscription = {
  organizations: Array<{
    id: string;
    name: any;
    avatar?: Maybe<string>;
    slug: string;
  }>;
};

export type OrgRolesSubscriptionVariables = Exact<{
  orgId: Scalars["uuid"];
}>;

export type OrgRolesSubscription = {
  roles: Array<{ id: string; name: string; type?: Maybe<string> }>;
};

export type OrgRoleMembersSubscriptionVariables = Exact<{
  orgId: Scalars["uuid"];
}>;

export type OrgRoleMembersSubscription = {
  roles: Array<{
    id: string;
    name: string;
    type?: Maybe<string>;
    members: Array<{
      id: string;
      memberId: string;
      user: { name?: Maybe<string>; email?: Maybe<string> };
    }>;
    groups: Array<{ id: string; group: { id: string; name: string } }>;
  }>;
};

export type OrgTeamsSubscriptionVariables = Exact<{
  orgId: Scalars["uuid"];
  distinctOn?: Maybe<
    | Array<PermissionUserGroupRolesSelectColumn>
    | PermissionUserGroupRolesSelectColumn
  >;
}>;

export type OrgTeamsSubscription = {
  teams: Array<{
    id: string;
    name: string;
    roles: Array<{
      id: string;
      expires?: Maybe<any>;
      role: { id: string; name: string };
      folderScopes: Array<{ id: string; folderId: string }>;
    }>;
    members: Array<{
      id: string;
      user: { id: string; name?: Maybe<string>; email?: Maybe<string> };
    }>;
  }>;
};

export type SearchMembersToAddQueryVariables = Exact<{
  partialNameOrEmail: Scalars["String"];
  excludedOrgIds?: Maybe<Array<Scalars["uuid"]> | Scalars["uuid"]>;
  includedMemberOrgs?: Maybe<Array<Scalars["uuid"]> | Scalars["uuid"]>;
}>;

export type SearchMembersToAddQuery = {
  users: Array<{
    id: string;
    email?: Maybe<string>;
    name?: Maybe<string>;
    orgMembers: Array<{ id: string }>;
  }>;
};

export type TeamDetailsSubscriptionVariables = Exact<{
  teamId: Scalars["uuid"];
}>;

export type TeamDetailsSubscription = {
  team?: Maybe<{
    id: string;
    name: string;
    roles: Array<{
      id: string;
      expires?: Maybe<any>;
      role: { id: string; name: string };
      folderScopes: Array<{ id: string; folderId: string }>;
    }>;
    members: Array<{
      id: string;
      user: { id: string; name?: Maybe<string>; email?: Maybe<string> };
    }>;
  }>;
};

export type UpsertMemberRolesMutationVariables = Exact<{
  memberId: Scalars["uuid"];
  roles:
    | Array<PermissionOrgMemberRolesInsertInput>
    | PermissionOrgMemberRolesInsertInput;
}>;

export type UpsertMemberRolesMutation = {
  deleted?: Maybe<{ returning: Array<{ id: string }> }>;
  upserted?: Maybe<{ roles: Array<{ id: string }> }>;
};

export type UpsertRoleMutationVariables = Exact<{
  role: PermissionRolesInsertInput;
}>;

export type UpsertRoleMutation = {
  upserted?: Maybe<{ roles: Array<{ id: string }> }>;
};

export type UpsertTeamMutationVariables = Exact<{
  teamId?: Maybe<Scalars["uuid"]>;
  orgId?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  roles:
    | Array<PermissionUserGroupRolesInsertInput>
    | PermissionUserGroupRolesInsertInput;
  members:
    | Array<PermissionUserGroupMembersInsertInput>
    | PermissionUserGroupMembersInsertInput;
}>;

export type UpsertTeamMutation = {
  deletedRoles?: Maybe<{ affected_rows: number }>;
  deletedMembers?: Maybe<{ affected_rows: number }>;
  upserted?: Maybe<{ teams: Array<{ id: string }> }>;
};

export type UpsertTeamRoleMutationVariables = Exact<{
  role: PermissionUserGroupRolesInsertInput;
}>;

export type UpsertTeamRoleMutation = {
  upserted?: Maybe<{ roles: Array<{ id: string }> }>;
};

export type CanDeleteFolderSubscriptionVariables = Exact<{
  folderId: Scalars["uuid"];
  userId: Scalars["uuid"];
}>;

export type CanDeleteFolderSubscription = {
  removableFolder: Array<{ folderId?: Maybe<string> }>;
};

export type CanEditFolderSubscriptionVariables = Exact<{
  folderId: Scalars["uuid"];
  userId: Scalars["uuid"];
}>;

export type CanEditFolderSubscription = {
  editableFolders: Array<{ folderId?: Maybe<string> }>;
};

export type EditableFoldersSubscriptionVariables = Exact<{
  orgId: Scalars["uuid"];
  userId: Scalars["uuid"];
}>;

export type EditableFoldersSubscription = {
  editableFolders: Array<{ folderId?: Maybe<string> }>;
};

export type EditableMetaSubscriptionVariables = Exact<{
  mediaId: Scalars["uuid"];
}>;

export type EditableMetaSubscription = {
  editableMeta: Array<{ fieldId?: Maybe<string> }>;
};

export type RemovableMediasSubscriptionVariables = Exact<{
  mediaIds: Array<Scalars["uuid"]> | Scalars["uuid"];
  userId: Scalars["uuid"];
}>;

export type RemovableMediasSubscription = {
  removableMedias: Array<{ mediaAssetId?: Maybe<string> }>;
};

export type ViewableMetaSubscriptionVariables = Exact<{
  folderId: Scalars["uuid"];
}>;

export type ViewableMetaSubscription = {
  viewableMeta: Array<{ fieldId?: Maybe<string> }>;
};

export type CreateCmsProjectMutationVariables = Exact<{
  projectId: Scalars["uuid"];
  cmsRow: CmsContentRowsInsertInput;
  schemaSdl: Scalars["String"];
}>;

export type CreateCmsProjectMutation = {
  insertedRow?: Maybe<{ id: string }>;
  insertedSchema?: Maybe<{ id: string }>;
};

export type CreateProjectMutationVariables = Exact<{
  name: Scalars["String"];
  orgId: Scalars["uuid"];
  slug?: Maybe<Scalars["String"]>;
  extraData?: Maybe<Scalars["jsonb"]>;
}>;

export type CreateProjectMutation = {
  insert_projects?: Maybe<{ returning: Array<{ id: string }> }>;
};

export type ProjectsGridSubscriptionVariables = Exact<{ [key: string]: never }>;

export type ProjectsGridSubscription = {
  projects: Array<{
    id: string;
    name: string;
    slug?: Maybe<string>;
    createdAt: string;
    imageUrl?: Maybe<string>;
    organization: { id: string; name: any };
  }>;
};

export type ProjectsTableSubscriptionVariables = Exact<{
  orgId: Scalars["uuid"];
}>;

export type ProjectsTableSubscription = {
  projects: Array<{
    id: string;
    name: string;
    slug?: Maybe<string>;
    createdAt: string;
    organization: { id: string; name: any };
  }>;
};

export type RoleDetailsSubscriptionVariables = Exact<{
  roleId: Scalars["uuid"];
}>;

export type RoleDetailsSubscription = {
  role?: Maybe<{
    id: string;
    name: string;
    notes?: Maybe<string>;
    type?: Maybe<string>;
    orgDataScopes: any;
    orgMembersScopes: any;
    ownedMediaScopes: any;
    sharedMediaScopes: any;
    metaScopes: Array<{ scopes: any; fieldId: string }>;
  }>;
};

export type CreateLinkShareMutationVariables = Exact<{
  title?: Maybe<Scalars["String"]>;
  validFrom?: Maybe<Scalars["timestamptz"]>;
  validUntil?: Maybe<Scalars["timestamptz"]>;
  downloadEnabled?: Maybe<Scalars["Boolean"]>;
  mediaId?: Maybe<Scalars["uuid"]>;
}>;

export type CreateLinkShareMutation = {
  inserted?: Maybe<{ returning: Array<{ id: string }> }>;
};

export type DeleteLinkShareMutationVariables = Exact<{
  linkShareId: Scalars["uuid"];
}>;

export type DeleteLinkShareMutation = {
  delete_fe_link_share?: Maybe<{ returning: Array<{ id: string }> }>;
};

export type LinkSharesSubscriptionVariables = Exact<{
  mediaId: Scalars["uuid"];
}>;

export type LinkSharesSubscription = {
  linkShares: Array<{
    id: string;
    title?: Maybe<string>;
    validFrom?: Maybe<string>;
    validUntil?: Maybe<string>;
    downloadEnabled?: Maybe<boolean>;
  }>;
};

export type CmsProjectsSubscriptionVariables = Exact<{
  orgId: Scalars["uuid"];
  session?: Maybe<Scalars["String"]>;
}>;

export type CmsProjectsSubscription = {
  projects: Array<{
    id: string;
    data: any;
    proto?: Maybe<any>;
    project: { proto?: Maybe<any> };
  }>;
};

export type CmsProjectResourcesQueryVariables = Exact<{
  projects: Array<Scalars["uuid"]> | Scalars["uuid"];
  session?: Maybe<Scalars["String"]>;
}>;

export type CmsProjectResourcesQuery = {
  cms_resources: Array<{ id: string; data: any; proto?: Maybe<any> }>;
};

export type CmsResourcesSubscriptionVariables = Exact<{
  projects: Array<Scalars["uuid"]> | Scalars["uuid"];
  after: Scalars["timestamptz"];
  session?: Maybe<Scalars["String"]>;
}>;

export type CmsResourcesSubscription = {
  cms_resources_stream: Array<{ id: string; data: any; proto?: Maybe<any> }>;
};

export type CreateProjectPublicationMutationVariables = Exact<{
  publications:
    | Array<CmsProjectPublicationsInsertInput>
    | CmsProjectPublicationsInsertInput;
}>;

export type CreateProjectPublicationMutation = {
  publication?: Maybe<{ affected_rows: number }>;
};

export type ProjectsListSubscriptionVariables = Exact<{
  orgId: Scalars["uuid"];
}>;

export type ProjectsListSubscription = {
  projects: Array<{
    id: string;
    data: any;
    proto?: Maybe<any>;
    project: { proto?: Maybe<any> };
  }>;
};

export type UpdateCmsResourcesMutationVariables = Exact<{
  resourceId: Scalars["uuid"];
  resource: CmsResourcesSetInput;
  expectedHash?: Maybe<Scalars["String"]>;
}>;

export type UpdateCmsResourcesMutation = {
  update_cms_resources?: Maybe<{
    affected_rows: number;
    returning: Array<{ id: string; data: any }>;
  }>;
};

export type UpsertCmsResourcesMutationVariables = Exact<{
  resources: Array<CmsResourcesInsertInput> | CmsResourcesInsertInput;
}>;

export type UpsertCmsResourcesMutation = {
  insert_cms_resources?: Maybe<{ returning: Array<{ id: string; data: any }> }>;
};

export type OrganizationTableSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type OrganizationTableSubscription = {
  organizations: Array<{
    id: string;
    name: any;
    type: string;
    email: string;
    slug: string;
    createdAt: string;
    orgMembers: { total?: Maybe<{ count: number }> };
  }>;
};

export type UsersTabelSubscriptionVariables = Exact<{ [key: string]: never }>;

export type UsersTabelSubscription = {
  users: Array<{
    id: string;
    name?: Maybe<string>;
    email?: Maybe<string>;
    photoUrl?: Maybe<string>;
    createdAt: string;
  }>;
};

export type TicketOptionFragment = {
  id: string;
  name: string;
  price: number;
  currency: string;
  validUntil?: Maybe<string>;
  validForUnit?: Maybe<string>;
  validForAmount?: Maybe<number>;
  ticketType: string;
  contentId: string;
};

export type ContentTicketsSubscriptionVariables = Exact<{
  contentId: Scalars["uuid"];
}>;

export type ContentTicketsSubscription = {
  tickets: Array<TicketOptionFragment>;
};

export type UpsertTicketMutationVariables = Exact<{
  ticket:
    | Array<TicketingTicketOptionsInsertInput>
    | TicketingTicketOptionsInsertInput;
}>;

export type UpsertTicketMutation = {
  upserted?: Maybe<{ affected_rows: number }>;
};

export type CmsDataSubscriptionVariables = Exact<{
  projectId?: Maybe<Scalars["uuid"]>;
  ignoreRevision?: Maybe<Scalars["String"]>;
}>;

export type CmsDataSubscription = {
  cmsContents: Array<{
    id: string;
    type: string;
    status: string;
    createdAt: string;
    deletedAt?: Maybe<string>;
    isAccessRestricted: boolean;
    localeData?: Maybe<any>;
  }>;
};

export type ContentSchemasSubscriptionVariables = Exact<{
  projectId?: Maybe<Scalars["uuid"]>;
}>;

export type ContentSchemasSubscription = {
  contentSchemas: Array<{ schemaSdl: string }>;
};

export type DeleteCmsDataMutationVariables = Exact<{
  rowIds: Array<Scalars["uuid"]> | Scalars["uuid"];
  deletedAt: Scalars["timestamptz"];
  revision: Scalars["String"];
}>;

export type DeleteCmsDataMutation = {
  update_cms_content_rows?: Maybe<{ affected_rows: number }>;
};

export type DeleteProjectDataMutationVariables = Exact<{
  projectId: Scalars["uuid"];
}>;

export type DeleteProjectDataMutation = {
  delete_projects_by_pk?: Maybe<{ id: string; name: string }>;
};

export type MyPermissionsSubscriptionVariables = Exact<{
  userId: Scalars["uuid"];
  orgId: Scalars["uuid"];
}>;

export type MyPermissionsSubscription = {
  roles: Array<{
    id: string;
    name: string;
    orgDataScopes: any;
    orgMembersScopes: any;
    ownedMediaScopes: any;
    sharedMediaScopes: any;
  }>;
};

export type OrgFoldersSubscriptionVariables = Exact<{
  orgId: Scalars["uuid"];
}>;

export type OrgFoldersSubscription = {
  folders: Array<{ id: string; name: string; parentId?: Maybe<string> }>;
};

export type ProjectDataSubscriptionVariables = Exact<{
  projectId: Scalars["uuid"];
}>;

export type ProjectDataSubscription = {
  project?: Maybe<{
    id: string;
    locales: any;
    slug?: Maybe<string>;
    defaultLocale: CmsLocalesEnum;
    extraData?: Maybe<any>;
    org: { id: string; slug: string };
  }>;
};

export type UpsertCmsDataMutationVariables = Exact<{
  id: Scalars["uuid"];
  type: Scalars["String"];
  projectId: Scalars["uuid"];
  revision?: Maybe<Scalars["String"]>;
  isAccessRestricted?: Maybe<Scalars["Boolean"]>;
  localeData: Scalars["jsonb"];
  status: Scalars["String"];
}>;

export type UpsertCmsDataMutation = {
  cmsContent?: Maybe<{ affected_rows: number }>;
};

export const MetaFieldFragmentDoc = gql`
  fragment MetaField on asset_metadata_fields {
    id
    type
    name
    options
  }
`;
export const MetaFieldValuesFragmentDoc = gql`
  fragment MetaFieldValues on meta_field_values {
    id
    fieldId: field_id
    booleanValues: boolean_values {
      value
    }
    dateValues: date_values {
      value
    }
    numberValues: number_values {
      value
    }
    textValues: text_values {
      value
    }
  }
`;
export const FolderFragmentDoc = gql`
  fragment Folder on folders {
    id
    name
    description
    orgId: org_id
    parentId: parent_id
    createdAt: created_at
    updatedAt: updated_at
    deletedAt: deleted_at
  }
`;
export const MediaAssetFragmentDoc = gql`
  fragment MediaAsset on media_assets {
    id
    name
    description
    type
    createdAt: created_at
    updatedAt: updated_at
    deletedAt: deleted_at
    orgId: org_id
    folderId: folder_id
    originalFiles: asset_files(where: { type: { _eq: ORIGINAL } }) {
      id
      name
      thumbnails: image_thumbnails(order_by: { width: asc }) {
        height
        width
        file: thumbnail_file {
          id
        }
      }
      videoThumbnails: thumbnail_profiles(
        where: { status: { _eq: FINISHED } }
        order_by: { width: asc }
      ) {
        status
        width
        height
        file: output_file {
          id
        }
      }
    }
  }
`;
export const TicketOptionFragmentDoc = gql`
  fragment TicketOption on ticketing_ticket_options {
    id
    name
    price
    currency
    validUntil: valid_until
    validForUnit: valid_for_unit
    validForAmount: valid_for_amount
    ticketType: ticket_type
    contentId: content_id
  }
`;
export const UpsertFolderDocument = gql`
  mutation UpsertFolder($folder: [folders_insert_input!]!) {
    folders: insert_folders(
      objects: $folder
      on_conflict: {
        constraint: folders_pkey
        update_columns: [name, description]
      }
    ) {
      upserted: returning {
        id
      }
    }
  }
`;
export function useUpsertFolderMutation(
  variables?: UpsertFolderMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertFolderMutation,
    UpsertFolderMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpsertFolderMutation,
    UpsertFolderMutationVariables
  >(UpsertFolderDocument, { variables, ...baseOptions });
}
export const MediaAssetDetailsDocument = gql`
  subscription MediaAssetDetails($mediaAssetId: uuid!) {
    media: media_assets_by_pk(id: $mediaAssetId) {
      ...MediaAsset
      creator {
        name
        email
      }
      originalFiles: asset_files(where: { type: { _eq: ORIGINAL } }) {
        id
        name
        type
        mimeType: mime_type
        size
        transcoderProfiles: transcoder_profiles(order_by: { width: asc }) {
          id
          name
          status
          width
          height
          videoBitrateKbps: video_bitrate_kbps
          audioBitrateKbps: audio_bitrate_kbps
          outputFile: output_file {
            id
            storageKey: storage_key
          }
        }
        thumbnails: image_thumbnails(order_by: { width: asc }) {
          originalFileId: original_file_id
          width
          height
          file: thumbnail_file {
            id
          }
        }
      }
      metaValues: meta_field_values {
        ...MetaFieldValues
      }
      statistics: asset_files_aggregate {
        total: aggregate {
          file: sum {
            size
          }
        }
      }
    }
  }
  ${MediaAssetFragmentDoc}
  ${MetaFieldValuesFragmentDoc}
`;
export function useMediaAssetDetailsSubscription(
  variables: MediaAssetDetailsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    MediaAssetDetailsSubscription,
    MediaAssetDetailsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    MediaAssetDetailsSubscription,
    MediaAssetDetailsSubscriptionVariables
  >(MediaAssetDetailsDocument, { variables, ...baseOptions });
}
export const DeleteMetadataFieldDocument = gql`
  mutation DeleteMetadataField($fieldId: uuid!) {
    delete_asset_metadata_fields(where: { id: { _eq: $fieldId } }) {
      returning {
        id
      }
    }
  }
`;
export function useDeleteMetadataFieldMutation(
  variables?: DeleteMetadataFieldMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMetadataFieldMutation,
    DeleteMetadataFieldMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteMetadataFieldMutation,
    DeleteMetadataFieldMutationVariables
  >(DeleteMetadataFieldDocument, { variables, ...baseOptions });
}
export const MetadataFieldsDocument = gql`
  subscription MetadataFields($orgId: uuid!) {
    metadataFields: asset_metadata_fields(
      where: { org_id: { _eq: $orgId } }
      order_by: { created_at: desc }
    ) {
      ...MetaField
    }
  }
  ${MetaFieldFragmentDoc}
`;
export function useMetadataFieldsSubscription(
  variables: MetadataFieldsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    MetadataFieldsSubscription,
    MetadataFieldsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    MetadataFieldsSubscription,
    MetadataFieldsSubscriptionVariables
  >(MetadataFieldsDocument, { variables, ...baseOptions });
}
export const UpsertMetadataFieldDocument = gql`
  mutation UpsertMetadataField($field: asset_metadata_fields_insert_input!) {
    insert_asset_metadata_fields(
      objects: [$field]
      on_conflict: {
        constraint: asset_metadata_fields_pkey
        update_columns: [name, type, options]
      }
    ) {
      returning {
        id
      }
    }
  }
`;
export function useUpsertMetadataFieldMutation(
  variables?: UpsertMetadataFieldMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertMetadataFieldMutation,
    UpsertMetadataFieldMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpsertMetadataFieldMutation,
    UpsertMetadataFieldMutationVariables
  >(UpsertMetadataFieldDocument, { variables, ...baseOptions });
}
export const AssetMetadataValuesDocument = gql`
  subscription AssetMetadataValues($mediaAssetId: uuid!, $orgId: uuid!) {
    metadataFields: asset_metadata_fields(
      where: { org_id: { _eq: $orgId } }
      order_by: { created_at: desc }
    ) {
      id
      name
      options
      type
      metadataValues: values(where: { asset_id: { _eq: $mediaAssetId } }) {
        ...MetaFieldValues
      }
    }
  }
  ${MetaFieldValuesFragmentDoc}
`;
export function useAssetMetadataValuesSubscription(
  variables: AssetMetadataValuesSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    AssetMetadataValuesSubscription,
    AssetMetadataValuesSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    AssetMetadataValuesSubscription,
    AssetMetadataValuesSubscriptionVariables
  >(AssetMetadataValuesDocument, { variables, ...baseOptions });
}
export const ReplaceAssetMetadataDocument = gql`
  mutation ReplaceAssetMetadata(
    $mediaAssetId: uuid!
    $fieldIds: [uuid!]!
    $newValues: [meta_field_values_insert_input!]!
  ) {
    delete_meta_field_values(
      where: {
        _and: [
          { asset_id: { _eq: $mediaAssetId } }
          { field_id: { _in: $fieldIds } }
        ]
      }
    ) {
      affected_rows
    }
    insert_meta_field_values(objects: $newValues) {
      returning {
        id
      }
    }
  }
`;
export function useReplaceAssetMetadataMutation(
  variables?: ReplaceAssetMetadataMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReplaceAssetMetadataMutation,
    ReplaceAssetMetadataMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    ReplaceAssetMetadataMutation,
    ReplaceAssetMetadataMutationVariables
  >(ReplaceAssetMetadataDocument, { variables, ...baseOptions });
}
export const TagListSuggestionsDocument = gql`
  subscription TagListSuggestions($fieldId: uuid!) {
    metadataValues: meta_text_values(
      where: {
        _and: [
          { field_values: { field: { type: { _eq: TAG_LIST } } } }
          { field_values: { field_id: { _eq: $fieldId } } }
        ]
      }
      distinct_on: value
      limit: 20
    ) {
      id
      textValue: value
    }
  }
`;
export function useTagListSuggestionsSubscription(
  variables: TagListSuggestionsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    TagListSuggestionsSubscription,
    TagListSuggestionsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    TagListSuggestionsSubscription,
    TagListSuggestionsSubscriptionVariables
  >(TagListSuggestionsDocument, { variables, ...baseOptions });
}
export const AccessCodesDocument = gql`
  subscription AccessCodes($contentRowIds: [uuid!]!) {
    accessCodes: ticketing_access_codes(
      where: { content_row_id: { _in: $contentRowIds } }
      order_by: { created_at: asc }
    ) {
      id
      codes
    }
  }
`;
export function useAccessCodesSubscription(
  variables: AccessCodesSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    AccessCodesSubscription,
    AccessCodesSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    AccessCodesSubscription,
    AccessCodesSubscriptionVariables
  >(AccessCodesDocument, { variables, ...baseOptions });
}
export const DeleteAccessCodeDocument = gql`
  mutation DeleteAccessCode($contentId: uuid!, $code: String!) {
    update_ticketing_access_codes(
      where: { content_row_id: { _eq: $contentId } }
      _delete_key: { codes: $code }
    ) {
      affected_rows
    }
  }
`;
export function useDeleteAccessCodeMutation(
  variables?: DeleteAccessCodeMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteAccessCodeMutation,
    DeleteAccessCodeMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteAccessCodeMutation,
    DeleteAccessCodeMutationVariables
  >(DeleteAccessCodeDocument, { variables, ...baseOptions });
}
export const EmbedBaseUrlDocument = gql`
  subscription EmbedBaseUrl($projectId: uuid!) {
    project: projects_by_pk(id: $projectId) {
      id
      embedBaseUrl: extra_data(path: "embedBaseUrl")
    }
  }
`;
export function useEmbedBaseUrlSubscription(
  variables: EmbedBaseUrlSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    EmbedBaseUrlSubscription,
    EmbedBaseUrlSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    EmbedBaseUrlSubscription,
    EmbedBaseUrlSubscriptionVariables
  >(EmbedBaseUrlDocument, { variables, ...baseOptions });
}
export const PublishingDataDocument = gql`
  query PublishingData($projectId: uuid!) {
    project: projects_by_pk(id: $projectId) {
      id
      publishingUrl: publishing_url
      defaultLocale: default_locale
      locales
    }
    schemas: cms_content_schemas(
      where: { project_id: { _eq: $projectId } }
      order_by: { updated_at: desc }
      limit: 1
    ) {
      id
      sdl: schema_sdl
      version
    }
    contentData: cms_content_rows(
      where: {
        _and: [
          { project_id: { _eq: $projectId } }
          { deleted_at: { _is_null: true } }
        ]
      }
    ) {
      id
      type
      isAccessRestricted: is_access_restricted
      localeData: locale_data
      version
      status
    }
    latestPublications: cms_publications(
      where: { project_id: { _eq: $projectId } }
      order_by: { created_at: desc }
      limit: 1
    ) {
      id
      version
    }
    accessCodes: ticketing_access_codes(
      where: { content_row: { project_id: { _eq: $projectId } } }
    ) {
      id
      codes
      contentId: content_row_id
    }
  }
`;
export function usePublishingDataQuery(
  variables: PublishingDataQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PublishingDataQuery,
    PublishingDataQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    PublishingDataQuery,
    PublishingDataQueryVariables
  >(PublishingDataDocument, { variables, ...baseOptions });
}
export function usePublishingDataLazyQuery(
  variables?: PublishingDataQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PublishingDataQuery,
    PublishingDataQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    PublishingDataQuery,
    PublishingDataQueryVariables
  >(PublishingDataDocument, { variables, ...baseOptions });
}
export const PublishStatusDocument = gql`
  subscription PublishStatus($projectId: uuid!) {
    project: projects_by_pk(id: $projectId) {
      id
      publishingUrl: publishing_url
      latestPublications: publications(
        where: { project_id: { _eq: $projectId } }
        order_by: { created_at: desc }
        limit: 1
      ) {
        id
        status
      }
    }
  }
`;
export function usePublishStatusSubscription(
  variables: PublishStatusSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    PublishStatusSubscription,
    PublishStatusSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    PublishStatusSubscription,
    PublishStatusSubscriptionVariables
  >(PublishStatusDocument, { variables, ...baseOptions });
}
export const TriggerPublishingDocument = gql`
  mutation TriggerPublishing($projectId: uuid!) {
    insert_cms_publications(
      objects: {
        project_id: $projectId
        published_data: {}
        schema_sdl: ""
        publishing_webhook_url: ""
      }
    ) {
      returning {
        id
      }
    }
    updatedRows: update_cms_content_rows(
      where: { project_id: { _eq: $projectId }, deleted_at: { _is_null: true } }
      _set: { status: "PUBLISHING", revision: "PUBLISHER" }
    ) {
      total: affected_rows
    }
  }
`;
export function useTriggerPublishingMutation(
  variables?: TriggerPublishingMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TriggerPublishingMutation,
    TriggerPublishingMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    TriggerPublishingMutation,
    TriggerPublishingMutationVariables
  >(TriggerPublishingDocument, { variables, ...baseOptions });
}
export const UpsertAccessCodesDocument = gql`
  mutation UpsertAccessCodes($contentId: uuid!, $codes: jsonb!) {
    accessCodes: insert_ticketing_access_codes(
      objects: { content_row_id: $contentId, codes: $codes }
      on_conflict: {
        constraint: access_codes_content_row_id_key
        update_columns: [codes]
      }
    ) {
      affected_rows
    }
  }
`;
export function useUpsertAccessCodesMutation(
  variables?: UpsertAccessCodesMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertAccessCodesMutation,
    UpsertAccessCodesMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpsertAccessCodesMutation,
    UpsertAccessCodesMutationVariables
  >(UpsertAccessCodesDocument, { variables, ...baseOptions });
}
export const FolderPathDocument = gql`
  subscription FolderPath($folderIds: [uuid!]!, $orgId: uuid!) {
    pathFolders: folder_hierarchy(
      where: {
        _or: [
          { child_folder_id: { _in: $folderIds } }
          {
            child_folder: {
              _and: { parent_id: { _is_null: true }, org_id: { _eq: $orgId } }
            }
          }
        ]
      }
      order_by: { child_folder: { parent_id: asc_nulls_first }, depth: desc }
    ) {
      folder: parent_folder {
        id
        name
      }
    }
  }
`;
export function useFolderPathSubscription(
  variables: FolderPathSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    FolderPathSubscription,
    FolderPathSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    FolderPathSubscription,
    FolderPathSubscriptionVariables
  >(FolderPathDocument, { variables, ...baseOptions });
}
export const ContentStatisticsDocument = gql`
  query ContentStatistics($contentId: uuid!) {
    tokens: ticketing_access_tokens_aggregate(
      where: { content_row_id: { _eq: $contentId } }
    ) {
      total: aggregate {
        count
      }
    }
    usedCodes: ticketing_access_tokens_aggregate(
      where: { content_row_id: { _eq: $contentId } }
      distinct_on: code
    ) {
      total: aggregate {
        count
      }
    }
    ips: ticketing_access_tokens_aggregate(
      where: { content_row_id: { _eq: $contentId } }
      distinct_on: ip
    ) {
      total: aggregate {
        count
      }
    }
    usedIps: ticketing_access_tokens(
      where: { content_row_id: { _eq: $contentId } }
      order_by: { created_at: desc }
    ) {
      token: id
      code
      ip
      codeUsedAt: created_at
      extraData: extra_data
    }
  }
`;
export function useContentStatisticsQuery(
  variables: ContentStatisticsQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ContentStatisticsQuery,
    ContentStatisticsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    ContentStatisticsQuery,
    ContentStatisticsQueryVariables
  >(ContentStatisticsDocument, { variables, ...baseOptions });
}
export function useContentStatisticsLazyQuery(
  variables?: ContentStatisticsQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentStatisticsQuery,
    ContentStatisticsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    ContentStatisticsQuery,
    ContentStatisticsQueryVariables
  >(ContentStatisticsDocument, { variables, ...baseOptions });
}
export const OrganizationStatisticsDocument = gql`
  subscription OrganizationStatistics {
    organizations: organizations_aggregate {
      total: aggregate {
        count
      }
    }
  }
`;
export function useOrganizationStatisticsSubscription(
  variables: OrganizationStatisticsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    OrganizationStatisticsSubscription,
    OrganizationStatisticsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    OrganizationStatisticsSubscription,
    OrganizationStatisticsSubscriptionVariables
  >(OrganizationStatisticsDocument, { variables, ...baseOptions });
}
export const ProjectStatisticsDocument = gql`
  subscription ProjectStatistics {
    projects: projects_aggregate {
      total: aggregate {
        count
      }
    }
  }
`;
export function useProjectStatisticsSubscription(
  variables: ProjectStatisticsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    ProjectStatisticsSubscription,
    ProjectStatisticsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    ProjectStatisticsSubscription,
    ProjectStatisticsSubscriptionVariables
  >(ProjectStatisticsDocument, { variables, ...baseOptions });
}
export const UserStatisticsDocument = gql`
  subscription UserStatistics {
    users: users_aggregate {
      total: aggregate {
        count
      }
    }
  }
`;
export function useUserStatisticsSubscription(
  variables: UserStatisticsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    UserStatisticsSubscription,
    UserStatisticsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    UserStatisticsSubscription,
    UserStatisticsSubscriptionVariables
  >(UserStatisticsDocument, { variables, ...baseOptions });
}
export const CheckOrgSlugDocument = gql`
  query CheckOrgSlug($slug: String!) {
    slugs: organizations_aggregate(where: { slug: { _eq: $slug } }) {
      total: aggregate {
        count
      }
    }
  }
`;
export function useCheckOrgSlugQuery(
  variables: CheckOrgSlugQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CheckOrgSlugQuery,
    CheckOrgSlugQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    CheckOrgSlugQuery,
    CheckOrgSlugQueryVariables
  >(CheckOrgSlugDocument, { variables, ...baseOptions });
}
export function useCheckOrgSlugLazyQuery(
  variables?: CheckOrgSlugQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CheckOrgSlugQuery,
    CheckOrgSlugQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    CheckOrgSlugQuery,
    CheckOrgSlugQueryVariables
  >(CheckOrgSlugDocument, { variables, ...baseOptions });
}
export const CheckProjectSlugDocument = gql`
  query CheckProjectSlug($orgId: uuid!, $slug: String!) {
    slugs: projects_aggregate(
      where: { _and: { org_id: { _eq: $orgId }, slug: { _eq: $slug } } }
    ) {
      total: aggregate {
        count
      }
    }
  }
`;
export function useCheckProjectSlugQuery(
  variables: CheckProjectSlugQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CheckProjectSlugQuery,
    CheckProjectSlugQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    CheckProjectSlugQuery,
    CheckProjectSlugQueryVariables
  >(CheckProjectSlugDocument, { variables, ...baseOptions });
}
export function useCheckProjectSlugLazyQuery(
  variables?: CheckProjectSlugQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CheckProjectSlugQuery,
    CheckProjectSlugQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    CheckProjectSlugQuery,
    CheckProjectSlugQueryVariables
  >(CheckProjectSlugDocument, { variables, ...baseOptions });
}
export const CreateOrganizationDocument = gql`
  mutation CreateOrganization(
    $name: name!
    $email: String!
    $organizationType: String!
    $phoneNumber: String!
    $notes: String
    $city: String
    $street: String
    $houseNumber: Int
    $zipCode: Int
    $companyCode: Int
    $vatNumber: Int
    $slug: String
  ) {
    organizations: insert_organizations(
      objects: {
        city: $city
        company_code: $companyCode
        email: $email
        house_number: $houseNumber
        name: $name
        notes: $notes
        phone_number: $phoneNumber
        street: $street
        type: $organizationType
        vat_number: $vatNumber
        zip_code: $zipCode
        slug: $slug
      }
    ) {
      inserted: returning {
        id
      }
    }
  }
`;
export function useCreateOrganizationMutation(
  variables?: CreateOrganizationMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(CreateOrganizationDocument, { variables, ...baseOptions });
}
export const CreateMediaAssetDocument = gql`
  mutation CreateMediaAsset(
    $mediaAssetId: uuid!
    $orgId: uuid!
    $folderId: uuid!
    $mimeType: String!
    $name: String!
    $size: bigint!
    $storageKey: String!
    $warehouseId: uuid!
    $mediaType: media_asset_types_enum!
    $metadata: [meta_field_values_insert_input!]!
  ) {
    insert_media_assets(
      objects: {
        id: $mediaAssetId
        name: $name
        folder_id: $folderId
        org_id: $orgId
        type: $mediaType
        meta_field_values: { data: $metadata }
        asset_files: {
          data: [
            {
              type: ORIGINAL
              org_id: $orgId
              mime_type: $mimeType
              name: $name
              size: $size
              storage_key: $storageKey
              warehouse_id: $warehouseId
            }
          ]
        }
      }
    ) {
      returning {
        id
      }
    }
  }
`;
export function useCreateMediaAssetMutation(
  variables?: CreateMediaAssetMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMediaAssetMutation,
    CreateMediaAssetMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateMediaAssetMutation,
    CreateMediaAssetMutationVariables
  >(CreateMediaAssetDocument, { variables, ...baseOptions });
}
export const LazyMetadataFieldsDocument = gql`
  query LazyMetadataFields($orgId: uuid!) {
    metadataFields: asset_metadata_fields(
      where: { org_id: { _eq: $orgId } }
      order_by: { created_at: desc }
    ) {
      ...MetaField
    }
  }
  ${MetaFieldFragmentDoc}
`;
export function useLazyMetadataFieldsQuery(
  variables: LazyMetadataFieldsQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LazyMetadataFieldsQuery,
    LazyMetadataFieldsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    LazyMetadataFieldsQuery,
    LazyMetadataFieldsQueryVariables
  >(LazyMetadataFieldsDocument, { variables, ...baseOptions });
}
export function useLazyMetadataFieldsLazyQuery(
  variables?: LazyMetadataFieldsQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LazyMetadataFieldsQuery,
    LazyMetadataFieldsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    LazyMetadataFieldsQuery,
    LazyMetadataFieldsQueryVariables
  >(LazyMetadataFieldsDocument, { variables, ...baseOptions });
}
export const OrgDataBySlugDocument = gql`
  subscription OrgDataBySlug($slug: String!) {
    organizations(where: { slug: { _eq: $slug } }) {
      id
      name
    }
  }
`;
export function useOrgDataBySlugSubscription(
  variables: OrgDataBySlugSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    OrgDataBySlugSubscription,
    OrgDataBySlugSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    OrgDataBySlugSubscription,
    OrgDataBySlugSubscriptionVariables
  >(OrgDataBySlugDocument, { variables, ...baseOptions });
}
export const OrgSlugDocument = gql`
  subscription OrgSlug($orgId: uuid!) {
    org: organizations_by_pk(id: $orgId) {
      id
      name
      slug
    }
  }
`;
export function useOrgSlugSubscription(
  variables: OrgSlugSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    OrgSlugSubscription,
    OrgSlugSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    OrgSlugSubscription,
    OrgSlugSubscriptionVariables
  >(OrgSlugDocument, { variables, ...baseOptions });
}
export const ProjectDataBySlugDocument = gql`
  subscription ProjectDataBySlug($slug: String!) {
    projects(where: { slug: { _eq: $slug } }) {
      id
      name
      imageUrl: image_url
      createdAt: created_at
      orgId: org_id
      slug
      updatedAt: updated_at
    }
  }
`;
export function useProjectDataBySlugSubscription(
  variables: ProjectDataBySlugSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    ProjectDataBySlugSubscription,
    ProjectDataBySlugSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    ProjectDataBySlugSubscription,
    ProjectDataBySlugSubscriptionVariables
  >(ProjectDataBySlugDocument, { variables, ...baseOptions });
}
export const VlConfigDocument = gql`
  query VlConfig($id: String = "default") {
    defaultConfig: vl_config_by_pk(id: $id) {
      id
      defaultStorageWarehouseId: default_storage_warehouse_id
      proto
    }
  }
`;
export function useVlConfigQuery(
  variables: VlConfigQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    VlConfigQuery,
    VlConfigQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<VlConfigQuery, VlConfigQueryVariables>(
    VlConfigDocument,
    { variables, ...baseOptions },
  );
}
export function useVlConfigLazyQuery(
  variables?: VlConfigQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VlConfigQuery,
    VlConfigQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<VlConfigQuery, VlConfigQueryVariables>(
    VlConfigDocument,
    { variables, ...baseOptions },
  );
}
export const DeleteLiveStreamDocument = gql`
  mutation DeleteLiveStream($streamKey: uuid!) {
    deleted: delete_media_live_streams_by_pk(stream_key: $streamKey) {
      streamKey: stream_key
    }
  }
`;
export function useDeleteLiveStreamMutation(
  variables?: DeleteLiveStreamMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteLiveStreamMutation,
    DeleteLiveStreamMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteLiveStreamMutation,
    DeleteLiveStreamMutationVariables
  >(DeleteLiveStreamDocument, { variables, ...baseOptions });
}
export const LiveStreamDocument = gql`
  subscription LiveStream($streamKey: uuid!) {
    liveStream: media_live_streams_by_pk(stream_key: $streamKey) {
      streamKey: stream_key
      title
      status
      transcode
      createdAt: created_at
      updatedAt: updated_at
      playbackKey: playback_key
      proto
    }
  }
`;
export function useLiveStreamSubscription(
  variables: LiveStreamSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    LiveStreamSubscription,
    LiveStreamSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    LiveStreamSubscription,
    LiveStreamSubscriptionVariables
  >(LiveStreamDocument, { variables, ...baseOptions });
}
export const LiveStreamsDocument = gql`
  subscription LiveStreams($orgId: uuid!) {
    liveStreams: media_live_streams(where: { org_id: { _eq: $orgId } }) {
      streamKey: stream_key
      title
      status
      transcode
      createdAt: created_at
      updatedAt: updated_at
      playbackKey: playback_key
      proto
    }
  }
`;
export function useLiveStreamsSubscription(
  variables: LiveStreamsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    LiveStreamsSubscription,
    LiveStreamsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    LiveStreamsSubscription,
    LiveStreamsSubscriptionVariables
  >(LiveStreamsDocument, { variables, ...baseOptions });
}
export const UpsertLiveStreamDocument = gql`
  mutation UpsertLiveStream($streams: [media_live_streams_insert_input!]!) {
    addedStreams: insert_media_live_streams(
      objects: $streams
      on_conflict: {
        constraint: live_streams_pkey
        update_columns: [title, transcode, proto]
      }
    ) {
      returning {
        title
      }
    }
  }
`;
export function useUpsertLiveStreamMutation(
  variables?: UpsertLiveStreamMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertLiveStreamMutation,
    UpsertLiveStreamMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpsertLiveStreamMutation,
    UpsertLiveStreamMutationVariables
  >(UpsertLiveStreamDocument, { variables, ...baseOptions });
}
export const BinFoldersDocument = gql`
  subscription BinFolders($orgId: uuid!) {
    folders(
      where: {
        _and: { org_id: { _eq: $orgId }, deleted_at: { _is_null: false } }
      }
    ) {
      id
      name
      createdAt: created_at
      updatedAt: updated_at
      parent: direct_parent {
        id
      }
      deletedAt: deleted_at
    }
  }
`;
export function useBinFoldersSubscription(
  variables: BinFoldersSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    BinFoldersSubscription,
    BinFoldersSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    BinFoldersSubscription,
    BinFoldersSubscriptionVariables
  >(BinFoldersDocument, { variables, ...baseOptions });
}
export const CreateArchiveJobDocument = gql`
  mutation CreateArchiveJob(
    $orgId: uuid!
    $outputWarehouseId: uuid!
    $outputName: String!
    $assetFiles: [archive_files_insert_input!]!
    $folderId: uuid!
    $proto: jsonb
  ) {
    result: insert_archive_jobs(
      objects: {
        org_id: $orgId
        output_name: $outputName
        output_warehouse_id: $outputWarehouseId
        folder_id: $folderId
        archive_files: { data: $assetFiles }
        proto: $proto
      }
    ) {
      archiveJobs: returning {
        id
      }
    }
  }
`;
export function useCreateArchiveJobMutation(
  variables?: CreateArchiveJobMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateArchiveJobMutation,
    CreateArchiveJobMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateArchiveJobMutation,
    CreateArchiveJobMutationVariables
  >(CreateArchiveJobDocument, { variables, ...baseOptions });
}
export const DeletedMediaDocument = gql`
  subscription DeletedMedia(
    $orgId: uuid!
    $mediaSearch: media_assets_bool_exp!
    $metadataFieldIds: [uuid!]!
    $sortBy: [media_assets_order_by!]
  ) {
    mediaAssets: media_assets(
      order_by: $sortBy
      where: {
        _and: [
          $mediaSearch
          { deleted_at: { _is_null: false } }
          { org_id: { _eq: $orgId } }
        ]
      }
    ) {
      ...MediaAsset
      metadataValues: meta_field_values(
        where: { field_id: { _in: $metadataFieldIds } }
      ) {
        ...MetaFieldValues
      }
    }
  }
  ${MediaAssetFragmentDoc}
  ${MetaFieldValuesFragmentDoc}
`;
export function useDeletedMediaSubscription(
  variables: DeletedMediaSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    DeletedMediaSubscription,
    DeletedMediaSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    DeletedMediaSubscription,
    DeletedMediaSubscriptionVariables
  >(DeletedMediaDocument, { variables, ...baseOptions });
}
export const DeleteAssetsDocument = gql`
  mutation DeleteAssets($assetIds: [uuid!]!) {
    deletedMedia: delete_media_assets(where: { id: { _in: $assetIds } }) {
      returning {
        id
      }
    }
    deletedFolders: delete_folders(where: { id: { _in: $assetIds } }) {
      returning {
        id
      }
    }
  }
`;
export function useDeleteAssetsMutation(
  variables?: DeleteAssetsMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteAssetsMutation,
    DeleteAssetsMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteAssetsMutation,
    DeleteAssetsMutationVariables
  >(DeleteAssetsDocument, { variables, ...baseOptions });
}
export const ExistingMediasDocument = gql`
  subscription ExistingMedias(
    $mediaSearch: media_assets_bool_exp!
    $metadataFieldIds: [uuid!]!
    $sortBy: [media_assets_order_by!]
  ) {
    mediaAssets: media_assets(
      order_by: $sortBy
      where: { _and: [$mediaSearch, { deleted_at: { _is_null: true } }] }
    ) {
      ...MediaAsset
      metadataValues: meta_field_values(
        where: { field_id: { _in: $metadataFieldIds } }
      ) {
        ...MetaFieldValues
      }
    }
  }
  ${MediaAssetFragmentDoc}
  ${MetaFieldValuesFragmentDoc}
`;
export function useExistingMediasSubscription(
  variables: ExistingMediasSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    ExistingMediasSubscription,
    ExistingMediasSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    ExistingMediasSubscription,
    ExistingMediasSubscriptionVariables
  >(ExistingMediasDocument, { variables, ...baseOptions });
}
export const FolderDetailsDocument = gql`
  subscription FolderDetails($folderId: uuid!) {
    folder: folders_by_pk(id: $folderId) {
      ...Folder
    }
  }
  ${FolderFragmentDoc}
`;
export function useFolderDetailsSubscription(
  variables: FolderDetailsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    FolderDetailsSubscription,
    FolderDetailsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    FolderDetailsSubscription,
    FolderDetailsSubscriptionVariables
  >(FolderDetailsDocument, { variables, ...baseOptions });
}
export const MediaAssetOriginalFilesDocument = gql`
  query MediaAssetOriginalFiles($mediaAssetIds: [uuid!]!) {
    mediaAssets: media_assets(where: { id: { _in: $mediaAssetIds } }) {
      assetFiles: asset_files(where: { type: { _eq: ORIGINAL } }) {
        id
      }
    }
  }
`;
export function useMediaAssetOriginalFilesQuery(
  variables: MediaAssetOriginalFilesQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MediaAssetOriginalFilesQuery,
    MediaAssetOriginalFilesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    MediaAssetOriginalFilesQuery,
    MediaAssetOriginalFilesQueryVariables
  >(MediaAssetOriginalFilesDocument, { variables, ...baseOptions });
}
export function useMediaAssetOriginalFilesLazyQuery(
  variables?: MediaAssetOriginalFilesQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MediaAssetOriginalFilesQuery,
    MediaAssetOriginalFilesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    MediaAssetOriginalFilesQuery,
    MediaAssetOriginalFilesQueryVariables
  >(MediaAssetOriginalFilesDocument, { variables, ...baseOptions });
}
export const MoveAssetsDocument = gql`
  mutation MoveAssets($assetIds: [uuid!]!, $toFolder: uuid!) {
    movedMedia: update_media_assets(
      where: { id: { _in: $assetIds } }
      _set: { folder_id: $toFolder }
    ) {
      returning {
        id
      }
    }
    movedFolders: update_folders(
      where: { id: { _in: $assetIds } }
      _set: { parent_id: $toFolder }
    ) {
      returning {
        id
      }
    }
  }
`;
export function useMoveAssetsMutation(
  variables?: MoveAssetsMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MoveAssetsMutation,
    MoveAssetsMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    MoveAssetsMutation,
    MoveAssetsMutationVariables
  >(MoveAssetsDocument, { variables, ...baseOptions });
}
export const OrgFolderTreeDocument = gql`
  subscription OrgFolderTree($orgId: uuid!) {
    folders(
      where: {
        _and: {
          org_id: { _eq: $orgId }
          _not: {
            parent_folders: {
              parent_folder: { deleted_at: { _is_null: false } }
            }
          }
        }
      }
      order_by: { name: asc }
    ) {
      id
      name
      parentId: parent_id
    }
  }
`;
export function useOrgFolderTreeSubscription(
  variables: OrgFolderTreeSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    OrgFolderTreeSubscription,
    OrgFolderTreeSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    OrgFolderTreeSubscription,
    OrgFolderTreeSubscriptionVariables
  >(OrgFolderTreeDocument, { variables, ...baseOptions });
}
export const OrgRootFolderDocument = gql`
  query OrgRootFolder($orgId: uuid!) {
    folders(
      where: {
        _and: { org_id: { _eq: $orgId }, parent_id: { _is_null: true } }
      }
    ) {
      id
      name
    }
  }
`;
export function useOrgRootFolderQuery(
  variables: OrgRootFolderQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrgRootFolderQuery,
    OrgRootFolderQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    OrgRootFolderQuery,
    OrgRootFolderQueryVariables
  >(OrgRootFolderDocument, { variables, ...baseOptions });
}
export function useOrgRootFolderLazyQuery(
  variables?: OrgRootFolderQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrgRootFolderQuery,
    OrgRootFolderQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    OrgRootFolderQuery,
    OrgRootFolderQueryVariables
  >(OrgRootFolderDocument, { variables, ...baseOptions });
}
export const SearchOrgAssetsDocument = gql`
  subscription SearchOrgAssets(
    $mediaSearch: media_assets_bool_exp!
    $orgId: uuid!
    $metadataFieldIds: [uuid!]!
  ) {
    mediaAssets: media_assets(
      where: {
        _and: [
          $mediaSearch
          { org_id: { _eq: $orgId } }
          { deleted_at: { _is_null: true } }
        ]
      }
    ) {
      ...MediaAsset
      metadataValues: meta_field_values(
        where: { field_id: { _in: $metadataFieldIds } }
      ) {
        ...MetaFieldValues
      }
    }
  }
  ${MediaAssetFragmentDoc}
  ${MetaFieldValuesFragmentDoc}
`;
export function useSearchOrgAssetsSubscription(
  variables: SearchOrgAssetsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    SearchOrgAssetsSubscription,
    SearchOrgAssetsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    SearchOrgAssetsSubscription,
    SearchOrgAssetsSubscriptionVariables
  >(SearchOrgAssetsDocument, { variables, ...baseOptions });
}
export const SetAssetsDeletedDocument = gql`
  mutation SetAssetsDeleted($assetIds: [uuid!]!, $deletedAt: timestamptz) {
    binnedAssets: update_media_assets(
      where: { id: { _in: $assetIds } }
      _set: { deleted_at: $deletedAt }
    ) {
      returning {
        id
      }
    }
    binnedFolders: update_folders(
      where: { id: { _in: $assetIds } }
      _set: { deleted_at: $deletedAt }
    ) {
      returning {
        id
      }
    }
  }
`;
export function useSetAssetsDeletedMutation(
  variables?: SetAssetsDeletedMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetAssetsDeletedMutation,
    SetAssetsDeletedMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SetAssetsDeletedMutation,
    SetAssetsDeletedMutationVariables
  >(SetAssetsDeletedDocument, { variables, ...baseOptions });
}
export const UpdateMediaAssetDocument = gql`
  mutation UpdateMediaAsset(
    $assetId: uuid!
    $name: String!
    $description: String
  ) {
    update_media_assets(
      where: { id: { _eq: $assetId } }
      _set: { name: $name, description: $description }
    ) {
      returning {
        id
      }
    }
  }
`;
export function useUpdateMediaAssetMutation(
  variables?: UpdateMediaAssetMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMediaAssetMutation,
    UpdateMediaAssetMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateMediaAssetMutation,
    UpdateMediaAssetMutationVariables
  >(UpdateMediaAssetDocument, { variables, ...baseOptions });
}
export const MemberRolesDocument = gql`
  subscription MemberRoles($memberId: uuid!) {
    member: org_members_by_pk(id: $memberId) {
      id
      user {
        id
        name
        email
      }
      roles: member_roles {
        id
        expires
        role {
          id
          name
          type
          membersAgg: member_roles_aggregate {
            total: aggregate {
              count
            }
          }
        }
        folderScopes: folder_scopes {
          id
          folderId: folder_id
        }
      }
      groupMemberships: user_group_members {
        group: user_group {
          roles: group_roles {
            id
            expires
            role {
              id
              name
              type
            }
            folderScopes: folder_scopes {
              id
              folderId: folder_id
            }
          }
        }
      }
    }
  }
`;
export function useMemberRolesSubscription(
  variables: MemberRolesSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    MemberRolesSubscription,
    MemberRolesSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    MemberRolesSubscription,
    MemberRolesSubscriptionVariables
  >(MemberRolesDocument, { variables, ...baseOptions });
}
export const MyArchiveJobsDocument = gql`
  subscription MyArchiveJobs($archiveIds: [uuid!]!) {
    archives: archive_jobs(where: { id: { _in: $archiveIds } }) {
      id
      outputName: output_name
      status
      error
    }
  }
`;
export function useMyArchiveJobsSubscription(
  variables: MyArchiveJobsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    MyArchiveJobsSubscription,
    MyArchiveJobsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    MyArchiveJobsSubscription,
    MyArchiveJobsSubscriptionVariables
  >(MyArchiveJobsDocument, { variables, ...baseOptions });
}
export const AddOrgMemberDocument = gql`
  mutation AddOrgMember($orgId: uuid!, $userId: uuid!) {
    insert_org_members(objects: { org_id: $orgId, user_id: $userId }) {
      returning {
        id
      }
    }
  }
`;
export function useAddOrgMemberMutation(
  variables?: AddOrgMemberMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddOrgMemberMutation,
    AddOrgMemberMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AddOrgMemberMutation,
    AddOrgMemberMutationVariables
  >(AddOrgMemberDocument, { variables, ...baseOptions });
}
export const CreateOrgMemberInviteDocument = gql`
  mutation CreateOrgMemberInvite($orgId: uuid!, $inviteeEmail: String!) {
    insert_org_member_invites(
      objects: { invitee_email: $inviteeEmail, org_id: $orgId }
    ) {
      returning {
        id
      }
    }
  }
`;
export function useCreateOrgMemberInviteMutation(
  variables?: CreateOrgMemberInviteMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrgMemberInviteMutation,
    CreateOrgMemberInviteMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateOrgMemberInviteMutation,
    CreateOrgMemberInviteMutationVariables
  >(CreateOrgMemberInviteDocument, { variables, ...baseOptions });
}
export const DeleteOrgMemberDocument = gql`
  mutation DeleteOrgMember($memberId: uuid!) {
    delete_org_members(where: { id: { _eq: $memberId } }) {
      deleted: returning {
        id
      }
    }
  }
`;
export function useDeleteOrgMemberMutation(
  variables?: DeleteOrgMemberMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteOrgMemberMutation,
    DeleteOrgMemberMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteOrgMemberMutation,
    DeleteOrgMemberMutationVariables
  >(DeleteOrgMemberDocument, { variables, ...baseOptions });
}
export const DeleteOrgMemberInviteDocument = gql`
  mutation DeleteOrgMemberInvite($inviteId: uuid!) {
    delete_org_member_invites(where: { id: { _eq: $inviteId } }) {
      returning {
        id
      }
    }
  }
`;
export function useDeleteOrgMemberInviteMutation(
  variables?: DeleteOrgMemberInviteMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteOrgMemberInviteMutation,
    DeleteOrgMemberInviteMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteOrgMemberInviteMutation,
    DeleteOrgMemberInviteMutationVariables
  >(DeleteOrgMemberInviteDocument, { variables, ...baseOptions });
}
export const OrganizationDetailsDocument = gql`
  subscription OrganizationDetails($orgSlug: String!) {
    organizations(where: { slug: { _eq: $orgSlug } }) {
      id
      name
      city
      companyCode: company_code
      createdAt: created_at
      email
      houseNumber: house_number
      notes
      phoneNumber: phone_number
      street
      type
      updatedAt: updated_at
      vatNumber: vat_number
      zipCode: zip_code
    }
  }
`;
export function useOrganizationDetailsSubscription(
  variables: OrganizationDetailsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    OrganizationDetailsSubscription,
    OrganizationDetailsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    OrganizationDetailsSubscription,
    OrganizationDetailsSubscriptionVariables
  >(OrganizationDetailsDocument, { variables, ...baseOptions });
}
export const OrganizationMembersDocument = gql`
  subscription OrganizationMembers($orgSlug: String!) {
    organizations(where: { slug: { _eq: $orgSlug } }) {
      id
      orgMembers: org_members {
        user {
          id
          email
          name
          photoUrl: photo_url
        }
        id
      }
      orgMemberInvites: org_member_invites(where: { is_used: { _eq: false } }) {
        id
        inviteeEmail: invitee_email
        isUsed: is_used
      }
    }
  }
`;
export function useOrganizationMembersSubscription(
  variables: OrganizationMembersSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    OrganizationMembersSubscription,
    OrganizationMembersSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    OrganizationMembersSubscription,
    OrganizationMembersSubscriptionVariables
  >(OrganizationMembersDocument, { variables, ...baseOptions });
}
export const OrganizationNamesDocument = gql`
  subscription OrganizationNames {
    organizations {
      id
      name
      avatar
      slug
    }
  }
`;
export function useOrganizationNamesSubscription(
  variables: OrganizationNamesSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    OrganizationNamesSubscription,
    OrganizationNamesSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    OrganizationNamesSubscription,
    OrganizationNamesSubscriptionVariables
  >(OrganizationNamesDocument, { variables, ...baseOptions });
}
export const OrgRolesDocument = gql`
  subscription OrgRoles($orgId: uuid!) {
    roles: permission_roles(where: { org_id: { _eq: $orgId } }) {
      id
      name
      type
    }
  }
`;
export function useOrgRolesSubscription(
  variables: OrgRolesSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    OrgRolesSubscription,
    OrgRolesSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    OrgRolesSubscription,
    OrgRolesSubscriptionVariables
  >(OrgRolesDocument, { variables, ...baseOptions });
}
export const OrgRoleMembersDocument = gql`
  subscription OrgRoleMembers($orgId: uuid!) {
    roles: permission_roles(where: { org_id: { _eq: $orgId } }) {
      id
      name
      type
      members: member_roles(distinct_on: member_id) {
        id
        memberId: member_id
        user {
          name
          email
        }
      }
      groups: user_group_roles(distinct_on: group_id) {
        id
        group: user_group {
          id
          name
        }
      }
    }
  }
`;
export function useOrgRoleMembersSubscription(
  variables: OrgRoleMembersSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    OrgRoleMembersSubscription,
    OrgRoleMembersSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    OrgRoleMembersSubscription,
    OrgRoleMembersSubscriptionVariables
  >(OrgRoleMembersDocument, { variables, ...baseOptions });
}
export const OrgTeamsDocument = gql`
  subscription OrgTeams(
    $orgId: uuid!
    $distinctOn: [permission_user_group_roles_select_column!]
  ) {
    teams: permission_user_groups(where: { org_id: { _eq: $orgId } }) {
      id
      name
      roles: group_roles(distinct_on: $distinctOn) {
        id
        expires
        role {
          id
          name
        }
        folderScopes: folder_scopes {
          id
          folderId: folder_id
        }
      }
      members: group_members {
        id: member_id
        user {
          id
          name
          email
        }
      }
    }
  }
`;
export function useOrgTeamsSubscription(
  variables: OrgTeamsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    OrgTeamsSubscription,
    OrgTeamsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    OrgTeamsSubscription,
    OrgTeamsSubscriptionVariables
  >(OrgTeamsDocument, { variables, ...baseOptions });
}
export const SearchMembersToAddDocument = gql`
  query SearchMembersToAdd(
    $partialNameOrEmail: String!
    $excludedOrgIds: [uuid!]
    $includedMemberOrgs: [uuid!]
  ) {
    users(
      where: {
        _and: [
          {
            _or: [
              { name: { _ilike: $partialNameOrEmail } }
              { email: { _ilike: $partialNameOrEmail } }
            ]
          }
          { _not: { org_members: { org_id: { _in: $excludedOrgIds } } } }
        ]
      }
      limit: 10
    ) {
      id
      email
      name
      orgMembers: org_members(where: { org_id: { _in: $includedMemberOrgs } }) {
        id
      }
    }
  }
`;
export function useSearchMembersToAddQuery(
  variables: SearchMembersToAddQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SearchMembersToAddQuery,
    SearchMembersToAddQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    SearchMembersToAddQuery,
    SearchMembersToAddQueryVariables
  >(SearchMembersToAddDocument, { variables, ...baseOptions });
}
export function useSearchMembersToAddLazyQuery(
  variables?: SearchMembersToAddQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SearchMembersToAddQuery,
    SearchMembersToAddQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    SearchMembersToAddQuery,
    SearchMembersToAddQueryVariables
  >(SearchMembersToAddDocument, { variables, ...baseOptions });
}
export const TeamDetailsDocument = gql`
  subscription TeamDetails($teamId: uuid!) {
    team: permission_user_groups_by_pk(id: $teamId) {
      id
      name
      roles: group_roles {
        id
        expires
        role {
          id
          name
        }
        folderScopes: folder_scopes {
          id
          folderId: folder_id
        }
      }
      members: group_members {
        id: member_id
        user {
          id
          name
          email
        }
      }
    }
  }
`;
export function useTeamDetailsSubscription(
  variables: TeamDetailsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    TeamDetailsSubscription,
    TeamDetailsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    TeamDetailsSubscription,
    TeamDetailsSubscriptionVariables
  >(TeamDetailsDocument, { variables, ...baseOptions });
}
export const UpsertMemberRolesDocument = gql`
  mutation UpsertMemberRoles(
    $memberId: uuid!
    $roles: [permission_org_member_roles_insert_input!]!
  ) {
    deleted: delete_permission_org_member_roles(
      where: { member_id: { _eq: $memberId } }
    ) {
      returning {
        id
      }
    }
    upserted: insert_permission_org_member_roles(
      objects: $roles
      on_conflict: {
        constraint: org_member_roles_pkey
        update_columns: [role_id, expires]
      }
    ) {
      roles: returning {
        id
      }
    }
  }
`;
export function useUpsertMemberRolesMutation(
  variables?: UpsertMemberRolesMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertMemberRolesMutation,
    UpsertMemberRolesMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpsertMemberRolesMutation,
    UpsertMemberRolesMutationVariables
  >(UpsertMemberRolesDocument, { variables, ...baseOptions });
}
export const UpsertRoleDocument = gql`
  mutation UpsertRole($role: permission_roles_insert_input!) {
    upserted: insert_permission_roles(
      objects: [$role]
      on_conflict: {
        constraint: roles_pkey
        update_columns: [
          name
          notes
          org_data
          org_members
          owned_media
          shared_media
        ]
      }
    ) {
      roles: returning {
        id
      }
    }
  }
`;
export function useUpsertRoleMutation(
  variables?: UpsertRoleMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertRoleMutation,
    UpsertRoleMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpsertRoleMutation,
    UpsertRoleMutationVariables
  >(UpsertRoleDocument, { variables, ...baseOptions });
}
export const UpsertTeamDocument = gql`
  mutation UpsertTeam(
    $teamId: uuid
    $orgId: uuid
    $name: String
    $roles: [permission_user_group_roles_insert_input!]!
    $members: [permission_user_group_members_insert_input!]!
  ) {
    deletedRoles: delete_permission_user_group_roles(
      where: { group_id: { _eq: $teamId } }
    ) {
      affected_rows
    }
    deletedMembers: delete_permission_user_group_members(
      where: { group_id: { _eq: $teamId } }
    ) {
      affected_rows
    }
    upserted: insert_permission_user_groups(
      objects: {
        id: $teamId
        org_id: $orgId
        name: $name
        group_roles: {
          data: $roles
          on_conflict: {
            constraint: user_group_roles_pkey
            update_columns: [role_id, expires]
          }
        }
        group_members: {
          data: $members
          on_conflict: {
            constraint: user_group_members_group_id_member_id_key
            update_columns: [user_id, member_id]
          }
        }
      }
      on_conflict: { constraint: user_groups_pkey, update_columns: [name] }
    ) {
      teams: returning {
        id
      }
    }
  }
`;
export function useUpsertTeamMutation(
  variables?: UpsertTeamMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertTeamMutation,
    UpsertTeamMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpsertTeamMutation,
    UpsertTeamMutationVariables
  >(UpsertTeamDocument, { variables, ...baseOptions });
}
export const UpsertTeamRoleDocument = gql`
  mutation UpsertTeamRole($role: permission_user_group_roles_insert_input!) {
    upserted: insert_permission_user_group_roles(
      objects: [$role]
      on_conflict: {
        constraint: user_group_roles_pkey
        update_columns: [role_id, expires]
      }
    ) {
      roles: returning {
        id
      }
    }
  }
`;
export function useUpsertTeamRoleMutation(
  variables?: UpsertTeamRoleMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertTeamRoleMutation,
    UpsertTeamRoleMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpsertTeamRoleMutation,
    UpsertTeamRoleMutationVariables
  >(UpsertTeamRoleDocument, { variables, ...baseOptions });
}
export const CanDeleteFolderDocument = gql`
  subscription CanDeleteFolder($folderId: uuid!, $userId: uuid!) {
    removableFolder: permission_folder_permissions(
      where: {
        _and: [
          { folder_id: { _eq: $folderId } }
          {
            _or: [
              { expires: { _is_null: true } }
              { expires: { _gt: "now()" } }
            ]
          }
          {
            _or: [
              { shared_media: { _contains: "DELETE" } }
              {
                _and: [
                  { owned_media: { _contains: "DELETE" } }
                  { folder: { created_by: { _eq: $userId } } }
                ]
              }
            ]
          }
        ]
      }
    ) {
      folderId: folder_id
    }
  }
`;
export function useCanDeleteFolderSubscription(
  variables: CanDeleteFolderSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    CanDeleteFolderSubscription,
    CanDeleteFolderSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    CanDeleteFolderSubscription,
    CanDeleteFolderSubscriptionVariables
  >(CanDeleteFolderDocument, { variables, ...baseOptions });
}
export const CanEditFolderDocument = gql`
  subscription CanEditFolder($folderId: uuid!, $userId: uuid!) {
    editableFolders: permission_folder_permissions(
      where: {
        _and: [
          { folder_id: { _eq: $folderId } }
          {
            _or: [
              { expires: { _is_null: true } }
              { expires: { _gt: "now()" } }
            ]
          }
          {
            _or: [
              { shared_media: { _contains: "EDIT" } }
              {
                _and: [
                  { owned_media: { _contains: "EDIT" } }
                  { folder: { created_by: { _eq: $userId } } }
                ]
              }
            ]
          }
        ]
      }
    ) {
      folderId: folder_id
    }
  }
`;
export function useCanEditFolderSubscription(
  variables: CanEditFolderSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    CanEditFolderSubscription,
    CanEditFolderSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    CanEditFolderSubscription,
    CanEditFolderSubscriptionVariables
  >(CanEditFolderDocument, { variables, ...baseOptions });
}
export const EditableFoldersDocument = gql`
  subscription EditableFolders($orgId: uuid!, $userId: uuid!) {
    editableFolders: permission_folder_permissions(
      where: {
        _and: [
          { folder: { org_id: { _eq: $orgId } } }
          {
            _or: [
              { expires: { _is_null: true } }
              { expires: { _gt: "now()" } }
            ]
          }
          {
            _or: [
              { shared_media: { _contains: "EDIT" } }
              {
                _and: [
                  { owned_media: { _contains: "EDIT" } }
                  { folder: { created_by: { _eq: $userId } } }
                ]
              }
            ]
          }
        ]
      }
    ) {
      folderId: folder_id
    }
  }
`;
export function useEditableFoldersSubscription(
  variables: EditableFoldersSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    EditableFoldersSubscription,
    EditableFoldersSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    EditableFoldersSubscription,
    EditableFoldersSubscriptionVariables
  >(EditableFoldersDocument, { variables, ...baseOptions });
}
export const EditableMetaDocument = gql`
  subscription EditableMeta($mediaId: uuid!) {
    editableMeta: permission_meta_permissions(
      distinct_on: field_id
      where: {
        _and: [
          { media_asset_id: { _eq: $mediaId } }
          {
            _or: [
              { expires: { _is_null: true } }
              { expires: { _gt: "now()" } }
            ]
          }
          { scopes: { _contains: "EDIT" } }
        ]
      }
    ) {
      fieldId: field_id
    }
  }
`;
export function useEditableMetaSubscription(
  variables: EditableMetaSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    EditableMetaSubscription,
    EditableMetaSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    EditableMetaSubscription,
    EditableMetaSubscriptionVariables
  >(EditableMetaDocument, { variables, ...baseOptions });
}
export const RemovableMediasDocument = gql`
  subscription RemovableMedias($mediaIds: [uuid!]!, $userId: uuid!) {
    removableMedias: permission_media_permissions(
      where: {
        _and: [
          { media_asset_id: { _in: $mediaIds } }
          {
            _or: [
              { expires: { _is_null: true } }
              { expires: { _gt: "now()" } }
            ]
          }
          {
            _or: [
              { shared_media: { _contains: "DELETE" } }
              {
                _and: [
                  { owned_media: { _contains: "DELETE" } }
                  { media: { created_by: { _eq: $userId } } }
                ]
              }
            ]
          }
        ]
      }
    ) {
      mediaAssetId: media_asset_id
    }
  }
`;
export function useRemovableMediasSubscription(
  variables: RemovableMediasSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    RemovableMediasSubscription,
    RemovableMediasSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    RemovableMediasSubscription,
    RemovableMediasSubscriptionVariables
  >(RemovableMediasDocument, { variables, ...baseOptions });
}
export const ViewableMetaDocument = gql`
  subscription ViewableMeta($folderId: uuid!) {
    viewableMeta: permission_meta_permissions(
      distinct_on: field_id
      where: {
        _and: [
          { folder_id: { _eq: $folderId } }
          {
            _or: [
              { expires: { _is_null: true } }
              { expires: { _gt: "now()" } }
            ]
          }
          { scopes: { _contains: "VIEW" } }
        ]
      }
    ) {
      fieldId: field_id
    }
  }
`;
export function useViewableMetaSubscription(
  variables: ViewableMetaSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    ViewableMetaSubscription,
    ViewableMetaSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    ViewableMetaSubscription,
    ViewableMetaSubscriptionVariables
  >(ViewableMetaDocument, { variables, ...baseOptions });
}
export const CreateCmsProjectDocument = gql`
  mutation CreateCmsProject(
    $projectId: uuid!
    $cmsRow: cms_content_rows_insert_input!
    $schemaSdl: String!
  ) {
    insertedRow: insert_cms_content_rows_one(object: $cmsRow) {
      id
    }
    insertedSchema: insert_cms_content_schemas_one(
      object: { project_id: $projectId, schema_sdl: $schemaSdl }
    ) {
      id
    }
  }
`;
export function useCreateCmsProjectMutation(
  variables?: CreateCmsProjectMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCmsProjectMutation,
    CreateCmsProjectMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateCmsProjectMutation,
    CreateCmsProjectMutationVariables
  >(CreateCmsProjectDocument, { variables, ...baseOptions });
}
export const CreateProjectDocument = gql`
  mutation CreateProject(
    $name: String!
    $orgId: uuid!
    $slug: String
    $extraData: jsonb
  ) {
    insert_projects(
      objects: {
        name: $name
        org_id: $orgId
        slug: $slug
        extra_data: $extraData
      }
    ) {
      returning {
        id
      }
    }
  }
`;
export function useCreateProjectMutation(
  variables?: CreateProjectMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CreateProjectDocument, { variables, ...baseOptions });
}
export const ProjectsGridDocument = gql`
  subscription ProjectsGrid {
    projects(order_by: { updated_at: desc }) {
      id
      name
      slug
      createdAt: created_at
      imageUrl: image_url
      organization {
        id
        name
      }
    }
  }
`;
export function useProjectsGridSubscription(
  variables: ProjectsGridSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    ProjectsGridSubscription,
    ProjectsGridSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    ProjectsGridSubscription,
    ProjectsGridSubscriptionVariables
  >(ProjectsGridDocument, { variables, ...baseOptions });
}
export const ProjectsTableDocument = gql`
  subscription ProjectsTable($orgId: uuid!) {
    projects(where: { org_id: { _eq: $orgId } }) {
      id
      name
      slug
      createdAt: created_at
      organization {
        id
        name
      }
    }
  }
`;
export function useProjectsTableSubscription(
  variables: ProjectsTableSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    ProjectsTableSubscription,
    ProjectsTableSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    ProjectsTableSubscription,
    ProjectsTableSubscriptionVariables
  >(ProjectsTableDocument, { variables, ...baseOptions });
}
export const RoleDetailsDocument = gql`
  subscription RoleDetails($roleId: uuid!) {
    role: permission_roles_by_pk(id: $roleId) {
      id
      name
      notes
      type
      orgDataScopes: org_data
      orgMembersScopes: org_members
      ownedMediaScopes: owned_media
      sharedMediaScopes: shared_media
      metaScopes: meta_scopes {
        fieldId: field_id
        scopes
      }
    }
  }
`;
export function useRoleDetailsSubscription(
  variables: RoleDetailsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    RoleDetailsSubscription,
    RoleDetailsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    RoleDetailsSubscription,
    RoleDetailsSubscriptionVariables
  >(RoleDetailsDocument, { variables, ...baseOptions });
}
export const CreateLinkShareDocument = gql`
  mutation CreateLinkShare(
    $title: String
    $validFrom: timestamptz
    $validUntil: timestamptz
    $downloadEnabled: Boolean
    $mediaId: uuid
  ) {
    inserted: insert_fe_link_share(
      objects: [
        {
          title: $title
          valid_from: $validFrom
          valid_until: $validUntil
          download_enabled: $downloadEnabled
          mam_media_id: $mediaId
        }
      ]
    ) {
      returning {
        id
      }
    }
  }
`;
export function useCreateLinkShareMutation(
  variables?: CreateLinkShareMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateLinkShareMutation,
    CreateLinkShareMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateLinkShareMutation,
    CreateLinkShareMutationVariables
  >(CreateLinkShareDocument, { variables, ...baseOptions });
}
export const DeleteLinkShareDocument = gql`
  mutation DeleteLinkShare($linkShareId: uuid!) {
    delete_fe_link_share(where: { id: { _eq: $linkShareId } }) {
      returning {
        id
      }
    }
  }
`;
export function useDeleteLinkShareMutation(
  variables?: DeleteLinkShareMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteLinkShareMutation,
    DeleteLinkShareMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteLinkShareMutation,
    DeleteLinkShareMutationVariables
  >(DeleteLinkShareDocument, { variables, ...baseOptions });
}
export const LinkSharesDocument = gql`
  subscription LinkShares($mediaId: uuid!) {
    linkShares: fe_link_share(where: { mam_media_id: { _eq: $mediaId } }) {
      id
      title
      validFrom: valid_from
      validUntil: valid_until
      downloadEnabled: download_enabled
    }
  }
`;
export function useLinkSharesSubscription(
  variables: LinkSharesSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    LinkSharesSubscription,
    LinkSharesSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    LinkSharesSubscription,
    LinkSharesSubscriptionVariables
  >(LinkSharesDocument, { variables, ...baseOptions });
}
export const CmsProjectsDocument = gql`
  subscription CmsProjects($orgId: uuid!, $session: String) {
    projects: cms_resources_stream(
      where: {
        project: { org_id: { _eq: $orgId } }
        data: { _contains: { meta: { type: "vl-project" } } }
        _or: [
          { proto: { _is_null: true } }
          { _not: { proto: { _contains: { session: $session } } } }
        ]
      }
      cursor: { initial_value: { updated_at: "2022-08-23" }, ordering: ASC }
      batch_size: 1000
    ) {
      id
      data
      project {
        proto
      }
      proto
    }
  }
`;
export function useCmsProjectsSubscription(
  variables: CmsProjectsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    CmsProjectsSubscription,
    CmsProjectsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    CmsProjectsSubscription,
    CmsProjectsSubscriptionVariables
  >(CmsProjectsDocument, { variables, ...baseOptions });
}
export const CmsProjectResourcesDocument = gql`
  query CmsProjectResources($projects: [uuid!]!, $session: String) {
    cms_resources(
      where: {
        _and: [
          { project_id: { _in: $projects } }
          {
            _or: [
              { proto: { _is_null: true } }
              { _not: { proto: { _contains: { session: $session } } } }
            ]
          }
        ]
      }
    ) {
      id
      data
      proto
    }
  }
`;
export function useCmsProjectResourcesQuery(
  variables: CmsProjectResourcesQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CmsProjectResourcesQuery,
    CmsProjectResourcesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    CmsProjectResourcesQuery,
    CmsProjectResourcesQueryVariables
  >(CmsProjectResourcesDocument, { variables, ...baseOptions });
}
export function useCmsProjectResourcesLazyQuery(
  variables?: CmsProjectResourcesQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CmsProjectResourcesQuery,
    CmsProjectResourcesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    CmsProjectResourcesQuery,
    CmsProjectResourcesQueryVariables
  >(CmsProjectResourcesDocument, { variables, ...baseOptions });
}
export const CmsResourcesDocument = gql`
  subscription CmsResources(
    $projects: [uuid!]!
    $after: timestamptz!
    $session: String
  ) {
    cms_resources_stream(
      where: {
        _and: [
          { project_id: { _in: $projects } }
          {
            _or: [
              { proto: { _is_null: true } }
              { _not: { proto: { _contains: { session: $session } } } }
            ]
          }
        ]
      }
      cursor: { initial_value: { updated_at: $after }, ordering: ASC }
      batch_size: 1000
    ) {
      id
      data
      proto
    }
  }
`;
export function useCmsResourcesSubscription(
  variables: CmsResourcesSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    CmsResourcesSubscription,
    CmsResourcesSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    CmsResourcesSubscription,
    CmsResourcesSubscriptionVariables
  >(CmsResourcesDocument, { variables, ...baseOptions });
}
export const CreateProjectPublicationDocument = gql`
  mutation CreateProjectPublication(
    $publications: [cms_project_publications_insert_input!]!
  ) {
    publication: insert_cms_project_publications(objects: $publications) {
      affected_rows
    }
  }
`;
export function useCreateProjectPublicationMutation(
  variables?: CreateProjectPublicationMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProjectPublicationMutation,
    CreateProjectPublicationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateProjectPublicationMutation,
    CreateProjectPublicationMutationVariables
  >(CreateProjectPublicationDocument, { variables, ...baseOptions });
}
export const ProjectsListDocument = gql`
  subscription ProjectsList($orgId: uuid!) {
    projects: cms_resources(
      where: {
        project: { org_id: { _eq: $orgId } }
        data: { _contains: { meta: { type: "vl-project" } } }
      }
      order_by: { created_at: desc }
    ) {
      id
      data
      project {
        proto
      }
      proto
    }
  }
`;
export function useProjectsListSubscription(
  variables: ProjectsListSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    ProjectsListSubscription,
    ProjectsListSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    ProjectsListSubscription,
    ProjectsListSubscriptionVariables
  >(ProjectsListDocument, { variables, ...baseOptions });
}
export const UpdateCmsResourcesDocument = gql`
  mutation UpdateCmsResources(
    $resourceId: uuid!
    $resource: cms_resources_set_input!
    $expectedHash: String
  ) {
    update_cms_resources(
      _set: $resource
      where: {
        id: { _eq: $resourceId }
        _or: [
          { _not: { proto: { _has_key: "hash" } } }
          { proto: { _contains: { hash: $expectedHash } } }
        ]
      }
    ) {
      affected_rows
      returning {
        id
        data
      }
    }
  }
`;
export function useUpdateCmsResourcesMutation(
  variables?: UpdateCmsResourcesMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCmsResourcesMutation,
    UpdateCmsResourcesMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateCmsResourcesMutation,
    UpdateCmsResourcesMutationVariables
  >(UpdateCmsResourcesDocument, { variables, ...baseOptions });
}
export const UpsertCmsResourcesDocument = gql`
  mutation UpsertCmsResources($resources: [cms_resources_insert_input!]!) {
    insert_cms_resources(
      objects: $resources
      on_conflict: { constraint: resources_pkey, update_columns: [data, proto] }
    ) {
      returning {
        id
        data
      }
    }
  }
`;
export function useUpsertCmsResourcesMutation(
  variables?: UpsertCmsResourcesMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertCmsResourcesMutation,
    UpsertCmsResourcesMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpsertCmsResourcesMutation,
    UpsertCmsResourcesMutationVariables
  >(UpsertCmsResourcesDocument, { variables, ...baseOptions });
}
export const OrganizationTableDocument = gql`
  subscription OrganizationTable {
    organizations(order_by: { created_at: desc }) {
      id
      name
      type
      email
      slug
      createdAt: created_at
      orgMembers: org_members_aggregate {
        total: aggregate {
          count(distinct: true)
        }
      }
    }
  }
`;
export function useOrganizationTableSubscription(
  variables: OrganizationTableSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    OrganizationTableSubscription,
    OrganizationTableSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    OrganizationTableSubscription,
    OrganizationTableSubscriptionVariables
  >(OrganizationTableDocument, { variables, ...baseOptions });
}
export const UsersTabelDocument = gql`
  subscription UsersTabel {
    users {
      id
      name
      email
      photoUrl: photo_url
      createdAt: created_at
    }
  }
`;
export function useUsersTabelSubscription(
  variables: UsersTabelSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    UsersTabelSubscription,
    UsersTabelSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    UsersTabelSubscription,
    UsersTabelSubscriptionVariables
  >(UsersTabelDocument, { variables, ...baseOptions });
}
export const ContentTicketsDocument = gql`
  subscription ContentTickets($contentId: uuid!) {
    tickets: ticketing_ticket_options(
      where: { content_id: { _eq: $contentId } }
    ) {
      ...TicketOption
    }
  }
  ${TicketOptionFragmentDoc}
`;
export function useContentTicketsSubscription(
  variables: ContentTicketsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    ContentTicketsSubscription,
    ContentTicketsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    ContentTicketsSubscription,
    ContentTicketsSubscriptionVariables
  >(ContentTicketsDocument, { variables, ...baseOptions });
}
export const UpsertTicketDocument = gql`
  mutation UpsertTicket($ticket: [ticketing_ticket_options_insert_input!]!) {
    upserted: insert_ticketing_ticket_options(
      objects: $ticket
      on_conflict: {
        constraint: ticket_options_pkey
        update_columns: [
          name
          price
          currency
          valid_until
          valid_for_unit
          valid_for_amount
          content_id
          ticket_type
        ]
      }
    ) {
      affected_rows
    }
  }
`;
export function useUpsertTicketMutation(
  variables?: UpsertTicketMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertTicketMutation,
    UpsertTicketMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpsertTicketMutation,
    UpsertTicketMutationVariables
  >(UpsertTicketDocument, { variables, ...baseOptions });
}
export const CmsDataDocument = gql`
  subscription CmsData($projectId: uuid, $ignoreRevision: String) {
    cmsContents: cms_content_rows(
      where: {
        _and: [
          { project_id: { _eq: $projectId } }
          { revision: { _neq: $ignoreRevision } }
        ]
      }
      order_by: { created_at: asc }
    ) {
      id
      type
      createdAt: created_at
      deletedAt: deleted_at
      isAccessRestricted: is_access_restricted
      localeData: locale_data
      status
    }
  }
`;
export function useCmsDataSubscription(
  variables: CmsDataSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    CmsDataSubscription,
    CmsDataSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    CmsDataSubscription,
    CmsDataSubscriptionVariables
  >(CmsDataDocument, { variables, ...baseOptions });
}
export const ContentSchemasDocument = gql`
  subscription ContentSchemas($projectId: uuid) {
    contentSchemas: cms_content_schemas(
      where: { project_id: { _eq: $projectId }, version: { _eq: 1 } }
    ) {
      schemaSdl: schema_sdl
    }
  }
`;
export function useContentSchemasSubscription(
  variables: ContentSchemasSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    ContentSchemasSubscription,
    ContentSchemasSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    ContentSchemasSubscription,
    ContentSchemasSubscriptionVariables
  >(ContentSchemasDocument, { variables, ...baseOptions });
}
export const DeleteCmsDataDocument = gql`
  mutation DeleteCmsData(
    $rowIds: [uuid!]!
    $deletedAt: timestamptz!
    $revision: String!
  ) {
    update_cms_content_rows(
      where: { id: { _in: $rowIds } }
      _set: { deleted_at: $deletedAt, revision: $revision }
    ) {
      affected_rows
    }
  }
`;
export function useDeleteCmsDataMutation(
  variables?: DeleteCmsDataMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCmsDataMutation,
    DeleteCmsDataMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteCmsDataMutation,
    DeleteCmsDataMutationVariables
  >(DeleteCmsDataDocument, { variables, ...baseOptions });
}
export const DeleteProjectDataDocument = gql`
  mutation DeleteProjectData($projectId: uuid!) {
    delete_projects_by_pk(id: $projectId) {
      id
      name
    }
  }
`;
export function useDeleteProjectDataMutation(
  variables?: DeleteProjectDataMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteProjectDataMutation,
    DeleteProjectDataMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteProjectDataMutation,
    DeleteProjectDataMutationVariables
  >(DeleteProjectDataDocument, { variables, ...baseOptions });
}
export const MyPermissionsDocument = gql`
  subscription MyPermissions($userId: uuid!, $orgId: uuid!) {
    roles: permission_roles(
      where: {
        _and: [
          { org_id: { _eq: $orgId } }
          {
            _or: [
              {
                member_roles: {
                  _and: [
                    { user_id: { _eq: $userId } }
                    {
                      _or: [
                        { expires: { _is_null: true } }
                        { expires: { _gt: "now()" } }
                      ]
                    }
                  ]
                }
              }
              {
                group_roles: {
                  _and: [
                    { user_id: { _eq: $userId } }
                    {
                      _or: [
                        { expires: { _is_null: true } }
                        { expires: { _gt: "now()" } }
                      ]
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ) {
      id
      name
      orgDataScopes: org_data
      orgMembersScopes: org_members
      ownedMediaScopes: owned_media
      sharedMediaScopes: shared_media
    }
  }
`;
export function useMyPermissionsSubscription(
  variables: MyPermissionsSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    MyPermissionsSubscription,
    MyPermissionsSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    MyPermissionsSubscription,
    MyPermissionsSubscriptionVariables
  >(MyPermissionsDocument, { variables, ...baseOptions });
}
export const OrgFoldersDocument = gql`
  subscription OrgFolders($orgId: uuid!) {
    folders(
      where: {
        _and: [
          { org_id: { _eq: $orgId } }
          {
            _not: {
              parent_folders: {
                parent_folder: { deleted_at: { _is_null: false } }
              }
            }
          }
        ]
      }
      order_by: { name: asc }
    ) {
      id
      name
      parentId: parent_id
    }
  }
`;
export function useOrgFoldersSubscription(
  variables: OrgFoldersSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    OrgFoldersSubscription,
    OrgFoldersSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    OrgFoldersSubscription,
    OrgFoldersSubscriptionVariables
  >(OrgFoldersDocument, { variables, ...baseOptions });
}
export const ProjectDataDocument = gql`
  subscription ProjectData($projectId: uuid!) {
    project: projects_by_pk(id: $projectId) {
      id
      locales
      slug
      org: organization {
        id
        slug
      }
      defaultLocale: default_locale
      extraData: extra_data
    }
  }
`;
export function useProjectDataSubscription(
  variables: ProjectDataSubscriptionVariables,
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    ProjectDataSubscription,
    ProjectDataSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    ProjectDataSubscription,
    ProjectDataSubscriptionVariables
  >(ProjectDataDocument, { variables, ...baseOptions });
}
export const UpsertCmsDataDocument = gql`
  mutation UpsertCmsData(
    $id: uuid!
    $type: String!
    $projectId: uuid!
    $revision: String
    $isAccessRestricted: Boolean
    $localeData: jsonb!
    $status: String!
  ) {
    cmsContent: insert_cms_content_rows(
      objects: {
        id: $id
        type: $type
        project_id: $projectId
        revision: $revision
        is_access_restricted: $isAccessRestricted
        locale_data: $localeData
        status: $status
      }
      on_conflict: {
        constraint: content_rows_pkey
        update_columns: [
          version
          revision
          is_access_restricted
          locale_data
          status
        ]
      }
    ) {
      affected_rows
    }
  }
`;
export function useUpsertCmsDataMutation(
  variables?: UpsertCmsDataMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertCmsDataMutation,
    UpsertCmsDataMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpsertCmsDataMutation,
    UpsertCmsDataMutationVariables
  >(UpsertCmsDataDocument, { variables, ...baseOptions });
}
