import VLButton from "components/common/buttons/VLButton";
import VLSelect from "components/common/select/VLSelect";
import strings from "localisation/strings";
import { TranslationPaths } from "localisation/TranslationPaths";
import React from "react";
import MediaBrowserPreferences, {
  SortOrderValue,
} from "state/MediaBrowserPreferences";
import styled from "styled-components";
import { OrderBy } from "__gen__/appService";

type SortOrderOption = {
  label: TranslationPaths;
  value: SortOrderValue;
};

const sortOrderOptions: SortOrderOption[] = [
  { value: "type", label: "common.type" },
  { value: "name", label: "common.name" },
  { value: "created_at", label: "common.createdAt" },
  { value: "updated_at", label: "common.updatedAt" },
];

const Button = styled(VLButton)`
  margin-right: ${({ theme }) => theme.margin.xlarge};
  margin-left: ${({ theme }) => theme.margin.medium};
`;
export default () => {
  const {
    sortBy,
    setSortBy,
    sortingOrder,
    setSortingOrder,
  } = MediaBrowserPreferences.useContainer();

  const onSortOrderSelect = (value: SortOrderValue) => {
    setSortBy(value);
  };

  const onSortOrderDirection = () => {
    if (sortingOrder === "asc") {
      setSortingOrder(OrderBy.desc);
    } else {
      setSortingOrder(OrderBy.asc);
    }
  };

  return (
    <>
      <VLSelect
        options={sortOrderOptions}
        placeholder={strings("common.sortBy")}
        selectedOption={sortOrderOptions.find(({ value }) => value === sortBy)}
        optionLabel={({ label }) => strings(label)}
        onOptionSelect={(option) => onSortOrderSelect(option.value)}
      />

      <Button
        onClick={onSortOrderDirection}
        icon={sortingOrder === "asc" ? "arrowDown" : "arrowUp"}
      />
    </>
  );
};
