import useDateFns from "components/hooks/useDateFns";
import { head } from "ramda";
import { useRouteMatch } from "react-router-dom";
import createTuple from "types/createTuple";
import { isDefined } from "types/predicates";
import {
  ArchiveFilesInsertInput,
  useDeleteAssetsMutation,
  useSetAssetsDeletedMutation,
} from "__gen__/appService";
import useMediaDownload from "./useMediaDownload";
import useOriginalAssetFiles from "./useOriginalAssetFiles";

interface AssetIds {
  assetIds: string[];
}

export default () => {
  const [onBin, binResult] = useSetAssetsDeletedMutation();
  const [onRestore, restoreResult] = useSetAssetsDeletedMutation();
  const [performDelete, deleteResults] = useDeleteAssetsMutation();
  const [createMediaDownload, mediaDownloadResults] = useMediaDownload();

  const match = useRouteMatch<{ folderId?: string }>();
  const { getNowTimestamp } = useDateFns();
  const { queryOriginalAssetFiles } = useOriginalAssetFiles();

  const folderId = match.params.folderId;

  const moveToBin = async ({ assetIds }: AssetIds) => {
    await onBin({ variables: { assetIds, deletedAt: getNowTimestamp() } });
  };

  const restore = async ({ assetIds }: AssetIds) => {
    await onRestore({ variables: { assetIds, deletedAt: null } });
  };

  const onDelete = async ({ assetIds }: AssetIds) => {
    await performDelete({ variables: { assetIds } });
  };

  const onCreateDownload = async ({ assetIds }: AssetIds) => {
    const {
      data: { mediaAssets },
    } = await queryOriginalAssetFiles({ mediaAssetIds: assetIds });
    const assetFiles = mediaAssets
      .map((media) => head(media.assetFiles))
      .filter(isDefined)
      .map<ArchiveFilesInsertInput>(({ id }) => ({
        asset_file_id: id,
      }));
    createMediaDownload({ folderId: folderId || "", assetFiles });
  };

  return {
    moveToBinAction: createTuple(moveToBin, binResult),
    restoreAction: createTuple(restore, restoreResult),
    deleteAction: createTuple(onDelete, deleteResults),
    createDownloadAction: createTuple(onCreateDownload, mediaDownloadResults),
  };
};
