import { ApolloLink, split } from "apollo-link";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import Env from "config/Env";
import { contains, path } from "ramda";
import { SubscriptionClient } from "subscriptions-transport-ws";
import getAuthLink, { AuthLinkParams } from "./getAuthLink";

export interface AppLinkParams extends AuthLinkParams {
  triggerRefresh: () => void;
}

export default ({ userRole, triggerRefresh, getAuthToken }: AppLinkParams) => {
  const httpLink = createHttpLink({
    uri: Env.REACT_APP_VL_HASURA_URL,
  });

  const wsClient = new SubscriptionClient(`${Env.REACT_APP_VL_HASURA_WS_URL}`, {
    lazy: true,
    reconnect: true,
    connectionParams: () => {
      const authToken = getAuthToken();
      if (!authToken) {
        return {};
      }
      return {
        headers: {
          authorization: authToken ? `Bearer ${authToken}` : "",
          "x-hasura-role": userRole,
        },
      };
    },
    connectionCallback: (error) => {
      if ((error as any) === "Malformed Authorization header") {
        triggerRefresh();
      }
      if (typeof error === "string" && contains("JWTExpired", error)) {
        triggerRefresh();
      }
    },
  });

  const wsLink = new WebSocketLink(wsClient);

  const transportLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink,
  );

  const errorLink = onError(({ graphQLErrors }) => {
    if (
      graphQLErrors &&
      graphQLErrors.find(
        (err) => path<string>(["extensions", "code"], err) === "invalid-jwt",
      )
    ) {
      triggerRefresh();
    }
  });

  return {
    link: ApolloLink.from([
      errorLink,
      getAuthLink({ userRole, getAuthToken }),
      transportLink,
    ]),
    wsClient,
  };
};
