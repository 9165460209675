import { Input, message } from "antd";
import { InputProps } from "antd/lib/input";
import VLButton from "components/common/buttons/VLButton";
import VLInput from "components/common/input/VLInput";
import { Row } from "components/common/layout/Flex";
import VLTooltip from "components/common/VLTooltip";
import strings from "localisation/strings";
import React, { createRef } from "react";
import styled from "styled-components";

const InputBox = styled(VLInput)`
  margin-right: ${({ theme }) => theme.margin.small};
`;

const CopyInput = (props: InputProps) => {
  const inputRef = createRef<Input>();

  const copyToClipboard = (e: any) => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      e.target.focus();
      message.success(strings("copyInput.urlCopied"));
    }
  };

  return (
    <Row>
      <InputBox ref={inputRef} {...props} />
      <VLTooltip t="common.copy">
        <VLButton icon="copy" onClick={copyToClipboard} />
      </VLTooltip>
    </Row>
  );
};

export default CopyInput;
