import { SpreadRow } from "components/common/layout/Flex";
import ScreenTitle from "components/common/text/ScreenTitle";
import AddProjectButton from "components/projects/AddProjectButton";
import ProjectsTable from "components/projects/ProjectsTable";
import strings from "localisation/strings";
import React from "react";
import AppScreen from "./AppScreen";

const ProjectsScreen = () => {
  return (
    <AppScreen>
      <SpreadRow grow={0}>
        <ScreenTitle as="h2">{strings("screens.projects.title")}</ScreenTitle>
        <AddProjectButton />
      </SpreadRow>
      <ProjectsTable />
    </AppScreen>
  );
};

export default ProjectsScreen;
