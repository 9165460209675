import { useCallback, useEffect, useState } from "react";

interface EditableFieldProps<T> {
  initialValue: T;
  initialEditingValue?: (initialValue: T) => T;
}

export default <T>({
  initialValue,
  initialEditingValue = (x) => x,
}: EditableFieldProps<T>) => {
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setValue(initialValue);
    setIsEditing(false);
  }, [initialValue]);

  const onCancel = useCallback(() => {
    setValue(initialValue);
    setIsEditing(false);
  }, [initialValue]);

  const startEditing = () => {
    setIsEditing(true);
    setValue(initialEditingValue(initialValue));
  };

  const stopEditing = () => {
    setIsEditing(false);
  };

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        onCancel();
      }
    },
    [onCancel],
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => document.removeEventListener("keydown", escFunction, false);
  }, [escFunction]);

  return {
    stopEditing,
    startEditing,
    value,
    setValue,
    isEditing,
    onCancel,
  };
};
