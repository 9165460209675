import VLButton from "components/common/buttons/VLButton";
import ResponsiveImage from "components/common/images/ResponsiveImage";
import ImagePreviewModal from "components/imagePreview/ImagePreviewModal";
import head from "functions/head";
import { assoc, dissoc, last, prop, sortBy } from "ramda";
import React, { useEffect, useState } from "react";
import GraphQLClientState from "state/GraphQLClientState";
import styled from "styled-components";
import { WithId } from "types/common";
import { isDefined } from "types/predicates";
import { S3SignedUrlsDocument, S3SignedUrlsQuery } from "__gen__/appGatewaySdk";

const PreviewImage = styled(ResponsiveImage)`
  cursor: zoom-in;
  margin: ${({ theme }) => theme.margin.medium} 0;
`;

interface Thumbnail {
  width: number;
  height: number;
  file: WithId;
}

interface ImageCache {
  [originalFileId: string]: Array<{ id: string; signedUrl: string }>;
}

interface ImagePreviewProps {
  originalFile: WithId & {
    name: string;
    thumbnails: Thumbnail[];
  };
}

export default ({
  originalFile: { id: originalFileId, name, thumbnails },
}: ImagePreviewProps) => {
  const [cache, setCache] = useState<ImageCache>({});
  const { apiClient } = GraphQLClientState.useContainer();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const signedUrls = cache[originalFileId] || [];

  const loadData = async () => {
    try {
      const result = await apiClient?.query<S3SignedUrlsQuery>({
        query: S3SignedUrlsDocument,
        variables: {
          assetFileIds: [originalFileId, ...thumbnails.map((t) => t.file.id)],
        },
      });
      if (result?.data.s3SignedUrls) {
        setCache(assoc(originalFileId, result.data.s3SignedUrls));
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  useEffect(() => {
    if (!cache[originalFileId]) {
      loadData();
    }
    // eslint-disable-next-line
  }, [originalFileId, JSON.stringify(cache)]);

  const signedThumbnails = sortBy(
    prop("width"),
    thumbnails
      .map((t) => {
        const { signedUrl } =
          signedUrls.find(({ id }) => t.file.id === id) || {};
        return signedUrl ? { ...t, signedUrl } : undefined;
      })
      .filter(isDefined),
  );

  const { signedUrl: originalFileSignedUrl } =
    signedUrls.find(({ id }) => originalFileId === id) || {};

  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  if (!originalFileSignedUrl) {
    return null;
  }

  const highestRes = last(signedThumbnails)?.signedUrl || originalFileSignedUrl;
  const lowestRes = head(signedThumbnails)?.signedUrl || originalFileSignedUrl;

  return (
    <div key={originalFileId}>
      <VLButton icon="zoom-in" onClick={showModal} />
      <ImagePreviewModal
        alt={name}
        src={highestRes}
        onClose={hideModal}
        closed={!isModalVisible}
      />
      <PreviewImage
        onError={() => setCache(dissoc(originalFileId))}
        alt={name}
        src={lowestRes}
        onClick={showModal}
      />
    </div>
  );
};
