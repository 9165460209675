import { useState } from "react";
import { createContainer } from "unstated-next";
import usePersistedState from "./usePersistedState";

export enum UserRole {
  "USER" = "vl-user",
  "ADMIN" = "vl-admin"
}

export interface User {
  id: string;
  name?: string | null;
  email?: string | null;
  photoUrl?: string | null;
  allowedRoles: UserRole[];
}

interface UserState {
  selectedRole: UserRole;
  selectedOrgId: string;
}

const useUserState = () => {
  const [userState, setUserState, reset] = usePersistedState<UserState>(
    "VLUserState",
    {
      selectedRole: UserRole.USER,
      selectedOrgId: ""
    }
  );
  const [user, setUser] = useState<User>();

  const updateUser = (newUser: User) => {
    setUser(oldUser => ({ ...oldUser, ...newUser }));
  };

  const selectRole = (newRole: UserRole) => {
    setUserState({ ...userState, selectedRole: newRole });
  };

  const selectOrgId = (newId: string) => {
    setUserState({ ...userState, selectedOrgId: newId });
  };

  return {
    user,
    updateUser,
    userState,
    selectRole,
    selectOrgId,
    selectedOrgId: userState.selectedOrgId,
    reset
  };
};

export default createContainer(useUserState);
