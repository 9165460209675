import { flatten, isEmpty } from "ramda";
import { StringMapped } from "types/common";
import { isDefined } from "types/predicates";
import { ValidationErrorTypes } from "__gen__/forms";
import { FormFieldDefinition, FormObjectType } from "../types";

export const validateFormField = (
  fieldValue: any,
  field: FormFieldDefinition,
  parentPath: string[] = [],
): FormFieldValidationError[] => {
  const errors: ValidationErrorTypes[] = [];
  const fieldPath = [...parentPath, field.name];
  const { directives } = field;
  const hasNonEmptyValue = isDefined(fieldValue) && !isEmpty(fieldValue);
  if (field.isRequired && !hasNonEmptyValue) {
    return [
      { fieldPath, errors: [ValidationErrorTypes.MISSING_REQUIRED_VALUE] },
    ];
  }
  const minLength = directives.length?.min;
  if (isDefined(minLength) && minLength > `${fieldValue || ""}`.length) {
    errors.push(ValidationErrorTypes.TOO_SHORT);
  }
  const maxLength = directives.length?.max;
  if (isDefined(maxLength) && maxLength < `${fieldValue || ""}`.length) {
    errors.push(ValidationErrorTypes.TOO_LONG);
  }
  const rangeMin = directives.range?.min;
  if (isDefined(rangeMin) && rangeMin > fieldValue) {
    errors.push(ValidationErrorTypes.TOO_SMALL);
  }
  const rangeMax = directives.range?.max;
  if (isDefined(rangeMax) && rangeMax < fieldValue) {
    errors.push(ValidationErrorTypes.TOO_BIG);
  }

  if (field.type.type === "object") {
    if (hasNonEmptyValue) {
      const childErrors = flatten(
        field.type.fields.map((childField) => {
          const newValue = fieldValue[childField.name];
          return validateFormField(newValue, childField, fieldPath);
        }),
      );
      return childErrors;
    }
    return [];
  }

  if (errors.length) {
    return [{ fieldPath, errors }];
  }
  return [];
};

export interface FormFieldValidationError {
  fieldPath: string[];
  errors: (ValidationErrorTypes | string)[];
}

const validateForm = (
  form: FormObjectType,
  values: StringMapped,
): FormFieldValidationError[] => {
  return flatten(
    form.fields.map<FormFieldValidationError[]>(
      (field) => validateFormField(values[field.name], field),
      {} as StringMapped,
    ),
  );
};

export default validateForm;
