import {
  AssetMetaFieldsState,
  stateToInsertValues,
} from "components/assetMetadata/stateMapper";
import { S3UploadedFile } from "state/functions/patchAwsPlugin";
import {
  MediaAssetTypesEnum,
  useCreateMediaAssetMutation,
} from "__gen__/appService";

export default () => {
  const [onCreateFile] = useCreateMediaAssetMutation();

  const createMedia = async (
    file: S3UploadedFile,
    metadataState: AssetMetaFieldsState,
  ) => {
    const {
      name,
      size,
      meta,
      s3Key: storageKey,
      warehouseId,
      type = "",
    } = file;
    const { mediaAssetId, orgId, folderId } = meta;
    return onCreateFile({
      variables: {
        mediaAssetId,
        orgId,
        folderId,
        name,
        size,
        storageKey,
        warehouseId,
        mimeType: type,
        mediaType: MediaAssetTypesEnum.UNKNOWN,
        metadata: stateToInsertValues(metadataState),
      },
    });
  };

  return { createMedia };
};
