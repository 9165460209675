import VLButton from "components/common/buttons/VLButton";
import { SpreadRow } from "components/common/layout/Flex";
import VLCard from "components/common/layout/VLCard";
import { SimpleText } from "components/common/text/SimpleText";
import React, { useState } from "react";
import { TicketOptionFragment } from "__gen__/appService";
import UpsertTicketForm from "./UpsertTicketForm";

interface TicketCardProps {
  ticket: TicketOptionFragment;
}

export default ({ ticket }: TicketCardProps) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <VLCard key={ticket.id} marginVertical="small">
      {isEditing ? (
        <UpsertTicketForm
          contentId={ticket.contentId}
          onClose={() => setIsEditing(false)}
          initialTicket={ticket}
        />
      ) : (
        <>
          <SpreadRow crossAxis="center">
            <SimpleText as="h1">{ticket.name}</SimpleText>
            <VLButton
              size="small"
              icon="edit"
              onClick={() => setIsEditing(true)}
            />
          </SpreadRow>
          <SimpleText color="tertiary">
            {ticket.price} {ticket.currency}
          </SimpleText>
        </>
      )}
    </VLCard>
  );
};
