import getDateFnsLocale from "components/cms/functions/getDateFnsLocale";
import { format, formatRelative } from "date-fns";
import { VLLocales } from "store/contentInterfaces";

const DEFAULT_FORMAT = "dd MMM yyyy";

export const timeStringToDate = (timeString: string) =>
  new Date([new Date().toISOString().split("T")[0], timeString].join("T"));

const useDateFns = () => {
  const formatTimeStamp = (timestamp?: string | null) =>
    timestamp ? format(new Date(timestamp), DEFAULT_FORMAT) : "";

  const getNowTimestamp = () => format(new Date(), "yyyy-MM-dd");

  const formatDate = (date: Date) => format(date, DEFAULT_FORMAT);

  const formatDateTime = (date: Date) =>
    format(date, "P p", { locale: getDateFnsLocale(VLLocales.ET) });

  const formatRelativeTime = (date: Date) => formatRelative(date, new Date());

  const formatTimeString = (timeString: string) => {
    const date = timeStringToDate(timeString);
    return format(date, date.getSeconds() !== 0 ? "HH:mm:ss" : "HH:mm");
  };

  return {
    formatTimeStamp,
    getNowTimestamp,
    formatDate,
    formatDateTime,
    formatRelativeTime,
    timeStringToDate,
    formatTimeString,
  };
};

export default useDateFns;
