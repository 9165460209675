import { Column } from "components/common/layout/Flex";
import React from "react";
import styled from "styled-components";

const Tab = styled.div<{ isSelected?: boolean }>`
  background-color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.color.background.tertiary
      : theme.color.background.primary};
  cursor: pointer;
  width: 200px;
  margin: ${({ theme }) => theme.margin.small};
  padding: ${({ theme }) => theme.margin.small};

  &:hover {
    background-color: ${({ theme }) => theme.color.background.secondary};
  }
`;

export interface Tab {
  key: string;
  name: string;
}

interface MainTabSelect {
  tabs: Tab[];
  selectedTabKey?: string;
  onSelect: (tabKey: string) => void;
}

export default ({ tabs, selectedTabKey, onSelect }: MainTabSelect) => {
  if (tabs.length === 0) {
    return null;
  }

  return (
    <Column grow={0}>
      {tabs.map(({ key, name }) => (
        <Tab
          onClick={() => onSelect(key)}
          isSelected={selectedTabKey === key}
          key={key}
        >
          {name}
        </Tab>
      ))}
    </Column>
  );
};
