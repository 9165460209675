import { Column } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import React from "react";
import CmsErrorState from "state/CmsErrorState";
import ContentStore from "store/ContentStore";
import styled from "styled-components";
import ContentLink from "../common/ContentLink";
import ResourceFormButton from "../inputs/ResourceFormButton";

const SidebarColumn = styled(Column)`
  border: 1px solid ${({ theme }) => theme.color.foreground.border};
  margin-top: 15px;
`;

export const ErrorSidebar = () => {
  const { getContentType } = ContentStore.useContainer();
  const { validationErrors } = CmsErrorState.useContainer();

  return (
    <SidebarColumn>
      <SimpleText margin="small">
        Please fix the Error(s) before publishing
      </SimpleText>
      {validationErrors.map((item: any) => {
        const rowType = getContentType(item.rowType);
        if (rowType === undefined) {
          return null;
        }
        return (
          <Column>
            <SidebarColumn key={item.rowId}>
              <SimpleText>
                Error in {item.rowType}
                {<ContentLink id={item.rowId} />}
              </SimpleText>
              <ResourceFormButton
                contentType={rowType}
                contentId={item.rowId}
                buttonProps={{ icon: "edit" }}
              />
            </SidebarColumn>
          </Column>
        );
      })}
    </SidebarColumn>
  );
};
