import { intersection, slice } from "ramda";
import { MetaFieldTagList } from "../MetadataFieldTypes";

export default (
  options: MetaFieldTagList["options"]
): MetaFieldTagList["options"] => {
  const { maxNumberOfTags } = options;
  const possibleValues = slice(
    0,
    maxNumberOfTags || Infinity,
    options.possibleValues || []
  );
  const defaultValues =
    possibleValues.length === 0
      ? options.default
      : intersection(options.default || [], possibleValues);
  return {
    ...options,
    possibleValues: possibleValues.length === 0 ? undefined : possibleValues,
    default: defaultValues
  };
};
