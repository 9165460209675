import { SimpleText } from "components/common/text/SimpleText";
import React from "react";
import styled from "styled-components";
import { FolderNodeProps } from "./types";

const TextTitle = styled(SimpleText)`
  color: ${({ theme, disabled }) =>
    disabled
      ? theme.color.foreground.tertiary
      : theme.color.foreground.primary};
`;

export default ({
  folder,
  disabled,
}: FolderNodeProps & { disabled?: boolean }) => {
  return <TextTitle disabled={disabled}>{folder.name}</TextTitle>;
};
