import { StringMapped } from "types/common";
import getObjectTypes from "vl-app-client/lib/cms/schema/getObjectTypes";
import { createVLComposer } from "../cmsClient";
import { VLContentType } from "../contentInterfaces";

export default (schemaSdl:string) => {
  const composer = createVLComposer(schemaSdl);
  const objectTypes = getObjectTypes(composer);
  const typeMap: StringMapped<VLContentType> = {};
  objectTypes.forEach((type) => {
    typeMap[type.getTypeName()] = type;
  });
  return typeMap
};
