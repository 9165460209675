import { DatePicker } from "antd";
import { moment } from "localisation/i18n";
import React from "react";
import InputWrapper from "./InputWrapper";
import { ResourceInputProps } from "./types";

export default (props: ResourceInputProps<string>) => {
  const {
    resolveValue,
    resolveFallback,
    dataPath,
    locale,
    isRequired,
    onValueSaved,
  } = props;
  const value = resolveValue({ locale, dataPath });
  const fallbackValue = resolveFallback({ locale, dataPath });

  return (
    <InputWrapper {...props}>
      <DatePicker
        allowClear={!isRequired}
        placeholder={fallbackValue}
        value={value ? moment(value) : undefined}
        format="DD MMM YYYY"
        onChange={(m) => {
          const newValue = m ? m.format("YYYY-MM-DD") : undefined;
          onValueSaved({ value: newValue, locale, dataPath });
        }}
      />
    </InputWrapper>
  );
};
