import { Row } from "components/common/layout/Flex";
import { MarginedDivider } from "components/common/layout/MarginedDivider";
import FolderTree from "components/folderBrowser/FolderTree";
import UpsertFolder from "components/folderBrowser/UpsertFolder";
import useCanEditFolder from "components/permissionControl/hooks/useCanEditFolder";
import React from "react";
import styled from "styled-components";
import BinLink from "../folderBrowser/BinLink";

const FolderTreeContainer = styled.div`
  overflow: auto;
`;

interface MediaAssetsProps {
  folderId: string;
  isBin: boolean;
  isSearch: boolean;
}

export default React.memo(({ folderId, isBin, isSearch }: MediaAssetsProps) => {
  const canCreate = useCanEditFolder({ folderId });

  return (
    <>
      <Row mainAxis="flex-end" grow={0}>
        <UpsertFolder parentFolderId={folderId} disabled={!canCreate} />
      </Row>
      <FolderTreeContainer>
        <FolderTree openedFolderId={isBin || isSearch ? "" : folderId} />
      </FolderTreeContainer>
      <div>
        <MarginedDivider margin="medium" />
      </div>
      <BinLink selected={isBin} />
    </>
  );
});
