import {
  parseComponentDefinition,
  VlComponentDefinition,
} from "./parseComponentDefinition";

const cache: Record<string, VlComponentDefinition> = {};

export const getComponentSchema = (schemaUrl: string) => {
  const cached = cache[schemaUrl];
  if (cached) {
    return Promise.resolve(cached);
  }
  return fetch(schemaUrl)
    .then((res) => res.text())
    .then((gqlSchema) => {
      const componentDefinition = parseComponentDefinition({ gqlSchema });
      cache[schemaUrl] = componentDefinition;
      return componentDefinition;
    });
};
