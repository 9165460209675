import { Checkbox } from "antd";
import React from "react";

interface EditableBooleanProps {
  initialValue?: boolean;
  onSave: (value?: boolean) => void;
  placeholder?: string;
}

export default ({
  initialValue,
  onSave,
  placeholder,
}: EditableBooleanProps) => {
  return (
    <Checkbox
      checked={initialValue}
      onChange={(ev) => {
        onSave(ev.target.checked);
      }}
    >
      {placeholder}
    </Checkbox>
  );
};
