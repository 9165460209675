import { DatePicker } from "antd";
import { isDateMeta } from "components/assetMetaSpecs/MetadataFieldTypes";
import { moment } from "localisation/i18n";
import { Moment } from "moment";
import React from "react";
import { MetaFieldInputProps } from "./MetaFieldInputTypes";

export default ({
  field,
  onValuesChanged,
  values: {
    dateValues: [selectedValue],
  },
}: MetaFieldInputProps) => {
  if (!isDateMeta(field)) {
    return null;
  }
  const onChange = (selectedMoment: Moment | null) => {
    onValuesChanged({
      fieldId: field.id || "",
      values: { dateValues: selectedMoment ? [selectedMoment.toDate()] : [] },
    });
  };
  return (
    <DatePicker
      value={selectedValue ? moment(selectedValue) : undefined}
      onChange={onChange}
      format="DD MMM YYYY"
    />
  );
};
