import { Form } from "antd";
import { Rule } from "antd/lib/form";
import { FormInstance } from "antd/lib/form";
import { ApolloError } from "apollo-client";
import VLInput, { VLInputProps } from "components/common/input/VLInput";
import strings from "localisation/strings";
import { append } from "ramda";
import React, { useEffect, useState } from "react";
import slugify from "slugify";
import { isDuplicateSlugError, isSlugFormatted } from "./utils";

const getValidationErrors = (inputValue?: string) => {
  let errors: Error[] | undefined;
  if (!inputValue) {
    errors = append(new Error(strings("slugInput.errors.empty")), errors || []);
  } else if (inputValue.length < 3) {
    errors = append(
      new Error(strings("slugInput.errors.tooShort")),
      errors || [],
    );
  }
  if (!isSlugFormatted(inputValue || "")) {
    errors = append(
      new Error(strings("slugInput.errors.specialCharacters")),
      errors || [],
    );
  }
  return errors;
};

const validator = (
  rule: any,
  inputValue: string | undefined,
  callback: (error?: string) => void,
) => {
  const errors = getValidationErrors(inputValue);
  if (errors) {
    callback(errors[0]?.message);
  } else {
    callback();
  }
};

const getDuplicateErrors = (slugCount: number) => {
  let errors: Error[] | undefined;
  const checkDuplicate = slugCount !== undefined;
  if (checkDuplicate && slugCount) {
    errors = append(
      new Error(strings("slugInput.errors.duplicate")),
      errors || [],
    );
  }
  return errors;
};

interface SlugInputItemProps extends VLInputProps {
  slugFrom?: string;
  formUtils: FormInstance;
  checkSlug: (slug: string) => void;
  sameSlugCount: number;
  error?: ApolloError;
  rules?: Rule[];
}

const SlugInputItem = ({
  slugFrom = "",
  form,
  checkSlug,
  sameSlugCount,
  formUtils,
  error,
  rules = [],
  ...inputProps
}: SlugInputItemProps) => {
  const inputValue: string | undefined = formUtils.getFieldValue("slug");
  const setInputValue = (value: string) =>
    formUtils.setFieldsValue({ slug: value });
  const [loading, setLoading] = useState(false);

  const setErrors = (errors?: Error[]) => {
    formUtils.setFields([
      {
        name: "slug",
        value: inputValue,
        errors: errors?.map((err) => err.message),
      },
    ]);
  };

  useEffect(() => {
    if (error && isDuplicateSlugError(error)) {
      formUtils.setFields([
        {
          name: "slug",
          value: formUtils.getFieldValue("slug"),
          errors: [strings("slugInput.errors.duplicate")],
        },
      ]);
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    setInputValue(slugify(slugFrom, { lower: true }));
    // eslint-disable-next-line
  }, [slugFrom]);

  useEffect(() => {
    if (!loading) {
      setErrors(getDuplicateErrors(sameSlugCount));
    }
    // eslint-disable-next-line
  }, [sameSlugCount, loading]);

  const validate = async () => {
    const errors = getValidationErrors(inputValue);
    if (!errors && inputValue) {
      setLoading(true);
      await checkSlug(inputValue);
      setLoading(false);
    }
  };

  return (
    <Form.Item
      name="slug"
      label={strings("slugInput.label")}
      rules={[{ type: "method", validator }, ...rules]}
    >
      <VLInput
        {...inputProps}
        onBlur={validate}
        placeholder={strings("slugInput.label")}
      />
    </Form.Item>
  );
};

export default SlugInputItem;
