import { Tag } from "antd";
import { SimpleText } from "components/common/text/SimpleText";
import React from "react";
import {
  isMultipleChoiceMeta,
  isSingleChoiceMeta,
  isTagListMeta,
  MetaField
} from "./MetadataFieldTypes";

export default ({ field }: { field: MetaField }) => {
  if (
    (isTagListMeta(field) ||
      isMultipleChoiceMeta(field) ||
      isSingleChoiceMeta(field)) &&
    !!field.options.possibleValues
  ) {
    return (
      <>
        {field.options.possibleValues.map(value => (
          <Tag color="volcano" key={value}>
            {value}
          </Tag>
        ))}
      </>
    );
  }

  return <SimpleText color="tertiary" t="metadataSpecs.noRestrictions" />;
};
