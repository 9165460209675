import { Spin } from "antd";
import React from "react";
import { Row, FlexProps } from "./Flex";

const Loading = (props: FlexProps) => (
  <Row mainAxis="center" crossAxis="center" {...props}>
    <Spin />
  </Row>
);

export default Loading;
