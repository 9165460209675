import { ApolloError } from "apollo-client";
import { head, path } from "ramda";
import { AuthErrorTypes } from "__gen__/authGatewaySdk";

export const extractErrorTypes = <ErrorTypes = AuthErrorTypes>(
  error?: ApolloError | null,
) => {
  const gqlErrors = error ? error.graphQLErrors : [];
  const types = gqlErrors
    .map(path(["extensions", "exception", "errorType"]))
    .filter((x) => !!x);
  return types as ErrorTypes[];
};

export const getFirstErrorType = <ErrorTypes = AuthErrorTypes>(
  error?: ApolloError | null,
) => {
  return head<ErrorTypes | undefined>(extractErrorTypes(error));
};
