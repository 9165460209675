import React, { ReactNode } from "react";
import UserState, { UserRole } from "state/UserState";

interface IsAdminProps {
  children: ReactNode;
}

const IsAdmin = ({ children }: IsAdminProps) => {
  const { userState } = UserState.useContainer();
  const isAdmin = userState.selectedRole === UserRole.ADMIN;

  return isAdmin ? <>{children}</> : null;
};

export default IsAdmin;
