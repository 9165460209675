import VLButton from "components/common/buttons/VLButton";
import React from "react";
import useCmsErrorSidebar from "state/hooks/useCmsErrorSidebar";
import ContentStore from "store/ContentStore";
import styled from "styled-components";
import { isDefined } from "types/predicates";
import { validateProject } from "../functions/validation/validateProject";
import useProjectLanguages from "../hooks/useProjectLanguages";

const PublishButton = styled(VLButton)`
  color: ${({ theme }) => theme.color.foreground.primary};
  margin-left: -100px;
  margin-top: auto;
`;

export default () => {
  const { getContentType, contentRows } = ContentStore.useContainer();
  const { defaultLanguage, selectedLanguage } = useProjectLanguages();
  const { showErrorSidebar } = useCmsErrorSidebar();

  const validation = () => {
    if (!defaultLanguage) {
      return undefined;
    }
    const validationResults = validateProject({
      contentRows,
      getContentType,
      defaultLanguage,
      selectedLanguage,
    });
    const allResult = validationResults
      .filter(isDefined)
      .map((item) => {
        const isValid = item.validationResults.reduce(
          (accumulator: boolean, currenItem) => {
            if (!accumulator || currenItem.isValid === false) {
              return false;
            }
            return accumulator;
          },
          true,
        );
        return {
          isValid,
          rowId: item.rowId,
          rowType: item.rowType,
        };
      })
      .filter((item) => !item.isValid);

    if (allResult.length > 0) {
      showErrorSidebar(allResult);
    }
  };
  return (
    <>
      <PublishButton icon="upload" onClick={validation} t="buttons.publish" />
    </>
  );
};
