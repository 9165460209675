import React, { useEffect, useRef } from "react";
import { transformVlComponent } from "./inputs/VlComponentInput";
import { VlComponentState } from "./types";

interface PagePreviewProps {
  page: VlComponentState;
}

export default function PagePreview({ page }: PagePreviewProps) {
  let iframeRef = useRef<HTMLIFrameElement>(null);

  const sendData = () => {
    transformVlComponent({ value: page }).then((transformedData) => {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        iframeRef.current.contentWindow.postMessage(
          { sender: "videolevels", page: transformedData },
          "*",
        );
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      sendData();
    }, 1000);
  }, [JSON.stringify(page)]);

  // useEffect(() => {
  //   const messageHandler = (event: MessageEvent) => {
  //     if (event.data.sender === "vl-preview") {
  //       setTimeout(() => {
  //         sendData();
  //       }, 1000);
  //     }
  //   };
  //   window.addEventListener("message", messageHandler);
  //   return () => window.removeEventListener("message", messageHandler);
  // }, []);

  return (
    <iframe
      width="100%"
      height="100%"
      ref={iframeRef}
      src="https://vl-dev-public.s3.eu-north-1.amazonaws.com/visitor-site-components/svelte-components/build/preview.html"
    />
  );
}
