import { ObjectTypeComposer } from "graphql-compose";
import { append, without } from "ramda";
import { StringMapped } from "types/common";
import { ValidationResult } from "./types";
import validateField from "./validateField";

interface validationProps {
  value: StringMapped;
  typeComposer: ObjectTypeComposer;
  fieldPath?: Array<string | number>;
}

export default ({
  value: objectValue,
  typeComposer,
  fieldPath: objectPath = [],
}: validationProps): ValidationResult[] => {
  return typeComposer.getFieldNames().reduce((validationResults, fieldName) => {
    const fieldPath = append(fieldName, objectPath);
    const value = objectValue[fieldName];
    const fieldType = typeComposer.getFieldTC(fieldName);
    const fieldDirectives = typeComposer.getFieldDirectiveNames(fieldName);
    const directivesToSkip = ["tab", "preview", "ref", "table"];
    const shouldSkipField =
      without(fieldDirectives, directivesToSkip).length !==
      directivesToSkip.length;
    if (shouldSkipField) {
      return validationResults;
    }
    const fieldResult = validateField({
      value,
      fieldType,
      fieldPath,
      isList: typeComposer.isFieldPlural(fieldName),
      isRequired: typeComposer.isFieldNonNull(fieldName),
    });
    return [...validationResults, ...fieldResult];
  }, [] as ValidationResult[]);
};
