import VLButton from "components/common/buttons/VLButton";
import VLInput from "components/common/input/VLInput";
import VLTextArea from "components/common/input/VLTextArea";
import { Column, Row } from "components/common/layout/Flex";
import Loading from "components/common/layout/Loading";
import { SimpleText } from "components/common/text/SimpleText";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import { assoc, reduce, toPairs } from "ramda";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { PermissionScopesEnum as Scopes } from "vl-app-client/lib/__gen__/appServiceSdk";
import {
  PermissionRoleMetaScopesConstraint,
  PermissionRoleMetaScopesInsertInput,
  PermissionRoleMetaScopesUpdateColumn,
  useMetadataFieldsSubscription,
  useRoleDetailsSubscription,
  useUpsertRoleMutation,
} from "__gen__/appService";
import isOrgOwnersRole from "./functions/isOrgOwnersRole";
import PermissionScopes from "./PermissionScopes";

interface RoleDetailsProps {
  orgId: string;
}

const viewEdit = [Scopes.VIEW, Scopes.EDIT];

interface MetaState {
  [fieldId: string]: Scopes[];
}

const fromMetaState = (state: MetaState) => {
  return toPairs(state).map<PermissionRoleMetaScopesInsertInput>(
    ([fieldId, scopes]) => ({
      field_id: fieldId,
      scopes,
    }),
  );
};

export default ({ orgId }: RoleDetailsProps) => {
  const match = useRouteMatch<typeof Paths.orgRoleDetails.params>();
  const history = useHistory();
  const { data, loading: metaLoading } = useMetadataFieldsSubscription({
    orgId,
  });
  const [roleName, setRoleName] = useState("");
  const [notes, setNotes] = useState("");
  const orgDataState = useState<Scopes[]>([]);
  const orgMembersState = useState<Scopes[]>([]);
  const ownedMediaState = useState<Scopes[]>([]);
  const sharedMediaState = useState<Scopes[]>([]);
  const [metaScopes, setMetaScopes] = useState<MetaState>({});
  const { data: initialData, loading, error } = useRoleDetailsSubscription({
    roleId: match.params.roleId,
  });
  const [upsertRole, { loading: isSaving }] = useUpsertRoleMutation();

  const role = initialData?.role;

  const isOwnerRole = isOrgOwnersRole(role);

  useEffect(() => {
    if (role) {
      setRoleName(role.name);
      setNotes(role.notes || "");
      orgDataState[1](role.orgDataScopes);
      orgMembersState[1](role.orgMembersScopes);
      ownedMediaState[1](role.ownedMediaScopes);
      sharedMediaState[1](role.sharedMediaScopes);
      const initialMetaScopes = reduce(
        (acc, { fieldId, scopes }) => {
          return { ...acc, [fieldId]: scopes };
        },
        {} as MetaState,
        role.metaScopes,
      );
      setMetaScopes(initialMetaScopes);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(role)]);

  const onDone = () => {
    history.goBack();
  };

  const onSave = async () => {
    try {
      await upsertRole({
        variables: {
          role: {
            id: role?.id,
            name: roleName,
            notes,
            org_id: orgId,
            org_data: orgDataState[0],
            org_members: orgMembersState[0],
            owned_media: ownedMediaState[0],
            shared_media: sharedMediaState[0],
            meta_scopes: {
              on_conflict: {
                constraint:
                  PermissionRoleMetaScopesConstraint.role_meta_scopes_pkey,
                update_columns: [PermissionRoleMetaScopesUpdateColumn.scopes],
              },
              data: fromMetaState(metaScopes),
            },
          },
        },
      });
      onDone();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  if (loading && !error && match.params.roleId !== "new") {
    return <Loading />;
  }

  return (
    <Column>
      <SimpleText fontSize="large" t="common.role" />
      <VLInput
        placeholder={strings("common.name")}
        value={roleName}
        onTextChange={setRoleName}
      />
      <Row grow={0} marginVertical="medium">
        <VLTextArea
          placeholder={strings("common.notes")}
          value={notes}
          onTextChange={setNotes}
        />
      </Row>
      <SimpleText bold t="common.organization" />
      <PermissionScopes
        nameT="roleDetails.generalData"
        permissionState={orgDataState}
        possiblePermissions={viewEdit}
        disabled={isOwnerRole}
      />
      <PermissionScopes
        nameT="common.members"
        permissionState={orgMembersState}
        possiblePermissions={viewEdit}
        disabled={isOwnerRole}
      />
      <SimpleText bold t="common.media" />
      <PermissionScopes
        nameT="roleDetails.createdByUser"
        permissionState={ownedMediaState}
        possiblePermissions={[Scopes.EDIT, Scopes.DELETE]}
        disabled={isOwnerRole}
      />
      <PermissionScopes
        nameT="roleDetails.createdByOthers"
        permissionState={sharedMediaState}
        possiblePermissions={[Scopes.VIEW, Scopes.EDIT, Scopes.DELETE]}
        disabled={isOwnerRole}
      />
      <SimpleText bold t="roleDetails.mediaMetadata" />
      {metaLoading && <Loading />}
      {(data?.metadataFields || []).map(({ id, name }) => {
        const currentState = metaScopes[id] || [];
        const setState: React.Dispatch<React.SetStateAction<Scopes[]>> = (
          value,
        ) => {
          if (typeof value === "function") {
            setMetaScopes(assoc(id, value(currentState)));
          } else {
            setMetaScopes(assoc(id, value));
          }
        };
        return (
          <PermissionScopes
            key={id}
            name={name}
            possiblePermissions={viewEdit}
            permissionState={[currentState, setState]}
            disabled={isOwnerRole}
          />
        );
      })}
      <Row grow={0} mainAxis="flex-end" margin="medium">
        <VLButton
          t="buttons.cancel"
          onClick={onDone}
          marginHorizontal="medium"
        />
        <VLButton
          type="primary"
          t="buttons.save"
          onClick={onSave}
          loading={isSaving}
        />
      </Row>
    </Column>
  );
};
