import { setContext } from "apollo-link-context";
import { UserRole } from "state/UserState";

export interface AuthLinkParams {
  userRole: UserRole;
  getAuthToken: () => string | undefined;
}

export default ({ getAuthToken, userRole }: AuthLinkParams) => {
  return setContext((_, { headers }) => {
    const authToken = getAuthToken();
    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : "",
        "x-hasura-role": userRole
      }
    };
  });
};
