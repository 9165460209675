import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import Env from "config/Env";
import getAuthLink, { AuthLinkParams } from "./getAuthLink";

const getGatewayLink = (params: AuthLinkParams) => {
  return ApolloLink.from([
    getAuthLink(params),
    createHttpLink({
      uri: Env.REACT_APP_GATEWAY_URL
    })
  ]);
};

export default class AppGatewayClient extends ApolloClient<any> {
  constructor(params: AuthLinkParams) {
    const link = getGatewayLink(params);
    super({
      link,
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: "no-cache"
        }
      }
    });
  }
}
