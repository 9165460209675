import { DirectiveNode, ValueNode } from "graphql";
import { fromPairs } from "ramda";
import { StringMapped } from "types/common";

const resolveValueNode = (value: ValueNode): any => {
  if (
    value.kind === "BooleanValue" ||
    value.kind === "StringValue" ||
    value.kind === "EnumValue"
  ) {
    return value.value;
  }
  if (value.kind === "IntValue" || value.kind === "FloatValue") {
    return parseFloat(value.value);
  }
  if (value.kind === "ObjectValue") {
    return value.fields.reduce(
      (acc, { name, value: fieldValue }) => ({
        ...acc,
        [name.value]: resolveValueNode(fieldValue),
      }),
      {},
    );
  }
  if (value.kind === "ListValue") {
    return value.values.map(resolveValueNode);
  }
};

export default (directives: readonly DirectiveNode[] = []) => {
  return fromPairs(
    directives.map<[string, StringMapped]>((directive) => {
      const directiveName = directive.name.value;
      const args = (directive.arguments || []).reduce(
        (acc, { name, value }) => ({
          ...acc,
          [name.value]: resolveValueNode(value),
        }),
        {} as StringMapped,
      );
      return [directiveName, args];
    }),
  );
};
