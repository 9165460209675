import { pluck } from "ramda";
import GraphQLClientState from "state/GraphQLClientState";
import {
  ArchiveDownloadUrlsDocument,
  ArchiveDownloadUrlsQuery,
  ArchiveDownloadUrlsQueryVariables,
} from "__gen__/appGatewaySdk";

const downloadArchive = (downloadUrl: string) => {
  const link = document.createElement("a");
  link.setAttribute("display", "none");
  link.href = downloadUrl;
  link.setAttribute("download", "download");
  document.body.appendChild(link);
  link.click();
  if (link.parentNode) {
    link.parentNode.removeChild(link);
  }
};

export default () => {
  const { apiClient } = GraphQLClientState.useContainer();

  const downloadArchives = async ({ archiveIds }: { archiveIds: string[] }) => {
    if (apiClient) {
      const {
        data: { archiveDownloadUrls },
      } = await apiClient.query<
        ArchiveDownloadUrlsQuery,
        ArchiveDownloadUrlsQueryVariables
      >({
        query: ArchiveDownloadUrlsDocument,
        variables: { archiveJobIds: archiveIds },
      });
      pluck("signedUrl", archiveDownloadUrls).map(downloadArchive);
    }
  };

  return { downloadArchives };
};
