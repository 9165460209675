import { Row } from "components/common/layout/Flex";
import VLTag from "components/common/text/VLTag";
import VLTooltip from "components/common/VLTooltip";
import { TranslationPaths } from "localisation/TranslationPaths";
import React from "react";
import themeDef from "style/theme";
import styled from "styled-components";
import { JobStatusTypesEnum as JobStatus } from "__gen__/appService";

const color = themeDef.color.statusTypes;

const ProfileTag = styled(VLTag)`
  padding: ${({ theme }) => `${theme.margin.small} ${theme.margin.medium}`};
`;

const statusTranslations: { [key in JobStatus]: TranslationPaths } = {
  [JobStatus.FAILED]: "jobStatusTypes.FAILED",
  [JobStatus.FINISHED]: "jobStatusTypes.FINISHED",
  [JobStatus.NOT_APPLICABLE]: "jobStatusTypes.NOT_APPLICABLE",
  [JobStatus.PROCESSING]: "jobStatusTypes.PROCESSING",
  [JobStatus.WAITING_FOR_PROCESSING]: "jobStatusTypes.WAITING_FOR_PROCESSING",
};

interface Profile {
  id: string;
  name: string;
  status: JobStatus;
}

interface ProfileStatusesProps {
  profiles: Profile[];
}

export default ({ profiles }: ProfileStatusesProps) => {
  return (
    <Row marginVertical="medium">
      {profiles.map(({ id, status, name }) => (
        <VLTooltip key={id} t={statusTranslations[status]}>
          <ProfileTag label={name} color={color[status]} />
        </VLTooltip>
      ))}
    </Row>
  );
};
