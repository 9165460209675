import GraphQLClientState from "state/GraphQLClientState";
import {
  S3PublicUploadDocument,
  S3PublicUploadQuery,
  S3PublicUploadQueryVariables,
} from "__gen__/appGatewaySdk";

export default () => {
  const { apiClient } = GraphQLClientState.useContainer();

  const getUpload = (variables: S3PublicUploadQueryVariables) =>
    apiClient?.query<S3PublicUploadQuery, S3PublicUploadQueryVariables>({
      query: S3PublicUploadDocument,
      variables,
    });

  return { getUpload };
};
