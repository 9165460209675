import { SimpleText } from "components/common/text/SimpleText";
import VLIcon from "components/common/VLIcon";
import React from "react";
import styled from "styled-components";

const PreviewText = styled(SimpleText)`
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

interface TextPreviewProps {
  text: string;
  startEditing: () => void;
  placeholder?: string;
  disabled?: boolean;
}

export default ({
  text,
  startEditing,
  placeholder,
  disabled,
}: TextPreviewProps) => {
  return (
    <SimpleText color={disabled ? "tertiary" : "primary"}>
      <PreviewText color={!text ? "tertiary" : "primary"}>
        {text || placeholder}
      </PreviewText>{" "}
      <VLIcon
        color={disabled ? "tertiary" : "primary"}
        type="edit"
        onClick={!disabled ? startEditing : undefined}
      />
    </SimpleText>
  );
};
