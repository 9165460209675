import MetaFieldInput from "components/assetMetadata/MetaFieldInput";
import { emptyValues } from "components/assetMetadata/stateMapper";
import { MetaField } from "components/assetMetaSpecs/MetadataFieldTypes";
import { Row } from "components/common/layout/Flex";
import VLIcon from "components/common/VLIcon";
import React from "react";
import styled from "styled-components";
import FieldOperatorSelect from "./FieldOperatorSelect";
import FieldSelect from "./FieldSelect";
import { PartialCondition } from "./types";

const Container = styled(Row)`
  min-width: 300px;
`;

const CloseIcon = styled(VLIcon)`
  margin-left: ${({ theme }) => theme.margin.medium};
  justify-self: flex-end;
`;

interface FieldConditionRowProps {
  fields: MetaField[];
  condition: PartialCondition;
  onUpdate: (condition: PartialCondition) => void;
  onRemove?: () => void;
}

export default ({
  condition,
  fields,
  onUpdate,
  onRemove,
}: FieldConditionRowProps) => {
  return (
    <Container crossAxis="center" marginVertical="medium">
      <FieldSelect
        fields={fields}
        selectedField={condition.field}
        onFieldSelect={onUpdate}
      />
      {!!condition.field && (
        <>
          <FieldOperatorSelect
            field={condition.field}
            selectedOperator={condition.operator}
            onOperatorSelect={onUpdate}
          />
          <MetaFieldInput
            field={condition.field}
            values={{ ...emptyValues, ...condition.values }}
            onValuesChanged={({ values }) => onUpdate({ values })}
          />
        </>
      )}
      {!!onRemove && (
        <>
          <Row />
          <CloseIcon type="close" onClick={onRemove} color="tertiary" />
        </>
      )}
    </Container>
  );
};
