import Loading from "components/common/layout/Loading";
import NotAuthorized from "components/errors/NotAuthorized";
import React, { ReactNode } from "react";
import PermissionStore from "store/PermissionStore";

export default ({
  children,
  showNA
}: {
  children: ReactNode;
  showNA?: boolean;
}) => {
  const { canEditOrgData, loading } = PermissionStore.useContainer();

  if (loading) {
    return <Loading />;
  }

  if (canEditOrgData) {
    return <>{children}</>;
  }

  if (showNA) {
    return <NotAuthorized />;
  }

  return null;
};
