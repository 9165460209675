import { Row } from "components/common/layout/Flex";
import Margin from "components/common/layout/Margin";
import { append } from "ramda";
import React from "react";
import FieldDescription from "../inputs/FieldDescription";
import DatePreview from "./DatePreview";
import TimePreview from "./TimePreview";
import { ResourcePreviewProps } from "./types";

export default (props: ResourcePreviewProps) => {
  const { resolveValue, resolveFallback, dataPath, locale } = props;
  const dateDataPath = append("date", dataPath);
  const timeDataPath = append("time", dataPath);

  return (
    <Row marginVertical="medium">
      <FieldDescription {...props} isPreview />
      <DatePreview
        value={resolveValue({ locale, dataPath: dateDataPath })}
        fallbackValue={resolveFallback({ locale, dataPath: dateDataPath })}
      />
      <Margin />
      <TimePreview
        value={resolveValue({ locale, dataPath: timeDataPath })}
        fallbackValue={resolveFallback({ locale, dataPath: timeDataPath })}
      />
    </Row>
  );
};
