import VLButton from "components/common/buttons/VLButton";
import { Column, Row } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import { append, assocPath, head, move } from "ramda";
import React from "react";
import TypeInput, { transformAnyType, validateAnyType } from "../TypeInput";
import {
  AsyncValidationFunction,
  InputProps,
  TransformFunction,
  ValidationError,
  ValidationResult,
} from "../types";
import VlComponentSelect from "../VlComponentSelect";

export const validateArrayInput: AsyncValidationFunction<any[]> = async ({
  value,
  schemaType,
  typeMap,
  dataPath,
}) => {
  const itemType = head(schemaType.of || []);
  if (!itemType) {
    const error: ValidationError = {
      message: "Array type is missing",
      dataPath,
    };
    return { filteredData: [], errors: [error], unhandledErrors: [error] };
  }

  const promises = (value || []).map((item, index) => {
    return validateAnyType({
      value: item,
      schemaType: itemType,
      typeMap,
      dataPath: [...dataPath, index],
    });
  });

  const itemValidationResults = await Promise.all(promises);
  return itemValidationResults.reduce<ValidationResult>(
    (accumulator, validationResult) => {
      if (validationResult.unhandledErrors.length > 0) {
        return {
          filteredData: accumulator.filteredData,
          errors: [...accumulator.errors, ...validationResult.errors],
          unhandledErrors: accumulator.unhandledErrors,
        };
      }
      return {
        filteredData: [
          ...accumulator.filteredData,
          ...[validationResult.filteredData],
        ],
        errors: [...accumulator.errors, ...validationResult.errors],
        unhandledErrors: accumulator.unhandledErrors,
      };
    },
    { filteredData: [], errors: [], unhandledErrors: [] },
  );
};

export const transformArray: TransformFunction<any[], any[]> = async ({
  value,
  schemaType,
}) => {
  const itemType = head(schemaType.of || []);
  if (!itemType) {
    return value;
  }
  return Promise.all(
    (value || []).map(async (item) => {
      return await transformAnyType({
        value: item,
        schemaType: itemType,
      });
    }),
  );
};

export default function ArrayInput({
  schemaType,
  value: valueFromProps,
  onValueChange,
  dataPath,
}: InputProps<any[]>) {
  const itemType = head(schemaType.of || []);
  const value = valueFromProps || [];

  if (!itemType) {
    return null;
  }

  const onAddNewItem = () => {
    const newValue = append(null, value);
    onValueChange(newValue);
  };

  const onDeleteExistingItem = (itemIndex: number) => {
    const newArray = value.filter((_, index) => index !== itemIndex);
    onValueChange(newArray);
  };

  const moveItemUp = (index: number) => {
    onValueChange(move(index, index - 1, value));
  };
  const moveItemDown = (index: number) => {
    onValueChange(move(index, index + 1, value));
  };

  return (
    <>
      {value.map((item, index) => {
        return (
          <Column marginVertical="medium" key={index}>
            <Row crossAxis="center" marginVertical="medium">
              <SimpleText>{index + 1}</SimpleText>
              <VLButton
                marginHorizontal="medium"
                size="small"
                icon="delete"
                onClick={() => onDeleteExistingItem(index)}
              />
              <VLButton
                marginHorizontal="medium"
                size="small"
                icon="arrowUp"
                disabled={index === 0}
                onClick={() => moveItemUp(index)}
              />
              <VLButton
                marginHorizontal="medium"
                size="small"
                icon="arrowDown"
                disabled={value.length === index + 1}
                onClick={() => moveItemDown(index)}
              />
            </Row>
            <TypeInput
              value={item}
              onValueChange={(itemValue) => {
                onValueChange(assocPath([index], itemValue, value));
              }}
              schemaType={itemType}
              dataPath={[...dataPath, index]}
            />
          </Column>
        );
      })}
      {itemType.name === "VlComponent" ? (
        <VlComponentSelect
          onComponentSelect={(option) => {
            onValueChange(append(option, value));
          }}
        />
      ) : (
        <Row mainAxis="flex-end">
          <VLButton t="buttons.add" onClick={onAddNewItem} />
        </Row>
      )}
    </>
  );
}
