import { Row } from "components/common/layout/Flex";
import React from "react";
import FieldDescription from "../inputs/FieldDescription";
import {
  PreviewImage,
  resizedImageProps,
  VlS3MediaPosterFile,
} from "../inputs/PosterUploadInput";
import { ResourcePreviewProps } from "./types";

export default (props: ResourcePreviewProps<VlS3MediaPosterFile>) => {
  const { resolveValue, resolveFallback, dataPath, locale } = props;
  const value = resolveValue({ locale, dataPath });
  const fallbackValue = resolveFallback({ locale, dataPath });

  const posterFile = value || fallbackValue;

  if (!posterFile) {
    return null;
  }

  return (
    <Row grow={0} marginVertical="small">
      <FieldDescription {...props} isPreview />
      <PreviewImage
        key={posterFile.s3Url}
        isFallback={!value}
        src={posterFile.s3Url}
        {...resizedImageProps(posterFile)}
      />
    </Row>
  );
};
