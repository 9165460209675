import { useState } from "react";
import { createContainer } from "unstated-next";

export const MEDIA_ASSET_SIDEBAR = "MediaAssetSidebar";
export const CONTENT_DETAILS_SIDEBAR = "ContentDetailsSidebar";
export const CMS_ERRORS_SIDEBAR = "CmsErrorSidebar";
export const LIVE_STREAM_DETAILS_SIDEBAR = "LiveStreamDetailsSidebar";

export type RightSidebarType =
  | typeof LIVE_STREAM_DETAILS_SIDEBAR
  | typeof MEDIA_ASSET_SIDEBAR
  | typeof CONTENT_DETAILS_SIDEBAR
  | typeof CMS_ERRORS_SIDEBAR;

const useRightSidebarState = () => {
  const [sidebarType, setSidebarType] = useState<RightSidebarType | null>(null);

  const isSidebarOpen = sidebarType !== null;

  const openSidebar = (type: RightSidebarType) => {
    setSidebarType(type);
  };

  const closeSidebar = () => {
    setSidebarType(null);
  };

  return { isSidebarOpen, openSidebar, closeSidebar, sidebarType };
};

export default createContainer(useRightSidebarState);
