import { SimpleTypeReference } from "@vldev/simple-type/lib/v2";
import React from "react";
import ComponentEditorState from "state/ComponentEditorState";
import TypeInput, { validateAnyType } from "../TypeInput";
import {
  InputProps,
  TransformFunction,
  AsyncValidationFunction,
  ValidationError,
} from "../types";
import ArrayInput, { transformArray, validateArrayInput } from "./ArrayInput";
import NumberInput, { validateNumberInput } from "./NumberInput";
import OptionalInput, {
  transformOptional,
  validateOptionalInput,
} from "./OptionalInput";
import TextInput, { validateTextInput } from "./TextInput";
import VlComponentInput, {
  transformVlComponent,
  validateVlComponent,
} from "./VlComponentInput";

export const validateReference: AsyncValidationFunction<any> = async (
  props,
) => {
  const { typeMap, schemaType, value, dataPath } = props;
  const referredType = typeMap[schemaType.name];

  if (referredType) {
    return await validateAnyType({
      ...props,
      schemaType: referredType,
    });
  }

  if (schemaType.name === "String") {
    const errors = await validateTextInput(props);
    return { filteredData: value, errors, unhandledErrors: errors };
  }
  if (schemaType.name === "Int") {
    const errors = await validateNumberInput(props);
    return { filteredData: value, errors, unhandledErrors: errors };
  }
  if (schemaType.name === "Array") {
    return await validateArrayInput(props);
  }
  if (schemaType.name === "Optional") {
    return await validateOptionalInput(props);
  }
  if (schemaType.name === "VlComponent") {
    return await validateVlComponent(props);
  }
  const error: ValidationError = {
    message: `Missing type validator for ${schemaType.name} at ${dataPath}`,
    dataPath,
  };
  return { filteredData: value, errors: [error], unhandledErrors: [error] };
};

export const transformReference: TransformFunction<
  any,
  any,
  SimpleTypeReference
> = async ({ value, schemaType }) => {
  if (schemaType.name === "VlComponent") {
    return await transformVlComponent({ value });
  }
  if (schemaType.name === "Array") {
    return await transformArray({ value, schemaType });
  }
  if (schemaType.name === "Optional") {
    return await transformOptional({ value, schemaType });
  }
  return value;
};

export default function TypeReferenceInput(
  props: InputProps<any, SimpleTypeReference>,
) {
  const { schemaType } = props;
  const { componentTypeMap } = ComponentEditorState.useContainer();
  const referredType = componentTypeMap[schemaType.name];

  if (referredType) {
    return <TypeInput {...props} schemaType={referredType} />;
  }
  if (schemaType.name === "VlComponent") {
    return <VlComponentInput {...props} />;
  }
  if (schemaType.name === "String") {
    return <TextInput {...props} />;
  }
  if (schemaType.name === "Int") {
    return <NumberInput {...props} />;
  }
  if (schemaType.name === "Array") {
    return <ArrayInput {...props} />;
  }
  if (schemaType.name === "Optional") {
    return <OptionalInput {...props} />;
  }
  return null;
}
