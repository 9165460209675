import { isEmpty, toPairs } from "ramda";
import { isDefined } from "types/predicates";

const removeEmptyValues = <T>(obj: T): Partial<T> | undefined => {
  if (typeof obj === "object") {
    return toPairs(obj as any).reduce<T>((acc, [key, value]) => {
      if (!isDefined(value) || isEmpty(value)) {
        return acc;
      }
      if (typeof value === "object" && !Array.isArray(value)) {
        const newValue = removeEmptyValues(value);
        if (isEmpty(newValue)) {
          return acc;
        }
        return { ...acc, [key]: newValue };
      }
      return { ...acc, [key]: value };
    }, {} as T);
  }
  if (!isEmpty(obj)) {
    return obj;
  }
  return undefined;
};

export default removeEmptyValues;
