import { SimpleText } from "components/common/text/SimpleText";
import { pluck } from "ramda";
import React from "react";
import FolderStore from "store/FolderStore";

interface FolderPathProps {
  folderId: string;
}

export default ({ folderId }: FolderPathProps) => {
  const { getFolderPath } = FolderStore.useContainer();
  const path = getFolderPath(folderId);

  return <SimpleText>{pluck("name", path).join(" / ")}</SimpleText>;
};
