import { path } from "ramda";
import { ContentRow } from "store/contentInterfaces";
import useProjectLanguages from "./useProjectLanguages";

export default (dataPath: string[]) => {
  const { selectedLanguage, defaultLanguage } = useProjectLanguages();

  const resolveValue = <T>(contentRow: ContentRow): T | undefined =>
    path<T>([selectedLanguage, ...dataPath], contentRow.localeData);
  const resolveFallback = <T>(contentRow: ContentRow): T | undefined =>
    resolveValue<T>(contentRow) ||
    path<T>([defaultLanguage || "", ...dataPath], contentRow.localeData);

  return { resolveValue, resolveFallback };
};
