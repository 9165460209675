import MediaBrowserPreferences from "state/MediaBrowserPreferences";
import UserState from "state/UserState";
import { useMetadataFieldsSubscription } from "__gen__/appService";

export default () => {
  const { metaColumns } = MediaBrowserPreferences.useContainer();
  const { selectedOrgId } = UserState.useContainer();
  const { data: metaFieldsData } = useMetadataFieldsSubscription({
    orgId: selectedOrgId,
  });

  if (metaColumns.length === 0) {
    return [];
  }

  const metaFields = (
    (metaFieldsData && metaFieldsData.metadataFields) ||
    []
  ).filter(({ id }) => metaColumns.indexOf(id) > -1);

  return metaFields;
};
