import { Row } from "components/common/layout/Flex";
import UploadNotificationBox from "components/common/upload/UploadNotificationBox";
import React from "react";
import DownloadNotificationBox from "./DownloadNotificationBox";
import NotificationArea from "./NotificationArea";

export default () => {
  return (
    <NotificationArea>
      <DownloadNotificationBox />
      <Row grow={0} marginVertical="medium" />
      <UploadNotificationBox />
    </NotificationArea>
  );
};
