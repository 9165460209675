import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { equals, findIndex } from "ramda";
import React from "react";

export interface VLSelectProps<T> extends Omit<SelectProps<any>, "options"> {
  options: T[];
  optionLabel: (option: T) => string;
  onOptionSelect: (option: T) => void;
  selectedOption?: T;
}

export default <T extends any>({
  options,
  optionLabel,
  onOptionSelect,
  selectedOption,
  ...selectProps
}: VLSelectProps<T>) => {
  const onSelect = (index: any) => {
    onOptionSelect(options[index]);
  };

  const selectedIndex = findIndex(
    (option) => equals(option, selectedOption),
    options,
  );

  return (
    <Select
      onSelect={onSelect}
      value={selectedIndex === -1 ? undefined : selectedIndex}
      {...selectProps}
    >
      {options.map((option, index) => (
        <Select.Option key={index} value={index}>
          {optionLabel(option)}
        </Select.Option>
      ))}
    </Select>
  );
};
