import { mergeDeepRight } from "ramda";
import UserState from "state/UserState";
import { useVlConfigQuery, VlConfigQuery } from "__gen__/appService";

export default () => {
  return useVlConfigQuery({});
};

export const useOrgConfig = () => {
  const { selectedOrgId } = UserState.useContainer();
  const { data: vlOrgConfig, loading: vlOrgConfigLoading } = useVlConfigQuery(
    {},
  );
  const { data: orgConfig, loading } = useVlConfigQuery({ id: selectedOrgId });
  const mergedProto = mergeDeepRight(
    vlOrgConfig?.defaultConfig?.proto || {},
    orgConfig?.defaultConfig?.proto || {},
  );
  const data = {
    defaultConfig: {
      ...vlOrgConfig?.defaultConfig,
      ...orgConfig?.defaultConfig,
      proto: mergedProto,
    },
  };
  return {
    data: (data as VlConfigQuery) || {} || undefined,
    loading: loading || vlOrgConfigLoading,
  };
};
