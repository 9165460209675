import { TextButton } from "components/common/buttons/VLButton";
import DropdownOverlay from "components/common/layout/DropdownOverlay";
import { Column } from "components/common/layout/Flex";
import { MarginedDivider } from "components/common/layout/MarginedDivider";
import { SimpleText } from "components/common/text/SimpleText";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import React from "react";
import { Link } from "react-router-dom";
import { User, UserRole } from "state/UserState";
import SignOutButton from "./SignOutButton";

const getRoleString = (role: UserRole) => {
  switch (role) {
    case UserRole.ADMIN:
      return strings("roles.vl-admin");
    case UserRole.USER:
      return strings("roles.vl-user");
  }
};

interface UserDropdownProps {
  user: User;
  selectRole: (role: UserRole) => void;
  selectedRole: UserRole;
}

const UserDropdown = ({
  user,
  selectRole,
  selectedRole
}: UserDropdownProps) => {
  const hasManyRoles = user.allowedRoles.length > 1;

  return (
    <DropdownOverlay padding="medium">
      {!!user.email && <SimpleText color="secondary">{user.email}</SimpleText>}
      {!!user.name && (
        <SimpleText color="tertiary" fontSize="small">
          {user.name}
        </SimpleText>
      )}
      <MarginedDivider margin="small" />
      {hasManyRoles &&
        user.allowedRoles.sort().map(role => (
          <TextButton
            disabled={role === selectedRole}
            key={role}
            onClick={() => selectRole(role)}
          >
            {`${user.name || user.email} - ${getRoleString(role)}`}
          </TextButton>
        ))}
      {hasManyRoles && <MarginedDivider margin="small" />}
      <Column marginVertical="small">
        <Link to={Paths.account.withParams()}>
          <TextButton icon="user" t="common.account" />
        </Link>
      </Column>
      {/* <Column marginVertical="small">
        <Link to={Paths.projects.withParams()}>
          <TextButton icon="project" t="common.projects" />
        </Link>
      </Column> */}
      <Column marginVertical="small">
        <SignOutButton />
      </Column>
    </DropdownOverlay>
  );
};

export default UserDropdown;
