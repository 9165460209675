import { Table } from "antd";
import VLButton from "components/common/buttons/VLButton";
import { Row } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import CanEditOrgData from "components/permissionControl/CanEditOrgData";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import React from "react";
import { Link } from "react-router-dom";
import PermissionStore from "store/PermissionStore";
import {
  OrgRoleMembersSubscription,
  useOrgRoleMembersSubscription,
} from "__gen__/appService";
import commaJoin from "./functions/commaJoin";
import MemberLink from "./MemberLink";
import RoleLink from "./RoleLink";
import TeamLink from "./TeamLink";

type MemberRole = OrgRoleMembersSubscription["roles"][0];

interface OrgRolesProps {
  orgId: string;
  orgSlug: string;
}

export default ({ orgId, orgSlug }: OrgRolesProps) => {
  const { data, loading } = useOrgRoleMembersSubscription({ orgId });
  const { canEditOrgData, canEditOrgMembers } = PermissionStore.useContainer();

  const roles = data?.roles || [];

  return (
    <>
      <CanEditOrgData>
        <Row grow={0} mainAxis="flex-end" marginVertical="medium">
          <Link
            to={Paths.orgRoleDetails.withParams({ orgSlug, roleId: "new" })}
          >
            <VLButton icon="plus" />
          </Link>
        </Row>
      </CanEditOrgData>
      <Table<MemberRole>
        dataSource={roles}
        rowKey="id"
        pagination={false}
        loading={loading}
        size="small"
      >
        <Table.Column<MemberRole>
          dataIndex="name"
          title={strings("common.role")}
          render={(_, role) => (
            <RoleLink
              disabled={!canEditOrgData}
              role={role}
              orgSlug={orgSlug}
            />
          )}
        />
        <Table.Column<MemberRole>
          dataIndex="groups"
          title={strings("common.teams")}
          render={(_, { groups }) => {
            if (groups.length === 0) {
              return <SimpleText color="tertiary" t="orgRoles.noTeams" />;
            }
            return (
              <SimpleText color="secondary">
                {commaJoin(
                  groups.map(({ id, group }) => (
                    <TeamLink
                      disabled={!canEditOrgMembers}
                      key={id}
                      team={group}
                      orgSlug={orgSlug}
                    />
                  )),
                )}
              </SimpleText>
            );
          }}
        />
        <Table.Column<MemberRole>
          dataIndex="members"
          title={strings("orgRoles.membersWithRole")}
          render={(_, { members }) => {
            if (members.length === 0) {
              return <SimpleText color="tertiary" t="orgRoles.noMembers" />;
            }
            return (
              <SimpleText color="secondary">
                {commaJoin(
                  members.map(({ id, user, memberId }) => (
                    <MemberLink
                      disabled={!canEditOrgMembers}
                      key={id}
                      memberId={memberId}
                      user={user}
                      orgSlug={orgSlug}
                    />
                  )),
                )}
              </SimpleText>
            );
          }}
        />
      </Table>
    </>
  );
};
