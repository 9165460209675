import { endsWith, equals, startsWith } from "ramda";

export interface MimeFile {
  name: string;
  mimeType: string;
}

export const isMimeAudio = (file: MimeFile) =>
  startsWith("audio", file.mimeType);
export const isMimeImage = (file: MimeFile) =>
  startsWith("image/", file.mimeType);
export const isMimeVideo = (file: MimeFile) =>
  startsWith("video/", file.mimeType) || isMxf(file);

const isMxf = (file: MimeFile) =>
  equals("application/mxf", file.mimeType) ||
  (equals("application/octet-stream", file.mimeType) &&
    endsWith(".mxf", file.name.toLowerCase()));
