import { AutoComplete } from "antd";
import { SelectValue } from "antd/lib/select";
import VLInput from "components/common/input/VLInput";
import VLIcon from "components/common/VLIcon";
import useDebouncedValue from "components/hooks/useDebouncedValue";
import strings from "localisation/strings";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { WithId } from "types/common";
import {
  Maybe,
  SearchMembersToAddQuery,
  useSearchMembersToAddLazyQuery,
} from "__gen__/appService";

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isValidEmail = (maybeEmail: string) => {
  return EMAIL_REGEX.test(maybeEmail.toLowerCase());
};

const LoadingIcon = styled(VLIcon)`
  color: ${({ theme, spin }) =>
    spin ? theme.color.background.tertiary : "transparent"};
`;

export interface SelectedUser {
  id: string;
  name?: Maybe<string>;
  email?: Maybe<string>;
  orgMembers: WithId[];
}

interface UserAutocompleteProps {
  onUserSelected: (user: SelectedUser) => void;
  onEmailSelected: (email: string) => void;
  excludedOrgIds?: string[];
  includedMemberOrgs?: string[];
}

const UserAutocomplete = ({
  onUserSelected,
  onEmailSelected,
  excludedOrgIds,
  includedMemberOrgs,
}: UserAutocompleteProps) => {
  const {
    value: inputValue,
    setValue: setInputValue,
    debouncedValue,
  } = useDebouncedValue({ initialValue: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchMembers, { data }] = useSearchMembersToAddLazyQuery();

  const onSearch = useCallback(async () => {
    if (debouncedValue) {
      try {
        await searchMembers({
          variables: {
            partialNameOrEmail: `%${debouncedValue}%`,
            excludedOrgIds,
            includedMemberOrgs,
          },
        });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [
    debouncedValue,
    setIsLoading,
    searchMembers,
    excludedOrgIds,
    includedMemberOrgs,
  ]);

  useEffect(() => {
    onSearch();
  }, [debouncedValue, onSearch]);

  useEffect(() => {
    if (!isLoading && inputValue !== debouncedValue) {
      setIsLoading(true);
    }
  }, [inputValue, isLoading, setIsLoading, debouncedValue]);

  const users = (data && data.users) || [];

  const onInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const onSelect = (userOrEmail: SelectValue) => {
    setInputValue("");
    if (userOrEmail === "email") {
      onEmailSelected(inputValue);
    } else {
      const user: SearchMembersToAddQuery["users"][0] = JSON.parse(
        `${userOrEmail}`,
      );
      onUserSelected(user);
    }
  };

  const usersDataSource = users.map((user) => ({
    value: JSON.stringify(user),
    text: `${user.name || ""} • ${user.email || ""}`,
  }));

  const emailDataSource = isValidEmail(inputValue)
    ? [
        {
          value: "email",
          text: `Invite: ${inputValue}`,
        },
      ]
    : [];

  const dataSource = [...usersDataSource, ...emailDataSource];

  return (
    <AutoComplete
      open={isFocused && dataSource.length > 0 && !isLoading}
      dataSource={dataSource}
      onSearch={onInputChange}
      value={inputValue}
      onSelect={onSelect}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <VLInput
        suffix={<LoadingIcon type="sync" spin={isLoading} />}
        placeholder={strings("organizationMembers.addMembersInput")}
      />
    </AutoComplete>
  );
};

export default UserAutocomplete;
