import { SimpleText } from "components/common/text/SimpleText";
import { equals } from "ramda";
import React from "react";
import ComponentEditorState from "state/ComponentEditorState";
import { DataPath } from "./types";

export default function InputErrors({ dataPath }: { dataPath: DataPath }) {
  const { errors } = ComponentEditorState.useContainer();
  return (
    <>
      {errors.map(
        (error, index) =>
          equals(error.dataPath, dataPath) && (
            <SimpleText key={index} color="error">
              {error.message}
            </SimpleText>
          ),
      )}
    </>
  );
}
