import VLSelect from "components/common/select/VLSelect";
import { UnionTypeComposer } from "graphql-compose";
import React from "react";
import FieldInput from "./FieldInput";
import InputWrapper from "./InputWrapper";
import { ResourceInputProps } from "./types";

interface WithTypename {
  __typename: string;
}

export default (props: ResourceInputProps<WithTypename, UnionTypeComposer>) => {
  const {
    composer,
    dataPath,
    onValueSaved,
    locale,
    resolveValue,
    resolveFallback,
  } = props;

  const value = resolveValue({ locale, dataPath });
  const fallbackValue = resolveFallback({ locale, dataPath });
  const options = composer.getTypeComposers();

  const selectedOption = options.find(
    (option) => option.getTypeName() === value?.__typename,
  );
  const fallbackOption = options.find(
    (option) => option.getTypeName() === fallbackValue?.__typename,
  );

  return (
    <>
      <InputWrapper {...props}>
        <VLSelect
          disabled={options.length === 0}
          selectedOption={selectedOption}
          placeholder={fallbackOption?.getDescription()}
          style={{ width: 200 }}
          options={options}
          onOptionSelect={(option) =>
            onValueSaved({
              value: { __typename: option.getTypeName() },
              dataPath,
              locale,
            })
          }
          optionLabel={(option) =>
            option.getDescription() || option.getTypeName()
          }
        />
      </InputWrapper>
      {fallbackOption && (
        <FieldInput
          key={value?.__typename}
          {...props}
          onValueSaved={(params) => {
            if (!selectedOption && !!fallbackOption) {
              onValueSaved({
                value: { __typename: fallbackOption.getTypeName() },
                dataPath,
                locale,
              });
            }
            onValueSaved(params);
          }}
          composer={fallbackOption}
        />
      )}
    </>
  );
};
