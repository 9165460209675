import { SpreadRow } from "components/common/layout/Flex";
import { format } from "date-fns";
import { ObjectTypeComposer } from "graphql-compose";
import React from "react";
import { VLLocales } from "vl-app-client/lib/cms/contentInterfaces";
import { StringMapped } from "vl-app-client/lib/util/types";
import directivesToObject from "../functions/directivesToObject";
import FieldDescription from "./FieldDescription";
import FieldInput from "./FieldInput";
import InputWrapper from "./InputWrapper";
import { ResourceInputProps } from "./types";

export default (
  props: ResourceInputProps<StringMapped, ObjectTypeComposer>,
) => {
  const { composer, dataPath, tabKey, locale } = props;
  const directives = directivesToObject(composer.getType().astNode?.directives);
  const GMT = format(new Date(), "z");

  return (
    <InputWrapper {...props}>
      {composer.getFieldNames().map((fieldName) => {
        const fieldComposer = composer.getFieldTC(fieldName);
        const fieldConfig = composer.getFieldConfig(fieldName);

        const fieldDirectives = directivesToObject(
          fieldConfig?.astNode?.directives,
        );

        if (
          !!fieldDirectives.ref ||
          (!!directives.tabs && fieldDirectives.tab?.key !== tabKey)
        ) {
          return null;
        }

        const isDateField = fieldName === "date";
        const noLocale = !!fieldDirectives.noLocale;

        return (
          <SpreadRow key={fieldName}>
            {!isDateField && (
              <FieldDescription
                extras={` (${GMT})`}
                isRequired={composer.isFieldNonNull(fieldName)}
                fieldConfig={fieldConfig}
                style={{ flexShrink: 1 }}
              />
            )}
            <FieldInput
              {...props}
              composer={fieldComposer}
              isRequired={false}
              isList={composer.isFieldPlural(fieldName)}
              fieldConfig={fieldConfig}
              dataPath={[...dataPath, fieldName]}
              locale={noLocale ? VLLocales.NO_LOCALE : locale}
              noWrapper
            />
          </SpreadRow>
        );
      })}
    </InputWrapper>
  );
};
