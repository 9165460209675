import styled from "styled-components";
import { SimpleText } from "./SimpleText";

const ScreenTitle = styled(SimpleText).attrs({
  as: "h2",
  fontSize: "large",
})`
  margin-bottom: ${({ theme }) => theme.margin.large};
`;

export default ScreenTitle;
