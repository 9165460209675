import changeContentStatus from "components/cms/functions/changeContentStatus";
import { assocPath } from "ramda";
import React, { Dispatch, SetStateAction } from "react";
import { ContentRow, VLContentType } from "store/contentInterfaces";
import ContentStore from "store/ContentStore";
import ResourceForm, { ResourceFormProps } from "../inputs/ResourceForm";
import { LocaleData } from "../inputs/types";
import LanguageSelect from "../LanguageSelect";

interface ContentDetailsProps
  extends Omit<ResourceFormProps, "resourceValue" | "setResourceValue"> {
  contentRow: ContentRow;
  contentType: VLContentType;
  tabKey?: string;
}

export default ({ contentType, contentRow, ...props }: ContentDetailsProps) => {
  const { upsertRow } = ContentStore.useContainer();

  const onChange: Dispatch<SetStateAction<LocaleData>> = (setterOrValue) => {
    let newValue: LocaleData;
    if (typeof setterOrValue === "function") {
      newValue = setterOrValue(contentRow.localeData);
    } else {
      newValue = setterOrValue;
    }
    upsertRow(
      assocPath(["localeData"], newValue, changeContentStatus(contentRow)),
    );
  };

  return (
    <>
      <LanguageSelect />
      <ResourceForm
        key={contentRow.id}
        {...props}
        resourceValue={contentRow.localeData}
        setResourceValue={onChange}
        contentRow={contentRow}
        contentType={contentType}
        isPreview
      />
    </>
  );
};
