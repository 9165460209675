import { Form, message, Radio } from "antd";
import VLButton from "components/common/buttons/VLButton";
import { Column, Row } from "components/common/layout/Flex";
import { MarginedDivider } from "components/common/layout/MarginedDivider";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import React, { useState } from "react";
import { useHistory } from "react-router";
import UserState from "state/UserState";
import { useCreateOrganizationMutation } from "__gen__/appService";
import FormField from "./FormField";
import SlugInputItem from "./SlugInputItem";
import { useCheckSlug } from "./useCheckSlug";

export enum OrganizationType {
  PERSONAL = "PERSONAL",
  COMPANY = "COMPANY",
}

export default () => {
  const { selectOrgId } = UserState.useContainer();
  const [
    createOrganization,
    { loading: createIsLoading, error },
  ] = useCreateOrganizationMutation();
  const history = useHistory();
  const { checkSlug, sameSlugCount } = useCheckSlug({ type: "orgSlug" });
  const [form] = Form.useForm();
  const [isCompanyType, setIsCompanyType] = useState(false);

  const onTypeChange = () => {
    setIsCompanyType(
      form.getFieldValue("organizationType") === OrganizationType.COMPANY,
    );
  };

  const onFinish = async (variables: any) => {
    try {
      const { data } = await createOrganization({
        variables,
      });
      const orgId = data?.organizations?.inserted[0]?.id;
      if (orgId) {
        selectOrgId(orgId);
      }
      history.push(Paths.dashboard.withParams());
    } catch (err) {
      message.error(strings("createOrganizationForm.createFailed"));
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Form.Item
        label="Type"
        name="organizationType"
        initialValue={OrganizationType.PERSONAL}
      >
        <Radio.Group onChange={onTypeChange}>
          <Radio value={OrganizationType.PERSONAL}>
            {strings("createOrganizationForm.fields.orgType.personal")}
          </Radio>
          <Radio value={OrganizationType.COMPANY}>
            {strings("createOrganizationForm.fields.orgType.company")}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Row>
        <Column>
          <FormField
            fieldKey="name"
            label={strings("createOrganizationForm.fields.name.label")}
            rules={[
              {
                required: true,
                message: strings("createOrganizationForm.fields.name.message"),
              },
            ]}
          />
          <FormField
            fieldKey="email"
            label={strings("createOrganizationForm.fields.email.label")}
            rules={[
              {
                required: true,
                message: strings("createOrganizationForm.fields.email.message"),
              },
            ]}
          />
          <FormField
            fieldKey="phoneNumber"
            label={strings("createOrganizationForm.fields.phoneNumber.label")}
            rules={[
              {
                required: true,
                message: strings(
                  "createOrganizationForm.fields.phoneNumber.message",
                ),
              },
            ]}
          />
          <FormField
            fieldKey="notes"
            label={strings("createOrganizationForm.fields.notes.label")}
            type="textArea"
          />
        </Column>
        <MarginedDivider type="vertical" margin="large" />
        <Column>
          <SlugInputItem
            checkSlug={checkSlug}
            sameSlugCount={sameSlugCount}
            slugFrom={form.getFieldValue("name")}
            formUtils={form}
            addonBefore={`${window.location.host}/`}
            error={error}
            rules={[
              {
                required: true,
              },
            ]}
          />
          {isCompanyType && (
            <>
              <FormField
                fieldKey="companyCode"
                label={strings(
                  "createOrganizationForm.fields.companyCode.label",
                )}
                type="number"
              />
              <FormField
                fieldKey="vatNumber"
                label={strings("createOrganizationForm.fields.vatNumber.label")}
                type="number"
              />
            </>
          )}
          <FormField
            fieldKey="city"
            label={strings("createOrganizationForm.fields.city.label")}
          />
          <FormField
            fieldKey="street"
            label={strings("createOrganizationForm.fields.street.label")}
          />
          <FormField
            fieldKey="houseNumber"
            label={strings("createOrganizationForm.fields.houseNumber.label")}
            type="number"
          />
          <FormField
            fieldKey="zipCode"
            label={strings("createOrganizationForm.fields.zipCode.label")}
            type="number"
          />
        </Column>
      </Row>
      <Form.Item>
        <VLButton loading={createIsLoading} htmlType="submit" type="primary">
          {strings("buttons.create")}
        </VLButton>
      </Form.Item>
    </Form>
  );
};
