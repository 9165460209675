import useTableMetaFields from "components/assetList/hooks/useTableMetaFields";
import { Column } from "components/common/layout/Flex";
import Loading from "components/common/layout/Loading";
import { useBinFolders } from "components/mediaAssets/hooks/useBinFolders";
import { VLFolder } from "components/mediaAssets/types";
import { queryStringToGraphql } from "components/search/serializers";
import Paths from "navigation/Paths";
import { pluck } from "ramda";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import MediaBrowserPreferences from "state/MediaBrowserPreferences";
import UserState from "state/UserState";
import { useDeletedMediaSubscription } from "__gen__/appService";
import BinnedAssetsInfo from "../mediaAssets/BinnedAssetsInfo";
import AssetBrowser from "./AssetBrowser";

export default withRouter(
  ({ location: { search }, history }: RouteComponentProps) => {
    const { selectedOrgId: orgId } = UserState.useContainer();
    const { folders: binnedFolders } = useBinFolders({ orgId });
    const {
      metaColumns,
      sortingOrder,
      sortBy,
    } = MediaBrowserPreferences.useContainer();
    const metaFields = useTableMetaFields();
    const { data, loading } = useDeletedMediaSubscription({
      orgId,
      mediaSearch: queryStringToGraphql(search),
      metadataFieldIds: metaColumns,
      sortBy: sortBy && sortingOrder ? { [sortBy]: sortingOrder } : undefined,
    });

    const onFolderClick = (folder: VLFolder) => {
      history.push(Paths.folderMediaAssets.withParams({ folderId: folder.id }));
    };

    if (loading) {
      return <Loading />;
    }

    if (!data) {
      return null;
    }

    const { mediaAssets: binnedMedia } = data;
    const binnedAssetIds = [
      ...pluck("id", binnedMedia),
      ...pluck("id", binnedFolders),
    ];

    return (
      <Column>
        <BinnedAssetsInfo binnedAssetIds={binnedAssetIds} />
        <AssetBrowser
          mediaAssets={[
            ...binnedMedia,
            ...binnedFolders.map(
              (folder): VLFolder => ({ ...folder, type: "VLFolder" }),
            ),
          ]}
          metaFields={metaFields}
          onFolderClick={onFolderClick}
          isBinnedMedia
        />
      </Column>
    );
  },
);
