import UserState from "state/UserState";
import { useRemovableMediasSubscription } from "__gen__/appService";
export default ({ mediaIds }: { mediaIds: string[] }) => {
  const { user } = UserState.useContainer();
  const { data } = useRemovableMediasSubscription({
    mediaIds,
    userId: user?.id || "",
  });

  return data?.removableMedias || [];
};
