import { DatePicker, Select } from "antd";
import { Row } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import FolderTree from "components/folderBrowser/FolderTree";
import FolderText from "components/folderBrowser/folderTreeNodes/FolderText";
import isOrgOwnersRole from "components/roleDetails/functions/isOrgOwnersRole";
import { moment } from "localisation/i18n";
import strings from "localisation/strings";
import React, { useEffect, useState } from "react";
import FolderStore from "store/FolderStore";
import styled from "styled-components";
import { isDefined, isStringArray, toStringArray } from "types/predicates";
import uuid from "uuid/v4";
import useOrgRoles, { OrgRole } from "./hooks/useOrgRoles";

const RoleSelect = styled(Select)`
  margin-left: ${({ theme }) => theme.margin.medium};
  min-width: 200px;
`;

const ExpiresDatePicker = styled(DatePicker)`
  margin-left: ${({ theme }) => theme.margin.medium};
`;

export interface FolderScope {
  id: string;
  role: OrgRole;
  expires: Date | null;
  folderIds: string[];
}

export interface FolderScopeFormProps {
  orgId: string;
  onScopeChange: (scope: FolderScope) => void;
  initialScope?: FolderScope;
  filterOwnerRoles?: boolean;
}

export default ({
  orgId,
  onScopeChange,
  initialScope,
  filterOwnerRoles,
}: FolderScopeFormProps) => {
  const orgRoles = useOrgRoles({ orgId });
  const [expires, setExpires] = useState<Date | null>(null);
  const [selectedRole, setSelectedRole] = useState<OrgRole>();
  const [folderIds, setFolderIds] = useState<string[]>([]);
  const { rootFolder } = FolderStore.useContainer();

  useEffect(() => {
    if (initialScope) {
      setExpires(initialScope.expires);
      setSelectedRole(initialScope.role);
      setFolderIds(initialScope.folderIds);
    }
  }, [initialScope, setExpires, setSelectedRole, setFolderIds]);

  useEffect(() => {
    if (selectedRole) {
      onScopeChange({
        id: initialScope?.id || uuid(),
        role: selectedRole,
        expires,
        folderIds,
      });
    }
    // eslint-disable-next-line
  }, [JSON.stringify({ selectedRole, expires, folderIds })]);

  if (orgRoles.length === 0) {
    return null;
  }

  const isOwnerRole = isOrgOwnersRole(selectedRole);
  const rolesToSelectFrom = filterOwnerRoles
    ? orgRoles.filter((role) => !isOrgOwnersRole(role))
    : orgRoles;

  return (
    <>
      <Row crossAxis="center">
        <SimpleText t="common.role" />
        <RoleSelect
          placeholder={strings("common.role")}
          onSelect={(value) => {
            const role: OrgRole = JSON.parse(`${value}`);
            if (isOrgOwnersRole(role)) {
              setExpires(null);
              setFolderIds([rootFolder?.id].filter(isDefined));
            }
            setSelectedRole(role);
          }}
        >
          {rolesToSelectFrom.map((role) => (
            <Select.Option key={role.id} value={JSON.stringify(role)}>
              {role.name}
            </Select.Option>
          ))}
        </RoleSelect>
      </Row>
      {!isOwnerRole && (
        <>
          <Row marginVertical="medium" crossAxis="center">
            <SimpleText t="common.expires" />
            <ExpiresDatePicker
              onChange={(newDate) =>
                setExpires(newDate ? newDate.toDate() : null)
              }
              value={expires ? moment(expires) : undefined}
              format="DD MMM YYYY"
            />
          </Row>
          <SimpleText t="folderScopes.mediaScopes" />
          <FolderTree
            checkable
            checkStrictly
            checkedKeys={folderIds}
            onCheck={(ids) => {
              if (Array.isArray(ids)) {
                if (isStringArray(ids)) {
                  setFolderIds(ids);
                }
              } else {
                setFolderIds(toStringArray(ids.checked));
              }
            }}
            nodeComponent={FolderText}
          />
        </>
      )}
    </>
  );
};
