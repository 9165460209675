import ResponsiveImage from "components/common/images/ResponsiveImage";
import { VLMedia } from "components/mediaAssets/types";
import head from "functions/head";
import { prop, sortBy } from "ramda";
import React, { ImgHTMLAttributes, ReactElement, useState } from "react";
import GraphQLClientState from "state/GraphQLClientState";
import { isDefined } from "types/predicates";
import { useS3SignedUrlQuery } from "__gen__/appGatewaySdk";

export interface MediaThumbnailProps
  extends ImgHTMLAttributes<HTMLImageElement> {
  asset: VLMedia;
  imagePlaceholder?: ReactElement;
}

function sortByResolution<T extends { height: number }>(thumbnails: Array<T>) {
  return sortBy(prop("height"), thumbnails.filter(isDefined));
}

export default ({ asset, imagePlaceholder, ...props }: MediaThumbnailProps) => {
  const [error, setError] = useState<any>();
  const { apiClient } = GraphQLClientState.useContainer();
  const originalFile = head(asset.originalFiles);
  const possibleThumbnails = [
    ...(originalFile?.thumbnails || []),
    ...(originalFile?.videoThumbnails || []),
  ];
  const lowestRes = head(sortByResolution(possibleThumbnails));
  const fileId = lowestRes?.file?.id || originalFile?.id || "";
  const { data: signedUrlData } = useS3SignedUrlQuery(
    { assetFileId: fileId },
    { client: apiClient },
  );
  const thumbnailUrl = signedUrlData?.s3SignedUrl;
  if (!originalFile || !thumbnailUrl || error) {
    return imagePlaceholder || null;
  }

  return (
    <ResponsiveImage
      onError={setError}
      alt={originalFile.name}
      src={thumbnailUrl}
      {...props}
    />
  );
};
