import globalTheme from "style/theme";
import styled from "styled-components";

interface MarginProps {
  size?: keyof typeof globalTheme.margin;
}

export default styled.div<MarginProps>`
  margin-left: ${({ theme, size }) => theme.margin[size || "medium"]};
  margin-top: ${({ theme, size }) => theme.margin[size || "medium"]};
`;
