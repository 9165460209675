import { Tag } from "antd";
import strings from "localisation/strings";
import { TranslationPaths } from "localisation/TranslationPaths";
import React from "react";
import styled from "styled-components";

interface TagRadioProps<T> {
  value: T;
  options: Array<{
    value: T;
    label?: string;
    t?: TranslationPaths;
  }>;
  onSelect: (value: T) => void;
}

const TagRadioRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -${({ theme }) => theme.margin.medium};
`;

const TagRadioTag = styled(Tag.CheckableTag)`
  margin: 0 ${({ theme }) => theme.margin.medium};
`;

export default function <T>({ value, options, onSelect }: TagRadioProps<T>) {
  return (
    <TagRadioRow>
      {options.map(({ value: optionValue, label, t }) => (
        <TagRadioTag
          key={`${optionValue}`}
          checked={optionValue === value}
          onChange={(checked) => {
            if (checked) {
              onSelect(optionValue);
            }
          }}
        >
          {t ? strings(t) : label}
        </TagRadioTag>
      ))}
    </TagRadioRow>
  );
}
