import { Checkbox } from "antd";
import { CheckboxProps } from "antd/lib/checkbox";
import { Row } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import strings from "localisation/strings";
import { TranslationPaths } from "localisation/TranslationPaths";
import { append, contains, without } from "ramda";
import React from "react";
import { ReactState } from "state/interfaces/ReactState";
import styled from "styled-components";
import { PermissionScopesEnum } from "vl-app-client/lib/__gen__/appServiceSdk";

const scopeStringMap: { [key in PermissionScopesEnum]: TranslationPaths } = {
  VIEW: "permissionScopes.VIEW",
  EDIT: "permissionScopes.EDIT",
  DELETE: "permissionScopes.DELETE",
};

const allScopes = [
  PermissionScopesEnum.VIEW,
  PermissionScopesEnum.EDIT,
  PermissionScopesEnum.DELETE,
];

const Cell = styled.div`
  display: flex;
  width: 100px;
`;

interface ScopeCheckboxProps extends CheckboxProps {
  scope: PermissionScopesEnum;
  permissionState: ReactState<PermissionScopesEnum[]>;
  possiblePermissions: PermissionScopesEnum[];
}

const ScopeCheckbox = ({
  scope,
  permissionState: [scopes, setScopes],
  possiblePermissions,
  ...props
}: ScopeCheckboxProps) => {
  if (!contains(scope, possiblePermissions)) {
    return <Cell />;
  }

  return (
    <Cell>
      <Checkbox
        checked={contains(scope, scopes)}
        onChange={(ev) => {
          if (ev.target.checked) {
            setScopes(append(scope));
          } else {
            setScopes(without([scope]));
          }
        }}
        {...props}
      >
        {strings(scopeStringMap[scope])}
      </Checkbox>
    </Cell>
  );
};

interface PermissionScopesProps {
  name?: string;
  nameT?: TranslationPaths;
  permissionState: ReactState<PermissionScopesEnum[]>;
  possiblePermissions: PermissionScopesEnum[];
  disabled?: boolean;
}

export default ({ name, nameT, ...props }: PermissionScopesProps) => {
  return (
    <Row grow={0}>
      <SimpleText t={nameT} style={{ width: 300 }}>
        {name}
      </SimpleText>
      {allScopes.map((scope) => (
        <ScopeCheckbox key={scope} scope={scope} {...props} />
      ))}
    </Row>
  );
};
