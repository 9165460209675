import {
  CmsProjectResourcesDocument,
  CmsProjectResourcesQuery,
  CmsProjectResourcesQueryVariables,
  CmsProjectsDocument,
  CmsProjectsSubscription,
  CmsProjectsSubscriptionVariables,
  CmsResourcesDocument,
  CmsResourcesSubscription,
  CmsResourcesSubscriptionVariables,
  CreateProjectPublicationDocument,
  CreateProjectPublicationMutation,
  CreateProjectPublicationMutationVariables,
  ProjectsListDocument,
  ProjectsListSubscription,
  ProjectsListSubscriptionVariables,
  UpdateCmsResourcesDocument,
  UpdateCmsResourcesMutation,
  UpdateCmsResourcesMutationVariables,
  UpsertCmsResourcesDocument,
  UpsertCmsResourcesMutation,
  UpsertCmsResourcesMutationVariables,
} from "__gen__/appService";
import { useOrgConfig } from "components/hooks/useVlConfig";
import { assocPath, drop, equals, keys, pluck } from "ramda";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ReplaySubject, Subject, concatMap, filter, from, of } from "rxjs";
import AuthState from "state/AuthState";
import GraphQLClientState from "state/GraphQLClientState";
import { isDefined } from "types/predicates";
import { v4 } from "uuid";

interface VlCmsResource {
  _ref: string;
  projectId: string;
  data?: any;
  meta?: any;
}

type ResourcePointer = {
  ref: string;
};

type InitEvent = {
  type: "init";
  onResourceValues: ({}: { resources: VlCmsResource[] }) => any;
};

type SubscribeEvent = {
  type: "subscribe";
  ref: string;
  handler: ({}: { resource: VlCmsResource }) => any;
};

type UnsubscribeEvent = {
  type: "unsubscribe";
  ref: string;
};

type CreateEvent = {
  type: "create";
  resource: VlCmsResource;
};

type UpdateEvent = {
  type: "update";
  resource: VlCmsResource;
};

type ResourceSelectedEvent = {
  type: "resourceSelected";
  pointer: ResourcePointer;
  resourcePath: ResourcePointer[];
};

type ResourceEvent =
  | InitEvent
  | ResourceSelectedEvent
  | CreateEvent
  | UpdateEvent
  | SubscribeEvent
  | UnsubscribeEvent;

const baseProjectId = "6d413947-79e1-4b01-bd57-1f4dc4fc8f56";
const projectListType = { ref: "74bcc960-32f0-4d94-834d-c8c3fad132e7" };

const projectsList = {
  _ref: "vl-bo-state/vl-projects-list",
  projectId: "vl-projects",
  type: projectListType,
  data: {
    name: "/",
    // projects: [{ ref: vlBaseProjectId }],
  },
};

const isUpdateEvent = (ev: ResourceEvent): ev is UpdateEvent =>
  ev.type === "update";
const isCreateEvent = (ev: ResourceEvent): ev is CreateEvent =>
  ev.type === "create";

const stringifyPath = ({
  resourcePath,
}: {
  resourcePath: ResourcePointer[];
}) => {
  const refs = drop(1, resourcePath);
  const ids = pluck("ref", refs);
  return ["/cms", ...ids].join("/");
};

const parseLocation = ({ pathname }: { pathname: string }) => {
  const ids = drop(2, pathname.split("/")).filter((s) => s.length > 0);
  return [{ ref: projectsList._ref }, ...ids.map((ref) => ({ ref }))];
};

const hashMap = new Map<string, string>();

export default ({ orgId }: { orgId: string }) => {
  const [siteManagers, setSiteManagers] = useState<Record<string, any>>({});
  let elementRef = useRef<HTMLDivElement>();
  const { hasuraClient } = GraphQLClientState.useContainer();
  const { authState } = AuthState.useContainer();
  const resourceValues$ = useMemo(() => new Subject<VlCmsResource[]>(), []);
  const siteManagerEvents$ = useMemo(() => new Subject<ResourceEvent>(), []);
  const location = useLocation();

  const [resourcePath, setResourcePath] = useState(
    parseLocation({ pathname: location.pathname }),
  );
  const siteManagerProps$ = useMemo(
    () => new ReplaySubject<{ resourcePath: typeof resourcePath }>(1),
    [],
  );
  const [initEvent, setInitEvent] = useState<InitEvent>();
  const [session] = useState(v4());
  const selectedProjectId = resourcePath.at(1)?.ref;
  const { data } = useOrgConfig();
  const history = useHistory();

  const siteManagerUrl =
    // "http://localhost:8080/lib/SiteManager.svelte.js" ||
    data?.defaultConfig?.proto?.cms?.siteManagerUrl ||
    "https://site-manager.visitor.videolevels.com/staging/lib/SiteManager.svelte.js";

  useEffect(() => {
    const newPath = parseLocation({ pathname: location.pathname });
    if (!equals(newPath, resourcePath)) {
      siteManagerProps$.next({
        resourcePath: newPath,
      });
      setResourcePath(newPath);
    }
  }, [location.pathname]);

  useEffect(() => {
    const newPathname = stringifyPath({ resourcePath });
    if (resourcePath.length > 0 && newPathname !== location.pathname) {
      history.push([newPathname, location.search].join(""));
    }
  }, [JSON.stringify(resourcePath)]);

  useEffect(() => {
    const projects = [baseProjectId, selectedProjectId].filter(isDefined);
    const cmsResourcesSubscription = hasuraClient?.subscribe<
      CmsResourcesSubscription,
      CmsResourcesSubscriptionVariables
    >({
      query: CmsResourcesDocument,
      variables: {
        projects,
        after: new Date().toUTCString(),
        session,
      },
    });

    const loadProjectResources = async () => {
      const initialData = await hasuraClient?.query<
        CmsProjectResourcesQuery,
        CmsProjectResourcesQueryVariables
      >({
        query: CmsProjectResourcesDocument,
        variables: {
          projects,
          session,
        },
      });

      initialData?.data.cms_resources.forEach((row) => {
        hashMap.set(row.id, row.proto?.hash);
      });
      const resources = pluck("data", initialData?.data.cms_resources || []);
      resourceValues$.next(resources);
    };

    loadProjectResources();

    const cmsResourcesObserver = cmsResourcesSubscription?.subscribe(
      ({ data }) => {
        data?.cms_resources_stream.forEach((row) => {
          hashMap.set(row.id, row.proto?.hash);
        });
        const resources: VlCmsResource[] = pluck(
          "data",
          data?.cms_resources_stream || [],
        );

        resourceValues$.next(resources);
      },
    );

    const handleUpdateEvent = async (event: UpdateEvent) => {
      const { resource } = event;
      const nextHash = v4();
      const res = await hasuraClient?.mutate<
        UpdateCmsResourcesMutation,
        UpdateCmsResourcesMutationVariables
      >({
        mutation: UpdateCmsResourcesDocument,
        variables: {
          resourceId: resource._ref,
          resource: {
            data: resource,
            proto: {
              session,
              hash: nextHash,
            },
          },
          expectedHash: hashMap.get(resource._ref),
        },
      });

      if (!res?.data?.update_cms_resources?.affected_rows) {
        setTimeout(() => {
          alert("Something went wrong! Refresh!");
          window.location.reload();
        }, 1000);
        throw new Error("INCONSISTENT_STATE");
      } else {
        hashMap.set(resource._ref, nextHash);
      }
    };

    const createPublication = async (resource: VlCmsResource) => {
      await hasuraClient?.mutate<
        CreateProjectPublicationMutation,
        CreateProjectPublicationMutationVariables
      >({
        mutation: CreateProjectPublicationDocument,
        variables: {
          publications: [
            {
              id: resource._ref,
              projectId: resource.projectId,
              data: resource.data,
            },
          ],
        },
      });
    };

    const handleCreateEvent = async (event: CreateEvent) => {
      const { resource } = event;
      if (resource.meta?.type === "vl-publication") {
        await createPublication(resource);
      }
      if (!selectedProjectId) {
        const newResource = assocPath(["meta", "type"], "vl-project", resource);
        resourceValues$.next([newResource]);
        await upsertResources({
          resources: {
            id: resource._ref,
            project: {
              data: {
                id: resource._ref,
                org_id: orgId,
              },
            },
            data: newResource,
            proto: {
              session,
            },
          },
        });
      } else {
        await upsertResources({
          resources: {
            id: resource._ref,
            project_id: selectedProjectId,
            data: resource,
            proto: {
              session,
            },
          },
        });
      }
    };

    const dbEventsSubscription = siteManagerEvents$
      .pipe(
        filter(
          (event) =>
            (isUpdateEvent(event) || isCreateEvent(event)) &&
            !event.resource._ref.startsWith("vl-bo-state"),
        ),
        concatMap((event) => {
          if (isCreateEvent(event)) {
            return from(handleCreateEvent(event));
          }
          if (isUpdateEvent(event)) {
            return from(handleUpdateEvent(event));
          }
          return of();
        }),
      )
      .subscribe();

    return () => {
      cmsResourcesObserver?.unsubscribe();
      dbEventsSubscription.unsubscribe();
    };
  }, [selectedProjectId, !initEvent, orgId]);

  const init = (ev: InitEvent) => {
    setInitEvent(ev);

    resourceValues$.subscribe((resources) => {
      ev.onResourceValues({ resources });
    });
    resourceValues$.next([projectsList]);
    const projectListSubscription = hasuraClient?.subscribe<
      ProjectsListSubscription,
      ProjectsListSubscriptionVariables
    >({
      query: ProjectsListDocument,
      variables: {
        orgId,
      },
    });
    projectListSubscription?.subscribe(({ data }) => {
      const projectResources: VlCmsResource[] = pluck(
        "data",
        data?.projects || [],
      );
      const projects = projectResources.map((project) => ({
        ref: project._ref,
      }));
      const projectsListResource = assocPath(
        ["data", "projects"],
        projects,
        projectsList,
      );

      resourceValues$.next([projectsListResource]);
    });
    const cmsProjectsSubscription = hasuraClient?.subscribe<
      CmsProjectsSubscription,
      CmsProjectsSubscriptionVariables
    >({
      query: CmsProjectsDocument,
      variables: {
        orgId,
        session,
      },
    });
    cmsProjectsSubscription?.subscribe(({ data }) => {
      const projectResources: VlCmsResource[] = pluck(
        "data",
        data?.projects || [],
      );
      resourceValues$.next(projectResources);
    });

    // TODO unsubscribe
  };

  const upsertResources = async ({
    resources,
  }: {
    resources: UpsertCmsResourcesMutationVariables["resources"];
  }) => {
    try {
      await hasuraClient?.mutate<
        UpsertCmsResourcesMutation,
        UpsertCmsResourcesMutationVariables
      >({
        mutation: UpsertCmsResourcesDocument,
        variables: {
          resources,
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  useEffect(() => {
    const component = siteManagers[siteManagerUrl];
    if (component) {
      const siteManager = new component({
        target: elementRef.current,
        props: {
          backOfficeJwt: authState.token,
        },
      });
      siteManager.$on("init", (ev: CustomEvent<InitEvent>) => {
        siteManagerEvents$.next({ ...ev.detail, type: "init" });
        init(ev.detail);
      });
      siteManager.$on(
        "update",
        (ev: CustomEvent<{ resource: VlCmsResource }>) => {
          siteManagerEvents$.next({ ...ev.detail, type: "update" });
          // console.log("Update", ev.detail);
        },
      );
      siteManager.$on(
        "create",
        (ev: CustomEvent<{ resource: VlCmsResource }>) => {
          siteManagerEvents$.next({ ...ev.detail, type: "create" });
          // console.log("Create", ev.detail);
        },
      );
      siteManager.$on(
        "subscribe",
        (ev: CustomEvent<{ ref: string; handler: any }>) => {
          siteManagerEvents$.next({ ...ev.detail, type: "subscribe" });
          // console.log("Subscribe", ev.detail);
        },
      );
      siteManager.$on("unsubscribe", (ev: CustomEvent<{ ref: string }>) => {
        siteManagerEvents$.next({ ...ev.detail, type: "unsubscribe" });
        // console.log("Unsubscribe", ev.detail);
      });
      siteManager.$on(
        "resourceSelected",
        (ev: CustomEvent<Omit<ResourceSelectedEvent, "type">>) => {
          siteManagerEvents$.next({ ...ev.detail, type: "resourceSelected" });
          // console.log("resourceSelected", ev.detail);
          setResourcePath(ev.detail.resourcePath);
        },
      );
      siteManager.$set({
        handlersReady: true,
        resourcePath,
      });
      const observer = siteManagerProps$.subscribe((props) => {
        siteManager.$set(props);
      });
      return () => {
        siteManager.$destroy();
        observer.unsubscribe();
      };
    }
  }, [JSON.stringify(keys(siteManagers)), siteManagerUrl, orgId]);

  useEffect(() => {
    import(/* webpackIgnore: true */ siteManagerUrl)
      .then((module) => {
        const component = module?.default;
        if (component) {
          setSiteManagers((current) => ({
            ...current,
            [siteManagerUrl]: component,
          }));
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [siteManagerUrl]);

  return <div ref={elementRef as any}></div>;
};
