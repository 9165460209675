import VLInput from "components/common/input/VLInput";
import React from "react";
import InputErrors from "../InputErrors";
import { InputProps, ValidationFunction } from "../types";

const countCharacters = (string: string) => {
  return string.replace(/\s/g, "").length;
};

export const validateTextInput: ValidationFunction<string> = ({
  value,
  dataPath,
}) => {
  if (!value || countCharacters(value) <= 0) {
    return [{ message: "This field is required", dataPath }];
  }
  return [];
};

export default function TextInput({
  value,
  onValueChange,
  dataPath,
}: InputProps<string>) {
  return (
    <>
      <VLInput value={value} onTextChange={onValueChange} />
      <InputErrors dataPath={dataPath} />
    </>
  );
}
