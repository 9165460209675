import styled from "styled-components";

interface AppContainerProps {
  oppositeTheme?: boolean;
}

const AppContainer = styled.div<AppContainerProps>`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, oppositeTheme }) =>
    oppositeTheme
      ? theme.color.background.opposite
      : theme.color.background.primary};
`;

export default AppContainer;
