import { writeToString } from "@fast-csv/format";
import { Table } from "antd";
import VLButton from "components/common/buttons/VLButton";
import { Column, Row } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import useDateFns from "components/hooks/useDateFns";
import downloadFileWithUrl from "functions/downloadFileWithUrl";
import React from "react";
import { isDefined } from "types/predicates";
import { useContentStatisticsQuery } from "__gen__/appService";

export interface ContentStatisticsProps {
  contentId: string;
}

const localeCompare = (a?: string | null, b?: string | null) =>
  (a || "").localeCompare(b || "");
const dateCompare = (a: string, b: string) => Date.parse(a) - Date.parse(b);

const propCompare = <T, K extends keyof T>(
  key: K,
  compareFn: (a: T[K], b: T[K]) => number,
) => (a: T, b: T) => compareFn(a[key], b[key]);

export default ({ contentId }: ContentStatisticsProps) => {
  const { data } = useContentStatisticsQuery({ contentId });
  const { formatDateTime } = useDateFns();

  const usedIps = (data?.usedIps || []).map((usage) => {
    const {country, city} = usage.extraData?.geo || {}
    return ({
    ...usage,
    usedAtDate: new Date(usage.codeUsedAt),
    location: [country, city].filter(isDefined).join(" / "),
    country,
    city
  })});

  const downloadCsv = async () => {
    const headerRow = ["Code", "IP", "Code used at", "Country", "City"];
    const csv = await writeToString(
      [
        headerRow,
        ...usedIps.map(({ code, ip, codeUsedAt, country, city }) => [
          code || "",
          ip,
          codeUsedAt,
          country,
          city
        ]),
      ],
      { headers: true },
    );
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    downloadFileWithUrl({ url, fileName: `${contentId}-statistics` });
  };

  return (
    <Column>
      <SimpleText>{`Access codes used ${
        data?.tokens.total?.count || 0
      } times`}</SimpleText>
      <SimpleText>{`${
        data?.usedCodes.total?.count || 0
      } different access codes used`}</SimpleText>
      <SimpleText>{`${
        data?.ips.total?.count || 0
      } different ip addresses used`}</SimpleText>
      {usedIps.length > 0 && (
        <>
          <Row mainAxis="flex-end" marginVertical="small">
            <VLButton icon="download" onClick={downloadCsv} />
          </Row>
          <Table size="small" dataSource={usedIps} rowKey="token">
            <Table.Column<typeof usedIps[0]>
              dataIndex="code"
              title="Code"
              sorter={propCompare("code", localeCompare)}
            />
            <Table.Column<typeof usedIps[0]>
              dataIndex="ip"
              title="IP address"
              sorter={propCompare("ip", localeCompare)}
            />
            <Table.Column<typeof usedIps[0]>
              dataIndex="usedAtDate"
              title="Used at"
              sorter={propCompare("codeUsedAt", dateCompare)}
              render={(_, { usedAtDate }) => (
                <SimpleText>{formatDateTime(usedAtDate)}</SimpleText>
              )}
            />
            <Table.Column<typeof usedIps[0]>
              dataIndex="location"
              title="Location"
              sorter={propCompare("location", dateCompare)}
            />
          </Table>
        </>
      )}
    </Column>
  );
};
