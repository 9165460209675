import MediaSidebarState from "state/MediaSidebarState";
import RightSidebarState, {
  MEDIA_ASSET_SIDEBAR
} from "state/RightSidebarState";

export default () => {
  const { openSidebar, closeSidebar } = RightSidebarState.useContainer();
  const {
    reset,
    selectAsset,
    selectedAssetId
  } = MediaSidebarState.useContainer();

  const openMediaSidebar = (mediaAssetId: string) => {
    selectAsset(mediaAssetId);
    openSidebar(MEDIA_ASSET_SIDEBAR);
  };

  const closeMediaSidebar = () => {
    reset();
    closeSidebar();
  };

  return {
    openMediaSidebar,
    closeMediaSidebar,
    selectedAssetId
  };
};
