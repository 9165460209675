import { UppyFile } from "@uppy/core";
import { Progress } from "antd";
import Paths from "navigation/Paths";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import UploadState, {
  UploadingFile,
  uploadingHasFailed,
} from "state/UploadState";
import styled from "styled-components";
import VLIcon from "../VLIcon";

const StatusProgress = styled(Progress)``;

const StatusIcon = styled(VLIcon)`
  font-size: 20px;
  position: absolute;
  top: 2px;
  left: 0px;
  color: ${({ theme }) => theme.color.foreground.action};
`;

const StatusContainer = styled.div`
  position: relative;
  cursor: pointer;

  ${StatusIcon} {
    visibility: hidden;
  }

  ${StatusProgress} {
    visibility: visible;
  }

  :hover {
    ${StatusIcon} {
      visibility: visible;
    }

    ${StatusProgress} {
      visibility: hidden;
    }
  }
`;

const isCompleted = (file: UppyFile) =>
  !!file.progress && file.progress.uploadComplete;

const MaybeLink = ({ isLink, ...props }: LinkProps & { isLink: boolean }) => {
  if (isLink) {
    return <Link {...props} />;
  }
  return <div className={props.className} children={props.children} />;
};

interface UploadStatusProgressProps {
  file: UploadingFile;
}

const UploadStatusProgress = ({ file }: UploadStatusProgressProps) => {
  const { removeFile } = UploadState.useContainer();
  const completed = isCompleted(file);
  const action = () => {
    if (!completed) {
      removeFile(file.id);
    }
  };

  // if (file.progress && file.progress.uploadComplete) {
  //   console.log(
  //     `Upload finished in ${(Date.now() - (file.progress.uploadStarted || 0)) /
  //       1000} seconds`
  //   );
  // }
  return (
    <StatusContainer onClick={action}>
      <StatusProgress
        type="circle"
        width={20}
        strokeWidth={16}
        showInfo={false}
        percent={file.progress && file.progress.percentage}
        status={uploadingHasFailed(file) ? "exception" : undefined}
      />
      <MaybeLink
        isLink={completed}
        to={Paths.folderMediaAssets.withParams({
          folderId: file.meta.folderId,
        })}
      >
        <StatusIcon
          type={completed ? "folder" : "close-circle"}
          iconTheme="filled"
        />
      </MaybeLink>
    </StatusContainer>
  );
};

export default UploadStatusProgress;
