import VLInput from "components/common/input/VLInput";
import VLTextArea from "components/common/input/VLTextArea";
import React, { useEffect, useState } from "react";
import directivesToObject from "../functions/directivesToObject";
import InputWrapper from "./InputWrapper";
import TagListInput from "./TagListInput";
import { ResourceInputProps } from "./types";

export default (props: ResourceInputProps<string>) => {
  const {
    onValueSaved,
    resolveValue,
    resolveFallback,
    dataPath,
    fieldConfig,
    locale,
  } = props;
  const value = resolveValue({ locale, dataPath });
  const fallbackValue = resolveFallback({ locale, dataPath });
  const [localValue, setLocalValue] = useState(value || "");

  useEffect(() => {
    setLocalValue(value || "");
  }, [value]);

  const onSave = () => onValueSaved({ value: localValue, dataPath, locale });

  const directives = directivesToObject(fieldConfig?.astNode?.directives);

  const Component =
    directives.input?.type === "textarea" ? VLTextArea : VLInput;

  if (directives.input?.type === "tags") {
    return <TagListInput {...(props as any)} />;
  }

  return (
    <InputWrapper {...props}>
      <Component
        placeholder={fallbackValue}
        onBlur={onSave}
        value={localValue}
        onTextChange={setLocalValue}
        onSave={onSave}
      />
    </InputWrapper>
  );
};
