import { Upload } from "antd";
import { RcCustomRequestOptions } from "antd/lib/upload/interface";
import usePublicUpload from "appGateway/hooks/usePublicUpload";
import Loading from "components/common/layout/Loading";
import { SimpleText } from "components/common/text/SimpleText";
import React, { useState } from "react";
import useUserState from "state/hooks/useUserState";
import styled from "styled-components";
import uuid from "uuid/v4";
import InputWrapper from "./InputWrapper";
import { ResourceInputProps } from "./types";

const Container = styled.div`
  position: relative;
  flex: 1;
`;

export const PreviewImage = styled.img<{ isFallback?: boolean }>`
  opacity: ${({ isFallback }) => (isFallback ? 0.7 : 1)};
  max-width: 100%;
  height: 300px;
  object-fit: contain;
  background-color: black;
`;

const LoadingOverlay = styled(Loading)`
  position: absolute;
  background: #fffc;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export interface VlS3MediaPosterFile {
  key: string;
  warehouseId: string;
  s3Url: string;
  resizingServiceUrl?: string | null;
}

export const resizedImageProps = (
  file?: VlS3MediaPosterFile,
): { srcSet?: string; sizes?: string } => {
  const baseUrl = file?.resizingServiceUrl;
  if (!file || !baseUrl) {
    return {};
  }

  return {
    srcSet: `${baseUrl}/fit-in/400x400/${file.key} 400w`,
    sizes: "400px",
  };
};

export default (props: ResourceInputProps<VlS3MediaPosterFile>) => {
  const {
    resolveValue,
    resolveFallback,
    dataPath,
    locale,
    onValueSaved,
  } = props;
  const value = resolveValue({ locale, dataPath });
  const fallbackValue = resolveFallback({ locale, dataPath });
  const { getUpload } = usePublicUpload();
  const { selectedOrgId } = useUserState();
  const [loading, setLoading] = useState(false);

  const posterFile = value || fallbackValue;
  const imageSource = posterFile?.s3Url;
  const hasImage = !!posterFile;

  const handleUpload = async ({
    onSuccess,
    onError,
    file,
  }: RcCustomRequestOptions) => {
    setLoading(true);
    try {
      const res = await getUpload({
        orgId: selectedOrgId,
        keySuffix: `${uuid()}/${file.name}`,
        fileType: file.type,
      });
      if (!res) {
        const error = new Error("Could not get upload URL");
        onError(error);
        throw error;
      }

      const {
        signedUrl,
        warehouseId,
        key,
        resizingServiceUrl,
      } = res.data.s3PublicUploadUrl;
      const url = new URL(signedUrl);
      const s3Url = `${url.origin}/${url.pathname}`;

      const response = await fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
          "x-amz-acl": "public-read",
        },
      });
      onSuccess(response, file);
      onValueSaved({
        value: { key, warehouseId, s3Url, resizingServiceUrl },
        locale,
        dataPath,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      onError(err as any);
    }
    setLoading(false);
  };

  return (
    <InputWrapper {...props}>
      <Container>
        <Upload.Dragger
          disabled={loading}
          fileList={[]}
          customRequest={handleUpload}
        >
          {hasImage && (
            <PreviewImage
              key={imageSource}
              isFallback={!value}
              src={imageSource}
              {...resizedImageProps(posterFile)}
            />
          )}
          {!hasImage && (
            <SimpleText color="secondary" t="uploadArea.dragDropArea" />
          )}
        </Upload.Dragger>
        {loading && <LoadingOverlay />}
      </Container>
    </InputWrapper>
  );
};
