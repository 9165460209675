import { Dropdown } from "antd";
import { DropDownProps } from "antd/lib/dropdown";
import TagRadio from "components/common/buttons/TagRadio";
import { Column, SpreadRow, Row } from "components/common/layout/Flex";
import { MarginedDivider } from "components/common/layout/MarginedDivider";
import VLIcon from "components/common/VLIcon";
import React, { ReactNode } from "react";
import styled from "styled-components";
import MetaSearchConditions from "./MetaSearchConditions";
import { SearchState, ThisOrAll } from "./types";
import VLButton from "components/common/buttons/VLButton";

const DropdownContainer = styled.div`
  border-radius: ${({ theme }) => theme.border.radius.small};
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  background-color: ${({ theme }) => theme.color.background.primary};
  padding: ${({ theme }) => theme.margin.medium};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

interface AssetSearchDropdownProps extends Partial<DropDownProps> {
  children?: ReactNode;
  onClose: () => void;
  searchState: SearchState;
  onChange: (state: Partial<SearchState>) => void;
  onSearch: () => void;
}
export default ({
  onClose,
  onChange,
  searchState,
  onSearch,
  ...props
}: AssetSearchDropdownProps) => {
  return (
    <Dropdown
      overlay={
        <DropdownContainer>
          <Column>
            <SpreadRow crossAxis="center">
              <TagRadio<ThisOrAll>
                value={searchState.folder}
                onSelect={(value) => onChange({ folder: value })}
                options={[
                  { value: "THIS", t: "assetSearchDropdown.thisFolder" },
                  { value: "ALL", t: "assetSearchDropdown.allFolders" },
                ]}
              />
              <VLIcon type="close" onClick={onClose} />
            </SpreadRow>
            <MetaSearchConditions
              searchState={searchState}
              onChange={onChange}
            />
            <MarginedDivider margin="medium" />
            <Row mainAxis="flex-end">
              <VLButton t="common.search" onClick={onSearch} />
            </Row>
          </Column>
        </DropdownContainer>
      }
      {...props}
    />
  );
};
