import { CompareFn, SortOrder } from "antd/lib/table/interface";
import {
  isBooleanMeta,
  isDateMeta,
  isMultipleChoiceMeta,
  isNumberMeta,
  isSimpleTextMeta,
  isSingleChoiceMeta,
  isTagListMeta,
  MetaField,
} from "components/assetMetaSpecs/MetadataFieldTypes";
import head from "functions/head";
import { prop } from "ramda";
import { VLAsset, VLMedia } from "../mediaAssets/types";

export const descend = (comparator: CompareFn<VLAsset>): CompareFn<VLAsset> => {
  return (a, b, order) => comparator(b, a, "descend");
};
export const ascend = (comparator: CompareFn<VLAsset>): CompareFn<VLAsset> =>
  comparator;

type Prop<T, K extends keyof T, V> = {
  [P in K]: V;
};

export const compareStringProps =
  <T, K extends keyof T>(key: K) =>
  (a: Prop<T, K, string>, b: Prop<T, K, string>) => {
    return prop(key, a).localeCompare(prop(key, b));
  };

export const compareBooleanProps =
  <T, K extends keyof T>(key: K) =>
  (a: Prop<T, K, boolean>, b: Prop<T, K, boolean>) => {
    return castBoolean(prop(key, a)) - castBoolean(prop(key, b));
  };

export const compareMediaTypes = (a: VLAsset, b: VLAsset) =>
  a.type.localeCompare(b.type);
export const compareMediaNames = (a: VLAsset, b: VLAsset) =>
  a.name.localeCompare(b.name);
export const compareDeletedDates = (a: VLAsset, b: VLAsset) =>
  Date.parse(a.deletedAt || "") - Date.parse(b.deletedAt || "");
export const compareUpdatedDates = (
  a: Pick<VLAsset, "updatedAt">,
  b: Pick<VLAsset, "updatedAt">,
) => Date.parse(a.updatedAt || "") - Date.parse(b.updatedAt || "");

const castBoolean = (value?: boolean, descends?: boolean) => {
  if (typeof value !== "boolean") {
    return descends ? -Infinity : Infinity;
  }
  return value ? 2 : 1;
};

const castDate = (value?: string, descends?: boolean) => {
  if (!value) {
    return descends ? -Infinity : Infinity;
  }
  return new Date(value).getTime();
};

const LAST_STRING = "ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ";

export const getMetaComparator =
  (field: MetaField) => (a: VLMedia, b: VLMedia, order?: SortOrder) => {
    const descends = order === "descend";
    const aValues = a.metadataValues?.find(
      ({ fieldId }) => field.id === fieldId,
    );
    const bValues = b.metadataValues?.find(
      ({ fieldId }) => field.id === fieldId,
    );
    if (
      isTagListMeta(field) ||
      isSingleChoiceMeta(field) ||
      isMultipleChoiceMeta(field) ||
      isSimpleTextMeta(field)
    ) {
      return (
        head(aValues?.textValues)?.value || (descends ? "" : LAST_STRING)
      ).localeCompare(
        head(bValues?.textValues)?.value || (descends ? "" : LAST_STRING),
      );
    }
    if (isNumberMeta(field)) {
      return (
        (head(aValues?.numberValues)?.value ||
          (descends ? -Infinity : Infinity)) -
        (head(bValues?.numberValues)?.value ||
          (descends ? -Infinity : Infinity))
      );
    }
    if (isDateMeta(field)) {
      return (
        castDate(head(aValues?.dateValues)?.value, descends) -
        castDate(head(bValues?.dateValues)?.value, descends)
      );
    }
    if (isBooleanMeta(field)) {
      return (
        castBoolean(head(aValues?.booleanValues)?.value, descends) -
        castBoolean(head(bValues?.booleanValues)?.value, descends)
      );
    }

    return 0;
  };
