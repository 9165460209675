import { Column, Row } from "components/common/layout/Flex";
import { ObjectTypeComposer } from "graphql-compose";
import React from "react";
import { VLLocales } from "vl-app-client/lib/cms/contentInterfaces";
import { StringMapped } from "vl-app-client/lib/util/types";
import directivesToObject from "../functions/directivesToObject";
import ResourceFormButton from "../inputs/ResourceFormButton";
import { ResourceInputProps } from "../inputs/types";
import FieldPreview from "./FieldPreview";

export default (
  props: ResourceInputProps<StringMapped, ObjectTypeComposer>,
) => {
  const {
    rootComposer,
    composer,
    tabKey,
    dataPath,
    locale,
    contentRow,
    fieldConfig: parentFieldConfig,
    goBack,
  } = props;
  const directives = directivesToObject(composer.getType().astNode?.directives);
  const parentFieldTab = directivesToObject(
    parentFieldConfig?.astNode?.directives,
  )?.tab?.key;

  return (
    <Column grow={0}>
      {!!contentRow && !!directives.editable && parentFieldTab === tabKey && (
        <Row mainAxis="flex-end">
          <ResourceFormButton
            contentType={rootComposer}
            contentId={contentRow.id}
            tabKey={tabKey}
            buttonProps={{ icon: "edit" }}
            onDelete={goBack}
          />
        </Row>
      )}
      {composer.getFieldNames().map((fieldName) => {
        const fieldComposer = composer.getFieldTC(fieldName);
        const fieldConfig = composer.getFieldConfig(fieldName);

        const fieldDirectives = directivesToObject(
          fieldConfig?.astNode?.directives,
        );

        if (!!directives.tabs && fieldDirectives.tab?.key !== tabKey) {
          return null;
        }

        const noLocale = !!fieldDirectives.noLocale;

        return (
          <FieldPreview
            {...props}
            key={fieldName}
            composer={fieldComposer}
            isList={composer.isFieldPlural(fieldName)}
            fieldConfig={fieldConfig}
            dataPath={[...dataPath, fieldName]}
            locale={noLocale ? VLLocales.NO_LOCALE : locale}
          />
        );
      })}
    </Column>
  );
};
