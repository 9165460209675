import { Dropdown, Menu } from "antd";
import VLButton from "components/common/buttons/VLButton";
import { SimpleText } from "components/common/text/SimpleText";
import DeleteAssetsModal from "components/mediaAssets/DeleteAssetsModal";
import useMediaDownload from "components/mediaAssets/hooks/useMediaDownload";
import MoveAssetsModal from "components/mediaAssets/MoveAssetsModal";
import useCanDeleteFolder from "components/permissionControl/hooks/useCanDeleteFolder";
import useCanEditFolder from "components/permissionControl/hooks/useCanEditFolder";
import React, { useState } from "react";
import { FolderFragment } from "__gen__/appService";
import EditFolderModal from "./EditFolderModal";

type ModalType = "EDIT" | "MOVE" | "DELETE";

interface FolderActionsProps {
  folder: FolderFragment;
}

export default ({ folder }: FolderActionsProps) => {
  const folderId = folder.id;
  const [modal, setModal] = useState<ModalType>();
  const canDeleteFolder = useCanDeleteFolder({ folderId });
  const canEditFolder = useCanEditFolder({ folderId });
  const [onDownload] = useMediaDownload();

  const canDelete = !!folder.parentId && canDeleteFolder;

  const onModalClosed = () => {
    setModal(undefined);
  };

  return (
    <>
      <Dropdown
        trigger={["click"]}
        overlay={
          <Menu>
            <Menu.Item onClick={() => onDownload({ folderId })}>
              <SimpleText t="common.download" />
            </Menu.Item>
            {canEditFolder && (
              <Menu.Item onClick={() => setModal("EDIT")}>
                <SimpleText t="common.edit" />
              </Menu.Item>
            )}
            {canDelete && (
              <Menu.Item onClick={() => setModal("MOVE")}>
                <SimpleText t="common.move" />
              </Menu.Item>
            )}
            {canDelete && (
              <Menu.Item onClick={() => setModal("DELETE")}>
                <SimpleText t="common.delete" />
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <VLButton icon="more" />
      </Dropdown>
      <MoveAssetsModal
        visible={modal === "MOVE"}
        assetIds={[folderId]}
        onDone={onModalClosed}
      />
      <DeleteAssetsModal
        visible={modal === "DELETE"}
        assetIds={[folderId]}
        onDone={onModalClosed}
      />
      <EditFolderModal
        visible={modal === "EDIT"}
        folder={folder}
        onDone={onModalClosed}
      />
    </>
  );
};
