import VLIcon from "components/common/VLIcon";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)<BinLinkProps>`
  color: ${({ theme, selected }) =>
    selected
      ? theme.color.foreground.opposite
      : theme.color.foreground.secondary};
  background-color: ${({ selected, theme }) =>
    selected ? theme.color.foreground.action : "initial"};
  border-radius: 2px;
  padding: ${({ theme }) => theme.margin.small};
`;

const BinIcon = styled(VLIcon)`
  margin-right: ${({ theme }) => theme.margin.medium};
`;

interface BinLinkProps {
  selected: boolean;
}

export default ({ selected }: BinLinkProps) => {
  return (
    <StyledLink selected={selected} to={Paths.binnedMediaAssets.withParams()}>
      <BinIcon type="delete" />
      {strings("folderTree.bin")}
    </StyledLink>
  );
};
