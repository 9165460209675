import {
  default as en_us,
  default as lt,
} from "antd/es/date-picker/locale/en_US";
import et from "antd/es/date-picker/locale/et_EE";
import lv from "antd/es/date-picker/locale/lv_LV";
import { PickerLocale } from "antd/lib/date-picker/generatePicker";
import { VLLocales } from "store/contentInterfaces";

const localeMap: { [key in VLLocales]: PickerLocale } = {
  [VLLocales.NO_LOCALE]: en_us,
  [VLLocales.EN_US]: en_us,
  [VLLocales.ET]: et,
  [VLLocales.LV]: lv,
  [VLLocales.LT]: lt,
};

export default (locale: VLLocales): PickerLocale => localeMap[locale];
