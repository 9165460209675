import { FolderScope } from "components/organizations/FolderScopeForm";
import { WithFolderId } from "navigation/Paths";
import { pluck } from "ramda";

export default ({
  folderScopes,
  expires,
  ...scope
}: Omit<Omit<FolderScope, "folderIds">, "expires"> & {
  folderScopes: WithFolderId[];
  expires?: string | null;
}): FolderScope => {
  return {
    ...scope,
    expires: expires ? new Date(expires) : null,
    folderIds: pluck("folderId", folderScopes),
  };
};
