import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";
import { SimpleText } from "components/common/text/SimpleText";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import React from "react";
import { useHistory } from "react-router-dom";
import useAssetActions from "./hooks/useAssetActions";

interface DeleteAssetsModalProps extends ModalProps {
  assetIds: string[];
  onDone?: () => void;
}

export default ({
  assetIds,
  onDone = () => {},
  ...props
}: DeleteAssetsModalProps) => {
  const history = useHistory();
  const {
    moveToBinAction: [moveToBin, { loading }],
  } = useAssetActions();

  const onModalOk = async () => {
    await moveToBin({ assetIds });
    onDone();
    history.push(Paths.binnedMediaAssets.withParams());
  };

  return (
    <Modal
      onOk={onModalOk}
      onCancel={onDone}
      cancelText={strings("common.no")}
      okText={strings("common.yes")}
      okButtonProps={{ loading }}
      {...props}
    >
      <SimpleText t="folderInfo.deleteConfirm" />
    </Modal>
  );
};
