import { useEffect, useState } from "react";
import createTuple from "types/createTuple";
import { isDefined } from "types/predicates";
import removeEmptyValues from "../functions/removeEmptyValues";
import schemaToForm from "../functions/schemaToForm";
import validateForm, {
  FormFieldValidationError,
} from "../functions/validateForm";
import { FormObjectType } from "../types";

interface UseGqlForm<T> {
  schema: string;
  initialValue?: Partial<T>;
}

export default <T = any>({ schema, initialValue }: UseGqlForm<T>) => {
  const errorsState = useState<FormFieldValidationError[]>([]);
  const [form] = useState<FormObjectType>(schemaToForm(schema));
  const [values, setValues] = useState<Partial<T>>({});

  useEffect(() => {
    if (isDefined(initialValue)) {
      setValues(initialValue);
    } else {
      setValues({});
    }
    // eslint-disable-next-line
  }, [JSON.stringify(initialValue)]);

  const validate = (): T | undefined => {
    const validationErrors = validateForm(
      form,
      removeEmptyValues(values) || {},
    );
    errorsState[1](validationErrors);
    if (validationErrors.length === 0) {
      return values as T;
    }
    return;
  };

  return {
    validate,
    errorsState,
    form,
    valuesState: createTuple(values, setValues),
  };
};
