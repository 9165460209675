import { List } from "antd";
import useArchiveDownloads from "appGateway/hooks/useArchiveDownloads";
import { Row, SpreadRow } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import FileTypeIcon from "components/common/upload/FileTypeIcon";
import VLIcon from "components/common/VLIcon";
import NotificationBox from "components/notificationBox/NotificationBox";
import { pluck } from "ramda";
import React, { useEffect } from "react";
import DownloadState from "state/DownloadState";
import {
  TranscoderJobStatusTypesEnum,
  useMyArchiveJobsSubscription,
} from "__gen__/appService";

interface Archive {
  id: string;
  outputName: string;
  status: TranscoderJobStatusTypesEnum;
}

export default () => {
  const {
    pendingArchives,
    removePendingArchives,
  } = DownloadState.useContainer();
  const { data } = useMyArchiveJobsSubscription({
    archiveIds: pendingArchives,
  });
  const { downloadArchives } = useArchiveDownloads();

  const archives = (data && data.archives) || [];

  useEffect(() => {
    const finishedArchives = archives.filter(
      ({ status }) => status === TranscoderJobStatusTypesEnum.FINISHED,
    );
    archives.forEach(({ status, error }) => {
      if (status === TranscoderJobStatusTypesEnum.FAILED) {
        // eslint-disable-next-line no-console
        console.error(`Error when archiving files`, error);
      }
    });
    const finishedArchiveIds = pluck("id", finishedArchives);
    removePendingArchives(finishedArchiveIds);
    downloadArchives({ archiveIds: finishedArchiveIds });
    // eslint-disable-next-line
  }, [JSON.stringify(archives)]);

  return (
    <NotificationBox
      title="Zipping files for download"
      isClosed={archives.length === 0}
    >
      <List<Archive>
        dataSource={archives}
        renderItem={({ id, outputName, status }) => (
          <List.Item key={id}>
            <SpreadRow>
              <Row crossAxis="center">
                <FileTypeIcon size="small" type="application/zip" />
                <SimpleText marginHorizontal="medium">{outputName}</SimpleText>
              </Row>
              {status === TranscoderJobStatusTypesEnum.FAILED ? (
                <VLIcon
                  type="close-circle"
                  color="error"
                  onClick={() => removePendingArchives([id])}
                />
              ) : (
                <VLIcon type="loading" spin />
              )}
            </SpreadRow>
          </List.Item>
        )}
      />
    </NotificationBox>
  );
};
