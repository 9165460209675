import { SimpleText } from "components/common/text/SimpleText";
import useDateFns from "components/hooks/useDateFns";
import React from "react";
import FallbackText from "./FallbackText";

export default ({
  value,
  fallbackValue,
}: {
  value?: string;
  fallbackValue?: string;
}) => {
  const { formatDate } = useDateFns();

  return value ? (
    <SimpleText>{formatDate(new Date(value))}</SimpleText>
  ) : (
    <FallbackText>
      {fallbackValue ? formatDate(new Date(fallbackValue)) : ""}
    </FallbackText>
  );
};
