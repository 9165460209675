import { Breadcrumb } from "antd";
import { queryStringToGraphql } from "components/search/serializers";
import Paths from "navigation/Paths";
import { isEmpty } from "ramda";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import FolderStore from "store/FolderStore";

const FolderName = ({
  isFirst,
  isLink,
  id,
  name,
}: {
  isFirst: boolean;
  isLink: boolean;
  id: string;
  name: string;
}) => {
  if (isFirst) {
    return <Link to={Paths.orgMediaAssets.withParams()}>{name}</Link>;
  }
  if (!isLink) {
    return <>{name}</>;
  }
  return (
    <Link to={Paths.folderMediaAssets.withParams({ folderId: id })}>
      {name}
    </Link>
  );
};

interface FolderBreadcrumbsProps {
  folderId?: string;
  noSearch?: boolean;
}

export default ({ folderId, noSearch = false }: FolderBreadcrumbsProps) => {
  const { getFolderPath } = FolderStore.useContainer();
  const location = useLocation();
  const searchQuery = queryStringToGraphql(location.search);
  const hasSearch = !noSearch && !isEmpty(searchQuery);

  const path = getFolderPath(folderId || null);

  return (
    <Breadcrumb>
      {path.map(({ id, name }, index) => (
        <Breadcrumb.Item key={id}>
          <FolderName
            isFirst={index === 0}
            isLink={hasSearch || noSearch || index < path.length - 1}
            id={id}
            name={name}
          />
        </Breadcrumb.Item>
      ))}
      {hasSearch && <Breadcrumb.Item>Search</Breadcrumb.Item>}
    </Breadcrumb>
  );
};
