import { SimpleObjectType } from "@vldev/simple-type/lib/v2";
import { SimpleText } from "components/common/text/SimpleText";
import { assoc, fromPairs, prop } from "ramda";
import React, { Fragment } from "react";
import TypeInput, { transformAnyType, validateAnyType } from "../TypeInput";
import {
  AsyncValidationFunction,
  InputProps,
  TransformFunction,
  ValidationResult,
} from "../types";

export const validateObject: AsyncValidationFunction<
  any,
  SimpleObjectType
> = async ({ value, schemaType, typeMap, dataPath }) => {
  const promises = schemaType.fields.map(async (field) => {
    return await validateAnyType({
      value: prop(field.name, value),
      schemaType: field.type,
      typeMap,
      dataPath: [...dataPath, field.name],
    });
  });

  const fieldValidationResults = await Promise.all(promises);
  return fieldValidationResults.reduce<ValidationResult>(
    (accumulator, validationResult, index) => {
      const fieldName = schemaType.fields[index].name;
      return {
        filteredData: assoc(
          fieldName,
          validationResult.filteredData,
          accumulator.filteredData,
        ),
        errors: [...accumulator.errors, ...validationResult.errors],
        unhandledErrors: [
          ...accumulator.unhandledErrors,
          ...validationResult.unhandledErrors,
        ],
      };
    },
    { filteredData: {}, errors: [], unhandledErrors: [] },
  );
};

export const transformObject: TransformFunction<
  any,
  any,
  SimpleObjectType
> = async ({ value, schemaType }) => {
  const promises = schemaType.fields.map(async (field) => {
    return [
      field.name,
      await transformAnyType({
        value: prop(field.name, value),
        schemaType: field.type,
      }),
    ] as const;
  });
  const fieldPairs = await Promise.all(promises);
  return fromPairs(fieldPairs as any);
};

export default function ObjectInput(props: InputProps<any, SimpleObjectType>) {
  const { schemaType, dataPath } = props;

  return (
    <>
      {schemaType.fields.map((field) => {
        return (
          <Fragment key={field.name}>
            <SimpleText>{field.description || field.name}</SimpleText>
            <TypeInput
              {...props}
              value={prop(field.name, props.value)}
              onValueChange={(value) => {
                props.onValueChange(assoc(field.name, value, props.value));
              }}
              schemaType={field.type}
              dataPath={[...dataPath, field.name]}
            />
          </Fragment>
        );
      })}
    </>
  );
}
