import CopyButton from "components/common/buttons/CopyButton";
import { Column, Row } from "components/common/layout/Flex";
import VLCard from "components/common/layout/VLCard";
import React from "react";
import styled from "styled-components";
import directivesToObject from "../functions/directivesToObject";
import FieldDescription from "../inputs/FieldDescription";
import { ResourceInputProps } from "../inputs/types";

interface EmbedCodeParams {
  baseUrl: string;
  contentId: string;
}

const createEmbedCode = ({ baseUrl, contentId }: EmbedCodeParams) => {
  return `<div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;">
  <iframe src="${baseUrl}/${contentId}" style="position: absolute; top:0; left: 0; width: 100%; height: 100%;" frameborder="0" scrolling="no" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen="true" class="noTransform">
  </iframe>
</div>`;
};

const CodeArea = styled.pre`
  white-space: pre-wrap;
  overflow-wrap: break-word;
  margin: 0;
`;

export default (props: ResourceInputProps<string>) => {
  const { fieldConfig, contentRow } = props;
  const directives = directivesToObject(fieldConfig?.astNode?.directives);

  const baseUrl = directives.input?.baseUrl;
  const contentId = contentRow?.id;

  if (!baseUrl || !contentId) {
    return null;
  }

  const embedCode = createEmbedCode({ baseUrl, contentId });

  return (
    <Column>
      <Row
        grow={0}
        marginVertical="small"
        mainAxis="space-between"
        crossAxis="flex-end"
      >
        <FieldDescription {...props} isPreview />
        <CopyButton
          textToCopy={embedCode}
          t="common.copy"
          icon="copy"
          afterCopyMessage="Embed code copied"
        />
      </Row>
      <VLCard style={{ position: "relative" }}>
        <CodeArea>{embedCode}</CodeArea>
      </VLCard>
    </Column>
  );
};
