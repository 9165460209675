import AppContainer from "components/common/layout/AppContainer";
import LoadingScreen from "components/common/layout/LoadingScreen";
import LoginForm from "components/login/LoginForm";
import Paths from "navigation/Paths";
import React from "react";
import { Redirect } from "react-router";
import AuthState from "state/AuthState";

const LoginScreen = () => {
  const { authState } = AuthState.useContainer();

  if (authState.status === "loading") {
    return <LoadingScreen />;
  }

  if (authState.status === "loggedIn") {
    return <Redirect to={Paths.dashboard.path} />;
  }

  return (
    <AppContainer oppositeTheme>
      <LoginForm />
    </AppContainer>
  );
};

export default LoginScreen;
