import { Row } from "components/common/layout/Flex";
import VLSelect from "components/common/select/VLSelect";
import { contains, values } from "ramda";
import React, { useEffect } from "react";
import ContentStore from "store/ContentStore";
import { isEnumType } from "./functions/typePredicates";
import useProjectLanguages from "./hooks/useProjectLanguages";

export default () => {
  const { getContentType } = ContentStore.useContainer();
  const projectComposer = getContentType("VlProject");
  const {
    defaultLanguage,
    projectLanguages,
    selectedLanguage,
    setSelectedLanguage,
  } = useProjectLanguages();

  const languageComposer = projectComposer?.schemaComposer.getAnyTC(
    "VlLanguage",
  );

  useEffect(() => {
    if (
      !contains(selectedLanguage, projectLanguages || []) &&
      projectLanguages?.length &&
      defaultLanguage
    ) {
      setSelectedLanguage(defaultLanguage);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(projectLanguages), defaultLanguage]);

  if (!languageComposer || !isEnumType(languageComposer) || !projectLanguages) {
    return null;
  }

  const options = values(languageComposer.getFields()).filter((field) =>
    contains(field.value, projectLanguages),
  );

  return (
    <Row grow={0} mainAxis="flex-end" marginVertical="medium">
      <VLSelect
        selectedOption={options.find(
          (option) => option.value === selectedLanguage,
        )}
        style={{ width: 200 }}
        options={options}
        onOptionSelect={(option) => setSelectedLanguage(option.value)}
        optionLabel={(field) => field.description || field.value}
      />
    </Row>
  );
};
