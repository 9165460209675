import { Breadcrumb } from "antd";
import { SimpleText } from "components/common/text/SimpleText";
import { slice } from "ramda";
import React from "react";
import ContentStore from "store/ContentStore";
import styled from "styled-components";
import { isDefined } from "types/predicates";
import useDataResolvers from "./hooks/useDataResolvers";

const BreadcrumbContainer = styled(Breadcrumb)`
  margin: ${({ theme }) => theme.margin.medium};
`;

const ContentLink = styled(SimpleText)`
  color: ${({ theme }) => theme.color.foreground.tertiary};
  cursor: pointer;
`;

interface ContentBreadcrumbsProps {
  contentPath: string[];
  onPathChange: (newPath: string[]) => void;
}

export default ({ contentPath, onPathChange }: ContentBreadcrumbsProps) => {
  const { getContentRow } = ContentStore.useContainer();
  const { resolveFallback } = useDataResolvers(["name"]);

  const contentNames = contentPath
    .map(getContentRow)
    .filter(isDefined)
    .map((row) => {
      const name = resolveFallback<string>(row);
      if (!name) {
        return undefined;
      }
      return { id: row.id, name };
    })
    .filter(isDefined);

  const onItemClick = (id: string) => {
    const index = contentPath.indexOf(id);
    onPathChange(slice(0, index + 1, contentPath));
  };

  return (
    <BreadcrumbContainer>
      {contentNames.map(({ id, name }, index) => {
        const isLast = index === contentNames.length - 1;
        return (
          <Breadcrumb.Item key={id}>
            {isLast ? (
              <SimpleText bold>{name}</SimpleText>
            ) : (
              <ContentLink bold onClick={() => onItemClick(id)}>
                {name}
              </ContentLink>
            )}
          </Breadcrumb.Item>
        );
      })}
    </BreadcrumbContainer>
  );
};
