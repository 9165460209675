import DescriptionItem from "components/common/descriptions/DescriptionItem";
import { Row, SpreadRow } from "components/common/layout/Flex";
import { MarginedDivider } from "components/common/layout/MarginedDivider";
import VLSelect, { VLSelectProps } from "components/common/select/VLSelect";
import { SimpleText } from "components/common/text/SimpleText";
import ContentStatisticsModal from "components/contentStatistics/ContentStatisticsModal";
import ContentTickets from "components/ticketing/ContentTickets";
import { format } from "date-fns";
import strings from "localisation/strings";
import { contains } from "ramda";
import React, { FC, useState } from "react";
import ContentSidebarState from "state/ContentSidebarState";
import {
  hasTickets,
  isNoLocaleType,
  VLContentScalars,
  VLDirectives,
  VLLocales,
} from "store/contentInterfaces";
import ContentStore from "store/ContentStore";
import createContentRow from "store/functions/createContentRow";
import styled from "styled-components";
import AccessCodeRestrictions from "./AccessCodeRestrictions";
import EditableContent from "./EditableContent";
import EmbedLink from "./EmbedLink";
import shouldShowEmbedCode from "./functions/shouldShowEmbedCode";

const LocaleSelect = styled(VLSelect)`
  min-width: 80px;
`;

export default () => {
  const {
    getContentRow,
    getContentType,
    upsertRow,
    defaultLocale,
    projectLocales,
    projectData,
  } = ContentStore.useContainer();
  const { selectedContentId, selectedContentType } =
    ContentSidebarState.useContainer();
  const [selectedLocale, setSelectedLocale] = useState(defaultLocale);

  const contentType = getContentType(selectedContentType);
  const GMT = format(new Date(), "z");

  if (!contentType) {
    return <SimpleText t="contentDetails.noContentType" />;
  }

  const locale = isNoLocaleType(contentType)
    ? VLLocales.NO_LOCALE
    : selectedLocale;

  const contentRow =
    getContentRow(selectedContentId) ||
    createContentRow({
      id: selectedContentId,
      type: selectedContentType,
    });
  const fieldNames = contentType.getFieldNames();

  let labelExtra = "";

  const noLocale = isNoLocaleType(contentType);

  return (
    <>
      {!noLocale && (
        <Row mainAxis="flex-end" marginVertical="medium" grow={0}>
          <LocaleSelect<FC<VLSelectProps<VLLocales>>>
            options={projectLocales}
            optionLabel={(locale) => locale}
            onOptionSelect={setSelectedLocale}
            value={locale}
          />
        </Row>
      )}
      <SpreadRow crossAxis="center">
        <DescriptionItem
          marginVertical="medium"
          label={strings("contentDetails.contentId")}
        >
          <SimpleText color="tertiary">{contentRow.id}</SimpleText>
        </DescriptionItem>
        <ContentStatisticsModal contentId={contentRow.id} />
      </SpreadRow>
      {shouldShowEmbedCode(contentType) && (
        <DescriptionItem
          marginVertical="medium"
          label={strings("contentDetails.embedCode")}
        >
          <EmbedLink contentId={contentRow.id} project={projectData?.project} />
        </DescriptionItem>
      )}
      {fieldNames.map((fieldName) => {
        const fieldType = contentType.getFieldTC(fieldName).getTypeName();
        if (fieldType === VLContentScalars.VLDateTime) {
          labelExtra = `(${GMT})`;
        }
        return (
          <DescriptionItem
            key={fieldName}
            requiredLabel={contentType.isFieldNonNull(fieldName)}
            marginVertical="small"
            label={[
              contentType.getField(fieldName).description || "",
              labelExtra,
            ].join(" ")}
          >
            <EditableContent
              key={`${contentRow.id}/${fieldName}`}
              locale={locale}
              defaultLocale={defaultLocale}
              fieldName={fieldName}
              contentRow={contentRow}
              onContentChanged={upsertRow}
              fieldType={fieldType}
            />
          </DescriptionItem>
        );
      })}
      {contains(
        VLDirectives.MayBeAccessCodeRestricted,
        contentType.getDirectiveNames(),
      ) && (
        <>
          <MarginedDivider margin="small" />
          <AccessCodeRestrictions
            contentRow={contentRow}
            onContentChanged={upsertRow}
          />
        </>
      )}
      {hasTickets(contentType) && <ContentTickets contentId={contentRow.id} />}
    </>
  );
};
