import I18n from "i18n-js";
import moment from "moment";

import en from "./en.json";
import "moment/locale/en-gb";

I18n.fallbacks = true;
I18n.defaultLocale = "en";

/*
  Without this it could cause a runtime crash.
  See https://github.com/moment/moment/issues/3624
  Another option would be to require locales dynamically as per:
  https://github.com/facebook/react-native/issues/1629#issuecomment-162881175
*/
const chooseMomentLocale = (desiredLocale: string) => {
  const locale = desiredLocale.toLowerCase();
  const shortLocale = locale.substring(0, 2);
  if (-1 < moment.locales().indexOf(locale)) {
    return locale;
  }
  if (-1 < moment.locales().indexOf(shortLocale)) {
    return shortLocale;
  }
  return "en-gb";
};

I18n.translations = {
  en,
};

export { I18n, moment, chooseMomentLocale };
