import { Checkbox } from "antd";
import { Row } from "components/common/layout/Flex";
import VLSelect from "components/common/select/VLSelect";
import { EnumTypeComposer } from "graphql-compose";
import { append, contains, dropLast, values, without } from "ramda";
import React, { useEffect } from "react";
import directivesToObject from "../functions/directivesToObject";
import InputWrapper from "./InputWrapper";
import { ResourceInputProps } from "./types";

export default (
  props: ResourceInputProps<string | string[], EnumTypeComposer>,
) => {
  const {
    composer,
    dataPath,
    onValueSaved,
    fieldConfig,
    isList,
    locale,
    resolveValue,
    resolveFallback,
  } = props;

  const value = resolveValue({ locale, dataPath });
  const fallbackValue = resolveFallback({ locale, dataPath });

  const directives = directivesToObject(fieldConfig?.astNode?.directives);

  const isCheckboxList = !!isList && directives.input?.type === "checkbox";
  const oneOfChecksField = directives.oneOf?.field;

  const applicableOptionValues = oneOfChecksField
    ? (resolveValue({
        locale,
        dataPath: [...dropLast(1, dataPath), oneOfChecksField],
      }) as any) || []
    : undefined;

  const fields = values(composer.getFields());
  const options = Array.isArray(applicableOptionValues)
    ? fields.filter((field) => contains(field.value, applicableOptionValues))
    : fields;
  const placeholderOption = options.find(
    (field) => field.value === fallbackValue,
  );

  useEffect(() => {
    if (applicableOptionValues && !contains(value, applicableOptionValues)) {
      onValueSaved({ value: applicableOptionValues[0], dataPath, locale });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(applicableOptionValues)]);

  return (
    <InputWrapper {...props}>
      {!isCheckboxList ? (
        <VLSelect
          disabled={options.length === 0}
          selectedOption={options.find((field) => field.value === value)}
          placeholder={
            placeholderOption?.description || placeholderOption?.value
          }
          style={{ width: 200 }}
          options={options}
          onOptionSelect={(field) =>
            onValueSaved({ value: field.value, dataPath, locale })
          }
          optionLabel={(field) => field.description || field.value}
        />
      ) : (
        <Row>
          {options.map((field) => {
            const arrayValue = Array.isArray(value) ? value : [];
            const checked = contains(field.value, arrayValue);
            const onChange = () => {
              const newValue = checked
                ? without(field.value, arrayValue)
                : append(field.value, arrayValue);
              onValueSaved({ value: newValue, dataPath, locale });
            };

            return (
              <Checkbox checked={checked} onChange={onChange} key={field.value}>
                {field.description || field.value}
              </Checkbox>
            );
          })}
        </Row>
      )}
    </InputWrapper>
  );
};
