import React, { useEffect } from "react";
import styled, { css } from "styled-components";

const closedCss = css`
  opacity: 0;
  pointer-events: none;
`;

const Container = styled.div<{ closed: boolean }>`
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  transition: opacity 0.1s ease-in;
  opacity: 1;
  ${({ closed }) => (closed ? closedCss : "")};
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.color.background.opposite};
  opacity: 0.4;
`;

const Frame = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: zoom-out;
`;

const Image = styled.img`
  object-fit: contain;
  background-color: ${({ theme }) => theme.color.background.primary};
  max-height: 98%;
`;

interface ImagePreviewModalProps {
  src: string;
  alt: string;
  onClose: () => void;
  closed: boolean;
}

export default ({ src, alt, onClose, closed }: ImagePreviewModalProps) => {
  useEffect(() => {
    const escFunction = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        onClose();
        event.stopPropagation();
      }
    };
    document.addEventListener("keydown", escFunction, false);
    return () => document.removeEventListener("keydown", escFunction, false);
  });

  return (
    <Container closed={closed}>
      <Overlay />
      <Frame onClick={onClose}>
        <Image alt={alt} src={src} width="auto" height="auto" />
      </Frame>
    </Container>
  );
};
