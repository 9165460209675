import head from "functions/head";
import { pluck, toPairs } from "ramda";
import {
  AssetMetadataValuesSubscription,
  MetaFieldValuesInsertInput,
} from "__gen__/appService";
import { FieldValues } from "./MetaFieldInputTypes";

const toValue = <T>(value: T) => ({ value });

export const stateToInsertValues = (
  state: AssetMetaFieldsState,
  commonVariables?: MetaFieldValuesInsertInput,
): MetaFieldValuesInsertInput[] => {
  const insertValues = toPairs(state).map<MetaFieldValuesInsertInput>(
    ([fieldId, { booleanValues, dateValues, numberValues, textValues }]) => {
      return {
        ...commonVariables,
        field_id: fieldId,
        boolean_values: {
          data: (booleanValues || []).map(toValue),
        },
        text_values: {
          data: (textValues || []).map(toValue),
        },
        number_values: {
          data: (numberValues || []).map(toValue),
        },
        date_values: {
          data: (dateValues || []).map((date) => ({
            value: date.toISOString(),
          })),
        },
      };
    },
  );
  return insertValues;
};

export const initialValuesToState = (
  fields: AssetMetadataValuesSubscription["metadataFields"],
): AssetMetaFieldsState => {
  const initialState: AssetMetaFieldsState = fields.reduce<
    AssetMetaFieldsState
  >((acc, field) => {
    const values = head(field.metadataValues);
    const booleanValues = pluck("value", values?.booleanValues || []);
    const dateValues = (values?.dateValues || []).map(
      ({ value }) => new Date(value),
    );
    const textValues = pluck("value", values?.textValues || []);
    const numberValues = pluck("value", values?.numberValues || []);
    return {
      ...acc,
      [field.id]: {
        booleanValues,
        dateValues,
        textValues,
        numberValues,
      },
    };
  }, {});
  return initialState;
};

export const emptyValues: FieldValues = {
  booleanValues: [],
  dateValues: [],
  numberValues: [],
  textValues: [],
};

export interface AssetMetaFieldsState {
  [fieldId: string]: FieldValues;
}
