import gql from "graphql-tag";
import * as ApolloReactHooks from "components/hooks/apollo";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
}

export interface Mutation {
  login: User;
}

export interface MutationLoginArgs {
  token: Scalars["String"];
}

export interface Query {
  s3SignedUrl: Scalars["String"];
  s3SignedUrls: Array<SignedAsset>;
  s3DownloadUrl: Scalars["String"];
  s3PublicUploadUrl: S3PublicUpload;
  archiveDownloadUrls: Array<SignedAsset>;
  defaultWarehouseId: Scalars["String"];
}

export interface QueryS3SignedUrlArgs {
  assetFileId: Scalars["String"];
}

export interface QueryS3SignedUrlsArgs {
  assetFileIds: Array<Scalars["String"]>;
}

export interface QueryS3DownloadUrlArgs {
  assetFileId: Scalars["String"];
  fileName?: Maybe<Scalars["String"]>;
}

export interface QueryS3PublicUploadUrlArgs {
  orgId: Scalars["String"];
  keySuffix: Scalars["String"];
  fileType: Scalars["String"];
}

export interface QueryArchiveDownloadUrlsArgs {
  archiveJobIds: Array<Scalars["String"]>;
}

export interface S3PublicUpload {
  signedUrl: Scalars["String"];
  key: Scalars["String"];
  warehouseId: Scalars["String"];
  resizingServiceUrl?: Maybe<Scalars["String"]>;
}

export interface SignedAsset {
  id: Scalars["String"];
  signedUrl: Scalars["String"];
}

export interface UploadFileMeta {
  orgId: Scalars["String"];
  folderId: Scalars["String"];
  mediaAssetId: Scalars["String"];
}

export interface User {
  isVlAdmin: Scalars["Boolean"];
  id: Scalars["String"];
  roles: Array<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  photoUrl?: Maybe<Scalars["String"]>;
}

export enum VlApiErrorTypes {
  TOKEN_REFRESH_NEEDED = "TOKEN_REFRESH_NEEDED",
  INVALID_TOKEN = "INVALID_TOKEN",
  LOGIN_FAILED = "LOGIN_FAILED",
  FILE_NOT_FOUND = "FILE_NOT_FOUND",
  NOT_ALLOWED = "NOT_ALLOWED",
}

export type ArchiveDownloadUrlsQueryVariables = Exact<{
  archiveJobIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type ArchiveDownloadUrlsQuery = {
  archiveDownloadUrls: Array<{ id: string; signedUrl: string }>;
};

export type DefaultWarehouseIdQueryVariables = Exact<{ [key: string]: never }>;

export type DefaultWarehouseIdQuery = { defaultWarehouseId: string };

export type GatewayLoginMutationVariables = Exact<{
  token: Scalars["String"];
}>;

export type GatewayLoginMutation = {
  user: {
    isVlAdmin: boolean;
    id: string;
    roles: Array<string>;
    email?: Maybe<string>;
    name?: Maybe<string>;
    photoUrl?: Maybe<string>;
  };
};

export type S3DownloadUrlQueryVariables = Exact<{
  assetFileId: Scalars["String"];
  fileName?: Maybe<Scalars["String"]>;
}>;

export type S3DownloadUrlQuery = { s3DownloadUrl: string };

export type S3PublicUploadQueryVariables = Exact<{
  orgId: Scalars["String"];
  keySuffix: Scalars["String"];
  fileType: Scalars["String"];
}>;

export type S3PublicUploadQuery = {
  s3PublicUploadUrl: {
    signedUrl: string;
    key: string;
    warehouseId: string;
    resizingServiceUrl?: Maybe<string>;
  };
};

export type S3SignedUrlQueryVariables = Exact<{
  assetFileId: Scalars["String"];
}>;

export type S3SignedUrlQuery = { s3SignedUrl: string };

export type S3SignedUrlsQueryVariables = Exact<{
  assetFileIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type S3SignedUrlsQuery = {
  s3SignedUrls: Array<{ id: string; signedUrl: string }>;
};

export const ArchiveDownloadUrlsDocument = gql`
  query ArchiveDownloadUrls($archiveJobIds: [String!]!) {
    archiveDownloadUrls(archiveJobIds: $archiveJobIds) {
      id
      signedUrl
    }
  }
`;
export function useArchiveDownloadUrlsQuery(
  variables: ArchiveDownloadUrlsQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ArchiveDownloadUrlsQuery,
    ArchiveDownloadUrlsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    ArchiveDownloadUrlsQuery,
    ArchiveDownloadUrlsQueryVariables
  >(ArchiveDownloadUrlsDocument, { variables, ...baseOptions });
}
export function useArchiveDownloadUrlsLazyQuery(
  variables?: ArchiveDownloadUrlsQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ArchiveDownloadUrlsQuery,
    ArchiveDownloadUrlsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    ArchiveDownloadUrlsQuery,
    ArchiveDownloadUrlsQueryVariables
  >(ArchiveDownloadUrlsDocument, { variables, ...baseOptions });
}
export const DefaultWarehouseIdDocument = gql`
  query DefaultWarehouseId {
    defaultWarehouseId
  }
`;
export function useDefaultWarehouseIdQuery(
  variables: DefaultWarehouseIdQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DefaultWarehouseIdQuery,
    DefaultWarehouseIdQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    DefaultWarehouseIdQuery,
    DefaultWarehouseIdQueryVariables
  >(DefaultWarehouseIdDocument, { variables, ...baseOptions });
}
export function useDefaultWarehouseIdLazyQuery(
  variables?: DefaultWarehouseIdQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DefaultWarehouseIdQuery,
    DefaultWarehouseIdQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    DefaultWarehouseIdQuery,
    DefaultWarehouseIdQueryVariables
  >(DefaultWarehouseIdDocument, { variables, ...baseOptions });
}
export const GatewayLoginDocument = gql`
  mutation GatewayLogin($token: String!) {
    user: login(token: $token) {
      isVlAdmin
      id
      roles
      email
      name
      photoUrl
    }
  }
`;
export function useGatewayLoginMutation(
  variables?: GatewayLoginMutationVariables,
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GatewayLoginMutation,
    GatewayLoginMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    GatewayLoginMutation,
    GatewayLoginMutationVariables
  >(GatewayLoginDocument, { variables, ...baseOptions });
}
export const S3DownloadUrlDocument = gql`
  query S3DownloadUrl($assetFileId: String!, $fileName: String) {
    s3DownloadUrl(assetFileId: $assetFileId, fileName: $fileName)
  }
`;
export function useS3DownloadUrlQuery(
  variables: S3DownloadUrlQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    S3DownloadUrlQuery,
    S3DownloadUrlQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    S3DownloadUrlQuery,
    S3DownloadUrlQueryVariables
  >(S3DownloadUrlDocument, { variables, ...baseOptions });
}
export function useS3DownloadUrlLazyQuery(
  variables?: S3DownloadUrlQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    S3DownloadUrlQuery,
    S3DownloadUrlQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    S3DownloadUrlQuery,
    S3DownloadUrlQueryVariables
  >(S3DownloadUrlDocument, { variables, ...baseOptions });
}
export const S3PublicUploadDocument = gql`
  query S3PublicUpload(
    $orgId: String!
    $keySuffix: String!
    $fileType: String!
  ) {
    s3PublicUploadUrl(
      orgId: $orgId
      keySuffix: $keySuffix
      fileType: $fileType
    ) {
      signedUrl
      key
      warehouseId
      resizingServiceUrl
    }
  }
`;
export function useS3PublicUploadQuery(
  variables: S3PublicUploadQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    S3PublicUploadQuery,
    S3PublicUploadQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    S3PublicUploadQuery,
    S3PublicUploadQueryVariables
  >(S3PublicUploadDocument, { variables, ...baseOptions });
}
export function useS3PublicUploadLazyQuery(
  variables?: S3PublicUploadQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    S3PublicUploadQuery,
    S3PublicUploadQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    S3PublicUploadQuery,
    S3PublicUploadQueryVariables
  >(S3PublicUploadDocument, { variables, ...baseOptions });
}
export const S3SignedUrlDocument = gql`
  query S3SignedUrl($assetFileId: String!) {
    s3SignedUrl(assetFileId: $assetFileId)
  }
`;
export function useS3SignedUrlQuery(
  variables: S3SignedUrlQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    S3SignedUrlQuery,
    S3SignedUrlQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<S3SignedUrlQuery, S3SignedUrlQueryVariables>(
    S3SignedUrlDocument,
    { variables, ...baseOptions },
  );
}
export function useS3SignedUrlLazyQuery(
  variables?: S3SignedUrlQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    S3SignedUrlQuery,
    S3SignedUrlQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    S3SignedUrlQuery,
    S3SignedUrlQueryVariables
  >(S3SignedUrlDocument, { variables, ...baseOptions });
}
export const S3SignedUrlsDocument = gql`
  query S3SignedUrls($assetFileIds: [String!]!) {
    s3SignedUrls(assetFileIds: $assetFileIds) {
      id
      signedUrl
    }
  }
`;
export function useS3SignedUrlsQuery(
  variables: S3SignedUrlsQueryVariables,
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    S3SignedUrlsQuery,
    S3SignedUrlsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    S3SignedUrlsQuery,
    S3SignedUrlsQueryVariables
  >(S3SignedUrlsDocument, { variables, ...baseOptions });
}
export function useS3SignedUrlsLazyQuery(
  variables?: S3SignedUrlsQueryVariables,
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    S3SignedUrlsQuery,
    S3SignedUrlsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    S3SignedUrlsQuery,
    S3SignedUrlsQueryVariables
  >(S3SignedUrlsDocument, { variables, ...baseOptions });
}
