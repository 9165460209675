import VLButton, { VLButtonProps } from "components/common/buttons/VLButton";
import strings from "localisation/strings";
import React, { useState } from "react";
import AddProjectModal from "./AddProjectModal";

const AddProjectButton = (props: VLButtonProps) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => setModalVisible(true);

  const hideModal = () => setModalVisible(false);

  return (
    <>
      <VLButton type="primary" onClick={showModal} {...props}>
        {strings("projects.addNew")}
      </VLButton>
      <AddProjectModal visible={modalVisible} onDone={hideModal} />
    </>
  );
};

export default AddProjectButton;
