import VlSelect from "components/common/select/VLSelect";
import strings from "localisation/strings";
import React from "react";
import useComponentReferences from "./hooks/useComponentReferences";
import { VlComponentReference } from "./types";

interface VlComponentSelectProps {
  onComponentSelect: (componentReference: VlComponentReference) => void;
}

export default function VlComponentSelect({
  onComponentSelect,
}: VlComponentSelectProps) {
  const references = useComponentReferences();

  return (
    <VlSelect
      options={references}
      optionLabel={(option) => option.name}
      onOptionSelect={onComponentSelect}
      placeholder={strings("common.addComponent")}
      value={null}
    />
  );
}
