import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
}

export enum AuthErrorTypes {
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  APP_ID_MISSING = "APP_ID_MISSING",
  NO_SUCH_USER = "NO_SUCH_USER",
  INVALID_APP_ID = "INVALID_APP_ID",
  INVALID_EMAIL = "INVALID_EMAIL",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  DUPLICATE_EMAIL = "DUPLICATE_EMAIL",
  INVALID_REFRESH_TOKEN = "INVALID_REFRESH_TOKEN",
  INVALID_EMAIL_TEMPLATE_ID = "INVALID_EMAIL_TEMPLATE_ID",
  EMAIL_SENDING_FAILED = "EMAIL_SENDING_FAILED",
  EMAIL_VERIFICATION_REQUIRED = "EMAIL_VERIFICATION_REQUIRED",
  EMAIL_NOT_REGISTERED = "EMAIL_NOT_REGISTERED",
  INVALID_VERIFICATION_TOKEN = "INVALID_VERIFICATION_TOKEN",
  SERVICE_ACCOUNT_TOKEN_REQUIRED = "SERVICE_ACCOUNT_TOKEN_REQUIRED",
  SERVICE_ACCOUNT_NOT_FOUND = "SERVICE_ACCOUNT_NOT_FOUND",
  UPDATING_CUSTOM_CLAIMS_FAILED = "UPDATING_CUSTOM_CLAIMS_FAILED",
}

export interface AuthPayload {
  token: Scalars["String"];
}

export interface Mutation {
  signupEmailPassword: AuthPayload;
  loginEmailPassword: AuthPayload;
  changePassword: Scalars["Boolean"];
  resetPassword: Scalars["Boolean"];
  sendPasswordResetEmail: Scalars["Boolean"];
  authenticateWithOauthProvider: Scalars["String"];
  refreshAuthToken: AuthPayload;
  logout: Scalars["Boolean"];
  verifyEmail: AuthPayload;
  resendEmailVerification: Scalars["Boolean"];
  sendEmailByTemplateName: Scalars["Boolean"];
  setCustomClaims: Scalars["Boolean"];
}

export interface MutationSignupEmailPasswordArgs {
  email: Scalars["String"];
  password: Scalars["String"];
}

export interface MutationLoginEmailPasswordArgs {
  email: Scalars["String"];
  password: Scalars["String"];
  rememberUser?: Maybe<Scalars["Boolean"]>;
}

export interface MutationChangePasswordArgs {
  email: Scalars["String"];
  oldPassword: Scalars["String"];
  newPassword: Scalars["String"];
}

export interface MutationResetPasswordArgs {
  resetToken: Scalars["String"];
  newPassword: Scalars["String"];
}

export interface MutationSendPasswordResetEmailArgs {
  email: Scalars["String"];
}

export interface MutationAuthenticateWithOauthProviderArgs {
  providerType: Scalars["String"];
}

export interface MutationVerifyEmailArgs {
  verificationToken: Scalars["String"];
}

export interface MutationResendEmailVerificationArgs {
  email: Scalars["String"];
}

export interface MutationSendEmailByTemplateNameArgs {
  emailTo: Scalars["String"];
  templateName: Scalars["String"];
  emailContext: Scalars["JSONObject"];
}

export interface MutationSetCustomClaimsArgs {
  userId: Scalars["String"];
  customClaims: Scalars["JSONObject"];
}

export interface Query {
  hello?: Maybe<Scalars["String"]>;
  authorizeServiceAccount: AuthPayload;
  userEmails: Array<Scalars["String"]>;
}

export interface QueryAuthorizeServiceAccountArgs {
  clientId: Scalars["String"];
  clientSecret: Scalars["String"];
}

export interface QueryUserEmailsArgs {
  userId: Scalars["String"];
  verifiedOnly: Scalars["Boolean"];
}

export type EmailLoginMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
  rememberUser: Scalars["Boolean"];
}>;

export type EmailLoginMutation = { login: { token: string } };

export type EmailSignupMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
}>;

export type EmailSignupMutation = { signupEmailPassword: { token: string } };

export type RefreshAuthTokenMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshAuthTokenMutation = { refreshAuthToken: { token: string } };

export type ResetPasswordMutationVariables = Exact<{
  resetToken: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type ResetPasswordMutation = { resetSuccessful: boolean };

export type SendPasswordResetEmailMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type SendPasswordResetEmailMutation = { sent: boolean };

export type SignOutMutationVariables = Exact<{ [key: string]: never }>;

export type SignOutMutation = { logout: boolean };

export type VerifyEmailMutationVariables = Exact<{
  token: Scalars["String"];
}>;

export type VerifyEmailMutation = { verified: { token: string } };

export const EmailLoginDocument = gql`
  mutation EmailLogin(
    $email: String!
    $password: String!
    $rememberUser: Boolean!
  ) {
    login: loginEmailPassword(
      email: $email
      password: $password
      rememberUser: $rememberUser
    ) {
      token
    }
  }
`;
export const EmailSignupDocument = gql`
  mutation EmailSignup($email: String!, $password: String!) {
    signupEmailPassword(email: $email, password: $password) {
      token
    }
  }
`;
export const RefreshAuthTokenDocument = gql`
  mutation RefreshAuthToken {
    refreshAuthToken {
      token
    }
  }
`;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($resetToken: String!, $newPassword: String!) {
    resetSuccessful: resetPassword(
      resetToken: $resetToken
      newPassword: $newPassword
    )
  }
`;
export const SendPasswordResetEmailDocument = gql`
  mutation SendPasswordResetEmail($email: String!) {
    sent: sendPasswordResetEmail(email: $email)
  }
`;
export const SignOutDocument = gql`
  mutation SignOut {
    logout
  }
`;
export const VerifyEmailDocument = gql`
  mutation VerifyEmail($token: String!) {
    verified: verifyEmail(verificationToken: $token) {
      token
    }
  }
`;
