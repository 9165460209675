import { Col } from "antd";
import { ResourceInputProps } from "components/cmsV2/inputs/types";
import VLButton from "components/common/buttons/VLButton";
import { Row } from "components/common/layout/Flex";
import Margin from "components/common/layout/Margin";
import { assoc, equals } from "ramda";
import React, { useEffect, useState } from "react";
import { validateComponentProps } from "./ComponentEditor";
import { baseUrl } from "./hooks/useComponentReferences";
import {
  TranformedVlComponent,
  transformVlComponent,
} from "./inputs/VlComponentInput";
import PageEditor from "./PageEditor";
import PagePreview from "./PagePreview";
import { ValidationResult, VlComponentState } from "./types";

interface VlPage {
  state: VlComponentState;
  transformed: TranformedVlComponent;
}

export default function PageBuilder({
  onValueSaved,
  locale,
  dataPath: dataPathFromProps,
  resolveValue,
}: ResourceInputProps<VlPage>) {
  const [componentProps, setComponentProps] = useState<{}>({});
  const [filteredState, setFilteredState] = useState<VlComponentState>();
  const [validationResult, setValidationResult] = useState<ValidationResult>();
  const dataPath = [...dataPathFromProps, 0];
  const page: VlComponentState = {
    schemaUrl: `${baseUrl}/Page.props.gql`,
    svelteUrl: `${baseUrl}/Page.svelte.js`,
    props: componentProps,
    name: "Page",
  };
  const initialValue = resolveValue({ dataPath, locale });

  useEffect(() => {
    if (initialValue !== undefined) {
      setComponentProps(initialValue.state.props);
    }
  }, [JSON.stringify(initialValue)]);

  useEffect(() => {
    const validateResult = async () => {
      const newValidationResult = await runValidation();
      const newFilteredState = assoc(
        "props",
        newValidationResult.filteredData,
        page,
      );
      setFilteredState(newFilteredState);
    };
    validateResult();
  }, [JSON.stringify(componentProps)]);

  const onSave = async () => {
    const newValidationResult = await runValidation();
    const transformed = await transformVlComponent({ value: page });
    if (transformed !== undefined && newValidationResult.errors.length === 0) {
      onValueSaved({ value: { state: page, transformed }, dataPath, locale });
    }
  };

  const runValidation = async () => {
    const newValidationResult = await validateComponentProps(page, {
      dataPath: [],
    });
    setValidationResult(newValidationResult);
    return newValidationResult;
  };

  return (
    <>
      <Row>
        <Col span={initialValue ? 12 : 24}>
          <PageEditor
            onValueChange={setComponentProps}
            page={page}
            errors={validationResult?.errors || []}
          />
        </Col>
        {filteredState && (
          <Col span={12}>
            <PagePreview page={filteredState} />
          </Col>
        )}
      </Row>
      <Margin />
      <Row>
        <VLButton
          disabled={equals(initialValue?.state.props, componentProps)}
          onClick={onSave}
          t="buttons.save"
        />
      </Row>
    </>
  );
}
