import MediaAssetTypes from "components/common/upload/MediaAssetTypes";
import {
  MetaFieldValuesFragment,
  MediaAssetFragment,
} from "__gen__/appService";

export interface VLFile {
  id: string;
  name: string;
  mimeType: string;
  size: number;
}

export interface VLFolder {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: null | string;
  type: "VLFolder";
}

export interface VLMedia extends MediaAssetFragment {
  type: MediaAssetTypes;
  metadataValues?: MetaFieldValuesFragment[];
}

export type VLAsset = VLFolder | VLMedia;

export const isVlMedia = (asset: VLAsset): asset is VLMedia =>
  !!MediaAssetTypes[asset.type as MediaAssetTypes];
