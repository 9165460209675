import { MetadataFieldTypes } from "components/assetMetaSpecs/MetadataFieldTypes";
import React, { FunctionComponent } from "react";
import BooleanInput from "./BooleanInput";
import DateInput from "./DateInput";
import { MetaFieldInputProps } from "./MetaFieldInputTypes";
import MultipleChoiceInput from "./MultipleChoiceInput";
import NumberInput from "./NumberInput";
import SimpleTextInput from "./SimpleTextInput";
import SingleChoiceInput from "./SingleChoiceInput";
import TagListInput from "./TagListInput";

const inputComponentMap: {
  [i in keyof typeof MetadataFieldTypes]: FunctionComponent<
    MetaFieldInputProps
  >;
} = {
  [MetadataFieldTypes.BOOLEAN]: BooleanInput,
  [MetadataFieldTypes.DATE]: DateInput,
  [MetadataFieldTypes.MULTIPLE_CHOICE]: MultipleChoiceInput,
  [MetadataFieldTypes.NUMBER]: NumberInput,
  [MetadataFieldTypes.SIMPLE_TEXT]: SimpleTextInput,
  [MetadataFieldTypes.SINGLE_CHOICE]: SingleChoiceInput,
  [MetadataFieldTypes.TAG_LIST]: TagListInput,
};

export default (props: MetaFieldInputProps) => {
  const Component = inputComponentMap[props.field.type];

  return <Component {...props} />;
};
