import { SimpleText } from "components/common/text/SimpleText";
import Paths from "navigation/Paths";
import React from "react";
import { Link } from "react-router-dom";
import { OrgRole } from "./hooks/useOrgRoles";

interface RoleLinkProps {
  role: OrgRole;
  orgSlug: string;
  disabled?: boolean;
}

export default ({ role, orgSlug, disabled }: RoleLinkProps) => {
  if (disabled) {
    return <SimpleText>{role.name}</SimpleText>;
  }

  return (
    <Link to={Paths.orgRoleDetails.withParams({ orgSlug, roleId: role.id })}>
      {role.name}
    </Link>
  );
};
