import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { split } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import Env from "config/Env";
import { SubscriptionClient } from "subscriptions-transport-ws";

const getAuthServiceClient = () => {
  const httpLink = createHttpLink({
    uri: Env.REACT_APP_AM_HASURA_URL,
  });

  const wsClient = new SubscriptionClient(`${Env.REACT_APP_AM_HASURA_WS_URL}`, {
    reconnect: true,
  });
  const wsLink = new WebSocketLink(wsClient);

  const transportLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink,
  );

  return new ApolloClient({
    link: transportLink,
    cache: new InMemoryCache(),
  });
};

export const authServiceClient = getAuthServiceClient();
