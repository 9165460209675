import { DatePicker } from "antd";
import moment from "moment";
import React from "react";

interface EditableDateProps {
  initialValue?: string;
  onSave: (text?: string) => void;
  placeholder?: string;
}

export default ({ initialValue, onSave: onSaveDate }: EditableDateProps) => {
  return (
    <DatePicker
      allowClear={false}
      value={initialValue ? moment(initialValue) : undefined}
      showToday={false}
      onChange={(date) => {
        const dateString = date?.toISOString();
        onSaveDate(dateString);
      }}
      format="L"
    />
  );
};
