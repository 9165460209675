import { HTMLProps } from "react";
import { Divider } from "antd";
import { DividerProps } from "antd/lib/divider";
import globalTheme from "style/theme";
import styled from "styled-components";

interface MarginedDividerProps
  extends DividerProps,
    Omit<HTMLProps<HTMLDivElement>, "type"> {
  margin: keyof typeof globalTheme.margin;
}

export const MarginedDivider = styled(Divider)<MarginedDividerProps>`
  margin: ${({ theme, type, margin }) =>
    type === "vertical"
      ? `0 ${theme.margin[margin]}`
      : `${theme.margin[margin]} 0`};
  height: ${({ type }) => (type === "vertical" ? "auto" : "1px")};
  min-width: 1px;
`;
