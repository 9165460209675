import {
  RefreshAuthTokenDocument,
  RefreshAuthTokenMutation,
} from "__gen__/authGatewaySdk";
import authServiceClient from "./authGatewayClient";

export default async () => {
  const { data: refreshData } = await authServiceClient.mutate<
    RefreshAuthTokenMutation
  >({
    mutation: RefreshAuthTokenDocument,
  });
  return { token: refreshData && refreshData.refreshAuthToken.token };
};
