import { MetaField } from "components/assetMetaSpecs/MetadataFieldTypes";
import { contains, pluck } from "ramda";
import { useViewableMetaSubscription } from "__gen__/appService";

export default ({
  folderId,
  metaFields = [],
}: {
  folderId: string;
  metaFields?: MetaField[];
}) => {
  const { data } = useViewableMetaSubscription({ folderId });

  const viewableFieldIds = pluck("fieldId", data?.viewableMeta || []);

  const viewableMetaFields = metaFields.filter(({ id }) =>
    contains(id, viewableFieldIds),
  );

  return { viewableFieldIds, viewableMetaFields };
};
