import authGatewayClient from "authService/authGatewayClient";
import VLButton from "components/common/buttons/VLButton";
import GqlForm from "components/gqlForm/GqlForm";
import useGqlForm from "components/gqlForm/hooks/useGqlForm";
import Paths from "navigation/Paths";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import {
  ResetPasswordDocument,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from "__gen__/authGatewaySdk";
import { ResetPasswordForm } from "__gen__/forms";
import ResetPasswordFormSchema from "./forms/ResetPasswordForm.form.graphql";

const FormContainer = styled.div`
  width: 600px;
  padding: ${({ theme }) => theme.margin.medium};
  background-color: ${({ theme }) => theme.color.background.primary};
`;

export default ({ token }: { token: string }) => {
  const { form, validate, valuesState, errorsState } =
    useGqlForm<ResetPasswordForm>({
      schema: ResetPasswordFormSchema,
      initialValue: {
        newPassword: undefined,
      },
    });
  const [isSent, setIsSent] = useState(false);

  const onReset = async () => {
    const values = validate();
    if (!values) {
      return;
    }
    if (typeof token === "string") {
      try {
        const res = await authGatewayClient.mutate<
          ResetPasswordMutation,
          ResetPasswordMutationVariables
        >({
          mutation: ResetPasswordDocument,
          variables: { newPassword: values.newPassword, resetToken: token },
        });
        if (res.data?.resetSuccessful) {
          setIsSent(true);
        } else {
          // eslint-disable-next-line no-console
          console.error("Could not reset password");
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  };

  return !isSent ? (
    <FormContainer>
      <GqlForm form={form} errorsState={errorsState} state={valuesState} />
      <VLButton onClick={onReset} type="primary" marginVertical="medium">
        Reset
      </VLButton>
    </FormContainer>
  ) : (
    <Redirect to={Paths.login.withParams()} />
  );
};
