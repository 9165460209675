import "moment/locale/et";
import "moment/locale/lt";
import "moment/locale/lv";
import { VLLocales } from "store/contentInterfaces";

const localeMap: { [key in VLLocales]: string } = {
  [VLLocales.NO_LOCALE]: "en-us",
  [VLLocales.EN_US]: "en-us",
  [VLLocales.ET]: "et",
  [VLLocales.LV]: "lv",
  [VLLocales.LT]: "lt",
};

export default (locale: VLLocales): string => localeMap[locale];
