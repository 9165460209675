import { Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { ObjectTypeComposer } from "graphql-compose";
import { contains, path, toPairs } from "ramda";
import React from "react";
import ContentStore from "store/ContentStore";
import styled from "styled-components";
import { isDefined } from "types/predicates";
import { ContentRow } from "store/contentInterfaces";
import useProjectLanguages from "../hooks/useProjectLanguages";
import { ResolverContext } from "../inputs/types";
import FieldPreview from "./FieldPreview";

const tableRowClassname = "vl-table-row";
const TableContainer = styled.div`
  .${tableRowClassname} {
    cursor: pointer;
  }
`;

const withIdAsKey = <T extends { id: string }>(data: T) => ({
  ...data,
  key: data.id,
});

interface ResourceReferenceTableProps {
  composer: ObjectTypeComposer;
  locale: string;
  refs: string[];
  columnKeys: string[];
  onRowClick?: (row: ContentRow) => void;
}

export default (props: ResourceReferenceTableProps) => {
  const { composer, refs, locale, columnKeys, onRowClick = () => {} } = props;
  const { defaultLanguage } = useProjectLanguages();
  const { getContentRow } = ContentStore.useContainer();

  const rows = refs
    .map((id) => getContentRow(id))
    .filter(isDefined)
    .map(withIdAsKey);

  const columns = toPairs(composer.getFields())
    .filter(([key]) => contains(key, columnKeys))
    .map(
      ([key, field]): ColumnType<ContentRow> => ({
        title: field.description || key,
        dataIndex: key,
        key,
        render: (_, record) => {
          const tableFieldComposer = composer.getFieldTC(key);
          const tableFieldConfig = composer.getFieldConfig(key);
          const resourceValue = record.localeData;
          const valueResolver = (ctx: ResolverContext) =>
            path([ctx.locale, ...ctx.dataPath], resourceValue);
          const defaultResolver = (ctx: ResolverContext) =>
            path([defaultLanguage || "", ...ctx.dataPath], resourceValue);
          return (
            <FieldPreview
              onValueSaved={() => {}}
              locale={locale}
              resolveValue={valueResolver}
              resolveFallback={(ctx) =>
                valueResolver(ctx) || defaultResolver(ctx)
              }
              dataPath={[key]}
              fieldConfig={tableFieldConfig}
              composer={tableFieldComposer}
              rootComposer={composer}
              noWrapper
              contentRow={record}
            />
          );
        },
      }),
    );

  return (
    <TableContainer>
      <Table
        rowClassName={tableRowClassname}
        columns={columns}
        dataSource={rows}
        onRow={(row) => ({ onClick: () => onRowClick(row) })}
        size="small"
        bordered
      />
    </TableContainer>
  );
};
