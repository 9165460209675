import useTableMetaFields from "components/assetList/hooks/useTableMetaFields";
import Loading from "components/common/layout/Loading";
import { queryStringToGraphql } from "components/search/serializers";
import Paths from "navigation/Paths";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import MediaBrowserPreferences from "state/MediaBrowserPreferences";
import UserState from "state/UserState";
import { useSearchOrgAssetsSubscription } from "__gen__/appService";
import AssetBrowser from "./AssetBrowser";

const MediaSearch = ({ search }: { search: string }) => {
  const { selectedOrgId: orgId } = UserState.useContainer();
  const { metaColumns } = MediaBrowserPreferences.useContainer();
  const metaFields = useTableMetaFields();
  const { data, loading } = useSearchOrgAssetsSubscription({
    orgId,
    mediaSearch: queryStringToGraphql(search),
    metadataFieldIds: metaColumns,
  });
  const assets = data?.mediaAssets || [];

  if (loading) {
    return <Loading />;
  }

  if (assets.length === 0) {
    return null;
  }

  return <AssetBrowser mediaAssets={assets} metaFields={metaFields} />;
};

export default withRouter(
  ({ location: { search }, match }: RouteComponentProps) => {
    if (match.path !== Paths.assetsSearch.path) {
      return null;
    }

    return <MediaSearch search={search} />;
  },
);
