import { Table, Tooltip } from "antd";
import VLIcon from "components/common/VLIcon";
import { OrganizationType } from "components/forms/NewOrganizationForm";
import useDateFns from "components/hooks/useDateFns";
import strings from "localisation/strings";
import React from "react";
import styled from "styled-components";
import {
  OrganizationTableSubscription,
  useOrganizationTableSubscription,
} from "__gen__/appService";

const SelectableRow = styled.tr`
  cursor: pointer;
`;

interface OrganizationTableProps {
  onOrgClick: (org: OrganizationTableSubscription["organizations"][0]) => void;
}

export default ({ onOrgClick }: OrganizationTableProps) => {
  const { data, loading } = useOrganizationTableSubscription({});
  const { formatTimeStamp } = useDateFns();

  const organizations = (data && data.organizations) || [];

  const dataSource = organizations.map((org) => ({
    ...org,
    createdAt: formatTimeStamp(org.createdAt),
    memberCount: (org.orgMembers.total && org.orgMembers.total.count) || 0,
  }));

  return (
    <Table
      size="middle"
      loading={loading}
      dataSource={dataSource}
      rowKey="id"
      components={{ body: { row: SelectableRow } }}
      onRow={(record) => {
        return {
          onClick: () => onOrgClick(record),
        };
      }}
      pagination={{ hideOnSinglePage: true }}
    >
      <Table.Column
        key="type"
        title={strings("organizationTable.columns.type")}
        dataIndex="type"
        render={(type: OrganizationType) => (
          <Tooltip title={type}>
            {type === "PERSONAL" ? (
              <VLIcon type="user" />
            ) : (
              <VLIcon type="bank" />
            )}
          </Tooltip>
        )}
      />
      <Table.Column
        key="name"
        title={strings("organizationTable.columns.name")}
        dataIndex="name"
      />
      <Table.Column
        key="email"
        title={strings("organizationTable.columns.email")}
        dataIndex="email"
      />
      <Table.Column
        key="createdAt"
        title={strings("organizationTable.columns.createdAt")}
        dataIndex="createdAt"
      />
      <Table.Column
        key="memberCount"
        title={strings("organizationTable.columns.memberCount")}
        dataIndex="memberCount"
        align="right"
      />
    </Table>
  );
};
