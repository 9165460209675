import CmsErrorState, { ValidationResult } from "state/CmsErrorState";
import RightSidebarState, { CMS_ERRORS_SIDEBAR } from "state/RightSidebarState";

export default () => {
  const { openSidebar, closeSidebar } = RightSidebarState.useContainer();
  const { setValidationErrors } = CmsErrorState.useContainer();

  const showErrorSidebar = (validationResults: ValidationResult[]) => {
    setValidationErrors(validationResults);
    openSidebar(CMS_ERRORS_SIDEBAR);
  };

  const close = () => {
    closeSidebar();
  };

  return {
    showErrorSidebar,
    close,
  };
};
