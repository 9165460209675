import {
  AudioOutlined,
  BankOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  CopyOutlined,
  DashboardOutlined,
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfFilled,
  FileTextOutlined,
  FileZipOutlined,
  FolderOutlined,
  IdcardOutlined,
  LineChartOutlined,
  LoadingOutlined,
  LockOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MoreOutlined,
  PlayCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  PoweroffOutlined,
  ProjectOutlined,
  ReloadOutlined,
  RollbackOutlined,
  SettingOutlined,
  SyncOutlined,
  TagsOutlined,
  UpOutlined,
  UserOutlined,
  ZoomInOutlined,
  UploadOutlined,
  TableOutlined,
  UnorderedListOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import React from "react";
import globalTheme from "style/theme";
import styled from "styled-components";

const iconMap = {
  folder: FolderOutlined,
  "close-circle": CloseCircleOutlined,
  delete: DeleteOutlined,
  up: UpOutlined,
  down: DownOutlined,
  "menu-fold": MenuFoldOutlined,
  "menu-unfold": MenuUnfoldOutlined,
  loading: LoadingOutlined,
  "plus-circle": PlusCircleOutlined,
  close: CloseOutlined,
  dashboard: DashboardOutlined,
  idcard: IdcardOutlined,
  bank: BankOutlined,
  project: ProjectOutlined,
  tags: TagsOutlined,
  user: UserOutlined,
  edit: EditOutlined,
  plus: PlusOutlined,
  lock: LockOutlined,
  sync: SyncOutlined,
  "file-image": FileImageOutlined,
  "play-circle": PlayCircleOutlined,
  audio: AudioOutlined,
  file: FileOutlined,
  "file-zip": FileZipOutlined,
  "file-pdf": FilePdfFilled,
  "file-text": FileTextOutlined,
  "file-excel": FileExcelOutlined,
  mail: MailOutlined,
  copy: CopyOutlined,
  "zoom-in": ZoomInOutlined,
  more: MoreOutlined,
  setting: SettingOutlined,
  reload: ReloadOutlined,
  download: DownloadOutlined,
  "folder-add": FolderOutlined,
  poweroff: PoweroffOutlined,
  rollback: RollbackOutlined,
  "line-chart": LineChartOutlined,
  upload: UploadOutlined,
  grid: TableOutlined,
  list: UnorderedListOutlined,
  arrowUp: ArrowUpOutlined,
  arrowDown: ArrowDownOutlined,
};

export type VLIcons = keyof typeof iconMap;

interface ThemedIconProps {
  iconTheme?: "filled" | "outlined" | "twoTone";
  type: keyof typeof iconMap;
  onClick?: () => void;
  spin?: boolean;
  style?: React.CSSProperties;
}

const ThemedIcon = React.forwardRef(
  ({ iconTheme, type, ...props }: ThemedIconProps, ref) => {
    const Icon = iconMap[type];

    if (!Icon) {
      return null;
    }

    return <Icon ref={ref as any} translate="no" {...props} />;
  },
);

interface VLIconProps extends ThemedIconProps {
  size?: keyof typeof globalTheme.icon.size;
  color?: keyof typeof globalTheme.color.foreground;
}

const VLIcon = styled(ThemedIcon)<VLIconProps>`
  font-size: ${({ theme, size }) => theme.icon.size[size || "medium"]};
  ${({ theme, color }) =>
    color ? `color: ${theme.color.foreground[color]};` : ""}
`;

export default VLIcon;
