import { Column, FlexProps } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import { TranslationPaths } from "localisation/TranslationPaths";
import React, { ReactNode } from "react";

type LabelType = "normal" | "error";

interface DescriptionItemProps extends FlexProps {
  labelT?: TranslationPaths;
  label?: string;
  children?: ReactNode;
  isHidden?: boolean;
  labelType?: LabelType;
  requiredLabel?: boolean;
}

const DescriptionItem = ({
  label,
  labelT,
  children,
  isHidden,
  labelType,
  requiredLabel,
  ...props
}: DescriptionItemProps) =>
  isHidden ? null : (
    <Column {...props}>
      <SimpleText
        color={labelType === "error" ? "error" : "tertiary"}
        fontSize="small"
        t={labelT}
      >
        {label}
        {!!requiredLabel && <SimpleText color="error">*</SimpleText>}
      </SimpleText>
      {children}
    </Column>
  );

export default DescriptionItem;
