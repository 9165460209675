import { any, contains } from "ramda";
import UserState from "state/UserState";
import { createContainer } from "unstated-next";
import { PermissionScopesEnum } from "vl-app-client/lib/__gen__/appServiceSdk";
import { useMyPermissionsSubscription } from "__gen__/appService";

export default createContainer(({ orgId }: { orgId?: string } = {}) => {
  const { user } = UserState.useContainer();
  const { data, loading } = useMyPermissionsSubscription({
    userId: user?.id || "",
    orgId: orgId || "",
  });

  const myRoles = data?.roles || [];
  const canEditOrgData = any(
    ({ orgDataScopes }) => contains(PermissionScopesEnum.EDIT, orgDataScopes),
    myRoles,
  );
  const canViewOrgData = any(
    ({ orgDataScopes }) => contains(PermissionScopesEnum.VIEW, orgDataScopes),
    myRoles,
  );
  const canEditOrgMembers = any(
    ({ orgMembersScopes }) =>
      contains(PermissionScopesEnum.EDIT, orgMembersScopes),
    myRoles,
  );
  const canViewOrgMembers = any(
    ({ orgMembersScopes }) =>
      contains(PermissionScopesEnum.VIEW, orgMembersScopes),
    myRoles,
  );

  return {
    myRoles,
    loading,
    canEditOrgData,
    canViewOrgData,
    canEditOrgMembers,
    canViewOrgMembers,
  };
});
