import { startsWith } from "ramda";
import React from "react";
import styled from "styled-components";
import Avatar, { AvatarProps } from "../avatar/Avatar";
import { VLIcons } from "../VLIcon";
import { AssetType } from "./AssetType";

const getAssetType = (fileType: string): AssetType => {
  if (startsWith("text/csv", fileType)) {
    return "csv";
  }
  if (startsWith("image", fileType)) {
    return "image";
  }
  if (startsWith("application/zip", fileType)) {
    return "zip";
  }
  if (startsWith("application/pdf", fileType)) {
    return "pdf";
  }
  if (startsWith("text", fileType)) {
    return "text";
  }
  if (startsWith("video", fileType)) {
    return "video";
  }
  if (startsWith("audio", fileType)) {
    return "audio";
  }
  return "file";
};

const assetIcons: { [key: string]: string } & Record<AssetType, VLIcons> = {
  image: "file-image",
  zip: "file-zip",
  pdf: "file-pdf",
  text: "file-text",
  csv: "file-excel",
  video: "play-circle",
  audio: "audio",
  file: "file",
};

const TypeAvatar = styled(Avatar)<{ type: AssetType }>`
  background-color: ${({ theme, type }) => theme.color.fileTypes[type]};
  color: ${({ theme }) => theme.color.foreground.opposite};
`;

interface FileTypeIconProps extends AvatarProps {
  type: string;
}

const FileTypeIcon = ({ type, ...props }: FileTypeIconProps) => (
  <TypeAvatar
    size="large"
    shape="square"
    type={getAssetType(type)}
    icon={assetIcons[getAssetType(type)]}
    {...props}
  />
);

export default FileTypeIcon;
