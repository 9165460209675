import {
  append,
  differenceWith,
  filter,
  omit,
  prop,
  uniq,
  update,
  values,
} from "ramda";
import { useEffect, useState } from "react";
import { StringMapped } from "types/common";
import { isDefined } from "types/predicates";
import { createContainer } from "unstated-next";
import uuid from "uuid/v4";
import { ContentRow, VLContentType, VLLocales } from "./contentInterfaces";
import schemaToTypemap from "./functions/schemaToTypemap";
import useCmsData from "./hooks/useCmsData";
// import schemaSdl from "components/cmsV2/Schema.vlproject.graphql";

export interface AccessCode {
  id?: string;
  code: string;
}

interface ContentStoreOptions {
  projectId: string;
}

export default createContainer(
  ({ projectId }: ContentStoreOptions = { projectId: "" }) => {
    const [contentTypes, setContentTypes] = useState<
      StringMapped<VLContentType>
    >({});
    const [contentRows, setContentRows] = useState<ContentRow[]>([]);
    const [revision] = useState(uuid());
    const {
      rows,
      upsertData,
      deleteData,
      schemaSdl,
      locales: projectLocales,
      defaultLocale,
      loading,
      projectExtraData,
      projectData,
    } = useCmsData({
      projectId,
      ignoreRevision: revision,
    });
    const [selectedLanguage, setSelectedLanguage] = useState<string>(
      VLLocales.NO_LOCALE,
    );

    useEffect(() => {
      if (schemaSdl) {
        const typeMap = schemaToTypemap(schemaSdl);
        setContentTypes(typeMap);
      }
    }, [schemaSdl]);

    useEffect(() => {
      const deletedRowIds = uniq(
        rows.filter((row) => !!row.deletedAt).map(prop("id")),
      );
      const upsertedRows = rows
        .filter((row) => !row.deletedAt)
        .map(omit(["__typename", "deletedAt"]));

      upsertedRows.forEach((row) =>
        upsertRow({ ...row, localeData: row.localeData || {} }, true),
      );
      setContentRows((currentRows) =>
        differenceWith(
          (row, deletedId) => row.id === deletedId,
          currentRows,
          deletedRowIds,
        ),
      );
      // eslint-disable-next-line
    }, [JSON.stringify(rows)]);

    const getContentType = (typeId: string) => {
      return contentTypes[typeId];
    };

    const getContentRows = (contentTypeId: string) => {
      return contentRows.filter(({ type }) => contentTypeId === type);
    };

    const getContentRow = (contentId: string) => {
      return contentRows.find(({ id }) => id === contentId);
    };

    const upsertRow = (row: ContentRow, cacheOnly?: boolean) => {
      const index = contentRows.findIndex(({ id }) => id === row.id);
      if (index < 0) {
        setContentRows(append(row));
      } else {
        setContentRows(update(index, row));
      }
      if (!cacheOnly) {
        upsertData({
          variables: {
            ...omit(["createdAt"], row),
            revision,
            projectId,
          },
        });
      }
    };

    const removeRow = (rowId: string) => {
      setContentRows((rows) => filter((row) => row.id !== rowId, rows));
      deleteData({
        variables: {
          rowIds: [rowId],
          deletedAt: new Date().toISOString(),
          revision,
        },
      });
    };

    return {
      getContentType,
      getContentRows,
      contentTypes: values(contentTypes).filter(isDefined),
      contentRows,
      getContentRow,
      upsertRow,
      defaultLocale,
      projectLocales,
      removeRow,
      schemaSdl,
      projectId,
      selectedLanguage,
      setSelectedLanguage,
      loading,
      projectExtraData,
      projectData,
    };
  },
);
