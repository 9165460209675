import ScreenTitle from "components/common/text/ScreenTitle";
import UsersList from "components/tables/UsersList";
import strings from "localisation/strings";
import React from "react";
import AppScreen from "./AppScreen";

const UsersScreen = () => (
  <AppScreen>
    <ScreenTitle>{strings("usersScreen.title")}</ScreenTitle>
    <UsersList />
  </AppScreen>
);

export default UsersScreen;
