import { SimpleText } from "components/common/text/SimpleText";
import Paths from "navigation/Paths";
import React from "react";
import { Link } from "react-router-dom";

interface MemberLinkProps {
  memberId: string;
  user: { name?: string | null; email?: string | null };
  orgSlug: string;
  disabled?: boolean;
}

export default ({ memberId, user, orgSlug, disabled }: MemberLinkProps) => {
  if (disabled) {
    return <SimpleText>{user.name || user.email}</SimpleText>;
  }

  return (
    <Link to={Paths.orgMemberDetails.withParams({ orgSlug, memberId })}>
      {user.name || user.email}
    </Link>
  );
};
