import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";
import VLButton, { VLButtonProps } from "components/common/buttons/VLButton";
import strings from "localisation/strings";
import React, { ReactNode, useState } from "react";
import theme from "style/theme";

interface ButtonModalProps extends ModalProps {
  buttonProps?: VLButtonProps;
  children: ReactNode;
  onDone?: () => Promise<any> | void;
}

export default ({
  buttonProps,
  children,
  onCancel = () => {},
  onDone = () => {},
  ...modalProps
}: ButtonModalProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setModalVisible(false);
    onCancel(event);
  };
  const onOkPress = async () => {
    try {
      await onDone();
      setModalVisible(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <>
      <VLButton icon="plus" onClick={showModal} {...buttonProps} />
      <Modal
        onCancel={hideModal}
        onOk={onOkPress}
        visible={modalVisible}
        okText={strings("common.ok")}
        cancelText={strings("buttons.cancel")}
        destroyOnClose
        bodyStyle={{
          padding: theme.margin.large,
          paddingTop: 56,
        }}
        {...modalProps}
      >
        {children}
      </Modal>
    </>
  );
};
