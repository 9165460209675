import UserState from "state/UserState";
import { useRemovableMediasSubscription } from "__gen__/appService";

export default ({ mediaId }: { mediaId: string }) => {
  const { user } = UserState.useContainer();
  const { data } = useRemovableMediasSubscription({
    mediaIds: [mediaId],
    userId: user?.id || "",
  });

  return (data?.removableMedias.length || []) > 0;
};
