import { Result } from "antd";
import VLButton from "components/common/buttons/VLButton";
import AppContainer from "components/common/layout/AppContainer";
import strings from "localisation/strings";
import React from "react";

const AuthErrorScreen = () => (
  <AppContainer>
    <Result
      status="403"
      title={strings("authErrorScreen.title")}
      extra={
        <VLButton onClick={() => window.location.reload()}>
          {strings("authErrorScreen.reloadButton")}
        </VLButton>
      }
    />
  </AppContainer>
);

export default AuthErrorScreen;
