import { Row } from "components/common/layout/Flex";
import VLTag from "components/common/text/VLTag";
import React from "react";
import styled from "styled-components";
import { ResourcePreviewProps } from "./types";

const isMaybeArray = (value?: any): value is string[] | undefined =>
  value === undefined || Array.isArray(value);

const FallbackRow = styled(Row)`
  opacity: 0.7;
`;

export default (props: ResourcePreviewProps) => {
  const { resolveValue, resolveFallback } = props;
  const value = resolveValue(props);
  const fallbackValue = resolveFallback(props);

  if (!isMaybeArray(value) || !isMaybeArray(fallbackValue)) {
    return null;
  }

  return value ? (
    <Row>
      {value.map((tag, index) => (
        <VLTag key={index} label={tag} />
      ))}
    </Row>
  ) : (
    <FallbackRow>
      {(fallbackValue || []).map((tag, index) => (
        <VLTag key={index} label={tag} />
      ))}
    </FallbackRow>
  );
};
