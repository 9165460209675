import { Empty, List } from "antd";
import Avatar from "components/common/avatar/Avatar";
import useDateFns from "components/hooks/useDateFns";
import strings from "localisation/strings";
import React from "react";
import { useUsersTabelSubscription } from "__gen__/appService";

const UsersList = () => {
  const { data, loading } = useUsersTabelSubscription({});
  const { formatTimeStamp } = useDateFns();

  const users = data && data.users;

  if (!users) {
    return <Empty />;
  }

  return (
    <List
      size="small"
      loading={loading}
      itemLayout="horizontal"
      dataSource={users}
      renderItem={({ photoUrl, name, email, createdAt }) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar size="small" src={photoUrl} name={name || email} />}
            title={name}
            description={`${email} - ${strings(
              "common.createdAt",
            )} ${formatTimeStamp(createdAt)}`}
          />
        </List.Item>
      )}
    />
  );
};

export default UsersList;
