import globalTheme from "style/theme";
import styled from "styled-components";

interface MarginCardProps {
  margin?: keyof typeof globalTheme.margin;
  marginVertical?: keyof typeof globalTheme.margin;
  marginHorizontal?: keyof typeof globalTheme.margin;
}

export default styled.div<MarginCardProps>`
  border: 1px solid;
  border-color: ${({ theme }) => theme.border.color.primary};
  border-radius: ${({ theme }) => theme.border.radius.small};
  padding: ${({ theme }) => theme.margin.medium};
  ${({ margin, theme }) => (margin ? `margin: ${theme.margin[margin]};` : "")}
  ${({ marginHorizontal, theme }) =>
    marginHorizontal
      ? `margin-left: ${theme.margin[marginHorizontal]};
      margin-right: ${theme.margin[marginHorizontal]};`
      : ""}
  ${({ marginVertical, theme }) =>
    marginVertical
      ? `margin-top: ${theme.margin[marginVertical]}; margin-bottom: ${theme.margin[marginVertical]};`
      : ""}
`;
