import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import strings from "localisation/strings";
import { TranslationPaths } from "localisation/TranslationPaths";
import React from "react";
import globalTheme from "style/theme";
import styled from "styled-components";
import VLIcon, { VLIcons } from "../VLIcon";

export interface VLButtonProps extends Omit<ButtonProps, "icon"> {
  t?: TranslationPaths;
  margin?: keyof typeof globalTheme.margin;
  marginVertical?: keyof typeof globalTheme.margin;
  marginHorizontal?: keyof typeof globalTheme.margin;
  icon?: VLIcons;
}

const But = React.forwardRef(
  (
    {
      t,
      children,
      margin,
      marginVertical,
      marginHorizontal,
      ...props
    }: VLButtonProps,
    ref,
  ) => (
    <Button ref={ref as any} {...props}>
      {!!t ? strings(t) : children}
    </Button>
  ),
);

const StyledButton = styled(But)<VLButtonProps>`
  flex-shrink: 0;
  ${({ margin, theme }) => (margin ? `margin: ${theme.margin[margin]};` : "")}
  ${({ marginHorizontal, theme }) =>
    marginHorizontal
      ? `margin-left: ${theme.margin[marginHorizontal]};
      margin-right: ${theme.margin[marginHorizontal]};`
      : ""}
  ${({ marginVertical, theme }) =>
    marginVertical
      ? `margin-top: ${theme.margin[marginVertical]}; margin-bottom: ${theme.margin[marginVertical]};`
      : ""}
`;

const VLButton = React.forwardRef(
  ({ t, children, icon, ...props }: VLButtonProps, ref) => (
    <StyledButton ref={ref as any} {...props}>
      {!!icon && <VLIcon type={icon} />}
      {!!t ? strings(t) : children}
    </StyledButton>
  ),
);

const StyledTextButton = styled(VLButton)`
  display: flex;
  padding: 0;
  border: 0;
  height: auto;
  white-space: normal;
  text-align: inherit;
  align-items: center;
  i {
    padding-top: 2px;
  }
`;

export const TextButton = ({ ...props }: VLButtonProps) => (
  <StyledTextButton {...props} type="link" />
);

export default VLButton;
