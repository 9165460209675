import { SimpleText } from "components/common/text/SimpleText";
import useDateFns from "components/hooks/useDateFns";
import React from "react";
import {
  VLContentScalars,
  VLLocales,
  ContentRow,
} from "store/contentInterfaces";
import styled from "styled-components";

const FieldContainer = styled.div`
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface ContentValueProps {
  contentRow: ContentRow;
  fieldName: string;
  locale: VLLocales;
  fieldType?: string;
}

export default ({
  contentRow,
  fieldName,
  fieldType,
  locale,
}: ContentValueProps) => {
  let value = contentRow.localeData[locale]?.[fieldName];
  const { formatDateTime } = useDateFns();

  if (
    fieldType === VLContentScalars.VLDateTime ||
    fieldType === VLContentScalars.VLDate
  ) {
    if (value) {
      value = formatDateTime(new Date(value));
    }
  }

  return (
    <FieldContainer>
      <SimpleText>{value}</SimpleText>
    </FieldContainer>
  );
};
