import { DatePicker } from "antd";
import VLButton from "components/common/buttons/VLButton";
import { Row } from "components/common/layout/Flex";
import { format } from "date-fns";
import moment from "moment";
import React from "react";
import { VLLocales } from "store/contentInterfaces";
import styled from "styled-components";
import getDateFnsLocale from "../functions/getDateFnsLocale";
import getDatePickerLocale from "../functions/getDatePickerLocale";
import getMomentLocale from "../functions/getMomentLocale";
import TextPreview from "./TextPreview";
import useEditableField from "./useEditableField";

const DatePickerContainer = styled.div`
  width: 80px;
`;

const formatMaybeValue = (locale: VLLocales, value?: string) => {
  return value
    ? format(new Date(value), "P p", {
        locale: getDateFnsLocale(locale),
      })
    : "";
};

interface EditableDateProps {
  locale: VLLocales;
  initialValue?: string;
  onSave: (text?: string) => void;
  placeholder?: string;
}

export default ({
  initialValue,
  onSave: onSaveDate,
  placeholder,
  locale,
}: EditableDateProps) => {
  const momentLocale = getMomentLocale(locale);
  const {
    isEditing,
    value,
    setValue,
    startEditing,
    onCancel,
    stopEditing,
  } = useEditableField<string | undefined>({
    initialValue,
    initialEditingValue: (v) => v || placeholder,
  });

  if (!isEditing) {
    return (
      <TextPreview
        text={formatMaybeValue(locale, value)}
        startEditing={startEditing}
        placeholder={formatMaybeValue(locale, placeholder)}
      />
    );
  }

  const onSave = () => {
    onSaveDate(value);
    stopEditing();
  };

  return (
    <DatePickerContainer>
      <DatePicker
        autoFocus
        open
        showTime
        allowClear
        value={value ? moment(value).locale(momentLocale) : undefined}
        renderExtraFooter={() => (
          <Row mainAxis="flex-end" marginVertical="small">
            <VLButton
              size="small"
              onClick={() => {
                onSaveDate(undefined);
                stopEditing();
              }}
            >
              Clear
            </VLButton>
          </Row>
        )}
        showToday={false}
        onChange={(date) => {
          setValue(date?.toISOString() || "");
        }}
        onOk={onSave}
        locale={getDatePickerLocale(locale)}
        format="L LTS"
        onOpenChange={(opened) => {
          if (!opened) {
            onCancel();
          }
        }}
      />
    </DatePickerContainer>
  );
};
