import React from "react";
import ObjectInput, {
  transformObject,
  validateObject,
} from "./inputs/ObjectInput";
import TypeReferenceInput, {
  transformReference,
  validateReference,
} from "./inputs/TypeReferenceInput";
import {
  AsyncValidationFunction,
  InputProps,
  TransformFunction,
  TransformProps,
  ValidationError,
} from "./types";

export const validateAnyType: AsyncValidationFunction<any> = async (props) => {
  const { schemaType, value, dataPath } = props;
  if (schemaType.kind === "reference") {
    return await validateReference(props);
  }

  if (schemaType.kind === "object") {
    return await validateObject({ ...props, schemaType });
  }
  const error: ValidationError = {
    message: `Missing type validator for ${schemaType.kind} at ${dataPath}`,
    dataPath,
  };
  return { filteredData: value, errors: [error], unhandledErrors: [error] };
};

export const transformAnyType: TransformFunction<any, any> = async ({
  value,
  schemaType,
}: TransformProps<any>) => {
  if (schemaType.kind === "object") {
    return await transformObject({ value, schemaType });
  }
  if (schemaType.kind === "reference") {
    return await transformReference({ value, schemaType });
  }
  return value;
};

export default function TypeInput(props: InputProps<any>) {
  const { schemaType } = props;

  if (schemaType.kind === "object") {
    return <ObjectInput {...props} schemaType={schemaType} />;
  }

  if (schemaType.kind === "reference") {
    return <TypeReferenceInput {...props} schemaType={schemaType} />;
  }
  return null;
}
