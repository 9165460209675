import { assocPath, dissocPath, isEmpty } from "ramda";
import React from "react";
import {
  ContentRow,
  VLContentScalars,
  VLLocales,
} from "store/contentInterfaces";
import { isDefined } from "types/predicates";
import EditableBoolean from "./editableFields/EditableBoolean";
import EditableDate from "./editableFields/EditableDate";
import EditableNumber from "./editableFields/EditableNumber";
import EditableTextField from "./editableFields/EditableTextField";
import changeContentStatus from "./functions/changeContentStatus";

interface ContentValueProps {
  contentRow: ContentRow;
  fieldName: string;
  onContentChanged: (content: ContentRow) => void;
  fieldType: string;
  locale: VLLocales;
  defaultLocale?: VLLocales;
}

export default ({
  contentRow,
  fieldName,
  onContentChanged,
  fieldType,
  locale,
  defaultLocale,
}: ContentValueProps) => {
  const data = contentRow.localeData[locale] || {};
  const initialValue = data[fieldName];
  const placeholder = (contentRow?.localeData[
    (defaultLocale || "") as VLLocales
  ] || {})[fieldName];

  const onSave = (value: any) => {
    let change = assocPath<any, ContentRow>(
      ["localeData", locale, fieldName],
      value,
    );
    if (!isDefined(value) || isEmpty(value)) {
      change = dissocPath(["localeData", locale, fieldName]);
    }
    const newRow = change(changeContentStatus(contentRow));
    onContentChanged(newRow);
  };

  const commonProps = {
    placeholder,
    initialValue,
    onSave,
  };

  if (fieldType === VLContentScalars.VLDateTime) {
    return <EditableDate locale={locale} {...commonProps} />;
  }

  if (fieldType === VLContentScalars.VLNumber) {
    return <EditableNumber {...commonProps} />;
  }

  if (fieldType === VLContentScalars.VLBoolean) {
    return <EditableBoolean {...commonProps} />;
  }

  return (
    <EditableTextField
      isLongText={fieldType === VLContentScalars.VLLongText}
      {...commonProps}
    />
  );
};
