import { Tooltip } from "antd";
import Tag, { TagProps } from "antd/lib/tag";
import React from "react";

export interface VLTagProps extends TagProps {
  label: string;
}

export default React.forwardRef(({ label, ...props }: VLTagProps, ref) => {
  const isLongTag = label.length > 20;
  const tagElem = (
    <Tag ref={ref as any} {...props}>
      {isLongTag ? `${label.slice(0, 20)}...` : label}
    </Tag>
  );
  return isLongTag ? <Tooltip title={label}>{tagElem}</Tooltip> : tagElem;
});
