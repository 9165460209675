import { concat, uniq, without } from "ramda";
import { useState } from "react";
import { createContainer } from "unstated-next";

export default createContainer(() => {
  const [pendingArchives, setPendingArchives] = useState<string[]>([]);

  const addPendingArchives = (archiveIds: string[]) => {
    setPendingArchives(current => uniq(concat(archiveIds, current)));
  };

  const removePendingArchives = (archiveIds: string[]) => {
    setPendingArchives(without(archiveIds));
  };

  return {
    pendingArchives,
    addPendingArchives,
    removePendingArchives
  };
});
