import { pluck } from "ramda";
import { useEffect, useState } from "react";
import { isDefined } from "types/predicates";
import {
  AssetMetadataValuesSubscription,
  useReplaceAssetMetadataMutation,
} from "__gen__/appService";
import { ValuesChange } from "../MetaFieldInputTypes";
import {
  AssetMetaFieldsState,
  emptyValues,
  initialValuesToState,
  stateToInsertValues,
} from "../stateMapper";

interface UseAssetMetadataEditingProps {
  mediaAssetId: string;
  metadataFields: AssetMetadataValuesSubscription["metadataFields"];
}

export default ({
  mediaAssetId,
  metadataFields,
}: UseAssetMetadataEditingProps) => {
  const [updateMetadata] = useReplaceAssetMetadataMutation();
  const [selectedValues, setSelectedValues] = useState<AssetMetaFieldsState>(
    {},
  );
  const [initialState, setInitialState] = useState<AssetMetaFieldsState>({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const is = initialValuesToState(metadataFields);
    setInitialState(is);
    setSelectedValues(is);
    setIsSaving(false);
    // eslint-disable-next-line
  }, [JSON.stringify(metadataFields)]);

  const setFieldValues = ({ fieldId, values }: ValuesChange) => {
    setSelectedValues((currentValues) => ({
      ...currentValues,
      [fieldId]: { ...emptyValues, ...values },
    }));
  };

  const onSaveValues = () => {
    setIsSaving(true);
    const newValues = stateToInsertValues(selectedValues, {
      asset_id: mediaAssetId,
    });
    return updateMetadata({
      variables: {
        mediaAssetId,
        newValues,
        fieldIds: pluck("field_id", newValues).filter(isDefined),
      },
    });
  };

  const reset = () => {
    setSelectedValues(initialState);
  };

  return {
    initialState,
    isSaving,
    setFieldValues,
    onSaveValues,
    selectedValues,
    reset,
  };
};
