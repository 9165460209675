import { enUS, et, lt, lv } from "date-fns/locale";
import { VLLocales } from "store/contentInterfaces";

const localeMap: { [key in VLLocales]: Locale } = {
  [VLLocales.NO_LOCALE]: enUS,
  [VLLocales.EN_US]: enUS,
  [VLLocales.ET]: et,
  [VLLocales.LV]: lv,
  [VLLocales.LT]: lt,
};

export default (locale: VLLocales): Locale => localeMap[locale];
