import IsAdmin from "components/common/controlFlow/IsAdmin";
import IsNotAdmin from "components/common/controlFlow/IsNotAdmin";
import ScreenTitle from "components/common/text/ScreenTitle";
import UserDashboard from "components/dashboard/UserDashboard";
import strings from "localisation/strings";
import React from "react";
import AppScreen from "./AppScreen";

const DashboardScreen = () => {
  return (
    <AppScreen>
      <IsAdmin>
        <ScreenTitle>{strings("screens.dashboard.title")}</ScreenTitle>
      </IsAdmin>
      <IsNotAdmin>
        <UserDashboard />
      </IsNotAdmin>
    </AppScreen>
  );
};

export default DashboardScreen;
