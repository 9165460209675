import { SimpleText } from "components/common/text/SimpleText";
import NewOrganizationForm from "components/forms/NewOrganizationForm";
import strings from "localisation/strings";
import React from "react";
import styled from "styled-components";
import AppScreen from "./AppScreen";

const Title = styled(SimpleText)`
  margin-bottom: ${({ theme }) => theme.margin.large};
`;

const NewOrganizationScreen = () => {
  return (
    <AppScreen>
      <Title as="h2">{strings("screens.newOrganization.title")}</Title>
      <NewOrganizationForm />
    </AppScreen>
  );
};

export default NewOrganizationScreen;
