import { UploadingFile } from "state/UploadState";

export type S3UploadedFile = UploadingFile & {
  location: string;
  s3Bucket: string;
  s3Key: string;
  warehouseId: string;
};

export interface UploadHandler {
  onFileCreated: (file: S3UploadedFile) => void;
}

export default (awsPlugin: any, uploadHandler: UploadHandler) => {
  if (
    awsPlugin &&
    typeof awsPlugin.completeMultipartUpload === "function" &&
    typeof awsPlugin.opts === "object"
  ) {
    awsPlugin.opts.completeMultipartUpload = async (
      file: UploadingFile,
      other: any
    ) => {
      const res = await awsPlugin.completeMultipartUpload(file, other);
      uploadHandler.onFileCreated({
        ...file,
        location: res.location,
        s3Bucket: res.bucket,
        s3Key: res.key,
        warehouseId: res.warehouseId
      });
      return res;
    };
  }
};
