import VLButton from "components/common/buttons/VLButton";
import { Row } from "components/common/layout/Flex";
import Margin from "components/common/layout/Margin";
import { head } from "ramda";
import React from "react";
import TypeInput, { transformAnyType, validateAnyType } from "../TypeInput";
import {
  AsyncValidationFunction,
  InputProps,
  TransformFunction,
  ValidationError,
} from "../types";

export const validateOptionalInput: AsyncValidationFunction<any> = async ({
  value,
  schemaType,
  typeMap,
  dataPath,
}) => {
  const itemType = head(schemaType.of || []);

  if (!itemType) {
    const error: ValidationError = {
      message: "Optional type is missing",
      dataPath,
    };
    return {
      filteredData: value,
      errors: [error],
      unhandledErrors: [error],
    };
  }

  if (value === undefined || value === null) {
    return { filteredData: value, errors: [], unhandledErrors: [] };
  }

  return validateAnyType({
    value,
    schemaType: itemType,
    typeMap,
    dataPath,
  });
};

export const transformOptional: TransformFunction<any, any> = async ({
  value,
  schemaType,
}) => {
  const itemType = head(schemaType.of || []);
  if (!itemType) {
    return value;
  }
  return await transformAnyType({
    value,
    schemaType: itemType,
  });
};

export default function OptionalInput({
  schemaType,
  value,
  onValueChange,
  dataPath,
}: InputProps<any>) {
  const itemType = head(schemaType.of || []);
  if (!itemType) {
    return null;
  }

  const onReset = () => {
    onValueChange(null);
  };

  return (
    <Row>
      <TypeInput
        value={value}
        schemaType={itemType}
        onValueChange={onValueChange}
        dataPath={dataPath}
      />
      <Margin />
      <VLButton t="buttons.reset" onClick={onReset} />
    </Row>
  );
}
