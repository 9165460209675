import DescriptionItem from "components/common/descriptions/DescriptionItem";
import { Row } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import VLIcon from "components/common/VLIcon";
import {
  concat,
  dissoc,
  filter,
  flatten,
  path,
  pluck,
  startsWith,
} from "ramda";
import React from "react";
import { ReactState } from "state/interfaces/ReactState";
import styled from "styled-components";
import { isDefined } from "types/predicates";
import removeEmptyValues from "../functions/removeEmptyValues";
import {
  FormFieldValidationError,
  validateFormField,
} from "../functions/validateForm";
import { FormFieldDefinition } from "../types";
import FormInput, { SetValue } from "./FormInput";

const updateErrors = (
  newErrors: FormFieldValidationError[],
  fieldPath: string[],
) => (current: FormFieldValidationError[]) => {
  const filtered = filter(
    (error) => !startsWith(fieldPath, error.fieldPath),
    current,
  );
  return concat(filtered, newErrors);
};

const ClearIcon = styled(VLIcon)`
  margin-left: ${({ theme }) => theme.margin.small};
`;

export default ({
  field,
  state: [values, setValues],
  errorsState: [errors, setErrors],
}: {
  field: FormFieldDefinition;
  state: ReactState<any>;
  errorsState: ReactState<FormFieldValidationError[]>;
}) => {
  const fieldPath = [field.name];
  const fieldValue = values[field.name];
  const fieldErrors = flatten(
    pluck(
      "errors",
      errors.filter((fe) => startsWith(fieldPath, fe.fieldPath)),
    ),
  );

  const proxiedSetValue: SetValue = (updater) => {
    setValues((current: any) => {
      const newState = updater(current);
      const validationErrors = validateFormField(
        removeEmptyValues(path(fieldPath, newState)),
        field,
      );
      setErrors(updateErrors(validationErrors, [field.name]));
      return newState;
    });
  };

  return (
    <DescriptionItem
      label={field.description || field.name}
      requiredLabel={field.isRequired}
    >
      <Row crossAxis="center">
        <FormInput
          field={field}
          setValue={proxiedSetValue}
          fieldValue={fieldValue}
          errorsState={[errors, setErrors]}
        />
        {isDefined(fieldValue) && !field.isRequired && (
          <ClearIcon
            type="close-circle"
            onClick={() =>
              proxiedSetValue((value: any) => dissoc(field.name, value))
            }
          />
        )}
      </Row>
      {fieldErrors.map((error, index) => (
        <SimpleText key={index} color="error" fontSize="small">
          {error}
        </SimpleText>
      ))}
    </DescriptionItem>
  );
};
