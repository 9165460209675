import { message, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ApolloError } from "apollo-client";
import { mergeLanguageData } from "components/cmsV2/functions/mergeLanguageData";
import validateObject from "components/cmsV2/functions/validation/validateObject";
import ObjectTypeInput from "components/cmsV2/inputs/ObjectTypeInput";
import { ResourceInputProps } from "components/cmsV2/inputs/types";
import schema from "components/cmsV2/Schema.vlproject.graphql";
import useOrganizationNames from "components/organizations/useOrganizationNames";
import strings from "localisation/strings";
import { assocPath, dissocPath, head, isEmpty, path } from "ramda";
import React, { useState } from "react";
import UserState from "state/UserState";
import schemaToTypemap from "store/functions/schemaToTypemap";
import { isDefined } from "types/predicates";
import { StringMapped } from "vl-app-client/lib/util/types";
import {
  useCreateCmsProjectMutation,
  useCreateProjectMutation,
} from "__gen__/appService";

interface AddProjectModalProps {
  visible: boolean;
  onDone: () => void;
}

const AddProjectModal = ({ visible, onDone }: AddProjectModalProps) => {
  const [error, setError] = useState<ApolloError | undefined>();
  const [formData, setFormData] = useState<StringMapped>({});
  const [createProject, { loading: projectCreateLoading }] =
    useCreateProjectMutation();
  const [createCmsProject, { loading: cmsCreateLoading }] =
    useCreateCmsProjectMutation();
  const { names: organizations } = useOrganizationNames();
  const { selectedOrgId } = UserState.useContainer();
  const [form] = useForm();
  const showCreateOrg = organizations.length === 0;

  const onCreateProject = async () => {
    const name = path<string>(["EN", "name"], formData);
    const selectedLanguage = path<string>(["NO_LOCALE", "languages"], formData);
    const defaultLanguage = path<string>(
      ["NO_LOCALE", "defaultLanguage"],
      formData,
    );
    let mergedData;
    if (!selectedLanguage || !defaultLanguage) {
      return undefined;
    }
    mergedData = mergeLanguageData({
      dataValue: formData,
      selectedLanguage,
      defaultLanguage,
    });
    const typeComposer = typeMap["VlProject"];
    if (!typeComposer) {
      return null;
    }
    const validation = validateObject({
      value: mergedData,
      typeComposer,
    });
    // eslint-disable-next-line no-console
    console.log(validation); // for validating with resourceform in future
    try {
      if (!name) {
        message.error("Name is required", 0.5);
        throw new Error("No name!");
      }
      if (!selectedLanguage) {
        message.error("At least one language is required", 0.5);
        throw new Error("No language!");
      }
      const { data } = await createProject({
        variables: {
          name,
          orgId: selectedOrgId,
          extraData: { cmsVersion: 2 },
        },
      });
      const projectId = head(data?.insert_projects?.returning || [])?.id;
      if (projectId) {
        createCmsProject({
          variables: {
            projectId,
            schemaSdl: schema,
            cmsRow: {
              id: projectId,
              type: "VlProject",
              project_id: projectId,
              locale_data: formData,
              revision: "INIT",
            },
          },
        });
      }
      onDone();
    } catch (err) {
      setError(err as any);
    }
  };

  const onModalClose = () => {
    if (form) {
      form.resetFields();
      setError(undefined);
    }
  };

  const typeMap = schemaToTypemap(schema);
  const typeComposer = typeMap["VlProject"];
  if (!typeComposer) {
    return null;
  }

  const onValueSaved: ResourceInputProps["onValueSaved"] = ({
    value,
    dataPath,
    locale,
  }) => {
    if (!isDefined(value) || isEmpty(value)) {
      setFormData(dissocPath([locale, ...dataPath]));
    } else {
      setFormData(assocPath([locale, ...dataPath], value));
    }
  };

  return (
    <Modal
      title={strings("projects.addNew")}
      okText={error ? strings("buttons.tryAgain") : strings("buttons.create")}
      cancelText={strings("buttons.cancel")}
      confirmLoading={projectCreateLoading || cmsCreateLoading}
      visible={visible}
      onOk={onCreateProject}
      onCancel={onDone}
      afterClose={onModalClose}
      footer={showCreateOrg ? null : undefined}
    >
      <ObjectTypeInput
        rootComposer={typeComposer}
        composer={typeComposer}
        resolveValue={({ dataPath, locale }) =>
          path([locale, ...dataPath], formData)
        }
        resolveFallback={({ dataPath, locale }) =>
          path([locale, ...dataPath], formData)
        }
        onValueSaved={onValueSaved}
        dataPath={[]}
        locale={"EN"}
        isNewResource
      />
    </Modal>
  );
};

export default AddProjectModal;
