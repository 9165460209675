import { MetaField } from "components/assetMetaSpecs/MetadataFieldTypes";
import DescriptionItem from "components/common/descriptions/DescriptionItem";
import React from "react";
import MetaFieldInput from "./MetaFieldInput";
import { ValuesChange } from "./MetaFieldInputTypes";
import { AssetMetaFieldsState, emptyValues } from "./stateMapper";

interface AssetMetaFieldsProps {
  fields: Array<Required<MetaField>>;
  setValues: (values: ValuesChange) => void;
  selectedValues: AssetMetaFieldsState;
}

export default ({
  selectedValues,
  setValues,
  fields
}: AssetMetaFieldsProps) => {
  return (
    <>
      {fields.map(field => {
        const isRequired = field.options.required;
        return (
          <DescriptionItem
            labelType={isRequired ? "error" : "normal"}
            marginVertical="small"
            key={field.id}
            label={isRequired ? `${field.name}*` : field.name}
          >
            <MetaFieldInput
              field={field}
              onValuesChanged={setValues}
              values={selectedValues[field.id] || emptyValues}
            />
          </DescriptionItem>
        );
      })}
    </>
  );
};
