import { Dropdown, Menu } from "antd";
import useFileDownload from "appGateway/hooks/useFileDownload";
import EditAssetModal from "components/assetMetadata/EditAssetModal";
import VLButton from "components/common/buttons/VLButton";
import { SimpleText } from "components/common/text/SimpleText";
import DeleteAssetsModal from "components/mediaAssets/DeleteAssetsModal";
import useAssetActions from "components/mediaAssets/hooks/useAssetActions";
import MoveAssetsModal from "components/mediaAssets/MoveAssetsModal";
import ShareAssetModal from "components/shareAssets/ShareAssetModal";
import useCanDeleteMedia from "components/permissionControl/hooks/useCanDeleteMedia";
import useCanEditFolder from "components/permissionControl/hooks/useCanEditFolder";
import { TranslationPaths } from "localisation/TranslationPaths";
import { concat } from "ramda";
import React, { useState } from "react";
import RightSidebarState from "state/RightSidebarState";
import { WithId } from "types/common";
import { MediaAssetFragment } from "__gen__/appService";

type ModalType = "EDIT" | "MOVE" | "DELETE" | "SHARE";

interface MediaAssetActionsProps {
  media: MediaAssetFragment & { originalFiles: WithId[] };
}

export default ({ media }: MediaAssetActionsProps) => {
  const { closeSidebar } = RightSidebarState.useContainer();
  const {
    restoreAction: [restore],
  } = useAssetActions();
  const { downloadFile } = useFileDownload();
  const [modal, setModal] = useState<ModalType>();
  const canDeleteMedia = useCanDeleteMedia({ mediaId: media.id });
  const canEditMedia = useCanEditFolder({ folderId: media.folderId || "" });
  const isDeleted = !!media.deletedAt;

  const onDownload = async () => {
    downloadFile({
      assetFileId: media.originalFiles[0]?.id || "",
      fileName: media.name,
    });
  };

  const onModalClosed = () => {
    setModal(undefined);
  };

  let actions: Array<{
    t: TranslationPaths;
    onClick: () => void;
    visible: boolean;
  }> = [{ t: "common.download", onClick: onDownload, visible: !isDeleted }];

  if (canEditMedia) {
    actions = concat(actions, [
      {
        t: "common.edit",
        onClick: () => setModal("EDIT"),
        visible: !isDeleted,
      },
      {
        t: "buttons.restore",
        onClick: async () => {
          await restore({ assetIds: [media.id] });
          closeSidebar();
        },
        visible: isDeleted,
      },
      //TODO Maybe need to put in another if statement
      {
        t: "common.share",
        onClick: () => setModal("SHARE"),
        visible: !isDeleted,
      },
    ]);
  }

  if (canDeleteMedia) {
    actions = concat(actions, [
      {
        t: "common.move",
        onClick: () => setModal("MOVE"),
        visible: !isDeleted,
      },
      {
        t: "common.delete",
        onClick: () => setModal("DELETE"),
        visible: !isDeleted,
      },
    ]);
  }

  return (
    <>
      <Dropdown
        trigger={["click"]}
        overlay={
          <Menu>
            {actions
              .filter((action) => action.visible)
              .map((action, index) => (
                <Menu.Item key={index} onClick={action.onClick}>
                  <SimpleText t={action.t} />
                </Menu.Item>
              ))}
          </Menu>
        }
      >
        <VLButton icon="more" />
      </Dropdown>
      <MoveAssetsModal
        visible={modal === "MOVE"}
        assetIds={[media.id]}
        onDone={onModalClosed}
      />
      <DeleteAssetsModal
        visible={modal === "DELETE"}
        assetIds={[media.id]}
        onDone={onModalClosed}
      />
      <EditAssetModal
        visible={modal === "EDIT"}
        mediaAsset={media}
        onDone={onModalClosed}
      />
      <ShareAssetModal
        visible={modal === "SHARE"}
        mediaAssetId={media.id}
        onDone={onModalClosed}
      />
    </>
  );
};
