import { Collapse } from "antd";
import Margin from "components/common/layout/Margin";
import { assoc, startsWith } from "ramda";
import React, { useState } from "react";
import ComponentEditorState from "state/ComponentEditorState";
import styled from "styled-components";
import ComponentEditor, {
  transformComponent,
  validateComponentProps,
} from "../ComponentEditor";
import {
  AsyncValidationFunction,
  InputProps,
  ValidationError,
  VlComponentState,
} from "../types";

const CollapsePanel = styled(Collapse.Panel)<{ hasErrors: boolean }>`
  .ant-collapse-header {
    ${({ hasErrors, theme }) =>
      hasErrors
        ? `border: 1px solid ${theme.color.foreground.error}; background:#fbe9eb`
        : "inherit"};
  }
`;

export interface TranformedVlComponent {
  url: string;
  props: {};
}

export const validateVlComponent: AsyncValidationFunction<VlComponentState> = async ({
  value,
  dataPath,
}) => {
  if (!value) {
    const error: ValidationError = {
      message: "Please check input fields",
      dataPath,
    };
    return {
      filteredData: undefined,
      errors: [error],
      unhandledErrors: [error],
    };
  }

  const {
    filteredData,
    errors,
    unhandledErrors,
  } = await validateComponentProps(value, { dataPath });

  return {
    errors,
    unhandledErrors,
    filteredData: assoc("props", filteredData, value),
  };
};

export const transformVlComponent = async ({
  value,
}: {
  value: VlComponentState;
}): Promise<TranformedVlComponent | undefined> => {
  if (!value) {
    return undefined;
  }
  return {
    url: value.svelteUrl,
    props: await transformComponent(value),
  };
};

const containsErrors = (
  errors: ValidationError[],
  dataPath: Array<string | number>,
) => {
  const atLeastOne = errors.some((error) =>
    startsWith(dataPath, error.dataPath),
  );
  return atLeastOne;
};

export default function VlComponentInput({
  onValueChange,
  value,
  dataPath,
}: InputProps<VlComponentState>) {
  const { errors } = ComponentEditorState.useContainer();
  const [isClosed, setIsClosed] = useState<boolean>(true);

  return (
    <>
      {value && (
        <>
          <Margin />
          <Collapse onChange={() => setIsClosed(!isClosed)}>
            <CollapsePanel
              hasErrors={containsErrors(errors, dataPath) && isClosed}
              header={value.name}
              key={value.name}
            >
              <ComponentEditor
                value={value.props}
                definition={{ schemaUrl: value.schemaUrl }}
                onValueChange={(newProps) =>
                  onValueChange(assoc("props", newProps, value))
                }
                componentName={value.name}
                errors={errors}
                dataPath={dataPath}
              />
            </CollapsePanel>
          </Collapse>
        </>
      )}
    </>
  );
}
