import GraphQLClientState from "state/GraphQLClientState";
import {
  MediaAssetOriginalFilesDocument,
  MediaAssetOriginalFilesQuery,
  MediaAssetOriginalFilesQueryVariables,
} from "__gen__/appService";

export default () => {
  const { hasuraClient } = GraphQLClientState.useContainer();

  if (!hasuraClient) {
    throw new Error("No app service client");
  }

  const queryOriginalAssetFiles = (
    variables: MediaAssetOriginalFilesQueryVariables,
  ) =>
    hasuraClient.query<
      MediaAssetOriginalFilesQuery,
      MediaAssetOriginalFilesQueryVariables
    >({
      query: MediaAssetOriginalFilesDocument,
      variables,
    });

  return { queryOriginalAssetFiles };
};
