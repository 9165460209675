import { Layout } from "antd";
import VLBreadcrumbs from "components/common/breadcrumbs/VLBreadcrumbs";
import { Column } from "components/common/layout/Flex";
import MediaTypeTabs from "components/mediaAssets/MediaTypeTabs";
import React, { ReactNode } from "react";
import styled from "styled-components";

interface AppScreenProps {
  children?: ReactNode;
}

const { Content } = Layout;

const AppContentContainer = styled(Content)`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.margin.large};
  background-color: ${({ theme }) => theme.color.background.primary};
  padding: ${({ theme }) => theme.margin.large};
  min-height: auto;
`;

export default ({ children }: AppScreenProps) => {
  return (
    <Layout>
      <Column>
        <MediaTypeTabs />
        <VLBreadcrumbs />
        <AppContentContainer>{children}</AppContentContainer>
      </Column>
    </Layout>
  );
};
