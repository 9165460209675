export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
}

export enum Currency {
  EUR = "EUR",
  USD = "USD",
}

export interface EditFolderForm {
  /** Folder name */
  name: Scalars["String"];
  /** Description */
  description?: Maybe<Scalars["String"]>;
}

export interface EditStreamForm {
  /** Stream title */
  title: Scalars["String"];
  /** Transcode */
  transcode: Scalars["Boolean"];
  /** Live rewind (DVR) */
  dvrLiveRewind: Scalars["Boolean"];
  /** Record */
  record: Scalars["Boolean"];
  /** Notes */
  notes?: Maybe<Scalars["String"]>;
}

export interface ForgotPasswordForm {
  /** Enter your email */
  email: Scalars["String"];
}

export interface LengthDirective {
  min?: Maybe<Scalars["Float"]>;
  max?: Maybe<Scalars["Float"]>;
}

export interface RangeDirective {
  min?: Maybe<Scalars["Float"]>;
  max?: Maybe<Scalars["Float"]>;
}

export interface ResetPasswordForm {
  /** New password */
  newPassword: Scalars["String"];
}

export interface TicketOption {
  /** Ticket name */
  name: Scalars["String"];
  /** Price */
  price: TicketPrice;
  /** Type */
  ticketType: TicketType;
  /** Valid until */
  validUntil?: Maybe<Scalars["String"]>;
  /** Valid for */
  validFor?: Maybe<ValidFor>;
}

export interface TicketOptions {
  /** Ticket name */
  name: Scalars["String"];
  /** Price */
  price: TicketPrice;
  /** Type */
  ticketType: TicketType;
  /** Valid until */
  validUntil?: Maybe<Scalars["Date"]>;
  /** Valid for */
  validFor?: Maybe<ValidFor>;
}

export interface TicketPrice {
  amount: Scalars["Float"];
  currency: Currency;
}

export enum TicketType {
  /** Subscription */
  CONTINOUS_SUBSCRIPTION = "CONTINOUS_SUBSCRIPTION",
  /** Single ticket */
  SINGLE = "SINGLE",
}

export interface ValidFor {
  amount: Scalars["Int"];
  unit: ValidForUnit;
}

export enum ValidForUnit {
  /** Days */
  DAY = "DAY",
  /** Months */
  MONTH = "MONTH",
  /** Years */
  YEAR = "YEAR",
}

export enum ValidationErrorTypes {
  MISSING_REQUIRED_VALUE = "MISSING_REQUIRED_VALUE",
  TOO_SHORT = "TOO_SHORT",
  TOO_LONG = "TOO_LONG",
  TOO_SMALL = "TOO_SMALL",
  TOO_BIG = "TOO_BIG",
}

export interface Query {
  ticket_options: Array<TicketOption>;
}

export interface QueryTicketOptionsArgs {
  contentId?: Maybe<Scalars["String"]>;
}
