import { vlApiClient } from "api/clients";
import gatewayLogin from "appGateway/gatewayLogin";
import authServiceClient from "authService/authGatewayClient";
import { getFirstErrorType } from "authService/errorHandling";
import refreshToken from "authService/refreshToken";
import authServiceSignOut from "authService/signOut";
import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { AuthErrorTypes } from "__gen__/authGatewaySdk";
import UserState from "./UserState";

export type AuthStatus = "loading" | "loggedIn" | "loggedOut" | "error";

export interface AuthState {
  status: AuthStatus;
  token?: string;
}

const useAuthState = () => {
  const { updateUser, reset: resetUserState } = UserState.useContainer();
  const [authState, setAuthState] = useState<AuthState>({ status: "loading" });

  const loggedIn = ({ token }: { token: string }) => {
    setAuthState({ status: "loggedIn", token });
  };

  useEffect(() => {
    tryRefreshingToken();
    // eslint-disable-next-line
  }, []);

  const tryRefreshingToken = async () => {
    try {
      const { token: refreshedToken } = await refreshToken();
      const { token, user } = await gatewayLogin(refreshedToken);
      if (user) {
        updateUser(user);
      }
      if (token) {
        loggedIn({ token });
      } else {
        onError();
      }
    } catch (error: any) {
      const refreshErrorType = getFirstErrorType(error);
      if (refreshErrorType === AuthErrorTypes.INVALID_REFRESH_TOKEN) {
        signOut();
      } else {
        onError();
      }
    }
  };

  const onError = () => {
    setAuthState((current) => ({ ...current, status: "error" }));
  };

  const signOut = async () => {
    try {
      await authServiceSignOut();
      vlApiClient.clearStore();
      authServiceClient.clearStore();
      setAuthState({ status: "loggedOut" });
      resetUserState();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return {
    authState,
    loggedIn,
    onError,
    signOut,
    tryRefreshingToken,
  };
};

export default createContainer(useAuthState);
