import { Descriptions } from "antd";
import Loading from "components/common/layout/Loading";
import NotFound from "components/common/result/NotFound";
import useDateFns from "components/hooks/useDateFns";
import strings from "localisation/strings";
import React from "react";
import PermissionStore from "store/PermissionStore";
import {
  OrganizationDetailsSubscription,
  useOrganizationDetailsSubscription,
} from "__gen__/appService";

interface CellData {
  key: keyof OrganizationDetailsSubscription["organizations"][0];
  label: string;
  formatter?: (value: any) => string;
}

const getCells = (formatDate: (date: string) => string): CellData[] => [
  { key: "type", label: strings("organizationDetails.fields.type.label") },
  { key: "name", label: strings("organizationDetails.fields.name.label") },
  { key: "email", label: strings("organizationDetails.fields.email.label") },
  {
    key: "phoneNumber",
    label: strings("organizationDetails.fields.phoneNumber.label"),
  },
  {
    key: "createdAt",
    label: strings("organizationDetails.fields.createdAt.label"),
    formatter: formatDate,
  },
  {
    key: "updatedAt",
    label: strings("organizationDetails.fields.updatedAt.label"),
    formatter: formatDate,
  },
  { key: "city", label: strings("organizationDetails.fields.city.label") },
  {
    key: "street",
    label: strings("organizationDetails.fields.street.label"),
  },
  {
    key: "houseNumber",
    label: strings("organizationDetails.fields.houseNumber.label"),
  },
  {
    key: "zipCode",
    label: strings("organizationDetails.fields.zipCode.label"),
  },
];

const getCompanyCells = (): CellData[] => [
  {
    key: "companyCode",
    label: strings("organizationDetails.fields.companyCode.label"),
  },
  {
    key: "vatNumber",
    label: strings("organizationDetails.fields.vatNumber.label"),
  },
];

const getNotes = (): CellData[] => [
  { key: "notes", label: strings("organizationDetails.fields.notes.label") },
];

interface OrganizationDetailsProps {
  orgSlug: string;
}

const OrganizationDetails = ({ orgSlug }: OrganizationDetailsProps) => {
  const { canViewOrgData } = PermissionStore.useContainer();
  const { data, loading } = useOrganizationDetailsSubscription({ orgSlug });
  const { formatTimeStamp } = useDateFns();

  if (loading) {
    return <Loading />;
  }

  const orgData = ((data && data.organizations) || [])[0];

  if (!orgData) {
    return <NotFound />;
  }

  if (!canViewOrgData) {
    return (
      <Descriptions bordered>
        <Descriptions.Item
          label={strings("organizationDetails.fields.name.label")}
        >
          {orgData.name}
        </Descriptions.Item>
      </Descriptions>
    );
  }

  return (
    <Descriptions bordered column={2}>
      {getCells(formatTimeStamp).map(({ key, label, formatter = (x) => x }) => (
        <Descriptions.Item key={key} label={label}>
          {formatter(orgData[key])}
        </Descriptions.Item>
      ))}
      {orgData.type === "COMPANY" &&
        getCompanyCells().map(({ key, label, formatter = (x) => x }) => (
          <Descriptions.Item key={key} label={label}>
            {formatter(orgData[key])}
          </Descriptions.Item>
        ))}
      {getNotes().map(({ key, label, formatter = (x) => x }) => (
        <Descriptions.Item key={key} label={label}>
          {formatter(orgData[key])}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

export default OrganizationDetails;
