import VLButton from "components/common/buttons/VLButton";
import { Row, SpreadRow } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import React, { ReactNode, useState } from "react";
import styled, { css } from "styled-components";

const Box = styled.div`
  border-radius: ${({ theme }) => theme.border.radius.small};
  background-color: ${({ theme }) => theme.color.background.primary};
  width: 300px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const Header = styled(SpreadRow)`
  background-color: ${({ theme }) => theme.color.background.opposite};
  padding: ${({ theme }) => theme.margin.large};
`;

const ContentContainer = styled.div<{ minimized: number }>`
  padding: 0 ${({ theme }) => theme.margin.large};
  max-height: 250px;
  overflow-y: scroll;
  transition: max-height 0.2s ease-in-out;
  ${({ minimized }) =>
    !!minimized
      ? css`
          max-height: 0px;
        `
      : ""}
`;

const HeaderButton = styled(VLButton)`
  margin-left: ${({ theme }) => theme.margin.medium};
`;

const MinimizeButton = styled(VLButton)<{ minimized: number }>`
  margin-left: ${({ theme }) => theme.margin.medium};
  transition: transform 0.2s ease-in-out;
  ${({ minimized }) =>
    !!minimized
      ? css`
          transform: rotate(180deg);
        `
      : ""}
`;

interface NotificationBoxProps {
  isClosed: boolean;
  onClose?: () => void;
  title?: string;
  children: ReactNode;
  actions?: ReactNode;
}

export default ({
  title,
  isClosed,
  onClose,
  children,
  actions = null,
}: NotificationBoxProps) => {
  const [isMinimized, setIsMinimized] = useState(false);

  const toggleMinimized = () => setIsMinimized((minimized) => !minimized);

  if (isClosed) {
    return null;
  }
  return (
    <Box>
      <Header>
        <SpreadRow>
          {!!title && (
            <SimpleText color="opposite" bold>
              {title}
            </SimpleText>
          )}
          <Row grow={0}>
            {actions}
            <MinimizeButton
              ghost
              size="small"
              shape="circle"
              icon="down"
              onClick={toggleMinimized}
              minimized={isMinimized ? 1 : 0}
            />
            {!!onClose && (
              <HeaderButton
                ghost
                size="small"
                shape="circle"
                icon="close"
                onClick={onClose}
              />
            )}
          </Row>
        </SpreadRow>
      </Header>
      <ContentContainer minimized={isMinimized ? 1 : 0}>
        {children}
      </ContentContainer>
    </Box>
  );
};
