import { Dropdown, Menu } from "antd";
import { MetaField } from "components/assetMetaSpecs/MetadataFieldTypes";
import VLButton from "components/common/buttons/VLButton";
import { SimpleText } from "components/common/text/SimpleText";
import React from "react";
import { FieldCondition } from "./types";
import styled from "styled-components";

const SelectedFieldName = styled(SimpleText)`
  max-width: 100px;
`;

interface FieldSelectProps {
  fields: MetaField[];
  onFieldSelect: (field: Partial<FieldCondition>) => void;
  selectedField?: MetaField;
}

export default ({ fields, selectedField, onFieldSelect }: FieldSelectProps) => {
  const menu = (
    <Menu>
      {fields.map((field) => (
        <Menu.Item key={field.id} onClick={() => onFieldSelect({ field })}>
          {field.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  if (selectedField) {
    return <SelectedFieldName>{selectedField.name}</SelectedFieldName>;
  }

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <VLButton t="assetSearchDropdown.addCondition" />
    </Dropdown>
  );
};
