import { isUnionType } from "graphql";
import React, { FC } from "react";
import { StringMapped } from "types/common";
import { isObjectType } from "vl-app-client/lib/cms/schema/getObjectTypes";
import directivesToObject from "../functions/directivesToObject";
import { isEnumType } from "../functions/typePredicates";
import BooleanInput from "./BooleanInput";
import DateAndTimeInput from "./DateAndTimeInput";
import DateInput from "./DateInput";
import EnumInput from "./EnumInput";
import ObjectTypeInput from "./ObjectTypeInput";
import PosterUploadInput from "./PosterUploadInput";
import TextInput from "./TextInput";
import TimeInput from "./TimeInput";
import { ResourceInputProps } from "./types";
import UnionInput from "./UnionInput";

const NullComponent = () => null;

const inputComponentMap: StringMapped<FC<ResourceInputProps>> = {
  posterUpload: PosterUploadInput,
  embedCode: NullComponent,
};

export default (props: ResourceInputProps) => {
  const { composer, fieldConfig } = props;
  const directives = directivesToObject(fieldConfig?.astNode?.directives);
  const inputType = directives.input?.type;

  if (directives.hidden || !!directives.preview) {
    return null;
  }

  if (inputType) {
    const InputComponent = inputComponentMap[inputType];

    if (InputComponent) {
      return <InputComponent {...props} />;
    }
  }

  if (composer.getTypeName() === "String") {
    return <TextInput {...props} />;
  }

  if (composer.getTypeName() === "Boolean") {
    return <BooleanInput {...props} />;
  }

  if (composer.getTypeName() === "VlDate") {
    return <DateInput {...props} />;
  }

  if (composer.getTypeName() === "VlTime") {
    return <TimeInput {...props} />;
  }

  if (isUnionType(composer.getType())) {
    return <UnionInput {...props} composer={composer as any} />;
  }

  if (isObjectType(composer)) {
    if (inputType === "dateAndTime") {
      return <DateAndTimeInput {...props} composer={composer} />;
    }
    return <ObjectTypeInput {...props} composer={composer} />;
  }

  if (isEnumType(composer)) {
    return <EnumInput {...props} composer={composer} />;
  }

  return null;
};
