import React, { ReactNode } from "react";
import styled from "styled-components";

interface MyState {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}
interface MyProps {
  children: ReactNode;
}

const Title = styled.span`
  color: ${({ theme }) => theme.color.foreground.error};
`;
const ErrorWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.small};
`;

export default class ErrorBoundary extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <ErrorWrapper>
          <Title>Something went wrong</Title>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </ErrorWrapper>
      );
    }
    return this.props.children;
  }
}
