import { pathOr } from "ramda";

export const isError = (err?: Error): err is Error =>
  !!err && err instanceof Error;

interface GraphQLError {
  extensions: {
    path: string;
    code: string;
  };
  message: string;
}

export const extractGraphQLErrors = pathOr<GraphQLError[]>(
  [],
  ["networkError", "result", "errors"]
);
