import sendPasswordResetEmail from "authService/sendPasswordResetEmail";
import VLButton from "components/common/buttons/VLButton";
import { SimpleText } from "components/common/text/SimpleText";
import GqlForm from "components/gqlForm/GqlForm";
import useGqlForm from "components/gqlForm/hooks/useGqlForm";
import React, { useState } from "react";
import styled from "styled-components";
import { ForgotPasswordForm, ValidationErrorTypes } from "__gen__/forms";
import ForgotPasswordFormSchema from "./forms/ForgotPasswordForm.form.graphql";

const FormContainer = styled.div`
  width: 600px;
  padding: ${({ theme }) => theme.margin.medium};
  background-color: ${({ theme }) => theme.color.background.primary};
`;

export default () => {
  const { form, validate, valuesState, errorsState } =
    useGqlForm<ForgotPasswordForm>({
      schema: ForgotPasswordFormSchema,
      initialValue: {
        email: undefined,
      },
    });
  const [isSent, setIsSent] = useState(false);

  const onSend = async () => {
    const values = validate();
    if (!values) {
      return;
    }
    try {
      const success = await sendPasswordResetEmail(values.email);
      success && setIsSent(true);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
      const message: string = err.message || "";
      if (message.includes("not registered")) {
        errorsState[1]((current) => [
          ...current,
          {
            fieldPath: ["email"],
            errors: ["Email is not registered"],
          },
        ]);
      }
    }
  };

  return (
    <FormContainer>
      {!isSent ? (
        <>
          <GqlForm form={form} errorsState={errorsState} state={valuesState} />
          <VLButton onClick={onSend} type="primary" marginVertical="medium">
            Send
          </VLButton>
        </>
      ) : (
        <SimpleText>Email sent</SimpleText>
      )}
    </FormContainer>
  );
};
