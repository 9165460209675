import gatewayLogin from "appGateway/gatewayLogin";
import authServiceClient from "authService/authGatewayClient";
import {
  VerifyEmailDocument,
  VerifyEmailMutation,
  VerifyEmailMutationVariables,
} from "__gen__/authGatewaySdk";

export default async (token: string) => {
  let authToken;
  const { data } = await authServiceClient.mutate<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >({
    mutation: VerifyEmailDocument,
    variables: { token },
  });

  authToken = data && data.verified.token;
  return await gatewayLogin(authToken);
};
