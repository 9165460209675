import { Empty } from "antd";
import useTableMetaFields from "components/assetList/hooks/useTableMetaFields";
import Loading from "components/common/layout/Loading";
import FolderInfo from "components/mediaAssets/FolderInfo";
import useViewableMeta from "components/permissionControl/hooks/useViewableMeta";
import { queryStringToGraphql } from "components/search/serializers";
import { isEmpty } from "ramda";
import React from "react";
import { useLocation } from "react-router";
import MediaBrowserPreferences from "state/MediaBrowserPreferences";
import {
  MediaAssetsBoolExp,
  useExistingMediasSubscription,
} from "__gen__/appService";
import AssetBrowser from "./AssetBrowser";

const compileSearch = (
  folderId: string,
  search: MediaAssetsBoolExp,
): MediaAssetsBoolExp => {
  if (!isEmpty(search)) {
    return {
      _and: [
        {
          _or: [
            { folder_id: { _eq: folderId } },
            {
              folder: {
                parent_folders: { parent_folder_id: { _eq: folderId } },
              },
            },
          ],
        },
        search,
      ],
    };
  }
  return { folder_id: { _eq: folderId } };
};

interface ExistingAssetsBrowserProps {
  folderId: string;
}

export default ({ folderId }: ExistingAssetsBrowserProps) => {
  const location = useLocation();
  const {
    metaColumns,
    sortBy,
    sortingOrder,
  } = MediaBrowserPreferences.useContainer();
  const metaFields = useTableMetaFields();
  const { viewableMetaFields } = useViewableMeta({ folderId, metaFields });
  const search = queryStringToGraphql(location.search);
  const { data, loading } = useExistingMediasSubscription(
    {
      mediaSearch: compileSearch(folderId, search),
      metadataFieldIds: metaColumns,
      sortBy: sortBy && sortingOrder ? { [sortBy]: sortingOrder } : undefined,
    },
    { shouldResubscribe: true },
  );

  const assets = data?.mediaAssets || [];

  let content = (
    <AssetBrowser mediaAssets={assets} metaFields={viewableMetaFields} />
  );

  if (assets.length === 0) {
    content = <Empty description="No media" />;
  }

  if (loading) {
    content = <Loading />;
  }

  return (
    <>
      {isEmpty(search) && <FolderInfo folderId={folderId} />}
      {content}
    </>
  );
};
