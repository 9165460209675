import { assocPath, path, intersperse } from "ramda";
import React from "react";
import { ReactState } from "state/interfaces/ReactState";
import { FormFieldValidationError } from "../functions/validateForm";
import GqlFormDateInput from "../inputs/GqlFormDateInput";
import GqlFormEnumInput from "../inputs/GqlFormEnumInput";
import GqlFormNumberInput from "../inputs/GqlFormNumberInput";
import GqlFormTextInput from "../inputs/GqlFormTextInput";
import { FormFieldDefinition } from "../types";
import Margin from "components/common/layout/Margin";
import GqlFormBooleanInput from "./GqlFormBooleanInput";

export type SetValue = <T>(updateFn: (current: T) => T) => void;

const FormInput = ({
  field,
  fieldValue,
  setValue,
  parentPath = [],
  errorsState: [errors, setErrors],
}: {
  field: FormFieldDefinition;
  fieldValue: any;
  setValue: SetValue;
  parentPath?: string[];
  errorsState: ReactState<FormFieldValidationError[]>;
}) => {
  const fieldPath = [...parentPath, field.name];
  const onSave = (value: any) => setValue(assocPath(fieldPath, value));
  if (field.type.name === "String") {
    return (
      <GqlFormTextInput
        {...field.directives}
        initialValue={fieldValue}
        onSave={onSave}
      />
    );
  }
  if (field.type.name === "Int" || field.type.name === "Float") {
    return (
      <GqlFormNumberInput
        initialValue={fieldValue}
        onSave={onSave}
        min={field.directives.range?.min}
        max={field.directives.range?.max}
      />
    );
  }
  if (field.type.name === "Boolean") {
    return <GqlFormBooleanInput initialValue={fieldValue} onSave={onSave} />;
  }
  if (field.type.name === "Date") {
    return <GqlFormDateInput initialValue={fieldValue} onSave={onSave} />;
  }
  if (field.type.type === "enum") {
    return (
      <GqlFormEnumInput
        initialValue={fieldValue}
        onSave={onSave}
        options={field.type.options}
      />
    );
  }
  const fieldType = field.type;
  if (fieldType.type === "object") {
    return (
      <>
        {intersperse(
          <Margin key={Math.random()} size="medium" />,
          fieldType.fields.map((objectField) => (
            <FormInput
              key={[fieldPath, objectField.name].join("/")}
              field={objectField}
              fieldValue={path([objectField.name], fieldValue)}
              setValue={setValue}
              parentPath={fieldPath}
              errorsState={[errors, setErrors]}
            />
          )),
        )}
      </>
    );
  }

  return null;
};

export default FormInput;
