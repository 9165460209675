import VLInput from "components/common/input/VLInput";
import VLIcon from "components/common/VLIcon";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";
import AssetSearchDropdown from "./AssetSearchDropdown";
import { searchToQueryString } from "./serializers";
import { emptySearchState, SearchState } from "./types";

const SearchInput = styled(VLInput)`
  margin: 0 ${({ theme }) => theme.margin.medium};
  flex: 1;
  max-width: 240px;

  .ant-input {
    background-color: transparent;
  }
`;

const showSearch = (path: string) => {
  switch (path) {
    case Paths.orgMediaAssets.path:
    case Paths.binnedMediaAssets.path:
    case Paths.folderMediaAssets.path:
    case Paths.assetsSearch.path:
      return true;
    default:
      return false;
  }
};

export default withRouter(({ history, match }: RouteComponentProps) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [searchState, setSearchState] = useState<SearchState>(emptySearchState);

  if (!showSearch(match.path)) {
    return null;
  }

  const onPerformSearch = () => {
    const search = searchToQueryString(searchState);
    const pathname =
      searchState.folder === "ALL"
        ? Paths.assetsSearch.withParams()
        : undefined;
    history.push({
      pathname,
      search,
    });
    setIsDropdownVisible(false);
  };

  const onUpdate = (change: Partial<SearchState>) => {
    setSearchState((currentState) => ({ ...currentState, ...change }));
  };

  const onSearchChange = (text: string) => {
    onUpdate({ q: text });
  };

  const toggleDropdown = () => {
    setIsDropdownVisible((visible) => !visible);
  };

  const closeDropdown = () => setIsDropdownVisible(false);

  const suffix = (
    <VLIcon
      color="secondary"
      type={isDropdownVisible ? "up" : "down"}
      onClick={toggleDropdown}
    />
  );

  return (
    <AssetSearchDropdown
      searchState={searchState}
      onChange={onUpdate}
      onClose={closeDropdown}
      visible={isDropdownVisible}
      onSearch={onPerformSearch}
    >
      <SearchInput
        onKeyUp={(ev) => {
          if (ev.keyCode === 27) {
            closeDropdown();
          }
        }}
        placeholder={strings("common.search")}
        value={searchState.q}
        onTextChange={onSearchChange}
        onPressEnter={onPerformSearch}
        suffix={suffix}
      />
    </AssetSearchDropdown>
  );
});
