import { List, Popconfirm } from "antd";
import Avatar from "components/common/avatar/Avatar";
import VLButton from "components/common/buttons/VLButton";
import { SpreadRow } from "components/common/layout/Flex";
import { MarginedDivider } from "components/common/layout/MarginedDivider";
import { SimpleText } from "components/common/text/SimpleText";
import CanEditOrgMembers from "components/permissionControl/CanEditOrgMembers";
import strings from "localisation/strings";
import React from "react";
import UserState from "state/UserState";
import {
  useDeleteOrgMemberInviteMutation,
  useDeleteOrgMemberMutation,
  useOrganizationMembersSubscription,
} from "__gen__/appService";
import AddMemberInput from "./AddMemberInput";
import MemberLink from "./MemberLink";

const hasEmailOrName = ({
  user: { email, name },
}: {
  user: { email?: string | null; name?: string | null };
}) => !!email || !!name;

interface OrganizationMembersProps {
  orgSlug: string;
}

const OrganizationMembers = ({ orgSlug }: OrganizationMembersProps) => {
  const { user } = UserState.useContainer();
  const { data, loading } = useOrganizationMembersSubscription({ orgSlug });
  const [
    deleteInvite,
    { loading: isDeletingInvite },
  ] = useDeleteOrgMemberInviteMutation();
  const [
    deleteMember,
    { loading: isDeletingMember },
  ] = useDeleteOrgMemberMutation();

  const orgData = ((data && data.organizations) || [])[0];

  if (!orgData) {
    return null;
  }

  return (
    <>
      <SpreadRow grow={0}>
        <SimpleText as="h4" color="tertiary" t="common.members" />
        <CanEditOrgMembers>
          <AddMemberInput orgId={orgData.id} />
        </CanEditOrgMembers>
      </SpreadRow>
      <MarginedDivider margin="small" />
      <List<typeof orgData.orgMembers[0]>
        loading={loading}
        itemLayout="horizontal"
        dataSource={orgData.orgMembers.filter(hasEmailOrName)}
        renderItem={({ id, user: { photoUrl, name, email, id: userId } }) => (
          <List.Item
            actions={
              userId !== user?.id
                ? [
                    <CanEditOrgMembers key="delete">
                      <Popconfirm
                        title={strings("folderInfo.deleteConfirm")}
                        onConfirm={() =>
                          deleteMember({ variables: { memberId: id } })
                        }
                        okText={strings("common.yes")}
                        cancelText={strings("common.no")}
                        placement="topRight"
                      >
                        <VLButton icon="delete" loading={isDeletingMember} />
                      </Popconfirm>
                    </CanEditOrgMembers>,
                  ]
                : undefined
            }
          >
            <List.Item.Meta
              avatar={<Avatar src={photoUrl} name={name || email} />}
              title={
                <MemberLink
                  memberId={id}
                  user={{ name, email }}
                  orgSlug={orgSlug}
                />
              }
            />
          </List.Item>
        )}
      />
      {orgData.orgMemberInvites.length > 0 && (
        <>
          <SimpleText as="h4" color="tertiary" t="common.invites" />
          <MarginedDivider margin="small" />
          <List<typeof orgData.orgMemberInvites[0]>
            loading={loading}
            itemLayout="horizontal"
            dataSource={orgData.orgMemberInvites}
            renderItem={({ inviteeEmail, id }) => (
              <List.Item
                actions={[
                  <VLButton
                    key="delete"
                    icon="delete"
                    loading={isDeletingInvite}
                    onClick={() =>
                      deleteInvite({ variables: { inviteId: id } })
                    }
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar icon="mail" />}
                  title={inviteeEmail}
                  description={strings("organizationMembers.invitePending")}
                />
              </List.Item>
            )}
          />
        </>
      )}
    </>
  );
};

export default OrganizationMembers;
