import { DatePicker, Form, message, Switch } from "antd";
import VLButton from "components/common/buttons/VLButton";
import { Row } from "components/common/layout/Flex";
import ScreenTitle from "components/common/text/ScreenTitle";
import FormField from "components/forms/FormField";
import strings from "localisation/strings";
import React from "react";
import { useCreateLinkShareMutation } from "__gen__/appService";
import { ModalType } from "./ShareAssetModal";

interface ShareAssetFormProps {
  mediaAssetId: string;
  changeContent: (content: ModalType) => void;
}

export default ({ mediaAssetId, changeContent }: ShareAssetFormProps) => {
  const [createLinkShare] = useCreateLinkShareMutation();

  const onFinish = async (variables: any) => {
    variables.mediaId = mediaAssetId;
    variables.validFrom = variables.validFrom
      ? new Date(variables.validFrom).toISOString()
      : null;
    variables.validUntil = variables.validUntil
      ? new Date(variables.validUntil).toISOString()
      : null;
    variables.downloadEnabled = variables.downloadEnabled
      ? variables.downloadEnabled
      : false;
    try {
      await createLinkShare({
        variables,
      });
      changeContent("TABLE");
    } catch (err) {
      message.error(strings("shareAssetForm.createFailed"));
    }
  };
  return (
    <div>
      <ScreenTitle>{strings("shareAssetForm.formTitle")}</ScreenTitle>
      <Form layout="vertical" onFinish={onFinish}>
        <FormField
          fieldKey="title"
          label={strings("shareAssetForm.fields.title.label")}
          rules={[
            {
              required: true,
              message: strings("shareAssetForm.fields.title.message"),
            },
          ]}
        />
        <Row mainAxis="space-between">
          <Form.Item
            name="validFrom"
            label={strings("shareAssetForm.fields.validFrom.label")}
            style={{ width: "calc(50% - 12px)" }}
          >
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="validUntil"
            label={strings("shareAssetForm.fields.validUntil.label")}
            style={{ width: "calc(50% - 12px)" }}
          >
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Row>
        <Form.Item
          name="downloadEnabled"
          label={strings("shareAssetForm.fields.downloadable")}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Row mainAxis="flex-end">
          <VLButton
            type="default"
            marginHorizontal="medium"
            t="buttons.cancel"
            onClick={() => changeContent("TABLE")}
          />
          <VLButton htmlType="submit" type="primary" t="buttons.save" />
        </Row>
      </Form>
      <div></div>
    </div>
  );
};
