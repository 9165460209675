import { Dropdown } from "antd";
import Avatar from "components/common/avatar/Avatar";
import React from "react";
import UserState from "state/UserState";
import styled from "styled-components";
import UserDropdown from "./UserDropdown";

const UserAvatar = styled(Avatar)`
  margin: ${({ theme }) => theme.margin.medium};
  margin-right: 0;
  cursor: pointer;
`;

export default () => {
  const { user, selectRole, userState } = UserState.useContainer();

  if (!user) {
    return null;
  }

  const { email, photoUrl, name } = user;

  return (
    <Dropdown
      overlay={UserDropdown({
        user,
        selectRole,
        selectedRole: userState.selectedRole,
      })}
      trigger={["hover"]}
    >
      <UserAvatar src={photoUrl} name={name || email} />
    </Dropdown>
  );
};
