import Loading from "components/common/layout/Loading";
import NotFound from "components/common/result/NotFound";
import MediaAssets from "components/mediaAssets/MediaAssets";
import { RightSidebar } from "components/rightSidebar/RightSidebar";
import { WithFolderId } from "navigation/Paths";
import React from "react";
import { RouteComponentProps } from "react-router";
import FolderStore from "store/FolderStore";
import AppScreen from "./AppScreen";

export default ({
  match: {
    params: { folderId },
  },
}: RouteComponentProps<Partial<WithFolderId>>) => {
  const { rootFolder, loading } = FolderStore.useContainer();
  const fid = folderId || rootFolder?.id;

  if (loading) {
    return <Loading />;
  }

  if (!fid) {
    return (
      <AppScreen>
        <NotFound />
      </AppScreen>
    );
  }

  return (
    <>
      <AppScreen>
        <MediaAssets folderId={fid} />
      </AppScreen>
      <RightSidebar />
    </>
  );
};
