import CMS from "components/cms/CMS";
import ContentDetails from "components/cms/ContentDetails";
import { ErrorSidebar } from "components/cmsV2/preview/ErrorSidebar";
import Loading from "components/common/layout/Loading";
import { RightSidebar } from "components/rightSidebar/RightSidebar";
import Paths from "navigation/Paths";
import React from "react";
import { useParams } from "react-router-dom";
import CmsErrorState from "state/CmsErrorState";
import {
  CMS_ERRORS_SIDEBAR,
  CONTENT_DETAILS_SIDEBAR,
} from "state/RightSidebarState";
import ContentStore from "store/ContentStore";
import CMSv2 from "../components/cmsV2/CMSv2";
import AppScreen from "./AppScreen";

const VersionSwitch = ({ projectId }: { projectId: string }) => {
  const { projectExtraData, loading } = ContentStore.useContainer();

  if (loading) {
    return <Loading />;
  }

  if (projectExtraData?.cmsVersion === 2) {
    return <CMSv2 projectId={projectId} />;
  }

  return <CMS projectId={projectId} />;
};

export default () => {
  const { projectId } = useParams<typeof Paths.projectCms.params>();

  return (
    <CmsErrorState.Provider>
      <ContentStore.Provider initialState={{ projectId }}>
        <AppScreen>
          <VersionSwitch projectId={projectId} />
        </AppScreen>
        <RightSidebar
          renderers={[
            { type: CONTENT_DETAILS_SIDEBAR, component: <ContentDetails /> },
            { type: CMS_ERRORS_SIDEBAR, component: <ErrorSidebar /> },
          ]}
        />
      </ContentStore.Provider>
    </CmsErrorState.Provider>
  );
};
