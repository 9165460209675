import { Checkbox, Input, Modal, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import VLButton, { VLButtonProps } from "components/common/buttons/VLButton";
import DescriptionItem from "components/common/descriptions/DescriptionItem";
import useOrgRoles from "components/organizations/hooks/useOrgRoles";
import isOrgOwnersRole from "components/roleDetails/functions/isOrgOwnersRole";
import strings from "localisation/strings";
import { values } from "ramda";
import React, { useState } from "react";
import {
  PermissionRoleMetaScopesConstraint,
  PermissionScopesEnum,
} from "vl-app-client/lib/__gen__/appServiceSdk";
import { useUpsertMetadataFieldMutation } from "__gen__/appService";
import changeFieldType from "./changeFieldType";
import DefaultValuesSelect from "./DefaultValuesSelect";
import MetadataFieldOptions from "./MetadataFieldOptions";
import { MetadataFieldTypes, MetaField } from "./MetadataFieldTypes";
import PossibleValuesSelect from "./PossibleValuesSelect";

interface UpsertMetadataProps extends VLButtonProps {
  orgId: string;
  initialValues?: MetaField;
}

export default ({
  orgId,
  initialValues = {
    name: "",
    type: MetadataFieldTypes.SIMPLE_TEXT,
    options: {},
  },
  ...buttonProps
}: UpsertMetadataProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [upsertMetadataField, { loading }] = useUpsertMetadataFieldMutation();
  const orgRoles = useOrgRoles({ orgId });

  const [field, setField] = useState<MetaField>(initialValues);

  const onFieldChange = (newField: Partial<MetaField>) => {
    setField((oldField) => ({ ...oldField, ...(newField as any) }));
  };

  const onChangeType = (newType: MetadataFieldTypes) => {
    setField((oldField) => changeFieldType(oldField, newType));
  };

  const onNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onFieldChange({ name: ev.target.value });
  };

  const onRequiredChanged = (ev: CheckboxChangeEvent) => {
    setField((oldField) => ({
      ...oldField,
      options: { ...(oldField.options as any), required: ev.target.checked },
    }));
  };

  const showModal = () => {
    setField(initialValues);
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const tryUpsertingField = async () => {
    try {
      await upsertMetadataField({
        variables: {
          field: {
            id: field.id,
            name: field.name,
            type: field.type,
            options: field.options,
            org_id: orgId,
            role_meta_scopes: {
              on_conflict: {
                constraint:
                  PermissionRoleMetaScopesConstraint.role_meta_scopes_pkey,
                update_columns: [],
              },
              data: orgRoles
                .filter((role) => !isOrgOwnersRole(role))
                .map(({ id }) => ({
                  role_id: id,
                  scopes: [
                    PermissionScopesEnum.VIEW,
                    PermissionScopesEnum.EDIT,
                  ],
                })),
            },
          },
        },
      });
      hideModal();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  return (
    <>
      <VLButton
        icon="plus"
        onClick={showModal}
        loading={loading}
        {...buttonProps}
      />
      <Modal
        onCancel={hideModal}
        visible={modalVisible}
        onOk={tryUpsertingField}
        okText={strings("buttons.save")}
        cancelText={strings("buttons.cancel")}
        destroyOnClose
      >
        <DescriptionItem marginVertical="medium" labelT="common.name">
          <Input value={field.name} onChange={onNameChange} />
        </DescriptionItem>
        <DescriptionItem marginVertical="medium" labelT="common.type">
          <Select<MetadataFieldTypes>
            value={field.type}
            onChange={onChangeType}
          >
            {values(MetadataFieldTypes).map((value) => (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </DescriptionItem>
        <DescriptionItem marginVertical="medium" labelT="common.required">
          <Checkbox
            onChange={onRequiredChanged}
            checked={field.options.required}
          >
            {strings("common.required")}
          </Checkbox>
        </DescriptionItem>
        <DescriptionItem
          marginVertical="medium"
          labelT="metadataSpecs.possibleValues"
        >
          <PossibleValuesSelect field={field} updateField={onFieldChange} />
        </DescriptionItem>
        <DescriptionItem
          marginVertical="medium"
          labelT="metadataSpecs.defaultValues"
        >
          <DefaultValuesSelect field={field} updateField={onFieldChange} />
        </DescriptionItem>
        <MetadataFieldOptions field={field} updateField={onFieldChange} />
      </Modal>
    </>
  );
};
