import { ApolloError } from "apollo-client";
import { getFirstErrorType } from "authService/errorHandling";
import loginWithEmail from "authService/loginWithEmail";
import signUpWithEmail from "authService/signUpWithEmail";
import verifyEmail from "authService/verifyEmail";
import { useState } from "react";
import AuthState from "state/AuthState";
import UserState, { User } from "state/UserState";
import { AuthErrorTypes } from "__gen__/authGatewaySdk";

export interface EmailPassword {
  email: string;
  password: string;
}

interface RememberUser {
  rememberUser: boolean;
}

export default () => {
  const { updateUser } = UserState.useContainer();
  const { onError, loggedIn } = AuthState.useContainer();
  const [error, setError] = useState<ApolloError>();
  const [errorType, setErrorType] = useState<AuthErrorTypes>();
  const [loading, setLoading] = useState(false);

  const authenticate = async <T>(
    params: T,
    method: (params: T) => Promise<{
      token: string | null | undefined;
      user: User | undefined;
    }>,
  ) => {
    setError(undefined);
    setErrorType(undefined);
    setLoading(true);
    try {
      const { token, user } = await method(params);
      setLoading(false);
      if (user) {
        updateUser(user);
      }
      if (token) {
        loggedIn({ token });
      } else {
        onError();
      }
    } catch (error: any) {
      setLoading(false);
      setError(error);
      setErrorType(getFirstErrorType(error));
    }
  };

  const login = async (params: EmailPassword & RememberUser) =>
    await authenticate(params, loginWithEmail);
  const signUp = async (params: EmailPassword) =>
    await authenticate(params, signUpWithEmail);
  const emailVerification = async (token: string) => {
    await authenticate(token, verifyEmail);
  };

  return { login, signUp, emailVerification, error, errorType, loading };
};
