import { path } from "ramda";
import ContentStore from "store/ContentStore";
import { VLLocales } from "vl-app-client/lib/cms/contentInterfaces";

const isStringArray = (value?: any): value is string[] => Array.isArray(value);

export default () => {
  const {
    getContentRow,
    projectId,
    selectedLanguage,
    setSelectedLanguage,
  } = ContentStore.useContainer();
  const contentRow = getContentRow(projectId);
  const languages = path(
    ["localeData", VLLocales.NO_LOCALE, "languages"],
    contentRow,
  );
  const dl = path(
    ["localeData", VLLocales.NO_LOCALE, "defaultLanguage"],
    contentRow,
  );

  const projectLanguages = isStringArray(languages) ? languages : undefined;
  const defaultLanguage = typeof dl === "string" ? dl : undefined;

  return {
    projectLanguages,
    defaultLanguage,
    selectedLanguage,
    setSelectedLanguage,
  };
};
