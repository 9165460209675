import UploadingAssetMetadataModal from "components/assetMetadata/UploadingAssetMetadataModal";
import useDateFns from "components/hooks/useDateFns";
import NotificationBox from "components/notificationBox/NotificationBox";
import strings from "localisation/strings";
import { contains } from "ramda";
import React, { Fragment, useEffect, useState } from "react";
import UploadingFilesMetadataState from "state/UploadingFilesMetadataState";
import UploadState from "state/UploadState";
import VLButton from "../buttons/VLButton";
import { SpreadRow } from "../layout/Flex";
import { MarginedDivider } from "../layout/MarginedDivider";
import { SimpleText } from "../text/SimpleText";
import VLTooltip from "../VLTooltip";
import UploadFileList from "./UploadFileList";

const UploadNotificationBox = () => {
  const {
    uploadStartedFiles,
    completedFiles,
    failedFiles,
    retryFailedFiles
  } = UploadState.useContainer();
  const {
    batchList,
    findBatchByFileId
  } = UploadingFilesMetadataState.useContainer();
  const [isClosed, setIsClosed] = useState(false);
  const { formatRelativeTime } = useDateFns();

  useEffect(() => {
    if (uploadStartedFiles.length > 0) {
      setIsClosed(false);
    }
  }, [completedFiles.length, uploadStartedFiles.length, failedFiles.length]);

  const close = () => setIsClosed(true);
  const uploadFinishedFiles = uploadStartedFiles.filter(
    file => !findBatchByFileId(file.id)
  );

  let title = strings("uploadNotification.uploading.many", {
    fileCount: uploadStartedFiles.length
  });
  if (completedFiles.length > 0) {
    if (completedFiles.length === 1) {
      title = strings("uploadNotification.uploaded.one");
    } else {
      title = strings("uploadNotification.uploaded.many", {
        fileCount: completedFiles.length
      });
    }
  } else if (uploadStartedFiles.length === 1) {
    title = strings("uploadNotification.uploaded.one");
  }
  if (isClosed || uploadStartedFiles.length === 0) {
    return null;
  }
  return (
    <NotificationBox
      title={title}
      isClosed={isClosed}
      onClose={close}
      actions={
        failedFiles.length > 0 && (
          <VLTooltip t="uploadNotification.retryFailed">
            <VLButton
              ghost
              size="small"
              shape="circle"
              icon="reload"
              onClick={retryFailedFiles}
            />
          </VLTooltip>
        )
      }
    >
      {batchList.map(({ id, fileIds, createdAt }) => (
        <Fragment key={id}>
          <SpreadRow marginVertical="medium">
            <SimpleText fontSize="small" color="tertiary">
              {strings("uploadNotification.startedAt", {
                startedAt: formatRelativeTime(createdAt)
              })}
            </SimpleText>
            <UploadingAssetMetadataModal
              openOnMount
              size="small"
              batchId={id}
            />
          </SpreadRow>
          <MarginedDivider margin="small" />
          <UploadFileList
            files={uploadStartedFiles.filter(file =>
              contains(file.id, fileIds)
            )}
            showProgress
          />
        </Fragment>
      ))}
      {uploadFinishedFiles.length > 0 && (
        <>
          <SimpleText
            fontSize="small"
            color="tertiary"
            t="uploadNotification.uploadedFiles"
          />
          <MarginedDivider margin="small" />
          <UploadFileList files={uploadFinishedFiles} showProgress />
        </>
      )}
    </NotificationBox>
  );
};

export default UploadNotificationBox;
