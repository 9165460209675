import VLButton from "components/common/buttons/VLButton";
import { Row } from "components/common/layout/Flex";
import React, { useState } from "react";
import styled from "styled-components";
import useAssetActions from "./hooks/useAssetActions";
import MoveAssetsModal from "./MoveAssetsModal";

export type ActionTypes = "DELETE" | "RESTORE" | "DOWNLOAD" | "MOVE";

interface MediaBulkActionsProps {
  assetIds: string[];
  binned: boolean;
  clearSelection?: () => void;
  setActiveAction: (action: ActionTypes | undefined) => void;
  activeAction?: ActionTypes;
}

const SpacedButton = styled(VLButton)`
  margin-left: ${({ theme }) => theme.margin.medium};
`;

export default ({
  assetIds,
  binned,
  clearSelection = () => {},
  setActiveAction,
  activeAction,
}: MediaBulkActionsProps) => {
  const {
    moveToBinAction: [moveToBin, { loading: deleting }],
    restoreAction: [restore, { loading: restoring }],
    createDownloadAction: [downloadAssets, { loading: creatingDownload }],
  } = useAssetActions();
  const [moveModalVisible, setMoveModalVisible] = useState(false);

  const onMove = async () => {
    if (activeAction === "MOVE") {
      if (assetIds.length > 0) {
        setMoveModalVisible(true);
      } else {
        setActiveAction(undefined);
      }
    } else {
      setActiveAction("MOVE");
      clearSelection();
    }
  };

  const onAssetsMoved = () => {
    setMoveModalVisible(false);
    setActiveAction(undefined);
    clearSelection();
  };

  const onDownload = async () => {
    if (activeAction === "DOWNLOAD") {
      if (assetIds.length > 0) {
        await downloadAssets({ assetIds });
      }
      setActiveAction(undefined);
    } else {
      setActiveAction("DOWNLOAD");
    }
    clearSelection();
  };

  const onDelete = async () => {
    if (activeAction === "DELETE") {
      if (assetIds.length > 0) {
        await moveToBin({ assetIds });
      }
      setActiveAction(undefined);
    } else {
      setActiveAction("DELETE");
    }
    clearSelection();
  };

  const onRestore = async () => {
    if (activeAction === "RESTORE") {
      if (assetIds.length > 0) {
        await restore({ assetIds });
      }
      setActiveAction(undefined);
    } else {
      setActiveAction("RESTORE");
    }
    clearSelection();
  };

  const anySelected = assetIds.length > 0;

  if (binned) {
    return (
      <VLButton
        type={anySelected && activeAction === "RESTORE" ? "primary" : undefined}
        onClick={onRestore}
        loading={restoring}
        t={activeAction === "RESTORE" ? "buttons.restore" : undefined}
        icon="rollback"
      />
    );
  }
  return (
    <Row>
      <VLButton
        type={anySelected && activeAction === "DELETE" ? "primary" : undefined}
        onClick={onDelete}
        loading={deleting}
        t={activeAction === "DELETE" ? "buttons.delete" : undefined}
        icon="delete"
      />
      <SpacedButton
        type={anySelected && activeAction === "MOVE" ? "primary" : undefined}
        onClick={onMove}
        t={activeAction === "MOVE" ? "common.move" : undefined}
        icon="folder"
      />
      <MoveAssetsModal
        visible={moveModalVisible}
        assetIds={assetIds}
        onDone={onAssetsMoved}
      />
      <SpacedButton
        type={
          anySelected && activeAction === "DOWNLOAD" ? "primary" : undefined
        }
        onClick={onDownload}
        loading={creatingDownload}
        t={activeAction === "DOWNLOAD" ? "buttons.download" : undefined}
        icon="download"
      />
    </Row>
  );
};
