import AwsS3Multipart, { AwsS3MultipartOptions } from "@uppy/aws-s3-multipart";
import { FailedUppyFile, UploadedUppyFile, Uppy } from "@uppy/core";
import Env from "config/Env";
import { pluck, values } from "ramda";
import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import uuid from "uuid/v4";
import { UploadFileMeta } from "__gen__/appGatewaySdk";
import patchAwsPlugin, { UploadHandler } from "./functions/patchAwsPlugin";
import UploadingFilesMetadataState from "./UploadingFilesMetadataState";

const apiUrl = Env.REACT_APP_GATEWAY_URL;

export const uploadingHasFailed = (
  file: UploadingFile,
): file is FailedUppyFile<UploadFileMeta> => !!(file as any).error;

export type UploadingFile =
  | FailedUppyFile<UploadFileMeta>
  | UploadedUppyFile<UploadFileMeta>;

const uploadHandler: UploadHandler = {
  onFileCreated: () => {},
};

export default createContainer(() => {
  const [uppy] = useState(new Uppy());
  const [files, setFiles] = useState<{
    [index: string]: UploadingFile;
  }>({});
  const {
    createUploadBatch,
    removeFiles: removeFilesFromBatch,
    onUploadFinished,
  } = UploadingFilesMetadataState.useContainer();

  const fileValues = values(files);

  useEffect(() => {
    const awsPluginId = `VL_AWS_PLUGIN-${Math.random()}`;
    uppy.use(AwsS3Multipart, {
      companionUrl: apiUrl,
      id: awsPluginId,
      limit: 2,
    } as AwsS3MultipartOptions);
    patchAwsPlugin(uppy.getPlugin(awsPluginId), uploadHandler);
    const updateState = () => setFiles(uppy.getState<UploadFileMeta>().files);
    uppy.on("file-added", updateState);
    uppy.on("file-removed", updateState);
    uppy.on("upload-progress", updateState);
    uppy.on("complete", updateState);
    uppy.on("upload-error", updateState);
    // eslint-disable-next-line
  }, [uppy]);

  useEffect(() => {
    uploadHandler.onFileCreated = onUploadFinished;
  }, [onUploadFinished]);

  const addFile = (file: File, meta: Omit<UploadFileMeta, "mediaAssetId">) => {
    try {
      uppy.addFile<UploadFileMeta>({
        name: file.name,
        type: file.type,
        data: file,
        meta: { ...meta, mediaAssetId: uuid() },
      });
    } catch (err) {}
  };

  const filesWaitingForUpload = fileValues.filter(
    (file) => !!file.progress && !file.progress.uploadStarted,
  );

  const uploadStartedFiles = fileValues.filter(
    (file) => !!file.progress && !!file.progress.uploadStarted,
  );

  const completedFiles = fileValues.filter(
    (file) => !!file.progress && file.progress.uploadComplete,
  );

  const failedFiles = uploadStartedFiles.filter(
    (file) => !!(file as any).error,
  );

  const retryFailedFiles = () => {
    failedFiles.map(({ id }) => uppy.retryUpload(id));
  };

  const clearWaiting = () => {
    filesWaitingForUpload.forEach((file) => uppy.removeFile(file.id));
  };

  const clearCompleted = () => {
    completedFiles.forEach((file) => uppy.removeFile(file.id));
  };

  const removeFile = (fileId: string) => {
    removeFilesFromBatch([fileId]);
    uppy.removeFile(fileId);
  };

  const startUpload = () => {
    createUploadBatch(pluck("id", filesWaitingForUpload));
    uppy.upload();
  };

  return {
    uppy,
    files: values(files),
    addFile,
    filesWaitingForUpload,
    uploadStartedFiles,
    clearWaiting,
    removeFile,
    startUpload,
    clearCompleted,
    completedFiles,
    failedFiles,
    retryFailedFiles,
  };
});
