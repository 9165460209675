import "@videojs/themes/dist/forest/index.css";
import React, { useEffect, useState } from "react";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import "video.js/dist/video-js.css";

export default (props: VideoJsPlayerOptions) => {
  const [videoNode, setVideoNode] = useState<HTMLVideoElement | null>(null);
  const [currentPlayer, setCurrentPlayer] = useState<VideoJsPlayer>();

  useEffect(() => {
    let player: VideoJsPlayer | undefined;
    if (videoNode) {
      player = videojs(videoNode, props);
      //player.on("error", () => console.log("error"));
    }
    setCurrentPlayer(player);
    return () => {
      player?.dispose();
      setCurrentPlayer(undefined);
    };
    // eslint-disable-next-line
  }, [videoNode]);

  useEffect(() => {
    if (currentPlayer) {
      currentPlayer.src(props.sources || []);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(props.sources)]);

  return (
    <div data-vjs-player>
      <video
        data-setup={`{"fluid": true}`}
        ref={setVideoNode}
        className="video-js vjs-big-play-centered"
      />
    </div>
  );
};
