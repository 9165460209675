import authGatewayClient from "authService/authGatewayClient";
import {
  SendPasswordResetEmailMutation,
  SendPasswordResetEmailMutationVariables,
  SendPasswordResetEmailDocument,
} from "__gen__/authGatewaySdk";

export default async (email: string) => {
  const { data } = await authGatewayClient.mutate<
    SendPasswordResetEmailMutation,
    SendPasswordResetEmailMutationVariables
  >({
    mutation: SendPasswordResetEmailDocument,
    variables: { email },
  });

  return data?.sent;
};
