import { Column } from "components/common/layout/Flex";
import React from "react";
import styled from "styled-components";
import ComponentEditor from "./ComponentEditor";
import { ValidationError, VlComponentState } from "./types";
export interface PageEditorProps {
  onValueChange: (newValue: any) => void;
  page: VlComponentState;
  errors: ValidationError[];
}
const Wrapper = styled(Column)`
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  border-radius: ${({ theme }) => theme.border.radius.small};
`;

export default function PageEditor(props: PageEditorProps) {
  const { onValueChange, page, errors } = props;
  return (
    <Column>
      <Wrapper padding="large">
        <ComponentEditor
          value={page.props}
          onValueChange={onValueChange}
          definition={{ schemaUrl: page.schemaUrl }}
          errors={errors}
          dataPath={[]}
        />
      </Wrapper>
    </Column>
  );
}
