import { InputNumber } from "antd";
import React from "react";
import { isDefined } from "types/predicates";
import TextPreview from "./TextPreview";
import useEditableField from "./useEditableField";

const formatMaybeValue = (value?: number) => {
  return isDefined(value) ? `${value}` : "";
};

interface EditableNumberProps {
  initialValue?: number;
  onSave: (text?: number) => void;
  placeholder?: number;
}

export default ({
  initialValue,
  onSave: onSaveNumber,
  placeholder,
}: EditableNumberProps) => {
  const {
    isEditing,
    value,
    setValue,
    startEditing,
    stopEditing,
  } = useEditableField<number | undefined>({
    initialValue,
    initialEditingValue: (v) => v || placeholder,
  });

  if (!isEditing) {
    return (
      <TextPreview
        text={formatMaybeValue(value)}
        startEditing={startEditing}
        placeholder={formatMaybeValue(placeholder)}
      />
    );
  }

  const onSave = () => {
    const v = parseFloat(`${value}`);
    const newValue = isNaN(v) ? undefined : v;
    stopEditing();
    setValue(newValue);
    onSaveNumber(newValue);
  };

  const onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if ((ev.metaKey || ev.ctrlKey) && ev.key === "Enter") {
      onSave();
    }
  };

  return (
    <InputNumber
      autoFocus
      onKeyDown={onKeyDown}
      onBlur={onSave}
      value={value}
      onChange={(v) =>
        typeof v === "string" ? setValue(parseFloat(v)) : setValue(v)
      }
    />
  );
};
