import { InputNumber } from "antd";
import AssetGrid from "components/assetGrid/AssetGrid";
import SortOrderSelect from "components/assetGrid/SortOrderSelect";
import AssetList from "components/assetList/AssetList";
import ColumnSelect from "components/assetList/table/ColumnSelect";
import { MetaField } from "components/assetMetaSpecs/MetadataFieldTypes";
import VLButton from "components/common/buttons/VLButton";
import { Row, SpreadRow } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import MediaBulkActions, {
  ActionTypes,
} from "components/mediaAssets/MediaBulkActions";
import { VLAsset, VLFolder } from "components/mediaAssets/types";
import React, { useState } from "react";
import MediaBrowserPreferences from "state/MediaBrowserPreferences";
import styled from "styled-components";

interface AssetViewProps {
  mediaAssets: VLAsset[];
  metaFields: MetaField[];
  isBinnedMedia?: boolean;
  onFolderClick?: (folder: VLFolder) => void;
}

const Button = styled(VLButton)`
  margin-left: ${({ theme }) => theme.margin.medium};
`;

export default ({
  mediaAssets,
  isBinnedMedia,
  metaFields,
  onFolderClick,
}: AssetViewProps) => {
  const [activeAction, setActiveAction] = useState<ActionTypes>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const {
    gridColumnCount,
    setGridColumnCount,
    isGrid,
    toggleIsGrid,
  } = MediaBrowserPreferences.useContainer();

  const onClick = () => {
    toggleIsGrid();
  };

  const onChange = (value: number | string | undefined) => {
    if (typeof value === "number") {
      setGridColumnCount(value);
    }
  };

  return mediaAssets.length === 0 ? null : (
    <>
      <SpreadRow marginVertical="medium" crossAxis="center" grow={0}>
        <MediaBulkActions
          activeAction={activeAction}
          setActiveAction={setActiveAction}
          clearSelection={() => setSelectedIds([])}
          assetIds={selectedIds}
          binned={!!isBinnedMedia}
        />
        <Row grow={0} crossAxis="center">
          {!isGrid ? (
            <ColumnSelect />
          ) : (
            <>
              <SortOrderSelect />
              <SimpleText margin="medium" t="common.columns" />
              <InputNumber
                min={1}
                max={100}
                defaultValue={gridColumnCount}
                onChange={onChange}
              />
            </>
          )}
          <Button onClick={onClick} icon={!isGrid ? "grid" : "list"} />
        </Row>
      </SpreadRow>
      {isGrid ? (
        <AssetGrid
          mediaAssets={mediaAssets}
          activeAction={activeAction}
          gridColumnCount={gridColumnCount}
          selectedIds={selectedIds}
          onFolderClick={onFolderClick}
          setSelectedIds={setSelectedIds}
        />
      ) : (
        <AssetList
          mediaAssets={mediaAssets}
          metaFields={metaFields}
          activeAction={activeAction}
          onFolderClick={onFolderClick}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
      )}
    </>
  );
};
