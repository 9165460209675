import { SorterResult } from "antd/lib/table/interface";
import { pluck, sortWith } from "ramda";
import { useEffect, useState } from "react";
import MediaSidebarState from "state/MediaSidebarState";
import { VLAsset } from "../../mediaAssets/types";
import { ascend, descend } from "../assetSorters";

export default (assets: VLAsset[]) => {
  const {
    setAssetList,
    assetList,
    selectedAssetId,
  } = MediaSidebarState.useContainer();
  const [currentSorter, setCurrentSorter] = useState<SorterResult<VLAsset>>();

  const onListChanged = (sorter: SorterResult<VLAsset> | undefined) => {
    if (!sorter) {
      setAssetList(pluck("id", assets));
      return;
    }
    const orderFunction = sorter.order === "descend" ? descend : ascend;
    const sortFunctions =
      typeof sorter.column?.sorter === "function"
        ? [orderFunction(sorter.column?.sorter)]
        : [];
    const sortedAssets = sortWith(sortFunctions, assets);
    setAssetList(pluck("id", sortedAssets));
  };

  useEffect(() => {
    onListChanged(currentSorter);
    // eslint-disable-next-line
  }, [JSON.stringify({ currentSorter, assets })]);

  const onTableChange = (
    _: any,
    __: any,
    sorter: SorterResult<VLAsset> | Array<SorterResult<VLAsset>>,
  ) => {
    if (Array.isArray(sorter)) {
      setCurrentSorter(sorter[0]);
    } else {
      setCurrentSorter(sorter);
    }
  };

  const selectedAssetIndex = assetList.indexOf(selectedAssetId);

  return { onTableChange, selectedAssetIndex };
};
