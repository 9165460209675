import { Checkbox } from "antd";
import { SimpleText } from "components/common/text/SimpleText";
import React from "react";

interface EditableDateProps {
  initialValue?: boolean;
  onSave: (text?: boolean) => void;
}

export default ({ initialValue, onSave }: EditableDateProps) => {
  return (
    <Checkbox
      checked={initialValue}
      onChange={(e) => {
        onSave(e.target.checked);
      }}
    >
      {typeof initialValue !== "boolean" && <SimpleText t="common.notSet" />}
      {typeof initialValue === "boolean" && (
        <SimpleText t={initialValue ? "common.yes" : "common.no"} />
      )}
    </Checkbox>
  );
};
