import { Button, Form, message } from "antd";
import { NativeButtonProps } from "antd/lib/button/button";
import { useForm } from "antd/lib/form/Form";
import { Store } from "antd/lib/form/interface";
import VLInput from "components/common/input/VLInput";
import AppContainer from "components/common/layout/AppContainer";
import { SimpleText } from "components/common/text/SimpleText";
import VLIcon from "components/common/VLIcon";
import VerifyEmail from "components/login/VerifyEmail";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useEmailAuth from "state/hooks/useEmailAuth";
import styled from "styled-components";
import { AuthErrorTypes } from "__gen__/authGatewaySdk";

const StyledForm = styled(Form)`
  max-width: 300px;
  flex: 1;
`;

const B = (props: NativeButtonProps) => <Button {...props} />;
const LoginButton = styled(B)`
  width: 100%;
`;

export default () => {
  const [form] = useForm();
  const { signUp, errorType } = useEmailAuth();
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (errorType === AuthErrorTypes.DUPLICATE_EMAIL) {
      form.setFields([
        {
          name: "email",
          value: form.getFieldValue("email"),
          errors: [
            strings(
              "register.emailAndPassword.fieldErrors.auth/email-already-in-use",
            ),
          ],
        },
      ]);
    } else if (
      !!errorType &&
      errorType !== AuthErrorTypes.EMAIL_VERIFICATION_REQUIRED
    ) {
      message.error(strings("register.failed"));
    }
    // eslint-disable-next-line
  }, [errorType]);

  const onFinish = (values: Store) => {
    setEmail(values.email);
    signUp({ email: values.email, password: values.password });
  };

  if (errorType === AuthErrorTypes.EMAIL_VERIFICATION_REQUIRED) {
    return (
      <AppContainer>
        <VerifyEmail email={email} />
      </AppContainer>
    );
  }

  return (
    <StyledForm onFinish={onFinish} form={form}>
      <Form.Item
        name="email"
        rules={[{ required: true, message: strings("register.emailError") }]}
      >
        <VLInput
          prefix={<VLIcon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder={strings("register.emailPlaceholder")}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: strings("register.passwordError") }]}
      >
        <VLInput
          prefix={<VLIcon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
          type="password"
          placeholder={strings("register.passwordPlaceholder")}
        />
      </Form.Item>
      <LoginButton htmlType="submit">
        {strings("register.register")}
      </LoginButton>
      <SimpleText color="opposite">
        {`${strings("register.alreadySignedUp")} `}
      </SimpleText>
      <Link to={Paths.login.path}>{strings("register.login")}</Link>
    </StyledForm>
  );
};
