import { Tooltip } from "antd";
import Avatar from "components/common/avatar/Avatar";
import AdjustingButton from "components/common/buttons/AdjustingButton";
import useOrganizationNames from "components/organizations/useOrganizationNames";
import React from "react";
import styled from "styled-components";

const SwitcherColumn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.background.secondary};
  padding: ${({ theme }) => theme.margin.medium};
  border-right: 1px solid ${({ theme }) => theme.border.color.primary};
  overflow-y: scroll;
  flex-shrink: 0;
`;

const OrgButton = styled(AdjustingButton)<{ selected?: boolean }>`
  margin: ${({ theme }) => theme.margin.medium};
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
`;

const OrganizationSwitcher = () => {
  const { names, loading } = useOrganizationNames();

  return names.length === 0 && !loading ? null : (
    <SwitcherColumn>
      {loading && (
        <OrgButton>
          <Avatar icon="loading" shape="square" />
        </OrgButton>
      )}
      {names.map(({ name, id }) => (
        <Tooltip placement="right" title={name} key={id}>
          <OrgButton
            selected={id === "selectedOrgId"}
            onClick={() => "selectOrgId(id)"}
          >
            <Avatar icon="bank" shape="square" name={name} />
          </OrgButton>
        </Tooltip>
      ))}
    </SwitcherColumn>
  );
};

export default OrganizationSwitcher;
