import { List } from "antd";
import { ListProps } from "antd/lib/list";
import { formatBytes } from "localisation/formatBytes";
import React from "react";
import { UploadingFile } from "state/UploadState";
import VLButton from "../buttons/VLButton";
import { Column, Row, SpreadRow } from "../layout/Flex";
import { SimpleText } from "../text/SimpleText";
import FileTypeIcon from "./FileTypeIcon";
import UploadStatusProgress from "./UploadStatusProgress";

interface UploadFileListProps extends ListProps<UploadingFile> {
  files: UploadingFile[];
  showSize?: boolean;
  showProgress?: boolean;
  onFileRemoved?: (file: UploadingFile, index: number) => void;
}

const UploadFileList = ({
  files,
  onFileRemoved,
  showSize = false,
  showProgress = false,
  ...props
}: UploadFileListProps) => {
  return files.length === 0 ? null : (
    <List<UploadingFile>
      dataSource={files as any}
      renderItem={(file, index) => (
        <List.Item key={file.name}>
          <SpreadRow>
            <Row crossAxis="center">
              <FileTypeIcon
                size={showSize ? "large" : "small"}
                type={file.type || ""}
              />
              <Column marginHorizontal="medium">
                <SimpleText>{file.name}</SimpleText>
                {showSize && (
                  <SimpleText fontSize="small" color="secondary">
                    {formatBytes(file.size)}
                  </SimpleText>
                )}
              </Column>
              {showProgress && <UploadStatusProgress file={file} />}
            </Row>
            {!!onFileRemoved && (
              <VLButton
                icon="close"
                onClick={() => onFileRemoved(file, index)}
              />
            )}
          </SpreadRow>
        </List.Item>
      )}
      {...props}
    />
  );
};

export default UploadFileList;
