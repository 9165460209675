import ContentSidebarState from "state/ContentSidebarState";
import RightSidebarState, {
  CONTENT_DETAILS_SIDEBAR
} from "state/RightSidebarState";

export default () => {
  const { openSidebar, closeSidebar } = RightSidebarState.useContainer();
  const {
    selectContent,
    setSelectedContentType,
    reset
  } = ContentSidebarState.useContainer();

  const openContentDetails = (contentId: string, contentTypeName: string) => {
    selectContent(contentId);
    setSelectedContentType(contentTypeName);
    openSidebar(CONTENT_DETAILS_SIDEBAR);
  };

  const close = () => {
    closeSidebar();
    reset();
  };

  return {
    openContentDetails,
    close
  };
};
