import { FieldDefinitionNode, parse, TypeNode, visit } from "graphql";
import { clone, fromPairs, values } from "ramda";
import { StringMapped } from "types/common";
import { FormEnumOption, FormEnumType, FormFieldDefinition, FormObjectType, FormScalarType, FormTypeDefinition } from "../types";
import directivesToObject from "./directivesToObject";

type TypeMap = StringMapped<FormTypeDefinition>;

const gqlScalars = ["String", "Int", "Float", "Boolean", "ID", "Date"];
const defaultScalars: StringMapped<FormScalarType> = fromPairs(
  gqlScalars.map((name) => [name, { type: "scalar", name }]),
);

const hasType = (type: any): type is { type: TypeNode } => !!type.type;

const getTypeName = (type: TypeNode): string => {
  if (type.kind === "NamedType") {
    return type.name.value;
  }
  if (hasType(type)) {
    return getTypeName(type.type);
  }
  return "";
};

const isRequired = (type: TypeNode): boolean => {
  if (type.kind === "NonNullType") {
    return true;
  }
  if (hasType(type)) {
    return isRequired(type.type);
  }
  return false;
};

const fieldToFormField = (typeMap: TypeMap) => (
  field: FieldDefinitionNode,
): FormFieldDefinition => {
  const typeName = getTypeName(field.type);
  const type = typeMap[typeName];

  if (!type) {
    throw new Error(`No type "${typeName} for field "${field.name.value}"`);
  }

  return {
    name: field.name.value,
    description: field.description?.value,
    isRequired: isRequired(field.type),
    directives: directivesToObject(field.directives),
    type,
  };
};

export default (schemaSdl: string): FormObjectType => {
  const doc = parse(schemaSdl);
  const typeMap: TypeMap = clone(defaultScalars);
  visit(doc, {
    ObjectTypeDefinition: (node) => {
      const nodeName = node.name.value;
      const toFormField = fieldToFormField(typeMap);
      const type: FormObjectType = {
        type: "object",
        name: nodeName,
        description: node.description?.value,
        directives: directivesToObject(node.directives),
        fields: node.fields?.map(toFormField) || [],
      };

      typeMap[nodeName] = type;
    },
    EnumTypeDefinition: (node) => {
      const nodeName = node.name.value;
      const type: FormEnumType = {
        type: "enum",
        name: nodeName,
        description: node.description?.value,
        options:
          node.values?.map<FormEnumOption>((value) => ({
            directives: directivesToObject(value.directives),
            description: value.description?.value,
            value: value.name.value,
          })) || [],
      };
      typeMap[nodeName] = type;
    },
    ScalarTypeDefinition: (node) => {
      const nodeName = node.name.value;
      typeMap[nodeName] = {
        type: "scalar",
        name: nodeName,
      };
    },
  });

  const formObject = values(typeMap).find(
    (type) => type?.type === "object" && !!type.directives["form"],
  );

  if (formObject?.type !== "object") {
    throw new Error("Could not create form from schema");
  }

  return formObject;
};
