import VLButton from "components/common/buttons/VLButton";
import { Row } from "components/common/layout/Flex";
import LiveStreamDetails from "components/liveStreams/LiveStreamDetails";
import LiveStreamsList from "components/liveStreams/LiveStreamsList";
import UpsertStreamModal from "components/liveStreams/UpsertStreamModal";
import { RightSidebar } from "components/rightSidebar/RightSidebar";
import React, { useState } from "react";
import LiveStreamSidebarState from "state/LiveStreamSidebarState";
import { LIVE_STREAM_DETAILS_SIDEBAR } from "state/RightSidebarState";
import AppScreen from "./AppScreen";

export default () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const closeModal = () => setModalVisible(false);

  return (
    <LiveStreamSidebarState.Provider>
      <AppScreen>
        <Row grow={0} mainAxis="flex-end">
          <VLButton icon="plus" onClick={() => setModalVisible(true)} />

          <UpsertStreamModal
            visible={isModalVisible}
            onDone={closeModal}
            onCancel={closeModal}
          />
        </Row>
        <LiveStreamsList />
      </AppScreen>
      <RightSidebar
        renderers={[
          {
            type: LIVE_STREAM_DETAILS_SIDEBAR,
            component: <LiveStreamDetails key="liveStreamDetails" />,
          },
        ]}
      />
    </LiveStreamSidebarState.Provider>
  );
};
