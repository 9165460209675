import VLButton from "components/common/buttons/VLButton";
import { Row } from "components/common/layout/Flex";
import Margin from "components/common/layout/Margin";
import formToDefaultState from "components/gqlForm/functions/formToDefaultState";
import removeEmptyValues from "components/gqlForm/functions/removeEmptyValues";
import schemaToForm from "components/gqlForm/functions/schemaToForm";
import validateForm, {
  FormFieldValidationError,
} from "components/gqlForm/functions/validateForm";
import GqlForm from "components/gqlForm/GqlForm";
import React, { useState } from "react";
import {
  TicketingTicketOptionsInsertInput,
  TicketOptionFragment,
  useUpsertTicketMutation,
} from "__gen__/appService";
import {
  Currency,
  TicketOptions,
  TicketType,
  ValidForUnit,
} from "__gen__/forms";
import schema from "./TicketOptions.form.graphql";

const form = schemaToForm(schema);
const defaultState = removeEmptyValues(formToDefaultState(form));

const valuesToInsertValues = (
  values: TicketOptions,
  { contentId, ticketId }: { contentId: string; ticketId?: string },
): TicketingTicketOptionsInsertInput => {
  return {
    id: ticketId,
    content_id: contentId,
    name: values.name,
    ticket_type: values.ticketType,
    price: values.price.amount,
    currency: values.price.currency,
    valid_until: values.validUntil,
    valid_for_unit: values.validFor?.unit,
    valid_for_amount: values.validFor?.amount,
  };
};

const ticketOptionToValues = (
  ticket: TicketOptionFragment,
): Partial<TicketOptions> | undefined => {
  const validFor =
    !!ticket.validForUnit && !!ticket.validForAmount
      ? {
          amount: ticket.validForAmount,
          unit: ticket.validForUnit as ValidForUnit,
        }
      : undefined;
  return removeEmptyValues({
    name: ticket.name,
    ticketType: ticket.ticketType as TicketType,
    price: {
      amount: ticket.price,
      currency: ticket.currency as Currency,
    },
    validUntil: ticket.validUntil,
    validFor,
  });
};

interface UpsertTicketFormProps {
  contentId: string;
  onClose: () => void;
  initialTicket?: TicketOptionFragment;
}

export default ({
  contentId,
  onClose,
  initialTicket,
}: UpsertTicketFormProps) => {
  const initialState = initialTicket ? ticketOptionToValues(initialTicket) : {};
  const [values, setValues] = useState<Partial<TicketOptions>>({
    ...defaultState,
    ...initialState,
  });
  const [errors, setErrors] = useState<FormFieldValidationError[]>([]);
  const [upsertTicket, { loading }] = useUpsertTicketMutation();

  const onSave = async () => {
    const validationErrors = validateForm(
      form,
      removeEmptyValues(values) || {},
    );
    setErrors(validationErrors);
    if (validationErrors.length === 0) {
      await upsertTicket({
        variables: {
          ticket: [
            valuesToInsertValues(values as TicketOptions, {
              contentId,
              ticketId: initialTicket?.id,
            }),
          ],
        },
      });
      onClose();
    }
  };

  return (
    <>
      <GqlForm
        form={form}
        state={[values, setValues]}
        errorsState={[errors, setErrors]}
      />
      <Row mainAxis="flex-end">
        <VLButton
          loading={loading}
          disabled={loading}
          marginVertical="medium"
          onClick={onSave}
          type="primary"
          t="buttons.save"
        />
        <Margin size="medium" />
        <VLButton
          disabled={loading}
          marginVertical="medium"
          onClick={onClose}
          t="buttons.cancel"
        />
      </Row>
    </>
  );
};
