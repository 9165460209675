import { useState } from "react";
import { createContainer } from "unstated-next";

export default createContainer(() => {
  const [selectedAssetId, setSelectedAssetId] = useState<string>("");
  const [assetList, setAssetList] = useState<string[]>([]);

  const reset = () => {
    setSelectedAssetId("");
    setAssetList([]);
  };

  const selectAsset = (assetId: string) => {
    setSelectedAssetId(assetId);
  };

  const selectNextAsset = () => {
    const index = assetList.indexOf(selectedAssetId);
    const nextAssetId = assetList[index + 1];
    if (nextAssetId) {
      setSelectedAssetId(nextAssetId);
    }
  };

  const selectPreviousAsset = () => {
    const index = assetList.indexOf(selectedAssetId);
    const previousAssetId = assetList[index - 1];
    if (previousAssetId) {
      setSelectedAssetId(previousAssetId);
    }
  };

  return {
    reset,
    selectAsset,
    selectNextAsset,
    selectPreviousAsset,
    selectedAssetId,
    setAssetList,
    assetList
  };
});
