import VLButton from "components/common/buttons/VLButton";
import { Column, Row } from "components/common/layout/Flex";
import Loading from "components/common/layout/Loading";
import { SimpleText } from "components/common/text/SimpleText";
import { FolderScope } from "components/organizations/FolderScopeForm";
import FolderScopes from "components/organizations/FolderScopes";
import joinNameEmail from "components/organizations/functions/joinNameEmail";
import isOrgOwnersRole from "components/roleDetails/functions/isOrgOwnersRole";
import Paths from "navigation/Paths";
import { any, flatten } from "ramda";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  PermissionOrgMemberRolesInsertInput,
  PermissionRoleFolderScopesInsertInput,
  useMemberRolesSubscription,
  useUpsertMemberRolesMutation,
} from "__gen__/appService";
import roleToFolderScope from "./functions/roleToFolderScope";

interface MemberDetailsProps {
  orgId: string;
}

export default ({ orgId }: MemberDetailsProps) => {
  const match = useRouteMatch<typeof Paths.orgMemberDetails.params>();
  const history = useHistory();
  const { memberId } = match.params;
  const [scopes, setScopes] = useState<FolderScope[]>([]);
  const { data, loading, error } = useMemberRolesSubscription({ memberId });
  const [
    upsertMemberRoles,
    { loading: isSaving },
  ] = useUpsertMemberRolesMutation();

  const roles = data?.member?.roles || [];
  const cantAddOwnerRole = any(({ role }) => isOrgOwnersRole(role), scopes);
  const teamScopes = flatten(
    (data?.member?.groupMemberships || []).map((gm) =>
      gm.group.roles.map(roleToFolderScope),
    ),
  );

  useEffect(() => {
    setScopes(roles.map(roleToFolderScope));
    // eslint-disable-next-line
  }, [JSON.stringify(roles)]);

  const onDone = () => {
    history.goBack();
  };

  const onSave = async () => {
    try {
      await upsertMemberRoles({
        variables: {
          memberId,
          roles: scopes.map<PermissionOrgMemberRolesInsertInput>(
            ({ id, role, expires, folderIds }) => ({
              id,
              member_id: memberId,
              user_id: data?.member?.user.id,
              expires: expires?.toISOString(),
              role_id: role.id,
              folder_scopes: {
                data: folderIds.map<PermissionRoleFolderScopesInsertInput>(
                  (folderId) => ({
                    role_id: role.id,
                    folder_id: folderId,
                  }),
                ),
              },
            }),
          ),
        },
      });
      onDone();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  if (loading && !error) {
    return <Loading />;
  }

  return (
    <Column>
      <SimpleText fontSize="large">
        {joinNameEmail(data?.member?.user)}
      </SimpleText>
      <FolderScopes
        orgId={orgId}
        scopesState={[scopes, setScopes]}
        filterOwnerRoles={cantAddOwnerRole}
        titleT="memberDetails.memberRoles"
      />
      <FolderScopes
        orgId={orgId}
        editable={false}
        scopesState={[teamScopes, () => {}]}
        titleT="memberDetails.teamRoles"
      />
      <Row grow={0} mainAxis="flex-end" marginVertical="medium">
        <VLButton
          t="buttons.cancel"
          onClick={onDone}
          marginHorizontal="medium"
        />
        <VLButton
          type="primary"
          t="buttons.save"
          onClick={onSave}
          loading={isSaving}
        />
      </Row>
    </Column>
  );
};
