import { useOrganizationNamesSubscription } from "__gen__/appService";

const useOrganizationNames = () => {
  const { data, loading, error } = useOrganizationNamesSubscription({});

  const names = (data && data.organizations) || [];

  return { names, loading, error };
};

export default useOrganizationNames;
