import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { initializeFaro } from "@grafana/faro-web-sdk";
import Env from "config/Env";

initializeFaro({
  url: "https://faro-collector-prod-us-central-0.grafana.net/collect/a9e8e312fbc8245ca3f552f45081a5b9",
  app: {
    name: "Back-Office",
    version: "1.0.0",
    environment: Env.REACT_APP_ENVIRONMENT || "development",
  },
});

if ((module as any).hot) {
  (module as any).hot.accept();
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
