import { Popconfirm, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import VLButton from "components/common/buttons/VLButton";
import { Row } from "components/common/layout/Flex";
import VLSelect, { VLSelectProps } from "components/common/select/VLSelect";
import strings from "localisation/strings";
import { contains, toPairs } from "ramda";
import React, { FC, useEffect, useState } from "react";
import useContentSidebar from "state/hooks/useContentSidebar";
import {
  ContentRow,
  isNoLocaleType,
  VLContentType,
  VLDirectives,
  VLLocales,
} from "store/contentInterfaces";
import ContentStore from "store/ContentStore";
import createContentRow from "store/functions/createContentRow";
import styled from "styled-components";
import ContentValue from "./ContentValue";
import usePublishing from "./hooks/usePublishing";

interface CMSProps {
  projectId: string;
}

const TypeSelect = styled(VLSelect)`
  min-width: 200px;
`;

const VerticalMediumMargin = styled.div`
  margin-left: ${({ theme }) => theme.margin.medium};
`;

export default ({ projectId }: CMSProps) => {
  const {
    contentTypes,
    getContentType,
    getContentRows,
    upsertRow,
    defaultLocale,
    removeRow,
  } = ContentStore.useContainer();
  const { openContentDetails } = useContentSidebar();
  const firstContentTypeName = contentTypes[0]?.getTypeName();
  const [currentContentType, setCurrentContentType] = useState(
    firstContentTypeName,
  );
  const { onPublish, isPublishing, canPublish } = usePublishing({ projectId });

  const contentRows = getContentRows(currentContentType);
  const contentType = getContentType(currentContentType);

  useEffect(() => {
    setCurrentContentType(firstContentTypeName);
  }, [firstContentTypeName]);

  if (!contentType) {
    return null;
  }

  const fields = contentType.getFields();
  const notInternalFields = toPairs(fields).filter(([fieldName]) => {
    return !contains(
      VLDirectives.VLInternal,
      contentType.getFieldDirectiveNames(fieldName),
    );
  });
  const locale = isNoLocaleType(contentType)
    ? VLLocales.NO_LOCALE
    : defaultLocale;

  const addRow = () => {
    const row = createContentRow({
      type: currentContentType,
    });
    upsertRow(row);
    openContentDetails(row.id, currentContentType);
  };

  return (
    <>
      <Row mainAxis="flex-end" marginVertical="medium" grow={0}>
        <TypeSelect<FC<VLSelectProps<VLContentType>>>
          options={contentTypes}
          optionLabel={(type) => type.getDescription()}
          onOptionSelect={(type) => setCurrentContentType(type.getTypeName())}
          selectedOption={contentType}
        />
        <VerticalMediumMargin />
        <VLButton icon="plus" onClick={addRow} />
        {canPublish && (
          <>
            <VerticalMediumMargin />
            <VLButton loading={isPublishing} onClick={() => onPublish()}>
              Publish
            </VLButton>
          </>
        )}
      </Row>
      <Table<ContentRow>
        rowKey="id"
        size="small"
        scroll={{ x: "max-content" }}
        dataSource={contentRows}
        onRow={(row) => ({
          onClick: () => openContentDetails(row.id, currentContentType),
        })}
        columns={[
          ...notInternalFields.map<ColumnProps<ContentRow>>(
            ([fieldName, field]) => ({
              dataIndex: fieldName,
              title: field.description,
              sorter: (row1, row2) => {
                return `${row1.localeData[locale]?.[fieldName]}`.localeCompare(
                  `${row2.localeData[locale]?.[fieldName]}`,
                );
              },
              render: (_, row) => (
                <ContentValue
                  locale={locale}
                  fieldName={fieldName}
                  contentRow={row}
                  fieldType={contentType?.getFieldTC(fieldName).getTypeName()}
                />
              ),
            }),
          ),
          {
            dataIndex: "status",
            title: "Status",
          },
          {
            dataIndex: "actions",
            align: "right",
            render: (_, row) => (
              <div onClick={(ev) => ev.stopPropagation()}>
                <Popconfirm
                  title={strings("folderInfo.deleteConfirm")}
                  onConfirm={() => {
                    removeRow(row.id);
                  }}
                  okText={strings("common.yes")}
                  cancelText={strings("common.no")}
                  placement="topRight"
                >
                  <VLButton size="small" icon="delete" />
                </Popconfirm>
              </div>
            ),
          },
        ]}
      />
    </>
  );
};
