import { Checkbox } from "antd";
import { Column } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import AssetThumbnail from "components/imagePreview/AssetThumbnail";
import { isVlMedia, VLAsset, VLFolder } from "components/mediaAssets/types";
import { append, without } from "ramda";
import React, { MouseEventHandler } from "react";
import useMediaAssetSidebar from "state/hooks/useMediaAssetSidebar";
import styled from "styled-components";
import { FileOutlined } from "@ant-design/icons";

interface AssetGridProps {
  mediaAssets: VLAsset[];
  activeAction: string | undefined;
  gridColumnCount: number;
  selectedIds: string[];
  setSelectedIds: any;
  onFolderClick?: (folder: VLFolder) => void;
}

interface GridProps {
  gridColumnCount: number;
}

const Grid = styled.div<GridProps>`
  display: grid;
  grid-gap: ${({ theme }) => theme.margin.medium};
  grid-template-columns: repeat(
    ${({ gridColumnCount }) => gridColumnCount},
    1fr
  );
`;

const AssetCard = styled(Column)`
  border: ${({ theme }) => theme.margin.xsmall} solid
    ${({ theme }) => theme.border.color.primary};
  border-radius: ${({ theme }) => theme.border.radius.medium};
  cursor: pointer;
`;

const AssetName = styled(SimpleText)`
  word-break: break-word;
`;

const ImagePlaceholder = styled(FileOutlined)`
  & > svg {
    width: 100%;
    height: 100%;
    max-height: 600px;
  }
`;

const stopEventPropagation: MouseEventHandler<HTMLElement> = (event) => {
  event.stopPropagation();
};

export default ({
  mediaAssets,
  activeAction,
  gridColumnCount,
  selectedIds,
  setSelectedIds,
  onFolderClick = () => {},
}: AssetGridProps) => {
  const { openMediaSidebar } = useMediaAssetSidebar();

  const onAssetSelect = (value: string) => {
    if (selectedIds.includes(value)) {
      setSelectedIds(without([value]));
    } else {
      setSelectedIds(append(value));
    }
  };

  return (
    <Grid gridColumnCount={gridColumnCount}>
      {mediaAssets.map((asset) => {
        return (
          <AssetCard
            padding="large"
            mainAxis="space-between"
            key={asset.id}
            onClick={() => {
              isVlMedia(asset)
                ? openMediaSidebar(asset.id)
                : onFolderClick(asset);
            }}
          >
            <AssetThumbnail
              asset={asset}
              imagePlaceholder={<ImagePlaceholder />}
            />
            <AssetName>
              {activeAction ? (
                <Checkbox
                  onChange={() => onAssetSelect(asset.id)}
                  onClick={stopEventPropagation}
                  defaultChecked={selectedIds.includes(asset.id)}
                >
                  <SimpleText onClick={stopEventPropagation}>
                    {asset.name}
                  </SimpleText>
                </Checkbox>
              ) : (
                <SimpleText>{asset.name}</SimpleText>
              )}
            </AssetName>
          </AssetCard>
        );
      })}
    </Grid>
  );
};
