import { useState } from "react";
import { createContainer } from "unstated-next";

export default createContainer(() => {
  const [selectedContentId, setSelectedContentId] = useState<string>("");
  const [selectedContentType, setSelectedContentType] = useState<string>("");

  const reset = () => {
    setSelectedContentId("");
    setSelectedContentType("");
  };

  const selectContent = (contentId: string) => {
    setSelectedContentId(contentId);
  };

  return {
    reset,
    selectedContentId,
    selectContent,
    selectedContentType,
    setSelectedContentType
  };
});
