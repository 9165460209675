import { Checkbox } from "antd";
import DescriptionItem from "components/common/descriptions/DescriptionItem";
import strings from "localisation/strings";
import { dissoc } from "ramda";
import React from "react";
import restrictTagListOptions from "./functions/restrictTagListOptions";
import { isTagListMeta, MetaField } from "./MetadataFieldTypes";

interface MetadataFieldOptionsProps {
  field: MetaField;
  updateField: (field: Partial<MetaField>) => void;
}

export default ({ field, updateField }: MetadataFieldOptionsProps) => {
  if (isTagListMeta(field)) {
    const checked = !!field.options.maxNumberOfTags;
    const onSingleValueChange = () => {
      const newOptions: typeof field.options = checked
        ? dissoc("maxNumberOfTags", field.options)
        : { ...field.options, maxNumberOfTags: 1 };
      updateField({ ...field, options: restrictTagListOptions(newOptions) });
    };

    return (
      <DescriptionItem
        marginVertical="medium"
        labelT="metadataSpecs.singleValue"
      >
        <Checkbox onChange={onSingleValueChange} checked={checked}>
          {strings("metadataSpecs.singleValue")}
        </Checkbox>
      </DescriptionItem>
    );
  }

  return null;
};
