import UserState from "state/UserState";
import { useCanDeleteFolderSubscription } from "__gen__/appService";

export default ({ folderId }: { folderId: string }) => {
  const { user } = UserState.useContainer();
  const { data } = useCanDeleteFolderSubscription({
    folderId,
    userId: user?.id || "",
  });

  return (data?.removableFolder.length || []) > 0;
};
