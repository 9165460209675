import { contains, pluck } from "ramda";
import { WithId } from "types/common";
import { useEditableMetaSubscription } from "__gen__/appService";

export default <T extends WithId>({
  mediaId,
  metaFields = [],
}: {
  mediaId: string;
  metaFields?: T[];
}) => {
  const { data } = useEditableMetaSubscription({ mediaId });

  const editableFieldIds = pluck("fieldId", data?.editableMeta || []);

  const editableMetaFields = metaFields.filter(({ id }) =>
    contains(id, editableFieldIds),
  );

  return { editableFieldIds, editableMetaFields };
};
