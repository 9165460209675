import { Popconfirm } from "antd";
import VLButton from "components/common/buttons/VLButton";
import strings from "localisation/strings";
import React from "react";
import { useDeleteLinkShareMutation } from "__gen__/appService";

interface DeleteLinkShareButtonProps {
  linkShareId: string;
}

export default ({ linkShareId }: DeleteLinkShareButtonProps) => {
  const [onDelete] = useDeleteLinkShareMutation();

  return (
    <Popconfirm
      title={strings("folderInfo.deleteConfirm")}
      onConfirm={() => onDelete({ variables: { linkShareId } })}
      okText={strings("common.yes")}
      cancelText={strings("common.no")}
    >
      <VLButton danger marginVertical="medium" icon="delete" />
    </Popconfirm>
  );
};
