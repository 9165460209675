import { useOrgConfig } from "components/hooks/useVlConfig";
import useOrganizationNames from "components/organizations/useOrganizationNames";
import Env from "config/Env";
import Paths from "navigation/Paths";
import React, { useEffect, useRef } from "react";
import { Redirect } from "react-router";
import AuthState from "state/AuthState";
import UserState from "state/UserState";

const UserDashboard = () => {
  const { selectedOrgId, selectOrgId } = UserState.useContainer();
  const { names, loading, error } = useOrganizationNames();
  const elementRef = useRef<HTMLDivElement>();
  const { authState } = AuthState.useContainer();
  const { data } = useOrgConfig();

  const dashboardComponentUrl =
    // "http://localhost:8080/lib/components/backOffice/InsightsWithData.svelte.js" ||
    data?.defaultConfig?.proto?.componentLibrary?.url ||
    "https://site-manager.visitor.videolevels.com/staging/lib/components/backOffice/InsightsWithData.svelte.js";

  const { data: configData } = useOrgConfig();
  const disabledDashboards =
    configData.defaultConfig?.proto?.insight?.disabledDashboards || [];

  useEffect(() => {
    const { token } = authState;
    let currentComponent: Promise<any> = Promise.resolve();
    if (token && selectedOrgId) {
      currentComponent = import(/* webpackIgnore: true */ dashboardComponentUrl)
        .then((module) => {
          const Component = module?.default;
          if (Component) {
            return new Component({
              target: elementRef.current,
              props: {
                serviceUrl: Env.REACT_APP_VL_HASURA_URL,
                authToken: token,
                orgId: selectedOrgId,
                disabledDashboards,
              },
            });
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
    return () => {
      currentComponent.then((component: any) => component?.$destroy());
    };
  }, [
    dashboardComponentUrl,
    authState.token,
    selectedOrgId,
    JSON.stringify(disabledDashboards),
  ]);

  if (!selectedOrgId && names.length > 0) {
    selectOrgId(names[0].id);
    return null;
  }

  if (!loading && !error && names.length === 0) {
    return <Redirect to={Paths.newOrganization.withParams()} />;
  }

  return <div ref={elementRef as any}></div>;
};

export default UserDashboard;
