import { Tabs } from "antd";
import MetadataSpecs from "components/assetMetaSpecs/MetadataSpecs";
import Loading from "components/common/layout/Loading";
import ScreenTitle from "components/common/text/ScreenTitle";
import MemberDetails from "components/memberDetails/MemberDetails";
import OrganizationDetailsTable from "components/organizations/OrganizationDetailsTable";
import OrganizationMembers from "components/organizations/OrganizationMembers";
import OrgRoles from "components/organizations/OrgRoles";
import OrgTeams from "components/organizations/OrgTeams";
import TeamDetails from "components/organizations/TeamDetails";
import CanEditOrgData from "components/permissionControl/CanEditOrgData";
import CanEditOrgMembers from "components/permissionControl/CanEditOrgMembers";
import RoleDetails from "components/roleDetails/RoleDetails";
import strings from "localisation/strings";
import Paths from "navigation/Paths";
import React from "react";
import { Switch, useHistory } from "react-router";
import { Route } from "react-router-dom";
import AppScreen from "screens/AppScreen";
import PermissionStore from "store/PermissionStore";

export default ({
  org,
  orgSlug,
  tabKey
}: {
  org: { id: string; name: string };
  orgSlug: string;
  tabKey?: string;
}) => {
  const history = useHistory();
  const {
    loading,
    canViewOrgData,
    canViewOrgMembers
  } = PermissionStore.useContainer();

  const onTabChange = (newKey: string) => {
    if (newKey) {
      history.push(Paths.orgTab.withParams({ orgSlug, tabKey: newKey }));
    } else {
      history.push(Paths.accountOrganization.withParams({ orgSlug }));
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <AppScreen>
      <ScreenTitle>
        {strings("common.organization")}: {org.name}
      </ScreenTitle>
      <Switch>
        <Route
          exact
          path={Paths.orgMemberDetails.path}
          component={() => (
            <CanEditOrgMembers showNA>
              <MemberDetails orgId={org.id} />
            </CanEditOrgMembers>
          )}
        />
        <Route
          exact
          path={Paths.orgTeamDetails.path}
          component={() => (
            <CanEditOrgMembers showNA>
              <TeamDetails orgId={org.id} />
            </CanEditOrgMembers>
          )}
        />
        <Route
          exact
          path={Paths.orgRoleDetails.path}
          component={() => (
            <CanEditOrgData showNA>
              <RoleDetails orgId={org.id} />
            </CanEditOrgData>
          )}
        />
        <Route
          path={""}
          component={() => (
            <>
              <Tabs type="card" activeKey={tabKey || ""} onChange={onTabChange}>
                <Tabs.TabPane tab="Details" key="">
                  <OrganizationDetailsTable orgSlug={orgSlug} />
                </Tabs.TabPane>
                {canViewOrgMembers && (
                  <Tabs.TabPane
                    tab={strings("common.orgMembers")}
                    key="members"
                  >
                    <OrganizationMembers orgSlug={orgSlug} />
                  </Tabs.TabPane>
                )}
                {canViewOrgMembers && (
                  <Tabs.TabPane tab={strings("common.teams")} key="teams">
                    <OrgTeams orgId={org.id} orgSlug={orgSlug} />
                  </Tabs.TabPane>
                )}
                {canViewOrgData && (
                  <Tabs.TabPane tab={strings("common.metadata")} key="metadata">
                    <MetadataSpecs orgId={org.id} />
                  </Tabs.TabPane>
                )}
                {canViewOrgData && (
                  <Tabs.TabPane tab={strings("common.roles")} key="roles">
                    <OrgRoles orgId={org.id} orgSlug={orgSlug} />
                  </Tabs.TabPane>
                )}
              </Tabs>
            </>
          )}
        />
      </Switch>
    </AppScreen>
  );
};
