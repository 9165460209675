import { DirectiveNode } from "graphql";
import { fromPairs } from "ramda";
import { StringMapped } from "types/common";
import { isDefined } from "types/predicates";

export default (directives: readonly DirectiveNode[] = []) => {
  return fromPairs(
    directives.map<[string, StringMapped]>((directive) => {
      const directiveName = directive.name.value;
      const args = fromPairs(
        (directive.arguments || [])
          .map<[string, any] | undefined>(({ name, value }) => {
            if (value.kind === "BooleanValue" || value.kind === "StringValue") {
              return [name.value, value.value];
            }
            if (value.kind === "IntValue" || value.kind === "FloatValue") {
              return [name.value, parseFloat(value.value)];
            }
            return undefined;
          })
          .filter(isDefined),
      );
      return [directiveName, args];
    }),
  );
};
