import React from "react";
import { ReactState } from "state/interfaces/ReactState";
import { StringMapped } from "types/common";
import { FormFieldValidationError } from "./functions/validateForm";
import CommonFieldRenderer from "./inputs/CommonFieldRenderer";
import { FormObjectType } from "./types";

export default <T extends StringMapped = {}>({
  form,
  state,
  errorsState,
}: {
  form: FormObjectType;
  state: ReactState<T>;
  errorsState: ReactState<FormFieldValidationError[]>;
}) => {
  return (
    <>
      {form.fields.map((field) => (
        <CommonFieldRenderer
          key={field.name}
          field={field}
          state={state}
          errorsState={errorsState}
        />
      ))}
    </>
  );
};
