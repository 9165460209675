import {
  findDirective,
  parseGfSdl,
  SimpleType,
  typeDirectivesToObject,
  TypeMap,
} from "@vldev/simple-type/lib/v2";
import { values } from "ramda";

export interface VlComponentDefinition {
  name: string;
  propsType: SimpleType;
  propsTypeMap: TypeMap;
}

export function parseComponentDefinition({ gqlSchema }: { gqlSchema: string }) {
  const typeMap = parseGfSdl({ gfSdl: gqlSchema });
  const propsType = values(typeMap).find(
    (type) => !!findDirective({ type, directive: "component" }),
  );
  if (!propsType) {
    throw new Error("Failed to find props type");
  }
  const directivesObject = typeDirectivesToObject(propsType);
  const componentName = directivesObject.component?.name;
  if (typeof componentName !== "string") {
    throw new Error("Component name is not a string");
  }
  const definition: VlComponentDefinition = {
    name: `${componentName} - v${directivesObject?.component?.version || 1}`,
    propsTypeMap: typeMap,
    propsType,
  };
  return definition;
}
