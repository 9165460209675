import { Tree } from "antd";
import { DataNode, TreeProps } from "antd/lib/tree";
import VLIcon from "components/common/VLIcon";
import { contains, flatten, pluck } from "ramda";
import React, { FC, ReactText, useEffect, useState } from "react";
import FolderStore, { Folder, FolderTree } from "store/FolderStore";
import { isDefined, isStringArray, toStringArray } from "types/predicates";
import FolderLink from "./folderTreeNodes/FolderLink";
import { FolderNodeProps } from "./folderTreeNodes/types";

export type FolderFilter = (folder: Folder) => boolean;

const constructTreeData = (
  { folder, children }: FolderTree,
  checkedFolders: string[],
  NodeComponent: FC<FolderNodeProps> = FolderLink,
  filter: FolderFilter = () => true,
): DataNode | null => {
  const parentChecked = contains(folder.parentId, checkedFolders);
  if (!filter(folder)) {
    return null;
  }
  return {
    key: folder.id,
    checkable: !parentChecked,
    title: <NodeComponent folder={folder} />,
    children: children
      .map((childFolder) =>
        constructTreeData(
          childFolder,
          parentChecked ? [...checkedFolders, folder.id] : checkedFolders,
          NodeComponent,
          filter,
        ),
      )
      .filter(isDefined),
  };
};

interface FolderTreeProps {
  openedFolderId?: string;
  expandOnlySelected?: boolean;
  useLinks?: boolean;
  nodeComponent?: FC<FolderNodeProps>;
  filter?: FolderFilter;
}

export default ({
  openedFolderId,
  expandOnlySelected = true,
  checkedKeys,
  nodeComponent,
  filter,
  ...treeProps
}: FolderTreeProps & TreeProps) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const { getFolderPath, folderTree } = FolderStore.useContainer();

  useEffect(() => {
    setExpandedKeys(pluck("id", getFolderPath(openedFolderId || null)));
  }, [openedFolderId, getFolderPath]);

  const checked = Array.isArray(checkedKeys)
    ? checkedKeys
    : checkedKeys?.checked || [];

  const checkedParents = flatten(
    checked.map<string[]>((id) => {
      return pluck("id", getFolderPath(id as string));
    }),
  );

  const onExpand = (keys: ReactText[]) => {
    if (isStringArray(keys)) {
      setExpandedKeys(keys);
    }
  };

  if (!folderTree) {
    return null;
  }

  const treeData = constructTreeData(
    folderTree,
    toStringArray(checked),
    nodeComponent,
    filter,
  );

  return (
    <Tree
      treeData={[treeData].filter(isDefined)}
      onExpand={onExpand}
      expandedKeys={expandOnlySelected ? expandedKeys : undefined}
      selectedKeys={openedFolderId ? [openedFolderId] : []}
      switcherIcon={<VLIcon type="down" />}
      checkedKeys={{ checked, halfChecked: checkedParents }}
      {...treeProps}
    />
  );
};
