import DescriptionItem from "components/common/descriptions/DescriptionItem";
import VLInput from "components/common/input/VLInput";
import VLTextArea from "components/common/input/VLTextArea";
import strings from "localisation/strings";
import React from "react";

interface AssetFieldsDataProps {
  name: string;
  description: string;
}

interface EditAssetFieldsProps {
  assetFieldsData: AssetFieldsDataProps;
  onValueChange: (label: string, value: string) => void;
}

export default ({ assetFieldsData, onValueChange }: EditAssetFieldsProps) => {
  return (
    <>
      <DescriptionItem marginVertical="small" label={strings("common.name")}>
        <VLInput
          value={assetFieldsData.name}
          onTextChange={(value) => onValueChange("name", value)}
        />
      </DescriptionItem>
      <DescriptionItem
        marginVertical="small"
        label={strings("common.description")}
      >
        <VLTextArea
          placeholder={strings("common.description")}
          value={assetFieldsData.description || ""}
          onTextChange={(value) => onValueChange("description", value)}
        />
      </DescriptionItem>
    </>
  );
};
