import { useState } from "react";
import { createContainer } from "unstated-next";

export interface ValidationResult {
  isValid: boolean;
  rowId: string;
  rowType: string;
}

export default createContainer(() => {
  const [validationErrors, setValidationErrors] = useState<ValidationResult[]>(
    [],
  );
  return { validationErrors, setValidationErrors };
});
