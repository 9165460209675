import { assocPath, dissocPath, isEmpty, path } from "ramda";
import React, { Dispatch, SetStateAction } from "react";
import { VLContentType } from "store/contentInterfaces";
import { isDefined } from "types/predicates";
import useProjectLanguages from "../hooks/useProjectLanguages";
import ObjectTypePreview from "../preview/ObjectTypePreview";
import ObjectTypeInput from "./ObjectTypeInput";
import { LocaleData, ResourceInputProps } from "./types";

export interface ResourceFormProps
  extends Omit<
    ResourceInputProps,
    | "composer"
    | "rootComposer"
    | "dataPath"
    | "locale"
    | "onValueSaved"
    | "resolveValue"
    | "resolveFallback"
  > {
  contentType: VLContentType;
  setResourceValue?: Dispatch<SetStateAction<LocaleData>>;
  resourceValue?: LocaleData;
  isPreview?: boolean;
}

export default ({
  contentType,
  resourceValue,
  setResourceValue = () => {},
  isPreview,
  ...props
}: ResourceFormProps) => {
  const { selectedLanguage, defaultLanguage } = useProjectLanguages();

  const onValueSaved: ResourceInputProps["onValueSaved"] = ({
    value,
    dataPath,
    locale,
  }) => {
    if (!isDefined(value) || isEmpty(value)) {
      setResourceValue(dissocPath([locale, ...dataPath]));
    } else {
      setResourceValue(assocPath([locale, ...dataPath], value));
    }
  };

  let Component = isPreview ? ObjectTypePreview : ObjectTypeInput;

  return (
    <Component
      {...props}
      onValueSaved={onValueSaved}
      rootComposer={contentType}
      composer={contentType}
      dataPath={[]}
      resolveValue={({ dataPath, locale }) =>
        path([locale, ...dataPath], resourceValue)
      }
      resolveFallback={({ dataPath, locale }) =>
        path([locale, ...dataPath], resourceValue) ||
        path([defaultLanguage || "", ...dataPath], resourceValue)
      }
      locale={selectedLanguage}
    />
  );
};
