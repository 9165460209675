import { FieldValues } from "components/assetMetadata/MetaFieldInputTypes";
import { MetaField } from "components/assetMetaSpecs/MetadataFieldTypes";

export enum GeneralOperatorType {
  EQUALS = "EQUALS"
}
export enum NumberOperatorType {
  EQUALS = "EQUALS",
  IS_SMALLER = "IS_SMALLER",
  IS_BIGGER = "IS_BIGGER"
}
export enum DateOperatorType {
  EQUALS = "EQUALS",
  IS_BEFORE = "IS_BEFORE",
  IS_AFTER = "IS_AFTER"
}
export enum TextOperatorType {
  EQUALS = "EQUALS",
  CONTAINS = "CONTAINS"
}
export enum TagOperatorType {
  EQUALS = "EQUALS",
  IS_ANY = "IS_ANY"
}

export type Operator =
  | keyof typeof GeneralOperatorType
  | keyof typeof NumberOperatorType
  | keyof typeof DateOperatorType
  | keyof typeof TextOperatorType
  | keyof typeof TagOperatorType;

export type PartialCondition = Partial<FieldCondition>;

export interface FieldCondition {
  field: MetaField;
  operator: Operator;
  values: Partial<FieldValues>;
}

export type ThisOrAll = "THIS" | "ALL";

export interface SearchState {
  metaConditions: FieldCondition[];
  folder: ThisOrAll;
  q: string;
}

export const emptySearchState: SearchState = {
  q: "",
  folder: "THIS",
  metaConditions: []
};

export interface SerializedFieldValues extends Omit<FieldValues, "dateValues"> {
  dateValues?: string[];
}

export interface SerializedState extends Omit<SearchState, "metaConditions"> {
  metaConditions: Array<{
    field: string;
    operator: Operator;
    values: SerializedFieldValues;
  }>;
}
