import Paths from "navigation/Paths";
import React from "react";
import { Redirect } from "react-router";
import useAppInfo from "./hooks/useAppInfo";

export default () => {
  const { appInfo } = useAppInfo();
  const isAppInMaintenanceMode = appInfo?.maintenanceMode;

  if (
    window.location.pathname === Paths.maintenance.path &&
    isAppInMaintenanceMode === false
  ) {
    return <Redirect to={Paths.dashboard.withParams()} />;
  }

  if (isAppInMaintenanceMode) {
    return <Redirect to={Paths.maintenance.withParams()} />;
  }
  return null;
};
