import MaintenanceModeCheck from "authService/MaintenanceModeCheck";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AccountScreen from "screens/AccountScreen";
import AppFrame from "screens/AppFrame";
import AppScreen from "screens/AppScreen";
import CMSScreen from "screens/CMSScreen";
import DashboardScreen from "screens/DashboardScreen";
import EmailVerificationScreen from "screens/EmailVerificationScreen";
import ForgotPasswordScreen from "screens/ForgotPasswordScreen";
import LiveStreamsScreen from "screens/LiveStreamsScreen";
import LoginScreen from "screens/LoginScreen";
import MaintenanceScreen from "screens/MaintenanceScreen";
import MediaAssetsScreen from "screens/MediaAssetsScreen";
import NewOrganizationScreen from "screens/NewOrganiztionScreen";
import NotFoundScreen from "screens/NotFoundScreen";
import OrganizationDetailsScreen from "screens/OrganizationDetailsScreen";
import OrganizationsScreen from "screens/OrganizationsScreen";
import ProjectsScreen from "screens/ProjectsScreen";
import RegisterScreen from "screens/RegisterScreen";
import ResetPasswordScreen from "screens/ResetPasswordScreen";
import SiteManagerScreen from "screens/SiteManagerScreen";
import UsersScreen from "screens/UsersScreen";
import Paths from "./Paths";

const VLRouter = () => {
  return (
    <BrowserRouter>
      <Route path="/" component={MaintenanceModeCheck} />
      <Switch>
        {/* Maintenance */}
        <Route
          path={Paths.maintenance.path}
          component={MaintenanceScreen}
          exact
        />
        {/* Login and register */}
        <Route path={Paths.login.path} component={LoginScreen} exact />
        <Route path={Paths.register.path} component={RegisterScreen} exact />
        <Route
          path={Paths.forgotPassword.path}
          component={ForgotPasswordScreen}
          exact
        />
        <Route
          exact
          path={Paths.resetPassword.path}
          component={ResetPasswordScreen}
        />
        <Route
          exact
          path={Paths.emailVerification.path}
          component={EmailVerificationScreen}
        />
        {/* Root */}
        <Route
          path="/"
          exact
          component={() => <Redirect to={Paths.dashboard.path} />}
        />
        <Route
          path={[
            Paths.dashboard.path,
            Paths.organizations.path,
            Paths.users.path,
            Paths.projects.path,
            Paths.packages.path,
            Paths.newOrganization.path,
            Paths.organizationDetails.path,
            Paths.assetsSearch.path,
            Paths.orgMediaAssets.path,
            Paths.folderMediaAssets.path,
            Paths.binnedMediaAssets.path,
            Paths.account.path,
            Paths.accountOrganization.path,
            Paths.projectCms.path,
            Paths.siteManager.path,
            Paths.orgLiveStreams.path,
          ]}
        >
          <AppFrame>
            {/* Sidebar */}
            <Switch>
              <Route
                path={Paths.dashboard.path}
                component={DashboardScreen}
                exact
              />
              <Route
                path={Paths.organizations.path}
                component={OrganizationsScreen}
                exact
              />
              <Route path={Paths.users.path} component={UsersScreen} exact />
              <Route
                path={Paths.projects.path}
                component={ProjectsScreen}
                exact
              />
              <Route path={Paths.packages.path} component={AppScreen} exact />
              {/* Organization */}
              <Route
                path={Paths.newOrganization.path}
                component={NewOrganizationScreen}
                exact
              />
              <Route
                path={[
                  Paths.organizationDetails.path,
                  Paths.orgTab.path,
                  Paths.accountOrganization.path,
                ]}
                component={OrganizationDetailsScreen}
              />
              <Route
                path={[
                  Paths.orgMediaAssets.path,
                  Paths.binnedMediaAssets.path,
                  Paths.folderMediaAssets.path,
                  Paths.assetsSearch.path,
                ]}
                component={MediaAssetsScreen}
                exact
              />
              <Route
                path={[Paths.orgLiveStreams.path]}
                component={LiveStreamsScreen}
                exact
              />
              <Route
                path={Paths.account.path}
                component={AccountScreen}
                exact
              />
              <Route path={Paths.projectCms.path} component={CMSScreen} exact />
              <Route
                path={Paths.siteManager.path}
                component={SiteManagerScreen}
              />
            </Switch>
          </AppFrame>
        </Route>
        <Route path="/" component={NotFoundScreen} />
      </Switch>
    </BrowserRouter>
  );
};

export default VLRouter;
