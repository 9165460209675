import styled from "styled-components";
import { Column } from "./Flex";

export default styled(Column)`
  background-color: ${({ theme }) => theme.color.background.primary};
  overflow-y: scroll;
  flex-shrink: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: ${({ theme }) => theme.border.radius.small};
`;
