import { Table } from "antd";
import MetaFieldValues from "components/assetMetadata/valueRenderers/MetaFieldValues";
import { MetaField } from "components/assetMetaSpecs/MetadataFieldTypes";
import { VLMedia } from "components/mediaAssets/types";
import React from "react";
import styled from "styled-components";
import { getMetaComparator } from "../assetSorters";

const MetaContainer = styled.div`
  max-width: 200px;
`;

export default ({ metaFields }: { metaFields: MetaField[] }) => {
  if (metaFields.length === 0) {
    return null;
  }

  return metaFields.map((field) => (
    <Table.Column<VLMedia>
      dataIndex="metadataValues"
      key={field.id}
      title={field.name}
      sorter={getMetaComparator(field)}
      render={(_, { metadataValues = [] }) => (
        <MetaContainer>
          <MetaFieldValues field={field} metadataValues={metadataValues} />
        </MetaContainer>
      )}
    />
  ));
};
