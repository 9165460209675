import { useOrgRolesSubscription, Maybe } from "__gen__/appService";

export interface OrgRole {
  id: string;
  name: string;
  type?: Maybe<string>;
  membersAgg?: Maybe<{
    total?: Maybe<{
      count?: Maybe<number>;
    }>;
  }>;
}

export default ({ orgId }: { orgId: string }): OrgRole[] => {
  const { data } = useOrgRolesSubscription({ orgId });

  return data?.roles || [];
};
