import { message } from "antd";
import VLButton, { VLButtonProps } from "components/common/buttons/VLButton";
import strings from "localisation/strings";
import { TranslationPaths } from "localisation/TranslationPaths";
import React, { createRef } from "react";
import styled from "styled-components";

const CopyBox = styled.textarea`
  position: absolute;
  left: -9999px;
`;

export interface CopyButtonProps extends VLButtonProps {
  textToCopy: string;
  afterCopyMessageT?: TranslationPaths;
  afterCopyMessage?: string;
}

export default ({
  textToCopy,
  afterCopyMessage,
  afterCopyMessageT,
  onClick,
  ...buttonProps
}: CopyButtonProps) => {
  const inputRef = createRef<HTMLTextAreaElement>();

  const showMessage = () => {
    const messageText = afterCopyMessageT
      ? strings(afterCopyMessageT)
      : afterCopyMessage;
    if (!!messageText) {
      message.success(messageText);
    }
  };

  const copyToClipboard = (e: any) => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      showMessage();
      e.target.focus();
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <>
      <VLButton {...buttonProps} onClick={copyToClipboard} />
      <CopyBox
        ref={inputRef}
        value={textToCopy}
        contentEditable={false}
        readOnly
      />
    </>
  );
};
