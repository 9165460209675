import { Card } from "antd";
import { Row } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import UploadArea from "components/common/upload/UploadArea";
import FolderActions from "components/folderBrowser/FolderActions";
import useDateFns from "components/hooks/useDateFns";
import useCanEditFolder from "components/permissionControl/hooks/useCanEditFolder";
import React from "react";
import FolderStore from "store/FolderStore";
import styled from "styled-components";
import { useFolderDetailsSubscription } from "__gen__/appService";
import DescriptionItem from "../common/descriptions/DescriptionItem";

const DescriptionCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.margin.medium};

  .ant-card-body {
    padding: ${({ theme }) => theme.margin.large};
  }
`;

const DescriptionsContainer = styled(Row)`
  margin: -${({ theme }) => theme.margin.medium};
`;

interface FolderInfoProps {
  folderId: string;
}

export default ({ folderId }: FolderInfoProps) => {
  const { formatTimeStamp } = useDateFns();
  const canEditFolder = useCanEditFolder({ folderId });
  const { data } = useFolderDetailsSubscription({ folderId });
  const { getFolderName } = FolderStore.useContainer();

  const folder = data?.folder;

  return (
    <DescriptionCard size="small">
      <Row>
        <DescriptionsContainer flexWrap>
          <DescriptionItem basis="200px" margin="medium" labelT="common.name">
            <SimpleText color="secondary">{getFolderName(folderId)}</SimpleText>
          </DescriptionItem>
          <DescriptionItem
            basis="200px"
            margin="medium"
            labelT="common.description"
            isHidden={!folder?.description}
          >
            <SimpleText color="secondary">{folder?.description}</SimpleText>
          </DescriptionItem>
          {!!folder?.deletedAt && (
            <DescriptionItem
              basis="200px"
              margin="medium"
              labelT="common.deletedAt"
            >
              <SimpleText color="secondary">
                {formatTimeStamp(folder?.deletedAt)}
              </SimpleText>
            </DescriptionItem>
          )}
        </DescriptionsContainer>
        {!!folder && !folder?.deletedAt && <FolderActions folder={folder} />}
      </Row>
      {!!folder && !folder.deletedAt && canEditFolder && (
        <UploadArea orgId={folder.orgId} folderId={folderId} />
      )}
    </DescriptionCard>
  );
};
