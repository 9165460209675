export enum ValidationError {
  MissingRequiredValue = "MissingRequiredValue",
  InvalidSchemaType = "InvalidSchemaType",
  InvalidValueType = "InvalidValueType",
}

export interface ValidationResultError {
  isValid: false;
  error: ValidationError;
  fieldPath: Array<string | number>;
}

export interface ValidationResultOk {
  isValid: true;
  fieldPath: Array<string | number>;
}

export type ValidationResult = ValidationResultOk | ValidationResultError;
