import { Avatar as AntdAvatar } from "antd";
import { AvatarProps as AntdAvatarProps } from "antd/lib/avatar";
import { head } from "ramda";
import React from "react";
import styled from "styled-components";
import VLIcon, { VLIcons } from "../VLIcon";

const VLAvatar = styled(AntdAvatar)`
  background-color: ${({ theme }) => theme.color.foreground.action};
  && {
    display: inline-flex;
    align-items: center;
  }

  .ant-avatar-string {
    line-height: 0;
  }
`;

const Initial = styled.span`
  font-size: ${({ theme }) => theme.font.size.medium};
`;

export interface AvatarProps extends Omit<AntdAvatarProps, "src"> {
  src?: string | null;
  name?: string | null;
  icon?: VLIcons;
}

const Avatar = React.forwardRef(
  ({ src, name, icon, size, ...props }: AvatarProps, ref) => {
    const iconSize = size === "large" ? "large" : undefined;
    const iconName = !icon ? "user" : icon;
    return (
      <VLAvatar src={src || undefined} size={size} ref={ref as any} {...props}>
        {!name && <VLIcon size={iconSize} type={iconName} />}
        {!!name && <Initial>{head(name).toUpperCase()}</Initial>}
      </VLAvatar>
    );
  },
);

export default Avatar;
