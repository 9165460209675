import {
  AccessCodesSubscriptionVariables,
  useAccessCodesSubscription,
  useDeleteAccessCodeMutation,
  useUpsertAccessCodesMutation,
} from "__gen__/appService";

export default (variables: AccessCodesSubscriptionVariables) => {
  const accessCodes = useAccessCodesSubscription(variables);
  const upsertCodes = useUpsertAccessCodesMutation();
  const deleteCode = useDeleteAccessCodeMutation();

  return {
    accessCodes,
    upsertCodes,
    deleteCode,
  };
};
