import { Layout } from "antd";
import IsAdmin from "components/common/controlFlow/IsAdmin";
import LoadingScreen from "components/common/layout/LoadingScreen";
import Header from "components/header/Header";
import VLNotifications from "components/notificationBox/VLNotifications";
import OrganizationSwitcher from "components/sidebar/OrganizationSwitcher";
import Sidebar from "components/sidebar/Sidebar";
import HasuraProvider from "navigation/HasuraProvider";
import Paths from "navigation/Paths";
import React, { ReactNode } from "react";
import { Redirect } from "react-router";
import AuthState from "state/AuthState";
import ContentSidebarState from "state/ContentSidebarState";
import DownloadState from "state/DownloadState";
import MediaSidebarState from "state/MediaSidebarState";
import RightSidebarState from "state/RightSidebarState";
import UploadingFilesMetadataState from "state/UploadingFilesMetadataState";
import UploadState from "state/UploadState";
import FolderStore from "store/FolderStore";
import styled from "styled-components";
import AuthErrorScreen from "./AuthErrorScreen";

const MainLayout = styled(Layout)`
  height: 100%;
`;

export interface AppFrameProps {
  children?: ReactNode;
}

const AppFrame = ({ children }: AppFrameProps) => {
  const { authState } = AuthState.useContainer();

  if (authState.status === "error") {
    return <AuthErrorScreen />;
  }

  if (authState.status === "loading") {
    return <LoadingScreen />;
  }

  if (authState.status === "loggedOut") {
    return <Redirect to={Paths.login.path} />;
  }

  return (
    <HasuraProvider>
      <MainLayout>
        <Header />
        <Layout>
          <IsAdmin>
            <OrganizationSwitcher />
            <Sidebar />
          </IsAdmin>
          <MediaSidebarState.Provider>
            <ContentSidebarState.Provider>
              <FolderStore.Provider>
                <RightSidebarState.Provider>
                  <DownloadState.Provider>
                    <UploadingFilesMetadataState.Provider>
                      <UploadState.Provider>
                        {children}
                        <VLNotifications />
                      </UploadState.Provider>
                    </UploadingFilesMetadataState.Provider>
                  </DownloadState.Provider>
                </RightSidebarState.Provider>
              </FolderStore.Provider>
            </ContentSidebarState.Provider>
          </MediaSidebarState.Provider>
        </Layout>
      </MainLayout>
    </HasuraProvider>
  );
};

export default AppFrame;
