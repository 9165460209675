import VLButton from "components/common/buttons/VLButton";
import Margin from "components/common/layout/Margin";
import { MarginedDivider } from "components/common/layout/MarginedDivider";
import { SimpleText } from "components/common/text/SimpleText";
import React, { useState } from "react";
import { useContentTicketsSubscription } from "__gen__/appService";
import TicketCard from "./TicketCard";
import UpsertTicketForm from "./UpsertTicketForm";

interface ContentTicketsProps {
  contentId: string;
}

export default ({ contentId }: ContentTicketsProps) => {
  const { data } = useContentTicketsSubscription({ contentId });
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <SimpleText>Tickets</SimpleText>
      <MarginedDivider margin="medium" />
      {(data?.tickets || []).map((ticket) => (
        <TicketCard key={ticket.id} ticket={ticket} />
      ))}
      <Margin size="large" />
      {!isEditing && (
        <VLButton onClick={() => setIsEditing(true)}>Add ticket</VLButton>
      )}
      {isEditing && (
        <UpsertTicketForm
          contentId={contentId}
          onClose={() => setIsEditing(false)}
        />
      )}
    </>
  );
};
