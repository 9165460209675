import React from "react";
import {
  useAddOrgMemberMutation,
  useCreateOrgMemberInviteMutation,
} from "__gen__/appService";
import UserAutocomplete from "./UserAutocomplete";

interface AddMemberInputProps {
  orgId: string;
}

const AddMemberInput = ({ orgId }: AddMemberInputProps) => {
  const [addMember] = useAddOrgMemberMutation();
  const [inviteMember] = useCreateOrgMemberInviteMutation();

  const onUserSelected = async (user: { id: string }) => {
    addMember({ variables: { userId: user.id, orgId } });
  };

  const onEmailSelected = (email: string) => {
    inviteMember({ variables: { orgId, inviteeEmail: email } });
  };

  return (
    <UserAutocomplete
      onEmailSelected={onEmailSelected}
      onUserSelected={onUserSelected}
      excludedOrgIds={[orgId]}
    />
  );
};

export default AddMemberInput;
