import { useState } from "react";
import { createContainer } from "unstated-next";

export default createContainer(() => {
  const [selectedStreamKey, setSelectedStreamKey] = useState<string>("");

  const reset = () => {
    setSelectedStreamKey("");
  };

  const selectStream = (streamKey: string) => {
    setSelectedStreamKey(streamKey);
  };

  return {
    reset,
    selectedStreamKey,
    selectStream,
  };
});
