import { Row } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import { UnionTypeComposer } from "graphql-compose";
import React from "react";
import FieldDescription from "../inputs/FieldDescription";
import { ResourceInputProps } from "../inputs/types";
import FallbackText from "./FallbackText";
import FieldPreview from "./FieldPreview";

interface WithTypename {
  __typename: string;
}

export default (props: ResourceInputProps<WithTypename, UnionTypeComposer>) => {
  const { composer, dataPath, locale, resolveValue, resolveFallback } = props;

  const value = resolveValue({ locale, dataPath });
  const fallbackValue = resolveFallback({ locale, dataPath });
  const options = composer.getTypeComposers();

  const selectedOption = options.find(
    (option) => option.getTypeName() === value?.__typename,
  );
  const fallbackOption = options.find(
    (option) => option.getTypeName() === fallbackValue?.__typename,
  );

  return (
    <>
      <Row grow={0} marginVertical="small">
        <FieldDescription {...props} isPreview />
        {!!selectedOption && (
          <SimpleText>{selectedOption.getDescription()}</SimpleText>
        )}
        {!selectedOption && !!fallbackOption && (
          <FallbackText>{fallbackOption.getDescription()}</FallbackText>
        )}
      </Row>
      {fallbackOption && (
        <FieldPreview
          key={value?.__typename}
          {...props}
          composer={fallbackOption}
        />
      )}
    </>
  );
};
