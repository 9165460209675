import { Checkbox, Popconfirm } from "antd";
import VLButton from "components/common/buttons/VLButton";
import VLTextArea from "components/common/input/VLTextArea";
import { Row } from "components/common/layout/Flex";
import Loading from "components/common/layout/Loading";
import Margin from "components/common/layout/Margin";
import { SimpleText } from "components/common/text/SimpleText";
import strings from "localisation/strings";
import { concat, flatten, isEmpty, pluck, take, uniq, update } from "ramda";
import React, { useEffect, useState } from "react";
import { ContentRow } from "store/contentInterfaces";
import styled from "styled-components";
import EditableTextField from "./editableFields/EditableTextField";
import changeContentStatus from "./functions/changeContentStatus";
import useAccessCodes from "./hooks/useAccessCodes";

const INITIAL_AMOUNT_TO_SHOW = 10;

interface AccessCodeRestrictionsProps {
  contentRow: ContentRow;
  onContentChanged: (content: ContentRow) => void;
}

const CodeInput = styled(VLTextArea)`
  white-space: nowrap;
`;

export default ({
  contentRow,
  onContentChanged,
}: AccessCodeRestrictionsProps) => {
  const { isAccessRestricted } = contentRow;
  const [newCode, setNewCode] = useState("");
  const {
    accessCodes: { data, loading },
    upsertCodes: [onUpsert, { loading: isSaving }],
    deleteCode: [onDelete],
  } = useAccessCodes({ contentRowIds: [contentRow.id] });
  const [limit, setLimit] = useState(INITIAL_AMOUNT_TO_SHOW);

  const codes = flatten<string[]>(pluck("codes", data?.accessCodes || []));

  useEffect(() => {
    setLimit(INITIAL_AMOUNT_TO_SHOW);
  }, [contentRow.id]);

  const onCodeChanged = async (
    oldCode: string,
    code: string,
    index: number,
  ) => {
    const contentId = contentRow.id;
    if (isEmpty(code)) {
      onDelete({ variables: { contentId, code: oldCode } });
    } else {
      try {
        await onUpsert({
          variables: {
            contentId,
            codes: uniq(update(index, code, codes)),
          },
        });
      } catch {}
    }
  };

  const addCode = async () => {
    onContentChanged(changeContentStatus(contentRow));
    const addedCodes = newCode.split("\n").filter((code) => code.length > 0);
    if (addedCodes.length > 0) {
      try {
        await onUpsert({
          variables: {
            contentId: contentRow.id,
            codes: uniq(concat(addedCodes, codes)),
          },
        });
      } catch {}
    }
    setNewCode("");
  };

  const onDeleteAll = async () => {
    onContentChanged(changeContentStatus(contentRow));
    try {
      await onUpsert({
        variables: {
          contentId: contentRow.id,
          codes: [],
        },
      });
    } catch {}
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Row grow={0} marginVertical="small">
        <Checkbox
          checked={isAccessRestricted}
          onChange={(ev) => {
            onContentChanged({
              ...changeContentStatus(contentRow),
              isAccessRestricted: ev.target.checked,
            });
          }}
        >
          <SimpleText t="accessCodeRestrictions.accessRestricted" />
        </Checkbox>
      </Row>
      {isAccessRestricted && (
        <>
          <Row grow={0} marginVertical="medium">
            <CodeInput
              placeholder={strings("accessCodeRestrictions.addCodes")}
              value={newCode}
              onTextChange={setNewCode}
              onSave={addCode}
              disabled={isSaving}
            />
            <Margin />
            <VLButton icon="plus" onClick={addCode} loading={isSaving} />
          </Row>
          {take(limit, codes).map((code, index) => (
            <Row marginVertical="small" key={code}>
              <EditableTextField
                initialValue={code}
                onSave={(changedCode) =>
                  onCodeChanged(code, changedCode || "", index)
                }
                disabled={isSaving}
              />
            </Row>
          ))}
          <Row grow={0} marginVertical="medium" mainAxis="flex-end">
            {limit < codes.length && (
              <>
                <VLButton
                  onClick={() => setLimit(limit + INITIAL_AMOUNT_TO_SHOW)}
                  t="common.showMore"
                />
                <Margin />
                <VLButton
                  onClick={() => setLimit(Infinity)}
                  t="common.showAll"
                />
              </>
            )}
            {codes.length > 0 && (
              <>
                <Margin />
                <Popconfirm
                  title={strings("folderInfo.deleteConfirm")}
                  onConfirm={onDeleteAll}
                  okText={strings("common.yes")}
                  cancelText={strings("common.no")}
                  placement="topRight"
                >
                  <VLButton icon="delete" loading={isSaving} />
                </Popconfirm>
              </>
            )}
          </Row>
        </>
      )}
    </>
  );
};
