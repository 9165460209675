import Modal, { ModalProps } from "antd/lib/modal";
import GqlForm from "components/gqlForm/GqlForm";
import useGqlForm from "components/gqlForm/hooks/useGqlForm";
import { assocPath, pipe } from "ramda";
import React from "react";
import useUserState from "state/hooks/useUserState";
import FolderStore from "store/FolderStore";
import {
  LiveStreamsSubscription,
  useUpsertLiveStreamMutation,
} from "__gen__/appService";
import { EditStreamForm } from "__gen__/forms";
import EditStreamFormSchema from "./forms/EditStreamForm.form.graphql";

interface EditStreamModalProps extends ModalProps {
  stream?: LiveStreamsSubscription["liveStreams"][0] | null;
  onDone?: () => void;
}

export default ({
  onDone = () => {},
  stream,
  ...props
}: EditStreamModalProps) => {
  const [upsertLiveStream, { loading }] = useUpsertLiveStreamMutation();
  const { selectedOrgId } = useUserState();
  const { rootFolder } = FolderStore.useContainer();
  const { record } = stream?.proto || { record: { enabled: true } };
  const initialValue = {
    title: stream?.title,
    transcode: !!stream?.transcode,
    dvrLiveRewind: !!stream?.proto?.dvr?.liveRewind,
    record: record?.enabled,
    notes: stream?.proto?.notes,
  };
  const { form, validate, valuesState, errorsState } =
    useGqlForm<EditStreamForm>({
      schema: EditStreamFormSchema,
      initialValue,
    });

  const onUpsert = async () => {
    try {
      const values = validate();
      if (!values) {
        throw new Error("Invalid values");
      }
      await upsertLiveStream({
        variables: {
          streams: [
            {
              stream_key: stream?.streamKey,
              org_id: selectedOrgId,
              title: values.title,
              status: "off",
              transcode: !!values.transcode,
              proto: pipe(
                assocPath(["dvr", "liveRewind"], !!values.dvrLiveRewind),
                assocPath(["record", "enabled"], !!values.record),
                assocPath(["record", "folderId"], rootFolder?.id),
                assocPath(["notes"], values.notes),
              )(stream?.proto || { isFromVl1: true }),
            },
          ],
        },
      });
      if (!stream) {
        valuesState[1](initialValue);
      }
      onDone();
    } catch (err) {
      // console.warn(err);
    }
  };

  return (
    <Modal onOk={onUpsert} okButtonProps={{ loading }} {...props}>
      <GqlForm form={form} errorsState={errorsState} state={valuesState} />
    </Modal>
  );
};
