import { InputNumber } from "antd";
import React from "react";
import InputErrors from "../InputErrors";
import { InputProps, ValidationFunction } from "../types";

export const validateNumberInput: ValidationFunction<number> = ({
  value,
  dataPath,
}) => {
  if (typeof value !== "number") {
    return [{ message: "This field is required", dataPath }];
  }
  return [];
};

export default function NumberInput({
  onValueChange,
  value,
  dataPath,
}: InputProps<number>) {
  const onChange = (value: number | string | undefined) => {
    if (typeof value === "string") {
      return onValueChange(parseFloat(value) || 0);
    }
    onValueChange(value || 0);
  };

  return (
    <>
      <InputNumber min={1} max={100} onChange={onChange} value={value} />
      <InputErrors dataPath={dataPath} />
    </>
  );
}
