import VLRouter from "navigation/VLRouter";
import React from "react";
import AuthState from "state/AuthState";
import GraphQLClientState from "state/GraphQLClientState";
import MediaBrowserPreferences from "state/MediaBrowserPreferences";
import SidebarState from "state/SidebarState";
import UserState from "state/UserState";
import theme from "style/theme";
import { ThemeProvider } from "styled-components";
import "./App.css";

const App: React.FC = () => {
  return (
    <UserState.Provider>
      <MediaBrowserPreferences.Provider>
        <AuthState.Provider>
          <ThemeProvider theme={theme}>
            <GraphQLClientState.Provider>
              <SidebarState.Provider>
                <VLRouter />
              </SidebarState.Provider>
            </GraphQLClientState.Provider>
          </ThemeProvider>
        </AuthState.Provider>
      </MediaBrowserPreferences.Provider>
    </UserState.Provider>
  );
};

export default App;
