import { Layout } from "antd";
import { ReactComponent as TextLogo } from "assets/icons/text-logo.svg";
import VLButton from "components/common/buttons/VLButton";
import { Row, SpreadRow } from "components/common/layout/Flex";
import { useOrgConfig } from "components/hooks/useVlConfig";
import Paths from "navigation/Paths";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import AssetSearchBox from "../search/AssetSearchBox";
import OrganizationSelect from "./OrganizationSelect";
import SidebarButton from "./SIdebarButton";
import UserSelect from "./UserSelect";

const HeaderContentArea = styled(SpreadRow)`
  height: 100%;
`;

const HeaderContainer = styled(Layout.Header)`
  background-color: ${({ theme }) => theme.color.background.opposite};
  padding: 0;
`;

const LogoLink = styled(Link)`
  line-height: initial;
`;

const VLLogo = styled(TextLogo)`
  margin-left: ${({ theme }) => theme.margin.medium};
`;

const GhostButton = styled(VLButton)`
  margin-left: ${({ theme }) => theme.margin.medium};
  color: ${({ theme }) => theme.color.foreground.opposite};
`;

const Header = () => {
  const { pathname } = useLocation();
  const { data } = useOrgConfig();
  const toolbarConfig = data.defaultConfig?.proto?.toolbarConfig;

  return (
    <HeaderContainer>
      <HeaderContentArea marginHorizontal="large" crossAxis="center">
        <Row flex={0} crossAxis="center">
          <SidebarButton />
          <LogoLink to={Paths.dashboard.withParams()}>
            <VLLogo width="160px" />
          </LogoLink>
          {toolbarConfig?.mam?.enabled && (
            <Link to={Paths.orgMediaAssets.withParams()}>
              <GhostButton
                type={
                  pathname.startsWith("/assets") ||
                  pathname.startsWith("/streams")
                    ? "primary"
                    : "ghost"
                }
              >
                MEDIA LIBRARY
              </GhostButton>
            </Link>
          )}
          {toolbarConfig?.fem?.enabled && (
            <Link to={Paths.projects.withParams()}>
              <GhostButton
                type={pathname.startsWith("/projects") ? "primary" : "ghost"}
              >
                FEM
              </GhostButton>
            </Link>
          )}
          {toolbarConfig?.cms?.enabled && (
            <Link to={Paths.siteManager.withParams()}>
              <GhostButton
                type={pathname.startsWith("/cms") ? "primary" : "ghost"}
              >
                SITE MANAGER
              </GhostButton>
            </Link>
          )}
        </Row>
        <Row mainAxis="flex-end" crossAxis="center">
          <AssetSearchBox />
          <OrganizationSelect />
          <UserSelect />
        </Row>
      </HeaderContentArea>
    </HeaderContainer>
  );
};

export default Header;
