import { SpreadRow } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import { append, pluck } from "ramda";
import React from "react";
import { ContentRow, VLLocales } from "store/contentInterfaces";
import directivesToObject from "../functions/directivesToObject";
import { isObjectType } from "../functions/typePredicates";
import ResourceFormButton from "../inputs/ResourceFormButton";
import { ResourceInputProps } from "../inputs/types";
import ResourceReferenceTable from "./ResourceReferenceTable";

interface ResourceRef {
  id: string;
}

export default (props: ResourceInputProps<ResourceRef[]>) => {
  const {
    resolveValue,
    composer,
    fieldConfig,
    onValueSaved,
    dataPath,
    locale,
    onOpenContent,
  } = props;
  const refs = resolveValue({ ...props, locale: VLLocales.NO_LOCALE }) || [];
  const directives = directivesToObject(fieldConfig?.astNode?.directives);
  const columnKeys = directives.table?.fields;

  if (!isObjectType(composer) || !Array.isArray(columnKeys)) {
    return null;
  }

  const onRowCreated = (newRow: ContentRow) => {
    onValueSaved({
      value: append({ id: newRow.id }, refs),
      dataPath,
      locale: VLLocales.NO_LOCALE,
    });
  };

  return (
    <>
      <SpreadRow marginVertical="medium" mainAxis="flex-end" grow={0}>
        <SimpleText bold>
          {fieldConfig?.description || composer.getDescription()}
        </SimpleText>
        <ResourceFormButton
          contentType={composer}
          onContentCreated={onRowCreated}
          isNewResource
        />
      </SpreadRow>
      <ResourceReferenceTable
        composer={composer}
        locale={locale}
        refs={pluck("id", refs)}
        columnKeys={columnKeys}
        onRowClick={onOpenContent ? (row) => onOpenContent(row.id) : undefined}
      />
    </>
  );
};
