import AppContainer from "components/common/layout/AppContainer";
import ForgotPasswordForm from "components/register/ForgotPasswordForm";
import Paths from "navigation/Paths";
import React from "react";
import { Redirect } from "react-router";
import AuthState from "state/AuthState";

const RegisterScreen = () => {
  const { authState } = AuthState.useContainer();

  if (authState.status === "loggedIn") {
    return <Redirect to={Paths.dashboard.path} />;
  }

  return (
    <AppContainer oppositeTheme>
      <ForgotPasswordForm />
    </AppContainer>
  );
};

export default RegisterScreen;
