import { Dropdown, Menu } from "antd";
import {
  isBooleanMeta,
  isDateMeta,
  isNumberMeta,
  isSimpleTextMeta,
  isSingleChoiceMeta,
  MetaField,
} from "components/assetMetaSpecs/MetadataFieldTypes";
import VLButton from "components/common/buttons/VLButton";
import strings from "localisation/strings";
import { TranslationPaths } from "localisation/TranslationPaths";
import { values } from "ramda";
import React, { useEffect } from "react";
import styled from "styled-components";
import {
  DateOperatorType,
  GeneralOperatorType,
  NumberOperatorType,
  Operator,
  TagOperatorType,
  TextOperatorType,
} from "./types";

const SpacedDropdown = styled(Dropdown)`
  margin: 0 ${({ theme }) => theme.margin.medium};
`;

const getOperator = (field: MetaField) => {
  if (isBooleanMeta(field) || isSingleChoiceMeta(field)) {
    return GeneralOperatorType;
  }
  if (isDateMeta(field)) {
    return DateOperatorType;
  }
  if (isNumberMeta(field)) {
    return NumberOperatorType;
  }
  if (isSimpleTextMeta(field)) {
    return TextOperatorType;
  }
  return TagOperatorType;
};

const operatorTexts: { [i in Operator]: TranslationPaths } = {
  EQUALS: "searchMetaOperator.EQUALS",
  CONTAINS: "searchMetaOperator.CONTAINS",
  IS_ANY: "searchMetaOperator.IS_ANY",
  IS_SMALLER: "searchMetaOperator.IS_SMALLER",
  IS_BIGGER: "searchMetaOperator.IS_BIGGER",
  IS_BEFORE: "searchMetaOperator.IS_BEFORE",
  IS_AFTER: "searchMetaOperator.IS_AFTER",
};

interface FieldOperatorProps {
  field: MetaField;
  selectedOperator?: Operator;
  onOperatorSelect: (condition: { operator: Operator }) => void;
}

export default ({
  field,
  selectedOperator,
  onOperatorSelect,
}: FieldOperatorProps) => {
  const operator = getOperator(field);

  useEffect(() => {
    if (!selectedOperator) {
      onOperatorSelect({ operator: operator.EQUALS });
    }
    // eslint-disable-next-line
  }, [selectedOperator]);

  const menu = (
    <Menu>
      {values(operator).map((value) => (
        <Menu.Item
          key={value}
          onClick={() => onOperatorSelect({ operator: value })}
        >
          {strings(operatorTexts[value])}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <SpacedDropdown overlay={menu} trigger={["click"]}>
      <VLButton size="small">
        {strings(
          selectedOperator ? operatorTexts[selectedOperator] : "common.notSet",
        )}
      </VLButton>
    </SpacedDropdown>
  );
};
