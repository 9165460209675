const theme = {
  color: {
    background: {
      primary: "#ffffff",
      secondary: "#eaeaea",
      tertiary: "#ccc",
      opposite: "#323232",
      upload: "#fafafa",
    },
    foreground: {
      primary: "#333333",
      secondary: "#555555",
      tertiary: "#888888",
      opposite: "#ffffff",
      action: "#e6ae49",
      border: "#e8e8e8",
      error: "#aa0000",
    },
    fileTypes: {
      image: "#5ea6d9",
      zip: "#828898",
      pdf: "#e35149",
      text: "#555555",
      csv: "#19af66",
      video: "#e6ae49",
      audio: "#ed897c",
      file: "#555555",
    },
    mediaTypes: {
      IMAGE: "#5ea6d9",
      VIDEO: "#e6ae49",
      AUDIO: "#ed897c",
      FILE: "#555555",
      UNKNOWN: "#555555",
      VLFolder: "#e6ae49",
    },
    statusTypes: {
      FAILED: "#e35149",
      FINISHED: "#19af66",
      NOT_APPLICABLE: "#555555",
      PROCESSING: "#5ea6d9",
      WAITING_FOR_PROCESSING: "#e6ae49",
    },
  },
  margin: {
    none: "0px",
    xsmall: "2px",
    small: "4px",
    medium: "8px",
    large: "16px",
    xlarge: "32px",
  },
  font: {
    size: {
      small: "10px",
      medium: "14px",
      large: "20px",
      xlarge: "28px",
    },
  },
  border: {
    radius: {
      small: "4px",
      medium: "8px",
      large: "16px",
    },
    color: {
      primary: "#ddd",
    },
  },
  icon: {
    size: {
      medium: "16px",
      large: "24px",
      xlarge: "32px",
      xxlarge: "64px",
    },
  },
};

export type ThemeInterface = typeof theme;

export default theme;
