import { Tag } from "antd";
import VLInput from "components/common/input/VLInput";
import VLTag from "components/common/text/VLTag";
import VLIcon from "components/common/VLIcon";
import strings from "localisation/strings";
import { TranslationPaths } from "localisation/TranslationPaths";
import { without } from "ramda";
import React, { useState } from "react";

interface EditableTagListProps {
  tags?: string[] | null;
  onTagsChanged: (tags: string[]) => void;
  addLabelT: TranslationPaths;
}

export default ({
  tags: tagsFromProps,
  onTagsChanged,
  addLabelT,
}: EditableTagListProps) => {
  const tags = tagsFromProps || [];
  const [inputValue, setInputValue] = useState("");
  const [inputVisible, setInputVisible] = useState(false);

  const showInput = () => {
    setInputVisible(true);
  };

  const onInputConfirm = () => {
    if (inputValue) {
      onTagsChanged([...tags, inputValue]);
    }
    setInputValue("");
  };

  const onBlur = () => {
    onInputConfirm();
    setInputVisible(false);
  };

  return (
    <div>
      {tags.map((tag) => (
        <VLTag
          key={tag}
          label={tag}
          closable
          onClose={() => onTagsChanged(without([tag], tags))}
        />
      ))}
      {inputVisible && (
        <VLInput
          autoFocus
          type="text"
          size="small"
          style={{ width: 78 }}
          value={inputValue}
          onTextChange={setInputValue}
          onBlur={onBlur}
          onPressEnter={onInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag
          onClick={showInput}
          style={{ background: "#fff", borderStyle: "dashed" }}
        >
          <VLIcon type="plus" />
          {` ${strings(addLabelT)}`}
        </Tag>
      )}
    </div>
  );
};
