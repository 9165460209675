import { Checkbox, Dropdown, Menu } from "antd";
import VLButton from "components/common/buttons/VLButton";
import { SimpleText } from "components/common/text/SimpleText";
import strings from "localisation/strings";
import { without } from "ramda";
import React, { useState } from "react";
import MediaBrowserPreferences from "state/MediaBrowserPreferences";
import UserState from "state/UserState";
import { useMetadataFieldsSubscription } from "__gen__/appService";
import { allColumns, translations } from "./defaultColumns";

export default () => {
  const { selectedOrgId } = UserState.useContainer();
  const { data } = useMetadataFieldsSubscription({ orgId: selectedOrgId });
  const {
    metaColumns,
    setMetaColumns,
    assetListColumns,
    setAssetListColumns,
  } = MediaBrowserPreferences.useContainer();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [selectedMetaColumns, setSelectedMetaColumns] = useState(metaColumns);
  const [selectedColumns, setSelectedColumns] = useState(assetListColumns);

  const metadataFields = (data && data.metadataFields) || [];

  const onVisibleChange = (visible: boolean) => {
    setAssetListColumns(selectedColumns);
    setMetaColumns(selectedMetaColumns);
    setIsMenuOpened(visible);
  };

  return (
    <Dropdown
      onVisibleChange={onVisibleChange}
      visible={isMenuOpened}
      overlay={
        <Menu>
          {allColumns.map((id) => {
            const selected = selectedColumns.indexOf(id) > -1;
            return (
              <Menu.Item
                key={id}
                onClick={() => {
                  if (selected) {
                    setSelectedColumns(without([id], selectedColumns));
                  } else {
                    setSelectedColumns([...selectedColumns, id]);
                  }
                }}
              >
                <Checkbox checked={selected} />
                <SimpleText marginHorizontal="medium">
                  {strings(translations[id])}
                </SimpleText>
              </Menu.Item>
            );
          })}
          {metadataFields.map(({ name, id }) => {
            const selected = selectedMetaColumns.indexOf(id) > -1;
            return (
              <Menu.Item
                key={id}
                onClick={() => {
                  if (selected) {
                    setSelectedMetaColumns(without([id], selectedMetaColumns));
                  } else {
                    setSelectedMetaColumns([...selectedMetaColumns, id]);
                  }
                }}
              >
                <Checkbox checked={selected} />
                <SimpleText marginHorizontal="medium">{name}</SimpleText>
              </Menu.Item>
            );
          })}
        </Menu>
      }
      trigger={["click"]}
    >
      <VLButton icon="setting" />
    </Dropdown>
  );
};
