import React from "react";
import AppContainer from "./AppContainer";
import { Spin } from "antd";

const LoadingScreen = () => (
  <AppContainer oppositeTheme>
    <Spin size="large" />
  </AppContainer>
);

export default LoadingScreen;
