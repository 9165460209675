import VLButton from "components/common/buttons/VLButton";
import Paths from "navigation/Paths";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

export type MediaType = "FILE" | "LIVE";

const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.margin.large};
  margin-left: ${({ theme }) => theme.margin.large};
`;

export default () => {
  const { pathname } = useLocation();

  const isAssetsScreen = pathname.startsWith("/assets");
  const isStreamsScreen = pathname.startsWith("/stream");

  if (!isAssetsScreen && !isStreamsScreen) {
    return null;
  }

  return (
    <ButtonContainer>
      <Link to={Paths.orgMediaAssets.withParams()}>
        <VLButton type={isAssetsScreen ? "primary" : "default"}>File</VLButton>
      </Link>
      <Link to={Paths.orgLiveStreams.withParams()}>
        <VLButton
          type={isStreamsScreen ? "primary" : "default"}
          marginHorizontal="medium"
        >
          Live
        </VLButton>
      </Link>
    </ButtonContainer>
  );
};
