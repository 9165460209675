import { Result } from "antd";
import { SimpleText } from "components/common/text/SimpleText";
import strings from "localisation/strings";
import React from "react";

export default ({ email }: { email: string }) => {
  // const onResend = () => {
  //   console.log("Resent email");
  // };
  return (
    <Result
      status="warning"
      title={
        <SimpleText bold>{strings("verifyEmail.title", { email })}</SimpleText>
      }
      // extra={
      //   email ? (
      //     <Button type="primary" key="console" onClick={onResend}>
      //       {strings("verifyEmail.resendButton")}
      //     </Button>
      //   ) : null
      // }
    />
  );
};
