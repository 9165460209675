import { SimpleText } from "components/common/text/SimpleText";
import React from "react";
import ContentStore from "store/ContentStore";
import useDataResolvers from "../hooks/useDataResolvers";

interface ContentLinkProps {
  id: string;
}

export default ({ id }: ContentLinkProps) => {
  const { getContentRow } = ContentStore.useContainer();
  const { resolveFallback } = useDataResolvers(["name"]);

  const row = getContentRow(id);
  if (!row) {
    return null;
  }

  const name = resolveFallback<string>(row);

  return <SimpleText>{name}</SimpleText>;
};
