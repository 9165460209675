import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import getAppLink, { AppLinkParams } from "./getAppLink";

export default class AppServiceClient extends ApolloClient<any> {
  constructor(params: AppLinkParams) {
    const { link, wsClient } = getAppLink(params);
    super({
      link,
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: "no-cache"
        }
      }
    });

    this.stop = () => {
      wsClient.close(false, false);
    };
  }
}
