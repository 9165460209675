import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";
import useEditableMeta from "components/permissionControl/hooks/useEditableMeta";
import strings from "localisation/strings";
import { assoc } from "ramda";
import React, { useEffect, useState } from "react";
import {
  MediaAssetFragment,
  useAssetMetadataValuesSubscription,
  useUpdateMediaAssetMutation,
} from "__gen__/appService";
import AssetMetaFields from "./AssetMetaFields";
import EditAssetFields from "./EditAssetFields";
import useAssetMetadataEditing from "./hooks/useAssetMetadataEditing";

interface EditAssetMetaButtonProps extends ModalProps {
  mediaAsset: MediaAssetFragment;
  onDone?: () => void;
}

export default ({
  mediaAsset,
  onDone = () => {},
  ...modalProps
}: EditAssetMetaButtonProps) => {
  const [assetFieldsData, setAssetFieldsData] = useState({
    name: mediaAsset.name,
    description: mediaAsset.description || "",
  });

  useEffect(() => {
    setAssetFieldsData({
      name: mediaAsset.name,
      description: mediaAsset.description || "",
    });
  }, [mediaAsset.id]);

  const mediaAssetId = mediaAsset.id;
  const { data } = useAssetMetadataValuesSubscription({
    orgId: mediaAsset.orgId,
    mediaAssetId,
  });

  const metadataFields = (data && data.metadataFields) || [];
  const { editableMetaFields } = useEditableMeta({
    mediaId: mediaAssetId,
    metaFields: metadataFields,
  });

  const { setFieldValues, selectedValues, onSaveValues, isSaving, reset } =
    useAssetMetadataEditing({
      mediaAssetId,
      metadataFields: editableMetaFields,
    });
  const [updateMediaAsset] = useUpdateMediaAssetMutation({
    assetId: mediaAssetId,
    name: assetFieldsData.name,
    description: assetFieldsData.description,
  });

  const onSave = async () => {
    await onSaveValues();
    await updateMediaAsset();
    onDone();
  };

  const onValueChange = (label: string, value: string) => {
    setAssetFieldsData(assoc(label, value));
  };

  return editableMetaFields.length === 0 ? null : (
    <Modal
      onCancel={onDone}
      onOk={onSave}
      okButtonProps={{ loading: isSaving }}
      okText={strings("buttons.save")}
      cancelText={strings("buttons.cancel")}
      afterClose={reset}
      destroyOnClose
      {...modalProps}
    >
      <EditAssetFields
        assetFieldsData={assetFieldsData}
        onValueChange={onValueChange}
      />
      <AssetMetaFields
        fields={editableMetaFields as any}
        selectedValues={selectedValues}
        setValues={setFieldValues}
      />
    </Modal>
  );
};
