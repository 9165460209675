import { Table } from "antd";
import VLButton from "components/common/buttons/VLButton";
import { Column, SpreadRow } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import FolderPath from "components/folderBrowser/FolderPath";
import useDateFns from "components/hooks/useDateFns";
import isOrgOwnersRole from "components/roleDetails/functions/isOrgOwnersRole";
import strings from "localisation/strings";
import { TranslationPaths } from "localisation/TranslationPaths";
import { append, remove, update } from "ramda";
import React, { useState } from "react";
import { ReactState } from "state/interfaces/ReactState";
import styled from "styled-components";
import ButtonModal from "./ButtonModal";
import FolderScopeForm, { FolderScope } from "./FolderScopeForm";

const DeleteButton = styled(VLButton)`
  margin-left: ${({ theme }) => theme.margin.medium};
`;

interface FolderScopesProps {
  orgId: string;
  scopesState: ReactState<FolderScope[]>;
  filterOwnerRoles?: boolean;
  editable?: boolean;
  titleT?: TranslationPaths;
}

export default ({
  orgId,
  scopesState: [scopes, setScopes],
  filterOwnerRoles,
  editable = true,
  titleT,
}: FolderScopesProps) => {
  const [currentScope, setCurrentScope] = useState<FolderScope>();
  const { formatDate } = useDateFns();

  const onScopeAdded = () => {
    if (currentScope) {
      setScopes(append(currentScope));
      setCurrentScope(undefined);
    }
  };

  const onScopeChanged = (index: number) => {
    if (index > -1 && currentScope) {
      setScopes(update(index, currentScope));
      setCurrentScope(undefined);
    }
  };

  return (
    <>
      <SpreadRow grow={0} marginVertical="medium">
        <SimpleText color="tertiary" t={titleT || "common.roles"} />
        {editable && (
          <ButtonModal
            onDone={onScopeAdded}
            onCancel={() => setCurrentScope(undefined)}
          >
            <FolderScopeForm
              orgId={orgId}
              onScopeChange={setCurrentScope}
              filterOwnerRoles={filterOwnerRoles}
            />
          </ButtonModal>
        )}
      </SpreadRow>
      <Table<FolderScope>
        dataSource={scopes}
        rowKey="id"
        size="small"
        pagination={false}
      >
        <Table.Column<FolderScope>
          title={strings("common.role")}
          dataIndex="role"
          render={(_, { role }) => <SimpleText>{role.name}</SimpleText>}
          width={300}
        />
        <Table.Column<FolderScope>
          title={strings("folderScopes.mediaScopes")}
          dataIndex="folderIds"
          render={(_, { folderIds }) => (
            <Column>
              {folderIds.map((id) => (
                <FolderPath key={id} folderId={id} />
              ))}
            </Column>
          )}
        />
        <Table.Column<FolderScope>
          title={strings("common.expires")}
          dataIndex="expires"
          render={(_, { expires }) => (
            <SimpleText>{expires ? formatDate(expires) : ""}</SimpleText>
          )}
          width={100}
        />
        <Table.Column<FolderScope>
          dataIndex="actions"
          align="right"
          width={100}
          render={(_, scope, index) => {
            const isNotOwnerRole = !isOrgOwnersRole(scope.role);
            const canBeDeleted =
              isNotOwnerRole || (scope.role.membersAgg?.total?.count || 1) > 1;
            if (!editable) {
              return null;
            }
            return (
              <>
                {isNotOwnerRole && (
                  <ButtonModal
                    onDone={() => onScopeChanged(index)}
                    onCancel={() => setCurrentScope(undefined)}
                    buttonProps={{
                      type: "default",
                      size: "small",
                      icon: "edit",
                    }}
                  >
                    <FolderScopeForm
                      orgId={orgId}
                      initialScope={scope}
                      onScopeChange={setCurrentScope}
                    />
                  </ButtonModal>
                )}
                {canBeDeleted && (
                  <DeleteButton
                    size="small"
                    shape="circle"
                    icon="delete"
                    onClick={() => setScopes(remove(index, 1))}
                  />
                )}
              </>
            );
          }}
        />
      </Table>
    </>
  );
};
