import VLTooltip from "components/common/VLTooltip";
import Paths from "navigation/Paths";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FolderNodeProps } from "./types";

const LinkTitle = styled(Link)`
  color: ${({ theme }) => theme.color.foreground.primary};
`;

export default ({ folder }: FolderNodeProps) => {
  return (
    <VLTooltip title={folder.name} mouseEnterDelay={0.5}>
      <LinkTitle
        to={
          !folder.parentId
            ? Paths.orgMediaAssets.withParams()
            : Paths.folderMediaAssets.withParams({ folderId: folder.id })
        }
      >
        {folder.name}
      </LinkTitle>
    </VLTooltip>
  );
};
