import { Checkbox } from "antd";
import { isMultipleChoiceMeta } from "components/assetMetaSpecs/MetadataFieldTypes";
import React from "react";
import { MetaFieldInputProps } from "./MetaFieldInputTypes";

export default ({
  field,
  onValuesChanged,
  values: { textValues: selectedValues }
}: MetaFieldInputProps) => {
  if (!isMultipleChoiceMeta(field)) {
    return null;
  }
  const possibleValues = field.options.possibleValues || [];
  const onChange = (values: any[]) => {
    onValuesChanged({
      fieldId: field.id || "",
      values: { textValues: values }
    });
  };
  return (
    <Checkbox.Group
      options={possibleValues}
      value={selectedValues}
      onChange={onChange}
    />
  );
};
