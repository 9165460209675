import { MetaField } from "components/assetMetaSpecs/MetadataFieldTypes";
import { isEmpty, prop, toPairs } from "ramda";
import GraphQLClientState from "state/GraphQLClientState";
import {
  LazyMetadataFieldsDocument,
  LazyMetadataFieldsQuery,
  LazyMetadataFieldsQueryVariables,
} from "__gen__/appService";
import { FieldValues } from "../MetaFieldInputTypes";
import { AssetMetaFieldsState } from "../stateMapper";

const hasValue = ({
  booleanValues,
  dateValues,
  numberValues,
  textValues,
}: FieldValues) => {
  return (
    !isEmpty(booleanValues) ||
    !isEmpty(dateValues) ||
    !isEmpty(numberValues) ||
    !isEmpty(textValues)
  );
};

export default () => {
  const { hasuraClient } = GraphQLClientState.useContainer();

  const checkFields = async (state: AssetMetaFieldsState, orgId: string) => {
    if (hasuraClient) {
      const {
        data: { metadataFields },
      } = await hasuraClient.query<
        LazyMetadataFieldsQuery,
        LazyMetadataFieldsQueryVariables
      >({
        query: LazyMetadataFieldsDocument,
        variables: { orgId },
      });

      const requiredFieldIds = metadataFields
        .filter((field: Required<MetaField>) => !!field.options.required)
        .map(prop("id"));
      const fieldValues = toPairs(state);
      const missingFields = requiredFieldIds.filter((requiredFieldId) =>
        fieldValues.find(
          ([fieldId, values]) =>
            fieldId === requiredFieldId && !hasValue(values),
        ),
      );

      if (isEmpty(missingFields)) {
        return true;
      }
    }
    return false;
  };

  return { checkFields };
};
