import AppContainer from "components/common/layout/AppContainer";
import ResetPasswordForm from "components/register/ResetPasswordForm";
import { WithToken } from "navigation/Paths";
import React from "react";
import { RouteComponentProps } from "react-router";

export default ({ match }: RouteComponentProps<WithToken>) => {
  const { token } = match.params;

  return (
    <AppContainer oppositeTheme>
      <ResetPasswordForm token={token} />;
    </AppContainer>
  );
};
