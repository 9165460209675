import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";
import strings from "localisation/strings";
import React, { useState } from "react";
import ShareAssetForm from "./ShareAssetForm";
import ShareAssetTable from "./ShareAssetTable";

export type ModalType = "TABLE" | "FORM";

interface ShareAssetMetaButtonProps extends ModalProps {
  mediaAssetId: string;
  onDone?: () => void;
}

export default ({
  mediaAssetId,
  onDone = () => {},
  ...modalProps
}: ShareAssetMetaButtonProps) => {
  const [modalContent, setModalContent] = useState<ModalType>("TABLE");

  const onCancel = () => {
    onDone();
    setModalContent("TABLE");
  };

  return (
    <Modal
      title={strings("common.share")}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      {...modalProps}
      width={740}
      bodyStyle={{ padding: "32px" }}
    >
      {modalContent === "TABLE" && (
        <ShareAssetTable
          changeContent={setModalContent}
          mediaAssetId={mediaAssetId}
        />
      )}
      {modalContent === "FORM" && (
        <ShareAssetForm
          changeContent={setModalContent}
          mediaAssetId={mediaAssetId}
        />
      )}
    </Modal>
  );
};
