import { useQuery } from "@apollo/react-hooks";
import { useCallback } from "react";
import GraphQLClientState from "state/GraphQLClientState";
import UserState from "state/UserState";
import { createContainer } from "unstated-next";
import {
  DefaultWarehouseIdDocument,
  DefaultWarehouseIdQuery,
} from "__gen__/appGatewaySdk";
import { useOrgFoldersSubscription } from "__gen__/appService";

export interface Folder {
  id: string;
  name: string;
  parentId?: string | null;
}

export interface FolderTree {
  folder: Folder;
  children: FolderTree[];
}

const findFolder = (folders: Folder[]) => (folderId: string | null) => {
  return folderId
    ? folders.find(({ id }) => folderId === id)
    : folders.find(({ parentId }) => parentId === null);
};

const getPath = (folders: Folder[], path: Folder[] = []) => (
  folderId: string | null,
): Folder[] => {
  const currentFolder = findFolder(folders)(folderId);

  if (currentFolder?.parentId) {
    return getPath(folders, [currentFolder, ...path])(currentFolder.parentId);
  }
  if (currentFolder) {
    return [currentFolder, ...path];
  }
  return path;
};

const assembleTree = (folders: Folder[], parentFolder: Folder): FolderTree => {
  const levelFolders = folders.filter(
    ({ parentId }) => parentId === parentFolder.id,
  );
  const children = levelFolders.map((folder) => assembleTree(folders, folder));
  return {
    folder: parentFolder,
    children,
  };
};

export default createContainer(
  (initialState: { orgId: string } | undefined) => {
    const { apiClient } = GraphQLClientState.useContainer();
    const { selectedOrgId } = UserState.useContainer();
    const { data, loading, error } = useOrgFoldersSubscription({
      orgId: initialState?.orgId || selectedOrgId,
    });
    const { data: warehouseData } = useQuery<DefaultWarehouseIdQuery>(
      DefaultWarehouseIdDocument,
      { client: apiClient },
    );

    const folders = data?.folders || [];

    const rootFolder = folders.find(({ parentId }) => parentId === null);
    const folderTree = rootFolder ? assembleTree(folders, rootFolder) : null;
    const getFolderPath = useCallback(getPath(folders), [folders]);
    const getFolder = useCallback(findFolder(folders), [folders]);
    const getFolderName = useCallback(
      (folderId: string | null) => findFolder(folders)(folderId)?.name || "",
      [folders],
    );

    return {
      loading,
      error,
      rootFolder,
      folderTree,
      getFolderPath,
      getFolderName,
      getFolder,
      defaultWarehouseId: warehouseData?.defaultWarehouseId || "",
    };
  },
);
