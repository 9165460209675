import VLInput from "components/common/input/VLInput";
import VLTextArea from "components/common/input/VLTextArea";
import React from "react";
import TextPreview from "./TextPreview";
import useEditableField from "./useEditableField";

interface TextFieldProps {
  initialValue?: string;
  onSave: (text?: string) => void;
  placeholder?: string;
  isLongText?: boolean;
  disabled?: boolean;
}

export default ({
  initialValue,
  onSave: onSaveFromProps,
  placeholder,
  isLongText,
  disabled,
}: TextFieldProps) => {
  const {
    isEditing,
    value,
    setValue,
    startEditing,
    stopEditing,
  } = useEditableField<string | undefined>({
    initialValue,
    initialEditingValue: (v) => v || placeholder,
  });

  if (!isEditing) {
    return (
      <TextPreview
        text={value || ""}
        startEditing={startEditing}
        placeholder={placeholder}
        disabled={disabled}
      />
    );
  }

  const onSave = () => {
    stopEditing();
    onSaveFromProps(value);
  };

  if (isLongText) {
    return (
      <VLTextArea
        autoSize
        autoFocus
        onBlur={onSave}
        value={value}
        onTextChange={setValue}
        onSave={onSave}
        disabled={disabled}
      />
    );
  }

  return (
    <VLInput
      autoFocus
      onBlur={onSave}
      value={value}
      onTextChange={setValue}
      onSave={onSave}
      disabled={disabled}
    />
  );
};
