import { Table } from "antd";
import CopyButton from "components/common/buttons/CopyButton";
import VLButton from "components/common/buttons/VLButton";
import { Row } from "components/common/layout/Flex";
import { SimpleText } from "components/common/text/SimpleText";
import useDateFns from "components/hooks/useDateFns";
import useVlConfig from "components/hooks/useVlConfig";
import strings from "localisation/strings";
import { path } from "ramda";
import React from "react";
import styled from "styled-components";
import { useLinkSharesSubscription } from "__gen__/appService";
import DeleteLinkShareButton from "./DeleteLinkShareButton";
import { ModalType } from "./ShareAssetModal";

const LinkSharesTable = styled(Table)`
  margin-top: ${({ theme }) => theme.margin.xlarge};
`;

interface ShareAssetTableProps {
  mediaAssetId: string;
  changeContent: (content: ModalType) => void;
}

export default ({ changeContent, mediaAssetId }: ShareAssetTableProps) => {
  const { formatDateTime } = useDateFns();
  const { data, loading } = useLinkSharesSubscription({
    mediaId: mediaAssetId,
  });
  const { data: vlConfigData } = useVlConfig();

  const baseUrl =
    path(["linkShare", "linkBaseUrl"], vlConfigData?.defaultConfig?.proto) ||
    "NO_BASE_URL";

  const formatedDateTime = (dateTime: string) =>
    formatDateTime(new Date(dateTime));
  //The url need to be more unique. We should add there something beside mediaAssetId.
  const dataSource = data?.linkShares.map((link) => ({
    ...link,
    validFrom: link.validFrom ? formatedDateTime(link.validFrom) : "-",
    validUntil: link.validUntil ? formatedDateTime(link.validUntil) : "-",
    link: `${baseUrl}?linkId=${link.id}`,
  }));
  return (
    <div>
      <Row mainAxis="flex-end">
        <VLButton icon="plus" onClick={() => changeContent("FORM")} />
      </Row>
      {dataSource !== undefined && dataSource.length > 0 ? (
        <LinkSharesTable
          size="middle"
          loading={loading}
          dataSource={dataSource}
          rowKey="id"
          pagination={{ hideOnSinglePage: true }}
          //TODO Add onRow click handler
          onRow={(asset) => ({
            onClick: () => {},
          })}
        >
          <Table.Column
            key="title"
            title={strings("shareAssetForm.fields.title.label")}
            dataIndex="title"
          />
          <Table.Column
            key="validFrom"
            title={strings("shareAssetForm.fields.validFrom.label")}
            dataIndex="validFrom"
          />
          <Table.Column
            key="validUntil"
            title={strings("shareAssetForm.fields.validUntil.label")}
            dataIndex={"validUntil"}
          />
          <Table.Column
            key="action"
            title={strings("common.link")}
            render={(linkItem) => (
              <CopyButton
                textToCopy={linkItem.link}
                icon="copy"
                afterCopyMessage="Link copied"
              />
            )}
            width={55}
          />
          <Table.Column
            key="delete"
            title={strings("buttons.delete")}
            render={(linkItem) => (
              <DeleteLinkShareButton linkShareId={linkItem.id} />
            )}
            width={55}
          />
        </LinkSharesTable>
      ) : (
        <SimpleText>{strings("shareAssetForm.noLinks")}</SimpleText>
      )}
    </div>
  );
};
